import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { withAuthPing } from 'decorators/withAuthPing';
import { StoreProps } from 'store';

import { resendVerificationCode, sendVerificationCode } from 'api/verification';
import showNotification from 'components/ui/notification/showNotification';
import { CodeVerification } from './CodeVerification';
import { BACKEND_ERRORS, CODE_LENGTH, SCENES } from './constants';
import { BaseInterface, ContentInterface } from './types';

interface OwnProps {
  content: ContentInterface;
  callback: () => void;
  user: any;
}

type Props = OwnProps & IntlProps & StoreProps;

interface State extends BaseInterface {}

@withAuthPing
class CodeVerificationContainer extends PureComponent<Props, State> {
  codeValidationTimeoutId: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      scene: SCENES.MAIN,
      code: '',
      errorMessage: '',
      isLoading: false,
      canResendCode: false,
    };
  }

  componentDidMount() {
    const {
      content: {
        confirmationDetails: { codeResendSinceTimeLeft, codeValidTillTimeLeft },
      },
    } = this.props;

    this.startResendCodeTimeout(codeResendSinceTimeLeft);
    this.startConfirmCodeTimeout(codeValidTillTimeLeft);
    this.startValidationCodeTimeout();
  }

  componentWillUnmount() {
    this.clearValidationCodeTimeout();
  }

  sendCode = async () => {
    const canSendCode = this.checkSendingCodeAbility();

    if (!canSendCode) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const {
        content: { verificationId, requestInfo, rpc },
      } = this.props;

      const { id } = rpc;
      const params = {
        verificationId,
        code: this.getCodeWithoutSpace(),
        requestInfo,
      };

      await sendVerificationCode(id, params);
    } catch ({ payload }: any) {
      this.handleError(payload);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  resendCode = async () => {
    const { canResendCode } = this.state;

    if (!canResendCode) {
      return;
    }

    try {
      const {
        content: { verificationId },
      } = this.props;

      const {
        confirmationDetails: { codeResendSinceTimeLeft },
      } = await resendVerificationCode(verificationId);

      this.startResendCodeTimeout(codeResendSinceTimeLeft);
    } catch ({ payload }: any) {
      this.handleError(payload);
    }
  };

  handleError = (error) => {
    const { getTranslate } = this.props;

    if (!error) {
      return this.setState({
        errorMessage: getTranslate('confirmation.internalServerError.sysmsg'),
      });
    }

    const { errorCode, validationErrors } = error;

    switch (errorCode) {
      case BACKEND_ERRORS.ERROR_CODE_TRANSACTION_LOCKED:
        return this.changeScene(SCENES.TRANSACTION_LOCKED_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_ATTEMPTS_EXCEEDED:
        return this.changeScene(SCENES.ATTEMPTS_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_OPERATION_EXPIRED:
        return this.changeScene(SCENES.TRANSACTION_LOCKED_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_VERIFICATION_CODE_INVALID:
        return this.setState({
          errorMessage: getTranslate('payment.psd2.invalidCode.text'),
        });
      default:
        if (Object.values(validationErrors).length) {
          showNotification({
            status: 'error',
            content: getTranslate(Object.values(validationErrors)[0] as string),
          });
        }
    }
  };

  checkSendingCodeAbility = () => {
    const { isLoading } = this.state;
    return this.getCodeWithoutSpace().length === CODE_LENGTH && !isLoading;
  };

  getCodeWithoutSpace = () => {
    return this.state.code.replace(/\s/g, '');
  };

  changeCode = (event) => {
    this.setState({
      code: event.target.value,
      errorMessage: '',
    });
  };

  startResendCodeTimeout = (time: number) => {
    this.setState({
      timeResendCode: Date.now() + time * 1000,
      canResendCode: false,
    });
  };

  startConfirmCodeTimeout = (time: number) => {
    this.setState({
      timeConfirmCode: Date.now() + time * 1000,
    });
  };

  startValidationCodeTimeout = () => {
    const {
      content: {
        confirmationDetails: { codeValidTillTimeLeft },
      },
    } = this.props;

    this.codeValidationTimeoutId = setTimeout(() => {
      this.changeScene(SCENES.TIMEOUT_ERROR);
    }, codeValidTillTimeLeft * 1000);
  };

  clearValidationCodeTimeout = () => {
    clearTimeout(this.codeValidationTimeoutId);
  };

  completeResendTimer = () => {
    this.setState({
      canResendCode: true,
    });
  };

  changeScene = (scene: keyof typeof SCENES) => {
    this.setState({ scene });
  };

  render() {
    const { content, callback } = this.props;
    const {
      scene,
      code,
      errorMessage,
      isLoading,
      timeResendCode,
      timeConfirmCode,
      canResendCode,
    } = this.state;

    return (
      <CodeVerification
        scene={scene}
        code={code}
        timeResendCode={timeResendCode}
        timeConfirmCode={timeConfirmCode}
        errorMessage={errorMessage}
        content={content}
        isLoading={isLoading}
        canResendCode={canResendCode}
        canSendCode={this.checkSendingCodeAbility()}
        callback={callback}
        onChangeScene={this.changeScene}
        onCompleteTimer={this.completeResendTimer}
        onChangeCode={this.changeCode}
        onSendCode={this.sendCode}
        onResendCode={this.resendCode}
      />
    );
  }
}

export default connect()(addTranslation(CodeVerificationContainer));
