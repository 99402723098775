import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPaymentAvailableActions } from 'api/payment';
import PaymentActions from './PaymentActions';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import OPERATION_CODES from 'constants/operationCodes';
import config from 'config';
import { AnyObject } from 'types/Common';
import statuses from 'components/ui/button/statuses';
import { RootState, StoreProps } from 'store';
import { actionTypesT } from './components/action/ActionContainer';

interface OwnProps {
  transactionId: string;
  projectId: string;
  operationId?: string;
  actionsToShow?: string[];
  isNeedPing?: boolean;
  refundTooltip?: string;
  buttonTheme?: keyof typeof statuses;
  buttonType?: 'round';
  actionTypes?: actionTypesT[];
  customClass?: string;
  startPingOnClick?: boolean;
  disabled?: boolean;
  selectorPortal?: string;
}

interface ConnectedProps {
  availableActions: AnyObject;
}

interface State {
  isPingEnabled: boolean;
  isLoading: boolean;
  isOpenedPanel: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & IsMounted;

const defaultActionTypes: actionTypesT[] = [
  'capture',
  'cancel',
  'refund',
  'payout',
];

class PaymentActionsContainer extends Component<Props, State> {
  private updateTimerId;

  constructor(props) {
    super(props);
    this.state = {
      isPingEnabled: props.isNeedPing !== undefined ? props.isNeedPing : true,
      isLoading: false,
      isOpenedPanel: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { isNeedPing, startPingOnClick } = this.props;
    const { isLoading, isPingEnabled } = this.state;

    if (prevProps.isNeedPing !== isNeedPing && isNeedPing !== undefined) {
      this.setState({ isPingEnabled: isNeedPing });
    }

    if (!prevState.isPingEnabled && isPingEnabled) {
      this.init();
    }

    if (!prevState.isLoading && isLoading && startPingOnClick) {
      this.setState({ isOpenedPanel: true });
    }
  }

  componentWillUnmount() {
    this.stopUpdateStatus();
  }

  init = async () => {
    const { isPingEnabled } = this.state;
    const { availableActions } = this.props;

    if (!isPingEnabled && !availableActions) {
      return;
    }

    await this.getAvailableActions();

    if (this.canUpdateStatus()) {
      this.updateStatus();
    }
  };

  getAvailableActions = async (): Promise<any> => {
    const { transactionId, availableActions } = this.props;
    if (!transactionId) return;

    this.setState({ isLoading: !availableActions });

    return getPaymentAvailableActions({ transactionId });
  };

  canUpdateStatus = () => {
    const { actionTypes, availableActions } = this.props;
    const types = (actionTypes || defaultActionTypes).filter(
      (type) => type !== 'payout'
    );
    return (
      this.isProcessing() ||
      types.some((type) => {
        if (type === 'refund') {
          return availableActions?.[type].isEnabled;
        }
        return availableActions?.[type].reasonCode === true;
      })
    );
  };

  updateStatus = () => {
    const { isMount } = this.props;
    if (!isMount()) {
      return this.stopUpdateStatus();
    }

    const timer = this.isProcessing()
      ? config.CHECK_PAYMENT_STATUS
      : config.CHECK_PAYMENT_STATUS_BACKGROUND;
    this.updateTimerId = setTimeout(() => {
      this.getAvailableActions().then(() => {
        if (this.canUpdateStatus()) {
          this.updateStatus();
        }
      });
    }, timer);
  };

  stopUpdateStatus = () => {
    clearTimeout(this.updateTimerId);
  };

  isProcessing = () => {
    const { availableActions, actionTypes } = this.props;
    const types = (actionTypes || defaultActionTypes).filter(
      (type) => type !== 'payout'
    );
    return types.some((type) => {
      return (
        availableActions?.[type].reasonCode ===
        OPERATION_CODES.REASON_PAYMENT_PROCESSING
      );
    });
  };

  render() {
    const {
      disabled,
      transactionId,
      operationId,
      projectId,
      availableActions,
      refundTooltip,
      buttonTheme,
      buttonType,
      actionTypes,
      customClass,
      selectorPortal,
      startPingOnClick,
    } = this.props;
    const { isLoading, isOpenedPanel, isPingEnabled } = this.state;

    return (
      <PaymentActions
        availableActions={availableActions}
        isLoading={isLoading}
        projectId={projectId}
        transactionId={transactionId}
        operationId={operationId}
        refundTooltip={refundTooltip}
        buttonTheme={buttonTheme}
        buttonType={buttonType}
        customClass={customClass}
        actionTypes={actionTypes || defaultActionTypes}
        startPingOnClick={startPingOnClick}
        startPing={() => this.setState({ isPingEnabled: true })}
        isEnabled={isPingEnabled}
        disabled={disabled}
        isOpenedPanel={isOpenedPanel}
        selectorPortal={selectorPortal}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): ConnectedProps => {
  if (ownProps.actionsToShow) {
    const availableActions = state.paymentActions[ownProps.transactionId];

    return {
      availableActions: ownProps.actionsToShow.reduce((result, action) => {
        if (availableActions[action]) {
          return {
            ...result,
            [action]: availableActions[action],
          };
        }
        return result;
      }, {}),
    };
  }

  return {
    availableActions: state.paymentActions[ownProps.transactionId],
  };
};

export default connect(mapStateToProps)(addIsMounted(PaymentActionsContainer));
