import { v4 as uuid } from 'uuid';
import {
  ChargebackDataInner,
  ChartData,
  ChartFilters,
  ChartViewData,
  DeclineReasonsData,
  FiltersNormalizer,
  FraudReportDataInner,
  GraphData,
  IChartDataNormalizerObject,
  IFormValues,
  InOutData,
  SuccessRateDataInner,
  TopTenFormValues,
} from 'types/Analytics';
import { ServiceSettingsFormHelpers } from 'components/modal/modalList/graphDetails/services/ServiceSettingsFormHelpers';
import { ServiceChartDataFormatter } from 'components/modal/modalList/graphDetails/services/ServiceChartDataFormatter';
import { groupByHiddenAdapter as FraudReportGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';
import { groupByHiddenAdapter as ChargebackGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

enum CHART_TYPES {
  'line' = 'line',
  'pie chart' = 'pie chart',
  'bar' = 'bar',
}

const {
  inout,
  operations,
  chargeback,
  fraud_report,
  declineReasons,
  success_rate,
  topten,
} = AdvancedAnalyticsEntityType;

const chartDataFormatterWrapper = (
  chartData: GraphData['data']
): ChartViewData | undefined => {
  const { chart, chartType } = chartData;

  switch (chart) {
    case operations:
      if (chartType in CHART_TYPES) {
        return new ServiceChartDataFormatter(
          chartData as ChartData['data']
        ).format();
      }
    // falls through
    case inout:
      return {
        ...chartData,
        chartGroup: inout,
        uuid: uuid(),
      } as InOutData;
    case 'pivot':
      return {
        ...chartData,
        chartGroup: declineReasons,
        uuid: uuid(),
      } as DeclineReasonsData;
    case topten:
      return new ServiceChartDataFormatter(
        chartData as ChartData['data']
      ).format();
    case chargeback:
      if (chartType === 'simple_table') {
        return {
          ...chartData,
          chartGroup: chargeback,
          uuid: uuid(),
        } as ChargebackDataInner;
      }
      return new ServiceChartDataFormatter(
        chartData as ChartData['data']
      ).format();
    case fraud_report:
      if (chartType === 'simple_table') {
        return {
          ...chartData,
          chartGroup: fraud_report,
          uuid: uuid(),
        } as FraudReportDataInner;
      }

      return new ServiceChartDataFormatter(
        chartData as ChartData['data']
      ).format();

    case success_rate:
      if (chartType === 'simple_table') {
        return {
          ...chartData,
          chartGroup: success_rate,
          uuid: uuid(),
        } as SuccessRateDataInner;
      }

      return new ServiceChartDataFormatter(
        chartData as ChartData['data']
      ).format();

    default:
      return undefined;
  }
};

const prepareTopTenRequest = (values: TopTenFormValues, id?: string) => {
  const {
    chartName,
    chartData,
    chartType,
    dimension,
    paymentMethod,
    issuerCountry,
    type,
    projectId,
  } = values;

  const conditionalFilters = {
    paymentMethod: dimension !== 'paymentMethod' ? paymentMethod : undefined,
    issuerCountry: dimension !== 'issuerCountry' ? issuerCountry : undefined,
  };

  return {
    filters: {
      projectId,
      type,
      ...conditionalFilters,
    },
    chartParameters: {
      id,
      chartName,
      chartType,
      dimension,
      chart: topten,
      period: ServiceSettingsFormHelpers.formatDate(values),
      ...measureFieldsSerializer({ chartData }),
    },
  };
};

const measureFieldsSerializer = (payload: {
  chartData: string;
}): IChartDataNormalizerObject | undefined => {
  const { chartData } = payload;

  switch (chartData) {
    case 'count': {
      return {
        chartData: 'count',
        measureField: 'operation_id',
      };
    }

    case 'amount': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_eur': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_eur',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_gbp': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_gbp',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_rub': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_rub',
        measureFunction: 'sum',
      };
    }

    case 'avg_channel_amount': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount',
        measureFunction: 'avg',
      };
    }

    case 'avg_channel_amount_in_eur': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_eur',
        measureFunction: 'avg',
      };
    }

    case 'avg_channel_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_usd',
        measureFunction: 'avg',
      };
    }

    case 'unique_customers': {
      return {
        chartData: 'count',
        measureField: 'customer_id',
        measureFunction: 'distinct',
      };
    }

    case 'count_chargeback_id': {
      return {
        chartData: 'count',
        measureField: 'chargeback_id',
        measureFunction: 'count',
      };
    }

    case 'sum_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'ratio_by_count': {
      return {
        chartData: 'count',
        measureField: 'chargeback_id',
        measureFunction: 'ratio_by_number',
      };
    }

    case 'ratio_by_amount': {
      return {
        chartData: 'amount',
        measureField: 'amount_in_usd',
        measureFunction: 'ratio_by_amount',
      };
    }

    case 'numberOfFraudOperations': {
      return {
        chartData: 'count',
        measureField: 'arn',
        measureFunction: 'count',
      };
    }

    case 'fraudAmountUSD': {
      return {
        chartData: 'amount',
        measureField: 'fraud_amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'fraudRatioByNumber': {
      return {
        chartData: 'count',
        measureField: 'arn',
        measureFunction: 'ratio_by_number',
      };
    }

    case 'fraudRatioByAmount': {
      return {
        chartData: 'amount',
        measureField: 'fraud_amount_in_usd',
        measureFunction: 'ratio_by_amount',
      };
    }

    default:
      return undefined;
  }
};

const filtersNormalizer = (filters: ChartFilters): FiltersNormalizer => {
  const chartTypes = [
    operations,
    topten,
    declineReasons,
    fraud_report,
    chargeback,
  ];

  return Object.entries(filters).reduce((acc, [filterId, value]) => {
    const { chart, chartData, measureFunction, measureField } = filters;

    if (filterId === 'chartData' && chartTypes.includes(chart)) {
      acc['chartData'] = ServiceChartDataFormatter.normalizeChartData({
        data: {
          chartData,
          measureFunction,
          measureField,
        },
      });

      return acc;
    } else if (
      (chart === chargeback || chart === fraud_report) &&
      filterId === 'groupBy'
    ) {
      const hiddenAdapter =
        chart === chargeback
          ? ChargebackGroupByHiddenAdapter
          : FraudReportGroupByHiddenAdapter;

      if (hiddenAdapter(filters)) {
        acc['groupByMultiSelect'] = value;
      } else {
        acc['groupBy'] = Array.isArray(value) === true ? value[0] : [];
      }

      return acc;
    }

    acc[filterId] = value;

    return acc;
  }, {} as FiltersNormalizer);
};

const chartDataSerializer = (payload: {
  chartType: AdvancedAnalyticsEntityType;
  values: IFormValues;
}) => {
  const { chartType, values } = payload;

  const getGroupBy = () => {
    let groupBy: string[] = [];

    if (
      'groupByMultiSelect' in values &&
      Array.isArray(values.groupByMultiSelect) &&
      values.groupByMultiSelect.length !== 0
    ) {
      groupBy = values.groupByMultiSelect;
    } else if ('groupBy' in values && values.groupBy !== '') {
      groupBy = [values.groupBy];
    }

    return groupBy;
  };

  if (values.chartData === undefined) {
    throw new Error('Chart Data should not be empty');
  }

  switch (chartType) {
    case operations:
      return {
        filters: {
          paymentMethod:
            'paymentMethod' in values ? values.paymentMethod : undefined,
          operationType:
            'operationType' in values ? values.operationType : undefined,
          operationStatus:
            'operationStatus' in values ? values.operationStatus : undefined,
          currency: 'currency' in values ? values.currency : undefined,
          country: 'country' in values ? values.country : undefined,
          projectId:
            'projects' in values
              ? values.projects.map((i) => Number(i))
              : undefined,
          issuerCountry:
            'issuerCountry' in values ? values.issuerCountry : undefined,
          operationTypeDms:
            'operationTypeDms' in values ? values.operationTypeDms : undefined,
        },
        chartParameters: {
          chartName: values.chartName,
          chart: 'operations',
          chartType: 'chartType' in values ? values.chartType : undefined,
          groupBy: 'groupBy' in values ? values.groupBy : undefined,
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          ...measureFieldsSerializer({ chartData: values.chartData }),
        },
      };
    case inout:
      return {
        filters: {
          projectId:
            'projects' in values && values.projects.map((i) => Number(i)),
          paymentMethod:
            'paymentMethod' in values ? values.paymentMethod : undefined,
          currency: 'currency' in values ? values.currency : undefined,
          type: 'type' in values ? values.type : undefined,
          issuerCountry: 'country' in values ? values.country : undefined,
        },
        chartParameters: {
          chartName: values.chartName,
          chartData: values.chartData,
          chart: 'inout',
          chartType: 'table',
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          rows: 'groupByRows' in values ? values.groupByRows : undefined,
          columns:
            'groupByColumns' in values ? values.groupByColumns : undefined,
        },
      };
    case declineReasons:
      return {
        filters: {
          paymentMethod:
            'paymentMethod' in values ? values.paymentMethod : undefined,
          operationType:
            'operationType' in values ? values.operationType : undefined,
          operationStatus:
            'operationStatus' in values ? values.operationStatus : undefined,
          currency: 'currency' in values ? values.currency : undefined,
          issuerCountry:
            'countryByBin' in values ? values.countryByBin : undefined,
          country: 'countryByIp' in values ? values.countryByIp : undefined,
          projectId:
            'projectId' in values
              ? values.projectId.map((i) => Number(i))
              : undefined,
          operationTypeDms:
            'operationTypeDms' in values ? values.operationTypeDms : undefined,
        },
        chartParameters: {
          chartName: values.chartName,
          chart: 'pivot',
          chartType: 'table',
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          rows: 'groupByRows' in values ? values.groupByRows : undefined,
          columns:
            'groupByColumns' in values ? values.groupByColumns : undefined,
          ...measureFieldsSerializer({ chartData: values.chartData }),
        },
      };
    case topten:
      return prepareTopTenRequest(values as TopTenFormValues);
    case chargeback:
      return {
        filters: {
          paymentMethod:
            'paymentMethod' in values ? values.paymentMethod : undefined,
          projectId:
            'projectId' in values
              ? values.projectId.map((i) => Number(i))
              : undefined,
          cardType: 'cardType' in values ? values.cardType : [],
          issuerCountry: 'issuerCountry' in values ? values.issuerCountry : [],
        },
        chartParameters: {
          chartName: values.chartName,
          chart: 'chargeback',
          chartType: 'chartType' in values ? values.chartType : undefined,
          groupBy: getGroupBy(),
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          ...measureFieldsSerializer({ chartData: values.chartData }),
        },
      };
    case fraud_report:
      return {
        filters: {
          projectId:
            'projectId' in values
              ? values.projectId.map((i) => Number(i))
              : undefined,
          cardType: 'cardType' in values ? values.cardType : [],
          issuerCountry: 'issuerCountry' in values ? values.issuerCountry : [],
          brandId: 'brandId' in values ? values.brandId : [],
        },
        chartParameters: {
          chartName: values.chartName,
          chart: fraud_report,
          chartType: 'chartType' in values ? values.chartType : undefined,
          groupBy: getGroupBy(),
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          ...measureFieldsSerializer({ chartData: values.chartData }),
        },
      };
    case success_rate:
      return {
        filters: {
          projectId:
            'projectId' in values
              ? values.projectId.map((i) => Number(i))
              : undefined,
          transactionType:
            'transactionType' in values ? values.transactionType : undefined,
          paymentMethodGroup:
            'paymentMethodGroup' in values
              ? values.paymentMethodGroup
              : undefined,
          paymentMethodType:
            'paymentMethodType' in values
              ? values.paymentMethodType
              : undefined,
          issuerBankCountryName:
            'issuerBankCountryName' in values
              ? values.issuerBankCountryName
              : undefined,
          currency: 'currency' in values ? values.currency : undefined,
        },
        chartParameters: {
          chartName: values.chartName,
          chart: success_rate,
          chartType: values.chartType,
          groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
          period: ServiceSettingsFormHelpers.formatDate(values),
          ...measureFieldsSerializer({ chartData: values.chartData }),
        },
      };
    default:
      return {
        chartParameters: {},
        filters: {},
      };
  }
};

export {
  chartDataFormatterWrapper,
  prepareTopTenRequest,
  measureFieldsSerializer,
  filtersNormalizer,
  chartDataSerializer,
};
