import { MafHelpers } from '.';
import { MafField, MafTab, MafTabValidatorConfig } from '../types';
import getLocalizedText from '../../../helpers/getLocalizedText';
import { v4 as uuid } from 'uuid';

const splitFieldNames = (names?: string): Set<string> =>
  new Set(
    (names || '')
      .split(',')
      .map((name) => name.trim())
      .filter(Boolean)
  );

const getFieldsByName = (
  fields: MafField[],
  names: Set<string>
): Map<string, MafField | undefined> => {
  const fieldMap = new Map(fields.map((field) => [field.name, field]));
  return new Map(
    Array.from(names.values()).map((name) => [name, fieldMap.get(name)])
  );
};

const getAllFieldsFromListedTabDeeply = (tab: MafTab): MafField[] => {
  const fields = tab.fields ?? [];
  const subTabFields = (tab.tabs ?? []).reduce((accum, subTab) => {
    if (subTab.tabs?.length > 0) {
      return accum.concat(getAllFieldsFromListedTabDeeply(subTab));
    }
    return accum.concat(subTab.fields ?? []);
  }, [] as MafField[]);
  return fields.concat(subTabFields);
};

export class MafTabValidators {
  static sumValidator(tab: MafTab, config: MafTabValidatorConfig): string {
    if (
      !config.field_names ||
      !config.condition ||
      !config.value ||
      !tab.tab_template?.tabs?.[0]?.fields ||
      !Array.isArray(tab.tab_template.tabs[0].fields)
    ) {
      return '';
    }

    const sum = (tab.tabs ?? []).reduce((accum, subTab) => {
      const field = subTab.tabs?.[0]?.fields?.find(
        (item) => item.name === config.field_names
      );

      const val = MafHelpers.parseFieldValue(field);
      return accum + (typeof val === 'number' ? val : 0);
    }, 0);

    if (
      !MafHelpers.checkCondition(
        sum,
        config.condition,
        parseFloat(config.value)
      )
    ) {
      const fieldCaption = tab.tab_template.tabs[0].fields.find(
        (item) => item.name === config.field_names
      )?.caption;

      return `Sum of ${getLocalizedText(
        fieldCaption ?? 'values'
      )} ${MafHelpers.conditionText(config.condition)} ${config.value}`;
    }

    return '';
  }

  static uniqueValidator(
    tab: MafTab,
    config: MafTabValidatorConfig,
    getTranslate: (key: string) => string
  ): string {
    const fieldNames = splitFieldNames(config.field_names);

    if (
      !fieldNames.size ||
      !Array.isArray(tab.tabs) ||
      !tab.tab_template?.tabs?.[0]?.fields ||
      !Array.isArray(tab.tab_template.tabs[0].fields)
    ) {
      return '';
    }

    const buildKey = (fields: MafField[]) =>
      JSON.stringify(
        Array.from(getFieldsByName(fields, fieldNames).values()).map(
          (field) => MafHelpers.parseFieldValue(field) || uuid()
        )
      );

    const emptyKey = buildKey([]);
    const usedKeys: Record<string, boolean> = {};
    for (const subTab of tab.tabs) {
      const key = buildKey(subTab.tabs?.[0]?.fields ?? []);
      if (key === emptyKey) {
        continue;
      }
      if (usedKeys[key]) {
        const fieldCaptions = new Map(
          tab.tab_template.tabs[0].fields.map((field) => [
            field.name,
            field.caption,
          ])
        );
        return `Values for ${Array.from(fieldNames.values())
          .map((name) => getTranslate(fieldCaptions.get(name) || name))
          .join(', ')} must not repeat`;
      }
      usedKeys[key] = true;
    }

    return '';
  }

  static someCheckboxIsCheckedValidator(
    tab: MafTab,
    config: MafTabValidatorConfig,
    getTranslate: (key: string) => string
  ): string {
    const fieldNames = splitFieldNames(config.field_names);

    if (!fieldNames.size || !Array.isArray(tab.fields)) {
      return '';
    }

    const checkboxFields = getFieldsByName(tab.fields, fieldNames);
    if (
      Array.from(checkboxFields.values()).filter(
        (field) => field?.value === 'True'
      ).length === 0
    ) {
      return `${getTranslate('someof.maf.select')} ${Array.from(
        checkboxFields.entries()
      )
        .map(([name, field]) =>
          getLocalizedText(field?.caption || field?.name || name)
        )
        .filter((caption, index, array) => array.indexOf(caption) === index) // remove duplicates
        .join(', ')} ${getTranslate('mustbe.maf.select')}`;
    }

    return '';
  }

  static checkboxesAreCheckedAtLeastOnceValidator(
    tab: MafTab,
    config: MafTabValidatorConfig,
    getTranslate: (key: string) => string
  ): string {
    const checkboxesNames = splitFieldNames(config.field_names);
    const tabFields = getAllFieldsFromListedTabDeeply(tab);

    if (!checkboxesNames.size || !tabFields.length) {
      return '';
    }

    const doesCheckboxesCheckedAtLeastOnce: [MafField, boolean][] = Array.from(
      checkboxesNames.values()
    ).map((checkboxName) => {
      const checkboxes = tabFields.filter(
        (field) => field.name === checkboxName
      );
      return [
        checkboxes[0],
        checkboxes.some((field) => field.value === 'True'),
      ];
    });

    if (
      doesCheckboxesCheckedAtLeastOnce.every(
        ([checkbox, chekcedAtLeastOnce]) => chekcedAtLeastOnce
      )
    ) {
      return '';
    }

    const uncheckedCheckboxes = doesCheckboxesCheckedAtLeastOnce
      .filter(([checkbox, chekcedAtLeastOnce]) => !chekcedAtLeastOnce)
      .map(([checkbox, chekcedAtLeastOnce]) => checkbox);

    return `${getTranslate('someof.maf.select')} ${uncheckedCheckboxes
      .map((checkbox) =>
        getLocalizedText(checkbox?.caption || checkbox?.name || '')
      )
      .join(', ')} ${getTranslate('mustbe.maf.select')}`;
  }
}
