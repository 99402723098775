import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash-es';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import breakpoints from 'constants/breakpoints';
import './pageTemplate.scss';

const Main = (props) => {
  return (
    <div
      className={classNames('page-template', props.customClass, {
        'page-template_width': props.setWidth,
      })}>
      {props.children}
    </div>
  );
};

const Container = (props) => {
  return (
    <div className={classNames('page-template-container', props.customClass)}>
      {props.children}
    </div>
  );
};

interface Props {}

interface State {
  isMobile: boolean;
}

class Entities extends PureComponent<Props, State> {
  private pageEntities;
  observer;

  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= breakpoints.commonTabletL,
    };
    this.handleResize = throttle(this.handleResize, 200);
    this.observer = (() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.includes('safari') &&
        !userAgent.includes('chrome') &&
        window.innerWidth <= breakpoints.commonTabletL
      ) {
        return new MutationObserver(this.watchOpenedPanels);
      }
    })();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (this.observer) {
      this.observer?.observe(this.pageEntities, {
        childList: true,
        subtree: true,
      });
    }
  }

  render() {
    return (
      <div
        className='page-template-entities'
        ref={(el) => {
          this.pageEntities = el;
        }}>
        <ConditionalWrapper
          condition={this.state.isMobile}
          wrap={(children) => (
            <div className='page-template-entities__inner'>{children}</div>
          )}>
          {this.props.children}
        </ConditionalWrapper>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= breakpoints.commonTabletL });
  };

  watchOpenedPanels = () => {
    const panel = this.pageEntities.querySelector('.ui-panel');
    if (panel) {
      this.pageEntities.classList.add('page-template-entities_visible');
    } else {
      this.pageEntities.classList.remove('page-template-entities_visible');
    }
  };
}

export default { Main, Container, Entities };
