import { forEach } from 'lodash-es';
import { createSelector } from 'reselect';

const getFilters = (state, name) => {
  return state.filters[name].fields || state.filters[name];
};

const getFiltersValues = (state) => {
  return state.filtersValues;
};

export default createSelector(
  [getFilters, getFiltersValues],
  (filters, dictionaries) => {
    const values = {};
    forEach(filters, (filter, key) => {
      if (filter.value?.length) {
        const value = filter.value;
        const { dictionaryId } = filter;
        if (dictionaries && dictionaryId && dictionaries[dictionaryId]) {
          if (Array.isArray(value)) {
            const valueTexts: string[] = [];
            value.forEach((val) => {
              const item = dictionaries[dictionaryId].list?.find(
                (dItem) => dItem.id === val
              );
              item && item.text
                ? valueTexts.push(item.text)
                : valueTexts.push(val);
            });
            values[filter.filterId] = valueTexts;
          }
        } else {
          values[filter.filterId] = value;
        }
      }
    });
    return values;
  }
);
