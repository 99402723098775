import React from 'react';
import { throttle } from 'lodash-es';
import api from 'api/user';
import Token from 'helpers/Token';
import LocalStorage from 'helpers/LocalStorage';
import config from 'config';

interface Props {
  instanceId: string;
}

class UserActivityWatcher extends React.PureComponent<Props> {
  hasActivity = false;
  pingIntervalId: NodeJS.Timeout | undefined;
  pingTimeoutId: NodeJS.Timeout | undefined;

  constructor(props) {
    super(props);
    this.setUserActivity = throttle(this.setUserActivity, 1000);
  }

  componentDidMount() {
    this.setPing();
    this.init();
  }

  componentWillUnmount() {
    this.stopPing();
    this.destroy();
  }

  init = () => {
    document.addEventListener('keypress', this.setUserActivity);
    document.addEventListener('mousedown', this.setUserActivity);
    document.addEventListener('wheel', this.setUserActivity);
  };

  destroy = () => {
    document.removeEventListener('keypress', this.setUserActivity);
    document.removeEventListener('mousedown', this.setUserActivity);
    document.removeEventListener('wheel', this.setUserActivity);
  };

  setUserActivity = () => {
    this.hasActivity = true;
  };

  startPing = async () => {
    const nextPing = new Date().valueOf() + config.PING_INTERVAL;
    LocalStorage.set('nextPing', nextPing);

    this.pingIntervalId = setInterval(async () => {
      try {
        await this.singlePing();
      } catch (e) {
        console.error(e);
      }
    }, config.PING_INTERVAL);

    console.debug({
      message: 'Ping started.',
      hasActivity: this.hasActivity,
      time: new Date().toLocaleTimeString(),
      nextPing: new Date(nextPing).toLocaleTimeString(),
    });
  };

  singlePing = async () => {
    const nextPingInLocalStorage = LocalStorage.get('nextPing');
    const { instanceId } = this.props;

    if (LocalStorage.get('instanceId') === instanceId) {
      const nextPing =
        (nextPingInLocalStorage || new Date().valueOf()) + config.PING_INTERVAL;
      LocalStorage.set('nextPing', nextPing);
      const { authToken } = await api.ping({ hasActivity: this.hasActivity });

      console.debug({
        message: 'Single ping.',
        hasActivity: this.hasActivity,
        time: new Date().toLocaleTimeString(),
        nextPing: new Date(nextPing).toLocaleTimeString(),
      });

      Token.setTokens({ authToken });
      this.hasActivity = false;
    } else {
      console.warn(
        `instanceIdInLocalStorage !== instanceId (${LocalStorage.get(
          'instanceId'
        )} !== ${instanceId})`
      );
    }
  };

  stopPing = () => {
    console.debug({
      message: 'Ping stopped.',
      hasActivity: this.hasActivity,
      time: new Date().toLocaleTimeString(),
      nextPing: new Date(LocalStorage.get('nextPing')).toLocaleTimeString(),
    });
    clearInterval(this.pingIntervalId);
    clearTimeout(this.pingTimeoutId);
  };

  customPing = () => {
    const nextPingTime = LocalStorage.get('nextPing');
    let timeout = nextPingTime - new Date().valueOf();

    if (timeout < 0) {
      console.debug(
        'Ping started because "nextPingTime" is less than the present time.'
      );
      return this.startPing();
    }

    if (timeout > config.PING_INTERVAL) {
      timeout = config.PING_INTERVAL;
    }

    console.debug(`Next ping in ${new Date(timeout).getSeconds()} seconds.`);

    this.pingTimeoutId = setTimeout(async () => {
      try {
        await this.singlePing();
        console.debug('Ping started because "nextPing" was found.');
        await this.startPing();
      } catch (e) {
        console.error(e);
      }
    }, timeout);
  };

  setPing = () => {
    const nextPing = LocalStorage.get('nextPing');

    if (nextPing) {
      console.debug('Ping stopped because "nextPing" was found.');
      this.stopPing();
      return this.customPing();
    }

    console.debug('Ping started because "nextPing" was not found.');
    return this.startPing();
  };

  render() {
    return null;
  }
}
export default UserActivityWatcher;
