import { openModal } from 'actions/modal';
import Icon from 'components/ui/icon';
import { store } from 'store';

export const notifyUserAboutSuccessfullyDataProviding = () =>
  store.dispatch(
    openModal({
      modalId: 'Notification',
      needCloseButton: false,
      content: {
        title: 'Documents sent for review',
        text: 'We will notify you by email when documents will be reviewed. You can now go back to registry.',
        okButton: {
          text: 'Ok',
          status: 'primary',
        },
        icon: (
          <div className='accounting-request-notification__icon'>
            <Icon name='im-Check-mark' size={28} />
          </div>
        ),
      },
    })
  );
