import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { AccountingRequestItem } from 'pages/accountingStatementRequests/types';

export const getAccountingRequests = (): Promise<{
  [key: string]: AccountingRequestItem;
}> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.AccountingDataRequest_List,
      },
    ],
  });
};

export const commitAccountingRequest = (legalEntityId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.AccountingDataRequest_Submit,
        payload: {
          legalEntityId,
        },
      },
    ],
  });
};
