import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isEqual, throttle } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import DatePickerRange from 'components/ui/datePickerRange';

import filtersKeys from 'constants/filters';
import DateHelpers from 'helpers/Date';
import Utils from 'helpers/Utils';
import { filtersKeysType } from 'types/Filters';
import { toggleOpenClass } from 'components/quickFiltersContainer/helper';
import ButtonDropDown from 'components/ui/buttonDropDown';
import { periodTypes as PERIOD_TYPES } from 'constants/dateFormats';
import breakpoints from 'constants/breakpoints';
import { MENU_OPENED_WIDTH, MENU_WIDTH } from 'constants/ui';
import './dateFilter.scss';

interface OwnProps {
  onChangeFilter: (key: filtersKeysType, value: any) => void;
  onApplyRange: (m) => void;
  date: {
    type: string;
    values: any[];
  };
  filterKey: filtersKeysType;
  customClass?: string;
  blockTitleKey?: string;
  disabled?: boolean;
  withTime?: boolean;
  minDate?: string;
  mode?: 'select';
  title?: string;
  periodTypes?: Array<string>;
}

interface ConnectedProps {
  timezone: string;
  isMenuExpanded: boolean;
}

type Props = OwnProps & ConnectedProps & IntlProps;

interface State {
  activeDateButton: string;
  showDatePicker: boolean;
}

class DateFilter extends Component<Props, State> {
  private dateFilterRef;
  private customId = `custom-${Utils.getHash()}`;

  static defaultProps: Readonly<Partial<OwnProps>> = {
    filterKey: filtersKeys.date,
    blockTitleKey: 'quickFilters.chooseDate',
    withTime: true,
    periodTypes: [PERIOD_TYPES.Today, PERIOD_TYPES.Yesterday],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      activeDateButton: props.date.type,
      showDatePicker: false,
    };
    this.handlePanelPosition = throttle(this.handlePanelPosition, 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handlePanelPosition);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { activeDateButton } = this.state;
    const { date } = this.props;
    // Если календарь был закрыт, но изменения не были применены,
    // актуализируем состояние группы кнопок из filters (store)
    if (
      prevState.showDatePicker &&
      !this.state.showDatePicker &&
      !isEqual(activeDateButton, date.type)
    ) {
      this.setState({
        activeDateButton: date.type,
      });
    }

    // Если filters (store) был сброшен, нужно синхронизировать состояние
    if (
      (activeDateButton && prevProps.date.type && !date.type) ||
      (!activeDateButton && date.type) ||
      prevProps.date.type !== date.type
    ) {
      this.setState({
        activeDateButton: date.type,
      });
    }
  }

  render() {
    const { showDatePicker } = this.state;
    const {
      customClass,
      withTime,
      filterKey,
      minDate,
      disabled,
      getTranslate,
    } = this.props;
    return (
      <div
        className={classNames(`date-filter date-filter_${filterKey}`, {
          [`${customClass}`]: !!customClass,
          'date-filter_disabled': disabled,
        })}
        ref={(el) => {
          this.dateFilterRef = el;
        }}>
        <div className='date-filter__block'>
          <div className='date-filter__block-content'>
            <div className='date-filter__block-inner'>
              {this.renderButtons()}
              <Panel
                isOpened={showDatePicker}
                customClass='ui-panel_quick-datepicker'
                excludeFromCloseTrigger={[
                  `.date-filter_${filterKey} .date-filter__calendar-open-button`,
                ]}
                onClose={() => this.toggleDatePicker(false)}
                modern
                title={getTranslate('filter.date')}>
                <DatePickerRange
                  range={this.getRange()}
                  showMonths={3}
                  withTime={withTime}
                  applyFormat={withTime ? 'datetime' : 'date'}
                  onApplyRange={this.applyDate}
                  minDate={minDate}
                  customClass='ui-date-picker-range_quick-filter'
                  isOpenedMenu={this.props.isMenuExpanded}
                />
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePanelPosition);
  }

  renderButtons = () => {
    const { mode, title, getTranslate } = this.props;
    const { activeDateButton } = this.state;
    const activeOption = this.options?.find(
      (option) => option.id === activeDateButton
    );

    if (mode === 'select') {
      return (
        <ButtonsGroup
          activeButtons={[activeDateButton]}
          onClick={this.selectDate}
          className='ui-buttons-group_single-choice ui-buttons-group_fixed-mobile'>
          <Button
            status='light'
            customClass='ui-button_no-border date-filter__calendar-open-button'
            id={this.customId}
            icon='im-Calendar'
          />
          <ButtonDropDown
            id='dateSelect'
            text={`${getTranslate(title)}`}
            position='right'
            status='light'
            isSingle={true}
            customClass={classNames(
              'ui-buttons-group__last-item button-drop-down_fixed-mobile',
              {
                'button-drop-down_selected': !!activeOption?.text,
              }
            )}
            items={this.options}
            selected={activeDateButton ? [activeDateButton] : []}
            onChange={(value) => {
              this.selectDate([value]);
            }}
          />
        </ButtonsGroup>
      );
    }
    return (
      <ButtonsGroup
        activeButtons={[activeDateButton]}
        onClick={this.selectDate}
        className='ui-buttons-group_single-choice'>
        <Button
          status='light'
          customClass='date-filter__calendar-open-button'
          id={this.customId}
          icon='im-Calendar'
        />
        {this.options.map((item) => (
          <Button status='light' id={item.id} text={item.text} key={item.id} />
        ))}
      </ButtonsGroup>
    );
  };

  /**
   * Обработчик нажатия на группу кнопок Choose date
   * @param selectedTypes
   */
  selectDate = (selectedTypes) => {
    const { filterKey, onChangeFilter, timezone } = this.props;
    const { activeDateButton, showDatePicker } = this.state;
    const [type] = selectedTypes;

    // Из-за особенностей работы кнопки custom, при повторном нажатии
    // происходит нe сброс фильтра, а открытие/закрытие календаря с ранее выбранными датами
    if (activeDateButton === this.customId && !type) {
      return this.toggleDatePicker(!showDatePicker);
    }

    this.setState({
      activeDateButton: type,
    });

    switch (type) {
      case PERIOD_TYPES.Today:
        onChangeFilter(filterKey, {
          type,
          values: this.prepareDate(DateHelpers.getDateWithTimezone(timezone)),
        });
        break;
      case PERIOD_TYPES.Yesterday:
        onChangeFilter(filterKey, {
          type,
          values: this.prepareDate(DateHelpers.subtractDays(1)),
        });
        break;
      case PERIOD_TYPES.LastWeek:
      case PERIOD_TYPES.LastMonth:
      case PERIOD_TYPES.LastQuarter:
      case PERIOD_TYPES.LastYear:
        onChangeFilter(filterKey, {
          type,
          values: DateHelpers.getPeriod(type, timezone),
        });
        break;
      case this.customId:
        this.toggleDatePicker(true);
        break;
      default:
        onChangeFilter(filterKey, {
          type: '',
          values: [],
        });
    }
  };

  /**
   * Преобразование объекта дат, когда выбираются интервалы с помощью кнопок today и yesterday
   * @param date
   */
  prepareDate = (date) => {
    return [
      DateHelpers.getFormat(DateHelpers.getStartDay(date)),
      DateHelpers.getFormat(DateHelpers.getEndDay(date)),
    ];
  };

  /**
   * Переключение состояния показа/скрытия календаря
   * @param showDatePicker
   */
  toggleDatePicker = (showDatePicker: boolean) => {
    this.setState(
      {
        showDatePicker,
      },
      () => {
        toggleOpenClass(`#${this.customId}`, showDatePicker);
        setTimeout(() => {
          if (
            showDatePicker &&
            window.innerWidth > breakpoints.quickFilters &&
            window.innerWidth <= breakpoints.commonDesktop
          ) {
            this.handlePanelPosition();
          }
        });
      }
    );
  };

  /**
   * Подготовка интервала дат для DatePickerRange.
   * Помогает избежать передачи в календарь значений из однодневного интервала
   * yesterday и today, когда они выбраны в качестве активного фильтра
   */
  getRange() {
    const { date } = this.props;
    if (date.type === 'custom') {
      return {
        from: date.values[0],
        to: date.values[1],
      };
    }
  }

  applyDate = (data) => {
    const { onApplyRange } = this.props;
    onApplyRange(data);
    setTimeout(() => this.toggleDatePicker(false), 0);
  };

  get options() {
    const { periodTypes, getTranslate } = this.props;
    return (
      periodTypes?.map((type) => ({
        id: type,
        text: getTranslate(`filters.fields.dateTime.${type}`),
      })) || []
    );
  }

  handlePanelPosition = () => {
    if (!this.dateFilterRef) return;

    const panelEl = this.dateFilterRef.querySelector(
      '.ui-panel_quick-datepicker'
    );
    if (!panelEl) return;

    if (
      window.innerWidth > breakpoints.commonDesktop ||
      window.innerWidth <= breakpoints.commonTablet
    ) {
      panelEl.style = '';
      return;
    }

    const menuWidth = this.props.isMenuExpanded
      ? MENU_OPENED_WIDTH
      : MENU_WIDTH;
    const leftPosition = panelEl.getBoundingClientRect().left;
    const minLeftPosition =
      window.innerWidth > breakpoints.commonTablet ? menuWidth + 10 : 0;
    panelEl.style.maxWidth = `${window.innerWidth - minLeftPosition - 30}px`;

    if (leftPosition < minLeftPosition) {
      panelEl.style.position = 'fixed';
      panelEl.style.top = 'unset';
      panelEl.style.right = 'unset';
      panelEl.style.left = `${(minLeftPosition + 5).toFixed()}px`;
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  timezone: state.user.timezone,
  isMenuExpanded: state.configuration.isMenuExpanded,
});

export default connect(mapStateToProps)(addTranslation(DateFilter));
