import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import request from 'helpers/request';

export const getRisks = (payload): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Rcs_ListBlackWhite,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};

export const getFraud = (payload): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Rcs_ListFraud,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};

export const createSingleRisk = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Rcs_CreateElement,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const createRiskByTransaction = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Rcs_CreateElementByTransaction,
        payload,
      },
    ],
  });
};

export const getRcsContentTypes = (transactionId): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Rcs_transactionContentTypes,
        payload: {
          transactionId,
        },
      },
    ],
  });
};

export const deleteRisk = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Rcs_DeleteElement,
        payload: {
          ...payload,
        },
      },
    ],
  });
};

export const bulkCreate = (file: null | File) => {
  return request({
    method: 'POST',
    url: 'rcs/bulk-create-element',
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload);
};

export const getCreateListAccessByProjects = (payload: {
  projectIds: string[];
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Rcs_ProjectContentType,
        payload,
      },
    ],
  });
};
