export const statusMapper = {
  on: 'on',
  off: 'off',
};

export const projectTabs = [
  {
    id: 'general',
    path: '',
  },
  {
    id: 'paymentMethods',
    path: 'payment-methods',
  },
  {
    id: 'callbacks',
    path: 'callbacks',
  },
  {
    id: 'redirectUrls',
    path: 'redirect-urls',
  },
  {
    id: 'ppDesigner',
    path: 'pp-designer',
  },
  {
    id: 'ppDesignerVersionFive',
    path: 'pp-designer-version-five',
  },
  {
    id: 'fiscalReceipt',
    path: 'fiscal-receipt',
  },
];

const getValue = (data, valueName, valueKey?) => {
  const source = data;
  return source[valueName] ? source[valueName][valueKey] : source[valueKey];
};

export const prepareData = (data) => {
  return {
    projectId: getValue(data, 'project', 'projectId'),
    redirectSuccess: {
      url: getValue(data, 'redirectSuccess', 'url'),
      mode: getValue(data, 'redirectSuccess', 'mode'),
      enabled: getValue(data, 'redirectSuccess', 'enabled'),
    },
    redirectFail: {
      url: getValue(data, 'redirectFail', 'url'),
      mode: getValue(data, 'redirectFail', 'mode'),
      enabled: getValue(data, 'redirectFail', 'enabled'),
    },
    redirectTokenize: {
      url: getValue(data, 'redirectTokenize', 'url'),
      mode: getValue(data, 'redirectTokenize', 'mode'),
      enabled: getValue(data, 'redirectTokenize', 'enabled'),
    },
    merchantBackSuccess: {
      url: getValue(data, 'merchantBackSuccess', 'url'),
      enabled: getValue(data, 'merchantBackSuccess', 'enabled'),
    },
    merchantBackFail: {
      url: getValue(data, 'merchantBackFail', 'url'),
      enabled: getValue(data, 'merchantBackFail', 'enabled'),
    },
  };
};
