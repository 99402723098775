import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';

import './browserItem.scss';
import { browserIcons } from './browserIcons';

interface Props {
  browserSrc: string;
  browserTitle: string;
  customClass?: string;
}

class BrowserItem extends PureComponent<Props> {
  render() {
    const { browserTitle, browserSrc, customClass } = this.props;

    return (
      <span className={classNames('browser-item', customClass)}>
        <span className='browser-item__icon'>
          {browserIcons[browserSrc] ? (
            <img src={browserIcons[browserSrc]} alt={browserTitle} />
          ) : (
            <Icon name='im-Question' size={8} />
          )}
        </span>
        <span className='browser-item__text'>{browserTitle}</span>
      </span>
    );
  }
}

export default BrowserItem;
