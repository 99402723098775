import { AccountingRequestItem } from '../types';

export const sortAccountingRequests = (requests: AccountingRequestItem[]) => {
  return requests.sort((a, b) => {
    if (a.codatStatus === 'request') {
      return -1;
    }

    if (b.codatStatus === 'request') {
      return 1;
    }

    if (a.codatStatus !== 'review') {
      return 1;
    }

    if (b.codatStatus !== 'review') {
      return -1;
    }

    return 0;
  });
};
