import { ErrorCallbackArgs } from '@codat/sdk-link-types';
import { closeModal, openModal } from 'actions/modal';
import { store } from 'store';

const { dispatch } = store;

export const runCodatProcess = (companyId: string) =>
  new Promise((resolve) => {
    const onClose = () => {
      resolve(false);
      dispatch(closeModal());
    };
    const onFinish = () => {
      resolve(true);
      dispatch(closeModal());
    };
    const onError = (error: ErrorCallbackArgs) =>
      console.error(`On error callback - ${error.message}`);

    const content = {
      companyId,
      onError,
      onClose,
      onFinish,
    };

    dispatch(
      openModal({
        modalId: 'CodatModal',
        needCloseButton: false,
        content,
        callback: resolve,
      })
    );
  });
