import filters from 'constants/filters';
import tableNames from 'constants/tableNames';

export default {
  payments: [filters.paymentHotCurrency],
  payouts: [filters.currencies],
  moto: [
    filters.currencies,
    filters.motoType,
    { name: filters.motoPaymentProject, offset: 999 },
  ],
  financeRealTime: ['balanceType', 'balanceCompany', 'balanceContract'],
  financeStatement: [
    'balanceType',
    'balanceCompany',
    'balanceContract',
    'balance',
    'balanceCurrency',
  ],
  currencyRates: ['currency', 'userMerchant'],
  profile: [filters.currencies, 'contactLanguage', 'interfaceLanguage'],
  remittancePayments: ['remittanceTransferCurrency'],
  remittanceRecipientAccountsBuilder: [
    'country',
    'remittanceCountry',
    'remittanceTransferCurrency',
    'remittanceProject',
  ],
  myTeam: ['userStatus'],
  admin: ['userStatusAdmin', 'roleToSee', 'wlList', 'project', 'merchant'],
  userBuilder: [
    'roleToSee',
    'roleToGrant',
    'roleWithPermissionToGrant',
    'userActiveLegalEntity',
  ],
  userBuilderAdmin: [
    'roleToSee',
    'roleToGrant',
    'roleWithPermissionToGrant',
    'wlList',
    'project',
    'merchant',
    'legalEntity',
  ],
  invoices: ['currency', 'invoiceStatus', 'enabledProject'],
  invoicesCreate: ['currency', 'motoType'],
  recurringSettings: ['subscriptionPaymentPeriod'],
  recurring: ['paymentHotCurrency'],
  reportsBuilder: [
    'reportTemplateIntervalBy',
    'reportType',
    'reportTemplateLimits',
  ],
  reports: ['reportType'],
  chargebacks: ['enabledProject', 'chargebackStage'],
  chargebacksNeedsResponse: ['enabledProject', 'chargebackStage'],
  chargebacksPreArbitration: ['enabledProject', 'chargebackStage'],
  chargebacksArbitration: ['enabledProject', 'chargebackStage'],
  chargebackCard: ['chargebackType'],
  [tableNames.fraud]: ['enabledProjectExternal'],
  [tableNames.rcs]: [
    'enabledProjectExternal',
    'userMerchant',
    'rcsContentType',
  ],
  [tableNames.bankAccount]: ['userActiveLegalEntity'],
  [tableNames.balanceTransfer]: ['userActiveLegalEntity'],
  [tableNames.settlementRequest]: ['userActiveLegalEntity'],
  projects: [
    'paymentMethodChannels',
    'paymentMethodStatuses',
    'paymentMethodOptions',
    'settingHistoryAction',
    'redirectUrlMode',
    'projectCallbackSettings',
    'paymentPageMerchantReturnState',
  ],
  userNotifications: ['userNotificationCategories', 'userNotificationTypes'],
  advancedAnalytics: [
    'enabledProject',
    'country',
    'paymentMethodType',
    'currency',
    'analyticsBrand',
    'paymentMethodGroup',
    'successRateTransactionType',
  ],
  help: ['feature'],
};
