import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { pickBy } from 'lodash-es';

import { addArticle, updateArticle } from 'api/help';
import showNotification from 'components/ui/notification/showNotification';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import { AnyObject } from 'types/Common';
import availableLanguages from 'constants/languages';
import MenuContext from 'components/ui/menuContext';
import Icon from 'components/ui/icon';
import CustomSelect from 'components/ui/customSelect';
import CopyToClipboard from 'components/ui/copyToClipboard';
import { StoreProps } from 'store';

import flagList from 'images/flags/flagList';
import svgIcons from 'images/icons';
import checkFilters from 'helpers/checkFilters';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import icons from 'constants/icons';
import { DictionaryCustomSelect } from 'types/FilterValue';
import SelectItem from 'types/SelectItem';
import './addHelpArticle.scss';

interface OwnProps {
  callback: (isNew, data) => void;
  content: {
    title: string;
    language: string;
    slug?: string;
    menuIcon?: string;
    articleId?: string | number;
    content: string;
    sortMenuIndex?: number;
    featureId?: number;
  };
}

interface ConnectedProps {
  articlesCount: number;
  features: DictionaryCustomSelect;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  title: string;
  language: string;
  isNew: boolean;
  slug: string;
  menuIcon: string;
  validationErrors: AnyObject;
  isLoading: boolean;
  selectedFeature: SelectItem | null;
}

class AddHelpArticle extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { content } = props;
    this.state = {
      isNew: this.isNew(),
      title: content?.title || '',
      language: content?.language || 'en',
      slug: content?.slug || '',
      menuIcon: content?.menuIcon || '',
      validationErrors: {},
      isLoading: false,
      selectedFeature: null,
    };
  }

  async componentDidMount() {
    await checkFilters([{ name: 'feature', limit: 100, offset: 0 }]);
    if (this.props.content) {
      const { content, features } = this.props;
      this.setState({
        selectedFeature:
          features?.list.find((item) => item.value === content.featureId) ||
          null,
      });
    }
  }

  render() {
    const { features } = this.props;
    const {
      title,
      menuIcon,
      slug,
      language,
      isNew,
      selectedFeature,
      isLoading,
      validationErrors,
    } = this.state;
    const options = this.getIconOptions();

    return (
      <div className='add-help-article'>
        {isLoading && <Loader />}
        <ModalTitle>{isNew ? 'Create' : 'Edit'}</ModalTitle>
        <ModalContent>
          <Form onSubmit={() => this.saveSection()}>
            <div className='add-help-article__row'>
              <div className='add-help-article__item add-help-article__item_lang'>
                <MenuContext
                  customClass='add-help-article__lang-select'
                  list={this.getLangOptions()}
                  error={validationErrors.language}
                  value={{
                    buttonContent: (
                      <div className='change-lang__item'>
                        <img
                          className='change-lang__icon'
                          src={flagList[language]}
                          alt={language}
                        />
                        <span className='change-lang__text'>{language}</span>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
            <div className='add-help-article__row'>
              <CustomSelect
                id='icon'
                customClass='add-help-article__item add-help-article__item_no-title add-help-article__item_icon ui-select_large'
                value={menuIcon && this.getIconValue(menuIcon, options)}
                options={options}
                onChange={({ value }) => this.changeField('menuIcon', value)}
                error={validationErrors.menuIcon}
              />
              <Input
                autoFocus
                id='sectionTitle'
                label='Title'
                value={title}
                placeholder='Enter value'
                onChange={({ target: { value } }) => {
                  this.changeField('title', value);
                }}
                customClass='add-help-article__item'
                error={validationErrors?.title}
              />
            </div>
            <div className='add-help-article__row'>
              <Input
                id='Slug'
                label='Slug'
                value={slug}
                placeholder='Enter slug'
                onChange={({ target: { value } }) => {
                  this.changeField('slug', value);
                }}
                customClass='add-help-article__item'
                error={validationErrors?.slug}
                disabled={!!this.props.content.slug}
              />
              {this.props.content.slug && (
                <CopyToClipboard
                  customClass='add-help-article__item_no-title'
                  text={this.props.content.slug}
                  iconSize={18}
                />
              )}
            </div>
            <div className='add-help-article__row'>
              <CustomSelect
                id='feature'
                customClass='add-help-article__item add-help-article__item_feature ui-select_large'
                label='Feature'
                value={selectedFeature}
                options={features.list}
                isLoading={features.isLoading}
                onChange={(value) => this.changeField('selectedFeature', value)}
                error={validationErrors.featureId}
              />
            </div>
          </Form>
        </ModalContent>
        <ModalFooter>
          <Button
            text='Save'
            status='success'
            onClick={() => this.saveSection()}
          />
        </ModalFooter>
      </div>
    );
  }

  getLangOptions = () => {
    const { language } = this.state;
    return availableLanguages.map((item) => {
      return {
        id: item,
        active: item === language,
        onClick: () => this.changeField('language', item),
        content: (
          <div
            className={classNames('change-lang__item', {
              'change-lang_active': item === language,
            })}>
            <img
              className='change-lang__icon'
              src={flagList[item]}
              alt={item}
            />
            <span className='change-lang__text'>{item}</span>
            <span className='change-lang__check-icon'>
              <Icon name='im-Check-mark' size={6} />
            </span>
          </div>
        ),
      };
    });
  };

  getIconValue = (icon, options) => {
    return options.find((option) => option.value === icon);
  };

  getIconOptions = () => {
    const createOption = (result, option) => {
      return [
        ...result,
        {
          label: <Icon name={option} size={12} />,
          value: option,
        },
      ];
    };

    return icons
      .reduce(createOption, [])
      .concat(Object.keys(svgIcons).reduce(createOption, []));
  };

  isNew = () => {
    const { content } = this.props;
    return !content.title;
  };

  changeField = (name, value) => {
    this.setState((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  saveSection = () => {
    const {
      content: { content, articleId, sortMenuIndex },
      callback,
      articlesCount,
    } = this.props;
    const { title, language, slug, isNew, menuIcon, selectedFeature } =
      this.state;

    const action = isNew ? addArticle : updateArticle;

    const options = pickBy({
      title,
      language,
      slug,
      contentDraft: content || '',
      menuIcon: menuIcon || '',
      articleId: articleId || '',
      sortMenuIndex:
        sortMenuIndex !== undefined ? sortMenuIndex : articlesCount,
      featureId: selectedFeature?.value,
    });
    try {
      this.setState({ isLoading: true });
      action(options)
        .then((data) => {
          callback(isNew, data);
        })
        .catch((error) => {
          this.setState({ validationErrors: error.payload?.validationErrors });
        });
    } catch (error) {
      const { payload } = error;
      showNotification({ status: 'error', content: payload });
    } finally {
      this.setState({ isLoading: false });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  articlesCount: state.help.articles.length,
  features: {
    ...state.filtersValues.feature,
    list: getCustomSelectItems({
      list: state.filtersValues.feature?.list || [],
    }),
  },
});

export default connect(mapStateToProps)(AddHelpArticle);
