import React from 'react';
import NoContent from 'components/ui/noContent';
import getLocalizedText from 'helpers/getLocalizedText';
import './errorFallback.scss';

export const ErrorFallback = () => (
  <div className='error-boundary'>
    <NoContent text={getLocalizedText('common.sectionError.text')} />
  </div>
);
