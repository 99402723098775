import DateHelpers from 'helpers/Date';
import DateFormats from 'constants/dateFormats';
import { IFormDescriptorItem } from 'types/Analytics';

export const SuccessRateParams: IFormDescriptorItem[] = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.tableTitle.label',
    placeholder: 'analytics.editForm.params.tableTitle.placeholder',
    type: 'input',
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
      },
    ],
  },
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
    renderOptions: {
      minDate: DateHelpers.createDate('01.01.2021', 'date').format(
        DateFormats.datetime
      ),
    },
  },
];

export const SuccessRateFilter: IFormDescriptorItem[] = [
  {
    id: 'projectId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'issuerBankCountryName',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.issuerBankCountryName.label',
    dictionaryKey: 'country',
  },
  {
    id: 'transactionType',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.transactionType.label',
    dictionaryKey: 'successRateTransactionType',
  },
  {
    id: 'paymentMethodGroup',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupBy.paymentMethodGroup.item',
    dictionaryKey: 'paymentMethodGroup',
  },
  {
    id: 'paymentMethodType',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupBy.paymentMethodType.item',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'currency',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.currency.label',
    dictionaryKey: 'currency',
  },
];
