import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setHomePageCurrency } from 'api/home';
import { setCurrentCurrency } from 'actions/home';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';

export const CurrencySelect: React.FC = () => {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(
    (state: RootState) => state.home.currentCurrency
  );

  const saveSelectedCurrency = async (currency: string) => {
    try {
      await setHomePageCurrency(currency);
    } catch (err) {
      dispatch(setCurrentCurrency({ currency: selectedCurrency }));
      console.error(err);
    }
  };

  const handleCurrencyChange = (state: string[]) => {
    const currency = state[0];
    saveSelectedCurrency(currency);
    dispatch(setCurrentCurrency({ currency }));
  };

  return (
    <ButtonsGroup
      mode='singleRequired'
      activeButtons={[selectedCurrency]}
      onClick={handleCurrencyChange}>
      <Button id='GBP' text='GBP' />
      <Button id='EUR' text='EUR' />
      <Button id='USD' text='USD' />
    </ButtonsGroup>
  );
};
