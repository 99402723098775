import React from 'react';
import {
  List,
  CellMeasurerCache,
  CellMeasurer,
  InfiniteLoader,
} from 'react-virtualized';
import { components } from 'react-select';
import InfoIcon from 'components/ui/infoIcon';
import Icon from 'components/ui/icon';
import getLocalizedText from 'helpers/getLocalizedText';
import SelectItem from 'types/SelectItem';
import classNames from 'classnames';

export const ControlComponent = (props) => {
  const { selectProps, children } = props;
  const {
    modern,
    tooltip,
    mode,
    value,
    placeholder,
    tooltipId,
    tooltipPosition,
  } = selectProps;

  const needTooltip = modern && tooltip;
  const isLightTheme = mode === 'light' && value?.value;
  const label = isLightTheme ? `${getLocalizedText(placeholder)} ` : '';

  return (
    <components.Control
      {...props}
      className={needTooltip ? 'ui-select__control_tooltip' : ''}>
      <span className='ui-select__value-wrapper'>
        {label}
        {children}
      </span>
      {needTooltip && (
        <InfoIcon
          icon='im-Info'
          size={16}
          tooltip={tooltip || ''}
          tooltipId={tooltipId}
          dataPlace={tooltipPosition || 'top'}
          customClass='ui-select__info'
        />
      )}
    </components.Control>
  );
};

interface State {
  scrollToIndex: number | undefined;
  prevValue: string;
}

interface MenuListComponentProps {
  options: SelectItem[];
  selectProps: {
    id: string;
    modern?: boolean;
    isLoading?: boolean;
    isLoadMore?: boolean;
    autoScrollToSelectedOption?: boolean;
    customClass?: string;
    hasValue?: boolean;
    noOptionsMessage: () => React.ReactNode;
    loadMore?: () => void;
  };
  getValue: () => any;
  children: any;
  maxHeight: number;
}

export const SELECT_ITEM_HEIGHT = 24;
export const MODERN_SELECT_ITEM_HEIGHT = 34;
export class MenuListComponent extends React.Component<
  MenuListComponentProps,
  State
> {
  optionHeight: number;
  cache: CellMeasurerCache;

  constructor(props) {
    super(props);

    this.state = {
      scrollToIndex: undefined,
      prevValue: '',
    };

    const optionHeight = props.selectProps.modern
      ? MODERN_SELECT_ITEM_HEIGHT
      : SELECT_ITEM_HEIGHT;

    this.optionHeight = optionHeight;
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: optionHeight,
      keyMapper: (index) => props.children[index]?.key,
    });
  }

  componentDidUpdate() {}

  getSnapshotBeforeUpdate(prevProps: Readonly<MenuListComponentProps>) {
    const childrenChanged =
      this.props.children.length !== prevProps.children.length;

    if (childrenChanged) {
      this.cache.clearAll();
    }

    return null;
  }

  static getDerivedStateFromProps(
    props: Readonly<MenuListComponentProps>,
    state: Readonly<State>
  ) {
    const { selectProps, getValue, options } = props;
    const { autoScrollToSelectedOption, hasValue } = selectProps;
    const selectedValue = getValue()[0]?.value;

    if (!autoScrollToSelectedOption || !hasValue) {
      return null;
    }

    if (state.prevValue === selectedValue) {
      return null;
    }

    const selectedIndex = options.findIndex(
      ({ value }) => value === selectedValue
    );

    const scrollToIndex = selectedIndex >= 0 ? selectedIndex + 1 : 0;

    return {
      prevValue: selectedValue,
      scrollToIndex,
    };
  }

  renderRow = ({ index, parent, style }) => {
    const { children } = this.props;
    const item = children[index];

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={item.key}
        rowIndex={index}
        parent={parent}>
        {({ registerChild }) => (
          <div
            ref={registerChild}
            className='ui-select__option-wrapper'
            style={style}
            key={index}>
            {item}
          </div>
        )}
      </CellMeasurer>
    );
  };

  loadMoreRows = () => {
    const {
      selectProps: { isLoadMore, loadMore },
    } = this.props;

    if (isLoadMore && loadMore) {
      loadMore();
    }
  };

  render() {
    const { scrollToIndex } = this.state;
    const {
      children,
      maxHeight,
      selectProps: { noOptionsMessage, isLoading },
    } = this.props;

    const rowCount = children.length;
    const height = rowCount < 6 ? rowCount * this.optionHeight + 10 : maxHeight;
    const isNoOptions =
      !rowCount ||
      (rowCount === 1 && children?.props?.children === 'No options');

    return (
      <>
        {isNoOptions ? (
          <span className='ui-select__empty-list'>
            {noOptionsMessage() ||
              getLocalizedText('common.selectSingle.noOptions')}
          </span>
        ) : (
          <>
            <InfiniteLoader
              isRowLoaded={({ index }) => !!children[index]}
              loadMoreRows={this.loadMoreRows}
              threshold={2}
              rowCount={rowCount + 1}>
              {({ onRowsRendered, registerChild }) => (
                <List
                  id='menu-list'
                  height={height}
                  width={300} //width is rewritten in the stylesheet
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowCount={rowCount}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.renderRow}
                  scrollToIndex={scrollToIndex}
                  scrollToAlignment='center'
                />
              )}
            </InfiniteLoader>
            {isLoading && (
              <div className='ui-select__loader-wrapper'>
                <LoadingIndicatorComponent />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export const DropdownIndicatorComponent = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.modern ? (
        <Icon size={6} name='dropdownTriangle' />
      ) : (
        <Icon size={8} name='im-Arrow-down' />
      )}
    </components.DropdownIndicator>
  );
};

export const LoadingIndicatorComponent = () => {
  return (
    <Icon size={18} name='im-Ellipse' className='ui-select__loader rotating' />
  );
};

export const OptionComponent = (props) => {
  const { data, children, selectProps } = props;

  return (
    <components.Option
      {...props}
      className={data.isLastFavorite ? 'ui-select__option_favorite' : ''}>
      <div className='ui-select__option-value'>
        <div className='ui-select__option-value-inner'>{children}</div>
      </div>
      {!selectProps.modern && (
        <span className='ui-select__icon-checked'>
          <Icon size={6} name='im-Check-mark' />
        </span>
      )}
    </components.Option>
  );
};

export const MultiValueLabelComponent = (props) => {
  return (
    <span className='ui-select__multi-item'>
      <components.MultiValueLabel {...props} />
    </span>
  );
};

export const MultiValueRemoveComponent = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name='close' size={12} />
    </components.MultiValueRemove>
  );
};

export const MenuPortalComponent = (props) => {
  const { selectProps, children } = props;
  const { modern, customClass } = selectProps;

  return (
    <components.MenuPortal
      {...props}
      className={classNames(customClass, { 'ui-select_modern': modern })}>
      {children}
    </components.MenuPortal>
  );
};
