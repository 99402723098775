import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import HelpArticle from '../../types';
import './helpMenuTab.scss';
import Icon from '../../../../components/ui/icon';

interface Props extends WithPermissions, IntlProps {
  currentSlug?: string;
  hash: string;
  isFetched: boolean;
  showSubmenu: boolean;
  articles: HelpArticle[];
  onOpenModal: () => void;
  onChangePosition: ({ oldIndex, newIndex }) => void;
  onClick: Function;
}

class HelpMenuTab extends PureComponent<Props> {
  componentDidMount() {
    const scroller = document.getElementById('help-menu-scroller');
    scroller?.addEventListener('scroll', (e) => {
      const target = e.currentTarget as HTMLElement;
      const previous = document.getElementById(
        'previous-article'
      ) as HTMLElement;
      const previousBackground = document.getElementsByClassName(
        'arrow-previous-background'
      )[0] as HTMLElement;

      if (target?.scrollLeft <= 11) {
        previous.style.display = 'none';
        previousBackground.style.display = 'none';
      } else {
        previous.style.display = 'flex';
        previousBackground.style.display = 'block';
      }

      const next = document.getElementById('next-article') as HTMLElement;
      const nextBackground = document.getElementsByClassName(
        'arrow-next-background'
      )[0] as HTMLElement;

      if (target?.scrollLeft >= target?.scrollWidth - target?.offsetWidth - 5) {
        next.style.display = 'none';
        nextBackground.style.display = 'none';
      } else {
        next.style.display = 'flex';
        nextBackground.style.display = 'block';
      }
    });

    const scrollToPrevPage = () => {
      let element: HTMLElement = document.getElementsByClassName(
        'saved-entities__item_active'
      )[0] as HTMLElement;
      let previousElement: HTMLElement =
        element.previousElementSibling as HTMLElement;
      scroller?.scrollBy(-previousElement.clientWidth, 0);
      previousElement?.click();
    };
    document
      .getElementById('previous-article')
      ?.addEventListener('click', scrollToPrevPage);

    const scrollToNextPage = () => {
      let element: HTMLElement = document.getElementsByClassName(
        'saved-entities__item_active'
      )[0] as HTMLElement;
      let nextElement = element.nextElementSibling as HTMLElement;
      scroller?.scrollBy(nextElement.clientWidth || 100, 0);
      nextElement?.click();
    };
    document
      .getElementById('next-article')
      ?.addEventListener('click', scrollToNextPage);
  }

  render() {
    const { articles, currentSlug, onClick } = this.props;
    return (
      <div className='saved-entities saved-entities_switcher saved-entities_help-tabs'>
        <div id='previous-article' className='saved-entities_help-tabs-arrow'>
          <Icon name={'im-Arrow-left-Option-2'} size={10} color={'black'} />
        </div>
        <ul
          id='help-menu-scroller'
          className='saved-entities__list saved-entities__list-help'>
          {articles.map((item, index) => (
            <li
              key={index}
              className={classNames(
                'saved-entities__item saved-entities__item_fixed',
                {
                  'saved-entities__item_active': currentSlug === item.slug,
                }
              )}
              onClick={() => onClick(item.slug)}>
              <span className='saved-entities__item-outer'>
                <span className='saved-entities__item-inner utils-overflow-dots'>
                  {item.title}
                </span>
              </span>
            </li>
          ))}
        </ul>
        <div className={'arrow-background arrow-next-background'}></div>
        <div className={'arrow-background arrow-previous-background'}></div>
        <div
          id={'next-article'}
          className={
            'saved-entities_help-tabs-arrow saved-entities_help-tabs-arrow_next'
          }>
          <Icon name={'im-Arrow-right-Option-2'} size={10} color={'black'} />
        </div>
      </div>
    );
  }
}

export default addTranslation(addPermissions(HelpMenuTab));
