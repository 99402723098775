import { MafPageTabManagerState, MafTab } from 'components/maf/types';
import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState = {
  currentPageIndex: 0,
  pages: [],
  isPageTabMode: false,
};

export const mafPageTabManager = (
  state: MafPageTabManagerState = initialState,
  action: ActionReducer<{
    currentPageIndex: number;
    pages: MafTab[];
    isPageTabMode: boolean;
  }>
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_CURRENT_MAF_PAGE_INDEX:
      return {
        ...state,
        currentPageIndex: payload.currentPageIndex,
      };
    case ACTIONS.SET_MAF_PAGES:
      return {
        ...state,
        pages: payload.pages,
      };
    case ACTIONS.SET_MAF_PAGES_MODE:
      return {
        ...state,
        isPageTabMode: payload.isPageTabMode,
      };
    default:
      return state;
  }
};

export default mafPageTabManager;
