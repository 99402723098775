import React, { FC, ReactNode } from 'react';

import Icon from 'components/ui/icon';

interface Props {
  icon: 'ok' | 'wait' | 'fail';
  title?: ReactNode;
  actions?: ReactNode;
}

const ICON_CLASS: Record<Props['icon'], string> = {
  ok: 'Check-mark',
  wait: 'Timezone',
  fail: 'Close',
};

export const StatusBox: FC<Props> = ({ icon, title, children, actions }) => (
  <div className='statusbox utils-align'>
    <div className={`utils-align statusbox__icon statusbox__icon_${icon}`}>
      <Icon name={`im-${ICON_CLASS[icon]}`} size={20} />
    </div>
    {title && <div className='statusbox__title'>{title}</div>}
    <div className='statusbox__content'>{children}</div>
    {actions && <div className='statusbox__actions'>{actions}</div>}
  </div>
);
