import { Props } from './types';
import { useMafPagesToolkit } from '../PagesTabManager/hooks';
import MafFormLegacy from './MafFormLegacy';

export const MafForm = (props: Props) => {
  const { goToPage } = useMafPagesToolkit();
  const isPageTabMode = props.isPageTabMode;

  const withBreadCrumbs = Boolean(props.formData?.__collapsed || isPageTabMode);

  return (
    <MafFormLegacy
      {...props}
      goToPage={goToPage}
      withBreadCrumbs={withBreadCrumbs}
    />
  );
};
