import React, { PureComponent } from 'react';
import { isEmpty, isEqual, map } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Checkbox from 'components/ui/checkbox';
import PermissionsTreeType from 'types/PermissionsTree';
import IconMapper from 'components/permissionsTree/iconMapper';
import './permissionsTree.scss';
import classNames from 'classnames';
import RoundButton from 'components/ui/roundButton';
import TextBlock from 'components/ui/textBlock';
import Chip from 'components/ui/chip';
import Button from 'components/ui/button';

interface Props extends IntlProps {
  roles: {
    id: string;
    isSelected: boolean;
    text: string;
  }[];
  userRoles: string[];
  tree: PermissionsTreeType;
  permissionsDisabled?: string[];
  isAdmin: boolean;
  isEditing: boolean;
  onChangePermission: (key: string) => void;
  onChangeRole: (key: string, value) => void;
  toggleRolesPermissions: (isOpen: boolean, isApply?: boolean) => void;
  checkPermissionsChanges: () => void;
  canEdit?: boolean;
  customClass?: string;
}

interface State {
  hiddenGroups: string[];
}

const disabledRoles = ['internal_qa', 'internal_localization'];

class PermissionsTree extends PureComponent<Props, State> {
  static defaultProps = {
    canEdit: true,
  } as const;

  constructor(props) {
    super(props);
    this.state = {
      hiddenGroups: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (
      !isEqual(prevProps.tree, this.props.tree) &&
      !isEmpty(this.props.tree)
    ) {
      Object.keys(this.props.tree).forEach((item) => {
        if (!prevProps.tree[item] && this.state.hiddenGroups.includes(item)) {
          this.setState((state) => ({
            hiddenGroups: state.hiddenGroups.filter(
              (hiddenItem) => item !== hiddenItem
            ),
          }));
        }
      });
    }
  }

  render() {
    const {
      tree,
      onChangePermission,
      permissionsDisabled,
      getTranslate,
      canEdit,
      customClass,
    } = this.props;

    return (
      <div className={classNames('permissions-tree', customClass)}>
        <RoundButton
          icon='im-Close'
          size={26}
          customClass='user-builder__mobile-item user-builder__permissions-close'
          onClick={this.props.checkPermissionsChanges}
        />
        {this.renderRoles()}
        {isEmpty(tree) && (
          <div className='permissions-tree__empty'>
            {getTranslate('myteam.adduser.selectRoles.text')}
          </div>
        )}
        {map(tree, (data, key) => {
          return (
            <div
              key={key}
              className={classNames('permissions-tree__section', {
                'permissions-tree__section_hidden':
                  this.state.hiddenGroups.includes(data.id),
              })}>
              <RoundButton
                icon={IconMapper[data.id]}
                customClass='permissions-tree__icon'
                size={35}
                onClick={() => this.toggleGroup(data.id)}
              />
              <div className='permissions-tree__header'>
                <span className='permissions-tree__title'>
                  {getTranslate(data.text)}
                </span>
                <span className='permissions-tree__subtext'>
                  {`${this.getSelectedLength(data)}/${
                    Object.keys(data.permissions).length
                  } ${getTranslate('myteam.adduser.selectedPermissions')}`}
                </span>
              </div>
              {map(data.permissions, (permission, id) => {
                return (
                  <div key={id} className='permissions-tree__item'>
                    <Checkbox
                      id={id}
                      disabled={!canEdit}
                      text={getTranslate(permission.text)}
                      checked={!(permissionsDisabled ?? []).includes(id)}
                      onChange={() => onChangePermission(id)}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
        {!!this.props.roles.filter((item) => item.isSelected).length && (
          <div className='user-builder__mobile-item user-builder__permissions-footer'>
            <Button
              text={this.props.getTranslate(
                'team.createuser.permissions.continue'
              )}
              status='primary'
              size='normal'
              customClass='ui-button_full-width'
              onClick={() => this.props.toggleRolesPermissions(false, true)}
            />
          </div>
        )}
      </div>
    );
  }

  renderRoles = () => {
    const { userRoles, roles, isAdmin, isEditing, getTranslate } = this.props;
    if (!roles.length) return null;
    return (
      <>
        {userRoles.includes('internal_admin') && (
          <div className='user-builder__info'>
            <TextBlock
              status='error'
              text={getTranslate('myteam.adduser.role.internalRoles.text')}
              iconEnabled
            />
          </div>
        )}
        <div className='user-builder__roles'>
          <Chip
            key='all-roles'
            text={getTranslate('myteam.adduser.selectAll.label')}
            id='all-roles'
            isSelected={roles.every(({ isSelected }) => isSelected)}
            isDisabled={!this.props.canEdit}
            theme='brand'
            type='tag'
            onClick={this.toggleAllRoles}
            customClass='user-builder__roles-item'
          />
          {roles.map((item) => {
            return (
              <Chip
                key={item.id}
                text={item.text}
                id={item.id}
                isSelected={item.isSelected}
                theme='brand'
                type='tag'
                onClick={this.onChangeRole}
                customClass='user-builder__roles-item'
                isDisabled={
                  !this.props.canEdit ||
                  (!isAdmin && isEditing && disabledRoles.includes(item.id))
                }
              />
            );
          })}
        </div>
      </>
    );
  };

  toggleAllRoles = () => {
    const { roles, isAdmin, isEditing, onChangeRole } = this.props;

    const isOn = roles.some((item) => !item.isSelected);
    const items = roles.map((item) => ({
      ...item,
      isSelected: isOn
        ? isOn
        : disabledRoles.includes(item.id) && !isAdmin && isEditing,
    }));

    onChangeRole('roles', items);
  };

  onChangeRole = (_, id) => {
    const items = this.props.roles.map((item) => ({
      ...item,
      isSelected: item.id === id ? !item.isSelected : item.isSelected,
    }));

    this.props.onChangeRole('roles', items);
  };

  getSelectedLength = (data) => {
    return Object.keys(data.permissions).filter((item) => {
      return !this.props.permissionsDisabled?.includes(
        data.permissions[item].id
      );
    }).length;
  };

  toggleGroup = (id) => {
    this.setState(({ hiddenGroups }) => {
      let newHiddenGroups;
      if (hiddenGroups.includes(id)) {
        newHiddenGroups = hiddenGroups.filter((item) => item !== id);
      } else {
        newHiddenGroups = [...hiddenGroups, id];
      }
      return {
        hiddenGroups: newHiddenGroups,
      };
    });
  };
}

export default addTranslation(PermissionsTree);
