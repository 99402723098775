import { openModal } from 'actions/modal';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import { useTranslation } from 'hooks';
import { useDispatch } from 'react-redux';

type Props = {
  customClass?: string;
  isAdmin?: boolean;
  isOnlyOneMerchantSelected?: boolean;
  hasSelectedUsers?: boolean;
};

export const BunchUserEditButton: React.FC<Props> = ({
  customClass,
  isOnlyOneMerchantSelected,
  hasSelectedUsers,
}) => {
  const getTranslate = useTranslation();
  const dispatch = useDispatch();

  const getDisabledHint = ({ noSelectedUsers, noSelectedMerchants }) => {
    if (noSelectedMerchants) {
      return getTranslate('myteam.bunchUserEdit.noSelectedMerchants');
    }
    if (noSelectedUsers) {
      return getTranslate('myteam.bunchUserEdit.noSelectedUsers');
    }
  };

  const disabled = !hasSelectedUsers || !isOnlyOneMerchantSelected;
  const disabledHint = getDisabledHint({
    noSelectedUsers: !hasSelectedUsers,
    noSelectedMerchants: !isOnlyOneMerchantSelected,
  });

  return (
    <>
      <div>
        <Button
          status='primary'
          onClick={() => {
            dispatch(
              openModal({
                modalId: 'ButchUserEdit',
              })
            );
          }}
          text={getTranslate('myteam.bunchUserEdit.button')}
          disabled={disabled}
          customClass={customClass}
          tooltip={disabled ? disabledHint : undefined}
        />
      </div>
      {disabled && (
        <Icon name='im-Info' size={14} tooltip={disabledHint} disabled />
      )}
    </>
  );
};
