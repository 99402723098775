enum tableNames {
  payments = 'payments',
  payouts = 'payouts',
  massPayouts = 'massPayouts',
  batch = 'batch',
  rcs = 'rcsBlackWhite',
  fraud = 'rcsFraud',
  references = 'paymentOperationReference',
  teamUsers = 'teamUsers',
  users = 'users',
  teamActivities = 'teamActivities',
  subscriptionPayments = 'subscriptionPayments',
  subscriptionPaymentsSettingsHistory = 'subscriptionPaymentsSettingsHistory',
  chargebacks = 'chargebacks',
  chargebacksNeedsResponse = 'chargebacksNeedsResponse',
  chargebacksPreArbitration = 'chargebacksPreArbitration',
  chargebacksArbitration = 'chargebacksArbitration',
  reports = 'reports',
  apsIcon = 'apsIcon',
  balanceRealTime = 'balanceRealTime',
  balanceStatement = 'balanceStatement',
  financialReport = 'financialReport',
  financialFile = 'financialFile',
  currencyRates = 'currencyRates',
  projectCallbacks = 'projectCallbacks',
  remittanceRecipientAccounts = 'remittanceRecipientAccounts',
  remittancePayments = 'remittancePayments',
  massRemittancePayments = 'massRemittancePayments',
  remittancePaymentBatch = 'remittancePaymentBatch',
  invoices = 'invoices',
  merchantLegalEntities = 'merchantLegalEntities',
  chbHistory = 'chbHistory',
  bankAccount = 'bankAccount',
  balanceTransfer = 'balanceTransfer',
  settlementRequest = 'settlementRequest',
  accountingRequests = 'accountingRequests',
  batchPreview = 'batchPreview',
  reconciliation = 'reconciliation',
  lcManage = 'lcManage',
  lcUpload = 'lcUpload',
}

export default tableNames;
