import { statusMapper } from 'pages/chargebacks/statuses';

export default (data, type) => {
  if (type === 'chargeback') {
    return {
      type: 'chargeback',
      id: data.chargebackId,
      status: data.status,
      value: data.arn,
      label: 'ARN',
      amount: data.chargebackCurrentStageAmount,
      currency: data.chargebackCurrentStageCurrency,
      isNeedCheckbox: data.status === statusMapper.needsResponse,
      isSelected: data.isSelected,
    };
  }
  return {
    id: data.operationId,
    operationType: data.operationType,
    status: data.operationStatus,
    fraud: data.fraud,
    value: data.operationId,
    label: 'ID',
    amount: data.operationAmount,
    currency: data.operationCurrency,
    updatedDate: data.updatedDate,
    chargeback: data.with_chargeback,
    recurring: data.mapped_recurring_retry_status,
  };
};
