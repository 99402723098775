import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteRisk, getFraud, getRisks } from 'api/risks';
import { changeQuickFilter, initQuickFilters } from 'actions/quickFilters';
import getConfigurationByName from 'selectors/getConfigurationByName';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import RisksPage from './RisksPage';
import HelpDeskHelpers from 'components/helpDesk/HelpDeskHelpers';
import getSelectionListItems from 'creators/getSelectionListItems';
import path from 'helpers/path';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import Messages from 'constants/rpcTypes';
import tableNames from 'constants/tableNames';
import filtersKeys from 'constants/filters';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import LocalStorage from 'helpers/LocalStorage';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { checkSelectedRcsProjects } from 'pages/risks/helpers';
import { RootState, StoreProps } from 'store';
import { tableRoutes } from './routesMapper';
import { PROJECTS_LIMIT } from './constants';

interface OwnProps {
  configuration: AnyObject;
}

interface ConnectedProps {
  state: RootState;
  selectedRcsQuickFilters: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ id?: string }>;

interface State {
  tableName: tableNames;
  isLoading: boolean;
  merchantProjects: Dictionary | null;
}

@addListeners([Messages.Rcs_DeleteElement, Messages.Confirm_Reject])
class RisksContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const tableName = window['__riskTableName'] || tableNames.fraud;

    this.state = {
      tableName:
        props.match.params.id === tableRoutes[tableNames.rcs]
          ? tableNames.rcs
          : tableName,
      isLoading: false,
      merchantProjects: null,
    };

    if (
      this.state.tableName === tableNames.rcs &&
      props.match.params.id !== tableRoutes[tableNames.rcs]
    ) {
      props.history.replace(path(`risks/${tableRoutes[tableNames.rcs]}`));
    }

    HelpDeskHelpers.setCurrentTab.call(this, {
      riskTable: tableName,
    });
  }

  componentDidMount() {
    const {
      state: { quickFilters },
    } = this.props;
    const { tableName } = this.state;
    const merchantId = quickFilters[tableNames.rcs].merchantId;

    if (tableName === tableNames.rcs && merchantId) {
      this.getMerchantProjects(merchantId);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const {
      state: {
        quickFilters: prevQuickFilters,
        filtersValues: prevFiltersValues,
      },
    } = prevProps;
    const {
      state: {
        quickFilters: currentQuickFilters,
        filtersValues: currentFiltersValues,
      },
    } = this.props;
    const { tableName } = this.state;
    const prevParamsId = prevProps.match.params.id;
    const currentParamsId = this.props.match.params.id;

    if (prevParamsId !== currentParamsId) {
      if (!currentParamsId) {
        this.setState({ tableName: tableNames.fraud });
      } else if (currentParamsId === tableRoutes[tableNames.rcs]) {
        this.setState({ tableName: tableNames.rcs });
      }
    }

    if (prevState.tableName !== tableName) {
      window['__riskTableName'] = tableName;
      HelpDeskHelpers.setCurrentTab.call(this, {
        riskTable: tableName,
      });
    }

    if (
      !prevFiltersValues?.userMerchant?.isFetched &&
      currentFiltersValues?.userMerchant?.isFetched
    ) {
      this.setInitFilters(tableName, true);
    }

    if (
      tableName === tableNames.rcs &&
      (prevState.tableName !== tableName ||
        prevQuickFilters[tableName]?.isReady !==
          currentQuickFilters[tableName]?.isReady)
    ) {
      if (currentQuickFilters[tableNames.rcs]?.isReady) {
        this.getMerchantProjects(
          currentQuickFilters[tableNames.rcs].merchantId
        );
      } else {
        this.setInitFilters(tableName, true);
      }
    }

    const prevBlackWhiteFilters = prevQuickFilters?.[tableNames.rcs];

    if (
      tableName === tableNames.rcs &&
      prevBlackWhiteFilters &&
      prevBlackWhiteFilters.isReady &&
      prevBlackWhiteFilters[filtersKeys.riskMerchantId] !==
        currentQuickFilters[tableNames.rcs][filtersKeys.riskMerchantId]
    ) {
      this.getMerchantProjects(
        currentQuickFilters[tableNames.rcs][filtersKeys.riskMerchantId]
      );
    }
  }

  changeRouteForTable = (tableName) => {
    const { history, match } = this.props;
    //table name is setting to state in componentDidUpdate
    if (tableName === tableNames.rcs) {
      history.push(path(`/risks/${tableRoutes[tableNames.rcs]}`));
    } else if (match.params.id) {
      history.push(path('/risks'));
    }
  };

  setInitFilters = (tableName, isInitial = false) => {
    const {
      state: { filtersValues },
      dispatch,
    } = this.props;
    const { userMerchant, enabledProjectExternal } = filtersValues || {};

    if (
      tableName !== tableNames.rcs ||
      !userMerchant?.isFetched ||
      !enabledProjectExternal?.isFetched
    )
      return;

    const merchantId = userMerchant?.list?.[0].id;
    const projects = this.getMerchantProjects(merchantId, isInitial);

    dispatch(
      initQuickFilters({
        name: tableNames.rcs,
        content: {
          isReady: true,
          [filtersKeys.riskMerchantId]: merchantId,
          [filtersKeys.riskProjectIds]: projects,
          [filtersKeys.riskListType]: [],
          [filtersKeys.riskValue]: [],
          [filtersKeys.risksCreatedAt]: { values: [], type: '' },
          [filtersKeys.riskContentType]: isInitial
            ? 'pan'
            : LocalStorage.get('riskCategory'),
        },
      })
    );
  };

  getMerchantProjects = (merchantId, isInitial = false) => {
    const {
      state: {
        filtersValues: { userMerchant, enabledProjectExternal },
      },
      dispatch,
    } = this.props;
    const { tableName } = this.state;

    if (!merchantId || tableName !== tableNames.rcs) return;

    const merchant = userMerchant?.list?.find((item) => item.id === merchantId);

    const projects = (merchant.projectId || []).map((id) => {
      return enabledProjectExternal?.list?.find(
        (project) => +project.id === +id
      );
    });

    this.setState({
      merchantProjects: {
        isFetched: true,
        isLoading: false,
        hasMoreRows: false,
        list: getSelectionListItems({
          originalList: projects,
          selectedList: [],
        }),
      },
    });

    const projectIds =
      projects
        .filter((item, i) => i < PROJECTS_LIMIT && item.id)
        .map((item) => item.id) || [];

    if (isInitial) {
      return projectIds;
    }

    dispatch(
      changeQuickFilter(tableNames.rcs, filtersKeys.riskProjectIds, projectIds)
    );
  };

  changeRcsProjects = (key, value) => {
    if (this.state.tableName === tableNames.fraud) {
      return false;
    }

    return checkSelectedRcsProjects(value);
  };

  onDeleteItem = async (e, data) => {
    e.stopPropagation();

    if (isNotAvailableForSupport(Messages.Rcs_DeleteElement)) return;

    this.setState({ isLoading: true });

    try {
      await deleteRisk(data);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onRowClick = (data) => {
    const { history } = this.props;
    const { tableName } = this.state;

    if (tableName === tableNames.rcs || !data.transactionId) return;
    history.push(path(getPathUrl(tableName, data)));
  };

  checkSelectedFilters = () => {
    const { selectedRcsQuickFilters, state } = this.props;
    const { tableName, merchantProjects } = this.state;
    const quickFiltersKeys = Object.keys(selectedRcsQuickFilters);

    const selected = quickFiltersKeys.filter((key) => {
      if (tableName === tableNames.rcs && quickFiltersKeys.length === 3) {
        const merchantList = state.filtersValues.userMerchant?.list || [];

        if (key === filtersKeys.riskProjectIds) {
          if (
            merchantProjects &&
            merchantProjects.list.length > PROJECTS_LIMIT
          ) {
            return selectedRcsQuickFilters[key].length !== PROJECTS_LIMIT;
          }

          return (
            selectedRcsQuickFilters[key].length !==
            merchantProjects?.list.length
          );
        } else if (key === filtersKeys.riskMerchantId) {
          return selectedRcsQuickFilters[key] !== merchantList[0]?.id;
        } else if (key === filtersKeys.riskContentType) {
          return selectedRcsQuickFilters[key] !== 'pan';
        }
      }

      return true;
    });

    return Boolean(selected.length);
  };

  onChangeQuickFilters = (filterName, value) => {
    if (filterName === filtersKeys.riskContentType) {
      this.setValueToLocalStorage(value);
    }
  };

  setValueToLocalStorage = (value = 'pan') => {
    LocalStorage.set('riskCategory', value);
  };

  onReset = () => {
    this.setInitFilters(this.state.tableName, true);
    this.setValueToLocalStorage();
  };

  onEvent = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { tableName, merchantProjects, isLoading } = this.state;
    const { state, match, location, history } = this.props;
    const {
      params: { id },
    } = match;
    const isBlackWhite = tableName === tableNames.rcs;

    return (
      <RisksPage
        id={id !== tableRoutes[tableNames.rcs] ? id : ''}
        tableName={tableName}
        isLoading={isLoading}
        canRenderTable={state.quickFilters[tableName].isReady}
        apiRequest={isBlackWhite ? getRisks : getFraud}
        configuration={getConfigurationByName(state, tableName)}
        onDeleteItem={(e, id) => this.onDeleteItem(e, id)}
        onChangeCurrentTable={this.changeRouteForTable}
        onReset={isBlackWhite ? this.onReset : undefined}
        onChange={this.onChangeQuickFilters}
        onBeforeChange={{
          [filtersKeys.riskProjectIds]: this.changeRcsProjects,
        }}
        onRowClick={this.onRowClick}
        filtersValuesCustom={
          isBlackWhite ? { enabledProjectExternal: merchantProjects } : {}
        }
        hasSelectedFiltersCustom={
          isBlackWhite ? this.checkSelectedFilters() : undefined
        }
        location={location}
        history={history}
        match={match}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  state,
  selectedRcsQuickFilters: getQuickFiltersValues(state, tableNames.rcs),
});

export default addTranslation(connect(mapStateToProps)(RisksContainer));
