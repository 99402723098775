import React, { useEffect, useRef } from 'react';

import { CodatLinkProps, initializeCodatLink } from '@codat/sdk-link-types';

export const CodatLink: React.FC<CodatLinkProps> = (props: CodatLinkProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current && initializeCodatLink(ref.current, props);
  });

  return (
    <div
      style={{
        // Recommended dimensions
        width: '460px',
        height: '840px',
        maxHeight: '95%',
      }}
      ref={ref}></div>
  );
};
