import axios from 'axios';
import Token from './Token';
import Env from './Env';
import path from './path';
import Repository from './Repository';
import Utils from './Utils';
import getMessageError from './getMessageError';
import showNotification from 'components/ui/notification/showNotification';
import Messages from 'constants/rpcTypes';
import { AccessDeniedActions } from 'constants/httpCodes';
import { AnyObject } from 'types/Common';
import getLocalizedText from './getLocalizedText';

export default ({ instanceId }) => {
  let pendingRequests: any[] = [];

  axios.interceptors.request.use(function (config: any) {
    const { authToken } = Token.getTokens();
    config.url = config.url.includes('http')
      ? config.url
      : Env.getApiUrl() + config.url;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    config.cancelToken = source.token;

    if (config.headers['Content-Type'] === 'multipart/form-data') {
      const formData = new FormData();
      formData.set('authToken', authToken);
      formData.set('version', '1');
      formData.set('instanceId', instanceId);
      for (const key in config.data) {
        if (Utils.hasProp(config.data, key)) {
          formData.set(key, config.data[key]);
        }
      }
      config.data = formData;
    } else if (config.headers['Content-Type'] === 'application/json') {
      config.data = {
        authToken,
        version: '1',
        instanceId,
        payload: config.data,
      };
    } else {
      config.data.authToken = config.data.authToken || authToken;
      config.data.version = 1;
      config.data.instanceId = instanceId;

      const messages = config.data?.messages;
      if (
        messages &&
        (messages[0]?.type === Messages.Auth_Login ||
          messages[0]?.type === Messages.Auth_PasswordReset)
      ) {
        delete config.data.authToken;
      }
    }

    if (config.params) {
      config.errorHandle = config.params.errorHandle;
      delete config.params.errorHandle;
    }

    if (config.data.isCancelable) {
      const doubledRequest = pendingRequests.find((request) => {
        return (
          request.data?.[0].type === config.data.messages?.[0].type &&
          request.data?.[0].id !== config.data.messages[0].id
        );
      });

      if (doubledRequest) {
        const index = pendingRequests.findIndex(
          (request) => request.data?.[0].type === doubledRequest.data?.[0].type
        );

        pendingRequests.splice(index, 1);

        doubledRequest.cancel({
          type: config.data.messages[0].type,
          id: config.data.messages[0].id,
        });
      }

      pendingRequests.push({
        cancelToken: source.token,
        cancel: source.cancel,
        data: config.data?.messages || [config.data],
      });
    }

    config.meta = {
      pagination: config.data?.messages?.[0]?.payload?.pagination,
    };
    config.ts = performance.now();
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      const responseAuthToken = response.data?.authToken;
      const { authToken, prevAuthToken } = Token.getTokens();

      if (
        responseAuthToken &&
        responseAuthToken !== authToken &&
        responseAuthToken !== prevAuthToken
      ) {
        const tokens: AnyObject = {
          authToken: responseAuthToken,
        };
        if (authToken) {
          tokens.prevAuthToken = authToken;
        }
        Token.setTokens(tokens);
      }

      pendingRequests = pendingRequests.filter(
        (request) => request.data?.[0].id !== response.data.messages?.[0].id
      );

      return Promise.resolve(response);
    },
    function (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400 && error.config.errorHandle) {
          showNotification({ status: 'error', content: getMessageError(data) });
        } else if (status >= 401 && status <= 403) {
          if (data instanceof Blob) {
            const reader = new FileReader();
            reader.readAsText(data);
            let result;
            reader.onload = (event) => {
              result = reader.result;
              if (typeof result === 'string') {
                result = JSON.parse(result);
                if (result.error.code === AccessDeniedActions.notification) {
                  showNotification({
                    status: 'error',
                    content: getLocalizedText(
                      'common.notAvaliableAction.sysmsg'
                    ),
                  });
                }
              }
            };
          } else if (data.error.code === AccessDeniedActions.notification) {
            showNotification({
              status: 'error',
              content: getLocalizedText('common.notAvaliableAction.sysmsg'),
            });
          } else {
            const history = Repository.get('history');
            history.push(path('/login'), {
              from: history.location.pathname,
              reset: true,
            });
          }
        } else if (status === 500) {
          showNotification({
            status: 'error',
            content: 'Server error, please try again later',
          });
        }
      }

      pendingRequests = pendingRequests.filter((request) => {
        return (
          request.data?.[0].type !== error.message.type &&
          request.data?.[0].id !== error.message.id
        );
      });

      return Promise.reject(error);
    }
  );
};
