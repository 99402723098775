import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, disableUser, enableUser, getUsers } from 'api/admin';
import { AdminPage } from './AdminPage';
import path from 'helpers/path';
import { RootState } from 'store';
import { useHistory } from 'react-router-dom';
import {
  resetSelectedUsers,
  toggleAllSelectedUsers,
  toggleSelectedUser,
} from 'actions/butchUserEditing';
import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';

export const AdminContainer: React.ReactNode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prevMerchantId = useRef<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { items, selectedUsers, merchantIdFilters, configuration } =
    useSelector((state: RootState) => {
      return {
        items: state.data.users.items,
        selectedUsers: state.bunchUserEditing.usersToEdit,
        merchantIdFilters: state.quickFilters.users.merchantId,
        configuration: state.configuration.users || [],
      };
    });

  useEffect(() => {
    const isUserSelectionAvailable = merchantIdFilters.length === 1;

    // compare prev and current single values to prevent reset
    if (
      isUserSelectionAvailable &&
      prevMerchantId.current === merchantIdFilters[0]
    ) {
      return;
    }

    dispatch(resetSelectedUsers());
    prevMerchantId.current = undefined;

    if (isUserSelectionAvailable) {
      prevMerchantId.current = merchantIdFilters[0];
    }
  }, [merchantIdFilters]);

  const toggleActivateHandler = async (data: AdminUserFields) => {
    try {
      data.status === 'Active'
        ? await disableUser(data.id)
        : await enableUser(data.id);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteUserHandler = async (id: number) => {
    setIsLoading(true);

    try {
      await deleteUser(id);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const editUserHandler = (id: number) => {
    history.push(path(`/admin/edit/${id}`));
  };

  return (
    <AdminPage
      isLoading={isLoading}
      configuration={configuration}
      users={items}
      selectedUsers={selectedUsers}
      apiRequest={getUsers}
      onToggleActivate={toggleActivateHandler}
      onEditUser={editUserHandler}
      onDeleteUser={deleteUserHandler}
      toggleSelectedUser={(user) => dispatch(toggleSelectedUser(user))}
      toggleAllSelectedUser={(users) => dispatch(toggleAllSelectedUsers(users))}
      isUserSelectionAvailable={merchantIdFilters.length === 1}
    />
  );
};

export default AdminContainer;
