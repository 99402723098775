import React from 'react';
import I18nHelpers from 'i18n/I18nHelpers';
import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';
import statuses from 'components/ui/button/statuses';
import { useTranslation } from 'hooks';

interface Props {
  callback: (data: any) => void;
  content: {
    title: string;
    text: string;
    icon?: React.ReactNode;
    customContent?: React.ReactNode;
    okButton?: {
      text: string;
      status?: keyof typeof statuses;
    };
  };
}

export const Notification: React.FC<Props> = ({
  callback,
  content: {
    customContent,
    title,
    text,
    icon,
    okButton = {
      text: 'common.ok.button',
      status: 'primary',
    },
  },
}) => {
  const getTranslate = useTranslation();

  return (
    <>
      {icon ? <div className='modal__icon'>{icon}</div> : null}
      <ModalTitle>{getTranslate(title)}</ModalTitle>
      <ModalContent>
        {customContent ?? I18nHelpers.getJsx(getTranslate(text))}
      </ModalContent>
      <ModalFooter>
        <Button
          text={getTranslate(okButton.text)}
          status={okButton.status}
          onClick={() => callback(true)}
        />
      </ModalFooter>
    </>
  );
};
