import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { addListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { createRiskByTransaction, getRcsContentTypes } from 'api/risks';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import { openModal } from 'actions/modal';
import { StoreProps } from 'store';

import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import AddBlackList from './AddBlackList';
import ListTypes from 'pages/risks/components/addtoList/single/ListTypes';
import getSelectionListItems from 'creators/getSelectionListItems';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import Item from 'types/SelectionListItem';
import { AnyObject } from 'types/Common';
import ConfigType from './configType';
import './addBlackList.scss';

interface OwnProps {
  transactionId: string;
  operationId: number;
  customClass?: string;
}

interface ConnectedProps {
  isShowBinTooltip: boolean;
  availableAction: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IsMounted &
  IntlProps &
  WithPermissions;

interface State {
  isLoadingTypes: boolean;
  isOpened: boolean;
  listType: ListTypes;
  projectList: Item[];
  reasonValue: string;
  isChanged: boolean;
  isLoading: boolean;
  config: ConfigType;
}

@addListeners([
  Messages.Rcs_transactionContentTypes,
  Messages.Rcs_CreateElementByTransaction,
])
class AddBlackListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
      isLoadingTypes: false,
      listType: ListTypes.blackList,
      projectList: [],
      reasonValue: '',
      isChanged: false,
      isLoading: false,
      config: {
        [ListTypes.blackList]: {
          contentTypes: [],
        },
        [ListTypes.whiteList]: {
          contentTypes: [],
        },
      },
    };
  }

  render() {
    const {
      isEnabled,
      isDisabledByReason,
      transactionId,
      customClass,
      isShowBinTooltip,
    } = this.props;
    const {
      listType,
      projectList,
      reasonValue,
      isOpened,
      isLoadingTypes,
      isLoading,
      config,
    } = this.state;

    if (
      (!isDisabledByReason(
        Messages.Rcs_CreateElementByTransaction,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      ) &&
        !isEnabled(Messages.Rcs_CreateElementByTransaction)) ||
      !transactionId
    )
      return null;

    return (
      <AddBlackList
        isOpened={isOpened}
        isShowBinTooltip={isShowBinTooltip}
        isLoading={isLoading}
        isLoadingTypes={isLoadingTypes}
        reasonValue={reasonValue}
        listType={listType}
        projectList={projectList}
        config={config}
        onChange={this.onChange}
        onOpen={() => this.onClick()}
        onClose={this.onClosePanel}
        onSend={() => this.onSend()}
        customClass={customClass}
      />
    );
  }

  async onSend() {
    const { transactionId, operationId } = this.props;
    const { config, projectList, listType, reasonValue } = this.state;
    const selectedTypes = this.getSelectedFromSelectionList(
      config[listType].contentTypes
    );
    const selectedProjects = this.getSelectedFromSelectionList(projectList);

    this.setState({ isLoading: true });
    try {
      await createRiskByTransaction({
        transactionId,
        operationId,
        listType,
        contentType: selectedTypes,
        projectId: selectedProjects,
        comment: reasonValue,
      });
      this.closePanel();
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onChange = (name, data) => {
    const { config, listType } = this.state;
    if (name === 'contentTypes') {
      this.setState(
        {
          config: {
            ...config,
            [listType]: {
              [name]: data,
            },
          },
          isChanged: true,
        },
        () => {
          this.setProjects();
        }
      );
      return;
    } else if (name === 'listType') {
      this.resetValues();
    }

    this.setState({
      [name]: data,
      isChanged: true,
    } as Pick<State, keyof State>);
  };

  async onClick() {
    const { transactionId } = this.props;
    if (isNotAvailableForSupport(Messages.Rcs_CreateElementByTransaction))
      return;
    this.setState({ isLoadingTypes: true });
    try {
      await getRcsContentTypes(transactionId);
    } finally {
      this.setState({ isLoadingTypes: false });
    }
  }

  onClosePanel = () => {
    const { dispatch, getTranslate, isMount } = this.props;
    const { isChanged } = this.state;
    if (isChanged && isMount()) {
      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            title: getTranslate('risk.modal.cancelAdding.header'),
            text: getTranslate('risks.modal.cancelAdding.text'),
          },
          callback: (answer) => answer && this.closePanel(),
        })
      );
    } else {
      this.closePanel();
    }
  };

  closePanel = () => {
    this.setState({
      isOpened: false,
      config: {
        [ListTypes.blackList]: { contentTypes: [] },
        [ListTypes.whiteList]: { contentTypes: [] },
      },
      projectList: [],
      reasonValue: '',
      isChanged: false,
    });
  };

  getSelectedFromSelectionList = (selectionItems: Item[]) => {
    return selectionItems.reduce<string[]>((result, { isSelected, id }) => {
      return isSelected ? [...result, id] : result;
    }, []);
  };

  setProjects = () => {
    const { config, listType } = this.state;
    const { contentTypes } = config[listType];
    const selectedContentTypes = contentTypes.filter(
      ({ isSelected }) => isSelected
    );

    const projectList = selectedContentTypes.reduce((projects, type) => {
      if (!projects.length) return type.projects;

      return projects.filter((project) => {
        return type.projects.find(({ id }) => id === project.id);
      });
    }, []);

    this.setState({ projectList });
  };

  setConfig = (data) => {
    const config = data.listTypes.map((typeConfig) => {
      return {
        id: typeConfig.id,
        contentTypes: typeConfig.contentTypes.map((type) => {
          return {
            ...type,
            isSelected: false,
            projects: getSelectionListItems({
              originalList: type.projects,
              selectedList: [],
            }),
          };
        }),
      };
    });

    const blackListConfig = config.find(
      (item) => item.id === ListTypes.blackList
    );
    const whiteListConfig = config.find(
      (item) => item.id === ListTypes.whiteList
    );

    this.setState({
      config: {
        [ListTypes.blackList]: blackListConfig,
        [ListTypes.whiteList]: whiteListConfig,
      },
      isOpened: true,
    });
  };

  resetList = (list) => {
    return list.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });
  };

  resetValues = () => {
    const { config, projectList } = this.state;

    this.setState({
      config: {
        [ListTypes.blackList]: {
          ...config[ListTypes.blackList],
          contentTypes: this.resetList(
            config[ListTypes.blackList].contentTypes
          ),
        },
        [ListTypes.whiteList]: {
          ...config[ListTypes.whiteList],
          contentTypes: this.resetList(
            config[ListTypes.whiteList].contentTypes
          ),
        },
      },
      projectList: this.resetList(projectList),
    });
  };

  onEvent = ({ name, data }) => {
    if (name === Messages.Rcs_transactionContentTypes) {
      this.setConfig(data.payload);
    } else if (name === Messages.Rcs_CreateElementByTransaction) {
      this.setState({ isLoading: false });
    }
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  isShowBinTooltip:
    state.user.isRcsBinBlackEnabled || state.user.isRcsBinWhiteEnabled,
  availableAction:
    state.paymentActions[ownProps.transactionId]?.blacklistManage,
});

export default connect(mapStateToProps)(
  addTranslation(addIsMounted(addPermissions(AddBlackListContainer)))
);
