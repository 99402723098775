import { isObject, omit } from 'lodash-es';
import Utils from 'helpers/Utils';
import filtersKeys from 'constants/filters';
import tableNames from 'constants/tableNames';
import urlsMap from 'constants/urlsMap';

function foundQuickFilters(filtersSet) {
  const { filters, searchFilters } = filtersSet;

  return (
    searchFilters &&
    searchFilters.selectedCount === 0 &&
    filters &&
    Object.keys(getFilters(filters)).length === 0
  );
}

function getFilters(filters) {
  return omit(filters, '__lastApply');
}

function prepareDataForState(filtersSet, baseRequest, options?: any) {
  const { quickFilters, filters, searchFilters } = filtersSet;

  let filtersList = quickFilters;
  const isQuickFilters = foundQuickFilters(filtersSet);

  let isBaseFilters = false;

  // Согласно этому if-else определяется приоритет применение группы фильтров
  if (
    searchFilters &&
    searchFilters.selectedCount > 0 &&
    options?.tableName === tableNames.payments
  ) {
    filtersList = searchFilters.list;
  } else if (Object.keys(getFilters(filters)).length > 0) {
    filtersList = getFilters(filters);
    isBaseFilters = true;
  }

  const { filter } = baseRequest;

  for (const key in filtersList) {
    if (Utils.hasProp(filtersList, key)) {
      let item =
        isObject(filtersList[key]) && !Array.isArray(filtersList[key])
          ? filtersList[key].values
          : filtersList[key];

      if (typeof item === 'number') {
        filter[key] = String(item);
      } else if (item?.length) {
        if (key === filtersKeys.paymentStatus) {
          item = [...item];
          if (isQuickFilters && item.includes('refunded')) {
            item.push('partially refunded');
          }
          filter[key] = item;
        }
        filter[key] = item;
      }
    }
  }

  if (options?.tableName === tableNames.rcs) {
    const { listType } = baseRequest.filter;
    if (!listType || listType.length > 1) {
      baseRequest.filter.listType = 'both';
    } else if (listType.length === 1) {
      baseRequest.filter.listType = listType[0];
    }
  }

  if (options?.tableName === tableNames.payments) {
    baseRequest.saveFilter = isBaseFilters || Object.keys(filter).length === 0;
  }

  return baseRequest;
}

const getPathUrl = (tableName, data) => {
  switch (tableName) {
    case tableNames.payments:
      return `/payments/${encodeURIComponent(
        data.transactionId
      )}?name=${encodeURIComponent(data.paymentId)}`;
    case tableNames.payouts:
    case tableNames.batch:
      if (data.transactionId && data.paymentId) {
        return `/${urlsMap.payouts}/${encodeURIComponent(
          data.transactionId
        )}?name=${encodeURIComponent(data.paymentId)}`;
      }
      return '';
    case tableNames.massPayouts:
      return `/${urlsMap.payouts}/${encodeURIComponent(
        data.paymentOperationBatchId
      )}?type=batch&name=${encodeURIComponent(
        `Batch ${data.paymentOperationBatchId}`
      )}`;
    case tableNames.fraud:
      return `/risks/${encodeURIComponent(
        data.transactionId
      )}?name=${encodeURIComponent(data.paymentId)}`;
    default:
      return '/';
  }
};

export { prepareDataForState, foundQuickFilters, getPathUrl };
