import LabelStatus from 'components/ui/labelStatus';
import { statusThemes } from 'constants/statusList';
import { AccountingRequestItem } from 'pages/accountingStatementRequests/types';

const mapStatusToTheme: {
  [key in AccountingRequestItem['codatStatus']]: string;
} = {
  done: statusThemes.green,
  request: statusThemes.red,
  review: statusThemes.blue,
};

const mapStatusToText: {
  [key in AccountingRequestItem['codatStatus']]: string;
} = {
  done: 'Done',
  request: 'Verification Needed',
  review: 'Pending',
};

export const AccountingStatusTag: React.FC<{
  status: AccountingRequestItem['codatStatus'];
}> = ({ status }) => (
  <LabelStatus
    text={mapStatusToText[status]}
    status={mapStatusToTheme[status]}
  />
);
