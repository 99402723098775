import { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import InfoText from 'components/ui/InfoText';
import macbook from 'images/macbook.png';

interface Props extends IntlProps {
  showModal: () => void;
}

class ConstructorVersionFive extends PureComponent<Props> {
  private isAvailable: boolean = false;

  componentDidMount() {
    const { showModal } = this.props;

    this.isAvailable = document.body.clientWidth >= 1490;

    if (this.isAvailable) {
      showModal();
    }
  }

  render() {
    const { getTranslate, showModal } = this.props;
    return (
      <div className='card utils-container'>
        <InfoText
          text={getTranslate(
            this.isAvailable
              ? 'modals.ppDesignerVersionFive.description'
              : 'modals.ppDesignerVersionFive.descriptionNotAvailable'
          )}
        />
        <div className='utils-align utils-flex-column'>
          <img
            src={macbook}
            alt={'Payment Page Designer'}
            className='utils-container'
          />
          {this.isAvailable && (
            <Button
              status='outline'
              size='normal'
              customClass='policy-widget__btn policy-widget__btn_inline'
              onClick={showModal}
              text={getTranslate(
                'modals.ppDesignerVersionFive.openModalButton'
              )}
            />
          )}
        </div>
      </div>
    );
  }
}

export default addTranslation(ConstructorVersionFive);
