import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import {
  IFormValues,
  IOnChange,
  IDictionary,
  IValidateHandler,
} from 'types/Analytics';
import { AdvancedAnalyticsEntityType } from '../AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

import CustomScrollbar from 'components/ui/customScrollbar';
import { InOutTableGroupByParams, InOutTableFilters } from './InOutFormConfig';
import {
  chartParams,
  filters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/OperationsFormConfig';
import { topTenChartParams, topTenChartFilters } from './Top10FormConfig';
import {
  DeclineReasonsParams,
  DeclineReasonsFilters,
} from './DeclineReasonsFormConfig';
import {
  FraudReportParams,
  FraudReportFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';
import {
  ChargebackParams,
  ChargebackFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';

import { GetRenderableField } from '../../services/ServiceAdvancedAnalyticsEntityTypesFormGenerator';

import './settingsForm.scss';
import { SuccessRateFilter, SuccessRateParams } from './SuccessRateConfig';

interface Props extends IntlProps, WithPermissions {
  selectedType?: string;
  validationErrors: { [key: string]: string };
  formValidationErrors: { [key: string]: string };
  values: IFormValues;
  projects: IDictionary;
  country: IDictionary;
  currency: IDictionary;
  paymentMethod: IDictionary;
  paymentMethodGroup: IDictionary;
  analyticsBrand: IDictionary;
  successRateTransactionType: IDictionary;
  onChange: IOnChange;
  readonly?: boolean;
  validateHandler: IValidateHandler;
  formValidatedFields: string[];
  pristineFields: { [key: string]: boolean };
}

interface State {
  panelPosition: {
    top: number;
  };
}

class SettingsForm extends React.PureComponent<Props, State> {
  ref?: React.Ref<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.state = {
      panelPosition: { top: 0 },
    };
    this.whatToRender = this.whatToRender.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  render() {
    const { selectedType, readonly } = this.props;

    if (!selectedType && !readonly) {
      return null;
    }
    return (
      <div className='settings-form'>
        <CustomScrollbar
          getRef={(ref) => {
            this.ref = ref;
          }}
          hideHorizontal
          customHeight={300}>
          {this.whatToRender()}
        </CustomScrollbar>
      </div>
    );
  }

  get filtersHeader() {
    const { getTranslate } = this.props;

    return (
      <div className='settings-form__header'>
        {getTranslate('analytics.editForm.filters.filters.label')}
      </div>
    );
  }

  get chartParamsHeader() {
    const { getTranslate } = this.props;

    return (
      <div className='settings-form__header'>
        {getTranslate('analytics.editForm.chartParameters.operations.label')}
      </div>
    );
  }

  whatToRender() {
    const { selectedType, formValidationErrors, getTranslate } = this.props;

    switch (selectedType) {
      case AdvancedAnalyticsEntityType.operations: {
        return (
          <>
            {this.chartParamsHeader}
            {chartParams.map(this.renderField)}

            {this.filtersHeader}
            {filters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.inout: {
        const { formValidatedFields, pristineFields } = this.props;

        const shouldShowFormError = formValidatedFields.some(
          (field) => !pristineFields[field]
        );

        return (
          <>
            <div className='settings-form__header'>
              {getTranslate('analytics.editForm.tableParameters.label')}
            </div>
            {InOutTableGroupByParams.map(this.renderField)}

            <div className='settings-form__row'>
              {shouldShowFormError &&
                Object.entries(formValidationErrors).map(
                  ([errId, err]) =>
                    err !== undefined && (
                      <p
                        key={`formErr-${errId}`}
                        className='settings-form__error'>
                        {err}
                      </p>
                    )
                )}
            </div>

            {this.filtersHeader}
            {InOutTableFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.topten: {
        return (
          <>
            {this.chartParamsHeader}
            {topTenChartParams.map(this.renderField)}

            {this.filtersHeader}
            {topTenChartFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.declineReasons: {
        return (
          <>
            <div className='settings-form__header'>
              {getTranslate('analytics.editForm.tableParameters.label')}
            </div>
            {DeclineReasonsParams.map(this.renderField)}

            {this.filtersHeader}
            {DeclineReasonsFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.chargeback: {
        return (
          <>
            {this.chartParamsHeader}
            {ChargebackParams.map(this.renderField)}

            {this.filtersHeader}
            {ChargebackFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.fraud_report: {
        return (
          <>
            {this.chartParamsHeader}
            {FraudReportParams.map(this.renderField)}

            {this.filtersHeader}
            {FraudReportFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.success_rate: {
        return (
          <>
            {this.chartParamsHeader}
            {SuccessRateParams.map(this.renderField)}

            {this.filtersHeader}
            {SuccessRateFilter.map(this.renderField)}
          </>
        );
      }
    }
  }

  renderField(formItem) {
    const {
      values,
      onChange,
      getTranslate,
      validationErrors,
      country,
      currency,
      projects,
      paymentMethod,
      paymentMethodGroup,
      successRateTransactionType,
      analyticsBrand,
      validateHandler,
      isEnabled,
    } = this.props;
    const { panelPosition } = this.state;
    const { id, label, tooltip } = formItem;

    return GetRenderableField({
      formDescriptorItem: formItem,
      onChange,
      values,
      id,
      getTranslate,
      label,
      tooltip,
      validationErrors,
      toggleDatePicker: this.toggleDatePicker,
      panelPosition,
      dictionaries: {
        country,
        currency,
        projects,
        paymentMethod,
        paymentMethodGroup,
        analyticsBrand,
        successRateTransactionType,
      },
      validateHandler,
      isEnabled,
    });
  }

  toggleDatePicker = () => {
    let panelPositionTop = 0;
    const input = document.querySelector('#graph-details-period-settings');
    const modal = document.querySelector('.modal__inner');
    if (!input || !modal) return;

    panelPositionTop =
      (input?.getBoundingClientRect().top + 32 || 0) -
      (modal?.getBoundingClientRect().top || 0) +
      30;
    this.setState({
      panelPosition: {
        top: panelPositionTop,
      },
    });
  };
}

export default addTranslation(addPermissions(SettingsForm));
