import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetAll } from 'actions/app';
import { resetUserErrors } from 'actions/user';
import api from 'api/user';
import LocalStorage from 'helpers/LocalStorage';
import { RootState } from 'store';
import PageAuthWrapper from '../components/PageAuthWrapper';
import LoginForm from './LoginForm';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ reset?: boolean }>();
  const [isLoading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const {
    user,
    settings: { isSignUpAvailable, wl },
  } = useSelector((state: RootState) => state);
  const { email, password } = formValues;
  const signUpLink = isSignUpAvailable ? `https://${wl}.com/sign-up/` : '';

  useEffect(() => {
    dispatch(resetUserErrors());
    dispatch(resetAll());
    history.replace({
      state: { ...location.state, reset: false },
    });
  }, []);

  useEffect(() => {
    if (user?.email && location.state.reset) {
      LocalStorage.set('autoLogoutEmail', user.email);
    }
  }, [user]);

  const navigateToResetPassword = () => {
    history.push('/reset-password');
  };

  const handleChangeValues = (key: string, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleLogin = async () => {
    setLoading(true);

    try {
      await api.login({
        email: email.trim(),
        password,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAuthWrapper title='auth.login.header'>
      <LoginForm
        signUpLink={signUpLink}
        validation={user && user.validationErrors}
        email={email}
        password={password}
        isLoading={isLoading}
        onChange={handleChangeValues}
        onLogin={handleLogin}
        goToResetPassword={navigateToResetPassword}
      />
    </PageAuthWrapper>
  );
};

export default LoginPage;
