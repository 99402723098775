import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';
import { paymentHotCurrency } from 'components/quickFiltersContainer/defaultValues';

export default [
  {
    filterId: filtersKeys.paymentType,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      { id: 'purchase', text: 'types.purchase' },
      { id: 'payout', text: 'types.payout' },
      { id: 'recurring', text: 'types.recurring' },
    ],
  },
  {
    filterId: filtersKeys.paymentStatus,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      { id: 'success', text: 'statuses.success' },
      { id: 'decline', text: 'statuses.decline' },
      { id: 'refunded', text: 'statuses.refund' },
    ],
  },
  {
    filterId: filtersKeys.paymentCurrency,
    type: FilterTypes.buttonsList,
    mode: modes.single,
    dictionary: 'paymentHotCurrency',
    list: paymentHotCurrency,
  },
  {
    filterId: filtersKeys.amountFrom,
    type: FilterTypes.amount,
    title: 'quickFilters.amountPlaceholder',
  },
  {
    filterId: filtersKeys.date,
    type: FilterTypes.dateButtons,
  },
];
