import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { invert, isEqual, pickBy } from 'lodash-es';

import { addDataItem } from 'actions/getData';
import { openModal } from 'actions/modal';
import {
  createInvoice,
  createSubscriptionInvoice,
  getFormConfig,
} from 'api/invoices';
import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

import formStateFactory from 'components/formFields/formStateFactory';
import FormFields from 'components/formFields/FormFieldsContainer';
import {
  fieldsConfig,
  fieldsConfigStc,
  fieldsConfigSubscription,
} from './fieldsConfig';
import Utils from 'helpers/Utils';
import CacheService from 'helpers/CacheService';
import path from 'helpers/path';
import checkFilters from 'helpers/checkFilters';
import LocalStorage from 'helpers/LocalStorage';
import { getMethods } from 'creators/paymentMethods';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import { formIdRoutes } from './formIdRoutes';
import permissionReasons from 'constants/permissionReasons';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { RouteLeavingGuard } from 'components/routeLeavingGuard';

export enum InvoiceStatus {
  DISABLED,
  ENABLED,
}

interface OwnProps {
  invoiceStatusProjects?: AnyObject[];
}

interface ConnectedProps {
  isPaymentIdGeneratorEnabled: boolean;
  cardOperationManageable: boolean;
  invoiceProject: AnyObject;
  motoType: AnyObject;
  subscriptionPaymentPeriod: Dictionary;
  invoices: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ formId: string }> &
  WithPermissions;

interface State {
  isCreating: boolean;
  isSuccessful: boolean;
  isMotoFieldEnabled: boolean;
  isStcFieldsEnabled: boolean;
  fields: any; //InvoiceFields;
  fieldsSnapshot: AnyObject;
  isFieldsDirty: boolean;
  validationErrors: AnyObject;
  paymentMethods: Dictionary;
  fieldsState: {
    paymentMethodCode: boolean;
    isEmailSend: boolean;
    amount: boolean;
  };
  hiddenFields: {
    motoType: boolean;
    cardToken: boolean;
    cardOperationType: boolean;
    paymentMethodCode: boolean;
    customerFirstName: boolean;
    customerLastName: boolean;
    cardVerify: boolean;
  };
  link?: string;
  currentForm: FormVariants;
}

type FormVariants = 'single' | 'subscription';

const errorFieldsMap = {
  projectId: 'projectId',
  paymentMethodCode: 'paymentMethodCode',
  currency: 'currency',
};

const CARD_TOKEN_PAYMENT_METHOD = 'card-token';
const cardPaymentMethods = ['card', CARD_TOKEN_PAYMENT_METHOD];
const DIRECT_DEBIT_METHODS = ['directdebit-sepa', 'directdebit-bacs'];

@addListeners([
  Messages.Invoice_Create,
  Messages.Invoice_CreateSubscription,
  Messages.Invoice_FormConfig,
  Messages.InvoicesUpdated,
])
class InvoiceCreateContainer
  extends Component<Props, State>
  implements IListeners
{
  private cacheService;

  constructor(props) {
    super(props);
    const initialFields = this.getInitialFields();

    this.state = {
      isCreating: false,
      isSuccessful: false,
      isMotoFieldEnabled: false,
      isStcFieldsEnabled: false,
      fields: initialFields,
      fieldsSnapshot: initialFields,
      isFieldsDirty: false,
      validationErrors: {},
      paymentMethods: {
        isFetched: false,
        isLoading: false,
        hasMoreRows: false,
        list: [],
      },
      hiddenFields: {
        motoType: true,
        cardToken: true,
        cardOperationType: true,
        paymentMethodCode: false,
        customerFirstName: true,
        customerLastName: true,
        cardVerify: true,
      },
      fieldsState: {
        isEmailSend: false,
        paymentMethodCode: false,
        amount: true,
      },
      link: undefined,
      currentForm: 'single',
    };

    this.cacheService = new CacheService(
      Messages.Invoice_FormConfig,
      this.loadFormConfig
    );
  }

  componentDidMount() {
    const { motoType } = this.props;
    this.setFormTab();

    if (motoType) {
      this.setMotoType();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const {
      currentForm,
      fields: { selectedProject },
    } = this.state;
    const {
      currentForm: prevForm,
      fields: { selectedProject: prevSelectedProject },
    } = prevState;

    const {
      invoiceStatusProjects,
      motoType,
      match: {
        params: { formId },
      },
    } = this.props;
    const {
      invoiceStatusProjects: prevInvoiceStatusProjects,
      motoType: prevMotoType,
      match: {
        params: { formId: prevFormId },
      },
    } = prevProps;

    const value = selectedProject?.value;
    const prevValue = prevSelectedProject?.value;
    const isSubscription = currentForm === 'subscription';

    if (value && value !== prevValue) {
      this.validateProjectIdField(String(value));
    }

    if (!prevInvoiceStatusProjects && invoiceStatusProjects && value) {
      this.validateProjectIdField(String(value));
    }

    if (!prevMotoType && motoType) {
      this.setMotoType();
    }

    if (prevFormId !== formId) {
      this.setFormTab();
    }

    if (prevForm !== currentForm) {
      this.reset();

      if (isSubscription) {
        checkFilters([{ name: 'subscriptionPaymentPeriod' }]);
      }
    }
  }

  getText = () => {
    const { currentForm } = this.state;
    const { cardOperationManageable } = this.props;
    const isSubscription = currentForm === 'subscription';

    if (isSubscription) {
      return 'invoicing.newinvoice.subscription.infoText';
    }

    if (cardOperationManageable) {
      return 'invoicing.newinvoice.dmsFeature.infoText';
    }

    return 'invoicing.newinvoice.infoText';
  };

  validateProjectIdField = (value: string) => {
    const { invoiceStatusProjects, getTranslate } = this.props;
    const { validationErrors } = this.state;

    if (invoiceStatusProjects) {
      const selectedProject = invoiceStatusProjects.find(
        ({ id }) => id === value
      );

      const invoiceEnabled =
        selectedProject &&
        selectedProject.invoice_enabled === InvoiceStatus.ENABLED;

      if (!invoiceEnabled) {
        this.setState({
          validationErrors: {
            ...validationErrors,
            projectId: getTranslate(
              'invoicing.newinvoice.project.validationError.text'
            ),
          },
        });
      }
    }
  };

  formIsInvalid = () => {
    const { validationErrors } = this.state;

    if (!validationErrors) {
      return false;
    }

    return Object.values(validationErrors).some((error) => error);
  };

  canCreate = () => {
    const {
      isMotoFieldEnabled,
      currentForm,
      fields: {
        amount,
        cardToken,
        paymentId,
        currency,
        motoType,
        paymentMethodCode,
        projectId,
        bestBefore,
        customerId,
        recurringScheduledPaymentId,
        recurringInterval,
        recurringPeriod,
        recurringStartDate,
        cardVerify,
      },
    } = this.state;
    const isSubscription = currentForm === 'subscription';

    if (this.formIsInvalid()) {
      return false;
    }

    if (
      isSubscription &&
      !(
        recurringScheduledPaymentId &&
        recurringInterval &&
        recurringPeriod &&
        recurringStartDate &&
        paymentMethodCode
      )
    )
      return false;

    const formattedAmount = Number(Utils.getNumberWithoutSpace(amount));

    if (
      paymentId &&
      projectId &&
      (formattedAmount > 0 || cardVerify) &&
      currency &&
      bestBefore &&
      customerId
    ) {
      if (isMotoFieldEnabled && !motoType) {
        return false;
      }

      if (paymentMethodCode) {
        return !(paymentMethodCode === CARD_TOKEN_PAYMENT_METHOD && !cardToken);
      }

      return true;
    }

    return false;
  };

  showModal = () => {
    const { currentForm } = this.state;
    const { dispatch } = this.props;
    const isSubscription = currentForm === 'subscription';

    if (isSubscription && !LocalStorage.get('isInvoiceSubscriptionInfoSeen')) {
      dispatch(
        openModal({
          modalId: 'ConfirmInvoiceSubscription',
          callback: (isAgree) => isAgree && this.createInvoice(),
        })
      );
    } else {
      this.createInvoice();
    }
  };

  createInvoice = async () => {
    const {
      fields: {
        amount,
        cardToken,
        paymentId,
        currency,
        motoType,
        paymentMethodCode,
        projectId,
        bestBefore,
        customerId,
        recurringScheduledPaymentId,
        recurringInterval,
        recurringPeriod,
        recurringStartDate,
        recurringEndDate,
        description,
        customerEmail,
        cardOperationType,
        isEmailSend,
        customerFirstName,
        customerLastName,
        cardVerify,
        ...rest
      },
      currentForm,
    } = this.state;
    const isSubscription = currentForm === 'subscription';

    if (
      isNotAvailableForSupport(
        isSubscription
          ? Messages.Invoice_CreateSubscription
          : Messages.Invoice_Create
      )
    )
      return;

    this.setState({ isCreating: true });

    const request = isSubscription ? createSubscriptionInvoice : createInvoice;
    const options: AnyObject = {
      ...rest,
      customerId,
      paymentId,
      isEmailSend,
      customerEmail,
      bestBefore,
      description,
      cardToken,
      amount: Utils.getNumberWithoutSpace(amount),
      currency,
      motoType,
      projectId,
      customerFirstName,
      customerLastName,
      cardVerify,
      paymentMethodCode,
      cardOperationType: cardPaymentMethods.includes(paymentMethodCode)
        ? cardOperationType
        : '',
    };

    if (isSubscription) {
      options.recurringScheduledPaymentId = recurringScheduledPaymentId;
      options.recurringInterval = recurringInterval || '1';
      options.recurringPeriod = recurringPeriod;
      options.recurringStartDate = recurringStartDate;
      options.recurringEndDate = recurringEndDate;
    }

    try {
      await request(pickBy(options, (val) => val !== null && val !== ''));
    } catch (error: any) {
      const { payload } = error;
      this.setState({
        validationErrors: payload?.validationErrors || payload,
      });
    } finally {
      this.setState({ isCreating: false });
    }
  };

  reset = () => {
    const { invoiceProject } = this.props;
    const initialFields = this.getInitialFields();
    const canSelectSingleProjectId = invoiceProject?.list?.length === 1;
    const singleProjectId = invoiceProject?.list?.[0].projectId;
    const resetFields = {
      ...initialFields,
      projectId: canSelectSingleProjectId ? singleProjectId : '',
    };

    this.setState(
      () => ({
        isCreating: false,
        isSuccessful: false,
        isMotoFieldEnabled: false,
        isStcFieldsEnabled: false,
        fields: resetFields,
        fieldsSnapshot: resetFields,
        isFieldsDirty: false,
        hiddenFields: {
          paymentMethodCode: false,
          motoType: true,
          cardToken: true,
          cardOperationType: true,
          text: true,
          customerFirstName: true,
          customerLastName: true,
          cardVerify: true,
        },
        validationErrors: {},
        fieldsState: {
          isEmailSend: false,
          paymentMethodCode: canSelectSingleProjectId,
          amount: true,
        },
        link: undefined,
      }),
      () => {
        if (canSelectSingleProjectId) {
          this.handleProjectChange(singleProjectId);
        }
      }
    );
  };

  nullifyErrors = (field) => {
    const { validationErrors } = this.state;
    const errorField = errorFieldsMap[field] || field;

    this.setState({
      validationErrors: {
        ...validationErrors,
        [errorField]: '',
      },
    });
  };

  setMotoType = () => {
    const { motoType } = this.props;
    const { fields, hiddenFields } = this.state;

    if (hiddenFields.motoType) {
      return;
    }

    let selectedMotoType: any = null;

    if (motoType?.list?.length) {
      const withoutMoto = motoType?.list?.find(({ id }) => id === 0);

      if (withoutMoto) {
        const { id, text } = withoutMoto;

        selectedMotoType = {
          value: id,
          label: text,
        };
      }
    }

    this.setState({
      fields: {
        ...fields,
        motoType: selectedMotoType,
      },
    });
  };

  getInitialFields = () => {
    const {
      match: {
        params: { formId },
      },
      isPaymentIdGeneratorEnabled,
    } = this.props;
    const isSingle = formId === 'single';
    const config = isSingle ? fieldsConfig : fieldsConfigSubscription;
    const additional = config.additional.map((item) => {
      if (Array.isArray(item)) {
        return item.map((field) => this.checkItem(field));
      }
      return this.checkItem(item);
    });

    const fields = {
      ...formStateFactory(fieldsConfig.general, isPaymentIdGeneratorEnabled),
      ...formStateFactory(additional),
    };

    if (isSingle) {
      return fields;
    }

    return {
      ...fields,
      ...formStateFactory(config.subscription),
    };
  };

  checkItem = (item) => {
    const { cardOperationManageable } = this.props;

    if (item.id === 'cardOperationType') {
      return {
        ...item,
        isFeatureEnabled: cardOperationManageable,
      };
    }

    return item;
  };

  changeFields = (field: string, value) => {
    const { fields, fieldsState, isStcFieldsEnabled, currentForm } = this.state;
    const { cardOperationManageable, getTranslate } = this.props;
    const newFields = { ...fields };
    const isSingle = currentForm === 'single';

    this.nullifyErrors(field);
    newFields[field] = value;

    if (field === 'customerEmail') {
      if (!value) {
        newFields.isEmailSend = false;
      }

      this.setState({
        fieldsState: {
          ...fieldsState,
          isEmailSend: !!value,
        },
      });
    } else if (field === 'projectId') {
      this.handleProjectChange(value);
      this.setState({
        fieldsState: {
          ...fieldsState,
          paymentMethodCode: !!value,
        },
      });

      if (isEqual(fields[field], value)) {
        return false;
      }

      newFields.paymentMethodCode = null;
    } else if (field === 'paymentMethodCode') {
      const isDirectDebit = DIRECT_DEBIT_METHODS.includes(value);

      if (!isDirectDebit) {
        newFields.cardVerify = '';
        newFields.customerFirstName = '';
        newFields.customerLastName = '';
      }

      this.setState((prevState) => ({
        fieldsState: {
          ...prevState.fieldsState,
          amount: true,
        },
        hiddenFields: {
          ...prevState.hiddenFields,
          cardToken: value !== CARD_TOKEN_PAYMENT_METHOD,
          cardOperationType: cardOperationManageable
            ? !cardPaymentMethods.includes(value)
            : true,
          cardVerify: !isDirectDebit,
          customerFirstName:
            !isDirectDebit && !(isStcFieldsEnabled && isSingle),
          customerLastName: !isDirectDebit && !(isStcFieldsEnabled && isSingle),
        },
      }));
    } else if (field === 'cardVerify') {
      newFields.amount = value ? '0' : '';

      this.setState((prevState) => ({
        fieldsState: {
          ...prevState.fieldsState,
          amount: !value,
        },
      }));
    } else if (
      field === 'paymentId' ||
      field === 'recurringScheduledPaymentId'
    ) {
      this.setState((prevState) => ({
        validationErrors: {
          ...prevState.validationErrors,
          recurringScheduledPaymentId:
            fields.paymentId === value ||
            fields.recurringScheduledPaymentId === value
              ? getTranslate(
                  'invoice.create.subscription.paymenId.validationError.text'
                )
              : '',
        },
      }));
    }

    this.setState(
      {
        fields: newFields,
      },
      this.checkIsFieldsDirty
    );
  };

  checkIsFieldsDirty = () => {
    const { fields, fieldsSnapshot } = this.state;
    const copyFields = { ...fields };
    const isFieldsDirty = !isEqual(copyFields, fieldsSnapshot);

    this.setState({
      isFieldsDirty,
    });
  };

  handleProjectChange = (item) => {
    if (!item) return;

    const cachedName = this.getCachedName(item);
    const data = this.cacheService.getData(cachedName, true);

    if (data) {
      this.setConfigSettings(data);
    }
  };

  getCachedName = (projectId) => {
    const { currentForm } = this.state;
    return `${currentForm}-${projectId}`;
  };

  loadFormConfig = async (itemId) => {
    const { paymentMethods, currentForm } = this.state;
    const projectId = itemId.split('-')[1];
    const isSubscription = currentForm === 'subscription';

    this.setState({
      paymentMethods: {
        ...paymentMethods,
        isLoading: true,
      },
    });

    await getFormConfig({
      projectId,
      isSubscription,
    });
  };

  setConfigSettings = (data: {
    isMotoFieldEnabled: boolean;
    isStcFieldsEnabled: boolean;
    paymentMethodsList: Dictionary['list'];
  }) => {
    const { isMotoFieldEnabled, isStcFieldsEnabled, paymentMethodsList } = data;
    const { currentForm } = this.state;
    const isSingle = currentForm === 'single';

    this.setState((prevState) => ({
      ...prevState,
      isStcFieldsEnabled,
      paymentMethods: {
        list: [...paymentMethodsList],
        isLoading: false,
        isFetched: true,
        hasMoreRows: false,
      },
      fieldsState: {
        ...prevState.fieldsState,
        motoType: isMotoFieldEnabled,
      },
      hiddenFields: {
        ...prevState.hiddenFields,
        customerFirstName: !(isStcFieldsEnabled && isSingle),
        customerLastName: !(isStcFieldsEnabled && isSingle),
        paymentMethodCode: !paymentMethodsList.length,
        text: !!paymentMethodsList.length,
      },
    }));
  };

  getTabs = () => {
    const {
      match: {
        params: { formId },
      },
    } = this.props;

    const tabs = [
      {
        id: 'single',
        label: 'invoicing.newinvoice.tab.single',
        isCurrent: formId === formIdRoutes.single,
      },
    ];

    if (this.checkSubscriptionAvailability()) {
      tabs.push({
        id: 'subscription',
        label: 'invoicing.newinvoice.tab.subscription',
        isCurrent: formId === formIdRoutes.subscription,
      });
    }

    return tabs;
  };

  checkSubscriptionAvailability = () => {
    const { isEnabled, isDisabledByReason } = this.props;

    return (
      isEnabled(Messages.Invoice_CreateSubscription) ||
      isDisabledByReason(
        Messages.Invoice_CreateSubscription,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  };

  onChangeTab = (_, tabId) => {
    const { history } = this.props;
    history.push(path(`/invoices/${formIdRoutes[tabId]}`));
  };

  setFormTab = () => {
    const {
      match: {
        params: { formId },
      },
      history,
    } = this.props;

    const isCorrectRoute = Object.values(formIdRoutes).includes(formId);

    if (
      !isCorrectRoute ||
      (formId === formIdRoutes.subscription &&
        !this.checkSubscriptionAvailability())
    ) {
      history.push(path(`/invoices/${formIdRoutes['single']}`));
      return;
    }

    const currentForm = invert(formIdRoutes)[formId] as FormVariants;

    this.setState({
      currentForm,
    });
  };

  onEvent = ({ data, name }) => {
    const { status } = data.rpc;

    if (name === Messages.InvoicesUpdated) {
      const {
        fields: { paymentId },
      } = this.state;
      const result = data.payload;
      let link = '';

      if (Array.isArray(result)) {
        const currentResult = result.find(
          (item) => item.paymentId === paymentId
        );

        if (currentResult) {
          link = currentResult.link;
        }
      } else {
        if (result.paymentId === paymentId) {
          link = result.link;
        }
      }

      if (!link) {
        return;
      }

      this.setState({
        link,
        isFieldsDirty: false,
      });
    }

    if (status !== 'success') return;

    if (
      name === Messages.Invoice_Create ||
      name === Messages.Invoice_CreateSubscription
    ) {
      const { invoices, dispatch } = this.props;

      if (invoices?.items.length) {
        dispatch(addDataItem('invoices', data.payload));
      }

      this.setState({
        isSuccessful: true,
        isFieldsDirty: false,
      });
    } else if (name === Messages.Invoice_FormConfig) {
      if (!data?.payload) return;

      const {
        fields: { projectId },
      } = this.state;

      const { isMotoFieldEnabled, isStcFieldsEnabled } = data.payload;
      const paymentMethodsList = getMethods(data.payload.paymentMethods);

      this.setConfigSettings({
        isMotoFieldEnabled,
        isStcFieldsEnabled,
        paymentMethodsList,
      });

      this.cacheService.setData(this.getCachedName(projectId), {
        paymentMethodsList,
        isMotoFieldEnabled,
        isStcFieldsEnabled,
      });
    }
  };

  render() {
    const {
      isStcFieldsEnabled,
      isCreating,
      isSuccessful,
      link,
      fields,
      paymentMethods,
      validationErrors,
      currentForm,
      isFieldsDirty,
    } = this.state;
    const isSingle = currentForm === 'single';

    const renderConfig = isSingle ? fieldsConfig : fieldsConfigSubscription;
    const extraConfig =
      isSingle && isStcFieldsEnabled ? fieldsConfigStc : undefined;

    return (
      <>
        <FormFields
          id='invoices'
          isLoading={isCreating}
          fieldsConfig={renderConfig}
          extraConfig={extraConfig}
          hiddenFields={this.state.hiddenFields}
          fieldsState={this.state.fieldsState}
          canCreate={!isSuccessful && this.canCreate()}
          onChange={this.changeFields}
          onReset={this.reset}
          onSubmit={this.showModal}
          fieldsValues={fields}
          filtersValuesCustom={{
            paymentMethods,
            subscriptionPaymentPeriod: {
              ...this.props.subscriptionPaymentPeriod,
              list: getCustomSelectItems({
                list: this.props.subscriptionPaymentPeriod?.list,
                getLabel: (item) =>
                  this.props.getTranslate(item.text, {
                    amount: +this.state.fields.recurringInterval || 0,
                  }),
              }),
            },
          }}
          validationErrors={validationErrors}
          title='invoicing.newinvoice.tab'
          text={this.getText()}
          generalTitle='invoicing.newinvoice.paymentInfo.label'
          additionalTitle='invoicing.newinvoice.additionalInfo.label'
          backUrl='/invoices'
          backText='invoicing.newinvoice.backToRegistry.button'
          createButtonText='invoicing.newinvoice.createLink.button'
          repeatCreateButtonText='invoicing.newInvoice.button'
          isSubmitted={isSuccessful}
          submittedTitle='invoicing.newinvoice.successLink.header'
          submittedText={
            isSingle
              ? 'invoicing.newinvoice.successLink.text'
              : 'invoicing.newinvoicesubscription.successLink.text'
          }
          link={link}
          tabs={this.getTabs()}
          onBeforeChange={this.onChangeTab}
        />

        <RouteLeavingGuard blockWhen={isFieldsDirty} />
      </>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
  cardOperationManageable: state.user.isInvoiceCardOperationTypeManageAvailable,
  invoiceProject: state.filtersValues.invoiceProject,
  motoType: state.filtersValues.motoType,
  subscriptionPaymentPeriod: state.filtersValues.subscriptionPaymentPeriod,
  invoices: state.data.invoices,
});

export default withRouter(
  connect(mapStateToProps)(
    addPermissions(addTranslation(InvoiceCreateContainer))
  )
);
