enum ACTIONS {
  // Common
  INIT_APP = 'INIT_APP',
  RESET_ALL = 'RESET_ALL',
  CONFIRM_LEAVE_PAGE = 'CONFIRM_LEAVE_PAGE',
  SET_WL = 'SET_WL',

  //demoStage
  SET_DOC_LINKS = 'SET_DOC_LINKS',
  MAF_STATUS_CHANGE_AVAILABILITY = 'MAF_STATUS_CHANGE_AVAILABILITY',
  //maf
  SET_MAF_STATUS = 'SET_MAF_STATUS',
  SET_MAF_DICTIONARIES = 'SET_MAF_DICTIONARIES',
  GET_MAF_DICTIONARIES = 'GET_MAF_DICTIONARIES',
  SET_BEFORE_UPDATE_ACTIONS = 'SET_BEFORE_UPDATE_ACTIONS',
  SHOW_VALIDITY_FOR_TABS = 'SHOW_VALIDITY_FOR_TABS',
  SET_CURRENT_MAF_PAGE_INDEX = 'SET_CURRENT_MAF_PAGE_INDEX',
  SET_MAF_PAGES = 'SET_MAF_PAGES',
  SET_MAF_PAGES_MODE = 'SET_MAF_PAGES_MODE',

  // User
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  UPDATE_USER = 'UPDATE_USER',
  SET_USER_ERROR = 'SET_USER_ERROR',
  RESET_USER_ERROR = 'RESET_USER_ERROR',
  USER_CHANGE_LANGUAGE = 'USER_CHANGE_LANGUAGE',
  SET_SUPPORT_MESSAGES = 'SET_SUPPORT_MESSAGES',
  EDIT_SUPPORT_MESSAGES = 'EDIT_SUPPORT_MESSAGES',
  SET_USER_TIPS = 'SET_USER_TIPS',
  UPDATE_USER_TIPS = 'UPDATE_USER_TIPS',

  // Payments
  FETCH_PAYMENTS_PROGRESS = 'FETCH_PAYMENTS_PROGRESS',
  FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS',
  FETCH_PAYMENTS_UPLOAD_SUCCESS = 'FETCH_PAYMENTS_UPLOAD_SUCCESS',
  FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL',
  SAVE_PAYMENTS_LIST_STATE = 'SAVE_PAYMENTS_LIST_STATE',
  RESET_PAYMENTS = 'RESET_PAYMENTS',
  CHANGE_SORTING = 'CHANGE_SORTING',

  // Payouts
  FETCH_PAYOUTS_PROGRESS = 'FETCH_PAYOUTS_PROGRESS',
  FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS',
  FETCH_PAYOUTS_UPLOAD_SUCCESS = 'FETCH_PAYOUTS_UPLOAD_SUCCESS',
  FETCH_PAYOUTS_FAIL = 'FETCH_PAYOUTS_FAIL',
  SAVE_PAYOUTS_LIST_STATE = 'SAVE_PAYOUTS_LIST_STATE',
  RESET_PAYOUTS = 'RESET_PAYOUTS',

  // Single Payment
  FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS',
  FETCH_PAYMENT_FAIL = 'FETCH_PAYMENT_FAIL',
  REFUND_PAYMENT_FAIL = 'REFUND_PAYMENT_FAIL',

  // Recurring
  FETCH_RECURRING = 'FETCH_RECURRING',

  // Saved tabs
  GET_SAVED_ENTITIES = 'GET_SAVED_ENTITIES',
  ADD_ENTITY_TO_SAVED = 'ADD_ENTITY_TO_SAVED',
  REMOVE_ENTITY_FROM_SAVED = 'REMOVE_ENTITY_FROM_SAVED',
  REMOVE_ALL_ENTITIES = 'REMOVE_ALL_ENTITIES',
  UPDATE_SAVED_ENTITY = 'UPDATE_SAVED_ENTITY',
  RELOAD_SAVED_ENTITY = 'RELOAD_SAVED_ENTITY',
  UPDATE_ALL_SAVED_ENTITY = 'UPDATE_ALL_SAVED_ENTITY',

  // Payment actions
  UPDATE_PAYMENT_ACTIONS = 'UPDATE_PAYMENT_ACTIONS',

  // Modal
  OPEN_MODAL = 'OPEN_MODAL',
  EDIT_CONTENT = 'EDIT_CONTENT',
  EDIT_OPTIONS = 'EDIT_OPTIONS',
  CLOSE_MODAL = 'CLOSE_MODAL',
  RESET_MODAL = 'RESET_MODAL',

  // Quick filters
  CHANGE_QUICK_FILTER = 'CHANGE_QUICK_FILTER',
  RESET_CONCRETE_QUICK_FILTERS = 'RESET_CONCRETE_QUICK_FILTERS',
  RESET_CONCRETE_QUICK_FILTERS_PROPS = 'RESET_CONCRETE_QUICK_FILTERS_PROPS',
  RESET_QUICK_FILTERS = 'RESET_QUICK_FILTERS',
  INIT_QUICK_FILTERS = 'INIT_QUICK_FILTERS',
  RESET_PAYOUT_QUICK_FILTERS = 'RESET_PAYOUT_QUICK_FILTERS',

  // Filters
  INIT_FILTERS = 'INIT_FILTERS',
  APPLY_FILTERS = 'APPLY_FILTERS',
  RESET_ONE_FILTER = 'RESET_ONE_FILTER',
  RESET_FILTERS = 'RESET_FILTERS',

  // Filters values
  UPDATE_FILTERS_VALUES = 'UPDATE_FILTERS_VALUES',
  UPLOAD_FILTERS_VALUES = 'UPLOAD_FILTERS_VALUES',

  // Search filters
  APPLY_SEARCH_FILTERS = 'APPLY_SEARCH_FILTERS',
  RESET_ONE_SEARCH_FILTER = 'RESET_ONE_SEARCH_FILTER',
  RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS',

  // Dictionaries
  FETCH_DICTIONARY = 'FETCH_DICTIONARY',
  FETCH_DICTIONARY_SUCCESS = 'FETCH_DICTIONARY_SUCCESS',
  FETCH_DICTIONARY_FAIL = 'FETCH_DICTIONARY_FAIL',

  // Configuration
  INIT_ALL_CONFIGURATION = 'INIT_ALL_CONFIGURATION',
  UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION',
  CHANGE_FIXED_CONFIGURATION = 'CHANGE_FIXED_CONFIGURATION',
  APPLY_CONFIGURATION = 'APPLY_CONFIGURATION',

  // UI state
  SAVE_UI = 'SAVE_UI',

  // Table data
  CHANGE_DATA_OFFSET = 'CHANGE_DATA_OFFSET',
  CHANGE_DATA_SORTING = 'CHANGE_DATA_SORTING',
  CHANGE_DATA_FORCE_UPDATE = 'CHANGE_DATA_FORCE_UPDATE',
  SAVE_DATA_STATE = 'SAVE_DATA_STATE',
  FETCH_DATA_PROGRESS = 'FETCH_DATA_PROGRESS',
  FETCH_DATA_UPLOAD_SUCCESS = 'FETCH_DATA_UPLOAD_SUCCESS',
  FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL = 'FETCH_DATA_FAIL',
  RESET_PART_OF_DATA = 'RESET_PART_OF_DATA',
  ADD_DATA_ITEM = 'ADD_DATA_ITEM',
  UPDATE_DATA_ITEM = 'UPDATE_DATA_ITEM',
  UPDATE_DATA_ITEM_PROPERTIES = 'UPDATE_DATA_ITEM_PROPERTIES',
  DELETE_DATA_ITEM = 'DELETE_DATA_ITEM',
  ADD_DATA_ITEMS = 'ADD_DATA_ITEMS',

  // Localization
  CHECK_LOCALIZATION_ROLE = 'CHECK_LOCALIZATION_ROLE',

  // Template builder
  UPDATE_TEMPLATE_BUILDER = 'UPDATE_TEMPLATE_BUILDER',
  UPDATE_TEMPLATE_BUILDER_FIELD = 'UPDATE_TEMPLATE_BUILDER_FIELD',
  UPDATE_TEMPLATE_BUILDER_FILTER_FIELD = 'UPDATE_TEMPLATE_BUILDER_FILTER_FIELD',
  RESET_TEMPLATE_BUILDER = 'RESET_TEMPLATE_BUILDER',

  // global permissions
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',

  // user notifications
  USER_NOTIFICATIONS_UPDATE = 'USER_NOTIFICATIONS_UPDATE',
  USER_NOTIFICATIONS_CHANGE_SETTINGS = 'USER_NOTIFICATIONS_CHANGE_SETTINGS',
  USER_NOTIFICATIONS_NEW_ITEM = 'USER_NOTIFICATIONS_NEW_ITEM',
  USER_NOTIFICATIONS_UPDATE_ITEM = 'USER_NOTIFICATIONS_UPDATE_ITEM',
  USER_NOTIFICATIONS_DELETE_ITEM = 'USER_NOTIFICATIONS_DELETE_ITEM',
  USER_NOTIFICATIONS_DELETE_ALL = 'USER_NOTIFICATIONS_DELETE_ALL',
  USER_NOTIFICATIONS_UPDATE_COUNTS = 'USER_NOTIFICATIONS_UPDATE_COUNTS',

  //Projects
  SET_PROJECT = 'SET_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  UPDATE_PROJECT_FIELD = 'UPDATE_PROJECT_FIELD',
  RESET_PROJECT = 'RESET_PROJECT',

  //Help
  SET_HELP_ARTICLES = 'SET_HELP_ARTICLES',
  UPDATE_HELP_ARTICLES = 'UPDATE_HELP_ARTICLES',
  ADD_HELP_ARTICLE = 'ADD_HELP_ARTICLE',
  UPDATE_HELP_ARTICLE = 'UPDATE_HELP_ARTICLE',
  UPDATE_POSITION_HELP_ARTICLE = 'UPDATE_POSITION_HELP_ARTICLE',
  DELETE_HELP_ARTICLE = 'DELETE_HELP_ARTICLE',
  RESET_HELP = 'RESET_HELP',

  // Analytics
  CHART_SAVED = 'CHART_SAVED',
  CHARTS_LOADED = 'CHARTS_LOADED',
  CHART_LOADED = 'CHART_LOADED',
  CHART_DELETED = 'CHART_DELETED',
  CHART_ORDER_CHANGED = 'CHART_ORDER_CHANGED',
  CHART_RESTORE_STATE = 'CHART_RESTORE_STATE',
  CHART_EDIT = 'CHART_EDIT',
  CHART_FILTERS_LOADED = 'CHART_FILTERS_LOADED',
  CHART_FILTER_LOADED = 'CHART_FILTER_LOADED',
  BYID_MUTATED = 'BYID_MUTATED',

  // chat
  SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE',

  // formValues
  SET_FORM_VALUES = 'SET_FORM_VALUES',
  RESET_CONCRETE_FORM_VALUES = 'RESET_CONCRETE_FORM_VALUES',
  RESET_FORM_VALUES = 'RESET_FORM_VALUES',

  // Accounting Requests
  SET_ACCOUNTING_REQUESTS = 'SET_ACCOUNTING_REQUESTS',
  RESET_ACCOUNTING_REQUESTS = 'RESET_ACCOUNTING_REQUESTS',

  // Bunch User Editing
  TOGGLE_USER_TO_EDIT_SELECTION = 'TOGGLE_USER_TO_EDIT_SELECTION',
  TOGGLE_ALL_USERS_TO_EDIT_SELECTION = 'TOGGLE_ALL_USERS_TO_EDIT_SELECTION',
  RESET_USERS_TO_EDIT_SELECTION = 'RESET_USERS_TO_EDIT_SELECTION',

  // Home page data
  SET_HOME_TODAY_BLOCKS = 'SET_HOME_TODAY_BLOCKS',
  SET_HOME_TODAY_BLOCK_DATA_BY_NAME = 'SET_HOME_TODAY_BLOCK_DATA_BY_NAME',
  RESET_HOME_TODAY_BLOCK_DATA_BY_NAME = 'RESET_HOME_TODAY_BLOCK_DATA_BY_NAME',
  SET_HOME_CURRENT_CURRENCY = 'SET_HOME_CURRENT_CURRENCY',
  SET_HOME_QUICK_ACTIONS = 'SET_QUICK_ACTIONS',
  SET_HOME_GRAPH_BLOCK_DATA_BY_NAME = 'SET_HOME_GRAPH_DATA_BY_NAME',
  SET_HOME_GRAPH_PERIOD = 'SET_HOME_GRAPH_PERIOD',
  SET_HOME_REFRESH_ID = 'SET_HOME_REFRESH_ID',
}

export default ACTIONS;
