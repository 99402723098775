import React, { useState } from 'react';
import { useTranslation } from 'hooks';
import ModalContent from 'components/modal/components/ModalContent';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button/Button';
import Input from 'components/ui/input';

interface Props {
  content: {
    value: string;
  };
  callback: (data?: string) => void;
}

const BalanceRequestNote: React.FC<Props> = ({ content, callback }) => {
  const getTranslate = useTranslation();
  const [comment, setComment] = useState(content.value);

  return (
    <>
      <ModalTitle>{getTranslate('BalanceRequest.modal.note.title')}</ModalTitle>
      <ModalContent>
        <Input
          modern
          id='comment'
          value={comment}
          label='BalanceRequest.modal.note.input.label'
          placeholder='BalanceRequest.modal.note.input.placeholder'
          type='textarea'
          onChange={(e) => setComment(e.target.value)}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          text={getTranslate('common.cancel.button')}
          status='outline'
          size='normal'
          onClick={() => callback()}
        />
        <Button
          text={getTranslate('common.save.button')}
          status='primary'
          size='normal'
          onClick={() => callback(comment)}
        />
      </ModalFooter>
    </>
  );
};

export { BalanceRequestNote };
