import React, { ReactNode } from 'react';
import { MafTab } from '../../types';
import { SimpleSlider } from '../SimpleSlider';

interface Props {
  children: (value: MafTab) => ReactNode;
  tabs: MafTab[];
  currentStep: MafTab;
}

export default class ModalTabsManager extends React.PureComponent<Props> {
  // When slide is changed, we need to hide it and show another one with animation
  // When old slide is hidding it`s data can be changed and the slide will be immediatly rerendered
  // So we need to save old slide data to keep hiding slide unchanged during an hiding animation
  hidingSlide: MafTab = this.props.currentStep;
  previousSlidesLength = 0;

  componentDidUpdate(): void {
    this.previousSlidesLength = this.props.tabs.length;
    if (!this.props.currentStep) return;

    this.hidingSlide = JSON.parse(
      JSON.stringify(
        this.props.tabs[this.props.tabs.length - 1] || this.props.currentStep
      )
    );
  }

  getSlidesToRender(slideDirection: 'left' | 'right' | null): MafTab[] {
    const slidesToRender = [this.props.currentStep, ...this.props.tabs];

    if (slideDirection === 'left') {
      slidesToRender.push(this.hidingSlide);
    }

    if (slideDirection === 'right') {
      const hidingSlideIndex = slidesToRender.findIndex(
        (slide) => slide.name === this.hidingSlide?.name
      );
      slidesToRender[hidingSlideIndex] = this.hidingSlide;
    }

    return slidesToRender;
  }

  getSlideDirection = (): 'left' | 'right' | null => {
    if (this.previousSlidesLength > this.props.tabs.length) return 'left';

    if (this.previousSlidesLength < this.props.tabs.length) return 'right';

    return null;
  };

  render() {
    const slideDirection = this.getSlideDirection();
    const slides = this.getSlidesToRender(slideDirection);

    const lastSlideIndex = slides.length - 1;
    const preLastSlideIndex = lastSlideIndex - 1;
    const showingSlideIndex =
      slideDirection === 'left' ? preLastSlideIndex : lastSlideIndex;
    return (
      <SimpleSlider
        slideRenderer={this.props.children}
        slides={slides}
        showingSlideIndex={showingSlideIndex}
      />
    );
  }
}
