import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ManualInputCalendar from 'components/ui/manualInputCalendar';
import DateHelpers from 'helpers/Date';
import { isTopPositionByBlock } from 'helpers/setCorrectPosition';
import DatePickerRange from 'components/ui/datePickerRange/DatePickerRange';
import dateFormats from 'constants/dateFormats';
import Panel from 'components/ui/panel';
import { APP_PADDING } from 'constants/ui';
import breakpoints from 'constants/breakpoints';

interface OwnProps {
  value: { dateFrom: string; dateTo?: string };
  onChange: (date) => void;
  label?: string;
  placeholder?: string;
  title?: string; //?
  id?: string;
  customClass?: string;
  error?: string;
  minDate?: string;
  maxDate?: string;
  withTime?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  initialDate?: string;
  withCloseButton?: boolean;
  modern?: boolean;
  isUnselectDate?: boolean;
  onDatePickerToggle?: (isOpen: boolean) => void;
  getRef?: (ref: any) => void;
  containerSelector?: string;
  monthsAmount?: number;
  applyFormat?: keyof typeof dateFormats;
  disableButton?: boolean;
  panelTitle?: string;
  panelPosition?: {
    top: number;
    left?: number;
  };
}

interface ConnectedProps {
  isMenuExpanded: boolean;
}

interface State {
  isOpened: boolean;
  isTopPosition: boolean;
  height: string;
  left: string;
  top: string;
  maxWidth: string;
}

type Props = OwnProps & IntlProps & ConnectedProps;

class InputDateRange extends Component<Props, State> {
  private inputDate;
  private panelRef;
  static defaultProps = {
    withTime: true,
    withCloseButton: true,
    tooltip: 'common.enterDate.oneDate.incorrectFormat.text',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      isTopPosition: false,
      height: 'auto',
      left: 'unset',
      top: 'unset',
      maxWidth: '',
    };

    this.panelRef = React.createRef();
    this.attachEvents();
  }

  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(_, prevState: Readonly<State>) {
    if (this.state.isOpened && !prevState.isOpened) {
      this.setState({
        isTopPosition: isTopPositionByBlock(
          this.inputDate,
          this.props.containerSelector,
          '.ui-input-date__picker',
          true
        ),
      });
    }
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  render() {
    const {
      id,
      onChange,
      value,
      label,
      minDate,
      maxDate,
      withTime,
      isRequired,
      customClass,
      getTranslate,
      applyFormat,
      modern,
      error,
      isDisabled,
    } = this.props;
    const { isOpened, isTopPosition } = this.state;

    return (
      <div
        id={id}
        ref={(el) => {
          this.inputDate = el;
        }}
        className={classNames(
          'ui-input-date ui-input-date_range',
          customClass,
          {
            'ui-input-date_modern': !!modern,
            'ui-input-date_error': !!error,
            'ui-input-date_disabled': isDisabled,
            'ui-input-date_top': isTopPosition,
          }
        )}>
        <div
          className={classNames('ui-input-date__wrapper', {
            'ui-input-date__wrapper_modern': modern,
          })}>
          {(label || isRequired) && (
            <div className='ui-input-date__top'>
              <span>
                {getTranslate(label)}
                {isRequired && (
                  <span
                    data-tip={getTranslate('common.requiredField.tooltip')}
                    className='ui-input__required'>
                    *
                  </span>
                )}
              </span>
            </div>
          )}
          <ManualInputCalendar
            id={`${id}`}
            isRange={true}
            onChange={(date) => {
              onChange(date);
            }}
            onClick={this.handleManualInputClick}
            value={value || { dateFrom: '', dateTo: '' }}
            onOpenCalendar={() => this.toggle(!isOpened)}
            withTime={withTime}
            isDisabled={isDisabled}
            minDate={minDate}
            maxDate={maxDate}
            isOpenedCalendar={isOpened}
            modern={modern}
            placeholder={this.props.placeholder}
            customClass='ui-input-date__range-field'
            isInnerBtn={false}
            disableButton={this.props.disableButton}
          />
          <Panel
            id='date-picker-range-panel'
            isOpened={isOpened}
            title={getTranslate(this.props.panelTitle || 'filter.date')}
            customClass='ui-panel_input-date'
            excludeFromCloseTrigger={['.ui-input-date__range-field']}
            onClose={() => this.toggle(false)}
            containerStyle={{
              height: this.state.height,
              left: this.state.left,
              top: this.state.top,
              maxWidth: this.state.maxWidth,
              ...this.props.panelPosition,
            }}
            panelRef={this.panelRef}
            modern>
            <DatePickerRange
              range={{
                from: value?.dateFrom || '',
                to: value?.dateTo || '',
              }}
              showMonths={this.props.monthsAmount || 2}
              maxDate={this.props.maxDate}
              minDate={
                minDate
                  ? DateHelpers.getFormat(
                      DateHelpers.createDate(minDate, applyFormat),
                      applyFormat
                    )
                  : undefined
              }
              onApplyRange={({ from, to }) => {
                onChange({ dateFrom: from, dateTo: to });
                this.toggle(false);
              }}
              applyFormat={applyFormat}
              withTime={withTime}
              hideManualDate={true}
              inputLabel='reports.new.dateAndTime.fromTo.label'
              isOpenedMenu={this.props.isMenuExpanded}
            />
          </Panel>
        </div>
        {error && (
          <div className='ui-input-date__error'>{getTranslate(error)}</div>
        )}
      </div>
    );
  }

  attachEvents = () => {
    document.addEventListener('mousedown', this.close);
    window.addEventListener('resize', this.getContentStyle);
  };

  detachEvents = () => {
    document.removeEventListener('mousedown', this.close);
    window.removeEventListener('resize', this.getContentStyle);
  };

  close = ({ target }) => {
    if (!target.closest(`.ui-input-date`)) {
      this.toggle(false);
    }
  };

  toggle = (isOpened) => {
    const { isDisabled, onDatePickerToggle } = this.props;
    if (!isDisabled) {
      this.setState({ isOpened: isOpened || false }, () => {
        onDatePickerToggle && onDatePickerToggle(isOpened);
        if (isOpened) {
          setTimeout(this.getContentStyle, 50);
        } else {
          this.setState({ height: 'auto' });
        }
      });
    }
  };

  onInputDate = ({ dateFrom }) => {
    this.props.onChange && this.props.onChange(dateFrom);
  };

  handleManualInputClick = (e) => {
    e.stopPropagation();
    if (this.state.isOpened || !this.props.disableButton) return;
    this.toggle(!this.state.isOpened);
  };

  getContentStyle = () => {
    if (window.innerWidth <= breakpoints.commonTablet) return;

    if (!this.panelRef.current) return;
    let heightToSet = 0;
    const inputPosition = this.inputDate.getBoundingClientRect();
    const contentHeight =
      this.panelRef.current.querySelector('div').clientHeight;
    const appLayout = document.querySelector('.layout-app__inner');

    if (appLayout && contentHeight) {
      const layoutHeight = appLayout.getBoundingClientRect().height;
      heightToSet =
        contentHeight < layoutHeight - inputPosition.bottom.toFixed()
          ? contentHeight
          : layoutHeight - inputPosition.bottom.toFixed();
    }

    this.setState({
      maxWidth: `${
        window.innerWidth - inputPosition.left.toFixed() - APP_PADDING
      }px`,
      height: `${heightToSet}px`,
      left: `${inputPosition.left.toFixed()}px`,
      top: `${(inputPosition.bottom + 5).toFixed()}px`,
    });

    this.panelRef.current.addEventListener('wheel', (e) => {
      e.stopPropagation();
    });

    this.panelRef.current
      .closest('.ui-scrollbar')
      .addEventListener('wheel', this.toggleOff);
  };

  toggleOff = () => {
    this.toggle(false);
  };
}

const mapStateToProps = (state) => ({
  isMenuExpanded: state.configuration.isMenuExpanded,
});

export default connect(mapStateToProps)(addTranslation(InputDateRange));
