import React, { Component } from 'react';
import { batch, connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash-es';

import {
  deleteAllNotifications,
  deleteNotification,
  getNotifications,
  getNotificationsSettings,
  markAsReadNotification,
  saveNotificationsSettings,
} from 'api/userNotifications';
import {
  deleteAllUserNotifications,
  deleteUserNotificationsItem,
  updateUserNotifications,
  updateUserNotificationsCounts,
  updateUserNotificationsItem,
} from 'actions/userNotifications';
import { addListeners, IListeners } from 'decorators/addListeners';
import getDictionaryAsMap from 'selectors/getDictionaryAsMap';
import { StoreProps } from 'store';

import UserNotificationsPanel from './UserNotificationsPanel';
import checkFilters from 'helpers/checkFilters';
import Messages from 'constants/rpcTypes';
import { UserNotificationsType } from 'types/UserNotifications';
import { AnyObject } from 'types/Common';

interface ConnectedProps {
  userNotifications: UserNotificationsType;
  categoriesDictionary: AnyObject;
  typesDictionary: AnyObject;
}

type Props = ConnectedProps & StoreProps;

interface State {
  isLoading: boolean;
}

const LIMIT = 20;

@addListeners([
  Messages.UserNotification_List,
  Messages.UserNotification_MarkAsRead,
  Messages.UserNotification_Delete,
  Messages.UserNotification_DeleteAll,
  Messages.UserNotificationSetting_List,
  Messages.UserNotificationSetting_Save,
])
class UserNotificationsPanelContainer
  extends Component<Props, State>
  implements IListeners
{
  private canLoadMore: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };

    this.canLoadMore = true;
  }

  async componentDidMount() {
    await checkFilters('userNotifications');
    return this.init();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { userNotifications } = this.props;
    if (
      !isEqual(userNotifications.filters, prevProps.userNotifications.filters)
    ) {
      return this.fetchNotifications();
    }
    if (
      !isEqual(userNotifications.settings, prevProps.userNotifications.settings)
    ) {
      return this.fetchNotifications();
    }

    if (
      isEmpty(prevProps.typesDictionary) &&
      !isEmpty(this.props.typesDictionary) &&
      this.props.userNotifications.isFetched
    ) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { userNotifications, typesDictionary, categoriesDictionary } =
      this.props;
    const { isLoading } = this.state;

    return (
      <UserNotificationsPanel
        userNotifications={userNotifications}
        categoriesDictionary={categoriesDictionary}
        typesDictionary={typesDictionary}
        isLoading={isLoading}
        onMarkAsRead={(id) => this.markRead(id)}
        onDelete={(id) => this.delete(id)}
        onDeleteAll={() => this.deleteAll()}
        onLoadMore={() => this.loadMoreNotifications()}
        onChangeFilter={this.changeFilter}
        onChangeSetting={this.changeSetting}
      />
    );
  }

  changeFilter = (alias: string, value: any) => {
    const {
      userNotifications: { filters },
      dispatch,
    } = this.props;

    const newFilters = { ...filters };
    newFilters[alias] = value;
    dispatch(
      updateUserNotifications({
        filters: newFilters,
      })
    );
  };

  changeSetting = (category: string, isEnabled: boolean) => {
    const { userNotifications } = this.props;
    const newSettings = userNotifications.settings.map((setting) => {
      if (setting.category === category) {
        return {
          ...setting,
          isEnabled,
        };
      }
      return setting;
    });

    return this.saveSettings(newSettings);
  };

  async init() {
    try {
      await Promise.all([
        this.fetchNotifications(),
        getNotificationsSettings(),
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchNotifications() {
    const { dispatch, typesDictionary } = this.props;
    this.setState({ isLoading: true });

    try {
      const { list, counters } = await getNotifications(
        this.getBaseNotificationsParams()
      );

      this.canLoadMore = list.length >= LIMIT;

      dispatch(
        updateUserNotifications({
          counters,
          items: list,
          isFetched: true,
        })
      );
    } finally {
      if (!isEmpty(typesDictionary)) {
        this.setState({ isLoading: false });
      }
    }
  }

  async loadMoreNotifications() {
    const {
      userNotifications: { items },
      dispatch,
    } = this.props;

    if (!this.canLoadMore) return false;

    try {
      this.canLoadMore = false;
      const lastId: string = items[items.length - 1].id;
      const { list, counters } = await getNotifications({
        id: lastId,
        ...this.getBaseNotificationsParams(),
      });
      if (list) {
        this.canLoadMore = list.length >= LIMIT;
        dispatch(
          updateUserNotifications({
            items: [...items, ...list],
            counters,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  getBaseNotificationsParams() {
    const {
      userNotifications: { filters },
    } = this.props;

    const params: AnyObject = {
      limit: LIMIT,
    };

    if (filters.onlyNew) {
      params.isRead = false;
    }

    return params;
  }

  async markRead(id: string) {
    try {
      await markAsReadNotification(id);
    } catch (error) {
      console.error(error);
    }
  }

  async delete(id: string) {
    try {
      await deleteNotification(id);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteAll() {
    this.setState({ isLoading: true });
    try {
      await deleteAllNotifications();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async saveSettings(settings) {
    this.setState({ isLoading: true });
    try {
      await saveNotificationsSettings(
        settings.filter(({ category }) => category !== 'important')
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onEvent({ data, name }) {
    const { userNotifications, dispatch } = this.props;
    const payload = data.payload;
    const { status } = data.rpc;

    if (status !== 'success') return;

    switch (name) {
      case Messages.UserNotification_MarkAsRead:
        batch(() => {
          userNotifications.filters.onlyNew
            ? dispatch(deleteUserNotificationsItem(payload))
            : dispatch(updateUserNotificationsItem(payload));
          dispatch(updateUserNotificationsCounts({ unread: -1 }));
        });
        return;
      case Messages.UserNotification_Delete:
        batch(() => {
          dispatch(deleteUserNotificationsItem(payload));
          if (!payload.isRead) {
            dispatch(updateUserNotificationsCounts({ unread: -1 }));
          }
        });
        return;
      case Messages.UserNotification_DeleteAll:
        dispatch(deleteAllUserNotifications());
        return;
      case Messages.UserNotificationSetting_List:
      case Messages.UserNotificationSetting_Save:
        dispatch(
          updateUserNotifications({
            settings: payload.list,
          })
        );
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  userNotifications: state.userNotifications,
  categoriesDictionary: getDictionaryAsMap(
    state,
    'userNotificationCategories',
    'id'
  ),
  typesDictionary: getDictionaryAsMap(state, 'userNotificationTypes', 'id'),
});

export default connect(mapStateToProps)(UserNotificationsPanelContainer);
