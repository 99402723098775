import csvIcon from 'images/fileFormats/csv.svg';
import excelIcon from 'images/fileFormats/excel.svg';
import wordIcon from 'images/fileFormats/word.svg';
import imagesIcon from 'images/fileFormats/images.svg';
import pdfIcon from 'images/fileFormats/pdf.svg';
import powerPointIcon from 'images/fileFormats/power-point.svg';
import unknownIcon from 'images/fileFormats/unknown.svg';

export const getFileIcon = (file: File) => {
  switch (file.type) {
    case 'text/csv':
      return csvIcon;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return excelIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-word':
      return wordIcon;
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg':
      return imagesIcon;
    case 'application/pdf':
      return pdfIcon;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return powerPointIcon;
    default:
      if (file.name.endsWith('.svg')) {
        return imagesIcon;
      }
      return unknownIcon;
  }
};
