import React from 'react';
import classnames from 'classnames';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  IntlProps,
  addTranslation,
} from '../../../../decorators/addTranslation';
import Icon from 'components/ui/icon';

import './multipleFileUpload.scss';
import { ACCEPTED_FILES_CONFIG } from './constants';
import { getFileFormatIcon } from 'images/fileFormats/getFileFormatIcon';

type OwnProps = {
  error?: string;
  selectedFiles: Map<string, File>;
  onDrop: DropzoneOptions['onDrop'];
  unselectFile: (id: string) => void;
  maxFileSize?: number;
  disabled?: boolean;
  fileTypes?: string;
  accept?: DropzoneOptions['accept'];
};

type Props = IntlProps & OwnProps;

const Component = ({
  onDrop,
  selectedFiles,
  unselectFile,
  maxFileSize,
  disabled,
  fileTypes,
  accept,
  error,
}: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept || ACCEPTED_FILES_CONFIG,
  });

  const getFileSizeText = (size: number) => {
    const sizeInKb = size / 1024;
    if (sizeInKb < 1024) return `${sizeInKb.toFixed(1)} KB`;

    return `${(sizeInKb / 1024).toFixed(1)} MB`;
  };

  const rootProps = disabled ? {} : getRootProps();
  const inputProps = disabled ? {} : getInputProps({ accept: fileTypes });

  return (
    <>
      <div
        {...rootProps}
        className={classnames('muliple-file-upload', {
          'muliple-file-upload_disabled': disabled,
          'muliple-file-upload_error': error,
        })}>
        {!disabled && (
          <div className='muliple-file-upload__info-block'>
            <Icon
              name='im-Upload'
              className='muliple-file-upload__icon'
              size={16}
            />
            <p>
              Drop your documents here, or{' '}
              <span className='muliple-file-upload__link-text'>
                click to browse
              </span>
            </p>
          </div>
        )}
        <input {...inputProps} />
        <div className='muliple-file-upload__selected-files'>
          {[...selectedFiles.entries()].map(([id, { name, size }]) => (
            <div
              className={classnames('selected-file', {
                'selected-file_disabled': disabled,
              })}
              key={id}>
              <div className='selected-file__icon'>
                <img alt='file-icon' src={getFileFormatIcon(name)} />
              </div>
              <div className='selected-file__name'>{name}</div>
              {size && (
                <div className='selected-file__size'>
                  {/* TODO calculation to function */}
                  {getFileSizeText(size)}
                </div>
              )}
              {!disabled && (
                <div
                  className='selected-file__unselect-button'
                  onClick={(event) => {
                    unselectFile(id);
                    event.stopPropagation();
                  }}>
                  <Icon name='im-Basket' size={11} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {!disabled && (
        <div className='file-size-info'>{`Max file size: ${maxFileSize} MB`}</div>
      )}
      {error && <div className='file-error'>{error}</div>}
    </>
  );
};

export const MultipleFileUploadView = addTranslation(Component);
