import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

import { StoreProps } from 'store';
import {
  ChartViewData,
  DeclineReasonsData,
  IDictionary,
  IFormValues,
  InOutData,
  IOnChange,
  IValidateHandler,
  ModalChartTypes,
} from 'types/Analytics';

import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import {
  AdvancedAnalyticsEntityType,
  AdvancedAnalyticsEntityTypes,
} from './components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import SettingsForm from './components/settingsForm';
import ChartView from './components/chartView';
import NoContent from './components/NoContent';
import Loader from 'components/ui/loader';
import Icon from 'components/ui/icon';
import { InOutTable } from './components/InOutTable/InOutTable';
import { AdvancedAnalyticsTable } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsTable/AdvancedAnalyticsTable';

import './graphDetails.scss';

const CHART_CLASSNAME = 'graph-details__chart-view';
const TABLE_CLASSNAME = 'graph-details__in-out-table';

interface Props extends IntlProps, StoreProps {
  closeModal: () => void;
  saveChanges: () => void;
  setType: (type: AdvancedAnalyticsEntityType) => void;
  selectedType: AdvancedAnalyticsEntityType;
  values: IFormValues;
  options?: ChartViewData | null;
  validationErrors: { [key: string]: string };
  onChange: IOnChange;
  multiSelectItems: {
    projects: IDictionary;
    country: IDictionary;
    currency: IDictionary;
    paymentMethod: IDictionary;
    analyticsBrand: IDictionary;
    paymentMethodGroup: IDictionary;
    successRateTransactionType: IDictionary;
  };
  isLoading: boolean;
  titleToken: string;
  chartTypes: ModalChartTypes;
  validateHandler: IValidateHandler;
  formValidationErrors: { [key: string]: string };
  pristineFields: { [key: string]: boolean };
  submitButtonDisabledConditions: Array<{
    tooltipText: string;
    disabled: boolean;
  }>;
  formValidatedFields: string[];
}

class GraphDetails extends React.PureComponent<Props> {
  render() {
    const {
      getTranslate,
      closeModal,
      saveChanges,
      titleToken,
      submitButtonDisabledConditions,
    } = this.props;

    const submitDisabledConditions = submitButtonDisabledConditions.filter(
      ({ disabled }) => disabled
    );
    const tooltipTextSource = submitDisabledConditions.find(
      ({ tooltipText }) => tooltipText !== undefined
    );

    return (
      <div className='graph-details'>
        <ModalTitle customClass='graph-details__title'>
          {getTranslate(titleToken)}
        </ModalTitle>
        <ModalContent customClass='graph-details__content'>
          {this.renderContent()}
        </ModalContent>
        <ModalFooter customClass='graph-details__footer'>
          <Button
            status='outline'
            onClick={closeModal}
            text={getTranslate('common.cancel.button')}
          />
          <div className='graph-details__submit-button'>
            {tooltipTextSource !== undefined && (
              <Icon
                size={18}
                name='help_outline'
                className={classNames(
                  'payment-action__icon-info',
                  'settings-form__grid-element',
                  'graph-details__submit-tooltip-icon'
                )}
                data-place={'right'}
                tooltip={getTranslate(tooltipTextSource.tooltipText)}
                data-delay-hide='200'
              />
            )}
            <Button
              status='primary'
              onClick={saveChanges}
              text={getTranslate('common.saveContinue.button')}
              disabled={
                // Object.entries(pristineFields).every(
                //   ([_, value]) => value === true
                // ) ||
                // (Object.keys(formValidationErrors).length !== 0 &&
                //   Object.entries(formValidationErrors).every(
                //     ([_, value]) => value !== undefined
                //   )) ||
                submitDisabledConditions.some(({ disabled }) => disabled)
              }
            />
          </div>
        </ModalFooter>
      </div>
    );
  }

  get settingsForm() {
    const {
      values,
      validationErrors,
      selectedType,
      onChange,
      validateHandler,
      formValidationErrors,
      formValidatedFields,
      pristineFields,
      multiSelectItems,
    } = this.props;

    return (
      <SettingsForm
        values={values}
        validationErrors={validationErrors}
        selectedType={selectedType}
        onChange={onChange}
        validateHandler={validateHandler}
        formValidationErrors={formValidationErrors}
        formValidatedFields={formValidatedFields}
        pristineFields={pristineFields}
        {...multiSelectItems}
      />
    );
  }

  get chartView() {
    const { options } = this.props;

    return (
      options && (
        <ChartView
          data={options}
          className={CHART_CLASSNAME}
          shouldResize={false}
        />
      )
    );
  }

  whatToRender() {
    const { selectedType, options, getTranslate, isLoading } = this.props;

    switch (selectedType) {
      case AdvancedAnalyticsEntityType.operations: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.operations.tab')}
            </div>
            <div className='graph-details__wrapper'>
              {this.settingsForm}
              {this.chartView}
              {isLoading && <Loader />}
            </div>
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.inout: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.inOut.tab')}
            </div>
            <div className='graph-details__wrapper'>
              {this.settingsForm}
              <InOutTable
                className={TABLE_CLASSNAME}
                data={options as InOutData}
                header={'analytics.editForm.example.inOutTable.label'}
              />
              {isLoading && <Loader />}
            </div>
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.declineReasons: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.operationsPivot.tab')}
            </div>
            <div className='graph-details__wrapper'>
              {this.settingsForm}
              <InOutTable
                className={TABLE_CLASSNAME}
                data={options as DeclineReasonsData}
                header={'analytics.editForm.example.pivot.label'}
              />
              {isLoading && <Loader />}
            </div>
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.topten: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.top.tab')}
            </div>
            <div className='graph-details__wrapper'>
              {this.settingsForm}
              {this.chartView}
            </div>
            {isLoading && <Loader />}
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.chargeback: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.chargeback.tab')}
            </div>
            {this.renderChargeAndFraudAnalytics()}
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.fraud_report: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.fraud.tab')}
            </div>
            {this.renderChargeAndFraudAnalytics()}
          </div>
        );
      }
      case AdvancedAnalyticsEntityType.success_rate: {
        return (
          <div className='graph-details__chart-settings'>
            <div className='graph-details__label'>
              {getTranslate('analytics.editForm.successRate.tab')}
            </div>
            {this.renderChargeAndFraudAnalytics()}
          </div>
        );
      }
      default: {
        return <NoContent />;
      }
    }
  }

  renderChargeAndFraudAnalytics = () => {
    const { values, options, isLoading } = this.props;

    return (
      <div className='graph-details__wrapper'>
        {this.settingsForm}
        {options &&
        'chartType' in values &&
        values.chartType === 'simple_table' &&
        'data' in options ? (
          <AdvancedAnalyticsTable
            className={TABLE_CLASSNAME}
            data={options}
            header={'analytics.editForm.example.table.label'}
          />
        ) : (
          this.chartView
        )}
        {isLoading && <Loader />}
      </div>
    );
  };

  renderContent() {
    const { selectedType, setType, chartTypes } = this.props;

    return (
      <>
        <AdvancedAnalyticsEntityTypes
          selectedType={selectedType}
          setType={setType}
          chartTypes={chartTypes}
        />
        {this.whatToRender()}
      </>
    );
  }
}

const GraphDetails_ = addTranslation(GraphDetails);
export { GraphDetails_ as GraphDetails };
