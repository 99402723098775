import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { uploadUserFile } from 'api/maf';
import { RootState } from 'store';
import { setBeforeUpdateActions } from 'actions/maf';
import { openModal } from 'actions/modal';
import {
  getFileFieldFromTab,
  getSizeFieldFromTab,
  mapTabsToFiles,
  parseDateFromResponse,
} from './helpers';
import { MAX_FILE_SIZE_MB, MAX_NUMBER_OF_FILE_TO_UPLOAD } from './constants';
import { MafField, MafTab } from 'components/maf/types';
import showNotification from 'components/ui/notification/showNotification';
import { MultipleFileUploadView } from './MultipleFileUploadView';

type Props = {
  error?: string;
  form: MafTab;
  mafId?: string;
  legalEntityId?: string;
  disabled?: boolean;
  fileTypes?: string;
  onChangeFileName: ({
    objectName,
    date,
    fileName,
    field,
    formName,
  }: {
    objectName: string;
    date: string;
    fileName: string;
    field: MafField;
    formName: string;
  }) => void;
  onChangeFileSize: ({
    fileSize,
    field,
    formName,
  }: {
    fileSize: string;
    field: MafField;
    formName: string;
  }) => void;
  onAddFileField: (fileMafTab: MafTab) => MafTab | null;
  onRemoveFileField: (fileMafTab: MafTab) => void;
  setFormAsFailed: () => void;
};

const MultipleFileUpload: React.FC<Props> = ({
  mafId = '',
  legalEntityId,
  form,
  disabled,
  fileTypes,
  error: externalError,
  setFormAsFailed,
  onAddFileField,
  onRemoveFileField,
  onChangeFileName,
  onChangeFileSize,
}) => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<Map<string, File>>(
    mapTabsToFiles(form.tabs)
  );
  const [error, setError] = useState<string>();
  const { actionsBeforeUpdate } = useSelector((state: RootState) => state.maf);

  const getTabById = useCallback(
    (id: string) => {
      return form.tabs.find((tab) => tab.crm_id === id);
    },
    [form.tabs]
  );

  const onDrop: DropzoneOptions['onDrop'] = (filesToSelect: File[]) => {
    if (!filesToSelect.length) {
      setError(
        'Only the following file formats are allowed: docx, doc, rar, zip, pdf, png, jpeg, jpg.'
      );

      return;
    }

    if (filesToSelect.length >= MAX_NUMBER_OF_FILE_TO_UPLOAD) {
      setError(`Maximum number of files is ${MAX_NUMBER_OF_FILE_TO_UPLOAD}.`);

      return;
    }

    const filteredFilesToSelect = filesToSelect.filter((file) => {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setError(`File size must be less than ${MAX_FILE_SIZE_MB} MB`);

        return false;
      }

      return true;
    });

    filteredFilesToSelect.forEach((file) => {
      const createdMafTab = onAddFileField(form);
      if (!createdMafTab) {
        return;
      }

      selectedFiles.set(createdMafTab.crm_id, file);
    });

    setSelectedFiles(new Map(selectedFiles));
  };

  const uploadSelectedFiles = useCallback(() => {
    return Promise.all(
      [...selectedFiles.entries()].map(([id, file]) => {
        if (!file.lastModified) {
          // If file has last modified date, it means that it wasn't uploaded yet
          return Promise.resolve();
        }

        const updatingTab = getTabById(id);
        if (!updatingTab) {
          return Promise.resolve();
        }

        const fileField = getFileFieldFromTab(updatingTab);
        const sizeField = getSizeFieldFromTab(updatingTab);

        return uploadUserFile({
          file,
          mafId,
          legalEntityId,
          attachmentType: fileField?.attach_type_id || '',
          documentType: fileField?.doc_type_id || '',
        })
          .then((result) => {
            const date = parseDateFromResponse(result);
            sizeField &&
              onChangeFileSize({
                fileSize: file.size.toString(),
                formName: updatingTab.tabs[0].name, // Fragile code, but it's the only way to get form name
                field: sizeField,
              });
            fileField &&
              onChangeFileName({
                ...result,
                date,
                formName: updatingTab.tabs[0].name, // Fragile code, but it's the only way to get form name
                field: fileField,
              });
          })
          .catch((e) => {
            if (e.payload?.validationErrors?.file) {
              showNotification({
                status: 'error',
                content: (
                  <>
                    <div>{file.name}</div>
                    <div>{e.payload.validationErrors.file}</div>
                  </>
                ),
              });

              setFormAsFailed();

              selectedFiles.delete(id);
              setSelectedFiles(new Map(selectedFiles));
              onRemoveFileField(updatingTab);

              return Promise.resolve();
            }
            console.error(e);
          });
      })
    );
  }, [
    selectedFiles,
    mafId,
    legalEntityId,
    onChangeFileName,
    onChangeFileSize,
    onRemoveFileField,
    setFormAsFailed,
    getTabById,
  ]);

  useEffect(() => {
    if (selectedFiles.size) {
      actionsBeforeUpdate[form.name] = uploadSelectedFiles;
      dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
    }
  }, [selectedFiles]);

  useEffect(() => {
    return () => {
      delete actionsBeforeUpdate[form.name];
      dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
    };
  }, []);

  useEffect(() => {
    setError(externalError);
  }, [externalError]);

  const unselectFile = (id: string) => {
    const unselectedTab = getTabById(id);

    if (unselectedTab?.__temporal) {
      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            text: getTranslate('businessDocs.deleteItem.modal.text'),
          },
          callback: async (isOK) => {
            if (isOK) {
              selectedFiles.delete(id);
              setSelectedFiles(new Map(selectedFiles));
              unselectedTab && onRemoveFileField(unselectedTab);
            }
          },
        })
      );
    } else {
      unselectedTab && onRemoveFileField(unselectedTab);
    }
  };

  return (
    <MultipleFileUploadView
      error={error}
      onDrop={onDrop}
      selectedFiles={selectedFiles}
      unselectFile={unselectFile}
      maxFileSize={MAX_FILE_SIZE_MB}
      disabled={disabled}
      fileTypes={fileTypes}
    />
  );
};

export { MultipleFileUpload };
