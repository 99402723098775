import { statusThemes } from 'constants/statusList';

class Mapper {
  // eslint-disable-next-line complexity
  static getStatus(serverStatus: string | undefined): string {
    if (!serverStatus) {
      return '';
    }

    switch (serverStatus.toLowerCase()) {
      case 'success':
      case 'done':
      case 'won':
      case 'partially won':
      case 'partially paid':
      case 'partially paid out':
      case 'returned':
      case 'active':
      case 'recurring-active':
      case 'remittance-active':
      case 'remittance-success':
      case 'accepted':
      case 'completed':
        return statusThemes.green;
      case 'retry successful':
        return statusThemes.lightGreen;
      case 'canceled':
      case 'invoice cancelled':
      case 'needs response':
      case 'cancelled':
      case 'in review':
      case 'correction':
      case 'correction is needed':
      case 'pending':
      case 'chargeback':
        return statusThemes.yellow;
      case 'awaiting 3ds result':
      case 'awaiting capture':
      case 'awaiting redirect result':
      case 'awaiting customer':
      case 'remittance-expired':
      case 'remittance-draft':
      case 'remittance-external processing':
      case 'remittance-awaiting approval':
      case 'remittance-awaiting clarification':
      case 'recurring-cancelled':
      case 'recurring-deleted':
      case 'recurring-not_set':
      case 'processing':
      case 'external processing':
      case 'inactive':
      case 'draft':
      case 'archived':
      case 'retries unsuccessful':
      case 'retries canceled':
        return statusThemes.gray;
      case 'refunded':
      case 'refund processing':
      case 'partially refunded':
      case 'reversed':
      case 'refund reversal processing':
      case 'partially reversed':
      case 'waiting':
      case 'waiting for approval':
      case 'request sent':
        return statusThemes.purple;
      case 'external error':
      case 'internal error':
      case 'error':
      case 'blocked':
      case 'denied':
      case 'remittance-denied':
      case 'recurring-failed':
      case 'retries active':
      case 'missing information':
      case 'rejected':
      case 'bank refusal':
        return statusThemes.red;
      case 'expired':
      case 'accepted by merchant':
      case 'accepted by merchant (expired)':
      case 'recurring-expired':
        return statusThemes.violet;
      case 'blacklist':
      case 'deleted':
      case 'fraud':
      case 'decline':
      case 'declined':
      case 'remittance-decline':
      case 'lost':
        return statusThemes.black;
      case 'whitelist':
      case 'retry operation':
        return statusThemes.white;
      case 'invoice sent':
      case 'created':
      case 'remittance-created':
      case 'awaiting payment':
        return statusThemes.blue;
      case 'retry in progress':
        return statusThemes.lightBlue;
      case 'clarification':
      case 'awaiting clarification':
      case 'in progress':
        return statusThemes.teal;
      case 'new':
        return statusThemes.mint;
      default:
        return statusThemes.gray;
    }
  }
}

export default Mapper;
