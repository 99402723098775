import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectedFilters from './SelectedFilters';
import { resetFilter } from 'actions/filters';
import getFiltersValuesText from 'selectors/getFiltersValuesText';
import tableNames from 'constants/tableNames';
import { StoreProps } from 'store';

interface OwnProps {
  name: string;
  isSearchFilters?: boolean;
  onReset?: (filterId) => void;
}

interface ConnectedProps {
  filters: any;
}

type Props = OwnProps & ConnectedProps & StoreProps;

class SelectedFiltersContainer extends Component<Props> {
  render() {
    const { filters } = this.props;

    return (
      <SelectedFilters onResetFilter={this.resetFilter} filters={filters} />
    );
  }

  resetFilter = (filterId) => {
    const { name, onReset, isSearchFilters, dispatch } = this.props;
    if (!isSearchFilters) {
      dispatch(resetFilter(name, filterId));
    }

    onReset && onReset(filterId);
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  const { isSearchFilters } = ownProps;

  return {
    filters:
      ownProps.name === tableNames.payments && isSearchFilters
        ? state.searchFilters.list
        : getFiltersValuesText(state, ownProps.name),
  };
};

export default connect(mapStateToProps)(SelectedFiltersContainer);
