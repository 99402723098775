export const getTooltip = (containerRef, innerSelector: string) => {
  if (!containerRef || !innerSelector) return;
  const content =
    Array.from(containerRef.querySelector(innerSelector)?.children)
      .map((item) => (item as HTMLElement).innerText)
      .filter((item) => !!item)
      .join(', ') || containerRef.querySelector(innerSelector).innerText;

  const styles = window.getComputedStyle(containerRef);
  let padding: string | number = styles.getPropertyValue('padding-left');
  padding = parseInt(padding) * 2;

  const colInnerWidth = containerRef.clientWidth - padding - 1;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = styles.getPropertyValue('font');
    const contentWidth = context.measureText(content).width || 0;
    if (colInnerWidth < contentWidth) {
      //проверка на пробел нулевой ширины, который вставляется через метод setNoChangeWordOrder
      // для сохранения правильного порядка символов
      if (content.charCodeAt(0) === 8206) {
        return content.slice(1);
      }
      return content;
    }
  }
};
