import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';
import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: {
  usersToEdit: AdminUserFields[];
} = {
  usersToEdit: [],
};

const toggleUserSelectoin = (
  userToToggle: AdminUserFields,
  usersToEdit: AdminUserFields[]
) => {
  const userIndex = usersToEdit.findIndex(
    (user) => user.id === userToToggle.id
  );

  if (userIndex === -1) {
    return [...usersToEdit, userToToggle];
  }

  return usersToEdit.filter((user) => user.id !== userToToggle.id);
};

const toggleAllUsersSelection = (
  usersToEdit: AdminUserFields[],
  users: AdminUserFields[]
) => {
  if (usersToEdit.length === users.length) {
    return [];
  }

  return users;
};

export const bunchUserEditing = (
  state = initialState,
  action: ActionReducer
) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.TOGGLE_USER_TO_EDIT_SELECTION:
      return {
        ...state,
        usersToEdit: toggleUserSelectoin(payload.user, state.usersToEdit),
      };

    case ACTIONS.TOGGLE_ALL_USERS_TO_EDIT_SELECTION:
      return {
        ...state,
        usersToEdit: toggleAllUsersSelection(state.usersToEdit, payload.users),
      };

    case ACTIONS.RESET_USERS_TO_EDIT_SELECTION:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};
