import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { isEqual } from 'lodash-es';

import api from 'api/projects';
import { updateProject, updateProjectField } from 'actions/projects';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import PaymentMethods from './PaymentMethods';
import { statusMapper } from '../../utils';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import { AnyObject } from 'types/Common';
import { ValidationErrors } from '../../types';
import { StoreProps } from 'store';

interface ConnectedProps {
  isFetchedFilters: boolean;
  projectId: string;
  status: boolean;
  paymentMethods: AnyObject;
  paymentMethodsStatuses: any[];
  oldData: AnyObject;
  isEditable: boolean;
  canSavePaymentMethods: boolean;
  validationErrors: ValidationErrors;
  isHighRisk: boolean;
  isLowRisk: boolean;
  eShopLink?: string;
  isEshopLinkAvailable: boolean;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

class PaymentMethodsContainer extends PureComponent<Props> {
  componentDidMount() {
    setTimeout(() => Tooltip.rebuild());
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      !isEqual(
        this.props.oldData.paymentMethodsStatuses,
        prevProps.oldData.paymentMethodsStatuses
      )
    ) {
      this.checkChanges();
    }
    if (
      !isEqual(
        prevProps.paymentMethodsStatuses,
        this.props.paymentMethodsStatuses
      )
    ) {
      setTimeout(() => Tooltip.rebuild());
    }
  }

  render() {
    const {
      projectId,
      isFetchedFilters,
      status,
      paymentMethods,
      canSavePaymentMethods,
      paymentMethodsStatuses,
      validationErrors,
      isHighRisk,
      isLowRisk,
      eShopLink,
      isEshopLinkAvailable,
    } = this.props;
    return (
      <PaymentMethods
        projectId={projectId}
        isFetchedFilters={isFetchedFilters}
        status={status}
        paymentMethods={paymentMethods}
        paymentMethodsStatuses={paymentMethodsStatuses}
        editPaymentMethods={this.canEdit()}
        onChangeChannel={this.onChangeChannel}
        canSavePaymentMethods={canSavePaymentMethods}
        onSavePaymentMethods={() => this.onSavePaymentMethods()}
        validationErrors={validationErrors}
        isHighRisk={isHighRisk}
        isLowRisk={isLowRisk}
        eShopLink={eShopLink}
        isEshopLinkAvailable={isEshopLinkAvailable}
      />
    );
  }

  canEdit = () => {
    const {
      isEnabled,
      isDisabledByReason,
      isEditable,
      paymentMethodsStatuses,
    } = this.props;
    return (
      isEditable &&
      (isEnabled(Messages.Project_ChangePaymentMethodStatus) ||
        isDisabledByReason(
          Messages.Project_ChangePaymentMethodStatus,
          permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
        )) &&
      Array.isArray(paymentMethodsStatuses) &&
      paymentMethodsStatuses.length > 0
    );
  };

  onChangeChannel = (method, items) => {
    const { paymentMethodsStatuses, validationErrors, dispatch } = this.props;

    const newStatuses = paymentMethodsStatuses.map((item) => {
      if (item.id !== method) return item;
      return {
        ...item,
        items,
      };
    });

    dispatch(
      updateProject({
        paymentMethodsStatuses: newStatuses,
        validationErrors: {
          ...validationErrors,
          paymentMethodsStatuses: {
            [method]: '',
          },
        },
      })
    ).then(() => {
      Tooltip.hide();
      Tooltip.rebuild();
      this.checkChanges();
    });
  };

  checkChanges = () => {
    const { paymentMethodsStatuses, oldData, dispatch } = this.props;
    if (isEqual(paymentMethodsStatuses, oldData.paymentMethodsStatuses)) {
      dispatch(updateProjectField('canSavePaymentMethods', false));
    } else {
      dispatch(updateProjectField('canSavePaymentMethods', true));
    }
  };

  async onSavePaymentMethods() {
    const { projectId, dispatch } = this.props;
    dispatch(updateProjectField('isLoading', true));

    try {
      await api.changePaymentMethodStatus({
        projectId,
        methods: this.getMethodsToSave(),
      });
    } catch (error) {
      dispatch(updateProjectField('isLoading', false));
    }
  }

  getMethodsToSave = () => {
    const { paymentMethodsStatuses, oldData } = this.props;
    return paymentMethodsStatuses.reduce((dataToSave, method) => {
      const initialMethod = oldData.paymentMethodsStatuses.find(
        ({ id }) => id === method.id
      );
      if (isEqual(method, initialMethod)) return dataToSave;
      return [
        ...dataToSave,
        {
          method: method.id,
          channels: method.items.reduce((result, channel) => {
            const initialChannel = initialMethod.items.find(
              ({ id }) => id === channel.id
            );
            if (isEqual(channel, initialChannel)) return result;
            return [
              ...result,
              {
                channel: channel.id,
                action: channel.isSelected ? statusMapper.on : statusMapper.off,
              },
            ];
          }, []),
        },
      ];
    }, []);
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { projects } = state;
  return {
    isFetchedFilters: state.filtersValues.paymentMethodChannels?.isFetched,
    projectId: projects.project.projectId,
    status: projects.project.status,
    paymentMethods: projects.paymentMethods,
    paymentMethodsStatuses: projects.paymentMethodsStatuses,
    oldData: projects.oldData,
    isEditable: projects.isEditable,
    canSavePaymentMethods: projects.canSavePaymentMethods,
    validationErrors: projects.validationErrors,
    isHighRisk: projects.isApmHighRiskVisible,
    isLowRisk: projects.isApmLowRiskVisible,
    eShopLink: projects.eShopLink,
    isEshopLinkAvailable: state.settings.isEshopLinkAvailable,
  };
};

export default connect(mapStateToProps)(
  addPermissions(PaymentMethodsContainer)
);
