import { MafTab } from 'components/maf/types';
import './simple-slider.scss';

type Props<Slide> = {
  slideRenderer: (slideData: Slide) => React.ReactNode;
  slides: Slide[];
  showingSlideIndex: number;
};

export const SimpleSlider = (
  { slideRenderer, slides, showingSlideIndex }: Props<MafTab> //TODO: use generic type instead of MafTab
) => (
  <div className='simple-slider'>
    <div
      className='simple-slider__slides'
      style={{
        width: `${slides.length * 100}%`,
        left: `${-showingSlideIndex * 100}%`,
      }}>
      {slides.map((slide, index) => {
        return (
          <div
            key={index}
            style={{
              width: `${100 / slides.length}%`,
            }}>
            <div className='simple-slider__slide'>{slideRenderer(slide)}</div>
          </div>
        );
      })}
    </div>
  </div>
);
