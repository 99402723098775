import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, pickBy } from 'lodash-es';

import {
  createRemittance,
  getRecipientAccount,
  updateRemittance,
} from 'api/remittance';
import { addEntityToSaved } from 'actions/savedEntities';
import { openModal } from 'actions/modal';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import RemittanceBuilder from './RemittanceAccountBuilder';
import Loader from 'components/ui/loader';
import initialFields from './initialFields';
import { fieldsConfig } from './config';
import savedEntities from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import { createRoutes } from '../routesMapper';
import Utils from 'helpers/Utils';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import checkFilters from 'helpers/checkFilters';
import { isCustomId } from 'pages/remittance/helpers';
import { AnyObject } from 'types/Common';
import { DictionaryCustomSelect } from 'types/FilterValue';
import { StoreProps } from 'store';
import tableNames from 'constants/tableNames';
import './remittanceBuilder.scss';
import { RouteLeavingGuard } from 'components/routeLeavingGuard';

interface OwnProps {
  id?: string;
}

interface ConnectedProps {
  filtersValues: {
    projectIdAvailable: DictionaryCustomSelect;
    transferCurrencyAvailable: DictionaryCustomSelect;
    countryAvailable: DictionaryCustomSelect;
    allCountries: DictionaryCustomSelect;
  };
  storedSavedEntities: any;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps;

interface State {
  currentStep: number;
  isLoading: boolean;
  isSubmitting: boolean;
  isFieldsDirty: boolean;
  isFetched: boolean;
  fields: any;
  fieldsSnapshot: any;
  validationErrors: AnyObject;
}

@addListeners([
  Messages.Remittance_CreateRecipientAccount,
  Messages.Remittance_UpdateRecipientAccount,
  Messages.Remittance_GetRecipientAccount,
])
class RemittanceAccountBuilderContainer extends PureComponent<Props, State> {
  private isCreate;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: 0,
      fields: initialFields,
      fieldsSnapshot: initialFields,
      isFieldsDirty: false,
      validationErrors: {},
      isSubmitting: false,
      isLoading: Boolean(
        props.id &&
          !props.location.pathname.includes(
            createRoutes[tableNames.remittanceRecipientAccounts]
          )
      ),
      isFetched: false,
    };
    this.isCreate =
      props.id &&
      props.location.pathname.includes(
        createRoutes[tableNames.remittanceRecipientAccounts]
      );
  }

  componentDidMount() {
    checkFilters('remittanceRecipientAccountsBuilder');

    if (this.props.id && !this.isCreate) {
      this.getRemittanceRecipient();
    }

    this.setSavedEntities();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.id !== this.props.id) {
      this.isCreate =
        this.props.id &&
        this.props.location.pathname.includes(
          createRoutes[tableNames.remittanceRecipientAccounts]
        );

      if (!this.isCreate && this.props.id && !this.props.location.search) {
        this.getRemittanceRecipient();
      } else {
        this.handleReset();
      }
    }

    if (
      (!prevProps.storedSavedEntities?.items &&
        this.props.storedSavedEntities?.items) ||
      (!prevState.isFetched && this.state.isFetched)
    ) {
      this.setSavedEntities();
    }
  }

  setCurrentStep = (stepIndex) => {
    this.setState({ currentStep: stepIndex });
  };

  setSavedEntities = () => {
    const { id, dispatch, storedSavedEntities } = this.props;
    const { fields } = this.state;

    if (
      !storedSavedEntities?.isFetch ||
      storedSavedEntities.items.some((entity) => {
        return String(entity.id) === id;
      })
    )
      return;

    let savedEntityParams;

    if (
      !id?.includes(createRoutes[tableNames.remittanceRecipientAccounts]) &&
      fields
    ) {
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: `${id}`,
        hiddenParams: {
          localeKey: this.getCardName(),
        },
      };
    } else {
      if (
        storedSavedEntities.items.some(
          (entity) =>
            entity.id === createRoutes[tableNames.remittanceRecipientAccounts]
        )
      ) {
        return;
      }
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: createRoutes[tableNames.remittanceRecipientAccounts],
        hiddenParams: {
          localeKey: 'remittance.newRecipientAcc.header',
          isCreate: true,
        },
      };
    }

    dispatch(addEntityToSaved(savedEntityParams));
  };

  handleChangeFieldValue = (key: string, value: any) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [key]: value,
        },
        validationErrors: {
          ...prevState.validationErrors,
          [key]: '',
        },
      }),
      this.checkIsFieldsDirty
    );
  };

  checkIsFieldsDirty = () => {
    const { fields, fieldsSnapshot } = this.state;
    const copyFields = { ...fields };
    const isFieldsDirty = !isEqual(copyFields, fieldsSnapshot);

    this.setState({
      isFieldsDirty,
    });
  };

  handleSubmit = async (status: 'draft' | 'created') => {
    const { fields } = this.state;

    if (isNotAvailableForSupport(Messages.Remittance_CreateRecipientAccount))
      return;

    try {
      this.setState({
        isSubmitting: true,
      });
      const payload = {
        ...fields,
        monthlyMinAmount: Number(
          Utils.getNumberWithoutSpace(fields.monthlyMinAmount)
        ),
        transferMaxAmount: Number(
          Utils.getNumberWithoutSpace(fields.transferMaxAmount)
        ),
        paymentNumber: Number(fields.paymentNumber),
        status,
      };

      if (this.isCreate) {
        await createRemittance(pickBy(payload));
      } else {
        await updateRemittance(pickBy(payload));
      }
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  handleReset = () => {
    this.setState({
      currentStep: 0,
      fields: initialFields,
      fieldsSnapshot: initialFields,
      isFieldsDirty: false,
      validationErrors: {},
      isSubmitting: false,
      isLoading: false,
      isFetched: false,
    });
  };

  getRemittanceRecipient = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      await getRecipientAccount(this.props.id);
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({
        isLoading: false,
        isFetched: true,
      });
    }
  };

  getCardName = () => {
    const { id, getTranslate } = this.props;
    const { fields } = this.state;
    if (isCustomId(id)) {
      return `${
        fields.title || getTranslate('remittance.editDraftRecipientAcc.header')
      } #${id}`;
    }
    return getTranslate('remittance.addRecipientAcc.header');
  };

  onEvent = ({ name, data }) => {
    const { dispatch, history } = this.props;

    if (data.payload.validationErrors) {
      this.setState((prevState) => ({
        ...prevState,
        validationErrors: data.payload.validationErrors,
      }));
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          fields: data.payload,
          fieldsSnapshot: data.payload,
          validationErrors: {},
          isFieldsDirty: false,
        }),
        () => {
          if (
            name === Messages.Remittance_CreateRecipientAccount ||
            name === Messages.Remittance_UpdateRecipientAccount
          ) {
            if (data.payload.status === 'draft') {
              dispatch(
                openModal({
                  modalId: 'Notification',
                  content: {
                    title: 'remittance.addRecipientAcc.modal.saveDraft.text',
                  },
                  callback: () => {
                    history.push(`/remittance/recipient-accounts`);
                  },
                })
              );
            } else {
              history.push(`/remittance/recipient-accounts`);
            }
          }
        }
      );
    }
  };

  render() {
    const {
      fields,
      validationErrors,
      isLoading,
      isSubmitting,
      currentStep,
      isFieldsDirty,
    } = this.state;
    const {
      id,
      filtersValues: {
        projectIdAvailable,
        transferCurrencyAvailable,
        countryAvailable,
        allCountries,
      },
    } = this.props;
    const isCreated = fields.status === 'created';
    const fieldItems = Object.values(fieldsConfig).flat();
    const canSave = !isCreated && fieldItems.some((item) => fields[item.id]);
    const canApprove =
      !isCreated &&
      !Object.values(validationErrors).some((i) => i) &&
      fieldItems
        .filter((item) => item.isRequired)
        .every((item) => fields[item.id]);

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <RemittanceBuilder
          fields={fields}
          dictionaries={{
            projectId: projectIdAvailable,
            transferCurrency: transferCurrencyAvailable,
            companyLegalCountry: countryAvailable,
            beneficiaryBankCountry: countryAvailable,
            allCountries: allCountries,
          }}
          id={id}
          isSubmitting={isSubmitting}
          canSave={canSave}
          canApprove={canApprove}
          onChangeField={this.handleChangeFieldValue}
          save={() => this.handleSubmit('draft')}
          approve={() => this.handleSubmit('created')}
          validationErrors={validationErrors}
          setCurrentStep={this.setCurrentStep}
          currentStep={currentStep}
        />
        <RouteLeavingGuard blockWhen={isFieldsDirty} />
      </>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => {
  const getItems = function (list) {
    if (!list) return [];
    return list.map((item) => {
      return {
        value: item.id || item.projectId || '',
        label: item.text,
      };
    });
  };

  return {
    filtersValues: {
      projectIdAvailable: {
        ...state.filtersValues.remittanceProject,
        list: getItems(state.filtersValues.remittanceProject?.list),
      },
      transferCurrencyAvailable: {
        ...state.filtersValues.remittanceTransferCurrency,
        list: getItems(state.filtersValues.remittanceTransferCurrency?.list),
      },
      countryAvailable: {
        ...state.filtersValues.remittanceCountry,
        list: getItems(state.filtersValues.remittanceCountry?.list),
      },
      allCountries: {
        ...state.filtersValues.country,
        list: getItems(state.filtersValues.country?.list),
      },
    },
    storedSavedEntities: state.savedEntities[savedEntities.remittance],
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(RemittanceAccountBuilderContainer))
);
