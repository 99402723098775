import { ModalChartTypes } from 'types/Analytics';
import { AdvancedAnalyticsEntityType } from '../components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import RpcTypes from 'constants/rpcTypes';

export const types: ModalChartTypes = [
  {
    id: AdvancedAnalyticsEntityType.operations,
    icon: {
      name: 'im-Finance',
      className: 'payments',
    },
    title: 'analytics.editForm.operations.tab',
    text: 'analytics.editForm.comment.operations.info',
  },
  {
    id: AdvancedAnalyticsEntityType.declineReasons,
    icon: {
      name: 'im-Analytics-2',
      className: 'revenue',
    },
    title: 'analytics.editForm.operationsPivot.tab',
    text: 'analytics.editForm.comment.operationsPivot.info',
  },
  {
    id: AdvancedAnalyticsEntityType.inout,
    icon: {
      name: 'im-Finance',
      className: 'in-out-report',
    },
    title: 'analytics.editForm.inOut.tab',
    text: 'analytics.editForm.inOutComment.info',
  },
  {
    id: AdvancedAnalyticsEntityType.topten,
    icon: {
      name: 'top10question',
      className: 'top-countries',
    },
    title: 'analytics.editForm.top.tab',
    text: 'analytics.editForm.topComment.info',
  },
  {
    id: AdvancedAnalyticsEntityType.chargeback,
    icon: {
      name: 'im-Chargebacks',
      className: 'chargebacks',
    },
    title: 'analytics.editForm.chargeback.tab',
    text: 'analytics.editForm.chargebackComment.info',
    permission: RpcTypes.Chargeback_List,
  },
  {
    id: AdvancedAnalyticsEntityType.fraud_report,
    icon: {
      name: 'im-Risk',
      className: 'fraud-report',
    },
    title: 'analytics.editForm.fraud.tab',
    text: 'analytics.editForm.fraudComment.info',
    permission: RpcTypes.Rcs_ListFraud,
  },
  {
    id: AdvancedAnalyticsEntityType.success_rate,
    icon: {
      name: 'im-Single-Payout',
      className: 'success-rate',
    },
    title: 'analytics.editForm.successRate.tab',
    text: 'analytics.editForm.successRate.info',
  },
];
