import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { RootState } from 'store';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import savedEntities from 'constants/savedEntities';
import MafContainer from 'components/maf/MafContainer';
import { MafContainerProps } from 'components/maf/types/props';
import { Maf } from 'components/maf/types';
import { BusinessDocumentsFormView } from './BusinessDocumentsFormView';

const BusinessDocumentsForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ legalEntityCaption?: string }>();
  const { legalEntityId } = queryString.parse(location.search);
  const [wasCommited, setCommited] = useState(false);
  const caption = location.state?.legalEntityCaption;

  useEffect(() => {
    setCommited(false);
  }, [id]);

  const storedSavedEntities = useSelector(
    (state: RootState) => state.savedEntities[savedEntities.businessDocs]
  );

  const externalMaf = useMemo(() => {
    return storedSavedEntities?.items.find((entity) => entity.id === id)?.data;
  }, [id, storedSavedEntities]);

  useEffect(() => {
    if (!id || !legalEntityId || !storedSavedEntities) {
      return;
    }

    const currentSavedEntity = storedSavedEntities.items.find(
      (item) => item.id === id
    );

    if (currentSavedEntity) {
      return;
    }

    dispatch(
      addEntityToSaved({
        entityKey: savedEntities.businessDocs,
        id,
        caption,
        urlParams: { legalEntityId },
      })
    );
  }, [dispatch, id, legalEntityId, caption, storedSavedEntities]);

  const updateSavedMaf = (maf: Maf) => {
    dispatch(
      updateEntity({
        entityKey: savedEntities.businessDocs,
        id: maf.crm_id,
        fields: { data: maf },
      })
    );
  };

  const handleNavigate = () => {
    setCommited(false);
    history.push('/maf');
  };

  const handleCommit = (onCommit: () => Promise<boolean | undefined>) => {
    return async () => {
      const commited = await onCommit();

      if (commited) {
        setCommited(true);
      }

      return commited;
    };
  };

  return (
    <MafContainer
      crmId={id}
      legalEntityId={legalEntityId as string}
      onMafLoaded={updateSavedMaf}
      externalMaf={externalMaf}
      withOverviewTab>
      {(props: MafContainerProps) => (
        <BusinessDocumentsFormView
          {...props}
          wasCommited={wasCommited}
          goToMafList={handleNavigate}
          onCommit={handleCommit(props.onCommit)}
        />
      )}
    </MafContainer>
  );
};

export { BusinessDocumentsForm };
