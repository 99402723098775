import React, { PureComponent } from 'react';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import showNotification from 'components/ui/notification/showNotification';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './copyToClipboard.scss';

interface Props extends IntlProps {
  text: string | number;
  iconSize?: number;
  customClass?: any;
  children?: React.ReactNode;
}

class CopyToClipboard extends PureComponent<Props> {
  static defaultProps = {
    iconSize: 16,
    customClass: '',
  };

  render() {
    const { text, iconSize, children, customClass, getTranslate } = this.props;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames('ui-copy-to-clipboard', {
          [customClass]: !!customClass,
        })}>
        <Copy
          onCopy={() =>
            showNotification({
              status: 'success',
              content: getTranslate('common.copiedToClip.popup'),
            })
          }
          text={text}>
          {children || <Icon size={iconSize} name='im-Copy' />}
        </Copy>
      </div>
    );
  }
}

export default addTranslation(CopyToClipboard);
