import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import { WithRouterProps } from 'decorators/withRouter';

import FormView from './FormView';
import checkFilters, { uploadFilter } from 'helpers/checkFilters';
import getDictionaries from 'creators/getDictionaries';
import { Dictionary } from 'types/FilterValue';
import FormFieldsProps from './FormFieldsProps';
import { getDictionariesConfig } from 'components/formFields/helpers';

interface OwnProps extends Omit<FormFieldsProps, 'filtersValues'> {
  title: string;
}

interface ConnectedProps {
  filtersValues: { [key: string]: Dictionary };
}

type Props = OwnProps & ConnectedProps & WithRouterProps;

@wrapAppWithCssClass('layout-app_form')
class FormFieldsContainer extends Component<Props> {
  componentDidMount() {
    this.props.fieldsConfig &&
      checkFilters(getDictionariesConfig(this.props.fieldsConfig));
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const prevConfig =
      prevProps.fieldsConfig && getDictionariesConfig(prevProps.fieldsConfig);
    if (
      this.props.fieldsConfig &&
      !isEqual(prevConfig, getDictionariesConfig(this.props.fieldsConfig))
    ) {
      checkFilters(getDictionariesConfig(this.props.fieldsConfig));
    }
  }

  loadMore = (name) => {
    uploadFilter({ name, offset: this.props.filtersValues[name].list.length });
  };

  render() {
    return (
      <FormView
        id={this.props.id}
        title={this.props.title}
        tabs={this.props.tabs}
        onChangeTab={this.props.onChangeTab}
        text={this.props.text}
        generalTitle={this.props.generalTitle}
        additionalTitle={this.props.additionalTitle}
        fieldsConfig={this.props.fieldsConfig}
        extraConfig={this.props.extraConfig}
        canCreate={this.props.canCreate}
        onChange={this.props.onChange}
        onSubmit={this.props.onSubmit}
        fieldsValues={this.props.fieldsValues}
        validationErrors={this.props.validationErrors}
        backText={this.props.backText}
        backUrl={this.props.backUrl}
        createButtonText={this.props.createButtonText}
        repeatCreateButtonText={this.props.repeatCreateButtonText}
        hiddenFields={this.props.hiddenFields}
        fieldsState={this.props.fieldsState}
        filtersValues={this.props.filtersValues}
        filtersValuesCustom={this.props.filtersValuesCustom}
        isSubmitted={this.props.isSubmitted}
        submittedTitle={this.props.submittedTitle}
        submittedText={this.props.submittedText}
        isLoading={this.props.isLoading}
        link={this.props.link}
        loadMore={this.loadMore}
        onReset={this.props.onReset}
        formFields={this.props.formFields}
        onBeforeChange={this.props.onBeforeChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  const getDictionarySettings = (fields) => {
    if (!fields) return [];
    return fields.reduce((result, field) => {
      if (Array.isArray(field)) {
        return [...result, ...getDictionarySettings(field)];
      }

      if (!field.dictionaryId) return result;

      if (typeof field.dictionaryId === 'object') {
        return [
          ...result,
          {
            ...field.dictionaryId,
            type: field.fieldType,
          },
        ];
      }
      return [
        ...result,
        {
          name: field.dictionaryId,
          type: field.fieldType,
          valueKey: field.dictionaryValueKey,
        },
      ];
    }, []);
  };
  const config =
    ownProps.fieldsConfig &&
    Object.values(ownProps.fieldsConfig)
      .map((value) => value)
      .flat(2);

  return {
    filtersValues: getDictionaries(
      state.filtersValues,
      getDictionarySettings(config)
    ),
  };
};

const FormFieldsContainer_ = connect(mapStateToProps)(FormFieldsContainer);
export default withRouter(FormFieldsContainer_);
