import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

import { cancelInvoice, getInvoices } from 'api/invoices';
import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer';
import { Column, Row } from 'components/ui/table';
import Loader from 'components/ui/loader';
import Switch from 'components/ui/switch';
import Button from 'components/ui/button';

import getConfigurationByName from 'selectors/getConfigurationByName';
import path from 'helpers/path';
import quickFiltersConfig from '../quickFiltersConfig';
import tableNames from 'constants/tableNames';
import { AnyObject } from 'types/Common';

import './invoicesList.scss';

interface ConnectedProps {
  configuration: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  isCanceling: boolean;
}

class InvoicesListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCanceling: false,
    };
  }

  render() {
    const { isCanceling } = this.state;

    return (
      <Fragment>
        <TopPanelContainer
          name={tableNames.invoices}
          isFilterButton={false}
          isConfigurationButton={false}
          button={
            <Button
              status='primary'
              onClick={this.goToCreateForm}
              text={this.props.getTranslate('invoicing.newinvoice.tab')}
              customClass='invoices-create-button'
            />
          }
          filtersConfig={quickFiltersConfig}
        />
        <DataListContainer
          tableName={tableNames.invoices}
          customClass='table-invoices'
          apiRequest={getInvoices}
          rowRender={this.renderRow}
        />
        {isCanceling && <Loader />}
      </Fragment>
    );
  }

  renderRow = (data) => {
    const { configuration } = this.props;

    return (
      <Row>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];
          if (id === 'deactivate') {
            const checked =
              data.transactionActions &&
              data.transactionActions['invoice/cancel']?.isEnabled;
            content = (
              <Switch
                id='deactivate'
                checked={checked}
                onChange={() => this.cancelInvoice(data.transaction_id)}
                disabled={!checked}
              />
            );
          }
          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              content={content || '—'}
              customClass={classNames(`ui-table__column_${id}`, {
                'ui-table__column_fixed': config.fixed,
              })}
              params={{
                ...config,
                valueId: config.id,
                valueType: config.valueType,
              }}
            />
          );
        })}
      </Row>
    );
  };

  async cancelInvoice(transactionId) {
    this.setState({ isCanceling: true });

    try {
      await cancelInvoice({ transactionId });
    } finally {
      this.setState({ isCanceling: false });
    }
  }

  goToCreateForm = () => {
    const { history }: any = this.props;
    history.push(path('/invoices/create'));
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, 'invoices'),
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(InvoicesListContainer))
);
