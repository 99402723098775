/* eslint-disable max-lines */
import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import I18nHelpers from 'i18n/I18nHelpers';
import { commitMaf, getMaf, updateMaf } from 'api/maf';
import {
  fetchMafDictionaries,
  getPreviouslyValidatedTabs,
  setBeforeUpdateActions,
  showValidityForTabs,
  setMafStatus,
} from 'actions/maf';
import { openModal } from 'actions/modal';
import { RootState, StoreProps } from 'store';
import Messages from 'constants/rpcTypes';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners, IListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import { ModalContentType } from 'components/modal/types';
import showNotification from 'components/ui/notification/showNotification';
import { isEmpty } from 'components/ui/table/helpers';
import { MafHelpers } from './helpers';
import { renderChildren } from './helpers/renderChildren';
import { MafOp, MafServerStatus } from './constants';
import { MafContainerProps } from './types/props';
import { MafTabValidators } from './helpers/validators';
import {
  MafAppendix,
  MafField,
  MafInputData,
  MafSection,
  MafState,
  MafTab,
  Maf,
  MafValidationErrors,
  Rule,
  RuleSetName,
} from './types';
import { ModalTabManager } from './services/modalTabManager';
import { BoardingStage } from 'types/BoardingStage';
import { isSafe } from 'redos-detector';
import { debounce } from 'lodash-es';
import { flushSync } from 'react-dom';

interface OwnProps {
  externalMaf?: Maf;
  crmId?: string;
  legalEntityId?: string;
  children: (props: MafContainerProps) => ReactNode;
  onSendData?: (data: Maf) => void;
  withOverviewTab?: boolean;
  onMafLoaded?: (maf: Maf) => void;
}

type ConnectedProps = {
  modal: any;
  actionsBeforeUpdate: MafState['actionsBeforeUpdate'];
  validatedTabs: MafState['validatedTabs'];
};

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps;

interface State {
  modalTab: MafTab | null;
  data: Maf | null;
  mafId: string;
  crmId?: string;
  legalEntityId?: string;
  selectedEntityId: string;
  status?: BoardingStage;
  currentStep: number;
  isUpdating: boolean;
  isCommitting: boolean;
  isCommitted: boolean;
  fetchError: boolean;
  isMafFetching: boolean;
  isValidated: boolean;
  isUpdated: boolean;
  isEdited: boolean;
  validationErrors: MafValidationErrors;
  doesCurrendStepFailed: boolean;
}

const MAF_CONFIRM_MODAL_CLASSNAME = 'maf-confirm-modal';
@addListeners([Messages.Maf_Status, Messages.Maf_Error])
class MafContainer extends Component<Props, State> implements IListeners {
  private formsIds: Record<string, MafTab> = {};
  modifiedTabs: Record<string, boolean>;
  currentStepInitialValues?: MafTab;
  modalTabManager: ModalTabManager = new ModalTabManager(
    (modalTab) => this.setState({ modalTab }),
    this.formsIds
  );

  constructor(props: Props) {
    super(props);
    this.state = {
      data: null,
      modalTab: null,
      mafId: '',
      crmId: this.props.crmId,
      legalEntityId: this.props.legalEntityId,
      isUpdating: false,
      isCommitting: false,
      isCommitted: false,
      isMafFetching: false,
      selectedEntityId: '',
      currentStep: 0,
      fetchError: false,
      isValidated: false,
      isUpdated: false,
      isEdited: false,
      validationErrors: {},
      doesCurrendStepFailed: false,
    };
    this.modifiedTabs = {};
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.props.dispatch(setMafStatus(undefined));
  }

  // In some cases we need to set step as failed forced
  // not because of validation errors
  // but because of some other client logic errors (eg. failed to upload user file)
  forseMarkStepAsFailed = () => {
    this.setState({ doesCurrendStepFailed: true });
  };

  forseUnmarkStepAsFailed = () => {
    this.setState({ doesCurrendStepFailed: false });
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.externalMaf &&
      prevProps.externalMaf?.crm_id !== this.props.externalMaf.crm_id
    ) {
      this.updateState(
        {
          mafData: this.props.externalMaf,
          crmMaf: {
            status: this.props.externalMaf.boarding_status,
          },
          crmMafQueue: {},
        },
        true
      );
      this.props.dispatch(setMafStatus(this.props.externalMaf.boarding_status));
      this.modalTabManager.closeCurrentTab();
      return;
    }

    if (
      !this.props.externalMaf &&
      this.props.crmId &&
      this.props.crmId !== prevProps.crmId
    ) {
      this.setState(
        {
          crmId: this.props.crmId,
          legalEntityId: this.props.legalEntityId,
          isMafFetching: true,
        },
        () => {
          this.fetchMaf();
        }
      );
    }
  }

  render() {
    return <>{renderChildren(this)}</>;
  }

  init = async () => {
    const { dispatch } = this.props;

    dispatch(fetchMafDictionaries());
    if (this.props.externalMaf) {
      this.updateState({
        mafData: this.props.externalMaf,
        crmMaf: {
          status: this.props.externalMaf.boarding_status,
        },
        crmMafQueue: {},
      });
      this.props.dispatch(setMafStatus(this.props.externalMaf.boarding_status));
      return;
    }

    await this.fetchMaf();
  };

  fetchMaf = async () => {
    try {
      if (!this.state.data) {
        this.setState({ isMafFetching: true });
      }

      const maf = await getMaf(this.state.crmId, this.state.legalEntityId);

      this.props.dispatch(setMafStatus(maf.crmMaf.status));
      if (maf.mafData.sections) {
        this.state.data?.crm_id === maf.mafData.crm_id &&
          (await this.updateState(maf));
        this.props.onMafLoaded?.({
          ...maf.mafData,
          boarding_status: maf.crmMaf.status, // TODO: boarding_status were removed from mafData, needs to remove it from Maf interface (Maf Type and refactor all usages)
        });
      } else {
        this.setState({
          isMafFetching: false,
          status: maf.crmMaf.status,
        });
      }
    } catch (error) {
      this.setState({ isMafFetching: false });
    }
  };

  updateState = (maf: MafInputData, isForceUpdate = false): Promise<void> => {
    return new Promise((resolve) =>
      this.setState(
        (prevState) => ({
          isEdited: false,
          data: MafHelpers.prepareRawMafData(maf.mafData),
          mafId: maf.mafData.crm_id,
          status: maf.crmMaf?.status,
          currentStep:
            prevState.status === maf.crmMaf?.status // if status changed - reset step for new form
              ? prevState.currentStep
              : 0,
        }),
        () => {
          const { mafId, modalTab } = this.state;
          const { dispatch } = this.props;
          this.createFormsMap();
          this.modalTabManager.setCurrentTabModified(false);

          if (!this.state.selectedEntityId || isForceUpdate) {
            const validationErrors = this.validateForm();
            if (MafHelpers.beenModified(mafId)) {
              dispatch(getPreviouslyValidatedTabs({ mafId }));
            } else {
              // in case if the maf has already been modified, but validation data is missing from localstorage
              const validTabs = this.state.data?.sections?.[0].tabs?.reduce(
                (acc, val) => ({
                  ...acc,
                  [val.name]: MafHelpers.validateSection(val, validationErrors),
                }),
                {} as Record<string, boolean>
              );
              this.props.dispatch(
                showValidityForTabs({
                  mafValidatedTabs: validTabs || {},
                  mafId,
                })
              );
            }
            this.changeEntity(maf.mafData?.sections?.[0].crm_id);
          }

          for (const section of this.state.data?.sections ?? []) {
            for (const tab of section.tabs ?? []) {
              this.addRequiredTabs(tab);
            }

            for (const appendix of section.appendixes ?? []) {
              for (const tab of appendix.tabs ?? []) {
                this.addRequiredTabs(tab);
              }
            }
          }

          const modalTabFromCrm = this.getTabByName(modalTab?.name);
          if (modalTab && modalTabFromCrm) {
            this.modalTabManager.changeCurrentTab(modalTabFromCrm);
          }
          this.setState({ isMafFetching: false });
          resolve();
        }
      )
    );
  };

  needsNextStep = (validationErrors) => {
    const { currentStep, doesCurrendStepFailed } = this.state;

    if (doesCurrendStepFailed) {
      this.forseUnmarkStepAsFailed(); // drop step changing only once
      return false;
    }

    const entityTabs = this.currentSteps();
    const tabLength =
      (entityTabs?.length || 0) + (this.props.withOverviewTab ? 1 : 0);

    const currentTab = entityTabs?.[currentStep];

    if (
      (currentTab?.__tabGroup
        ? currentTab.tabs?.some((tab) => tab.tab_template)
        : currentTab?.tab_template) &&
      this.state.modalTab
    ) {
      // if user is in a section with ability to add new ubo/signatory, don't take them to the next step; they might want to add another record
      return false;
    }

    if (
      currentTab &&
      !MafHelpers.validateSection(currentTab, validationErrors)
    ) {
      return false;
    }

    return currentStep < tabLength - 1;
  };

  canUpdate = () => {
    const { modalTab, isEdited } = this.state;
    if (modalTab) {
      return this.modalTabManager.isCurrentTabModified;
    }

    return !this.isLocked() && isEdited;
  };

  canCommit = () => {
    const { isUpdated, isEdited, isValidated } = this.state;
    const isModified = isEdited && !isEmpty(this.modifiedTabs);

    const justOpenedAllValid =
      !isUpdated &&
      !isModified &&
      this.isMafValid() &&
      !this.isLocked() &&
      isValidated;

    const forbidSending =
      this.isLocked() || !this.isMafValid() || !isUpdated || isModified;

    return !forbidSending || justOpenedAllValid;
  };

  isLocked = () => {
    const { data } = this.state;
    return Boolean(data?.locked);
  };

  isMafValid = () => {
    const { validationErrors } = this.state;

    const noErrors = isEmpty(validationErrors);
    if (noErrors) {
      return true;
    }

    return Object.values(validationErrors).every(isEmpty);
  };

  update = async () => {
    const { mafId, data, currentStep } = this.state;

    if (
      this.currentTab.__parentFormId &&
      this.formsIds[this.currentTab.__parentFormId].__temporal
    ) {
      this.closeModalTab();
    }

    try {
      this.setState({ isUpdating: true });
      await this.performBeforeUpdateActions();
      this.validateForm();
      await updateMaf(
        mafId,
        MafHelpers.sanitizeFieldValues(data),
        this.state.legalEntityId
      );

      this.markSectionAsModified();
      this.props.dispatch(
        showValidityForTabs({
          mafValidatedTabs: this.modifiedTabs,
          mafId,
        })
      );

      this.modifiedTabs = {};
      if (this.needsNextStep(this.state.validationErrors)) {
        this.changeStep(currentStep + 1, false);
      }

      MafHelpers.markMafAsModified(mafId);

      const isModalTabFieldsValid = MafHelpers.validateSection(
        this.state?.modalTab || undefined,
        this.state.validationErrors
      );

      if (isModalTabFieldsValid) {
        this.closeModalTab();
        return;
      }
    } catch (error) {
      this.setState({ isUpdating: false });
      console.error(error);
    } finally {
      this.setState({ isUpdated: true, isEdited: false });
    }
  };

  confirm = (content: ModalContentType, invertButtons = true) => {
    return new Promise((resolve) => {
      this.props.dispatch(
        openModal({
          modalId: 'MafConfirm',
          needCloseButton: false,
          customClassName: MAF_CONFIRM_MODAL_CLASSNAME,
          content,
          invertButtons,
          callback: (isOK) => {
            resolve(isOK);
          },
        })
      );
    });
  };

  commit = async () => {
    const { mafId, data } = this.state;
    const { onSendData } = this.props;
    if (this.props.externalMaf && !onSendData) {
      console.error('Save handle lost!');
    }
    try {
      await this.performBeforeUpdateActions();
      const validationErrors = {
        ...this.state.validationErrors,
        ...this.validateForm(),
      };
      this.props.dispatch(
        showValidityForTabs({
          mafValidatedTabs: this.modifiedTabs,
          mafId,
        })
      );
      if (!isEmpty(validationErrors)) {
        console.warn('validation errors found: ', validationErrors);
        this.setState({ validationErrors });
        return false;
      }
      const confirmed = await this.confirm(
        {
          title: 'modals.businessDocs.title',
          text: 'modals.businessDocs.text',
          buttonYes: 'modals.businessDocs.button.yes',
          buttonNo: 'modals.businessDocs.button.no',
        },
        false
      );

      if (!confirmed) {
        return false;
      }

      this.setState({ isCommitting: true });
      if (onSendData) {
        if (!data) {
          console.error('Cannot find maf data to send');
        } else {
          await onSendData(MafHelpers.sanitizeFieldValues(data)!);
          this.setState({
            isCommitted: true,
          });
          return true;
        }
      } else {
        await commitMaf(
          mafId,
          MafHelpers.sanitizeFieldValues(data),
          this.state.legalEntityId
        );

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  updateAndCommit = async () => {
    const entityTabs = this.getEntityById(this.state.selectedEntityId)?.tabs;
    const tabLength =
      (entityTabs?.length || 0) + (this.props.withOverviewTab ? 1 : 0);
    const isLastStep = this.state.currentStep === tabLength - 1;

    await this.update(); // wait for state.validationErrors update?

    if (isLastStep) {
      if (!this.isMafValid()) {
        this.goToFirstTabWithValidationError();
      } else if (this.canCommit()) {
        this.commit();
      }
    }
  };

  goToFirstTabWithValidationError = () => {
    const { selectedEntityId, validationErrors } = this.state;
    const selectedEntity = this.getEntityById(selectedEntityId);
    if (!selectedEntity) {
      return;
    }

    for (const [index, tab] of this.currentSteps().entries()) {
      if (!MafHelpers.validateSection(tab, validationErrors)) {
        this.handleChangeStep(selectedEntity, index);
        return;
      }
    }
  };

  performBeforeUpdateActions = async () => {
    const { actionsBeforeUpdate, dispatch } = this.props;
    for (const func of Object.values(actionsBeforeUpdate)) {
      if (typeof func === 'function') {
        this.setState({ isEdited: false });
        const result = await func();
        this.setState({ isEdited: true });

        if (result?.validationErrors && result.formName && result.fieldName) {
          const { validationErrors, formName, fieldName } = result;
          return this.setState((prevState) => {
            const formErrors = prevState.validationErrors[formName] || {};
            return {
              validationErrors: {
                ...prevState.validationErrors,
                [formName]: {
                  ...formErrors,
                  [fieldName]: validationErrors[fieldName],
                },
              },
            };
          });
        }
      }
    }

    dispatch(setBeforeUpdateActions({}));
    flushSync(() => this.setState({ validationErrors: {} }));
  };

  setLock = () => {
    const { data } = this.state;
    if (data) {
      this.setState({
        data: {
          ...data,
          locked: true,
        },
      });
    }
  };

  createFormsMap = () => {
    const { data } = this.state;
    if (!data?.sections) return;

    this.clearFormIds();
    data.sections.forEach((section) => {
      if (section.tabs) {
        this.setFormsIds(section.tabs);
      }
      if (section.appendixes) {
        section.appendixes.forEach((appendix) => {
          if (appendix.tabs) {
            this.setFormsIds(appendix.tabs);
          }
        });
      }
    });
  };

  setFormsIds = (tabs: MafTab[], parentFormId = '') => {
    tabs.forEach((tab) => {
      const name = tab.name || MafHelpers.createFormId(tab);
      tab.name = name;
      tab.__parentFormId = parentFormId;
      this.formsIds[name] = tab;
      if (tab.tabs) {
        this.setFormsIds(tab.tabs, tab.name);
      }
    });
  };

  clearFormIds = () => {
    for (const formId of Object.keys(this.formsIds)) {
      delete this.formsIds[formId];
    }
  };

  getEntityById = (entityId: string) => {
    const { data } = this.state;
    if (!(data && data.sections)) return null;

    for (const section of data.sections) {
      if (section.crm_id === entityId) {
        return section;
      }
      if (section.appendixes) {
        for (const appendix of section.appendixes) {
          if (appendix.crm_id === entityId) {
            return appendix;
          }
        }
      }
    }
    return null;
  };

  openModalTab = (tab: MafTab | null) => {
    if (!tab) return;
    this.modalTabManager.openTab(tab);
  };

  closeModalTab = () => {
    this.modalTabManager.closeCurrentTab();

    const currentTabName = this.currentTab?.name;
    if (!this.props.validatedTabs[currentTabName]) {
      this.unMarkSectionAsModified(currentTabName);
    }
  };

  closeAllModalTabs = () => {
    while (this.modalTabManager.isModalTabOpened) {
      this.closeModalTab();
    }
  };

  handleCloseModalTab = () => {
    if (this.modalTabManager.isCurrentTabModified) {
      this.confirmLeavingTab().then((isOk) => {
        if (isOk) {
          this.closeModalTab();
        }
      });
    } else {
      this.closeModalTab();
    }
  };

  getNextEntityId = (entityId: string) => {
    const { data } = this.state;
    if (!(data && data.sections)) return;

    const { sections } = data;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const isCurrentEntity = section.crm_id === entityId;
      const appendixes = section.appendixes;
      const nextSection = sections[i + 1]?.crm_id;

      if (isCurrentEntity && !appendixes && sections[i + 1]) {
        return nextSection;
      }
      if (appendixes) {
        const currentEntity = appendixes.find(
          (_, j, arr) => j && arr[j - 1]?.crm_id === entityId
        );
        return currentEntity?.crm_id || nextSection;
      }
    }
  };

  changeEntity = (entityId?: string) => {
    this.setState({
      selectedEntityId: entityId || '',
      currentStep: 0,
    });
  };

  currentSteps = () => {
    const { selectedEntityId } = this.state;
    const selectedEntity = this.getEntityById(selectedEntityId);

    return (
      selectedEntity?.tabs?.filter((tab) => {
        if (tab.__tabGroup) {
          return this.fieldState.isVisible(tab.tabs[0], tab.tabs[0]);
        }
        return this.fieldState.isVisible(tab, tab);
      }) || []
    );
  };

  changeStep = (index, restoreTabValues = true) => {
    if (this.state.currentStep === index) return;

    const { selectedEntityId } = this.state;
    const selectedEntity = this.getEntityById(selectedEntityId);
    if (!selectedEntity) {
      return;
    }
    if (this.canUpdate() && restoreTabValues) {
      this.confirmLeavingTab().then((isOk) => {
        if (isOk) {
          this.handleChangeStep(selectedEntity, index, restoreTabValues);
        }
      });
    } else {
      this.handleChangeStep(selectedEntity, index, restoreTabValues);
    }
  };

  confirmLeavingTab = () => {
    return this.confirm({
      title: I18nHelpers.getJsx(
        this.props.getTranslate('modals.businessDocs.cancel.title')
      ),
      text: I18nHelpers.getJsx(
        this.props.getTranslate('modals.businessDocs.cancel.text')
      ),
    });
  };

  handleChangeStep = (
    selectedEntity: MafSection | MafAppendix,
    index,
    restoreTabValues = true
  ) => {
    const tabLength =
      (selectedEntity.tabs?.length || 0) + (this.props.withOverviewTab ? 1 : 0);

    if (index < tabLength) {
      restoreTabValues && this.state.isEdited && this.cancelStepProgress();
      this.setState(
        {
          currentStep: index,
        },
        () => {
          if (this.currentTab) {
            this.currentStepInitialValues = JSON.parse(
              JSON.stringify(this.currentTab)
            );
          }
        }
      );
    } else {
      const validationErrors = this.getTabValidationErrors(selectedEntity);
      if (isEmpty(validationErrors)) {
        this.changeEntity(this.getNextEntityId(this.state.selectedEntityId));
      }
    }
    if (this.modalTabManager.isModalTabOpened) {
      this.closeAllModalTabs();
    }
  };

  getTabByName = (tabName) => {
    if (!tabName) {
      return null;
    }
    return this.formsIds[tabName];
  };

  changeField = (formId: string, key: string, values: any, type?: string) => {
    const { isValidated } = this.state;
    const form = this.formsIds[formId];

    if (!form) {
      return console.warn(`${formId} was not found in map formsIds!`);
    }

    if (!form.fields) {
      return;
    }

    /*change field*/
    const targetFieldIdx = form.fields.findIndex((field) =>
      type === 'file'
        ? `${field.doc_type_id}${field.attach_type_id}` === key
        : field.name === key
    );

    form.fields.splice(targetFieldIdx, 1, {
      ...form.fields[targetFieldIdx],
      ...values,
      op: MafOp.update,
    });

    const targetField = form.fields[targetFieldIdx];

    form.fields.forEach((field) => {
      if (
        field.filter_rule &&
        field.filter_rule.field.name === targetField.name
      ) {
        field.value = '';
        field.lookup_display_value = '';
        field.op = MafOp.update;
      }
    });

    if (isValidated) {
      this.debouncedValidation(form, targetField);
    }

    this.formsIds[formId] = form;
    const parentTab = this.formsIds[form.__parentFormId || ''];

    if (!parentTab) {
      this.markSectionAsModified();
    } else {
      this.markSectionAsModified(form.name);
    }

    this.setState({
      isEdited: true,
    });
  };

  debouncedValidation = debounce((form, targetField) => {
    const error = this.validateField(form, targetField);

    let tabWithCrmValidators = form;
    let tabError: string | null = null;
    while (tabWithCrmValidators && !tabWithCrmValidators.validators) {
      tabWithCrmValidators =
        this.formsIds[tabWithCrmValidators.__parentFormId || ''];
    }
    if (tabWithCrmValidators?.validators) {
      tabError = this.getCrmTabValidationError(tabWithCrmValidators);
    }

    this.setState((prevState) => {
      const formErrors = prevState.validationErrors[form.name] || {};

      if (error) {
        formErrors[targetField.name] = error;
      } else {
        delete formErrors[targetField.name];
      }

      const validationErrors = {
        ...prevState.validationErrors,
        [form.name]: formErrors,
      };

      if (tabWithCrmValidators) {
        const crmValidationFormErrors =
          prevState.validationErrors[tabWithCrmValidators.name] || {};
        if (tabError) {
          crmValidationFormErrors.tab_error = tabError;
        } else {
          delete crmValidationFormErrors.tab_error;
        }

        validationErrors[tabWithCrmValidators.name] = crmValidationFormErrors;
      }

      return { validationErrors };
    });
  }, 300);

  get currentTab() {
    return this.currentSteps()[this.state.currentStep];
  }

  markSectionAsModified = (formName?: string) => {
    this.modalTabManager.setCurrentTabModified(true);
    if (formName) {
      this.modifiedTabs[formName] = true;
      return;
    }

    const currentTab = this.currentTab;
    if (currentTab?.__tabGroup) {
      for (const tab of currentTab.tabs ?? []) {
        this.modifiedTabs[tab.name] = true;
      }
    } else {
      this.modifiedTabs[currentTab?.name] = true;
    }

    const modalTab = this.modalTabManager.currentTab;
    if (modalTab) {
      this.modifiedTabs[modalTab.name] = true;
    }
  };

  unMarkSectionAsModified = (formName?: string) => {
    this.modalTabManager.setCurrentTabModified(false);
    if (formName) {
      delete this.modifiedTabs[formName];
      return;
    }

    const currentTab = this.currentTab;
    if (currentTab?.__tabGroup) {
      for (const tab of currentTab.tabs ?? []) {
        delete this.modifiedTabs[tab.name];
      }
    } else {
      delete this.modifiedTabs[currentTab?.name];
    }
  };

  createFormByTemplate = (
    parentFormId: string,
    tabTemplate: MafTab,
    fieldValues?: string[]
  ): MafTab | null => {
    const form = this.formsIds[parentFormId];
    if (!form || !tabTemplate) {
      console.warn('Form or tabTemplate not found');
      return null;
    }

    if (!form.tabs) {
      form.tabs = [];
    }

    const uuid = MafHelpers.generateId();
    const newForm: MafTab = JSON.parse(JSON.stringify(tabTemplate));
    newForm.op = MafOp.insert;
    if (form.requires_not_empty_tabs) {
      if (form.tabs.length > 0) {
        for (const subTab of form.tabs) {
          delete subTab.__canRemove;
        }
      } else {
        newForm.__canRemove = false;
      }
    }

    this.prepareNewTab(newForm, uuid, parentFormId, fieldValues);

    newForm.__temporal = true;
    newForm.crm_id = uuid;

    form.tabs.push(newForm);
    this.markSectionAsModified(newForm.name);
    tabTemplate.behaviour_type === 'filebin' &&
      this.setState({ isEdited: true });

    this.setState((state) => {
      const { validationErrors } = state;
      if (
        validationErrors[form.name]?.tab_error === MafHelpers.EMPTY_TAB_ERROR ||
        validationErrors[form.name]?.tab_error ===
          MafHelpers.EMPTY_FILE_TAB_ERROR
      ) {
        delete validationErrors[form.name].tab_error;
      }

      return { ...state, validationErrors };
    });
    return newForm;
  };

  addRequiredTabs = (tab: MafTab) => {
    if (
      tab.requires_not_empty_tabs &&
      tab.tab_template?.item_type === 'inline_edit' &&
      (tab.tabs?.length ?? 0) === 0 &&
      // we dont need an empty file for filebin requires_not_empty_tabs cases
      tab.behaviour_type !== 'filebin'
    ) {
      this.createFormByTemplate(tab.name, tab.tab_template);
    } else if (tab.tabs) {
      for (const subTab of tab.tabs) {
        this.addRequiredTabs(subTab);
      }
    }
  };

  deleteAllTabs = (formName: string) => {
    const form = this.formsIds[formName];
    if (!form) {
      return console.warn('Form or tabTemplate not found');
    }
    MafHelpers.makeTabsDeletable(form);
    this.forceUpdate();
  };

  prepareNewTab = (
    tab: MafTab,
    uuid: string,
    parentFormId: string,
    fieldValues: string[] = []
  ) => {
    tab.__parentFormId = parentFormId;
    tab.name = MafHelpers.createFormId(tab, uuid);
    tab.crm_id = uuid;
    if (tab.visible_rule_set) {
      tab.visible_rule_set.rules.forEach((rule) => {
        rule.field.tab = MafHelpers.createFormId(rule.field.tab, uuid);
      });
    }
    this.formsIds[tab.name] = tab;

    if (tab.tabs) {
      tab.tabs.forEach((innerTab) => {
        innerTab.__parentFormId = tab.name;
        this.prepareNewTab(innerTab, uuid, tab.name, fieldValues);
      });
    } else if (tab.fields) {
      tab.fields = tab.fields.map((field, index) => {
        return {
          ...field,
          value: fieldValues[index] || field.default_value || '',
          op: MafOp.view,
        };
      });
    }
  };

  deleteTab = (form: MafTab) => {
    const { dispatch } = this.props;
    const { mafId } = this.state;
    if (form.__temporal && form.__parentFormId) {
      const parentForm = this.formsIds[form.__parentFormId];
      parentForm.tabs = parentForm.tabs.filter((tab) => tab.name !== form.name);
      delete this.formsIds[form.name];
      this.forceUpdate();
      return;
    }

    dispatch(
      openModal({
        modalId: 'Confirm',
        customClassName: MAF_CONFIRM_MODAL_CLASSNAME,
        content: {
          text: this.props.getTranslate('businessDocs.deleteItem.modal.text'),
        },
        callback: async (isOK) => {
          if (isOK) {
            try {
              form.op = MafOp.delete;
              this.setState({ isUpdating: true }, async () => {
                await this.performBeforeUpdateActions();
                await updateMaf(
                  mafId,
                  MafHelpers.sanitizeFieldValues(this.state.data),
                  this.state.legalEntityId
                );
                MafHelpers.markMafAsModified(mafId);
                this.markSectionAsModified();
              });
            } catch (error) {
              this.setState({ isUpdating: false });
              console.error(error);
            } finally {
              this.setState({ isUpdated: true, isEdited: false });
            }
          }
        },
      })
    );
  };

  deleteForm = (formId: string) => {
    const form = this.formsIds[formId];

    if (!form) {
      return console.warn(`${formId} was not found in map formsIds!`);
    }

    const parentForm = this.formsIds[form.__parentFormId || ''];
    if (parentForm && parentForm.tabs) {
      if (form.__canRemove || form.op === MafOp.insert) {
        parentForm.tabs = parentForm.tabs.filter((tab) => tab.name !== formId);
        delete this.formsIds[formId];
      } else {
        parentForm.tabs.forEach((tab) => {
          if (tab.name === formId) {
            tab.op = MafOp.delete;
          }
        });
        this.setState({ isEdited: true });
      }
    }

    this.markSectionAsModified();
    this.setState({ isEdited: true });
  };

  getTabValidationErrors = (tab, validationErrors = {}) => {
    if (tab.requires_not_empty_tabs) {
      const filterBvdTabs = (currentTab) => !MafHelpers.isBVDTab(currentTab); // bvd tabs come from au10tix
      const filterMarkedForDeletion = (currentTab) =>
        currentTab.op !== MafOp.delete;

      const tabsToValidate = tab.tabs
        ?.filter(filterBvdTabs)
        .filter(filterMarkedForDeletion);

      if (!tabsToValidate?.length) {
        validationErrors[tab.name] = {
          tab_error: MafHelpers.getTabError(tab),
        };
      }
    }
    return this.validateFields(tab, validationErrors);
  };

  invisibleTabMapList = (): { [key: string]: boolean } => {
    const setChildTabsInvisible = (obj, tabs: MafTab[]) => {
      tabs?.forEach((tab) => {
        obj[tab.name] = true;
        if (tab.tabs) {
          setChildTabsInvisible(obj, tab.tabs);
        }
      });
    };

    return Object.values(this.formsIds as { [key: string]: MafTab }).reduce(
      (acc, form) => {
        if (form.__tabGroup) {
          return acc;
        }

        if (!acc[form.name] && !this.fieldState.isVisible(form, form)) {
          acc[form.name] = true;
          setChildTabsInvisible(acc, form.tabs);
        }
        return acc;
      },
      {}
    );
  };

  validateForm = () => {
    const validationErrors: MafValidationErrors = {};

    this.createFormsMap();
    const formIdsToValidate = Object.keys(this.formsIds);

    const invisibleTabs = this.invisibleTabMapList();

    if (!formIdsToValidate.length) {
      return validationErrors;
    }

    formIdsToValidate.forEach((formId) => {
      const form = this.formsIds[formId];

      if (
        !formId ||
        !form ||
        invisibleTabs[formId] ||
        invisibleTabs[form.tabs?.[0]?.name]
      ) {
        return;
      }

      this.getTabValidationErrors(form, validationErrors);
    });

    this.setState((state) => ({
      validationErrors,
      isValidated: true,
      isEdited: !this.props.externalMaf ? false : state.isEdited,
    }));

    return validationErrors;
  };

  validateFields = (form: MafTab, errors: MafValidationErrors = {}) => {
    const { tabs, fields } = form;

    const isTabVisible = this.fieldState.isVisible(form, form);
    if (!isTabVisible) {
      return;
    }

    if (!errors[form.name]?.tab_error) {
      const tabError = this.getCrmTabValidationError(form);
      if (tabError) {
        if (errors[form.name]) {
          errors[form.name].tab_error = tabError;
        } else {
          errors[form.name] = {
            tab_error: tabError,
          };
        }
      }
    }

    if (Array.isArray(tabs)) {
      tabs.forEach((tab) => {
        this.validateFields(tab, errors);
      });
    } else if (Array.isArray(fields)) {
      fields.forEach((field) => {
        if (
          field.type === 'heading_label' ||
          (!this.fieldState.isVisible(form, field) &&
            !field.obligatory_if_invisible)
        ) {
          return;
        }
        let errorMessage = this.validateField(form, field);

        const isFieldEmpty = ['int', 'number'].includes(field.type)
          ? Number(field.value) === 0
          : field.value === '';
        const isRequired: boolean = this.fieldState.isRequired(form, field);
        if (
          !errorMessage &&
          isRequired &&
          isFieldEmpty &&
          !(field.type === 'combo_lookup' && field.lookup_display_value)
        ) {
          errorMessage = MafHelpers.EMPTY_FIELD_ERROR;
        }
        if (
          !errorMessage &&
          isRequired &&
          field.value === 'False' &&
          field.type === 'checkbox'
        ) {
          errorMessage = MafHelpers.EMPTY_FIELD_ERROR;
        }
        if (!errorMessage) {
          return;
        }
        if (errors[form.name]) {
          errors[form.name][field.name] = errorMessage;
        } else {
          errors[form.name] = {
            [field.name]: errorMessage,
          };
        }
      });
    }
    return errors;
  };

  isAllFieldsInvisible = (tab: MafTab, fieldNames: string[]): boolean => {
    return fieldNames?.every((fieldName) => {
      const field = tab.fields?.find((f) => f.name === fieldName);
      if (!field) {
        if (tab.tabs?.length > 0) {
          return tab.tabs.every((tab) =>
            this.isAllFieldsInvisible(tab, fieldNames)
          );
        }

        return true; // if field is not found, it is invisible
      }

      return !this.fieldState.isVisible(tab, field);
    });
  };

  getCrmTabValidationError = (tab: MafTab) => {
    let result = '';
    for (const validator of tab.validators || []) {
      if (this.isAllFieldsInvisible(tab, validator.field_names.split(','))) {
        continue;
      }
      switch (validator.type) {
        case 'sum_validator': {
          result = MafTabValidators.sumValidator(tab, validator);
          break;
        }
        case 'unique_validator': {
          result = MafTabValidators.uniqueValidator(
            tab,
            validator,
            this.props.getTranslate
          );
          break;
        }
        case 'checkbox_group_is_not_false_validator': {
          result = MafTabValidators.someCheckboxIsCheckedValidator(
            tab,
            validator,
            this.props.getTranslate
          );
          break;
        }
        case 'checkboxes_are_checked_at_least_once': {
          result = MafTabValidators.checkboxesAreCheckedAtLeastOnceValidator(
            tab,
            validator,
            this.props.getTranslate
          );
          break;
        }
      }
      if (result) {
        return result;
      }
    }
    return null;
  };

  isBlockedByRule = (form: MafTab, mafField: MafField) => {
    if (!mafField.filter_rule) {
      return false;
    }
    const { field } = mafField.filter_rule;
    const compareTab = this.getTabByName(field.tab) || form;
    const compareField = compareTab?.fields.find((f) => f.name === field.name);
    return !compareField?.value;
  };

  checkRegexp = (regexp: string, value: string) => {
    if (!isSafe(new RegExp(regexp)).safe) {
      return false;
    }

    if (regexp.includes('&&')) {
      return !regexp
        .split('&&')
        .reduce(
          (result: boolean[], item: string) => [
            ...result,
            new RegExp(item).test(value),
          ],
          []
        )
        .includes(false);
    }

    return new RegExp(regexp).test(value);
  };

  validateField = (form: MafTab, field: MafField) => {
    const { validation_rule_set, regexp, value } = field;

    if (value === '' || value === undefined) return '';
    if (!validation_rule_set && !regexp) return '';

    if (regexp && !this.checkRegexp(regexp, value)) {
      return MafHelpers.INVALID_FIELD_ERROR;
    }

    if (validation_rule_set) {
      const ruleValues = validation_rule_set.rules.reduce((acc, rule) => {
        acc[rule.index] = this.checkFieldCondition(form, rule);
        return acc;
      }, {});

      if (
        !MafHelpers.executeExpression(
          validation_rule_set.expression_mask,
          ruleValues
        )
      ) {
        return MafHelpers.getValidationRuleErrorMessage(
          form,
          field,
          this.props.getTranslate.bind(this)
        );
      }
    }

    return '';
  };

  fieldState = {
    isDisabled: (form: MafTab, field: MafField) =>
      this.checkFieldState('blocking_rule_set', form, field) ||
      this.isBlockedByRule(form, field),
    isFieldsDisabledByParentForm: (form: MafTab) => {
      const getRecursiveParentForm = (name?: string): MafTab | null => {
        const currentForm = this.getTabByName(name);
        const parent = currentForm?.__parentFormId;

        if (parent) {
          return getRecursiveParentForm(parent);
        }

        return currentForm;
      };

      const currentForm = getRecursiveParentForm(form.__parentFormId) ?? form;
      const ruleSet = currentForm.blocking_rule_set;

      if (!ruleSet) {
        return false;
      }

      const ruleValues = ruleSet.rules.reduce((acc, rule) => {
        acc[rule.index] = this.checkFieldCondition(form, rule);
        return acc;
      }, {});

      return MafHelpers.executeExpression(ruleSet.expression_mask, ruleValues);
    },
    isRequired: (form: MafTab, field: MafField) =>
      this.checkFieldState('obligatory_rule_set', form, field, field.required),
    isVisible: (form: MafTab, field) => {
      const isVisibleByRule = this.checkFieldCondition(
        form,
        field.visible_rule,
        true
      );
      if (isVisibleByRule && field.visible_rule_set?.rules) {
        return this.checkFieldState('visible_rule_set', form, field);
      }
      if (field.tabs?.length > 0 && field.fields?.length === 0) {
        return field.tabs.some((tab) => this.fieldState.isVisible(field, tab));
      }
      return isVisibleByRule;
    },
  };

  checkFieldState = (
    rule_name: RuleSetName,
    form: MafTab,
    field: MafField,
    defaultValue?: boolean
  ) => {
    const rule_set = field[rule_name];

    if (!rule_set || !rule_set.rules) {
      return defaultValue || false;
    }

    const ruleValues = rule_set.rules.reduce((acc, rule) => {
      acc[rule.index] = this.checkFieldCondition(form, rule);
      return acc;
    }, {});
    return MafHelpers.executeExpression(rule_set.expression_mask, ruleValues);
  };

  checkFieldCondition = (form: MafTab, rule?: Rule, defaultValue = false) => {
    if (!rule) {
      return defaultValue;
    }

    const tab =
      this.getTabByName(rule.field.tab) ||
      this.getTabByName(`${rule.field.tab}_${form.crm_id}`) ||
      form;
    if (!(tab && tab.fields)) {
      return defaultValue;
    }

    const fieldToCompare = MafHelpers.getFieldByName(tab, rule.field.name);

    if (['filled_in', 'not_filled_in'].includes(rule.condition)) {
      return rule.condition === 'filled_in'
        ? MafHelpers.isFieldFilledIn(fieldToCompare)
        : !MafHelpers.isFieldFilledIn(fieldToCompare);
    }

    const fieldToCompareValue = MafHelpers.parseFieldValue(fieldToCompare);
    if (fieldToCompareValue === undefined) {
      return !defaultValue;
    }

    let valueToCheck;

    // Rule.value could be a Field.value or link to another field. Link format is "<ReferenceFieldName>";
    if (rule.value.startsWith('<') && rule.value.endsWith('>')) {
      valueToCheck = MafHelpers.getFieldValueByName(
        tab,
        rule.value.replace(/[<>]+/g, '')
      );
    } else if (
      fieldToCompare?.type === 'int' ||
      fieldToCompare?.type === 'number'
    ) {
      valueToCheck = Number(rule.value);
    } else {
      valueToCheck = rule.value;
    }

    return (
      MafHelpers.checkCondition(
        valueToCheck,
        rule.condition,
        fieldToCompareValue
      ) ?? defaultValue
    );
  };

  onEvent = async ({ name, data: { payload } }) => {
    if (name === Messages.Maf_Status) {
      switch (payload.status) {
        case MafServerStatus.received: {
          if (payload.operation?.endsWith('maf_commit')) {
            showNotification({
              status: 'success',
              content: this.props.getTranslate(
                'businessDocs.sendToValidation.sysmsg'
              ),
            });
            MafHelpers.markMafAsModified(this.state.mafId, false);
            this.setLock();
            this.setState({ isCommitted: true, isCommitting: false });
          }
          await this.fetchMaf();
          // if we fill bvd tab, then validate it before saving - it is still bvd and doesn't count as complete.
          // so, there will be empty tab error unless we validate it once more after fetching maf
          // setTimeout(() => {
          this.validateForm();
          // }, 500);

          break;
        }
        case MafServerStatus.failed:
          showNotification({
            status: 'error',
            content: 'External error, please try again later',
          });
          this.setState({
            isCommitted: false,
            currentStep: 0,
          });
          break;
        default:
          this.fetchMaf();
          break;
      }
      this.setState({ isUpdating: false });
    } else if (name === Messages.Maf_Error && payload.context) {
      this.setState({ isUpdating: false, isCommitting: false });
      const tabKeys = Object.keys(this.formsIds);
      const serverValidationErrors: State['validationErrors'] = {};
      payload.context.forEach((message) => {
        if (message && message.tabName) {
          const tabKey = tabKeys.find((key) => {
            return this.formsIds[
              key
            ].tab_template?.entity_schema_name?.startsWith(message.tabName);
          });
          if (tabKey) {
            serverValidationErrors[tabKey] = { tab_error: message.message };
          } else {
            showNotification({
              status: 'error',
              content: message.message,
            });
          }
        }
      });
      if (!isEmpty(serverValidationErrors)) {
        this.setState(
          (prevState) => ({
            validationErrors: {
              ...prevState.validationErrors,
              ...serverValidationErrors,
            },
          }),
          () =>
            !this.props.withOverviewTab &&
            this.goToFirstTabWithValidationError()
        );
      }
    }
  };

  onBlur = async (field: MafField) => {
    const { mafId, data } = this.state;

    if (field?.behaviour && field.behaviour === 'save_after_focus_lost') {
      try {
        this.setState({ isUpdating: true });
        await updateMaf(
          mafId,
          MafHelpers.sanitizeFieldValues(data),
          this.state.legalEntityId
        );
        this.markSectionAsModified();
        MafHelpers.markMafAsModified(mafId);
      } catch (error) {
        this.setState({ isUpdating: false });
        console.error(error);
      } finally {
        this.setState({ isUpdated: true, isEdited: false });
      }
    }
  };

  onBackAndSaveStep = async (mafId: string) => {
    const { currentStep } = this.state;

    try {
      this.validateForm();
      this.setState({ isUpdating: true });
      this.changeStep(currentStep - 1, false);
      await updateMaf(
        mafId,
        MafHelpers.sanitizeFieldValues(this.state.data),
        this.state.legalEntityId
      );
    } catch (error) {
      this.setState({ isUpdating: false });
      console.error(error);
    } finally {
      this.setState({ isUpdated: true, isEdited: false });
    }
  };

  onBackAndCancelStep = () => {
    const { currentStep } = this.state;
    this.changeStep(currentStep - 1);
  };

  cancelStepProgress = () => {
    this.setState({ isEdited: false });
    MafHelpers.restoreTabValues(this.currentTab, this.currentStepInitialValues);
  };
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  modal: state.modal,
  actionsBeforeUpdate: state.maf.actionsBeforeUpdate,
  validatedTabs: state.maf.validatedTabs,
});

export type MafContainerType = InstanceType<typeof MafContainer>;

export default withRouter(
  connect(mapStateToProps)(addTranslation(MafContainer))
);
