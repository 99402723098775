import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Panel from 'components/ui/panel';
import Button from 'components/ui/button';
import ActionForm from './ActionForm';
import OPERATION_CODES from 'constants/operationCodes';
import { AnyObject } from 'types/Common';
import statuses from 'components/ui/button/statuses';
import RoundButton from 'components/ui/roundButton';
import './action.scss';

interface Props extends IntlProps {
  type: string;
  projectId: string;
  transactionId: string;
  customClass: string;
  data: AnyObject;
  isSending: boolean;
  isOpenedPanel: boolean;
  togglePanel: (isOpened?) => void;
  onSubmit: (amount) => void;
  tooltip?: string;
  buttonTheme?: keyof typeof statuses;
  buttonType?: 'round';
  startPingOnClick?: boolean;
  isEnabled?: boolean;
  containerRef?: string;
  isMenuExpanded?: boolean;
  // to show Action under the sticked column
  selectorPortal?: string;
}

class Action extends PureComponent<Props> {
  private buttonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  private isRound: boolean = this.props.buttonType === 'round';

  componentDidMount(): void {
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps) {
    const { isOpenedPanel } = this.props;
    if (prevProps.isSending && !this.props.isSending) {
      isOpenedPanel && this.props.togglePanel(false);
    }
  }

  getTooltipText = () => {
    const { data, getTranslate } = this.props;

    switch (data.reasonCode) {
      case OPERATION_CODES.REASON_INVALID_PAYMENT_STATUS:
        return getTranslate('payments.single.reason.invalidStatus.popup');
      case OPERATION_CODES.REASON_PAYMENT_PROCESSING:
        if (data.processingStatus) {
          return (
            getTranslate(
              `payments.single.${data.processingOperation}.reason.processing.popup`
            ) +
            ' ' +
            getTranslate(data.processingStatus)
          );
        }
        return getTranslate('payments.single.reason.undefinedProcessing.popup');
      case OPERATION_CODES.REASON_INVALID_PAYMENT_TYPE:
        return getTranslate('payments.single.reason.invalidType.popup');
      case OPERATION_CODES.REASON_DISABLED_IN_GATE:
        return getTranslate('payments.single.reason.disabledInGate.popup');
      case OPERATION_CODES.REASON_INVALID_WL_ID:
        return getTranslate('payments.single.reason.invalidWL.popup');
      case OPERATION_CODES.REASON_INSUFFICIENT_PERMISSIONS:
        return getTranslate('payments.single.reason.invalidPermission.popup');
      case OPERATION_CODES.REASON_TRANSACTION_WITH_CHARGEBACK:
        return getTranslate(
          'payments.single.reason.transactionWithChargeback.popup'
        );
      default:
        return '';
    }
  };

  getType = () => {
    const { type } = this.props;
    return type[0].toUpperCase() + type.slice(1);
  };

  renderContent() {
    const {
      data,
      type,
      togglePanel,
      isOpenedPanel,
      tooltip,
      buttonTheme,
      getTranslate,
    } = this.props;
    const ButtonComponent = this.isRound ? RoundButton : Button;
    const icon = 'im-Refund';

    if (data.isEnabled) {
      return (
        <ButtonComponent
          onClick={() => togglePanel(!isOpenedPanel)}
          size={this.isRound ? 24 : 'normal'}
          status={buttonTheme}
          text={
            this.isRound ? '' : getTranslate(`payments.single.${type}.button`)
          }
          customClass='payment-action__button'
          infoTooltip={tooltip}
          tooltip={
            this.isRound ? getTranslate('payments.registry.refund.popup') : ''
          }
          data-class='tooltip-info_white'
          icon={this.isRound ? icon : ''}
          iconSize={11}
          buttonRef={this.buttonRef}
        />
      );
    }

    const tooltipText = this.getTooltipText();
    const tooltipOptions = {
      'data-place': 'top',
      'data-class': 'tooltip-info_white',
    };

    switch (data.reasonCode) {
      case OPERATION_CODES.REASON_INVALID_PAYMENT_STATUS:
      case OPERATION_CODES.REASON_PAYMENT_PROCESSING:
      case OPERATION_CODES.REASON_INSUFFICIENT_PERMISSIONS:
      case OPERATION_CODES.REASON_INVALID_PAYMENT_TYPE:
      case OPERATION_CODES.REASON_DISABLED_IN_GATE:
      case OPERATION_CODES.REASON_TRANSACTION_WITH_CHARGEBACK:
        return (
          <div {...tooltipOptions} data-tip={tooltipText}>
            <ButtonComponent
              disabled
              size={this.isRound ? 24 : 'normal'}
              status={buttonTheme}
              text={
                this.isRound
                  ? ''
                  : getTranslate(`payments.single.${type}.button`)
              }
              customClass='payment-action__button'
              icon={this.isRound ? icon : ''}
              iconSize={11}
            />
          </div>
        );
      case OPERATION_CODES.REASON_INVALID_WL_ID:
        if (!data?.isExtended) return null;
        return (
          <div {...tooltipOptions} data-tip={tooltipText}>
            <ButtonComponent
              disabled
              size={this.isRound ? 24 : 'normal'}
              status={buttonTheme}
              text={
                this.isRound
                  ? ''
                  : getTranslate(`payments.single.${type}.button`)
              }
              customClass='payment-action__button'
              icon={this.isRound ? icon : ''}
              iconSize={11}
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      data,
      type,
      projectId,
      transactionId,
      customClass,
      isOpenedPanel,
      isSending,
      togglePanel,
      onSubmit,
      selectorPortal,
    } = this.props;

    const panel = (
      <Panel
        id={`payment-${type}-panel`}
        isOpened={isOpenedPanel}
        onClose={() => togglePanel(false)}
        customClass='payment-action__panel ui-panel_payment-action'
        containerStyle={this.isRound ? undefined : { top: 0 }}
        containerRef={this.buttonRef}
        closeOnScroll
        isMenuExpanded={this.props.isMenuExpanded}
        modern>
        <ActionForm
          projectId={projectId}
          transactionId={transactionId}
          onSubmit={onSubmit}
          title={`payments.single.${type}`}
          text={`payments.single.${type}Available`}
          label={`payments.single.choose${this.getType()}Amount`}
          buttonText={`payments.single.${type}.button`}
          isSending={isSending}
          maxValue={data[`availableFor${this.getType()}Amount`]}
          currency={data.currency}
        />
      </Panel>
    );

    let renderPanel: React.ReactNode | React.ReactPortal;

    if (selectorPortal !== undefined) {
      const portalContainer: Element = document.querySelector(selectorPortal)!;
      renderPanel = createPortal(panel, portalContainer);
    } else {
      renderPanel = panel;
    }

    return (
      <div
        className={classNames('payment-action', customClass)}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {this.renderContent()}
        {renderPanel}
      </div>
    );
  }
}

export default addTranslation(Action);
