import React from 'react';
import { utils, writeFile } from 'xlsx';
import moment from 'moment';

import { openModal } from 'actions/modal';
import { removeChart } from 'actions/analytics';

import showNotification from 'components/ui/notification/showNotification';
import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { ChartViewData, ChartFilters } from 'types/Analytics';

import GraphCard from 'pages/analytics/components/GraphItem/GraphCard';
import UIHelpers from 'helpers/ui';
import DateHelpers from 'helpers/Date';
import { isGraph } from 'components/modal/modalList/graphDetails/components/settingsForm/utils';
import DateFormats from 'constants/dateFormats';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { RootState, StoreProps } from 'store';
import { connect } from 'react-redux';
import { AnyObject } from 'types/Common';

const XLS_FILE_PREFIX = {
  [AdvancedAnalyticsEntityType.inout]: 'in-out',
  [AdvancedAnalyticsEntityType.declineReasons]: 'decline-reasons',
  [AdvancedAnalyticsEntityType.fraud_report]: 'fraud-report',
  [AdvancedAnalyticsEntityType.chargeback]: 'chargeback',
};

interface ConnectedProps {
  item: ChartViewData;
  filters: ChartFilters;
}

interface OwnProps {
  id: string;
  className?: string;
  fetched: boolean;
  error?: string;
  order: number;
  layoutType: LayoutTypes;
  isDragging: boolean;
  dragHandler: AnyObject;
}

interface Props extends OwnProps, IntlProps, StoreProps, ConnectedProps {}

class GraphCardContainer extends React.PureComponent<Props> {
  chart;

  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
  }

  componentWillUnmount() {
    this.chart = null;
  }

  render() {
    const {
      item,
      className,
      fetched,
      error,
      layoutType,
      filters,
      isDragging,
      dragHandler,
    } = this.props;

    return (
      <GraphCard
        className={className}
        setRef={this.setRef}
        data={item}
        actionFunction={this.actionFunction}
        fetched={fetched}
        error={error}
        layoutType={layoutType}
        filters={filters}
        isDragging={isDragging}
        dragHandler={dragHandler}
      />
    );
  }

  setRef(ref) {
    const { item } = this.props;

    if ('chartType' in item && isGraph(item.chartType)) {
      this.chart = ref.chart;
    } else {
      this.chart = ref;
    }
  }

  actionFunction = ({ id }) => {
    const { item } = this.props;

    switch (id) {
      case 'edit': {
        const { dispatch } = this.props;

        if ('chartType' in item && 'chartGroup' in item)
          dispatch(
            openModal({
              modalId: 'ModalEditChart',
              content: {
                chartId: item.biChartId,
                chartType: item.chartType,
                graphType: item.chartGroup,
              },
            })
          );
        break;
      }

      case 'delete': {
        const { order, dispatch, id: chartId } = this.props;

        dispatch(removeChart({ order, id: chartId }));

        break;
      }
      case 'fullscreen': {
        if ('chartType' in item && isGraph(item.chartType)) {
          this.chart.fullscreen.open();
          UIHelpers.initResizeEvent();
        } else if (this.chart === undefined) {
          const { getTranslate } = this.props;

          showNotification({
            status: 'error',
            content: getTranslate('analytics.mainView.noData.error'),
          });
        } else {
          this.chart.requestFullscreen();
        }

        break;
      }
      case 'print':
        this.chart.print();
        break;
      case 'download-png':
        this.chart.exportChart({ type: 'image/png' }, item);
        break;
      case 'download-jpeg':
        this.chart.exportChart({ type: 'image/jpeg' }, item);
        break;
      case 'download-pdf':
        this.chart.exportChart({ type: 'application/pdf' }, item);
        break;
      case 'download-svg':
        this.chart.exportChart({ type: 'image/svg+xml' }, item);
        break;
      case 'download-xlsx': {
        if (this.chart === undefined) {
          const { getTranslate } = this.props;

          showNotification({
            status: 'error',
            content: getTranslate('analytics.mainView.noDataFound.error'),
          });

          return undefined;
        }

        const tableEl = this.chart.children[0].cloneNode(true);
        const tableRows: NodeListOf<HTMLTableRowElement> =
          tableEl?.querySelector('tbody')?.children;

        try {
          Array.from(tableRows).forEach((row) => {
            const columns: NodeListOf<HTMLTableDataCellElement> =
              row.querySelectorAll('td');

            Array.from(columns).forEach((cell) => {
              const value: string = cell.innerText;
              if (moment(value, DateFormats.date, true).isValid()) {
                const date = DateHelpers.createDate(value, DateFormats.date);

                cell.innerText = date.format('YYYY-MM-DD');
              } else if (value !== '' && !Number.isNaN(Number(value))) {
                cell.innerText = value.replace(/\./g, ',');
              }
            });
          });

          const xlsxBook = utils.table_to_book(tableEl, {
            raw: true,
          });
          const filePrefix =
            'chartGroup' in item ? XLS_FILE_PREFIX[item.chartGroup] : 'report';

          writeFile(
            xlsxBook,
            `${filePrefix}-${DateHelpers.getFormat(
              DateHelpers.getDate(),
              'datetime'
            )}.xlsx`
          );
        } catch (err) {
          const { getTranslate } = this.props;

          showNotification({
            status: 'error',
            content: getTranslate('analytics.mainView.xlsxProcessing.error'),
          });
        }
      }
    }
  };
}

const mapStateToProps = (
  state: RootState,
  props: OwnProps
): ConnectedProps => ({
  item: state.analytics.chartData.byId[props.id] ?? {},
  filters: state.analytics.chartData.filtersById[props.id],
});

export default connect(mapStateToProps)(addTranslation(GraphCardContainer));
