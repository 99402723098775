import './badge.scss';
import classnames from 'classnames';

type Props = {
  count: number;
  className?: string;
  position?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
};

export const Badge: React.FC<Props> = ({ count, className, position }) => (
  <span
    className={classnames('ui-badge', className)}
    style={{
      top: position?.top,
      right: position?.right,
      bottom: position?.bottom,
      left: position?.left,
    }}>
    {count > 99 ? '99+' : count}
  </span>
);
