import { MafTab } from 'components/maf/types';
import { SimpleSlider } from 'components/maf/components/SimpleSlider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';
import { MafFormProps } from 'components/maf/types/props';

type ChildrenProps = {
  formData: MafTab;
  isPageTabMode?: boolean;
  isCurrrentPageFirst?: boolean;
  isCurrentPageLast?: boolean;
};

type Props = {
  tab: MafTab;
  fieldState: MafFormProps['fieldState'];
  children: (props: ChildrenProps) => React.ReactNode;
};

export const PagesTabManager = ({ children, fieldState, tab }: Props) => {
  const currentPageIndex = useSelector(
    (state: RootState) => state.mafPageTabManager?.currentPageIndex
  );
  const dispatch = useDispatch();

  const pages = tab?.tabs?.filter((innerTab) =>
    fieldState.isVisible(tab, innerTab)
  );

  useEffect(() => {
    dispatch(
      createAction({
        type: ACTIONS.SET_MAF_PAGES,
        payload: { pages },
      })
    );
  }, [pages]);

  if (tab?.item_type !== 'display_tab_by_pages') {
    return <>{children({ formData: tab })}</>;
  }

  return (
    <SimpleSlider
      slides={pages}
      showingSlideIndex={currentPageIndex}
      slideRenderer={(page) =>
        children({
          formData: page,
          isPageTabMode: true,
          isCurrrentPageFirst: currentPageIndex === 0,
          isCurrentPageLast: currentPageIndex === pages.length - 1,
        })
      }
    />
  );
};
