import ReconnectingWebSocket from 'reconnecting-websocket';
import jwtDecode from 'jwt-decode';
import { createJwtToken } from 'api/user';
import getMessageHandler from 'messages';
import Repository from 'helpers/Repository';
import DateHelpers from 'helpers/Date';
import Env from 'helpers/Env';
import { AnyObject } from 'types/Common';

class SocketService {
  private ws: ReconnectingWebSocket | null;
  private jwt: string;

  constructor() {
    this.ws = null;
    this.jwt = '';

    this.getUrl = this.getUrl.bind(this);
  }

  public init() {
    return new Promise<{ isOpened: boolean }>((resolve) => {
      this.setTokenFromUser();

      this.ws = new ReconnectingWebSocket(this.getUrl);

      this.ws.addEventListener('open', () => {
        this.send('auth', { jwt: this.jwt });
        resolve({ isOpened: true });
      });

      this.ws.addEventListener('close', (event) => {
        console.warn(event);
      });

      this.ws.addEventListener('message', (event) => {
        try {
          const data = JSON.parse(event.data);
          const handler = getMessageHandler({
            dispatch: Repository.get('store').dispatch,
            history: Repository.get('history'),
            message: {
              status: 'success',
              ...data,
            },
            response: data,
          });

          handler && handler();
        } catch (error) {
          console.error(error);
        }
      });

      this.ws.addEventListener('error', (error) => {
        console.error(error);
      });
    });
  }

  public destroy() {
    this.ws?.close(1000, 'close connection');
    this.ws = null;
  }

  public send(type, payload = {}) {
    if (!this.ws) {
      return console.error('ws is not defined!');
    }
    this.ws.send(
      JSON.stringify({
        type,
        payload,
      })
    );
  }

  private setTokenFromUser() {
    const store = Repository.get('store');
    const { user } = store.getState();
    this.jwt = user?.jwt;
  }

  private isValidToken(): boolean {
    if (!this.jwt) return false;

    try {
      const jwtDecoded: AnyObject = jwtDecode(this.jwt);
      if (!jwtDecoded.exp) return false;
      const now = +DateHelpers.getDate();
      return jwtDecoded.exp * 1000 > now;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  private async getUrl(): Promise<string> {
    const url = Env.getWsUrl();
    if (!this.isValidToken()) {
      const { jwt } = await createJwtToken();
      this.jwt = jwt;
    }
    return `${url}`;
  }
}

export default new SocketService();
