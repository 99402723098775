import Utils from 'helpers/Utils';
import { v4 as uuidGenerator } from 'uuid';
import DOMPurify from 'dompurify';
import { isEmpty } from 'components/ui/table/helpers';
import LocalStorage from 'helpers/LocalStorage';
import DateHelpers from 'helpers/Date';
import { MafOp } from '../constants';
import {
  Condition,
  MafAppendix,
  MafField,
  MafSection,
  MafStepsType,
  MafTab,
  Maf,
  Rule,
} from '../types';

class MafHelpers {
  /**
   *
   * @param form
   */
  static EMPTY_FIELD_ERROR = 'common.requiredField.error';
  static INVALID_FIELD_ERROR = 'businessdocs.daf.invalid.error';
  static EMPTY_ASSIGNED_SIGNATORY_TAB_ERROR =
    'At least one authorised signatory should be verified';
  static EMPTY_TAB_ERROR = 'add.record.validationinfo.text';
  static EMPTY_FILE_TAB_ERROR = 'add.file.validationinfo.text';

  static getTabError = (tab: MafTab) => {
    if (tab.tabs?.some(MafHelpers.isBVDTab)) {
      return MafHelpers.EMPTY_ASSIGNED_SIGNATORY_TAB_ERROR;
    }

    if (tab.behaviour_type === 'filebin') {
      return MafHelpers.EMPTY_FILE_TAB_ERROR;
    }

    return MafHelpers.EMPTY_TAB_ERROR;
  };

  static formatCardPreviewValue = (field: MafField) => {
    if (!field) {
      return;
    }

    if (field.type === 'date') {
      return DateHelpers.getFormat(
        DateHelpers.createDate(field.value, 'crmDate'),
        'date'
      );
    }

    if (field.type === 'lookup') {
      if (field.lookup_display_value) return field.lookup_display_value;

      return field.enum_values?.find(
        (enumValue) => enumValue.value === field.value
      )?.title;
    }

    return field.lookup_display_value || field.value;
  };

  static isBVDTab(tab: MafTab) {
    return !!tab.tabs?.find((childTab) =>
      childTab.fields?.find(
        (field) => field.type === 'type_of_window' && field.value === 'select'
      )
    );
  }
  static conditionStrings(condition: Rule['condition']) {
    switch (condition) {
      case 'not_equal':
        return 'different from';
      case 'equal':
        return 'same as';
      case 'less_equal':
        return 'less than or equal to';
      case 'greater_equal':
        return 'greater than or equal to';
      case 'less':
      case 'greater':
        return `${condition} than`;
      case 'filled_in':
        return 'is filled in';
      case 'not_filled_in':
        return 'is not filled in';
    }
  }

  static isSignatorySection(section) {
    return section?.caption?.toLowerCase() === MafStepsType.SIGNATORIES;
  }

  static isTabAwaitingClarification(tab: MafTab): boolean {
    // the tab should also be invalid
    return (
      !!tab.tabs?.[0]?.fields?.find(
        (field) => field.type === 'tab_indicator' && field.value === '1'
      ) || false
    );
  }

  static restoreTabValues(tab: MafTab, prevTab?: MafTab): MafTab {
    if (!prevTab) return tab;

    if (tab.behaviour_type === 'filebin') {
      tab.tabs = tab.tabs?.filter((tabItem) => !tabItem.__temporal);
    }

    if (tab.tabs) {
      tab.tabs.forEach((tabItem, index) => {
        MafHelpers.restoreTabValues(tabItem, prevTab.tabs?.[index]);
      });
    }

    tab.fields?.forEach((field, index) => {
      field.value = prevTab.fields[index].value;
      if (field.lookup_display_value) {
        field.lookup_display_value = prevTab.fields[index].lookup_display_value;
      }
    });

    return tab;
  }

  static validateSection(section: MafTab | undefined, validationErrors) {
    if (!section) return false;
    if (isEmpty(validationErrors)) return true;

    if (!isEmpty(validationErrors[section.name])) {
      return false;
    }

    if (section.tabs) {
      return MafHelpers.checkTabValidityRecursive(
        section.tabs,
        validationErrors
      );
    }

    return true;
  }

  static getFieldCaption = (
    tab: MafTab,
    fieldName: string,
    translate: (i18nKey: string) => string
  ) => {
    return translate(
      tab.fields.find((field) => field.name === fieldName)?.caption || ''
    );
  };

  static getValidationRuleErrorMessage = (
    form: MafTab,
    field: MafField,
    translate: (i18nKey: string) => string
  ) => {
    if (field.mismatch_message) return field.mismatch_message;

    let errorString = 'Value must be ';
    field.validation_rule_set?.rules.forEach((rule, index) => {
      errorString += `${index > 0 ? ' and' : ''} ${MafHelpers.conditionStrings(
        rule.condition
      )} "${MafHelpers.getFieldCaption(form, rule.field.name, translate)}"`;
    });
    return errorString + '.';
  };

  static isListTabFieldsEmpty(tab: MafTab) {
    return tab.tabs.every((subTab) =>
      subTab.fields.every((field) => field.value === '')
    );
  }

  static sanitizeTabs = (tabs: MafTab[] | undefined) => {
    if (!tabs) {
      return tabs;
    }
    return tabs.flatMap((tab) => {
      if (tab.__tabGroup) {
        return MafHelpers.sanitizeTabs(tab.tabs);
      }

      if (
        tab.item_type === 'inline_edit' &&
        tab.requires_not_empty_tabs &&
        this.isListTabFieldsEmpty(tab)
      ) {
        tab.tabs = [];
        tab.op = MafOp.view;
      }

      delete tab.__temporal;
      delete tab.__canRemove;
      delete tab.__parentFormId;
      delete tab.__collapsed;

      tab.tabs = MafHelpers.sanitizeTabs(tab.tabs);
      tab.fields?.forEach((field) => {
        field.value = DOMPurify.sanitize(field.value);
      });

      return tab;
    });
  };

  static sanitizeFieldValues = (mafData: Maf | null) => {
    mafData = JSON.parse(JSON.stringify(mafData));
    mafData?.sections?.forEach((section) => {
      section.tabs = MafHelpers.sanitizeTabs(section.tabs);
      delete section.__grouped;

      section.appendixes?.forEach((appendix) => {
        appendix.tabs = MafHelpers.sanitizeTabs(appendix.tabs);
        delete appendix.__grouped;
      });
    });
    return mafData;
  };

  /**
   * example expression - ({2} || {3}) && {1}
   * example values - { 1: false, 2: false, 3: true }
   * instead of 1,2,3 we put values calculated by rules in rule set
   * in the end we get ({false} || {true}) && {false} = false
   * @param expression
   * @param values
   */

  static executeExpression(
    expression: string,
    values: { [key: string]: boolean }
  ) {
    const regex = /{(\d+)}/g;
    const replacedExpression = expression.replace(regex, (_, key) => {
      return String(values[key]);
    });

    const func = new Function('return ' + replacedExpression);

    try {
      return func();
    } catch (e) {
      console.warn(`rule set expression ${expression} is invalid. Error: ${e}`);
      return false;
    }
  }

  static parseFieldValue = (field?: MafField): string | number => {
    if (!field) {
      return '';
    }
    const { type, value } = field;
    if (type === 'date') {
      const [date] = value.split(' ');
      const [month, day, year] = date.split('/');
      return `${day}.${month}.${year}`;
    }

    if (type === 'number' || type === 'int') {
      return Number(value);
    }

    return value || field.default_value || '';
  };

  static getFieldByName = (form: MafTab, searchingFieldName: string) => {
    const field = form.fields.find(
      (currentField) => currentField.name === searchingFieldName
    );
    if (!field) return;

    return field;
  };

  static getFieldValueByName = (form: MafTab, searchingFieldName: string) => {
    return MafHelpers.parseFieldValue(
      MafHelpers.getFieldByName(form, searchingFieldName)
    );
  };

  static checkCondition(
    a: number | string,
    condition: Condition,
    b: number | string
  ): boolean | undefined {
    switch (condition) {
      case 'equal':
        return a === b;
      case 'not_equal':
        return a !== b;
      case 'greater_equal':
        return a >= b;
      case 'greater':
        return a > b;
      case 'less_equal':
        return a <= b;
      case 'less':
        return a < b;
    }
  }

  static isFieldFilledIn(field) {
    /**
     * Поле считается заполенным, если
     * int, number - не равно 0 или не пустое.
     * string - не пустое.
     * lookup - не пустое.
     * combo_lookup - не пусто lookup_display_value или value.
     */
    const { type, value } = field;
    if (value === '') {
      return false;
    }

    switch (type) {
      case 'int':
      case 'number':
        return Number(value) !== 0;
      case 'combo_lookup':
        return (
          field.lookup_display_value !== undefined &&
          field.lookup_display_value !== ''
        );
      default:
        return true;
    }
  }

  static conditionText(condition: Condition): string {
    switch (condition) {
      case 'equal':
        return 'must be equal to';
      case 'not_equal':
        return 'must not equal to';
      case 'greater_equal':
        return 'must be greater than or equal to';
      case 'greater':
        return 'must be greater than';
      case 'less_equal':
        return 'must be less than or equal to';
      case 'less':
        return 'must be less than';
      case 'filled_in':
        return 'must be filled in';
      case 'not_filled_in':
        return 'must not be filled in';
    }
  }

  static makeTabsDeletable = (form: MafTab) => {
    form.tabs?.forEach((tab) => {
      tab.op = MafOp.delete;
    });
  };

  static checkTabValidityRecursive = (tabs: MafTab[], validationErrors) => {
    return tabs.every((tab) => {
      if (!isEmpty(validationErrors[tab.name])) {
        return false;
      }

      if (tab.tabs) {
        return MafHelpers.checkTabValidityRecursive(tab.tabs, validationErrors);
      }

      return true;
    });
  };

  static sortEntities = (
    a: Pick<MafSection | MafAppendix, 'order' | 'caption'>,
    b: Pick<MafSection | MafAppendix, 'order' | 'caption'>
  ) => {
    if (a.order === b.order) {
      return a.caption > b.caption ? 1 : -1;
    }
    return a.order - b.order;
  };

  static groupTabs(entity: MafSection | MafAppendix | MafTab) {
    if (entity.__grouped) {
      return;
    }

    const groups: Map<MafTab['name'], MafTab> = new Map();

    for (const tab of entity.tabs ?? []) {
      const groupId = tab.group_by_tag || tab.name;
      if (!groups.has(groupId)) {
        groups.set(groupId, {
          name: '',
          caption: '',
          crm_id: '',
          fields: [],
          tabs: [],
          op: MafOp.view,
          __tabGroup: true,
        });
      }
      const group = groups.get(groupId)!;
      group.tabs.push(tab);
    }

    entity.tabs = Array.from(groups.values()).map((group) =>
      group.tabs.length === 1 ? group.tabs[0] : group
    );
    entity.__grouped = true;

    const checkInnerTabs = (tab: MafSection | MafAppendix | MafTab) => {
      if (tab.tabs) {
        tab.tabs.forEach((innerTab) => {
          if (innerTab.item_type === 'display_tab_by_pages') {
            MafHelpers.groupTabs(innerTab);
          } else {
            checkInnerTabs(innerTab);
          }
        });
      }
    };

    checkInnerTabs(entity);
  }

  static markMafAsModified(mafId: string, isModified = true) {
    const modifiedMafIds = LocalStorage.get('modifiedMafIds') || {};
    modifiedMafIds[mafId] = isModified;
    LocalStorage.set('modifiedMafIds', modifiedMafIds);
  }

  static prepareRawMafData = (mafData: Maf) => {
    mafData = MafHelpers.sortAndGroupSectionsAppendixesTabs(mafData);
    mafData = MafHelpers.protectLastElements(mafData);
    return mafData;
  };

  static sortAndGroupSectionsAppendixesTabs = (mafData: Maf) => {
    mafData.sections?.sort(MafHelpers.sortEntities);
    mafData.sections?.forEach((section) => {
      MafHelpers.groupTabs(section);

      section.appendixes?.sort(MafHelpers.sortEntities);
      section.appendixes?.forEach((appendix) => {
        MafHelpers.groupTabs(appendix);
      });
    });
    return mafData;
  };

  static protectLastTabElement(tab: MafTab) {
    if (tab.requires_not_empty_tabs && tab.tabs?.length === 1) {
      tab.tabs[0].__canRemove = false;
    } else if (tab.tabs) {
      for (const subTab of tab.tabs) {
        MafHelpers.protectLastTabElement(subTab);
      }
    }
  }

  static protectLastElements(mafData: Maf) {
    for (const section of mafData.sections ?? []) {
      for (const tab of section.tabs ?? []) {
        MafHelpers.protectLastTabElement(tab);
      }

      for (const appendix of section.appendixes ?? []) {
        for (const tab of appendix.tabs ?? []) {
          MafHelpers.protectLastTabElement(tab);
        }
      }
    }

    return mafData;
  }

  static beenModified(mafId: string) {
    const modifiedMafIds = LocalStorage.get('modifiedMafIds');
    return !!modifiedMafIds?.[mafId];
  }

  static generateId() {
    return uuidGenerator().toString();
  }

  /**
   *
   * @param tab
   * @param uuid
   */
  static createFormId(tab: MafTab | string, uuid?: string) {
    let name: string;
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const tabIsMafTab = (checkingTab: MafTab | string): checkingTab is MafTab =>
      (checkingTab as MafTab).name !== undefined;

    if (tabIsMafTab(tab)) {
      name = tab.name;
    } else {
      name = tab;
    }

    if (!uuid) {
      return `${name}_${MafHelpers.generateId()}`;
    }
    return `${name}_${uuid}`;
  }

  static removeTabfromParent(formId: string, parent: MafTab) {
    parent.tabs = parent.tabs?.filter((tab) => tab.name !== formId);
  }
}

export { MafHelpers };
