import { isEmpty } from 'lodash-es';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { IChartDataObject } from 'types/Analytics';
import { ServiceChartDataFormatter } from 'components/modal/modalList/graphDetails/services/ServiceChartDataFormatter';
import {
  IServiceConstructTable,
  IServiceGetColumnsSpanSizeParams,
  IServiceGetRowsSpanSizeParams,
  IServiceGetTableStart,
  IHideTotalItemsAdapter,
} from 'components/modal/modalList/graphDetails/services/types';

const ROWS_COLS_TITLE = {
  currency: 'analytics.editForm.params.groupBy.channelCurrency.item',
  type: 'analytics.editForm.params.groupBy.inOutType.item',
  projectId: 'analytics.editForm.params.groupBy.project.item',
  paymentMethod: 'analytics.editForm.params.groupBy.paymentMethodType.item',
  date: 'analytics.editForm.params.groupBy.operationCompletedAt.item',
  issuerCountry: 'analytics.editForm.params.groupBy.countryByBIN.item',
  country: 'analytics.editForm.params.groupBy.countryByIP.item',
  operationType: 'analytics.editForm.params.groupBy.operationType.item',
  operationStatus: 'analytics.editForm.params.groupBy.operationStatus.item',
  declineReasons:
    'analytics.editForm.params.groupBy.operationResultMessages.item',
  operationTypeDms: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
};

const TOTAL_COUNT_TITLE = {
  amount: 'analytics.mainView.chart.totalChannelAmount.label',
  count: 'analytics.mainView.chart.totalNumber.label',
  channel_amount_in_eur: 'analytics.mainView.chart.totalChannelAmountEUR.label',
  channel_amount_in_usd: 'analytics.mainView.chart.totalChannelAmountUSD.label',
  avg_channel_amount: 'analytics.mainView.chart.avgChannelAmount.label',
  avg_channel_amount_in_eur:
    'analytics.mainView.chart.avgChannelAmountEUR.label',
  avg_channel_amount_in_usd:
    'analytics.mainView.chart.avgChannelAmountUSD.label',
  unique_customers: 'analytics.mainView.chart.uniqueCustomers.label',
};

const COLUMN_TO_CLASS_MAPPING = {
  declineReasons: 'in-out-table__decline-reasons-cell',
};

const generateTotalCell = ({
  cellData,
  hideTotalItemsAdapter,
  chartDataObject,
  colspan,
  rowspan,
}: {
  cellData: unknown;
  hideTotalItemsAdapter: IHideTotalItemsAdapter;
  chartDataObject: IChartDataObject;
  colspan?: number;
  rowspan?: number;
}): string | undefined => {
  if (!hideTotalItemsAdapter(chartDataObject)) {
    return `<td colspan="${colspan}" rowspan="${rowspan}" class="in-out-table_table-bold in-out-table_right">${cellData}</td>`;
  }

  return '';
};

const getCustomCellColumnIndex = (
  cols: string[]
): (() => { index: number; column: string }[]) => {
  const result = Object.entries(COLUMN_TO_CLASS_MAPPING).map(([columnName]) => {
    const foundIndex = cols.findIndex((col) => col === columnName);

    if (foundIndex !== -1) {
      return {
        index: foundIndex,
        column: columnName,
      };
    }

    return undefined;
  });

  return function (): { column: string; index: number }[] {
    return result.filter((item) => item !== undefined) as {
      column: string;
      index: number;
    }[];
  };
};

const getCustomTdClassName = ({
  level,
  classMap,
  levelDelta,
}: {
  level: number;
  classMap: { index; column }[];
  levelDelta: number;
}): string => {
  const customCellLevelObject = classMap.find(
    ({ index }) => level === index - levelDelta
  );

  return customCellLevelObject !== undefined
    ? COLUMN_TO_CLASS_MAPPING[customCellLevelObject.column]
    : '';
};

const ServiceGetCorrectDataKey = (dataKey) =>
  dataKey[0] === '|' ? dataKey.slice(1) : dataKey;

const ServiceGetColumnsSpanSize = ({
  columnsGroup,
  cols,
}: IServiceGetColumnsSpanSizeParams): {
  resultSpanAmount: { [key: string]: number };
  data: Array<Array<{ key: string; size: number }>>;
  colKeys: Array<string>;
} => {
  let colKeys: Array<string> = [];
  const data: Array<Array<{ key: string; size: number }>> = cols.map(() => []);

  const calculateColumnsSpanSize = (
    val,
    countObj = { total: 0 },
    level = 0,
    colKey = ''
  ) => {
    if (Array.isArray(val)) {
      val.forEach((key) => {
        countObj[key] = val.length;
        data[level].push({ key, size: 1 });

        colKeys = [...colKeys, `|${key}`];
      });

      return val.reduce(
        (acc, key) => {
          acc[key] = { total: 1 };

          return acc;
        },
        { total: val.length }
      );
    }

    Object.keys(val).forEach((key) => {
      if (Array.isArray(val[key])) {
        countObj[key] = val[key].length;
        data[level].push({ key, size: val[key].length });

        colKeys = [
          ...colKeys,
          ...val[key].map((elem) => `${colKey}|${key}|${elem}`),
        ];

        data[level + 1] = [
          ...data[level + 1],
          ...val[key].map((elem) => ({ key: elem, size: 1 })),
        ];
      } else {
        countObj[key] = calculateColumnsSpanSize(
          val[key],
          countObj[key],
          level + 1,
          colKey + '|' + key
        );
        data[level].push({ key, size: countObj[key].total });
      }
    });

    let sum = 0;
    Object.keys(countObj).forEach(
      // eslint-disable-next-line no-return-assign
      (key) => (sum += countObj[key].total || countObj[key])
    );

    countObj.total = sum;

    return countObj;
  };

  const resultSpanAmount = calculateColumnsSpanSize(columnsGroup);

  return { resultSpanAmount, data, colKeys };
};

const ServiceGetRowsSpanSize = ({
  rowsGroup,
  table: tableArg,
  colKeys,
  tableValues,
  totalRows,
  result,
  rowsLabels,
  hideTotalItemsAdapter,
  chartDataObject,
  customCellColumnIndexMapCallback,
}: IServiceGetRowsSpanSizeParams): string => {
  let rowsCounter = 0;
  let table = tableArg;
  let rowKeys: Array<string> = [];
  let firstColumnIfNoColumnAdded = false;
  const customCellColumnIndexMap = customCellColumnIndexMapCallback();

  const calculateRowSpanSize = (
    val,
    countObj = { total: 0 },
    level = 0,
    rowKey = ''
  ) => {
    const customTdClassName = getCustomTdClassName({
      level,
      classMap: customCellColumnIndexMap,
      levelDelta: 1,
    });

    if (Array.isArray(val)) {
      val.forEach((elem, index) => {
        table += `<tr>`;

        if (
          index === 0 &&
          colKeys.length === 0 &&
          !firstColumnIfNoColumnAdded
        ) {
          table += `<td colspan="1" class="in-out-table_table-bold" rowspan="NO_COLUMNS_ROWSPAN">${rowsLabels}</td>`;

          firstColumnIfNoColumnAdded = true;
        }

        table += `<td class=${customTdClassName}>${elem}</td>`;

        for (let i = 0; i < result.total; i++) {
          const curValue = tableValues[elem + colKeys[i]] || 0;
          table += `<td>${curValue}</td>`;
        }

        if (!isEmpty(totalRows)) {
          table +=
            generateTotalCell({
              cellData: totalRows[elem],
              hideTotalItemsAdapter,
              chartDataObject,
            }) + '</tr>';
        }

        rowsCounter++;
      });

      return { total: rowsCounter };
    }

    Object.keys(val).forEach((key, keyIndex) => {
      if (Array.isArray(val[key])) {
        countObj[key] = val[key].length;

        if (
          keyIndex === 0 &&
          colKeys.length === 0 &&
          !firstColumnIfNoColumnAdded
        ) {
          table += `<td colspan="1" class="in-out-table_table-bold" rowspan="NO_COLUMNS_ROWSPAN">${rowsLabels}</td>`;

          firstColumnIfNoColumnAdded = true;
        }

        table += `<td rowspan="${val[key].length}">${key}</td>`;

        rowKeys = [
          ...rowKeys,
          ...val[key].map((elem) => `${rowKey}|${key}|${elem}`),
        ];

        if (val[key].length > 0) {
          table += val[key].reduce((acc, elem, index) => {
            if (index === 0) {
              acc += `<td class=${customTdClassName}>${elem}</td>`;
            } else {
              acc += `<tr><td class=${customTdClassName}>${elem}</td>`;
            }

            for (let i = 0; i < result.total; i++) {
              const dataKey = ServiceGetCorrectDataKey(
                rowKeys[rowsCounter] + colKeys[i]
              );
              const curValue = tableValues[dataKey] || 0;

              acc += `<td>${curValue}</td>`;
            }

            if (!isEmpty(totalRows)) {
              acc += generateTotalCell({
                cellData:
                  totalRows[ServiceGetCorrectDataKey(rowKeys[rowsCounter])],
                hideTotalItemsAdapter,
                chartDataObject,
              });
            }

            if (index !== 0 || index === val[key].length - 1) {
              acc += '</tr>';
            }

            rowsCounter++;
            return acc;
          }, '');
        }
      } else {
        if (level === keyIndex) {
          table += '<tr>';
        }

        if (
          level === 0 &&
          keyIndex === 0 &&
          colKeys.length === 0 &&
          !firstColumnIfNoColumnAdded
        ) {
          table += `<td colspan="1" class="in-out-table_table-bold" rowspan="NO_COLUMNS_ROWSPAN">${rowsLabels}</td>`;

          firstColumnIfNoColumnAdded = true;
        }

        table += `<td rowspan="ROWSPAN${level}-${keyIndex}">${key}</td>`;

        countObj[key] = calculateRowSpanSize(
          val[key],
          countObj[key],
          level + 1,
          rowKey + '|' + key
        );

        table = table.replace(
          `ROWSPAN${level}-${keyIndex}`,
          countObj[key].total
        );
      }
    });

    let sum = 0;
    Object.keys(countObj).forEach(
      // eslint-disable-next-line no-return-assign
      (key) => (sum += countObj[key].total || countObj[key])
    );

    countObj.total = sum;

    return countObj;
  };

  const countObj = calculateRowSpanSize(rowsGroup);

  table =
    colKeys.length === 0
      ? table.replace(`NO_COLUMNS_ROWSPAN`, String(countObj.total))
      : table;

  return table;
};

const ServiceGetTableStart = ({
  cols,
  rows,
  totalAmountLabel,
  totalSum,
  getTranslate,
  headerColspan,
  hideTotalItemsAdapter,
  chartDataObject,
  totalRows,
}: IServiceGetTableStart): string => {
  const headerColspanList: number[] = [];

  for (let i = 1; i <= headerColspan - 1; ++i) {
    headerColspanList.push(i);
  }

  const colspanTds = headerColspanList
    .map((_) => '<td class="in-out-table__td-colspan"></td>')
    .join('');

  let table = `<table><tr><td colspan=${
    cols.length !== 0 ? rows.length : 1
  } rowspan=1 class="in-out-table_table-bold">${totalAmountLabel}</td><td class="in-out-table__td-colspan in-out-table_table-bold">${cols
    .map((col) => getTranslate(ROWS_COLS_TITLE[col]))
    .join(', ')}</td> ${colspanTds}${
    !isEmpty(totalRows) || totalSum !== undefined
      ? generateTotalCell({
          cellData: totalAmountLabel,
          hideTotalItemsAdapter,
          colspan: 1,
          rowspan: cols.length + 1,
          chartDataObject,
        })
      : ''
  }</tr>`;
  if (cols.length !== 0) {
    table += `<tr><td class="in-out-table_table-bold" colspan=${
      rows.length
    } rowspan=${cols.length}>${rows
      .map((row) => getTranslate(ROWS_COLS_TITLE[row]))
      .join(', ')}</td>`;
  }

  return table;
};

const getTotalAmountLabel = ({
  chartDataObject,
}: {
  chartDataObject: IChartDataObject;
}): string => {
  if (chartDataObject.chartGroup === AdvancedAnalyticsEntityType.inout) {
    const { chartData } = chartDataObject;

    return TOTAL_COUNT_TITLE[chartData];
  } else if (
    chartDataObject.chartGroup === AdvancedAnalyticsEntityType.declineReasons
  ) {
    const chartData = ServiceChartDataFormatter.normalizeChartData({
      data: chartDataObject,
    });

    return TOTAL_COUNT_TITLE[chartData];
  }

  return '';
};

const ServiceConstructTable = ({
  rows,
  cols,
  columnsGroup,
  rowsGroup,
  tableValues,
  totalColumns,
  totalRows,
  totalSum,
  getTranslate,
  hideTotalItemsAdapter,
  chartDataObject,
}: IServiceConstructTable): string => {
  let table = '';
  let colKeys: Array<string>;
  let resultSpanAmount: { [p: string]: number };
  const totalAmountLabel = getTranslate(
    getTotalAmountLabel({ chartDataObject })
  );

  if (columnsGroup.length !== 1) {
    const {
      resultSpanAmount: _resultSpanAmount,
      data,
      colKeys: _colKeys,
    } = ServiceGetColumnsSpanSize({
      columnsGroup,
      cols,
    });

    colKeys = _colKeys;
    resultSpanAmount = _resultSpanAmount;

    let isFirst = true;
    const headerColspan =
      _colKeys.length !== 0 ? _resultSpanAmount.total : rows.length;

    table = ServiceGetTableStart({
      cols,
      rows,
      totalAmountLabel,
      getTranslate,
      headerColspan,
      hideTotalItemsAdapter,
      chartDataObject,
      totalSum,
      totalRows,
    });

    const customCellColumnTdIndexMapCallback = getCustomCellColumnIndex(cols);

    data.forEach((keys, index) => {
      if (isFirst) {
        isFirst = false;
      } else {
        table += '<tr>';
      }

      const customTdClassName = getCustomTdClassName({
        level: index,
        classMap: customCellColumnTdIndexMapCallback(),
        levelDelta: 0,
      });

      keys.forEach(({ key, size }) => {
        table += `<td class="${customTdClassName}" colspan="${size}">${key}</td>`;
      });

      table += '</tr>';
    });
  } else {
    table = ServiceGetTableStart({
      cols,
      rows,
      totalAmountLabel,
      getTranslate,
      headerColspan: 1,
      hideTotalItemsAdapter,
      chartDataObject,
      totalSum,
      totalRows,
    });

    table += `<td>${columnsGroup[0]}</td></tr>`;
    resultSpanAmount = { total: 1 };
    colKeys = [`|${columnsGroup[0]}`];
  }

  const rowsLabels = `${rows
    .map((row) => getTranslate(ROWS_COLS_TITLE[row]))
    .join(', ')}`;

  const customCellColumnIndexMapCallback = getCustomCellColumnIndex(rows);

  table = ServiceGetRowsSpanSize({
    rowsGroup,
    table,
    colKeys,
    tableValues,
    totalRows,
    totalSum,
    result: resultSpanAmount,
    rowsLabels,
    hideTotalItemsAdapter,
    chartDataObject,
    customCellColumnIndexMapCallback,
  });

  if (!isEmpty(totalColumns)) {
    table += `
      <tr>
        ${generateTotalCell({
          cellData: totalAmountLabel,
          hideTotalItemsAdapter,
          chartDataObject,
          colspan: cols.length !== 0 ? rows.length : rows.length + 1,
        })}

        ${colKeys.reduce((acc, key) => {
          acc += generateTotalCell({
            cellData: totalColumns[ServiceGetCorrectDataKey(key)],
            hideTotalItemsAdapter,
            chartDataObject,
          });

          return acc;
        }, '')}
        
        ${
          totalSum
            ? generateTotalCell({
                cellData: totalSum,
                hideTotalItemsAdapter,
                chartDataObject,
              })
            : ''
        }
           
      </tr>
    `;
  }

  return table;
};

export {
  ServiceGetColumnsSpanSize,
  ServiceGetRowsSpanSize,
  ServiceGetCorrectDataKey,
  ServiceConstructTable,
};
