export const fieldsConfig: {
  [key: number]: {
    id: string;
    placeholder: string;
    label: string;
    type: string;
    isRequired?: boolean;
    tooltip?: string;
    options?: any;
  }[];
} = {
  0: [
    {
      placeholder: 'remittance.addRecipientAcc.field.title.placeholder',
      label: 'remittance.addRecipientAcc.field.title.label',
      id: 'title',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.projectID.placeholder',
      label: 'remittance.addRecipientAcc.field.projectID.label',
      id: 'projectId',
      isRequired: true,
      type: 'select',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.currency.placeholder',
      label: 'remittance.addRecipientAcc.field.currency.label',
      id: 'transferCurrency',
      type: 'buttonGroup',
    },
  ],
  1: [
    {
      placeholder: 'remittance.addRecipientAcc.field.beneficName.placeholder',
      label: 'remittance.addRecipientAcc.field.beneficName.label',
      id: 'beneficiaryName',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.beneficAcc.placeholder',
      label: 'remittance.addRecipientAcc.field.beneficAcc.label',
      id: 'beneficiaryAccount',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.bankName.placeholder',
      label: 'remittance.addRecipientAcc.field.bankName.label',
      id: 'beneficiaryBankName',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.bankCode.placeholder',
      label: 'remittance.addRecipientAcc.field.bankCode.label',
      id: 'beneficiaryBankCode',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.bankAddress.placeholder',
      label: 'remittance.addRecipientAcc.field.bankAddress.label',
      id: 'beneficiaryBankAddress',
      type: 'text',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.bankCountry.placeholder',
      label: 'remittance.addRecipientAcc.field.bankCountry.label',
      id: 'beneficiaryBankCountry',
      type: 'select',
    },
  ],
  2: [
    {
      placeholder:
        'remittance.addRecipientAcc.field.companyCountry.placeholder',
      label: 'remittance.addRecipientAcc.field.companyCountry.label',
      id: 'companyLegalCountry',
      isRequired: true,
      type: 'select',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.companyCity.placeholder',
      label: 'remittance.addRecipientAcc.field.companyCity.label',
      id: 'companyLegalCity',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder:
        'remittance.addRecipientAcc.field.companyAddress.placeholder',
      label: 'remittance.addRecipientAcc.field.companyAddress.label',
      id: 'companyLegalAddress',
      isRequired: true,
      type: 'text',
    },
    {
      placeholder:
        'remittance.addRecipientAcc.field.companyPostCode.placeholder',
      label: 'remittance.addRecipientAcc.field.companyPostCode.label',
      id: 'companyPostCode',
      type: 'text',
    },
  ],
  3: [
    {
      placeholder: 'remittance.addRecipientAcc.field.partnerDescr.placeholder',
      label: 'remittance.addRecipientAcc.field.partnerDescr.label',
      id: 'partnershipDescription',
      isRequired: true,
      type: 'textarea',
    },
    {
      placeholder:
        'remittance.addRecipientAcc.field.minAmountMonth.placeholder',
      label: 'remittance.addRecipientAcc.field.minAmountMonth.label',
      id: 'monthlyMinAmount',
      isRequired: true,
      type: 'amount',
    },
    {
      placeholder:
        'remittance.addRecipientAcc.field.maxAmountTransfer.placeholder',
      label: 'remittance.addRecipientAcc.field.maxAmountTransfer.label',
      id: 'transferMaxAmount',
      isRequired: true,
      tooltip: 'remittance.addRecipientAcc.maxAmountTransfer.tooltip',
      type: 'amount',
    },
    {
      placeholder: 'remittance.addRecipientAcc.field.paymentNumber.placeholder',
      label: 'remittance.addRecipientAcc.field.paymentNumber.label',
      id: 'paymentNumber',
      tooltip: 'remittance.addRecipientAcc.paymentNumber.tooltip',
      options: {
        numericOnly: true,
      },
      isRequired: true,
      type: 'text',
    },
  ],
};

export const steps = [
  {
    title: 'remittance.addRecipientAcc.stepper.generalInfo',
    icon: 'im-Forms',
  },
  { title: 'remittance.addRecipientAcc.stepper.recipient', icon: 'im-Avatar' },
  {
    title: 'remittance.addRecipientAcc.stepper.companyAddress',
    icon: 'im-Document',
  },
  {
    title: 'remittance.addRecipientAcc.stepper.legalInfo',
    icon: 'im-Reports',
  },
];
