import { openModal } from 'actions/modal';
import Icon from 'components/ui/icon';
import { ButchUserEditMode, UserEditingResult } from '../types';
import { Dispatch } from 'react';
import { SimpleList } from '../components/SimpleList';
import { SuccessModalContent } from '../components/SuccessModalContent';

export const openSuccessModal = ({
  dispatch,
  editMode,
  userEditingResult,
}: {
  dispatch: Dispatch<any>;
  editMode: ButchUserEditMode;
  userEditingResult: UserEditingResult;
}) => {
  dispatch(
    openModal({
      modalId: 'Notification',
      content: {
        icon: (
          <div className='butch-user-edit-modal__icon butch-user-edit-modal__icon_success'>
            <Icon name='im-Check-mark' size={22} />
          </div>
        ),
        title:
          editMode === 'add'
            ? 'myteam.butchEdit.usersSuccessfullyAdded.title'
            : 'myteam.butchEdit.usersSuccessfullyRemoved.title',
        customContent: (
          <SuccessModalContent
            editMode={editMode}
            userEditingResult={userEditingResult}
          />
        ),
        okButton: {
          text: 'myteam.butchEdit.result.button',
          status: 'primary',
        },
      },
    })
  );
};
