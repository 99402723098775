import React, { Component, ReactElement } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Loader from 'components/ui/loader';
import BatchFile from 'types/BatchFile';

interface Props extends IntlProps {
  id: string;
  previewText: string;

  isLoading: boolean;
  isSuccess: boolean;
  isFilePreview: boolean;
  batch: null | BatchFile;

  renderSubmitted: () => ReactElement;
  renderBatch: () => ReactElement | null;

  renderGeneralColumn: () => ReactElement;
  renderAdditionalColumn: () => ReactElement;
  renderFooter: () => ReactElement;
}

class MassPayment extends Component<Props> {
  render() {
    return this.props.isSuccess
      ? this.props.renderSubmitted()
      : this.renderContent();
  }

  renderContent = () => {
    const { getTranslate } = this.props;

    return (
      <>
        {this.props.isFilePreview && this.props.batch ? (
          this.props.renderBatch()
        ) : (
          <>
            <div className='ui-form-fields__container ui-form-fields__content'>
              {this.props.isLoading && <Loader />}
              <div className='ui-form-fields__text'>
                {getTranslate(this.props.previewText)}
              </div>
              <div className='ui-form-fields__inner'>
                <div className='ui-form-fields__col ui-form-fields__col_general'>
                  {this.props.renderGeneralColumn()}
                </div>

                <div className='ui-form-fields__col ui-form-fields__col_additional ui-form-fields__col_flex'>
                  {this.props.renderAdditionalColumn()}
                </div>
              </div>
            </div>
          </>
        )}

        <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
          {this.props.renderFooter()}
        </div>
      </>
    );
  };
}

export default addTranslation(MassPayment);
