// crm field type 'int' options
export const INT_MAX = 2147483647;
export const INT_MIN = -2147483648;

// crm field type 'number' options
export const NUMBER_MAX_INTEGER_LENGTH = 18;
export const NUMBER_MAX_FRACTIONAL_LENGTH = 2;

export enum MafOp {
  view,
  insert,
  update,
  delete,
}

export enum MafServerStatus {
  received = 'received',
  failed = 'failed',
  error = 'error',
}
