import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { invert } from 'lodash-es';

import { addListeners } from 'decorators/addListeners';
import FormFields from 'components/formFields';

import Messages from 'constants/rpcTypes';
import fieldsConfig from './fieldsConfig';
import RemittancePaymentSingle from './components/remittancePaymentSingle/RemittancePaymentSingle';
import RemittancePaymentMass from './components/remittancePaymentMass/RemittancePaymentMass';
import { createRoutes, formIdRoutes } from '../routesMapper';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';
import { WithRouterProps } from 'decorators/withRouter';
import tableNames from 'constants/tableNames';
import { RouteLeavingGuard } from 'components/routeLeavingGuard';

interface OwnProps {
  id?: string;
}

interface State {
  currentForm: FormVariants;
  isFieldsDirty: boolean;
}

type Props = OwnProps & WithRouterProps<{ formId: string }>;

type FormVariants = 'single' | 'mass';

@addListeners([
  Messages.RemittanceQueue_StatusUpdated,
  Messages.Remittance_CreatePayment,
])
class RemittancePaymentBuilder extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentForm: 'single',
      isFieldsDirty: false,
    };
  }

  componentDidMount() {
    this.setCurrentForm();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      match: {
        params: { formId },
      },
    } = this.props;
    const {
      match: {
        params: { formId: prevFormId },
      },
    } = prevProps;

    if (prevFormId !== formId) {
      this.setCurrentForm();
      this.resetFieldsState();
    }
  }

  setCurrentForm = () => {
    const {
      match: {
        params: { formId },
      },
    } = this.props;

    const currentForm = invert(formIdRoutes)[formId] as FormVariants;

    if (!currentForm) {
      return;
    }

    this.setState({
      currentForm,
    });
  };

  onChangeTab = (_, tabId) => {
    const { history } = this.props;

    history.push(
      path(
        `/${urlsMap.remittance}/${
          createRoutes[tableNames.remittancePayments]
        }/${formIdRoutes[tabId]}`
      )
    );
  };

  handleFieldsDirty = (isFieldsDirty: boolean) => {
    this.setState({
      isFieldsDirty,
    });
  };

  resetFieldsState = () => {
    this.setState({
      isFieldsDirty: false,
    });
  };

  render() {
    const { currentForm, isFieldsDirty } = this.state;
    const {
      id,
      match: {
        params: { formId },
      },
    } = this.props;

    const tabs = [
      {
        id: 'single',
        label: 'remittance.addPayout.tabs.single',
        isCurrent: formId === formIdRoutes['single'],
      },
      {
        id: 'mass',
        label: 'remittance.addPayout.tabs.mass',
        isCurrent: formId === formIdRoutes['mass'],
      },
    ];

    return (
      <>
        <FormFields
          id={currentForm}
          title='remittance.addPayout.header'
          fieldsConfig={fieldsConfig}
          tabs={tabs}
          formFields={
            currentForm === 'single' ? (
              //@ts-ignore
              <RemittancePaymentSingle
                id={id}
                onFieldDirty={this.handleFieldsDirty}
              />
            ) : (
              <RemittancePaymentMass />
            )
          }
          onBeforeChange={this.onChangeTab}
        />

        <RouteLeavingGuard blockWhen={isFieldsDirty} />
      </>
    );
  }
}

export default withRouter(RemittancePaymentBuilder);
