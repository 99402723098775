import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import RpcTypes from 'constants/rpcTypes';

export const usePermission = () => {
  const permissions = useSelector((state: RootState) => state.permissions);

  const isEnabled = useCallback(
    (name: RpcTypes) => {
      const permission = permissions[name];

      if (!permission) {
        return false;
      }

      return permission.status === 'enabled';
    },
    [permissions]
  );

  const isSomeEnabled = useCallback(
    (nameList: RpcTypes[]) => nameList.some((name) => isEnabled(name)),
    [isEnabled]
  );

  const isDisabledByReason = useCallback(
    (name: RpcTypes, reason: string) => {
      const permission = permissions[name];

      if (!permission) {
        return false;
      }

      return permission.status === 'disabled' && permission.reason === reason;
    },
    [permissions]
  );

  return {
    isEnabled,
    isSomeEnabled,
    isDisabledByReason,
  };
};
