import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classnames from 'classnames';
import { Banner } from './components/Banner';
import { AccountingRequestItem } from './types';
import { Column, Header } from 'components/ui/table';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import PageTemplate from 'components/pageTemplate';
import './accounting-request.scss';
import { AccountingStatusTag } from './components/AccountingStatusTag';
import LabelStatus from 'components/ui/labelStatus';
import { statusThemes } from 'constants/statusList';
import { ACTIVE_CODAT_STATUS } from './constant';
import { SimpleTable } from 'components/ui/table/SimpleTable';
import Icon from 'components/ui/icon';
import Grid from 'components/grid';
import { AccountingRequestNotes } from './components/AccountingRequestNotes/AccountingRequestNotes';

type Props = {
  isFetch: boolean;
  items: AccountingRequestItem[];
  verifyCompanyAccountingData: (
    codatCompanyId: string,
    legalEntityId: string
  ) => void;
};

export const AccountingStatementRequestsView: React.FC<Props> = addTranslation<
  React.FC<Props & IntlProps>
>(({ isFetch, getTranslate, items, verifyCompanyAccountingData }) => {
  const legalEntities = useSelector(
    (state: RootState) => state.user?.activeLegalEntities || []
  );
  return (
    <PageTemplate.Main>
      <Banner />
      <PageTemplate.Container>
        <div className='accounting-request__page'>
          <SimpleTable
            customClass='accounting-request__table'
            isFetch={isFetch}
            noContentText={getTranslate('add.entities.info.text')}
            items={items}
            headerRender={
              <Header>
                <Column
                  key='name'
                  id='name'
                  content={getTranslate('MLE NAME')}
                />
                <Column
                  key='date'
                  id='date'
                  content={getTranslate('REQUEST SENT')}
                />
                <Column
                  key='status'
                  id='status'
                  content={getTranslate('Status')}
                />
                <Column
                  key='action'
                  id='action'
                  content={getTranslate('ACTION')}
                />
              </Header>
            }
            rowRender={({
              codatStatus,
              codatNotes,
              merchantLegalEntityId,
              codatStatusDate,
              codatCompanyId,
            }: AccountingRequestItem) => {
              const isRowActive = codatStatus === ACTIVE_CODAT_STATUS;
              const codatStatusDateFormatted =
                moment(codatStatusDate).format('DD MMM YYYY, HH:mm');
              return (
                <>
                  <div
                    className='accounting-request__row-wrapper'
                    key={merchantLegalEntityId}>
                    <div className='accounting-request__row'>
                      <Column
                        id={merchantLegalEntityId + '_name'}
                        customClass={classnames('accounting-request__cell', {
                          'ui-table__column_multiline': codatNotes,
                        })}
                        content={
                          <div className='accounting-request__cell-title'>
                            {legalEntities.find(
                              (entity) =>
                                String(entity.id) ===
                                String(merchantLegalEntityId)
                            )?.title || 'no title'}
                          </div>
                        }
                      />
                      <Column
                        id={merchantLegalEntityId + '_date'}
                        customClass={classnames('accounting-request__cell', {
                          'ui-table__column_multiline': codatNotes,
                        })}
                        content={codatStatusDateFormatted}
                      />
                      <Column
                        id={merchantLegalEntityId + '_status'}
                        customClass={classnames('accounting-request__cell', {
                          'ui-table__column_multiline': codatNotes,
                        })}
                        content={<AccountingStatusTag status={codatStatus} />}
                      />
                      <Column
                        id={merchantLegalEntityId + '_action'}
                        customClass={classnames('accounting-request__cell', {
                          'ui-table__column_multiline': codatNotes,
                        })}
                        content={
                          <div>
                            <Grid
                              align='center'
                              spacing='s'
                              className='accounting-request__action-inner'>
                              {isRowActive && (
                                <Icon
                                  name='im-Danger'
                                  size={15}
                                  className='accounting-request__danger-icon'
                                />
                              )}
                              <LabelStatus
                                customClass={classnames(
                                  'accounting-request__cell-label',
                                  {
                                    'accounting-request__cell-label_active':
                                      isRowActive,
                                  }
                                )}
                                onClick={() =>
                                  isRowActive &&
                                  verifyCompanyAccountingData(
                                    codatCompanyId,
                                    String(merchantLegalEntityId)
                                  )
                                }
                                status={statusThemes.lightBlue}
                                text={'Connect Financial Data'}
                              />
                            </Grid>
                          </div>
                        }
                      />
                    </div>
                    {codatNotes && (
                      <AccountingRequestNotes notes={codatNotes} />
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>
      </PageTemplate.Container>
    </PageTemplate.Main>
  );
});
