import * as Sentry from '@sentry/react';
import { privateRoutesConfig } from 'config/privateRoutes';
import Env from 'helpers/Env';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';

const SENTRY_DSN =
  'https://230e63e9c8d24f32938a66d369f586d7@sentry.ecommpay.com/11';

export const setupSentry = () => {
  if (!Env.getIsSentryEnabled()) {
    console.warn('Sentry is disabled', import.meta.env);
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: Env.getReleaseTag(),
    environment: 'production',
    // Provides an even cross-section of transactions, no matter where in your app.
    tracesSampleRate: 1.0,
    // For replays that begin recording immediately till the last user's session.
    replaysSessionSampleRate: 1.0,
    // For replays that recorded to a minute of events prior to the error happens till the session ends.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({
        history: createBrowserHistory(),
        routes: privateRoutesConfig.map(({ path }) => ({ path })),
        matchPath,
      }),
      Sentry.replayIntegration(),
    ],
  });
};
