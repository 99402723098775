import aloha from 'images/browsers/aloha.png';
import chrome from 'images/browsers/chrome.svg';
import firefox from 'images/browsers/firefox.svg';
import netfront from 'images/browsers/netfront.png';
import safari from 'images/browsers/safari.svg';
import silk from 'images/browsers/silk.png';
import vivaldi from 'images/browsers/vivaldi.svg';
import android from 'images/browsers/android.png';
import edge from 'images/browsers/edge.svg';
import ie from 'images/browsers/ie.svg';
import opera from 'images/browsers/opera.svg';
import samsung from 'images/browsers/samsung.svg';
import ucpng from 'images/browsers/uc.png';

export const browserIcons = {
  aloha,
  chrome,
  firefox,
  netfront,
  safari,
  silk,
  vivaldi,
  android,
  edge,
  ie,
  opera,
  samsung,
  ucpng,
};
