import React from 'react';
import { connect } from 'react-redux';

import { ById, ByOrder } from 'types/Analytics';
import { RootState, StoreProps } from 'store';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addListeners } from 'decorators/addListeners';

import { openModal } from 'actions/modal';
import checkFilters from 'helpers/checkFilters';
import Analytics from 'pages/analytics/Analytics';
import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';

import LocalStorage from 'helpers/LocalStorage';
import {
  fetchChartFilters,
  queryChartItems,
  fetchUserCharts,
  fetchChartItems,
} from 'actions/analytics';
import Messages from 'constants/rpcTypes';
import PageTemplate from 'components/pageTemplate/PageTemplate';

interface ConnectedProps {
  byOrder: ByOrder;
  byId: ById;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

interface State {
  layoutType: LayoutTypes;
  chartsFetched: boolean;
}

const MAX_CHART_AMOUNT = 10;

@addListeners([Messages.Analytics_GetChart])
class AnalyticsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chartsFetched: false,
      layoutType: LocalStorage.get('graphsLayoutType') || LayoutTypes.grid,
    };
  }

  componentDidMount() {
    this.fetchAnalyticsData();
  }

  fetchAnalyticsData = async () => {
    const { dispatch } = this.props;

    try {
      await Promise.all([
        checkFilters('advancedAnalytics'),
        dispatch(fetchUserCharts()),
      ]);

      this.setState({
        chartsFetched: true,
      });

      await dispatch(queryChartItems());
      await dispatch(fetchChartFilters());
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { layoutType, chartsFetched } = this.state;
    const { byOrder, byId, isEnabled } = this.props;

    return (
      <PageTemplate.Main>
        {isEnabled(Messages.Analytics_AdvancedAnalytics) && (
          <PageTemplate.Container customClass='analytics-template'>
            <Analytics
              layoutType={layoutType}
              openLayoutModal={this.openLayoutModal}
              openChartEditModal={this.openChartEditModal}
              chartsFetched={chartsFetched}
              byOrder={byOrder}
              isAddChartDisabled={Object.keys(byId).length >= MAX_CHART_AMOUNT}
            />
          </PageTemplate.Container>
        )}
      </PageTemplate.Main>
    );
  }

  onEvent({ data, name }) {
    const { dispatch } = this.props;
    if (name === Messages.Analytics_GetChart && data.payload.data) {
      dispatch(fetchChartItems(data.payload.data));
    }
  }

  openChartEditModal = () => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'GraphDetails',
      })
    );
  };

  openLayoutModal = () => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'EditLayout',
        callback: (layoutType) => {
          if (layoutType !== false) {
            this.setState({ layoutType });
            LocalStorage.set('graphsLayoutType', layoutType);
          }
        },
      })
    );
  };
}

const mapStateToProps = (store: RootState): ConnectedProps => ({
  byOrder: store.analytics.gridData.byOrder,
  byId: store.analytics.chartData.byId,
});

export default connect(mapStateToProps)(addPermissions(AnalyticsContainer));
