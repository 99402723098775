import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './animation.scss';

interface Props {
  name?: any;
  duration?: number;
  customClass?: string;
}

class Animation extends PureComponent<Props> {
  static defaultProps = {
    name: 'fadeIn',
    duration: 500,
  };

  render() {
    const { name, duration, children } = this.props;
    const classes = classNames('ui-animation', {
      [name]: name,
    });

    return (
      <div style={{ animationDuration: `${duration}ms` }} className={classes}>
        {children}
      </div>
    );
  }
}

export default Animation;
