import React from 'react';
import InfoIcon from 'components/ui/infoIcon';
import FileTemplate from 'components/ui/fileTemplate';
import Env from 'helpers/Env';

interface Props {
  title: string;
  templatesText: string;
  infoText?: string;
  templates: Array<{ title: string; url: string; isAvailable: boolean }>;
}

export const MassPaymentGeneralColumn: React.VFC<Props> = (props) => {
  return (
    <>
      <div className='ui-form-fields__col-title'>{props.title}</div>
      <div className='ui-form-fields__text ui-form-fields__text_subtext'>
        {props.templatesText}{' '}
        {props.infoText && (
          <InfoIcon tooltip={props.infoText} dataPlace='top' modern />
        )}
      </div>
      {props.templates.map((template) => {
        return template.isAvailable ? (
          <div className='ui-form-fields__item' key={template.title}>
            <FileTemplate
              title={template.title}
              url={Env.getApiUrl() + template.url}
            />
          </div>
        ) : null;
      })}
    </>
  );
};
