import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filter, isEmpty, isEqual, throttle } from 'lodash-es';

import { applyFilters } from 'actions/filters';
import { resetConcreteQuickFilters } from 'actions/quickFilters';
import { resetSearchFilters } from 'actions/searchFilters';
import getProjectsIds from 'selectors/getProjectsIds';
import getFiltersValues from 'selectors/getFiltersValues';
import DateHelpers from 'helpers/Date';
import Utils from 'helpers/Utils';
import FiltersHelper from './FiltersHelper';
import Filters from './Filters';
import { AnyObject } from 'types/Common';
import tableNames from 'constants/tableNames';
import { StoreProps } from 'store';

interface OwnProps {
  name: string;
  availableValues?: AnyObject;
  onClosePanel: (e?) => void;
  customClass?: string;
  checkChangesForApply?: boolean;
  resetByTab?: boolean;
  onApply?: () => void;
}

interface ConnectedProps {
  selectedFilters: any;
  filters: any;
  tabs: any;
  dictionaryValues: any;
  projectIds: string[];
  timezone: string;
  quickFilters: any;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  filters: AnyObject;
  errors: AnyObject;
  isLoading: boolean;
  isMobile: boolean;
}

class FiltersContainer extends Component<Props, State> {
  private initialFilters;

  static defaultProps: Readonly<Partial<OwnProps>> = {
    resetByTab: true,
    checkChangesForApply: true,
  };

  constructor(props: Props) {
    super(props);
    this.initialFilters = {};
    this.state = {
      filters: {},
      errors: {},
      isLoading: isEmpty(props.dictionaryValues),
      isMobile: false,
    };
    this.handleResize = throttle(this.handleResize, 300);
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      await FiltersHelper.getFiltersValues(
        this.props.filters,
        this.props.dictionaryValues
      );
    } finally {
      this.setState({ isLoading: false });
    }
    const withAppliedQuickFilters = this.syncFilters();
    const filters = FiltersHelper.prepareFiltersForState(
      withAppliedQuickFilters,
      this.props.dictionaryValues,
      this.props.availableValues
    );

    this.initialFilters = JSON.parse(JSON.stringify(filters));
    this.setState({ filters });

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { dictionaryValues, availableValues } = this.props;

    if (!isEqual(dictionaryValues, prevProps.dictionaryValues)) {
      const withAppliedQuickFilters = this.syncFilters();
      const filtersUpdated = FiltersHelper.prepareFiltersForState(
        withAppliedQuickFilters,
        dictionaryValues,
        availableValues
      );

      this.setState({
        filters: filtersUpdated,
      });

      this.initialFilters = JSON.parse(JSON.stringify(filtersUpdated));

      this.hasChangesInFilters();
    }
  }

  render() {
    const { errors, filters, isLoading, isMobile } = this.state;
    const { checkChangesForApply, customClass, timezone, tabs } = this.props;
    const hasChanges = checkChangesForApply ? this.hasChangesInFilters() : true;

    return (
      <Filters
        timezone={timezone}
        tabs={tabs}
        filters={filters}
        errors={errors}
        canApply={hasChanges && this.requiredFilled()}
        onChangeFilter={this.changeFilter}
        onResetFilters={this.resetFilters}
        onApplyFilters={this.applyFilters}
        customClass={customClass}
        isLoading={isLoading}
        isMobile={isMobile}
      />
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  syncFilters = () => {
    const { quickFilters, filters, selectedFilters } = this.props;
    if (!isEmpty(selectedFilters)) return filters;
    const newFilters: any[] = [];
    for (const filterName in quickFilters) {
      const currentFilter = filters.find(
        (item) => item.filterId === filterName
      );
      if (Utils.hasProp(quickFilters, filterName) && currentFilter) {
        if (
          filterName.toLowerCase().includes('date') ||
          filterName.toLowerCase().includes('created')
        ) {
          newFilters.push({
            ...currentFilter,
            value: quickFilters[filterName].values,
          });
        } else {
          newFilters.push({
            ...currentFilter,
            value: quickFilters[filterName],
          });
        }
      }
    }
    return [...filters, ...newFilters];
  };

  changeFilter = (filterId, value, options?: { valueKey?: string }) => {
    const { dictionaryValues } = this.props;
    const { filters, errors } = this.state;
    const newErrors = { ...errors };
    delete newErrors[filterId];

    let valueKey: string = 'value';
    if (options?.valueKey) {
      valueKey = options.valueKey;
    }

    filters[filterId] = {
      ...filters[filterId],
      [valueKey]: value,
    };

    this.setState(
      {
        filters,
        errors: newErrors,
      },
      () => {
        if (filters[filterId].dependencies) {
          const newFilters =
            FiltersHelper.getAvailableValuesForDependenciesFilters(
              filterId,
              filters,
              dictionaryValues
            );
          this.setState({ filters: newFilters });
        }
      }
    );
  };

  applyFilters = () => {
    const { name, onClosePanel, onApply, quickFilters, dispatch } = this.props;
    const { filters } = this.state;

    if (quickFilters) {
      dispatch(resetConcreteQuickFilters({ name }));
    }

    if (name === tableNames.payments) {
      dispatch(resetSearchFilters());
    }

    const data: any = FiltersHelper.prepareFiltersForStore(filters);
    data.__lastApply = {
      value: +DateHelpers.getDate(),
    };

    dispatch(applyFilters(name, data));
    onApply && onApply();
    onClosePanel();
  };

  hasChangesInFilters = (): boolean => {
    const { filters } = this.state;
    return !isEqual(
      this.getValuesForCompare(this.initialFilters),
      this.getValuesForCompare(filters)
    );
  };

  getValuesForCompare = (filters) => {
    const values = Object.values(filters);
    return values.map((data: any) => {
      const { value } = data;
      return typeof value === 'string' ? value.trim() : value;
    });
  };

  requiredFilled = () => {
    const { filters } = this.state;
    const requiredFilters = filter(
      filters,
      (filterItem) => filterItem.required
    );
    if (requiredFilters.length === 0) {
      return true;
    }

    return requiredFilters.every(
      ({ value }) => value !== null && value.length > 0
    );
  };

  resetFilters = () => {
    const { filters, dictionaryValues, availableValues } = this.props;

    const initialFilters = FiltersHelper.prepareFiltersForState(
      filters,
      dictionaryValues,
      availableValues
    );
    this.setState({
      filters: FiltersHelper.resetFiltersValuesByTab(initialFilters),
    });
  };

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 680,
    });
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  selectedFilters: getFiltersValues(state, ownProps.name),
  filters: state.filters[ownProps.name].fields,
  tabs: state.filters[ownProps.name].tabs,
  dictionaryValues: state.filtersValues || {},
  projectIds: getProjectsIds(state),
  timezone: state.user.timezone,
  quickFilters: state.quickFilters[ownProps.name],
});

export default connect(mapStateToProps)(FiltersContainer);
