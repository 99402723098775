import LabelStatus from 'components/ui/labelStatus';
import './mafListDemonstration.scss';
import { statusThemes } from 'constants/statusList';

export const MafListDemonstration = () => (
  <div className='form-list-demonstration'>
    <div className='form-list-demonstration__item'>
      <div className='form-list-demonstration__item-link'>Fill Out</div>
      <div className='form-list-demonstration__item-skeleton'></div>
      <div className='form-list-demonstration__item-tag'>
        <LabelStatus status={statusThemes.green} text='new' />
      </div>
      <div className='form-list-demonstration__item-skeleton form-list-demonstration__item-skeleton_last'></div>
    </div>
    <div className='form-list-demonstration__separator'>OR</div>
    <div className='form-list-demonstration__item'>
      <div className='form-list-demonstration__item-link'>Fill Out</div>
      <div className='form-list-demonstration__item-skeleton'></div>
      <div className='form-list-demonstration__item-tag'>
        <LabelStatus status={statusThemes.teal} text='in progress' />
      </div>
      <div className='form-list-demonstration__item-skeleton form-list-demonstration__item-skeleton_last'></div>
    </div>
  </div>
);
