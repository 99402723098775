import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AccountingStatementRequestsView } from './AccountingStatementRequestsView';
import Messages from 'constants/rpcTypes';
import { AccountingRequestItem } from './types';
import { askUserToProvideAccountingData } from './helpers/askUserToProvideAccountingData';
import { runCodatProcess } from './helpers/runCodatProcess';

import { useEventListener } from 'hooks';
import { commitAccountingRequest } from 'api/accouning';

import { notifyUserAboutSuccessfullyDataProviding } from './helpers/notifyUserAboutSuccessfullyDataProviding';
import { sortAccountingRequests } from './helpers/sortAccountingRequests';

export const AccountingStatementRequestsContainer = () => {
  const [isFetch, setIsFetch] = useState(false);
  const accountRequests = useSelector(
    (state: RootState) => state.accountingRequests?.list || []
  );
  const [accountingRequestsListItems, setAccountingRequestsListItems] =
    useState(accountRequests);

  useEffect(() => {
    setAccountingRequestsListItems(accountRequests);
  }, [accountRequests]);

  useEventListener(
    {
      events: [Messages.AccountingDataRequest_Submit],
      onEvent: (
        _,
        data: {
          payload: AccountingRequestItem;
        }
      ) => {
        if (!data?.payload) {
          return;
        }

        setAccountingRequestsListItems((items) =>
          items.map((item) => {
            if (item.codatCompanyId === data.payload.codatCompanyId) {
              return data.payload;
            }

            return item;
          })
        );

        setIsFetch(false);
      },
    },
    []
  );

  const verifyCompanyAccountingData = async (
    companyId: string,
    merchantLegalEntityId: string
  ) => {
    const isUserReadyToProvideAccountingData =
      await askUserToProvideAccountingData();

    if (!isUserReadyToProvideAccountingData) {
      return;
    }

    const isCodatProcessSuccessfullyFitished = await runCodatProcess(companyId);
    if (isCodatProcessSuccessfullyFitished) {
      notifyUserAboutSuccessfullyDataProviding();
      setIsFetch(true);
      commitAccountingRequest(merchantLegalEntityId);
    }
  };

  return (
    <AccountingStatementRequestsView
      isFetch={isFetch}
      items={sortAccountingRequests(accountingRequestsListItems)}
      verifyCompanyAccountingData={verifyCompanyAccountingData}
    />
  );
};
