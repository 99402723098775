import { UserBuilderProps } from './UserBuilderContainer';
import { AnyObject } from 'types/Common';
import PermissionsTreeType from 'types/PermissionsTree';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';
import getSelectionListItems from 'creators/getSelectionListItems';

export function getFieldsForState(
  this: { props: UserBuilderProps; state },
  data,
  isDisabled
) {
  const {
    id: dataId,
    username,
    email,
    password,
    contactPhone,
    contactPhoneCountry,
    permissionsDisabled,
    legalEntities = [],
    enabledProjects,
    projectsAvailable = [],
    roles,
    merchants = [],
    allowedIps,
    wlId,
    isChargebackMailingEnabled = false,
    isFinancialReportMailingEnabled = false,
    isFraudMailingEnabled = false,
  } = data;
  const {
    roleToGrant,
    isAdmin,
    roleToSee,
    wlList,
    user,
    userActiveLegalEntity,
  } = this.props;

  const rolesList: any[] = [];
  const availableRoles = isDisabled ? roleToSee : roleToGrant;

  availableRoles?.forEach(({ id, text }) => {
    const userRole = roles.find((r) => r === id);
    if (
      ['internal_localization', 'merchant_admin', 'internal_qa'].includes(id) &&
      !isAdmin
    ) {
      if (userRole) {
        rolesList.push({
          id,
          text,
          isSelected: Boolean(userRole),
          isDisabled: true,
        });
      }
    } else {
      rolesList.push({
        id,
        text,
        isDisabled,
        isSelected: Boolean(userRole),
      });
    }
  });

  const result = {
    username: getStringWithSpecialSymbols(username),
    email,
    password,
    contactPhone,
    contactPhoneCountry,
    permissionsDisabled,
    isChargebackMailingEnabled,
    isFinancialReportMailingEnabled,
    isFraudMailingEnabled,
    roles: rolesList,
    ipList:
      allowedIps.length > 0
        ? allowedIps.map((ips) => {
            return { from: ips[0], to: ips[1] };
          })
        : [],
  };

  if (isAdmin) {
    const wlIdList = getCustomSelectItems({
      list: wlList,
      getLabel: (item) => item.text,
      getValue: (item) => item.id,
    });
    const wlIdSelected = wlIdList.find((wl) => wl.value === wlId) || null;
    return {
      ...result,
      id: dataId,
      wlId: {
        value: wlIdSelected,
        list: wlIdList,
      },
      projectId: projectsAvailable.map(({ projectId }: any) => projectId),
      merchantId: merchants.map(({ merchantId }: any) => merchantId),
      legalEntities: legalEntities.map(({ id }: any) => id),
    };
  }
  return {
    ...result,
    legalEntities: userActiveLegalEntity?.list
      ? getSelectionListItems({
          originalList: userActiveLegalEntity.list,
          selectedList: legalEntities,
        })
      : [],
    projects: user.availableProjects.map(({ id, name, projectId }: any) => {
      return {
        id: projectId,
        text: `${name} (${projectId})`,
        isDisabled,
        isSelected: Boolean(enabledProjects.find((p) => p.id === id)),
      };
    }),
  };
}

export function validateAdminFields(fields, passwordEditable) {
  const roleSelected = fields.roles.some((role) => role.isSelected);
  const projectSelected = fields.projectId.length > 0;
  const passwordReady = !passwordEditable || fields.password;

  return Boolean(
    roleSelected &&
      projectSelected &&
      fields.email &&
      fields.username &&
      passwordReady &&
      fields.wlId.value
  );
}

function getSelectedCount(list): number {
  return list.filter((item) => item.isSelected).length;
}

export function validateUserFields(fields) {
  if (getSelectedCount(fields.roles) === 0) return false;

  if (getSelectedCount(fields.projects) === 0) return false;

  return Boolean(fields.username && fields.email);
}

export const getPermissionTree = (
  roleWithPermissionToGrant: AnyObject,
  roles: AnyObject
): { permissionsTree: PermissionsTreeType; permissionsMap: any } => {
  const tree: PermissionsTreeType = {};
  const rolesMap = {};
  const permissionsMap = {};

  roles.forEach((role) => {
    if (role.isSelected) {
      rolesMap[role.id] = true;
    }
  });

  (roleWithPermissionToGrant || []).forEach((roleWithPermission) => {
    if (roleWithPermission.role && rolesMap[roleWithPermission.role.id]) {
      const { permissionCategories } = roleWithPermission;
      permissionCategories.forEach((permissionCategory) => {
        const { category, permissions } = permissionCategory;
        if (category.id === 'generic') return;
        if (!tree[category.id]) {
          tree[category.id] = {
            id: category.id,
            text: category.text,
            permissions: {},
          };
        }
        permissions.forEach((permission) => {
          if (!tree[category.id].permissions[permission.id]) {
            tree[category.id].permissions[permission.id] = {
              id: permission.id,
              text: permission.text,
            };
            permissionsMap[permission.id] = true;
          }
        });
      });
    }
  });

  return { permissionsTree: tree, permissionsMap };
};
