import React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { InferableComponentEnhancer } from 'react-redux';

import { AnyObject } from 'types/Common';

interface GetTranslate {
  (
    id?: string,
    value?: (AnyObject & { node?: false }) | null | undefined
  ): string;
  (id: string, value: AnyObject & { node: true }): JSX.Element;
}

export interface IntlProps {
  getTranslate: GetTranslate;
}

const getTranslate = (intl: InjectedIntl): GetTranslate =>
  ((id, values) => {
    if (values && values.node) {
      return <FormattedMessage id={id} values={values} />;
    }
    if (!id) return '';

    return intl.formatMessage({ id }, values || {});
  }) as GetTranslate;

export const addTranslation: InferableComponentEnhancer<IntlProps> = (
  Component
) => {
  function IntlWrapper({ intl, ...props }) {
    return <Component {...(props as any)} getTranslate={getTranslate(intl)} />;
  }
  IntlWrapper.WrappedComponent = Component;
  IntlWrapper.displayName = `AddTranslation(${
    Component.displayName || Component.name || 'Component'
  })`;

  return injectIntl(IntlWrapper) as any;
};
