import React, { Component } from 'react';
import classNames from 'classnames';

import CustomScrollbar from 'components/ui/customScrollbar';

import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

import { addTranslation, IntlProps } from 'decorators/addTranslation';

import {
  FraudReportViewData,
  ChargebackViewData,
  SuccessRateViewData,
} from 'types/Analytics';

import '../InOutTable/InOutTable.scss';

interface Props extends IntlProps {
  data: ChargebackViewData | FraudReportViewData | SuccessRateViewData;
  className?: string;
  header?: string;
  setRef?: (ref: React.Ref<HTMLDivElement>) => void;
}

const COMMON_COLUMN_HEADERS = {
  card_type: 'analytics.editForm.params.groupBy.cardType.item',
  project_id: 'analytics.editForm.params.groupBy.projectID.item',
  issuer_country_name: 'analytics.editForm.params.groupBy.countryByBIN.item',
  sum_channel_amount_in_usd:
    'analytics.mainView.chart.totalChannelAmountUSD.label',
};

const COLUMNS_HEADER = {
  [AdvancedAnalyticsEntityType.fraud_report]: {
    brand: 'analytics.editForm.filters.brand.label',
    received_on: 'analytics.editForm.params.groupBy.fraudPeriod.item',
    fraud_type_name: 'analytics.editForm.params.groupBy.fraudType.item',
    card: 'analytics.editForm.params.groupBy.cardType.item',
    customer_id: 'analytics.editForm.params.groupBy.fraudCustomer.item',
    sum_fraud_amount_in_usd: 'analytics.mainView.chart.totalFraudAmount.label',
    count_arn: 'analytics.mainView.chart.totalFraudNumber.label',
    count_operation_id: 'analytics.mainView.chart.totalNumber.label',
    ratio_by_amount: 'analytics.mainView.chart.fraudAmountRatio.label',
    ratio_by_count: 'analytics.mainView.chart.fraudNumberRatio.label',
  },
  [AdvancedAnalyticsEntityType.chargeback]: {
    count_chargeback_id: 'analytics.editForm.params.chartData.chbNumber.item',
    result: 'analytics.editForm.params.groupBy.chargebackStatus.item',
    claim_type: 'analytics.editForm.params.groupBy.claimType.item',
    reason_code: 'analytics.editForm.params.groupBy.chargebackReason.item',
    chb_proc_date:
      'analytics.editForm.params.groupBy.chargebackCreatedDate.item',
    count_operation_id: 'analytics.mainView.chart.totalNumber.label',
    sum_amount_in_usd: 'analytics.mainView.chart.totalChbAmount.label',
    ratio_by_count: 'analytics.mainView.chart.chbNumberRatio.label',
    ratio_by_amount: 'analytics.mainView.chart.chbAmountRatio.label',
  },
  [AdvancedAnalyticsEntityType.success_rate]: {
    transaction_created_at: 'analytics.mainView.chart.transactionTime.label',
    count_transaction_id: 'analytics.mainView.chart.transactionCount.label',
    count_success_transaction_id:
      'analytics.mainView.chart.successTransactionCount.label',
    rate: 'analytics.mainView.chart.successRate.label',
  },
};

class AdvancedAnalyticsTable extends Component<Props> {
  private chartRef: React.RefObject<HTMLDivElement> | undefined;

  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
  }

  setRef(ref) {
    const { setRef } = this.props;

    if (!ref) {
      return;
    }

    this.chartRef = ref;
    setRef && setRef(ref);
  }

  render() {
    const { data, getTranslate, className, header } = this.props;
    const currentColumnSet = {
      ...COMMON_COLUMN_HEADERS,
      ...COLUMNS_HEADER[data.chartGroup],
    };

    return (
      <div className={classNames('in-out-table__container', className)}>
        {header !== undefined && (
          <div className='settings-form__header'>{getTranslate(header)}</div>
        )}
        <CustomScrollbar customHeight={362}>
          <div className='in-out-table' ref={this.setRef}>
            <table>
              <thead>
                <tr>
                  {data.headLine.map((key, index) => (
                    <td
                      key={`th-${key}-${index}`}
                      className='in-out-table_table-bold'>
                      {getTranslate(currentColumnSet[key])}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.data.map((rowData, index) => (
                  <tr key={`row-${index}`}>
                    {data.headLine.map((column) => (
                      <td key={`td-${column}-${rowData[column]}`}>
                        {rowData[column]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CustomScrollbar>
      </div>
    );
  }
}

const AdvancedAnalyticsTable_ = addTranslation(AdvancedAnalyticsTable);
export { AdvancedAnalyticsTable_ as AdvancedAnalyticsTable };
