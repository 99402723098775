import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import {
  IDictionary,
  IPositionSetting,
} from 'pages/payments/createFiscalReceipt/types';
import { RootState } from 'store';
import { useTranslation } from 'hooks';
import Utils from 'helpers/Utils';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import Input from 'components/ui/input';
import Form from 'components/ui/form/Form';

interface Props {
  content: IPositionSetting & { availableAmount: number };
  callback: (data: Partial<IPositionSetting>) => void;
}

const FiscalReceiptPositionSettings: React.FC<Props> = ({
  content,
  callback,
}) => {
  const getTranslate = useTranslation();
  const [availableAmount, setAvailableAmount] = useState(0);
  const [values, setValues] = useState<IPositionSetting>({
    id: uuid(),
    quantity: '',
    price: '',
    positionDescription: '',
    tax: null,
    paymentSubjectType: null,
    paymentMethodType: null,
    incomeType: null,
    contributionType: null,
    nomenclatureCode: '',
  });

  const {
    tax,
    paymentMethodType,
    paymentSubjectType,
    contributionType,
    incomeType,
  } = useSelector((state: RootState) => {
    const {
      CashVoucherTaxRate: taxRate,
      CashVoucherPaymentMethodType: paymentMethod,
      CashVoucherPaymentSubjectType: paymentSubject,
      CashVoucherPositionDescriptionContrType: contributionType,
      CashVoucherPositionDescriptionIncomeType: incomeType,
    } = state.filtersValues;

    const getFormattedData = (data?: IDictionary) => {
      return {
        list:
          data?.list?.map(({ id, text }) => ({
            value: id,
            label: text,
          })) || [],
        isLoading: data?.isLoading || false,
      };
    };

    return {
      tax: getFormattedData(taxRate),
      paymentMethodType: getFormattedData(paymentMethod),
      paymentSubjectType: getFormattedData(paymentSubject),
      contributionType: getFormattedData(contributionType),
      incomeType: getFormattedData(incomeType),
    };
  });

  useEffect(() => {
    const formattedValues = omit(content, 'availableAmount');
    const { availableAmount } = content;
    const { price, quantity } = formattedValues;

    if (!isEmpty(formattedValues)) {
      setValues(formattedValues);
      setAvailableAmount(availableAmount + Number(price) * Number(quantity));
      return;
    }

    setAvailableAmount(availableAmount);
  }, [content]);

  const parsedQuantity = Number(Utils.getNumberWithoutSpace(values.quantity));
  const parsedPrice = Number(Utils.getNumberWithoutSpace(values.price));
  const isAmountLimitValid = availableAmount >= parsedPrice * parsedQuantity;

  const isFormValid = () => {
    if (values.paymentSubjectType === 15 || values.paymentSubjectType === 16) {
      return Boolean(values.contributionType || values.incomeType);
    }

    return Boolean(
      parsedQuantity !== 0 &&
        parsedPrice !== 0 &&
        values.positionDescription &&
        values.tax &&
        isAmountLimitValid
    );
  };

  const handleChange = (values: Partial<IPositionSetting>) => {
    setValues((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const handleAdd = () => {
    callback(values);
  };

  return (
    <Form customClass='ui-form-fields__form' onSubmit={handleAdd}>
      <ModalTitle>
        {getTranslate('fiscalReceipt.form.PositionSettings.label')}
      </ModalTitle>
      <ModalContent>
        <div className='modal__content-item'>
          <InputAmount
            id='quantity'
            modern
            isRequired
            label={getTranslate('fiscalReceipt.detCard.Quantity.label')}
            placeholder={getTranslate('fiscalReceipt.detCard.Quantity.label')}
            value={values.quantity}
            onChange={(e) => {
              handleChange({
                quantity: e.target.value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <InputAmount
            id='price'
            modern
            isRequired
            label={getTranslate('fiscalReceipt.detCard.Price.label')}
            placeholder={getTranslate('fiscalReceipt.detCard.Price.label')}
            value={values.price}
            onChange={(e) => {
              handleChange({
                price: e.target.value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <Input
            id='positionDescription'
            modern
            isRequired
            label={getTranslate('fiscalReceipt.detCard.PosDesc.label')}
            placeholder={getTranslate('fiscalReceipt.detCard.PosDesc.label')}
            value={values.positionDescription}
            onChange={(e) => {
              handleChange({
                positionDescription: e.target.value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <CustomSelect
            id='tax'
            modern
            isRequired
            placeholder={getTranslate('fiscalReceipt.detCard.Vat.label')}
            label={getTranslate('fiscalReceipt.detCard.Vat.label')}
            tooltip={getTranslate('fiscalReceipt.detCard.Vat.tooltip')}
            isLoading={tax.isLoading}
            options={tax.list}
            value={tax.list.find(({ value }) => value === values.tax)}
            onChange={({ value }) => {
              handleChange({
                tax: value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <CustomSelect
            id='paymentMethodType'
            modern
            placeholder={getTranslate('fiscalReceipt.detCard.PayMethod.label')}
            label={getTranslate('fiscalReceipt.detCard.PayMethod.label')}
            isLoading={paymentMethodType.isLoading}
            options={paymentMethodType.list}
            value={paymentMethodType.list.find(
              ({ value }) => value === values.paymentMethodType
            )}
            onChange={({ value }) => {
              handleChange({
                paymentMethodType: value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <CustomSelect
            id='paymentSubjectType'
            modern
            placeholder={getTranslate('fiscalReceipt.detCard.PaySubject.label')}
            label={getTranslate('fiscalReceipt.detCard.PaySubject.label')}
            isLoading={paymentSubjectType.isLoading}
            options={paymentSubjectType.list}
            value={paymentSubjectType.list.find(
              ({ value }) => value === values.paymentSubjectType
            )}
            onChange={({ value }) => {
              if (value === values.paymentSubjectType) {
                return;
              }

              if (values.contributionType || values.incomeType) {
                handleChange({
                  positionDescription: '',
                });
              }

              handleChange({
                contributionType: null,
                incomeType: null,
                paymentSubjectType: value,
              });
            }}
          />
        </div>
        {values.paymentSubjectType === 15 ? (
          <div className='modal__content-item'>
            <CustomSelect
              id='incomeType'
              modern
              isRequired
              placeholder={getTranslate(
                'fiscalReceipt.detCard.IncomeType.label'
              )}
              label={getTranslate('fiscalReceipt.detCard.IncomeType.label')}
              isLoading={incomeType.isLoading}
              options={incomeType.list}
              value={incomeType.list.find(
                ({ value }) => value === values.incomeType
              )}
              onChange={({ value, label }) => {
                handleChange({
                  incomeType: value,
                  positionDescription: label,
                });
              }}
            />
          </div>
        ) : null}
        {values.paymentSubjectType === 16 ? (
          <div className='modal__content-item'>
            <CustomSelect
              id='contributionType'
              modern
              isRequired
              placeholder={getTranslate(
                'fiscalReceipt.detCard.ContrType.label'
              )}
              label={getTranslate('fiscalReceipt.detCard.ContrType.label')}
              isLoading={contributionType.isLoading}
              options={contributionType.list}
              value={contributionType.list.find(
                ({ value }) => value === values.contributionType
              )}
              onChange={({ value, label }) => {
                handleChange({
                  contributionType: value,
                  positionDescription: label,
                });
              }}
            />
          </div>
        ) : null}
        <div className='modal__content-item'>
          <Input
            id='nomenclatureCode'
            modern
            label={getTranslate('fiscalReceipt.detCard.NomenCode.label')}
            placeholder={getTranslate('fiscalReceipt.detCard.NomenCode.label')}
            value={values.nomenclatureCode}
            onChange={(e) => {
              handleChange({
                nomenclatureCode: e.target.value,
              });
            }}
          />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={!isFormValid()}
          text={getTranslate('common.add.button')}
          status='primary'
          type='submit'
        />
      </ModalFooter>
    </Form>
  );
};

export { FiscalReceiptPositionSettings };
