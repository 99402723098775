import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RootState } from 'store';
import PageTemplate from 'components/pageTemplate';
import SavedEntitiesContainer from 'components/savedEntities';
import savedEntities from 'constants/savedEntities';
import { TopPageInfromer } from './components/TopPageInformer';
import { BusinessDocumentsForm } from './components/BusinessDocumentsForm';
import { BusinessDocumentsList } from './components/BusinessDocumentsList';

const statusToInform = ['accepted', 'decline', 'daf_review'];

const BusinessDocuments = () => {
  const { path } = useRouteMatch();
  const { status } = useSelector((state: RootState) => state.maf);

  return (
    <PageTemplate.Main>
      <PageTemplate.Entities>
        <SavedEntitiesContainer entityKey={savedEntities.businessDocs} />
      </PageTemplate.Entities>

      {status && statusToInform.includes(status) ? (
        <TopPageInfromer
          status={status as 'accepted' | 'decline' | 'daf_review'}
        />
      ) : null}

      <PageTemplate.Container>
        <Switch>
          <Route path={`${path}/:id`} component={BusinessDocumentsForm} />
          <Route path={path} component={BusinessDocumentsList} />
        </Switch>
      </PageTemplate.Container>
    </PageTemplate.Main>
  );
};

export { BusinessDocuments };
