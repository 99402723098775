import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ModalContent from 'components/modal/components/ModalContent';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import CustomSelect from 'components/ui/customSelect';
import { useTranslation } from 'hooks';

interface Props {
  callback: (data: number | string) => void;
}

const BankAccountCreateForm: React.FC<Props> = ({ callback }) => {
  const getTranslate = useTranslation();
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<number | null>(
    null
  );
  const legalEntities = useSelector(
    (state: RootState) => state.filtersValues?.userActiveLegalEntity
  );

  const options: {
    value: number;
    label: string;
  }[] = useMemo(() => {
    const legalEntityOptions = legalEntities?.list || [];

    return legalEntityOptions.map(({ id, text }) => ({
      value: id,
      label: text,
    }));
  }, [legalEntities?.list]);

  const handleContinue = () => {
    if (selectedLegalEntity) {
      callback(selectedLegalEntity);
    }
  };

  return (
    <>
      <ModalTitle>
        {getTranslate('finance.bankAccount.form.header.new')}
      </ModalTitle>
      <ModalContent>
        <div className='warning'>
          <Icon name='im-Danger' size={16} />
          <h3>
            {getTranslate('finance.bankAccount.modal.create.form.warning')}
          </h3>
        </div>
        <p className='info-text'>
          {getTranslate('finance.bankAccount.modal.create.form.description')}
        </p>
        <CustomSelect
          id='legalEntityId'
          modern
          isRequired
          label={getTranslate(
            'finance.bankAccount.modal.create.form.select.label'
          )}
          placeholder={getTranslate(
            'finance.bankAccount.modal.create.form.select.placeholder'
          )}
          options={options}
          isLoading={legalEntities?.isLoading}
          value={options.find(({ value }) => value === selectedLegalEntity)}
          onChange={({ value }) => {
            setSelectedLegalEntity(value);
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          text={getTranslate('finance.bankAccount.modal.create.form.button')}
          status='primary'
          size='normal'
          onClick={handleContinue}
        />
      </ModalFooter>
    </>
  );
};

export { BankAccountCreateForm };
