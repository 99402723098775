import { chunk } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { createAction } from 'helpers/redux';
import getLocalizedText from 'helpers/getLocalizedText';
import getMessageError from 'helpers/getMessageError';
import { closeModal } from 'actions/modal';
import ACTIONS from 'constants/actionTypes';
import { RootState, StoreProps } from 'store';
import {
  AnalyticsData,
  ByOrder,
  GraphData,
  IFormValues,
  ILoadChartData,
} from 'types/Analytics';
import {
  createChart as createChartRequest,
  deleteChart,
  editChart,
  getChart,
  getChartFilters,
  getUserCharts,
  moveChart,
} from 'api/analytics';
import { isGraph } from 'components/modal/modalList/graphDetails/components/settingsForm/utils';
import showNotification from 'components/ui/notification/showNotification';
import { ServiceCheckGraphEmpty } from 'components/modal/modalList/graphDetails/services/ServiceCheckGraphEmpty';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import {
  chartDataFormatterWrapper,
  chartDataSerializer,
  filtersNormalizer,
} from 'helpers/analytics';

const CHUNK_THRESHOLD = 2;
const NO_DATA_TEXT = 'analytics.mainView.noDataFound.error';
const SUCCESS_SAVED_CHART_TEXT = 'analytics.mainView.chartSaved.text';

const newChartItem = (payload: {
  id: string;
  chartData: GraphData['data'];
}) => {
  const { id, chartData } = payload;
  const data = chartDataFormatterWrapper(chartData);
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.CHART_SAVED,
        payload: {
          id,
          chartData: data,
        },
      })
    );
  };
};

const loadChartData = (payload: ILoadChartData) => {
  const { id, chartData, error, order } = payload;

  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.CHART_LOADED,
        payload: {
          id,
          chartData,
          error,
          order,
        },
      })
    );
  };
};

const fetchUserCharts = () => {
  return async (dispatch: StoreProps['dispatch']) => {
    try {
      const chartsData = await getUserCharts();

      dispatch({
        type: ACTIONS.CHARTS_LOADED,
        payload: {
          chartsData,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * Request to get the data for chart. The response will be via WebSocket.
 */
const queryChartItems = () => {
  return async (
    dispatch: StoreProps['dispatch'],
    getStore: () => RootState
  ) => {
    const byOrder: ByOrder = getStore().analytics.gridData.byOrder;
    const orderList = Object.keys(byOrder);
    const batchedData = chunk(orderList, CHUNK_THRESHOLD);

    for (const orderBatchItem of batchedData) {
      const promiseList = orderBatchItem.map(async (orderItem) => {
        try {
          await getChart({ id: byOrder[orderItem].biChartId });
        } catch (err) {
          const errText = getMessageError(err);

          dispatch(
            loadChartData({
              error: errText,
              id: byOrder[orderItem].biChartId,
              order: Number(orderItem),
            })
          );
        }
      });

      try {
        await Promise.all(promiseList);
      } catch (err) {
        console.error(err);
      }
    }
  };
};

const fetchChartItems = (data: GraphData['data']) => {
  return async (
    dispatch: StoreProps['dispatch'],
    getStore: () => RootState
  ) => {
    const byOrderAsArray: [string, { biChartId: string }][] = Object.entries(
      getStore().analytics.gridData.byOrder
    );
    const byOrderAsArrayFilter = byOrderAsArray.filter(
      ([, value]) => value.biChartId === data.biChartId
    );
    const byOrder = Object.fromEntries(byOrderAsArrayFilter);
    const orderList = Object.keys(byOrder);
    const batchedData = chunk(orderList, CHUNK_THRESHOLD);

    for (const orderBatchItem of batchedData) {
      const promiseList = orderBatchItem.map(async (orderItem) => {
        try {
          const chartData = chartDataFormatterWrapper(data);

          dispatch(
            loadChartData({
              id: data.biChartId,
              chartData,
              order: Number(orderItem),
            })
          );
        } catch (err) {
          const errText = getMessageError(err);

          dispatch(
            loadChartData({
              error: errText,
              order: Number(orderItem),
              id: byOrder[orderItem].biChartId,
            })
          );
        }
      });

      try {
        await Promise.all(promiseList);
      } catch (err) {
        console.error(err);
      }
    }
  };
};

const removeChart = (payload: { order: number; id: string }) => {
  const { order, id } = payload;

  return async (
    dispatch: StoreProps['dispatch'],
    getStore: () => RootState
  ) => {
    const {
      analytics: {
        gridData: { byOrder, orderData },
        chartData: { byId },
      },
    } = getStore() as AnalyticsData;

    dispatch(
      createAction({
        type: ACTIONS.CHART_DELETED,
        payload: {
          order,
          id,
        },
      })
    );

    try {
      await deleteChart({ id });
    } catch (err) {
      dispatch(
        createAction({
          type: ACTIONS.CHART_RESTORE_STATE,
          payload: {
            byId,
            orderData,
            byOrder,
          },
        })
      );
    }
  };
};

const changeOrder = (payload: { oldOrder: number; newOrder: number }) => {
  const { oldOrder, newOrder } = payload;

  return async (
    dispatch: StoreProps['dispatch'],
    getStore: () => RootState
  ) => {
    const {
      analytics: {
        gridData: { byOrder, orderData },
        chartData: { byId },
      },
    } = getStore() as AnalyticsData;

    const movedBiChartId = byOrder[oldOrder]['biChartId'];
    const newOrderData = orderData.filter(
      ({ biChartId }) => biChartId !== movedBiChartId
    );

    newOrderData.splice(newOrder - 1, 0, {
      biChartId: movedBiChartId,
      order: -1,
    });

    let index = 0;

    const resultOrderData = newOrderData.map(({ biChartId }) => ({
      biChartId,
      order: ++index,
    }));

    dispatch(
      createAction({
        type: ACTIONS.CHART_ORDER_CHANGED,
        payload: {
          orderData: resultOrderData,
          byOrder,
        },
      })
    );

    try {
      await moveChart({
        from: movedBiChartId,
        to: byOrder[newOrder]['biChartId'],
      });
    } catch (err) {
      dispatch(
        createAction({
          type: ACTIONS.CHART_RESTORE_STATE,
          payload: {
            orderData,
            byOrder,
            byId,
          },
        })
      );
    }
  };
};

const fetchChartFilter = (id: string) => {
  return async (dispatch: StoreProps['dispatch']) => {
    const { data } = await getChartFilters({ id });
    const normalizedFilters = filtersNormalizer(data);
    const filterData = { [id]: normalizedFilters };

    dispatch(
      createAction({
        type: ACTIONS.CHART_FILTER_LOADED,
        payload: { filterData },
      })
    );
  };
};

const editChartItem = (payload: {
  values: IFormValues;
  id: string;
  chartType: AdvancedAnalyticsEntityType;
}) => {
  const { values, id, chartType } = payload;

  return async (dispatch: StoreProps['dispatch']) => {
    try {
      const _requestData = chartDataSerializer({ chartType, values });
      const { chartParameters, ...rest } = _requestData;
      const requestData = {
        chartParameters: { ...chartParameters, id },
        ...rest,
      };
      const { data } = await editChart(requestData);
      const isGraphEmpty = ServiceCheckGraphEmpty(data);

      if (isGraphEmpty) {
        showNotification({
          status: 'info',
          content: getLocalizedText(NO_DATA_TEXT),
        });
      } else {
        showNotification({
          status: 'success',
          content: getLocalizedText(SUCCESS_SAVED_CHART_TEXT),
        });
      }

      const chartData = chartDataFormatterWrapper(data);

      dispatch(
        createAction({
          type: ACTIONS.CHART_EDIT,
          payload: {
            id,
            chartData,
          },
        })
      );

      await dispatch(fetchChartFilter(id));
      dispatch(closeModal());
    } catch (err) {
      console.error(err);
    }
  };
};

const createChart = (payload: {
  graphType: AdvancedAnalyticsEntityType;
  values: IFormValues;
}) => {
  const { graphType, values } = payload;

  return async (dispatch: StoreProps['dispatch']) => {
    const requestData = chartDataSerializer({ chartType: graphType, values });

    try {
      const { data } = await createChartRequest(requestData);
      const isGraphEmpty = ServiceCheckGraphEmpty(data);
      const id = data.biChartId;

      if (isGraphEmpty) {
        showNotification({
          status: 'info',
          content: getLocalizedText(NO_DATA_TEXT),
        });
      } else {
        showNotification({
          status: 'success',
          content: getLocalizedText(SUCCESS_SAVED_CHART_TEXT),
        });
      }

      dispatch(
        newChartItem({
          id,
          chartData: data,
        })
      );

      await dispatch(fetchChartFilter(id));

      dispatch(closeModal());
    } catch (err) {
      console.error(err);
    }
  };
};

const fetchChartFilters = () => {
  return async (
    dispatch: StoreProps['dispatch'],
    getStore: () => RootState
  ) => {
    const {
      analytics: {
        chartData: { byId },
      },
    } = getStore() as AnalyticsData;

    const idsList = Object.keys(byId);
    const filtersList = await Promise.all(
      idsList.map(async (id) => {
        const { data } = await getChartFilters({ id });

        return filtersNormalizer(data);
      })
    );

    const filtersById = idsList.reduce((acc, id, index) => {
      acc[id] = filtersList[index];

      return acc;
    }, {});

    dispatch(
      createAction({
        type: ACTIONS.CHART_FILTERS_LOADED,
        payload: { filtersById },
      })
    );
  };
};

const reflowAllCharts = () => {
  return (dispatch: StoreProps['dispatch'], getStore: () => RootState) => {
    const {
      analytics: {
        chartData: { byId },
      },
    } = getStore() as AnalyticsData;

    const newById = Object.entries(byId).reduce((acc, [id, itemData]) => {
      if (
        'chartType' in itemData &&
        isGraph(itemData.chartType) &&
        'uuid' in itemData
      ) {
        itemData.uuid = uuid();
      }

      acc[id] = itemData;

      return acc;
    }, {});

    dispatch(
      createAction({
        type: ACTIONS.BYID_MUTATED,
        payload: { byId: newById },
      })
    );
  };
};

export {
  queryChartItems,
  fetchUserCharts,
  removeChart,
  changeOrder,
  editChartItem,
  createChart,
  fetchChartFilters,
  reflowAllCharts,
  fetchChartItems,
};
