import React, { Component } from 'react';
import { debounce } from 'lodash-es';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PageTemplate from 'components/pageTemplate';
import Loader from 'components/ui/loader';
import HelpMenu from './components/menu';
import HelpMenuTab from './components/menuTab';
import HelpArticle from 'pages/help/article';
import './helpPage.scss';
import AnchorList from './components/ancorList';

interface Props extends IntlProps {
  isFetched: boolean;
}

interface State {
  isSmallScreen: boolean;
}

class HelpPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isSmallScreen: false,
    };

    this.computeWidth = debounce(this.computeWidth, 100);
  }

  componentDidMount() {
    this.computeWidth();
    window.addEventListener('resize', this.computeWidth);
  }

  render() {
    const { isFetched, getTranslate } = this.props;

    return (
      <PageTemplate.Main customClass='help-page'>
        <div className='help-page__main'>
          <div className='help-page__sidebar'>
            <HelpMenu showSubmenu={this.state.isSmallScreen} />
          </div>
          <div className='help-page__content'>
            <div className={'help-page__sm'}>
              <div className={'help-page__sm-header'}>
                <h2>Help Categories</h2>
              </div>
              <HelpMenuTab showSubmenu />
              <AnchorList sm />
            </div>
            {isFetched ? this.renderContent() : <Loader />}
          </div>
          {!this.state.isSmallScreen && (
            <div className='help-page__sidebar'>
              <div className='help-menu help-menu_anchor-list'>
                <div className='help-menu__header help-menu__title'>
                  {getTranslate('help.contents.title')}
                </div>
                <AnchorList />
              </div>
            </div>
          )}
        </div>
      </PageTemplate.Main>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.computeWidth);
  }

  renderContent() {
    return <HelpArticle />;
  }

  computeWidth = () => {
    this.setState({ isSmallScreen: document.body.offsetWidth <= 1200 });
  };
}

export default addTranslation(HelpPage);
