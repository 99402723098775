import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { StoreProps } from 'store';

import {
  deleteRemittance,
  getRemittanceList,
  getRemittancePaymentBatchList,
  getRemittancePaymentsList,
} from 'api/remittance';
import { deleteDataItem } from 'actions/getData';
import { WithRouterProps } from 'decorators/withRouter';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import getConfigurationByName from 'selectors/getConfigurationByName';
import ItemConfiguration from 'types/ItemConfiguration';
import { getPath, isCustomId } from 'pages/remittance/helpers';
import path from 'helpers/path';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { tableRoutes } from './routesMapper';
import Remittance from './Remittance';
import { invert } from 'lodash-es';
import './remittance.scss';

interface ConnectedProps {
  paymentConfiguration: ItemConfiguration[];
  accountConfiguration: ItemConfiguration[];
  massRemittancePaymentsConfiguration: ItemConfiguration[];
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ id: string }>;

export type tableNameType =
  | tableNames.massRemittancePayments
  | tableNames.remittancePayments
  | tableNames.remittanceRecipientAccounts;

interface State {
  tableName: tableNameType;
}

@addListeners([Messages.Remittance_DeleteRecipientAccount])
class RemittanceContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableName: tableNames.remittancePayments,
    };
  }

  componentDidMount() {
    this.setTableFromRoute();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      match: {
        params: { id: prevId },
      },
    } = prevProps;

    if (prevId !== id) {
      this.setTableFromRoute();
    }
  }

  setTableFromRoute = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const tableName = invert(tableRoutes)[id] as tableNameType;

    this.setState({
      tableName: tableName || tableNames.remittancePayments,
    });
  };

  getSettings = () => {
    const { tableName } = this.state;
    const {
      paymentConfiguration,
      massRemittancePaymentsConfiguration,
      accountConfiguration,
    } = this.props;

    switch (tableName) {
      case tableNames.remittancePayments:
        return {
          request: getRemittancePaymentsList,
          configuration: paymentConfiguration,
        };
      case tableNames.massRemittancePayments:
        return {
          request: getRemittancePaymentBatchList,
          configuration: massRemittancePaymentsConfiguration,
        };
      case tableNames.remittanceRecipientAccounts:
        return {
          request: getRemittanceList,
          configuration: accountConfiguration,
        };
    }
  };

  handleOpenCard = (data) => {
    const { history } = this.props;
    const { tableName } = this.state;
    const pathToGo = getPath(tableName, data);
    history.push(path(pathToGo));
  };

  handleDelete = async (id) => {
    await deleteRemittance(id);
  };

  handleChangeTable = (tableName) => {
    const { history } = this.props;

    if (tableName === tableNames.remittancePayments) {
      history.push(path(`/${urlsMap.remittance}`));
    } else {
      history.push(path(`/${urlsMap.remittance}/${tableRoutes[tableName]}`));
    }
  };

  handleChangeBuilder = () => {
    const { history } = this.props;
    const { tableName } = this.state;
    history.push(path(getPath(tableName, {}, true)));
  };

  onEvent = ({ data }) => {
    const { dispatch } = this.props;
    const { remittanceRecipientAccountId } = data.payload;

    dispatch(
      deleteDataItem(
        tableNames.remittanceRecipientAccounts,
        'remittanceRecipientAccountId',
        remittanceRecipientAccountId
      )
    );
  };

  render() {
    const {
      match,
      location: { pathname, search },
    } = this.props;
    const { tableName } = this.state;
    const { id } = match.params;
    const { type } = queryString.parse(search);
    const { configuration, request } = this.getSettings();
    const isNewTab = match.path.includes(`${urlsMap.remittance}/create`);
    const isBatchDetail = Boolean(type === 'batch');

    if (!configuration) {
      return null;
    }

    return (
      <Remittance
        id={id}
        tableName={tableName}
        configuration={configuration}
        pathname={pathname}
        apiRequest={request}
        isNewTab={isNewTab}
        isBatchDetail={isBatchDetail}
        isRecipientAccountCard={Boolean(id && isCustomId(id) && !search)}
        onChangeTable={this.handleChangeTable}
        onChangeBuilder={this.handleChangeBuilder}
        onClickItem={this.handleOpenCard}
        onDelete={this.handleDelete}
      />
    );
  }
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    paymentConfiguration: getConfigurationByName(
      state,
      tableNames.remittancePayments
    ),
    massRemittancePaymentsConfiguration: getConfigurationByName(
      state,
      tableNames.massRemittancePayments
    ),
    accountConfiguration: getConfigurationByName(
      state,
      tableNames.remittanceRecipientAccounts
    ),
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(RemittanceContainer))
);
