import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { RootState } from 'store';
import getPath from 'helpers/path';
import { setCurrentCurrency, setRefreshId } from 'actions/home';
import { useTranslation } from 'hooks';
import { getHomePageCurrency } from 'api/home';
import landingState from 'constants/landingState';
import PageTemplate from 'components/pageTemplate';
import Button from 'components/ui/button';
import {
  TotalSummaries,
  QuickActions,
  OverviewGraphs,
  MainBannerContainer,
} from './modules';
import './home.scss';
import { CurrencySelect } from './components/CurrencySelect';
import Loader from 'components/ui/loader';

export const Home: React.FC = () => {
  const getTranslate = useTranslation();
  const dispatch = useDispatch();
  const { user, currentCurrency } = useSelector((state: RootState) => ({
    user: state.user,
    currentCurrency: state.home.currentCurrency,
  }));

  useEffect(() => {
    if (!currentCurrency) {
      fetchSelectedCurrency();
    }
  }, [currentCurrency]);

  const fetchSelectedCurrency = async () => {
    try {
      const { homePageCurrency } = await getHomePageCurrency();
      dispatch(setCurrentCurrency({ currency: homePageCurrency }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleRefresh = () => {
    dispatch(setRefreshId({ id: uuid() }));
  };

  if (user.landingState !== landingState.default) {
    return <Redirect to={getPath('/')} />;
  }

  if (!currentCurrency) {
    return <Loader />;
  }

  return (
    <PageTemplate.Main>
      <PageTemplate.Container customClass='home-page-template'>
        <MainBannerContainer />

        <div className='home-page-container'>
          <main className='home-page'>
            <div className='home-page__header'>
              <h1 className='home-page__title'>
                {getTranslate('getStarted.greeting.label')}, {user.name}
              </h1>

              <div className='home-page__actions'>
                <Button
                  id='refresh'
                  status='light'
                  icon='im-Refill'
                  iconSize={14}
                  iconPosition='left'
                  text={getTranslate('common.refresh.button')}
                  onClick={handleRefresh}
                />

                <CurrencySelect />
              </div>
            </div>
            <div className='home-page__modules'>
              <TotalSummaries />
              <QuickActions />
              <OverviewGraphs />
            </div>
          </main>
        </div>
      </PageTemplate.Container>
    </PageTemplate.Main>
  );
};
