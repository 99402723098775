import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import Utils from 'helpers/Utils';
import Form from 'components/ui/form';
import InputAmount from 'components/ui/inputAmount';
import Button from 'components/ui/button';
import Checkbox from 'components/ui/checkbox';

interface ConnectedProps {
  isCreateFiscalReceiptEnabled: boolean;
}

interface OwnProps {
  isSending: boolean;
  currency: string;
  maxValue: string;
  title: string;
  text: string;
  label: string;
  buttonText: string;
  projectId: string;
  transactionId: string;
  onSubmit: (amount: number) => void;
}

type Props = OwnProps & IntlProps & WithRouterProps & ConnectedProps;

interface State {
  amount: string;
  isCreateFiscalReceipt: boolean;
}

class ActionForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      isCreateFiscalReceipt: false,
    };
  }

  componentDidMount() {
    //need for position in table
    const el: HTMLElement | null = document.body.querySelector(
      '.ReactVirtualized__Grid'
    );
    if (el) {
      el.style.willChange = 'unset';
    }
  }

  componentWillUnmount() {
    const el: HTMLElement | null = document.body.querySelector(
      '.ReactVirtualized__Grid'
    );
    if (el) {
      el.style.willChange = 'transform';
    }
  }

  isValidForm = () => {
    const { isSending } = this.props;
    const { amount, isCreateFiscalReceipt } = this.state;

    if (isCreateFiscalReceipt) {
      return (
        !this.isMoreThatAvailable() &&
        Number(Utils.getNumberWithoutSpace(amount)) >= 0
      );
    }

    return (
      amount &&
      Number(Utils.getNumberWithoutSpace(amount)) > 0 &&
      !this.isMoreThatAvailable() &&
      !isSending
    );
  };

  handleSubmitRefund = () => {
    const {
      location,
      history,
      projectId,
      transactionId,
      maxValue,
      currency,
      onSubmit,
    } = this.props;
    const { amount, isCreateFiscalReceipt } = this.state;

    if (isCreateFiscalReceipt) {
      history.push({
        pathname: '/payments/create-fiscal-receipt',
        search: `?projectId=${projectId}&transactionId=${transactionId}`,
        state: {
          data: {
            currency,
            maxRefundAmount: maxValue,
            refundAmount: amount,
            returnBack: `${location.pathname}${location.search}`,
          },
        },
      });
      return;
    }

    if (this.isValidForm()) {
      onSubmit(+Utils.getNumberWithoutSpace(amount));
    }
  };

  isMoreThatAvailable = () => {
    const { maxValue } = this.props;
    const { amount } = this.state;
    if (!maxValue) return;
    return (
      Number(Utils.getNumberWithoutSpace(amount)) >
      Number(Utils.getNumberWithoutSpace(maxValue))
    );
  };

  handleChangeAmount = ({ target }) => {
    this.setState({
      amount: target.value,
    });
  };

  onHover = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      Tooltip.hide();
    }, 50);
  };

  render() {
    const {
      isSending,
      currency,
      maxValue,
      title,
      text,
      label,
      buttonText,
      getTranslate,
      isCreateFiscalReceiptEnabled,
    } = this.props;
    const { amount, isCreateFiscalReceipt } = this.state;

    return (
      <div className='payment-action__form' onMouseEnter={this.onHover}>
        <div className='payment-action__form-title'>{getTranslate(title)}</div>
        <div className='payment-action__form-available'>
          {getTranslate(text)}{' '}
          <span className='payment-action__accent'>
            {maxValue} {currency}
          </span>
        </div>
        <div className='payment-action__form-content'>
          <Form onSubmit={this.handleSubmitRefund}>
            <InputAmount
              label={getTranslate(label)}
              autoFocus
              id='input-refund'
              placeholder='0.00'
              value={amount}
              customClass='payment-action__form-item'
              disabled={isSending}
              error={
                this.isMoreThatAvailable()
                  ? getTranslate('payments.single.refundAmountBigger')
                  : ''
              }
              onChange={this.handleChangeAmount}
              modern
            />

            {isCreateFiscalReceiptEnabled ? (
              <Checkbox
                id='create-fiscal-receipt'
                text={getTranslate('fiscalReceipt.create.checkbox.label')}
                tooltip={getTranslate(
                  'fiscalReceipt.detCard.FiscReceipt.tooltip'
                )}
                checked={isCreateFiscalReceipt}
                customClass='payment-action__form-item'
                onChange={() =>
                  this.setState({
                    isCreateFiscalReceipt: !isCreateFiscalReceipt,
                  })
                }
              />
            ) : null}

            <Button
              type='submit'
              status='primary'
              text={getTranslate(
                isCreateFiscalReceipt
                  ? 'fiscalReceipt.CreateReceipt.submitButton'
                  : buttonText
              )}
              customClass='payment-action__form-button ui-button_full-width'
              loading={isSending}
              disabled={!this.isValidForm()}
            />
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps): ConnectedProps => {
  return {
    isCreateFiscalReceiptEnabled:
      state.paymentActions[props.transactionId]?.refundCashVoucher?.isEnabled,
  };
};

export default connect(mapStateToProps)(withRouter(addTranslation(ActionForm)));
