import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

import { ById, ByOrder, GraphData, IFiltersById } from 'types/Analytics';
import { AnyObject } from 'types/Common';

interface IAnalyticsChartState {
  chartData: {
    byId: ById;
    filtersById: IFiltersById;
  };
  gridData: {
    byOrder: ByOrder;
    orderData: Array<{ biChartId: string; order: number }>;
  };
}

const initialState: IAnalyticsChartState = {
  chartData: {
    byId: {},
    filtersById: {},
  },
  gridData: {
    byOrder: {},
    orderData: [],
  },
};

const analytics = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.CHART_SAVED: {
      const {
        id,
        chartData,
      }: { id: string; chartData: GraphData } | AnyObject = payload;
      const orderList: Array<string> = Object.keys(state.gridData.byOrder);
      const lastOrder: number =
        orderList.length === 0
          ? 1
          : Number(orderList[orderList.length - 1]) + 1;

      return {
        ...state,
        chartData: {
          ...state.chartData,
          byId: {
            ...state.chartData.byId,
            [id]: chartData,
          },
        },
        gridData: {
          byOrder: {
            ...state.gridData.byOrder,
            [lastOrder]: {
              biChartId: id,
              fetched: true,
            },
          },
          orderData: [
            ...state.gridData.orderData,
            { biChartId: id, order: lastOrder },
          ],
        },
      };
    }

    case ACTIONS.CHART_LOADED: {
      const {
        id,
        chartData,
        order,
        error,
      }:
        | {
            id: string;
            chartData: GraphData;
            order: number;
            error?: string;
          }
        | AnyObject = payload;

      if (error !== undefined) {
        return {
          ...state,
          chartData: {
            ...state.chartData,
            byId: {
              ...state.chartData.byId,
              [id]: { error, biChartId: id },
            },
          },
          gridData: {
            ...state.gridData,
            byOrder: {
              ...state.gridData.byOrder,
              [order]: {
                biChartId: id,
                fetched: true,
                error,
              },
            },
          },
        };
      }

      return {
        ...state,
        chartData: {
          ...state.chartData,
          byId: {
            ...state.chartData.byId,
            [id]: chartData,
          },
        },
        gridData: {
          ...state.gridData,
          byOrder: {
            ...state.gridData.byOrder,
            [order]: {
              biChartId: id,
              fetched: true,
            },
          },
        },
      };
    }

    case ACTIONS.CHARTS_LOADED: {
      const {
        chartsData,
      }:
        | { chartsData: Array<{ biChartId: string; order: number }> }
        | AnyObject = payload;
      const chartsByOrder = chartsData.reduce((accum, { biChartId, order }) => {
        accum[order] = {
          biChartId,
          fetched: false,
        };

        return accum;
      }, {});

      return {
        ...state,
        gridData: {
          byOrder: chartsByOrder,
          orderData: chartsData.map(({ biChartId, order }) => ({
            biChartId,
            order,
          })),
        },
      };
    }

    case ACTIONS.CHART_DELETED: {
      const { id, order }: { id: string; order: number } | AnyObject = payload;
      const {
        gridData: {
          byOrder: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            [order]: { biChartId: __biChartId },
            ...restByOrder
          },
        },
      } = state;

      let restById = state.chartData.byId;

      if (restById[id] !== undefined) {
        const {
          chartData: {
            byId: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              [id]: { biChartId: _biChartId },
              ..._restById
            },
          },
        } = state;

        restById = _restById;
      }

      return {
        ...state,
        chartData: { ...state.chartData, byId: restById },
        gridData: {
          byOrder: restByOrder,
          orderData: state.gridData.orderData.filter(
            ({ biChartId }) => biChartId !== id
          ),
        },
      };
    }

    case ACTIONS.CHART_ORDER_CHANGED: {
      const {
        orderData,
      }:
        | {
            orderData: Array<{ biChartId: string; order: number }>;
          }
        | AnyObject = payload;
      const newByOrder = orderData.reduce((accum, { biChartId, order }) => {
        const error = state.chartData.byId[biChartId]['error'];

        accum[order] = {
          biChartId,
          fetched: true,
          error,
        };

        return accum;
      }, {});

      return {
        ...state,
        gridData: {
          byOrder: newByOrder,
          orderData,
        },
      };
    }

    case ACTIONS.CHART_RESTORE_STATE: {
      const {
        byId,
        orderData,
        byOrder,
      }:
        | {
            byId: ById;
            byOrder: ByOrder;
            orderData: Array<{ biChartId: string; order: number }>;
          }
        | AnyObject = payload;

      return {
        ...state,
        chartData: {
          ...state.chartData,
          byId,
        },
        gridData: {
          byOrder,
          orderData,
        },
      };
    }

    case ACTIONS.CHART_EDIT: {
      const {
        id,
        chartData,
      }: { id: string; chartData: GraphData } | AnyObject = payload;
      const newById = {
        [id]: chartData,
      };

      return {
        ...state,
        chartData: {
          ...state.chartData,
          byId: { ...state.chartData.byId, ...newById },
        },
      };
    }

    case ACTIONS.CHART_FILTERS_LOADED: {
      const { filtersById }: { filtersById: IFiltersById } | AnyObject =
        payload;

      return {
        ...state,
        chartData: {
          ...state.chartData,
          filtersById,
        },
      };
    }

    case ACTIONS.CHART_FILTER_LOADED: {
      const { filterData }: { filterData: IFiltersById } | AnyObject = payload;
      const filtersById = state.chartData.filtersById;

      return {
        ...state,
        chartData: {
          ...state.chartData,
          filtersById: {
            ...filtersById,
            ...filterData,
          },
        },
      };
    }

    case ACTIONS.BYID_MUTATED: {
      const { byId }: { byId: ById } | AnyObject = payload;

      return {
        ...state,
        chartData: {
          ...state.chartData,
          byId: {
            ...byId,
          },
        },
      };
    }
    case ACTIONS.RESET_ALL: {
      return initialState;
    }
    default:
      return state;
  }
};
export { analytics };
