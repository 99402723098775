import React, { Component } from 'react';
import classNames from 'classnames';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DatePicker from 'components/ui/datePicker';
import Button from 'components/ui/button';
import Animation from 'components/ui/animation';
import ManualInputCalendar from 'components/ui/manualInputCalendar';
import InfoIcon from '../infoIcon';
import DateHelpers from 'helpers/Date';
import { isTopPositionByBlock } from 'helpers/setCorrectPosition';
import modes from './modes';
import dateFormats from 'constants/dateFormats';
import { AnyObject } from 'types/Common';
import './inputDate.scss';
import './inputDateModern.scss';

interface OwnProps {
  selectedDate?: Date | string; // Date or DD.MM.YYYY HH:mm:ss
  mode: keyof typeof modes;
  onChange?: (date: string) => void;
  title?: string;
  id?: string;
  customClass?: string;
  error?: string;
  minDate?: string;
  maxDate?: string;
  withTime?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  initialDate?: string;
  tooltip?: string;
  withCloseButton?: boolean;
  modern?: boolean;
  isUnselectDate?: boolean;
  closeAfterSelect?: boolean;
  onDatePickerToggle?: (isOpen: boolean) => void;
  getRef?: (ref: any) => void;
  containerSelector?: string;
  disableYear?: boolean;
  isHideYearPicker?: boolean;
  applyFormat?: keyof typeof dateFormats;
  withApplyButton?: boolean;
  onFocus?: (e: AnyObject) => void;
  tooltipId?: string;
  tooltipPlace?: string;
  scrollIntoView?: boolean;
}

interface State {
  isOpened: boolean;
  isTopPosition: boolean;
}

type Props = OwnProps & IntlProps;

class InputDate extends Component<Props, State> {
  private inputDate;
  private pickerRef;
  static defaultProps = {
    mode: modes.from,
    withTime: true,
    withCloseButton: true,
    tooltip: 'common.enterDate.oneDate.incorrectFormat.text',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      isTopPosition: false,
    };

    this.pickerRef = React.createRef();
    this.attachEvents();
  }

  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.state.isOpened && !prevState.isOpened) {
      this.setState({
        isTopPosition: isTopPositionByBlock(
          this.inputDate,
          this.props.containerSelector,
          '.ui-input-date__picker',
          true
        ),
      });
    }
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  render() {
    const {
      id,
      onChange,
      selectedDate,
      initialDate,
      mode,
      title,
      minDate,
      withTime,
      isRequired,
      customClass,
      getTranslate,
      tooltip,
      tooltipId,
      getRef,
      withCloseButton,
      modern,
      isUnselectDate,
      error,
      isDisabled,
      disableYear,
      onFocus,
    } = this.props;
    const { isOpened, isTopPosition } = this.state;

    return (
      <div
        id={id}
        ref={(el) => {
          this.inputDate = el;
        }}
        className={classNames(
          `ui-input-date ui-input-date_${mode}`,
          customClass,
          {
            'ui-input-date_modern': !!modern,
            'ui-input-date_info': !!tooltip,
            'ui-input-date_error': !!error,
            'ui-input-date_disabled': isDisabled,
            'ui-input-date_top': isTopPosition,
            [`${customClass}`]: !!customClass,
          }
        )}
        onClick={() => this.toggle(!isOpened)}>
        <div
          className={classNames('ui-input-date__wrapper', {
            'ui-input-date__wrapper_modern': modern,
          })}>
          <div className='ui-input-date__top'>
            <span className='ui-input-date__label'>
              {getTranslate(title) || getTranslate(`ui.inputDate.${mode}`)}
              {isRequired && (
                <span
                  data-tip={getTranslate('common.requiredField.tooltip')}
                  className='ui-input__required'>
                  *
                </span>
              )}
              {tooltip && !modern && withTime && (
                <InfoIcon
                  tooltip={getTranslate(tooltip)}
                  tooltipType='type-info_mobile'
                  customClass='ui-input-date__info'
                />
              )}
            </span>
            {isOpened && withCloseButton && (
              <Button
                text={getTranslate('filters.components.close')}
                type='button'
                customClass='ui-button_panel-button ui-input-date__close'
                onClick={() => this.toggle(false)}
              />
            )}
          </div>
          <ManualInputCalendar
            id={`${id}-${mode}`}
            mode={mode}
            onChange={this.onInputDate}
            onClick={this.handleManualInputClick}
            value={{ dateFrom: selectedDate || '' }}
            onOpenCalendar={() => this.toggle(!isOpened)}
            isInnerBtn={true}
            withTime={withTime}
            isDisabled={isDisabled}
            minDate={minDate}
            maxDate={this.props.maxDate}
            isOpenedCalendar={isOpened}
            modern={modern}
            onFocus={onFocus}
          />
          {tooltip && modern && withTime && (
            <InfoIcon
              icon='im-Info'
              size={16}
              tooltip={getTranslate(tooltip)}
              tooltipId={tooltipId}
              customClass='ui-input-date__info'
              dataPlace={this.props.tooltipPlace}
            />
          )}
          <div
            ref={getRef}
            className='ui-input-date__picker'
            onClick={(e) => {
              e.stopPropagation();
            }}>
            {isOpened && (
              <Animation duration={300}>
                <DatePicker
                  isUnselectDate={isUnselectDate}
                  selectedDate={selectedDate}
                  initialDate={initialDate}
                  onChange={(date, type) => {
                    onChange?.(date);
                    if (
                      (this.props.closeAfterSelect && type === 'day') ||
                      this.props.withApplyButton
                    ) {
                      this.toggle(false);
                    }
                  }}
                  mode={mode}
                  minDate={minDate}
                  maxDate={this.props.maxDate}
                  withTime={withTime}
                  pickerRef={this.pickerRef}
                  disableYear={disableYear}
                  isHideYearPicker={this.props.isHideYearPicker}
                  applyFormat={this.props.applyFormat}
                  withApplyButton={this.props.withApplyButton}
                />
              </Animation>
            )}
          </div>
        </div>
        {error && (
          <div className='ui-input-date__error'>{getTranslate(error)}</div>
        )}
      </div>
    );
  }

  attachEvents = () => {
    document.addEventListener('mousedown', this.close);
  };

  detachEvents = () => {
    document.removeEventListener('mousedown', this.close);
  };

  close = ({ target }) => {
    const { mode } = this.props;
    if (!target.closest(`.ui-input-date_${mode}`)) {
      this.toggle(false);
    }
  };

  toggle = (isOpened) => {
    const {
      isDisabled,
      onDatePickerToggle,
      scrollIntoView = true,
    } = this.props;
    if (!isDisabled) {
      this.setState({ isOpened }, () => {
        onDatePickerToggle && onDatePickerToggle(isOpened);
      });
      setTimeout(() => {
        if (isOpened && this.pickerRef.current && scrollIntoView) {
          this.pickerRef.current.scrollIntoView();
        }
      }, 100);
    }
  };

  getDate = (): { date: string; time: string } => {
    const { selectedDate } = this.props;

    if (selectedDate) {
      const date = DateHelpers.createDate(selectedDate, 'datetime');
      return {
        date: DateHelpers.getFormat(date, 'date'),
        time: DateHelpers.getFormat(date, 'time'),
      };
    }

    return {
      date: '',
      time: '',
    };
  };

  onInputDate = ({ dateFrom }) => {
    this.props.onChange && this.props.onChange(dateFrom);
  };

  handleManualInputClick = (e) => {
    e.stopPropagation();
    if (this.state.isOpened) return;
    this.toggle(!this.state.isOpened);
  };

  getMaxDate = () => {
    return this.props.maxDate === 'none'
      ? DateHelpers.getFormat(
          DateHelpers.addYears(1),
          this.props.applyFormat || 'datetime'
        )
      : this.props.maxDate;
  };
}

export default addTranslation(InputDate);
