import React, { PureComponent, ReactElement } from 'react';
import { AnyObject } from 'types/Common';
import './header.scss';
import classNames from 'classnames';

interface Props {
  style: AnyObject;
  children: any;
  childrenProps?: any;
  setHeaderRef?: (HTMLDivElement) => void;
}

interface State {
  isResizing: boolean;
}

class TableHeader extends PureComponent<Props, State> {
  state = { isResizing: false };
  static defaultProps = {
    style: {},
  };

  render() {
    const { style, children, setHeaderRef, childrenProps } = this.props;
    const { isResizing } = this.state;
    let currentWidthsSum = 0;
    let previousWidthsSum = 0;

    return (
      <div
        style={style}
        className={classNames('ui-table__header', {
          'ui-table__header__resizing': isResizing,
        })}
        ref={setHeaderRef}>
        {React.Children.map(children, (child: ReactElement, idx) => {
          if (!child) return null;

          const { params, customClass, style = {} } = child.props;
          const { columnWidths } = childrenProps || {};
          const { id, fixed } = params || {};
          let children: React.ReactNode = null;

          previousWidthsSum += columnWidths?.[id] || 0;

          children = React.cloneElement(child, {
            ...childrenProps,
            setIsResizing: this.setIsResizing,
            key: idx,
            header: true,
            customClass: `ui-table__column ${customClass}`,
            style: fixed ? { left: currentWidthsSum, ...style } : style,
          });

          currentWidthsSum = previousWidthsSum;

          return children;
        })}
      </div>
    );
  }

  setIsResizing = (isResizing) => this.setState({ isResizing });
}

export default TableHeader;
