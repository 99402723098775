import ACTIONS from 'constants/actionTypes';
import tableNames from 'constants/tableNames';
import { createAction } from 'helpers/redux';

export const setFormValues = (name: keyof typeof tableNames, data: any) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_FORM_VALUES,
        payload: {
          name,
          data,
        },
      })
    );
  };
};

export const resetFormValuesByName = (name: keyof typeof tableNames) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_CONCRETE_FORM_VALUES,
        payload: {
          name,
        },
      })
    );
  };
};

export const resetFormValues = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_FORM_VALUES,
      })
    );
  };
};
