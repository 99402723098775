import React, { PureComponent } from 'react';
import { IntlProps, addTranslation } from 'decorators/addTranslation';

import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import CustomScrollbar from 'components/ui/customScrollbar';
import DataBlock from 'components/ui/dataBlock';
import PaymentDataMapper from 'components/paymentCard/PaymentDataMapper';
import AdditionalInfo from 'components/paymentCard/additionalInfo/AdditionalInfo';
import Loader from 'components/ui/loader';

import PaymentPageUI from 'types/PaymentPageUI';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import { AnyObject } from 'types/Common';
import RoundButton from 'components/ui/roundButton';
import themes from 'components/ui/tabs/themes';
import './paymentInfo.scss';

interface Props extends IntlProps {
  data: AnyObject;
  isLoading: boolean;
  theme?: keyof typeof themes;
  pageUI: PaymentPageUI | RecurringCardPageUI | undefined;
  onChangePageUI: (params: AnyObject) => void;
  closePaymentInfo: () => void;
  isManagePayment?: boolean;
  isCustomerInfo?: boolean;
  isRemittanceRecipientAccount?: boolean;
}

class PaymentInfo extends PureComponent<Props> {
  render() {
    const { closePaymentInfo, getTranslate } = this.props;
    return (
      <div
        id='payment-single-payment-info'
        className='payment-card__payment-info payment-info payment-info_modern card'>
        <RoundButton
          customClass='payment-info__close'
          icon='im-Close'
          iconSize={10}
          size={26}
          onClick={closePaymentInfo}
        />
        <div className='card__header'>
          <div className='card__title-l'>
            {getTranslate('payments.single.paymentInfo')}
          </div>
        </div>
        <div className='payment-info__content'>{this.renderTabs()}</div>
      </div>
    );
  }

  renderTabs = () => {
    const {
      data,
      pageUI,
      onChangePageUI,
      isCustomerInfo,
      isRemittanceRecipientAccount,
      getTranslate,
      isLoading,
    } = this.props;
    const dataBlockTheme = this.props.theme === 'brand' ? 'light' : undefined;

    if (isLoading || !data)
      return (
        <div className='payment-info__loader-wrapper'>
          <Loader type='bung' />
        </div>
      );

    const {
      paymentInfo,
      projectInfo,
      customerInfo,
      customerIp,
      contactInfo,
      billingInfo,
      remittanceRecipientAccount,
    } = PaymentDataMapper.getPaymentInfo(data);

    return (
      <Tabs
        customClass='payment-info__tabs'
        defaultActiveTabIndex={pageUI?.paymentSelectedTab}
        theme={this.props.theme || 'boost-full-height'}
        onClick={(index) => {
          onChangePageUI({
            paymentSelectedTab: index,
            paymentInfoScrollTop: 0,
          });
        }}
        needButtons>
        <Tab title={getTranslate('payments.single.genPaymentInfo.header')}>
          <CustomScrollbar
            customHeight='100%'
            initialScrollTop={pageUI?.paymentInfoScrollTop}
            needResetScrollTop={true}
            onScrollStop={({ scrollTop }) =>
              onChangePageUI({ paymentInfoScrollTop: scrollTop })
            }>
            <DataBlock
              title='payments.single.paymentInformation.header'
              data={paymentInfo}
              theme={dataBlockTheme}
            />
            <DataBlock
              title='payments.single.projectInformation'
              data={projectInfo}
              theme={dataBlockTheme}
            />
          </CustomScrollbar>
        </Tab>

        {isCustomerInfo && (
          <Tab title={getTranslate('payments.single.customerInfo.header')}>
            <CustomScrollbar
              customHeight='100%'
              initialScrollTop={pageUI?.paymentInfoScrollTop}
              needResetScrollTop={true}
              onScrollStop={({ scrollTop }) =>
                onChangePageUI({ paymentInfoScrollTop: scrollTop })
              }>
              <DataBlock
                title='payments.single.customer.header'
                data={customerInfo}
                theme={dataBlockTheme}
              />
              <DataBlock
                title='payments.single.contactinfo.header'
                data={contactInfo}
                theme={dataBlockTheme}
              />
              <DataBlock
                title={'payments.single.IPInfo.header'}
                data={customerIp}
                theme={dataBlockTheme}
              />
              <DataBlock
                title='payments.single.BillingInfo.header'
                data={billingInfo}
                theme={dataBlockTheme}
              />
            </CustomScrollbar>
          </Tab>
        )}

        {isRemittanceRecipientAccount && (
          <Tab
            title={getTranslate(
              'remittance.payments.single.RecipAccInfo.header'
            )}>
            <CustomScrollbar
              initialScrollTop={pageUI?.paymentInfoScrollTop}
              needResetScrollTop={true}
              onScrollStop={({ scrollTop }) =>
                onChangePageUI({ paymentInfoScrollTop: scrollTop })
              }>
              <DataBlock
                title={getTranslate('remittance.payments.RecipAccInfo')}
                data={remittanceRecipientAccount}
                theme={dataBlockTheme}
              />
            </CustomScrollbar>
          </Tab>
        )}
        {Boolean(data.callbacks.length) && data.callbacks[0].merchantData && (
          <Tab title={getTranslate('payments.single.additionalInfo.header')}>
            <CustomScrollbar
              customHeight='100%'
              initialScrollTop={pageUI?.paymentInfoScrollTop}
              needResetScrollTop={true}
              onScrollStop={({ scrollTop }) =>
                onChangePageUI({ paymentInfoScrollTop: scrollTop })
              }>
              <AdditionalInfo content={data.callbacks[0].merchantData} />
            </CustomScrollbar>
          </Tab>
        )}
      </Tabs>
    );
  };
}

export default addTranslation(PaymentInfo);
