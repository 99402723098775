import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ListView from 'components/ui/listView';
import SelectionList from 'components/ui/selectionList';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import images from './images';
import { AnyObject } from 'types/Common';

interface Props extends IntlProps {
  projectId: string;
  isFetchedFilters: boolean;
  status: boolean;
  paymentMethods: AnyObject;
  paymentMethodsStatuses: AnyObject;
  editPaymentMethods: boolean;
  onChangeChannel: (method, items) => void;
  canSavePaymentMethods: boolean;
  onSavePaymentMethods: () => void;
  validationErrors: AnyObject;
  isHighRisk: boolean;
  isLowRisk: boolean;
  eShopLink?: string;
  isEshopLinkAvailable: boolean;
}

const PaymentMethods: React.VFC<Props> = ({
  projectId,
  isFetchedFilters,
  status,
  paymentMethods,
  paymentMethodsStatuses,
  editPaymentMethods,
  onChangeChannel,
  canSavePaymentMethods,
  onSavePaymentMethods,
  validationErrors,
  getTranslate,
  isHighRisk,
  isLowRisk,
  isEshopLinkAvailable,
  eShopLink,
}) => (
  <div className='page-projects__block page-projects__block_payment-methods'>
    <div className='card card_l'>
      <div className='card__header page-projects__block-title'>
        {getTranslate('projects.paymentMethods.title')}
      </div>
      <div className='card__content card__container'>
        <div className='page-projects__block-text'>
          {getTranslate('projects.paymentMethods.text')}
        </div>
        <div className='page-projects__item'>
          <div className='page-projects__block-label'>
            {getTranslate('projects.payIn.label')}
          </div>
          <div className='page-projects__box'>
            {paymentMethods?.payin.length > 0 ? (
              <ListView key={projectId} list={paymentMethods.payin} />
            ) : (
              getTranslate('projects.noPayIn.hint')
            )}
          </div>
        </div>
        <div className='page-projects__item'>
          <div className='page-projects__block-label'>
            {getTranslate('projects.payOut.label')}
          </div>
          <div className='page-projects__box'>
            {paymentMethods?.payout.length > 0 ? (
              <ListView key={projectId} list={paymentMethods.payout} />
            ) : (
              getTranslate('projects.noPayOut.hint')
            )}
          </div>
        </div>
        {editPaymentMethods && (
          <>
            <div className='page-projects__item'>
              <div className='page-projects__block-label'>
                {getTranslate('projects.paymentMethods.addMethods')}
              </div>
              {!isFetchedFilters && <Loader />}
              {isFetchedFilters &&
                paymentMethodsStatuses.map((item) => {
                  return (
                    <div
                      className='page-projects__row page-projects__row_methods'
                      key={item.id}>
                      <div className='page-projects__block-key'>
                        {getTranslate(item.text)}
                      </div>
                      <div className='page-projects__block-value page-projects__block-value_add-method'>
                        <SelectionList
                          items={item.items}
                          onChange={(items) => onChangeChannel(item.id, items)}
                          isFullMode={true}
                          isSearchable={false}
                          isSelectAll={false}
                          error={validationErrors[item.id]}
                          isDisabled={!status}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className='page-projects__block-footer'>
              <Button
                text={getTranslate('projects.urls.save.button')}
                status='primary'
                onClick={onSavePaymentMethods}
                disabled={!canSavePaymentMethods || !status}
              />
            </div>
          </>
        )}
      </div>
    </div>
    <div className='card card_l'>
      {isEshopLinkAvailable && (isHighRisk || isLowRisk) && (
        <>
          <div className='page-projects__payment-methods-title card__header card__title'>
            {getTranslate('projects.paymentMethods.methodCardsApm.label')}
          </div>
          <div className='page-projects__payment-methods card__container card__content'>
            <div className='page-projects__payment-methods-text'>
              {getTranslate('projects.paymentMethods.methodCardsApm.text', {
                node: true,
                eShopLink: (
                  <a
                    href={eShopLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='utils-link'>
                    eShop
                  </a>
                ),
              })}
            </div>
            <div className='page-projects__pictures utils-flex'>
              {isHighRisk &&
                !isLowRisk &&
                Object.entries(images.highRisk).map((item) => (
                  <img src={item[1]} alt={item[0]} key={item[0]} />
                ))}
              {isLowRisk &&
                Object.entries(images.lowRisk)
                  .sort((a, b) => (a[0] >= b[0] ? 1 : -1))
                  .map((item) => (
                    <img src={item[1]} alt={item[0]} key={item[0]} />
                  ))}
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

export default addTranslation(PaymentMethods);
