import React, { PureComponent } from 'react';
import { finalizeMoto } from 'api/payouts';
import Loader from 'components/ui/loader';
import CustomScrollbar from 'components/ui/customScrollbar';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import './paymentFrame.scss';

interface Props {
  callback: (isSuccess) => void;
  content: { src: string; motoPaymentId };
}

interface State {
  isLoading: boolean;
  frameWidth?: number;
  frameHeight?: number;
}

const statuses = {
  'epframe.payment.success': 'success',
  'epframe.payment.decline': 'decline',
  'epframe.payment.fail': 'decline',
  'epframe.payment.error': 'error',
};

const DEFAULT_FRAME_HEIGHT = 750;
const DEFAULT_FRAME_WIDTH = 1000;
const PADDING = 50;
class PaymentFrame extends PureComponent<Props, State> {
  iframe: HTMLIFrameElement | null = null;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      frameWidth: undefined,
      frameHeight: undefined,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler = async (e) => {
    const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    if (
      data.message === 'epframe.loaded' ||
      data.message === 'epframe.resize'
    ) {
      const newWidth = data.data?.width;
      const newHeight = data.data?.height;

      this.setState({
        isLoading: false,
        frameWidth: newWidth < 320 ? DEFAULT_FRAME_WIDTH : newWidth,
        frameHeight: newHeight < 100 ? DEFAULT_FRAME_HEIGHT : newHeight,
      });
    }

    if (statuses[data.message]) {
      await this.finalize(data.message);
    }
  };

  finalize = async (message) => {
    const { callback } = this.props;
    this.setState({ isLoading: true });
    const {
      content: { motoPaymentId },
    } = this.props;

    if (isNotAvailableForSupport(Messages.MotoPayment_Finalize)) return;

    const response = await finalizeMoto({
      motoPaymentId,
      status: statuses[message],
    });
    callback(response);
  };

  handleIframeLoad = () => {
    this.setState((prevState) => {
      const { frameHeight = 0, frameWidth = 0 } = prevState;

      return {
        isLoading: false,
        frameWidth: frameWidth < 320 ? DEFAULT_FRAME_WIDTH : frameWidth,
        frameHeight: frameHeight < 100 ? DEFAULT_FRAME_HEIGHT : frameHeight,
      };
    });
  };

  render() {
    const {
      content: { src },
    } = this.props;
    const { frameWidth, frameHeight, isLoading } = this.state;
    return (
      <div
        className='modal-payment-frame'
        style={{ width: frameWidth, height: (frameHeight ?? 0) + PADDING }}>
        {isLoading && <Loader />}
        <CustomScrollbar>
          <div className='modal-payment-frame__inner'>
            <iframe
              title='payment page'
              src={src}
              ref={(el) => {
                this.iframe = el;
              }}
              style={{ width: frameWidth, height: frameHeight }}
              onLoad={this.handleIframeLoad}
            />
          </div>
        </CustomScrollbar>
      </div>
    );
  }
}

export default PaymentFrame;
