import { useMemo, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'hooks';
import { LineChartData } from 'reducers/home';
import Utils from 'helpers/Utils';
import { currencyCodeToSymbol } from 'pages/home/helpers/mapper';
import { OverallChip } from 'pages/home/components/OverallChip/OverallChip';
import { ChartView } from '../ChartView/ChartView';
import './lineChart.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface Props {
  data: LineChartData;
}

export const LineChartView: React.FC<Props> = ({ data }) => {
  const getTranslate = useTranslation();
  const brandColor = useSelector(
    (state: RootState) => state.settings.themeConfig.colors.brand.main
  );
  const { chartData, period, currency } = data;
  const { increase } = chartData.points;
  const { actual: actualPeriod, previous: previousPeriod } = period;

  const [selectedType, setSelectedType] = useState('current');

  const { totalActual, totalPrevious } = useMemo(() => {
    const { actual, previous } = chartData.points;

    return {
      totalActual: actual
        .reduce((prev, current) => prev + current, 0)
        .toFixed(2),
      totalPrevious: previous
        .reduce((prev, current) => prev + current, 0)
        .toFixed(2),
    };
  }, [chartData]);

  const chartOptions: Highcharts.Options = useMemo(() => {
    const { actual: actualSeries, previous: previousSeries } = chartData.series;
    const { actual: actualPoints, previous: previousPoints } = chartData.points;
    const isMobile = window.innerWidth < 768;
    const maxLabels = isMobile ? 5 : 11;
    const pointsMaxCount = Math.max(actualPoints.length, previousPoints.length);
    let labelsStep = Math.ceil(pointsMaxCount / maxLabels);
    let labelsUnit = 'day';

    if (pointsMaxCount > 168) {
      // 6 month
      labelsStep = isMobile ? 56 : 28;
      labelsUnit = 'week';
    }else if (pointsMaxCount > 84) {
      // 3 month
      labelsStep = isMobile ? 28 : 14;
      labelsUnit = 'week';
    }

    return {
      chart: {
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        marginTop: 10,
        marginRight: 13,
      },
      title: {
        text: undefined,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        borderRadius: 8,
        backgroundColor: 'white',
        borderColor: 'transparent',
        shared: true,
        useHTML: true,
        // highcharts do not support react-components by default
        formatter: function () {
          const { points } = this;

          const markup = points?.map((item) => {
            const { options, series } = item.point;
            const date = options.name;
            const amount = options.y ?? '';
            const type = series.name;

            return `
              <div class="line-chart__tooltip-wrapper">
                <div class="${classnames('line-chart__tooltip-date', {
                  'line-chart__tooltip-date_previous': type !== selectedType,
                })}">
                  ${moment(date).format('MMM DD')}
                </div>
                <div class="line-chart__tooltip-total">
                  ${currencyCodeToSymbol[currency]} 
                  ${Utils.getNumberWithSpace(amount)}
                </div>
              </div>
            `;
          });

          return `
            <div class="line-chart__tooltip">
              ${markup?.join('')}
            </div>
          `;
        },
      },
      plotOptions: {
        line: {
          lineWidth: 2,
          cursor: 'pointer',
        },
      },
      xAxis: {
        crosshair: {
          dashStyle: 'ShortDash',
          width: 1,
          color: '#d9d9d9',
        },
        categories: Array(Math.max(actualSeries.length, previousSeries.length))
          .fill(null)
          .map(
            (_, idx) => {
              const labelText = labelsUnit === 'day'
                ? 'analytics.editForm.params.groupPeriodBy.day.item'
                : 'analytics.editForm.params.groupPeriodBy.weekShort.item'
              const labelNumber = labelsUnit === 'day'
                ? idx + 1
                : Math.floor(idx / 7 + 1);
              return `${getTranslate(labelText)} ${labelNumber}`;
            },
          ),
        labels: {
          step: labelsStep,
          formatter: function () {
            const { value } = this;
            return `
              <span class="line-chart__axis-label">
                ${value}
              </span>
            `;
          },
        },
      },
      yAxis: {
        title: undefined,
        labels: {
          formatter: function () {
            const { value } = this;
            return `
              <span class="line-chart__axis-label">
                ${Utils.getNumberWithSuffix(value)}
              </span>
            `;
          },
        },
      },
      series: [
        {
          data: actualPoints.map((item, idx) => [actualSeries[idx], item]),
          type: 'line',
          name: 'current',
          // remove first symbol from color
          color: selectedType === 'current' ? brandColor : '#CEBDFF',
          zIndex: selectedType === 'current' ? 1 : 0,
          states: {
            hover: {
              halo: null,
              lineWidthPlus: 0,
            },
          },
          marker: {
            enabled: false,
            radius: 3,
            symbol: 'circle',
          },
        },
        {
          data: previousPoints.map((item, idx) => [previousSeries[idx], item]),
          type: 'line',
          name: 'previous',
          color: selectedType === 'previous' ? brandColor : '#CEBDFF',
          zIndex: selectedType === 'previous' ? 1 : 0,
          states: {
            hover: {
              halo: null,
              lineWidthPlus: 0,
            },
          },
          marker: {
            enabled: false,
            radius: 3,
            symbol: 'circle',
          },
        },
      ],
    };
  }, [chartData, selectedType, currency]);

  const chartInfo = [
    {
      type: 'current',
      title: `${currencyCodeToSymbol[currency]} ${Utils.getNumberWithSuffix(
        totalActual
      )}`,
      increase,
      text: getTranslate('getStarted.lineGraph.currentPeriod'),
      period: `(${actualPeriod.from} — ${actualPeriod.to})`,
    },
    {
      type: 'previous',
      title: `${currencyCodeToSymbol[currency]} ${Utils.getNumberWithSuffix(
        totalPrevious
      )}`,
      text: getTranslate('getStarted.lineGraph.previousPeriod'),
      period: `(${previousPeriod.from} — ${previousPeriod.to})`,
    },
  ];

  return (
    <>
      <div className='line-chart__wrapper'>
        {chartInfo.map(({ type, title, text, period, increase }) => (
          <div
            key={type}
            className={classnames({
              'line-chart__clickable': type !== selectedType,
            })}
            role='button'
            onClick={() => setSelectedType(type)}>
            <div className='line-chart__info'>
              <h3
                className={classnames('line-chart__total', {
                  'line-chart__clickable_total': type !== selectedType,
                })}>
                {title}
              </h3>
              {increase ? <OverallChip value={increase} /> : null}
            </div>
            <p
              className={classnames('line-chart__period', {
                'line-chart__clickable_period': type !== selectedType,
              })}>
              <span>{text}</span>
              <span>{period}</span>
            </p>
          </div>
        ))}
      </div>

      <ChartView
        options={chartOptions}
        containerProps={{ style: { height: 148 } }}
      />
    </>
  );
};
