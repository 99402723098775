import { openModal } from 'actions/modal';
import Icon from 'components/ui/icon';
import { Dispatch } from 'react';

export const openFailureModal = ({ dispatch }: { dispatch: Dispatch<any> }) =>
  dispatch(
    openModal({
      modalId: 'Notification',
      content: {
        icon: (
          <div className='butch-user-edit-modal__icon butch-user-edit-modal__icon_error'>
            <Icon name='im-Error' size={22} />
          </div>
        ),
        title: 'myteam.butchEdit.usersEditingFailure.title',
        text: 'myteam.butchEdit.usersEditingFailure.text',
        okButton: {
          text: 'myteam.butchEdit.result.button',
          status: 'primary',
        },
      },
    })
  );
