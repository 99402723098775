import React, { Component } from 'react';
import { connect } from 'react-redux';

import api from 'api/lcManage';
import { openModal } from 'actions/modal';
import checkAccess from 'decorators/checkAccess';
import showNotification from 'components/ui/notification/showNotification';
import LcManage from './LcManage';
import LcManageHelpers from './LcManageHelpers';
import LocalStorage from 'helpers/LocalStorage';
import Utils from 'helpers/Utils';
import Messages from 'constants/rpcTypes';
import { Filters, RowItem } from './LcManageTypes';
import { StoreProps } from 'store';

type Props = StoreProps;

interface State {
  list: RowItem[];
  filteredList: RowItem[];
  filters: Filters;
  ticketsPreview: any[];
  isFetched: boolean;
  isLoading: boolean;
}

@checkAccess([Messages.Localize_LexemesList])
class LcManageContainer extends Component<Props, State> {
  private changeTimerId;
  private initialFilters = {
    byTask: '',
    byToken: '',
    byText: '',
    byStatus: [
      { id: 'draft', isSelected: false, text: 'Draft' },
      { id: 'testing', isSelected: false, text: 'Testing' },
      { id: 'released', isSelected: false, text: 'Released' },
    ],
    withoutText: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      list: [],
      filteredList: [],
      filters: this.initialFilters,
      ticketsPreview: [],
      isFetched: false,
      isLoading: true,
    };

    this.changeTimerId = null;
  }

  async componentDidMount() {
    await Promise.all([this.fetchList(), this.fetchTicketsPreview()]);
  }

  componentDidUpdate(_, prevState: Readonly<State>) {
    if (prevState.list.length !== this.state.list.length) {
      this.setState({
        filteredList: this.getFilteredList(),
      });
    }
  }

  render() {
    const { filteredList, filters, ticketsPreview, isFetched, isLoading } =
      this.state;

    return (
      <LcManage
        list={filteredList}
        filters={filters}
        ticketsPreview={ticketsPreview}
        isFetched={isFetched}
        isLoading={isLoading}
        onChangeFilter={this.changeFilter}
        onResetFilters={this.resetFilters}
        onCreateSuccess={this.onCreateCallback}
        onEdit={this.startEdit}
        onRemove={this.startRemove}
        onChangeTicketsPreview={this.changeTicketsPreview}
        onSync={this.sync}
      />
    );
  }

  getFilteredList(): RowItem[] {
    const { list, filters } = this.state;
    const filterByTask = (filters.byTask || '').toLowerCase().trim();
    const filterByToken = (filters.byToken || '').toLowerCase().trim();
    const filterByText = (filters.byText || '').toLowerCase().trim();
    const filterWithoutText = filters.withoutText;
    const filtersByStatus = filters.byStatus
      .filter((item) => item.isSelected)
      .map((item) => item.id);
    const fullFilled = 2;

    // eslint-disable-next-line complexity
    return list.filter((item: RowItem) => {
      const ticketNumber = (item.ticket_number || '').toLowerCase();
      if (filterByTask && !ticketNumber.includes(filterByTask)) {
        return false;
      }

      const token = (item.token || '').toLowerCase();
      if (filterByToken && !token.includes(filterByToken)) {
        return false;
      }

      if (filtersByStatus.length > 0) {
        let isFound = false;
        for (let i = 0; i < item.variants.length; i++) {
          const variant = item.variants[i];
          if (filtersByStatus.includes(variant.status)) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          return false;
        }
      }

      if (filterByText || filterWithoutText) {
        for (let i = 0; i < item.variants.length; i++) {
          const variant = item.variants[i];
          if (Array.isArray(variant.texts)) {
            if (
              filterWithoutText &&
              variant.texts.length < fullFilled &&
              item.variants.length < fullFilled
            ) {
              return true;
            }

            for (let j = 0; i < variant.texts.length; i++) {
              const checkResult = this.checkIncludesText(
                variant.texts[j],
                filterByText,
                filterWithoutText
              );
              // eslint-disable-next-line max-depth
              if (checkResult) {
                return checkResult;
              }
            }
          }
        }
        return false;
      }

      return true;
    });
  }

  checkIncludesText(data, filterByText: string, filterWithoutText: boolean) {
    if (data) {
      if (filterByText && data.text) {
        const text = data.text.toLowerCase();
        if (filterByText && text.includes(filterByText)) {
          return true;
        }
      }
      if (filterWithoutText && !data.text) {
        return true;
      }
    }
  }

  async fetchList() {
    this.setState({ isLoading: true });

    try {
      const list = await api.getList();
      this.setState({
        list: list.map((item) => LcManageHelpers.createItem(item)),
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false, isFetched: true });
    }
  }

  onCreateCallback = (data) => {
    const { list } = this.state;
    const newList = [...list];
    newList.unshift(data);
    this.setState({ list: newList });
  };

  startEdit = (data: RowItem) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'EditLocale',
        content: { data },
        callback: ({ data: cData }) => {
          if (!cData) {
            return;
          }
          const newList = this.state.list.map((item: RowItem) => {
            return item.customId === cData.customId ? cData : item;
          });
          this.setState(
            {
              list: Utils.uniqBy(newList, 'token'),
            },
            this.setFilteredList
          );
        },
      })
    );
  };

  startRemove = (data: RowItem) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'Confirm',
        callback: (isAccept) => isAccept && this.remove(data),
      })
    );
  };

  remove = (data: RowItem) => {
    const { list } = this.state;
    const template = LcManageHelpers.getTemplate(data);
    if (template && template.id) {
      this.changeLoader();
      api
        .remove(template.id)
        .then(() => {
          const newList = list.filter((row: RowItem) => {
            return row.customId !== data.customId;
          });
          this.setState({ list: newList });
        })
        .finally(() => {
          this.changeLoader(false);
        });
    }
  };

  changeLoader = (isLoading = true) => {
    this.setState({ isLoading });
  };

  changeFilter = (field: string, value) => {
    const { filters } = this.state;
    filters[field] = value;
    this.setState((state) => {
      return {
        ...state,
        filters,
      };
    });

    clearTimeout(this.changeTimerId);
    this.changeTimerId = setTimeout(this.setFilteredList, 200);
  };

  setFilteredList = () => {
    this.setState({
      filteredList: this.getFilteredList(),
    });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: this.initialFilters,
      },
      () =>
        this.setState({
          filteredList: this.getFilteredList(),
        })
    );
  };

  sync = () => {
    api.sync().then(() => {
      showNotification({
        status: 'success',
        content: 'Sync successfully!',
      });
    });
  };

  async fetchTicketsPreview() {
    try {
      const { ticketNumbers } = await api.ticketList();
      const selectedTickets = LocalStorage.get('lcTicketsPreview') || [];
      this.setState({
        ticketsPreview: ticketNumbers.map((ticket) => {
          return {
            id: ticket,
            text: ticket,
            isSelected: selectedTickets.includes(ticket),
          };
        }),
      });
    } catch (error) {
      console.error(error);
    }
  }

  changeTicketsPreview = (ticketsPreview: any[]) => {
    this.setState({
      ticketsPreview,
    });
    LocalStorage.set(
      'lcTicketsPreview',
      ticketsPreview
        .filter((ticket) => ticket.isSelected)
        .map((ticket) => ticket.id)
    );
  };
}

export default connect()(LcManageContainer);
