import LabelStatus from 'components/ui/labelStatus';
import './mafFormDemonstration.scss';
import { statusThemes } from 'constants/statusList';

export const MafFormDemonstration = () => (
  <div className='maf-form-demonstration__wrapper'>
    <table className='maf-form-demonstration'>
      <thead>
        <tr>
          <th>ACTION</th>
          <th>NAME</th>
          <th>STATUS</th>
          <th>CREATED</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 4 }).map((_, i) => (
          <tr key={i}>
            <td>
              <div className='maf-form-demonstration__link'>View</div>
            </td>
            <td>
              <div className='maf-form-demonstration__skeleton'></div>
            </td>
            <td>
              <LabelStatus status={statusThemes.yellow} text='in&nbsp;review' />
            </td>
            <td>
              <div className='maf-form-demonstration__skeleton'>&nbsp;</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
