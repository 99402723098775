import React, { PureComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import Loader from 'components/ui/loader';
import statuses from './statuses';
import InfoIcon from 'components/ui/infoIcon';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import './button.scss';

interface Props {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  text?: string | JSX.Element;
  status?: keyof typeof statuses;
  icon?: string;
  iconSize?: number;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  disabled?: boolean;
  customClass?: any;
  type?: 'button' | 'submit' | 'reset';
  size?: 'x-large' | 'large' | 'normal' | 'small' | 'inline' | number;
  tooltip?: string;
  infoTooltip?: string;
  tooltipDelay?: number;
  tooltipId?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

export class Button extends PureComponent<Props> {
  static defaultProps = {
    type: 'button',
    size: 'normal',
    iconPosition: 'right',
  };

  render() {
    const {
      id,
      text,
      status,
      disabled,
      loading,
      icon,
      iconSize,
      iconPosition,
      customClass,
      type,
      size,
      tooltip,
      tooltipId,
      infoTooltip,
      tooltipDelay,
      buttonRef,
    } = this.props;

    const classes = classNames('ui-button', customClass, {
      [`ui-button_status-${status}`]: !!status,
      [`ui-button_size-${size}`]: !!size,
      'ui-button_disabled': disabled && !loading,
      'ui-button_loading': loading,
      'ui-button_only-icon': !text && icon,
      [`ui-button_${iconPosition}-icon`]: !!iconPosition,
    });

    return (
      <ConditionalWrapper
        condition={disabled && tooltip}
        wrap={(children) => (
          <div
            data-tip={tooltip}
            className='ui-button-wrapper'
            data-for={tooltipId}>
            {children}
          </div>
        )}>
        {
          // eslint-disable-next-line react/button-has-type
          <button
            ref={buttonRef}
            id={id}
            type={type}
            className={classes}
            onClick={this.handleClick}
            data-tip={tooltip}
            data-for={tooltipId}
            data-delay-show={tooltipDelay}
            disabled={disabled}>
            {loading && (
              <Loader customClass='ui-button__loader ui-loader_button' />
            )}
            {text && (
              <span className='ui-button__text'>
                {text}
                {infoTooltip && (
                  <InfoIcon
                    tooltip={infoTooltip}
                    size={9}
                    customClass='ui-button__info'
                    dataPlace='top'
                  />
                )}
              </span>
            )}
            {icon && (
              <Icon
                name={icon}
                size={iconSize || 16}
                className={classNames('ui-button__icon', { rotating: loading })}
              />
            )}
          </button>
        }
      </ConditionalWrapper>
    );
  }

  handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { disabled, loading, onClick } = this.props;
    if (disabled || loading) return false;
    if (onClick) {
      onClick(event);
    }
  };
}

export default Button;
