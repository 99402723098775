import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';

export const toggleSelectedUser = (user: AdminUserFields) => {
  return createAction({
    type: ACTIONS.TOGGLE_USER_TO_EDIT_SELECTION,
    payload: { user },
  });
};

export const toggleAllSelectedUsers = (users: AdminUserFields[]) => {
  return createAction({
    type: ACTIONS.TOGGLE_ALL_USERS_TO_EDIT_SELECTION,
    payload: { users },
  });
};

export const resetSelectedUsers = () => {
  return createAction({
    type: ACTIONS.RESET_USERS_TO_EDIT_SELECTION,
  });
};
