import {
  IDeclineReasonsFormValues,
  IFormDescriptorItem,
} from 'types/Analytics';
import { isOperationDmsEnabled } from 'components/modal/modalList/graphDetails/components/settingsForm/utils';

const operationTypeOptions = [
  {
    id: 'payout',
    text: 'Payout',
  },
  {
    id: 'refund',
    text: 'Refund',
  },
  {
    id: 'recurring',
    text: 'Recurring',
  },
  {
    id: 'capture',
    text: 'Capture',
  },
  {
    id: 'sale',
    text: 'Sale',
  },
  {
    id: 'auth',
    text: 'Auth',
  },
  {
    id: 'cancel',
    text: 'Cancel',
  },
  {
    id: 'account verification',
    text: 'Account verification',
  },
];

const DeclineReasonsParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.tableTitle.label',
    placeholder: 'analytics.editForm.params.tableTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.tableData.label',
    options: [
      {
        value: 'count',
        label: 'analytics.editForm.params.chartData.number.item',
      },
      {
        value: 'amount',
        label: 'analytics.editForm.params.chartData.channelAmount.item',
      },
      {
        value: 'sum_channel_amount_in_eur',
        label: 'analytics.editForm.params.chartData.channelAmountEUR.item',
      },
      {
        value: 'sum_channel_amount_in_usd',
        label: 'analytics.editForm.params.chartData.channelAmountUSD.item',
      },
      {
        value: 'avg_channel_amount',
        label: 'analytics.editForm.params.chartData.avgChannelAmount.item',
      },
      {
        value: 'avg_channel_amount_in_eur',
        label: 'analytics.editForm.params.chartData.avgChannelAmountEUR.item',
      },
      {
        value: 'avg_channel_amount_in_usd',
        label: 'analytics.editForm.params.chartData.avgChannelAmountUSD.item',
      },
      {
        value: 'unique_customers',
        label: 'analytics.editForm.params.chartData.uniqueCustomers.item',
      },
    ],
  },
  {
    id: 'groupByRows',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupByRows.label',
    options: [],
    optionsTranslate: true,
    onChangeCallback: (values) => {
      if (
        'groupByRows' in values &&
        values.groupByRows.includes('operationTypeDms') === true
      ) {
        const { operationType, ...rest } = values as IDeclineReasonsFormValues;

        return { ...rest };
      }

      if (
        'groupByRows' in values &&
        values.groupByRows.includes('operationType') === true
      ) {
        const { operationTypeDms, ...rest } =
          values as IDeclineReasonsFormValues;

        return { ...rest };
      }

      return values;
    },
    conditionalOptions: [
      {
        data: [
          {
            id: 'projectId',
            text: 'analytics.editForm.params.groupBy.projectID.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('projectId') === false,
      },
      {
        data: [
          {
            id: 'operationType',
            text: 'analytics.editForm.params.groupBy.operationType.item',
            order: 2,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('operationType') === false &&
          values.groupByColumns.includes('operationTypeDms') === false &&
          values.groupByRows.includes('operationTypeDms') === false,
      },
      {
        data: [
          {
            id: 'operationTypeDms',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            text: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
            order: 3,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('operationTypeDms') === false &&
          values.groupByColumns.includes('operationType') === false &&
          values.groupByRows.includes('operationType') === false,
        permissionCallback: isOperationDmsEnabled,
      },
      {
        data: [
          {
            id: 'operationStatus',
            text: 'analytics.editForm.params.groupBy.operationStatus.item',
            order: 4,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('operationStatus') === false,
      },
      {
        data: [
          {
            id: 'issuerCountry',
            text: 'analytics.editForm.params.groupBy.countryByBIN.item',
            order: 5,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('issuerCountry') === false,
      },
      {
        data: [
          {
            id: 'country',
            text: 'analytics.editForm.params.groupBy.countryByIP.item',
            order: 6,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('country') === false,
      },
      {
        data: [
          {
            id: 'paymentMethod',
            text: 'analytics.editForm.params.groupBy.paymentMethodType.item',
            order: 7,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('paymentMethod') === false,
      },
      {
        data: [
          {
            id: 'currency',
            text: 'analytics.editForm.params.groupBy.channelCurrency.item',
            order: 8,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('currency') === false &&
          ['channel_amount_in_eur', 'channel_amount_in_usd'].includes(
            values.chartData
          ) === false,
      },
      {
        data: [
          {
            id: 'date',
            text: 'analytics.editForm.params.groupBy.operationCompletedAt.item',
            order: 9,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('date') === false,
      },
      {
        data: [
          {
            id: 'declineReasons',
            text: 'analytics.editForm.params.groupBy.operationResultMessages.item',
            order: 10,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('declineReasons') === false,
      },
    ],
  },
  {
    id: 'groupByColumns',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupByColumns.label',
    options: [],
    optionsTranslate: true,
    onChangeCallback: (values) => {
      if (
        'groupByColumns' in values &&
        values.groupByColumns.includes('operationTypeDms') === true
      ) {
        const { operationType, ...rest } = values as IDeclineReasonsFormValues;

        return { ...rest };
      }

      if (
        'groupByColumns' in values &&
        values.groupByColumns.includes('operationType') === true
      ) {
        const { operationTypeDms, ...rest } =
          values as IDeclineReasonsFormValues;

        return { ...rest };
      }

      return values;
    },
    conditionalOptions: [
      {
        data: [
          {
            id: 'projectId',
            text: 'analytics.editForm.params.groupBy.projectID.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('projectId') === false,
      },
      {
        data: [
          {
            id: 'operationType',
            text: 'analytics.editForm.params.groupBy.operationType.item',
            order: 2,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('operationType') === false &&
          values.groupByColumns.includes('operationTypeDms') === false &&
          values.groupByRows.includes('operationTypeDms') === false,
      },
      {
        data: [
          {
            id: 'operationTypeDms',
            text: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
            order: 3,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('operationTypeDms') === false &&
          values.groupByColumns.includes('operationType') === false &&
          values.groupByRows.includes('operationType') === false,
        permissionCallback: isOperationDmsEnabled,
      },
      {
        data: [
          {
            id: 'operationStatus',
            text: 'analytics.editForm.params.groupBy.operationStatus.item',
            order: 4,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('operationStatus') === false,
      },
      {
        data: [
          {
            id: 'issuerCountry',
            text: 'analytics.editForm.params.groupBy.countryByBIN.item',
            order: 5,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('issuerCountry') === false,
      },
      {
        data: [
          {
            id: 'country',
            text: 'analytics.editForm.params.groupBy.countryByIP.item',
            order: 6,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('country') === false,
      },
      {
        data: [
          {
            id: 'paymentMethod',
            text: 'analytics.editForm.params.groupBy.paymentMethodType.item',
            order: 7,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('paymentMethod') === false,
      },
      {
        data: [
          {
            id: 'currency',
            text: 'analytics.editForm.params.groupBy.channelCurrency.item',
            order: 8,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('currency') === false &&
          ['channel_amount_in_eur', 'channel_amount_in_usd'].includes(
            values.chartData
          ) === false,
      },
      {
        data: [
          {
            id: 'date',
            text: 'analytics.editForm.params.groupBy.operationCompletedAt.item',
            order: 9,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('date') === false,
      },
      {
        data: [
          {
            id: 'declineReasons',
            text: 'analytics.editForm.params.groupBy.operationResultMessages.item',
            order: 10,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('declineReasons') === false,
      },
    ],
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
      },
    ],
    renderOptions: {
      hidden: {
        condition: (values) =>
          'groupByRows' in values &&
          'groupByColumns' in values &&
          [...values.groupByRows, ...values.groupByColumns].includes('date') ===
            false,
      },
    },
  },
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
];

const DeclineReasonsFilters: IFormDescriptorItem[] = [
  {
    id: 'projectId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'operationType',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.operationType.label',
    options: operationTypeOptions,
    renderOptions: {
      hidden: {
        condition: (values) =>
          'groupByRows' in values &&
          'groupByColumns' in values &&
          (values.groupByRows.includes('operationTypeDms') === true ||
            values.groupByColumns.includes('operationTypeDms') === true),
      },
    },
  },
  {
    id: 'operationTypeDms',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
    renderOptions: {
      hidden: {
        // eslint-disable-next-line sonarjs/no-identical-functions
        condition: (values) =>
          'groupByRows' in values &&
          'groupByColumns' in values &&
          values.groupByRows.includes('operationTypeDms') === false &&
          values.groupByColumns.includes('operationTypeDms') === false,
      },
    },
    options: operationTypeOptions,
  },
  {
    id: 'operationStatus',
    type: 'selectGroup',
    label: 'analytics.editForm.filters.operationStatus.label',
    items: [
      {
        value: 'success',
        label: 'Success',
      },
      {
        value: 'decline',
        label: 'Decline',
      },
    ],
  },
  {
    id: 'countryByBin',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
  {
    id: 'countryByIp',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByIP.label',
    dictionaryKey: 'country',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'currency',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.currency.label',
    dictionaryKey: 'currency',
    tooltip: 'select.currency.favorite.tooltip',
  },
];

export { DeclineReasonsParams, DeclineReasonsFilters };
