import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Select, { StylesConfig } from 'react-select';
import Utils from 'helpers/Utils';
import SelectItem from 'types/SelectItem';
import { useTranslation } from 'hooks';
import InfoIcon from 'components/ui/infoIcon';
import {
  ControlComponent,
  DropdownIndicatorComponent,
  LoadingIndicatorComponent,
  OptionComponent,
  MultiValueLabelComponent,
  MultiValueRemoveComponent,
  MenuListComponent,
  MenuPortalComponent,
} from './selectInnerComponents';
import './customSelect.scss';
import './customSelectModern.scss';
import './customSelectLight.scss';

export interface Value {
  value: any;
  label: any;
}

interface Props {
  id?: string;
  value?: any; //Value | Value[] | ReactNode | null;
  defaultMenuIsOpen?: boolean;
  options: SelectItem[];
  customClass?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  error?: string;
  tooltip?: string;
  isRow?: boolean;
  noOptionsMessage?: string;
  deselect?: boolean;
  autoScrollToSelectedOption?: boolean;
  isLoadMore?: boolean;
  backspaceRemovesValue?: boolean;
  modern?: boolean;
  theme?: 'light';
  containerSelector?: string;
  tooltipId?: string;
  tooltipPosition?: string;
  onChange: (data: any) => void;
  onInputChange?: (inputValue: string) => void;
  loadMore?: () => void;
  onBlur?: () => void;
  onOpen?: () => void;
  filterOption?: (option: Value, value: string) => boolean;
}

const CustomSelect: React.FC<Props> = ({
  placeholder,
  label,
  options,
  error,
  modern,
  theme,
  customClass,
  isLoading,
  isRequired,
  isRow,
  onOpen,
  onChange,
  onInputChange,
  onBlur,
  id = Utils.getHash(),
  value = null,
  defaultMenuIsOpen = false,
  deselect = false,
  isSearchable = false,
  isDisabled = false,
  readOnly = false,
  tooltip = '',
  noOptionsMessage = '',
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const getTranslate = useTranslation();

  const handleOpen = () => {
    if (readOnly) {
      return;
    }

    setOpen(true);

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue: Value) => {
    if (deselect && value?.value === newValue.value) {
      onChange({
        label: null,
        value: null,
      });
      return;
    }

    onChange(newValue);
  };

  const handleEvent = useCallback((e: Event) => {
    const { id } = e.target as HTMLElement;

    if (id !== 'menu-list') {
      handleClose();
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleEvent);
    window.addEventListener('scroll', handleEvent);

    return () => {
      window.removeEventListener('resize', handleEvent);
      window.removeEventListener('scroll', handleEvent);
    };
  }, [handleEvent]);

  const classes = classNames('ui-select', {
    'ui-select_opened': isOpen,
    'ui-select_selected': value?.value,
    'ui-select_disabled': isDisabled,
    'ui-select_loading': isLoading,
    'ui-select_error': !!error,
    'ui-select_row': !!isRow,
    'ui-select_modern': !!modern,
    [`ui-select_${theme}`]: !!theme,
    [`${customClass}`]: !!customClass,
  });

  const customProps = {
    id,
    mode: theme,
    modern,
    tooltip: getTranslate(tooltip),
    customClass,
    ...rest,
  };

  const customStyles: StylesConfig = {
    option: () => ({}),
    control: () => ({}),
    dropdownIndicator: () => ({}),
    indicatorSeparator: () => ({}),
    singleValue: () => ({}),
    input: () => ({}),
    placeholder: () => ({}),
    multiValue: () => ({}),
    multiValueLabel: () => ({}),
    multiValueRemove: () => ({}),
    valueContainer: (base) => ({
      ...base,
      input: {
        height: 0,
      },
    }),
    loadingIndicator: () => ({}),
    menuList: () => ({}),
    menu: (_, state) => {
      const position = modern ? 5 : 0;

      if (state.placement === 'bottom') {
        return {
          top: position,
        };
      }

      return {
        bottom: position,
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div id={id} className={classes}>
      <div
        className={classNames('ui-select__wrapper', {
          'ui-select__wrapper_modern': modern,
        })}>
        {label ? (
          <label
            htmlFor={id}
            className={classNames('ui-select__label', {
              'ui-select__label_tooltip': modern && tooltip,
            })}>
            {getTranslate(label)}
            {isRequired && (
              <span
                className='ui-select__required'
                data-tip={getTranslate('common.requiredField.tooltip')}>
                *
              </span>
            )}
            {tooltip && !modern && (
              <InfoIcon
                icon='im-Info'
                tooltip={tooltip}
                dataPlace='top'
                customClass='ui-select__info'
              />
            )}
          </label>
        ) : null}

        <div className='ui-select__inner'>
          <Select
            {...customProps}
            menuIsOpen={defaultMenuIsOpen || isOpen}
            className='ui-select__component'
            classNamePrefix='ui-select'
            styles={customStyles}
            components={{
              Control: ControlComponent,
              DropdownIndicator: DropdownIndicatorComponent,
              MenuList: MenuListComponent,
              MenuPortal: MenuPortalComponent,
              Option: OptionComponent,
              MultiValueLabel: MultiValueLabelComponent,
              MultiValueRemove: MultiValueRemoveComponent,
              LoadingIndicator: LoadingIndicatorComponent,
              ClearIndicator: () => null,
            }}
            placeholder={
              placeholder
                ? getTranslate(placeholder)
                : getTranslate('common.selectSingle.placeholder')
            }
            noOptionsMessage={() => getTranslate(noOptionsMessage)}
            options={options}
            isDisabled={isOpen ? false : isDisabled}
            isSearchable={isSearchable}
            isClearable={isSearchable}
            isLoading={isLoading}
            value={value}
            menuPlacement='auto'
            menuPosition='fixed'
            menuPortalTarget={document.body}
            menuShouldScrollIntoView
            maxMenuHeight={165}
            closeMenuOnScroll={handleEvent}
            onInputChange={onInputChange}
            onChange={handleChange}
            onMenuOpen={handleOpen}
            onMenuClose={handleClose}
            onBlur={onBlur}
          />

          {isRow && error ? (
            <div className='ui-select__error'>{error}</div>
          ) : null}
        </div>
      </div>
      {!isRow && error ? <div className='ui-select__error'>{error}</div> : null}
    </div>
  );
};

export default CustomSelect;
