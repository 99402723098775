import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import Env from 'helpers/Env';
import './aps-icon.scss';

interface Props {
  iconUrl: string;
  paymentType?: string;
  showTooltip?: boolean;
  customClass?: any;
}

class ApsIcon extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.showTooltip && this.rebuildTooltip();
  }

  render() {
    const { iconUrl, paymentType, showTooltip, customClass } = this.props;
    if (!paymentType && !iconUrl) return '—';

    const classes = classNames('aps-icon', {
      [customClass]: !!customClass,
      'aps-icon_no-img': !iconUrl,
    });

    if (paymentType && showTooltip) {
      return (
        <div
          data-tip={iconUrl ? paymentType : ''}
          data-place='right'
          className={classes}>
          {this.getContent()}
        </div>
      );
    }
    return (
      <div className={classes}>
        <div className='aps-icon__inner'>{this.getContent()}</div>
      </div>
    );
  }

  getContent = () => {
    const { iconUrl, paymentType } = this.props;
    if (iconUrl) {
      return <img src={Env.getIconHost() + iconUrl} alt={paymentType} />;
    }

    return <span className='aps-icon__text'>{paymentType || ''}</span>;
  };

  private rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true,
  });
}

export default ApsIcon;
