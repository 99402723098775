import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { WithRouterProps } from 'decorators/withRouter';
import Link from 'components/ui/link';
import { getArticleBySlug } from 'pages/help/article/utils';
import HelpArticle, { ArticleSubTitlesType } from 'pages/help/types';
import { RootState } from 'store';

interface ConnectedProps {
  articles: HelpArticle[];
}

interface OwnProps {
  sm?: boolean;
}

type Props = ConnectedProps & OwnProps & WithRouterProps<{ slug?: string }>;

class AnchorList extends PureComponent<Props> {
  render() {
    const { articles, location, match, sm = false } = this.props;
    const article = getArticleBySlug(articles, match.params.slug);
    if (!article?.subTitles?.length) return null;

    return (
      <div
        className={classNames('help-menu__submenu', {
          'help-menu__submenu_sm': sm,
        })}>
        {article.subTitles.map((item: ArticleSubTitlesType, index: number) => {
          return (
            <div
              key={index}
              className={classNames('help-menu__submenu-item', {
                'help-menu__submenu-item_active':
                  item.id && item.id === location.hash.slice(1),
              })}>
              <Link className='help-menu__submenu-link' url={`#${item.id}`}>
                {item.text}
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  articles: state.help.articles,
});

export default withRouter(connect(mapStateToProps)(AnchorList));
