import { isEqual } from 'lodash-es';
import BaseMessageHandler from '../BaseMessageHandler';
import { updateUser } from 'actions/user';
import fetchUpdateDictionary from 'helpers/dictionaries';
import Repository from 'helpers/Repository';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';

export default class Profile_Update extends BaseMessageHandler {
  success = async () => {
    const { dispatch, message } = this;

    if (isEqual(Repository.get('store').getState().user, message.payload))
      return;

    dispatch(
      updateUser({
        ...message.payload,
        name: getStringWithSpecialSymbols(message.payload.name),
      })
    );

    await fetchUpdateDictionary.call({ props: { dispatch } }, 'currency');
    await fetchUpdateDictionary.call(
      { props: { dispatch } },
      'balanceCurrency'
    );
  };
}
