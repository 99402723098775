import { setAppTheme } from 'helpers/themeCustomization';
import BaseMessageHandler from '../BaseMessageHandler';
import { wl } from 'actions/settings';

export default class GET_WL extends BaseMessageHandler {
  default = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    setAppTheme(payload?.themeConfig);
    dispatch(wl(payload));
  };
}
