import RoundButton from 'components/ui/roundButton';

export const DeleteButton: React.FC<{
  onClick: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled }) => (
  <RoundButton
    disabled={disabled}
    size={30}
    iconSize={14}
    onClick={onClick}
    icon='im-Basket'
    status='danger'
  />
);

export const AddButton: React.FC<{
  onClick?: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled }) => (
  <RoundButton
    disabled={disabled}
    size={30}
    iconSize={14}
    onClick={onClick}
    icon='im-Plus'
    status='primary'
  />
);
