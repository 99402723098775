import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { RootState } from 'store';
import './onboardingInformer.scss';

export const OnboardingInformer = () => {
  const getTranslate = useTranslation();
  const email = useSelector(
    (state: RootState) =>
      state.user?.activeLegalEntities?.[0]?.sales_email ||
      state.settings.salesEmail
  );

  return (
    <div className='onboarding-informer'>
      <div className='onboarding-informer__title'>
        {getTranslate('getStarted.onboardingInformer.title')}
      </div>
      <div className='onboarding-informer__description'>
        {getTranslate('getStarted.onboardingInformer.description', {
          email: <a href={`mailto:${email}`}>{email}</a>,
        })}
      </div>
    </div>
  );
};
