import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import CustomSelect from '../customSelect';
import { AddButton, DeleteButton } from './components';
import './multipleSelectList.scss';

export type Option = {
  label: string;
  value: string;
};

export const MultipleSelectionList: React.FC<{
  options: Option[];
  onChange: (selected: Option[]) => void;
  disabled?: boolean;
  addSelectorButtonLabel?: string;
}> = ({ options, onChange, disabled, addSelectorButtonLabel }) => {
  const getTranslate = useTranslation();
  const [selects, setSelects] = useState<(string | undefined)[]>([undefined]);

  useEffect(() => {
    onChange(
      selects
        .map((select) => options.find((option) => option.value === select)!)
        .filter((option) => option !== undefined)
    );
  }, [selects]);

  return (
    <div className='multiple-select-list'>
      <div className='multiple-select-list__list-element'>
        <CustomSelect
          modern
          isDisabled={disabled}
          options={options.filter(
            (option) =>
              !selects.includes(option.value) || option.value === selects[0]
          )}
          onChange={({ value }) => {
            setSelects((selects) => {
              const newSelects = [...selects];
              newSelects[0] = value;
              return newSelects;
            });
          }}
        />
      </div>
      <div>
        {selects.slice(1).map((select, index) => (
          <div
            key={index}
            className='multiple-select-list__list-element_delitable'>
            <CustomSelect
              customClass='multiple-select-list__select_delitable'
              modern
              isDisabled={disabled}
              options={options.filter(
                (option) =>
                  !selects.includes(option.value) || option.value === select
              )}
              onChange={({ value }) => {
                setSelects((selects) => {
                  const newSelects = [...selects];
                  newSelects[index + 1] = value;
                  return newSelects;
                });
              }}
            />
            <DeleteButton
              disabled={disabled}
              onClick={() => {
                setSelects((selects) =>
                  selects.filter((_, i) => i !== index + 1)
                );
              }}
            />
          </div>
        ))}
      </div>
      {options.length > 1 && options.length > selects.length ? (
        <div
          onClick={() => {
            setSelects((selects) => [...selects, undefined]);
          }}>
          <AddButton disabled={disabled} />
          <div className='multiple-select-list__add-item-label'>
            {addSelectorButtonLabel ||
              getTranslate('components.multipleSelectionList.addItem')}
          </div>
        </div>
      ) : null}
    </div>
  );
};
