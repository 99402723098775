import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Env from 'helpers/Env';
import PageTemplate from 'components/pageTemplate';
import Link from 'components/ui/link';
import Icon from 'components/ui/icon';
import { DevTools } from './components/devTools';
import menuItems from 'constants/menuConfiguration';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import './adminTools.scss';

interface Props extends IntlProps, WithPermissions {}

const AdminTools: React.FC<Props> = ({ isEnabled, getTranslate }) => (
  <PageTemplate.Main>
    <PageTemplate.Container customClass='admin-tools-page'>
      <div className='admin-tools-page__label'>Dashboard Admin Tools</div>
      <div className='admin-tools-page__content'>
        <ul className='admin-tools-page__item admin-tools-page__menu'>
          {menuItems
            .filter(
              (item) =>
                item.adminTool &&
                item.permissions &&
                item.permissions.some((permission) => isEnabled(permission))
            )
            .map((item) => {
              return (
                <li className='admin-tools-page__menu-item' key={item.key}>
                  <Link
                    url={`/${item.customUrl || item.key}`}
                    target='_blank'
                    className='admin-tools-page__link'>
                    <Icon
                      name={item.icon}
                      size={42}
                      className='admin-tools-page__menu-icon'
                    />
                    {getTranslate(`adminTools.${item.key}.button`)}
                  </Link>
                </li>
              );
            })}
        </ul>
        {Env.isDevelopment() && (
          <div className='admin-tools-page__item'>
            <DevTools />
          </div>
        )}
      </div>
    </PageTemplate.Container>
  </PageTemplate.Main>
);

export default wrapAppWithCssClass('layout-app_tablet-width')(
  addTranslation(addPermissions(AdminTools))
);
