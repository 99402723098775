import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './loader.scss';

interface Props {
  type?: 'bung';
  isInternal?: boolean;
  customClass?: string;
  text?: string;
}

class Loader extends PureComponent<Props> {
  render() {
    const { type, text, isInternal, customClass = '' } = this.props;
    const isBungType = type === 'bung';

    return (
      <div
        className={classNames(
          'ui-loader',
          {
            'ui-loader_bung': isBungType,
            'ui-loader_internal': isInternal,
            'ui-loader_whole-screen': !isInternal,
          },
          customClass
        )}>
        {!isBungType && (
          <svg
            width='40px'
            height='40px'
            viewBox='0 0 40 40'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'>
            <g id='Page-1' stroke='none' fill='none'>
              <g id='Artboard-Copy' fill='#818496'>
                <g id='Preloader_2'>
                  <path
                    className='ui-loader__1'
                    d='M17.7794515,6 C17.7794515,7.28888889 17.7794515,8.57777778 17.7794515,9.82222222 C17.7794515,11.0222222 18.6238959,11.8666667 19.735007,11.8666667 C20.8461181,11.8666667 21.6905626,11.0666667 21.6905626,9.82222222 C21.6905626,7.24444444 21.6905626,4.62222222 21.6905626,2.04444444 C21.6905626,0.844444444 20.8461181,-1.77635684e-15 19.735007,-1.77635684e-15 C18.5794515,-1.77635684e-15 17.7794515,0.8 17.7794515,2 C17.735007,3.37777778 17.7794515,4.71111111 17.7794515,6 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__2'
                    d='M31.1620723,11.1732354 C32.0509612,10.2843465 32.9842946,9.35101314 33.8731834,8.46212425 C34.7620723,7.52879092 34.8065168,6.37323537 33.9620723,5.57323537 C33.1620723,4.77323537 32.0065168,4.81767981 31.1176279,5.66212425 C29.2954057,7.43990203 27.4731834,9.26212425 25.6954057,11.0843465 C24.8065168,12.0176798 24.7620723,13.1732354 25.6065168,13.9732354 C26.4509612,14.7732354 27.5620723,14.7287909 28.4954057,13.7954576 C29.3842946,12.9510131 30.2731834,12.0621243 31.1620723,11.1732354 Z'
                    id='Path-Copy'
                    transform='translate(29.784295, 9.768509) scale(-1, -1) translate(-29.784295, -9.768509)'
                  />
                  <path
                    className='ui-loader__3'
                    d='M33.6016737,17.8666667 C32.3127848,17.8666667 31.0238959,17.8666667 29.7794515,17.8666667 C28.4905626,17.8666667 27.6461181,18.7111111 27.6905626,19.8666667 C27.735007,20.9777778 28.535007,21.7777778 29.7794515,21.7777778 C32.3572292,21.7777778 34.8905626,21.7777778 37.4683403,21.7777778 C38.7572292,21.7777778 39.6016737,20.9333333 39.5572292,19.7777778 C39.5127848,18.6666667 38.7127848,17.8666667 37.4683403,17.8666667 C36.135007,17.8222222 34.8461181,17.8666667 33.6016737,17.8666667 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__4'
                    d='M30.8016737,28.2222222 C29.9127848,27.3333333 28.9794515,26.4 28.0905626,25.5111111 C27.1572292,24.6222222 26.0016737,24.6222222 25.2016737,25.4666667 C24.4016737,26.2666667 24.4461181,27.4222222 25.335007,28.3111111 C27.1127848,30.1333333 28.935007,31.9555556 30.7572292,33.7333333 C31.6905626,34.6222222 32.8461181,34.6222222 33.6461181,33.7777778 C34.4016737,32.9333333 34.4016737,31.8222222 33.5127848,30.9333333 C32.6238959,30 31.735007,29.1111111 30.8016737,28.2222222 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__5'
                    d='M21.6905626,33.6888889 C21.6905626,32.4 21.6905626,31.1111111 21.6905626,29.8666667 C21.6905626,28.5777778 20.8461181,27.7333333 19.6905626,27.7777778 C18.5794515,27.8222222 17.7794515,28.6222222 17.7794515,29.8666667 C17.7794515,32.4444444 17.7794515,34.9777778 17.7794515,37.5555556 C17.7794515,38.8 18.5794515,39.6 19.6905626,39.6444444 C20.8905626,39.6888889 21.6905626,38.8444444 21.735007,37.5555556 C21.735007,36.2222222 21.6905626,34.9333333 21.6905626,33.6888889 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__6'
                    d='M11.335007,31.0222222 C12.2238959,30.1333333 13.1572292,29.2 14.0461181,28.3111111 C14.935007,27.3777778 14.9794515,26.2222222 14.135007,25.4222222 C13.335007,24.6222222 12.1794515,24.6666667 11.2905626,25.5111111 C9.46834034,27.2888889 7.64611812,29.1111111 5.86834034,30.9333333 C4.97945145,31.8666667 4.93500701,33.0222222 5.77945145,33.8222222 C6.6238959,34.6222222 7.73500701,34.5777778 8.66834034,33.6444444 C9.55722923,32.8 10.4461181,31.9111111 11.335007,31.0222222 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__7'
                    d='M5.86834034,21.7777778 C7.15722923,21.7777778 8.44611812,21.7777778 9.69056257,21.7777778 C10.9794515,21.7777778 11.8238959,20.9333333 11.8238959,19.7777778 C11.8238959,18.6666667 10.9794515,17.8666667 9.77945145,17.8222222 C7.20167368,17.8222222 4.66834034,17.8222222 2.09056257,17.8222222 C0.801673677,17.8222222 -0.0427707674,18.6222222 0.00167367704,19.8222222 C0.00167367704,20.9777778 0.846118121,21.7333333 2.17945145,21.7777778 C3.37945145,21.8222222 4.6238959,21.7777778 5.86834034,21.7777778 Z'
                    id='Path'
                  />
                  <path
                    className='ui-loader__8'
                    d='M11.2905626,8.62222222 C10.4016737,7.73333333 9.46834034,6.8 8.57945145,5.91111111 C7.64611812,5.02222222 6.49056257,5.02222222 5.69056257,5.86666667 C4.89056257,6.66666667 4.93500701,7.82222222 5.8238959,8.71111111 C7.60167368,10.5333333 9.4238959,12.3555556 11.2461181,14.1333333 C12.1794515,15.0222222 13.335007,15.0222222 14.135007,14.1777778 C14.935007,13.3777778 14.8905626,12.2222222 14.0016737,11.3333333 C13.1127848,10.4444444 12.2238959,9.55555556 11.2905626,8.62222222 Z'
                    id='Path'
                  />
                </g>
              </g>
            </g>
          </svg>
        )}
        {text && <span className='ui-loader__text'>{text}</span>}
      </div>
    );
  }
}

export default Loader;
