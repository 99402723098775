import React from 'react';
import { Chart } from 'highcharts';
import { connect } from 'react-redux';
import { RootState, StoreProps } from 'store';
import ChartView from './ChartView';
import { ChartViewData } from 'types/Analytics';
import { LayoutTypes } from '../../../editLayout/EditLayout';

interface ConnectedProps {
  isMenuExpanded: boolean;
}

interface Props extends StoreProps, ConnectedProps {
  data: ChartViewData;
  withExport?: boolean;
  setRef?: (ref: Chart) => void;
  className?: string;
  layoutType?: LayoutTypes;
  shouldResize: boolean;
}

class ChartViewContainer extends React.Component<Props> {
  render() {
    const {
      data,
      setRef,
      className,
      shouldResize,
      dispatch,
      layoutType,
      isMenuExpanded,
    } = this.props;

    return (
      <ChartView
        key={'uuid' in data ? data.uuid : ''}
        setRef={setRef}
        data={data}
        className={className}
        shouldResize={shouldResize}
        dispatch={dispatch}
        layoutType={layoutType}
        isMenuExpanded={isMenuExpanded}
      />
    );
  }
}
const mapStateToProps = (state: RootState): ConnectedProps => ({
  isMenuExpanded: state.configuration.isMenuExpanded,
});

export default connect(mapStateToProps)(ChartViewContainer);
