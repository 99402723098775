export enum FilterTypes {
  text = 'text',
  amount = 'amount',
  numbers = 'numbers',
  dateTime = 'dateTime',
  select = 'select',
  multiSelect = 'multiSelect',
  checkboxList = 'checkboxList',
  radioList = 'radioList',
  buttonsList = 'buttonsList',
  multiTextNumbers = 'multiTextNumbers',
  multiText = 'multiText',
  dateButtons = 'dateButtons',
  dateSelect = 'dateSelect',
  phoneInput = 'phoneInput',
}

export enum Tabs {
  basic,
  additional,
}
