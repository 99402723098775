import './simpleList.scss';

export const SimpleList: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className='simple-list'>
      {items.map((item, index) => (
        <div className='simple-list__item' key={index}>
          {item}
        </div>
      ))}
    </div>
  );
};
