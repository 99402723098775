import { MafTab } from 'components/maf/types';
import DateHelpers from 'helpers/Date';

export const parseDateFromResponse = ({ objectName }) => {
  // objectName: ...675-20210712160837.jpeg
  const lastPiece = objectName.split('-').pop(); // 20210712160837.jpeg
  const dateString = lastPiece.split('.').shift(); // 20210712160837
  return DateHelpers.getFormat(
    DateHelpers.createDate(dateString, 'YYYYMMDDHHmmss'),
    'crmDate'
  );
};

export const getFileFieldFromTab = (tab: MafTab) => {
  if (!tab.fields) {
    return tab.tabs[0].fields.find((field) => field.type === 'file');
  }

  return tab.fields.find((field) => field.type === 'file');
};

export const getSizeFieldFromTab = (tab: MafTab) => {
  if (!tab.fields) {
    return tab.tabs[0].fields.find((field) => field.type === 'filesize');
  }

  return tab.fields.find((field) => field.type === 'filesize');
};

export const mapTabsToFiles = (tabs: MafTab[]) => {
  return new Map(
    tabs?.map((tab) => {
      const fileField = getFileFieldFromTab(tab);
      const sizeField = getSizeFieldFromTab(tab);

      return [
        tab.crm_id,
        {
          name: fileField?.lookup_display_value || 'unknown file',
          size: sizeField?.value,
        } as unknown as File,
      ];
    })
  );
};
