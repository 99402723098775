import React from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { openModal } from 'actions/modal';
import { StoreProps } from 'store';
import Button from 'components/ui/button';

interface OwnProps {
  operationId: string;
  transactionId: string;
}

type Props = OwnProps & IntlProps & StoreProps;

interface State {
  isLoading: boolean;
}

class CreateSupportTicketButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  openModal = () => {
    const { dispatch, transactionId, operationId } = this.props;

    dispatch(
      openModal({
        modalId: 'CreateSupportTicket',
        customClassName: 'create-support-ticket',
        content: {
          transactionId,
          operationId,
        },
      })
    );
  };

  render() {
    const { isLoading } = this.state;
    const { getTranslate, transactionId, operationId } = this.props;

    if (!transactionId || !operationId) {
      return null;
    }

    return (
      <Button
        loading={isLoading}
        onClick={this.openModal}
        text={getTranslate('payments.single.ticketToSupport.header')}
        status='outline'
        type='submit'
        size='normal'
      />
    );
  }
}

export default connect()(addTranslation(CreateSupportTicketButton));
