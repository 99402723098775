import { omit } from 'lodash-es';
import BaseMessageHandler from '../BaseMessageHandler';
import { updateActions } from 'actions/paymentActions';

export default class PaymentOperation_AvailableActions extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      updateActions({
        transactionId: payload.transactionId,
        data: {
          ...omit(payload, ['transactionId']),
        },
      })
    );
  };
}
