import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ButchUserEditMode, Project, UserEditingResult } from './types';
import { ButchUserEditView } from './ButchUserEditModalView';
import { closeModal } from 'actions/modal';
import { updateUserBulk } from 'api/admin';
import { openFailureModal } from './helpers/openFailureModal';
import { openSuccessModal } from './helpers/openSuccessModal';

export const ButchUserEditModalContainer: React.FC = () => {
  const dispatch = useDispatch();

  const selectedUsers = useSelector<RootState, AdminUserFields[]>(
    (state) => state.bunchUserEditing.usersToEdit
  );
  const projects =
    useSelector<RootState, Project[]>(
      (state) => state.filtersValues.project.list
    ) || [];

  const [editMode, setEditMode] = useState<ButchUserEditMode>('add');
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onCancelButchUserEdit = () => {
    dispatch(closeModal());
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = await updateUserBulk<UserEditingResult>({
        userIds: selectedUsers.map((user) => user.id),
        [editMode === 'add' ? 'projectIdsAdd' : 'projectIdsRemove']:
          selectedProjects,
      });

      payload
        ? openSuccessModal({
            dispatch,
            editMode,
            userEditingResult: payload,
          })
        : openFailureModal({ dispatch });
    } catch (e) {
      console.error(e);
      openFailureModal({ dispatch });
    }
    setIsSubmitting(false);
  };

  return (
    <ButchUserEditView
      editMode={editMode}
      isSubmitting={isSubmitting}
      onCancel={onCancelButchUserEdit}
      onModeChange={(value: ButchUserEditMode) => setEditMode(value)}
      onSelectedProjectsChange={setSelectedProjects}
      onSubmit={onSubmit}
      projects={projects}
      selectedProjects={selectedProjects}
      selectedUsers={selectedUsers}
    />
  );
};
