import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import { Column, Header, Row } from 'components/ui/table';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import TopPanelContainer from 'pages/team/components/topPanel/TopPanelContainer';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import Switch from 'components/ui/switch';
import { AnyObject } from 'types/Common';
import { minUsersTableColumnsWidth } from './constants';
import tableNames from 'constants/tableNames';
import './admin.scss';
import { minPaymentTableColumnsWidth } from 'pages/payments/constants';
import Checkbox from 'components/ui/checkbox';
import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';
import { useTranslation } from 'hooks';

type Props = {
  isLoading: boolean;
  configuration: AnyObject;
  users: AdminUserFields[];
  selectedUsers: AdminUserFields[];
  onEditUser: (id: number) => void;
  isUserSelectionAvailable: boolean;
  onDeleteUser: (id: number) => void;
  apiRequest: (data?: any) => Promise<any>;
  onToggleActivate: (data: AdminUserFields) => void;
  toggleSelectedUser: (user: AdminUserFields) => void;
  toggleAllSelectedUser: (users: AdminUserFields[]) => void;
};

export const AdminPage: React.FC<Props> = ({
  isLoading,
  apiRequest,
  onEditUser,
  configuration,
  onDeleteUser,
  users,
  selectedUsers,
  onToggleActivate,
  toggleAllSelectedUser,
  toggleSelectedUser,
  isUserSelectionAvailable,
}) => {
  const getTranslation = useTranslation();

  const headerRender = () => {
    return (
      <Header>
        {configuration.map(({ id, localId }) => {
          const columnName = getTranslation(localId);

          if (id === 'id') {
            return (
              <Column
                id={id}
                key={id}
                modifier={id}
                content={
                  <>
                    {users.length ? (
                      <Checkbox
                        id={id}
                        customClass='admin-page__id-column-checkbox'
                        checked={Boolean(selectedUsers.length === users.length)}
                        disabled={!isUserSelectionAvailable}
                        onChange={() => {
                          toggleAllSelectedUser(users);
                        }}
                      />
                    ) : null}

                    {columnName}
                  </>
                }
                minColumnsWidth={minPaymentTableColumnsWidth}
              />
            );
          }

          return (
            <Column
              id={id}
              key={id}
              modifier={id}
              content={columnName}
              minColumnsWidth={minPaymentTableColumnsWidth}
            />
          );
        })}
      </Header>
    );
  };

  const rowRender = (data) => {
    return (
      <Row key={data.id}>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];

          if (id === 'edit') {
            content = (
              <>
                <Button
                  icon='im-Edit1'
                  status='transparent'
                  size='inline'
                  customClass='admin-page__edit-btn ui-button_no-border'
                  onClick={() => onEditUser(data.id)}
                />
                <Button
                  icon='im-Basket'
                  status='transparent'
                  size='inline'
                  customClass='admin-page__edit-btn ui-button_no-border'
                  onClick={() => onDeleteUser(data.id)}
                />
              </>
            );
          } else if (id === 'activate') {
            content = (
              <Switch
                id={'switch-status'}
                checked={data['status'] === 'Active'}
                onChange={() => onToggleActivate(data)}
                customClass='admin-page__switch'
              />
            );
          } else if (id === 'merchants' || id === 'projects') {
            content = content.reduce((result, item) => {
              result.push(item.name);
              return result;
            }, []);
          } else if (id === 'id') {
            content = (
              <>
                <Checkbox
                  id={data.id}
                  customClass='admin-page__id-column-checkbox'
                  checked={selectedUsers.some((user) => user.id === data.id)}
                  tooltip={
                    !isUserSelectionAvailable
                      ? getTranslation(
                          'myteam.bunchUserEdit.noSelectedMerchants'
                        )
                      : undefined
                  }
                  disabled={!isUserSelectionAvailable}
                  onChange={() => {
                    toggleSelectedUser(data);
                  }}
                />

                {content}
              </>
            );
          }
          return (
            (content !== undefined && (
              <Column
                columnWidths={data.columnWidths}
                key={id}
                id={id}
                content={
                  content ?? <span className='ui-table__column-empty'>—</span>
                }
                params={{
                  ...config,
                  valueId: config.id,
                  valueType: config.valueType,
                }}
                customClass={`ui-table__column_${id}`}
              />
            )) ||
            null
          );
        })}
      </Row>
    );
  };

  return (
    <PageTemplate.Main customClass='admin-page'>
      <PageTemplate.Container>
        <TopPanelContainer isAdmin tableName={tableNames.users} />
        <DataListContainer
          minColumnsWidth={minUsersTableColumnsWidth}
          apiRequest={apiRequest}
          tableName={tableNames.users}
          headerRender={headerRender()}
          rowRender={rowRender}
        />
        {isLoading && <Loader />}
      </PageTemplate.Container>
    </PageTemplate.Main>
  );
};
