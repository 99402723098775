import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { isEqual, omit } from 'lodash-es';
import { addEntityToSaved, removeEntityFromSaved } from 'actions/savedEntities';
import { openModal } from 'actions/modal';
import { RootState } from 'store';
import {
  IFormValues,
  IPositionSetting,
  FieldValidationErrors,
  LocationPayload,
} from './types';
import { paymentRefund } from 'api/payment';
import { getProjectFiscalReceiptInfo } from 'api/projects';
import { useTranslation } from 'hooks';
import Utils from 'helpers/Utils';
import checkFilters from 'helpers/checkFilters';
import savedEntities from 'constants/savedEntities';
import { CreateFiscalReceiptView } from './CreateFiscalReceiptView';
import './createFiscalReceipt.scss';

const initialState = {
  refundAmount: '',
  email: '',
  inn: '',
  group: '',
  taxationSystem: null,
  paymentAddress: '',
  positions: [],
  payments: [],
};

const dictionaries = [
  'CashVoucherTaxationSystem',
  'CashVoucherTaxRate',
  'CashVoucherPaymentMethodType',
  'CashVoucherPaymentSubjectType',
  'CashVoucherPositionDescriptionIncomeType',
  'CashVoucherPositionDescriptionContrType',
];

const CreateFiscalReceipt: React.FC = () => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const history = useHistory();
  const location = useLocation<{ data?: LocationPayload }>();
  const {
    maxRefundAmount = '',
    refundAmount = '',
    currency = '',
    returnBack = '/payments',
  } = location.state?.data || {};
  const initialValues = useRef({
    ...initialState,
    refundAmount,
  });
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errors, setErrors] = useState<FieldValidationErrors>({});
  const [formValues, setFormValues] = useState<IFormValues>(
    initialValues.current
  );
  const { projectId, transactionId } = queryString.parse(location.search) as {
    projectId: string;
    transactionId: string;
  };
  const isPayloadValid =
    projectId && transactionId && maxRefundAmount && currency && returnBack;
  const isFormValid = Boolean(
    formValues.refundAmount &&
      Number(Utils.getNumberWithoutSpace(formValues.refundAmount)) !== 0 &&
      formValues.email &&
      formValues.inn &&
      formValues.group &&
      (formValues.taxationSystem || formValues.taxationSystem === 0) &&
      formValues.positions.length
  );

  const { isCreateFiscalReceiptEnabled } = useSelector((state: RootState) => {
    const { paymentActions } = state;

    return {
      isCreateFiscalReceiptEnabled:
        paymentActions[transactionId]?.refundCashVoucher.isEnabled,
    };
  });

  const totalPositionAmount = useMemo(() => {
    return formValues.positions.reduce((sum, { price, quantity }) => {
      const unit =
        Number(Utils.getNumberWithoutSpace(price)) *
        Number(Utils.getNumberWithoutSpace(quantity));

      return sum + Number(unit.toFixed(2));
    }, 0);
  }, [formValues.positions]);

  useEffect(() => {
    if (!isCreateFiscalReceiptEnabled || !isPayloadValid) {
      history.push('/payments');
      return;
    }

    checkFilters(dictionaries);

    fetchInfo();

    dispatch(
      addEntityToSaved({
        id: 'create-fiscal-receipt',
        entityKey: savedEntities.payments,
        caption: getTranslate('fiscalReceipt.form.header.label'),
      })
    );

    return () => {
      dispatch(
        removeEntityFromSaved({
          id: 'create-fiscal-receipt',
          entityKey: savedEntities.payments,
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const fetchInfo = async () => {
    setLoading(true);

    try {
      const data = await getProjectFiscalReceiptInfo({ projectId });
      setFormValues((prevState) => ({ ...prevState, ...data }));
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (formValue: Partial<IFormValues>) => {
    setFormValues((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  const handleOpenSettingModal = (settingId?: string) => {
    const maxAmount = Number(Utils.getNumberWithoutSpace(maxRefundAmount));
    const availableAmount = Number(
      (maxAmount - totalPositionAmount).toFixed(2)
    );
    const currentSetting = formValues.positions.find(
      (item) => item.id === settingId
    );

    dispatch(
      openModal({
        modalId: 'FiscalReceiptPositionSettings',
        content: {
          ...currentSetting,
          availableAmount,
        },
        callback: (newData: IPositionSetting) => {
          if (!newData) {
            return;
          }

          if (settingId === newData.id) {
            setFormValues((prevState) => {
              const index = prevState.positions.findIndex(
                ({ id }) => id === settingId
              );
              const copy = [...prevState.positions];
              copy[index] = newData;

              return {
                ...prevState,
                positions: copy,
              };
            });
            return;
          }

          setFormValues((prevState) => ({
            ...prevState,
            positions: [...prevState.positions, newData],
          }));
        },
      })
    );
  };

  const handleDelete = (settingId: string) => {
    setFormValues((prevState) => {
      return {
        ...prevState,
        positions: prevState.positions.filter(({ id }) => settingId !== id),
      };
    });
  };

  const handleOpenSubmitModal = () => {
    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: getTranslate('fiscalReceipt.modal.submit.title'),
          text: getTranslate('fiscalReceipt.modal.submit.text'),
          okButton: {
            text: getTranslate('fiscalReceipt.detCard.Submit.button'),
            status: 'primary',
          },
          cancelButton: {
            text: getTranslate('fiscalReceipt.modal.submit.cancel'),
            status: 'outline',
          },
        },
        callback: (isAllowed) => {
          if (isAllowed) {
            handleSubmit();
          }
        },
      })
    );
  };

  const formattedPayload = () => {
    const positions = formValues.positions.map((item) => {
      const copy = omit(item, ['id', 'incomeType', 'contributionType']);

      return {
        ...copy,
        quantity: Number(Utils.getNumberWithoutSpace(copy.quantity)),
        price: Number(Utils.getNumberWithoutSpace(copy.price)),
      };
    });
    const amount = Number(Utils.getNumberWithoutSpace(formValues.refundAmount));
    const values = omit(formValues, 'refundAmount');

    return {
      projectId,
      transactionId,
      amount,
      cashVoucher: {
        ...values,
        sendCashVoucher: true,
        payments: [
          {
            amount,
            paymentType: 2,
          },
        ],
        positions,
      },
    };
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    const payload = formattedPayload();

    try {
      await paymentRefund(payload);
      setFormValues(initialValues.current);
      setSuccess(true);
    } catch (err: any) {
      const { validationErrors } = err?.payload || {};

      if (validationErrors) {
        const email = validationErrors?.['operation.cashVoucher.email'];
        const inn = validationErrors?.['operation.cashVoucher.inn'];

        setErrors({
          email,
          inn,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateFiscalReceiptView
      payload={{
        currency,
        maxRefundAmount,
        returnBack,
        totalPositionAmount,
      }}
      validationErrors={errors}
      formValues={formValues}
      isFieldsDirty={!isEqual(formValues, initialValues.current)}
      isFormValid={isFormValid}
      isLoading={isLoading}
      isSuccess={isSuccess}
      onChange={handleChange}
      onDelete={handleDelete}
      onOpenModal={handleOpenSettingModal}
      onSubmit={handleOpenSubmitModal}
    />
  );
};

export { CreateFiscalReceipt };
