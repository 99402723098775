import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';

import { createRemittancePayment } from 'api/remittance';
import { addEntityToSaved } from 'actions/savedEntities';
import { WithRouterProps } from 'decorators/withRouter';
import { addListeners } from 'decorators/addListeners';
import FormFields from 'components/formFields/FormFields';

import Utils from 'helpers/Utils';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import formStateFactory from 'components/formFields/formStateFactory';
import { getRequiredFields } from 'components/formFields/helpers';
import SubmittedForm from 'components/formFields/components/submittedForm';
import path from 'helpers/path';
import savedEntities from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { AnyObject } from 'types/Common';
import fieldsConfig from '../../fieldsConfig';
import { StoreProps } from 'store';
import { createRoutes } from '../../../routesMapper';
import tableNames from 'constants/tableNames';
import { isEqual } from 'lodash-es';

interface OwnProps {
  id?: string;
  onFieldDirty: (isFieldsDirty: boolean) => void;
}

interface ConnectedProps {
  storedSavedEntities: any;
  modal: any;
  availableAccountIds: any;
  filtersValues: any;
  filtersValuesCustom: any;
  isPaymentIdGeneratorEnabled: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  canSave: boolean;
  isLoading: boolean;
  statusText: string;
  fields: any;
  fieldsSnapshot: any;
  validationErrors: AnyObject;
  fieldsState: { currency: boolean };
}

@addListeners([
  Messages.RemittanceQueue_StatusUpdated,
  Messages.Remittance_CreatePayment,
])
class RemittancePaymentSingle extends Component<Props, State> {
  private requiredFields = getRequiredFields(fieldsConfig);

  constructor(props) {
    super(props);
    const initialFields = this.getInitialFields();

    this.state = {
      fields: initialFields,
      fieldsSnapshot: initialFields,
      fieldsState: {
        currency: false,
      },
      statusText: '',
      canSave: false,
      validationErrors: {},
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.setSavedEntities();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      storedSavedEntities: prevStoredSavedEntities,
      modal: { isOpened: prevIsOpened },
    } = prevProps;
    const {
      storedSavedEntities,
      modal: { isOpened },
    } = this.props;

    if (!prevStoredSavedEntities && storedSavedEntities) {
      this.setSavedEntities();
    }

    if (prevIsOpened && !isOpened) {
      this.setState({ isLoading: false });
    }
  }

  setSavedEntities = () => {
    const { id, dispatch, storedSavedEntities } = this.props;

    if (
      !storedSavedEntities?.isFetch ||
      storedSavedEntities.items.some((entity) => {
        return String(entity.id) === id;
      })
    ) {
      return;
    }

    let savedEntityParams;
    const createUrl = createRoutes[tableNames.remittancePayments];

    if (!id?.includes(createUrl)) {
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id,
      };
    } else {
      const isEntityExist = storedSavedEntities.items.some(
        ({ id }) => id === createUrl
      );

      if (isEntityExist) {
        return;
      }

      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: createUrl,
        hiddenParams: {
          localeKey: 'remittance.addPayout.header',
          isCreate: true,
        },
      };
    }

    dispatch(addEntityToSaved(savedEntityParams));
  };

  getInitialFields = () => {
    const { isPaymentIdGeneratorEnabled } = this.props;

    return {
      ...formStateFactory(fieldsConfig.general, isPaymentIdGeneratorEnabled),
      ...formStateFactory(fieldsConfig.additional),
    };
  };

  onChange = (name, value) => {
    this.setState(
      (state) => ({
        fields: {
          ...state.fields,
          [name]: value,
          currency:
            name === 'remittanceRecipientAccountId'
              ? this.getRecipientAccountIdCurrency(value)
              : state.fields.currency,
        },
        validationErrors: { ...state.validationErrors, [name]: '' },
      }),
      () => {
        this.checkIsFieldsDirty();
        this.checkRequiredFields();
      }
    );
  };

  checkRequiredFields = () => {
    this.setState(({ fields }) => ({
      canSave: this.requiredFields.every((item) => {
        return !!fields[item.id];
      }),
    }));
  };

  checkIsFieldsDirty = () => {
    const { fields, fieldsSnapshot } = this.state;
    const { onFieldDirty } = this.props;
    const copyFields = { ...fields };
    const isFieldsDirty = !isEqual(copyFields, fieldsSnapshot);

    onFieldDirty(isFieldsDirty);
  };

  getRecipientAccountIdCurrency = (value) => {
    const { availableAccountIds } = this.props;
    return availableAccountIds?.list.find(({ id }) => id === value)
      ?.transferCurrency;
  };

  cleanForm = () => {
    this.setState(
      {
        statusText: '',
        fields: this.getInitialFields(),
      },
      () => Tooltip.rebuild()
    );
  };

  handleSave = async () => {
    const { fields } = this.state;

    if (isNotAvailableForSupport(Messages.Remittance_CreatePayment)) {
      return;
    }

    this.setState({ isLoading: true });
    await createRemittancePayment({
      ...fields,
      amount: Utils.getNumberWithoutSpace(fields.amount),
    });
  };

  onEvent = ({ name, data }) => {
    const { onFieldDirty } = this.props;
    const { validationErrors } = data.payload;

    switch (name) {
      case Messages.Remittance_CreatePayment: {
        if (validationErrors) {
          this.setState({
            isLoading: false,
            validationErrors,
          });
        } else {
          this.setState({
            isLoading: false,
            statusText: 'remittance.addPayout.sendRequestSuccess.text',
          });
        }

        onFieldDirty(false);
        break;
      }
    }
  };

  render() {
    const {
      validationErrors,
      statusText,
      isLoading,
      canSave,
      fields,
      fieldsState,
    } = this.state;
    const { filtersValues, filtersValuesCustom } = this.props;

    if (statusText) {
      return (
        <SubmittedForm
          id='remittance'
          text={statusText}
          onPrimaryButtonClick={this.cleanForm}
          primaryButtonText='remittance.addRecipientAcc.newRemittance.button'
          secondaryButtonText='remittance.addPayout.back.button'
          onSecondaryButtonLink={path(`/${urlsMap.remittance}`)}
          status='success'
        />
      );
    }

    return (
      <FormFields
        id='remittance'
        text='remittance.addPayout.infoText'
        generalTitle='remittance.addPayout.generalInfo.label'
        additionalTitle='remittance.addPayout.additionalInfo.label'
        isLoading={isLoading}
        canCreate={canSave}
        fieldsValues={fields}
        dictionaries={{
          ...filtersValues,
          ...filtersValuesCustom,
        }}
        fieldsConfig={fieldsConfig}
        fieldsState={fieldsState}
        validationErrors={validationErrors}
        isSubmitted={!!statusText}
        submittedTitle='remittance.addPayout.sendRequestSuccess.header'
        submittedText={statusText}
        backUrl={path(`/${urlsMap.remittance}`)}
        backText='remittance.addPayout.back.button'
        createButtonText='remittance.addPayout.sendRequest.button'
        repeatCreateButtonText='remittance.addRecipientAcc.newRemittance.button'
        onChange={this.onChange}
        onSubmit={this.handleSave}
        onReset={this.cleanForm}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
    storedSavedEntities: state.savedEntities[savedEntities.remittance],
    modal: state.modal,
    availableAccountIds: state.filtersValues?.remittanceRecipientAccount,
  };
};

export default withRouter(connect(mapStateToProps)(RemittancePaymentSingle));
