import applePay from './ApplePay.svg';
import alipay from './Alipay.svg';
import amex from './AMEX.svg';
import bancotact from './Bancotact.svg';
import blik from './Blik.svg';
import dach from './DACH.svg';
import doku from './Doku.svg';
import eps from './EPS.svg';
import gbpOut from './GBPOut.svg';
import googlePay from './GooglePay.svg';
import iDeal from './iDeal.svg';
import multibanco from './Multibanco.svg';
import neteller from './Neteller.svg';
import openBanking from './OpenBanking.svg';
import payPal from './PayPal.svg';
import przelewy24 from './Przelewy24.svg';
import safetyPay from './Safetypay.svg';
import sepaOut from './SepaOut.svg';
import skrill from './Skrill.svg';
import sofort from './Sofort.svg';
import telegramBot from './TelegramBot.svg';
import trustly from './Trustly.svg';
import giroPay from './Giropay.svg';
import unionPay from './UnionPay.svg';
import weChat from './WeChat.svg';

const highRisk = {
  applePay,
  bancotact,
  blik,
  dach,
  eps,
  gbpOut,
  googlePay,
  iDeal,
  multibanco,
  neteller,
  openBanking,
  payPal,
  przelewy24,
  safetyPay,
  sepaOut,
  skrill,
  sofort,
  telegramBot,
  trustly,
};

export default {
  highRisk,
  lowRisk: {
    alipay,
    amex,
    doku,
    giroPay,
    unionPay,
    weChat,
    ...highRisk,
  },
};
