import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { throttle } from 'lodash-es';

import {
  deleteArticle,
  getArticle,
  publishArticle,
  updateArticle,
} from 'api/help';
import { confirmLeavePage } from 'actions/app';
import { updateArticle as update } from 'actions/help';
import { openModal } from 'actions/modal';
import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import Editor from 'components/editor';
import HelpArticle from './HelpArticle';
import {
  getArticleBySlug,
  getSubTitlesFromContent,
  setIdsForContentSubTitles,
} from './utils';
import HelpArticleType, { ArticleStatus } from 'pages/help/types';
import Messages from 'constants/rpcTypes';
import { RootState, StoreProps } from 'store';

interface ConnectedProps {
  userLang: string;
  article: HelpArticleType;
  isConfirmLogout: boolean;
}

type RouteProps = WithRouterProps<{ slug?: string }>;

type Props = ConnectedProps & StoreProps & RouteProps;

interface State {
  isEditing: boolean;
  hasNotData: boolean;
  isLoading: boolean;
  isDraft: boolean;
}

@addListeners([
  Messages.Article_Publish,
  Messages.Article_Update,
  Messages.Article_View,
])
class HelpArticleContainer extends Component<Props, State> {
  private editor;
  private scrollRef;
  private lastSavedContent;
  private timeoutId;
  private ignoreHashChanges: boolean = false;
  private ignoreScrollHandler: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      hasNotData: false,
      isLoading: false,
      isDraft: props.article && props.article.status === ArticleStatus.draft,
    };

    this.handleArticleScroll = throttle(this.handleArticleScroll, 100);
  }

  componentDidMount() {
    this.fetchArticleContent();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { location } = this.props;
    const currentParams = this.getUrlParams();
    const prevParams = this.getUrlParams(prevProps);

    if (currentParams.slug !== prevParams.slug) {
      this.removeSubtitles(prevProps.article);
      this.fetchArticleContent();
      return;
    }

    if (prevProps.location.hash !== location.hash) {
      if (!this.ignoreHashChanges) {
        this.scrollArticleToCurrentHash();
      }
      this.ignoreHashChanges = false;
    }
  }

  render() {
    const { article } = this.props;
    const { isLoading, hasNotData, isEditing, isDraft } = this.state;

    if (!article?.articleId)
      return (
        <div className='help-page__no-content'>
          <NoContent />
        </div>
      );

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <HelpArticle
            data={article}
            key={article.slug}
            isEditing={isEditing}
            hasNotData={hasNotData}
            isDraft={isDraft}
            getScrollRef={(el) => {
              this.scrollRef = el;
            }}
            onStartEdit={this.startEdit}
            onCancel={this.cancelEdit}
            onSave={this.startSave}
            toggleDraft={this.toggleDraft}
            onDelete={this.confirmDelete}
            onScroll={this.handleArticleScroll}
            publishArticle={this.publishArticle}
            onEditArticleInfo={(data) =>
              data.isEditMode && this.openModal(data)
            }>
            {((!isDraft && !article.content) ||
              (isDraft && !article.contentDraft)) &&
              !isLoading &&
              !isEditing && <NoContent />}
            <Editor
              content={this.setEditorContent()}
              onInit={this.handleInitEditor}
              onChange={this.handleChangeContentEditor}
            />
          </HelpArticle>
        )}
        <Prompt when={isEditing} message={this.checkLeavingPage} />
      </>
    );
  }

  handleInitEditor = (editor) => {
    const { article, dispatch } = this.props;
    if (!article) return false;

    this.editor = editor;
    const content = setIdsForContentSubTitles(this.getEditorContent());
    this.editor.setContents(content);

    const subTitles = getSubTitlesFromContent(
      this.getEditorNode(),
      this.scrollRef
    );

    dispatch(
      update({
        ...article,
        subTitles,
      })
    );

    this.scrollArticleToCurrentHash();
  };

  handleChangeContentEditor = () => {
    const { article, dispatch } = this.props;
    if (!article) return false;

    dispatch(
      update({
        ...article,
        subTitles: getSubTitlesFromContent(
          this.getEditorNode(),
          this.scrollRef
        ),
      })
    );
  };

  getEditorContent() {
    return this.editor.getContents();
  }

  getEditorNode = () => {
    return this.editor.core.context.element.wysiwyg;
  };

  setEditorContent = () => {
    const { article } = this.props;
    return this.state.isDraft && article.contentDraft
      ? setIdsForContentSubTitles(article.contentDraft)
      : setIdsForContentSubTitles(article.content);
  };

  startSave = () => {
    this.toggleMode(false);
    const markedContent = setIdsForContentSubTitles(this.getEditorContent());
    this.lastSavedContent = markedContent;
    this.editor.setContents(markedContent);
    this.saveArticleContent(markedContent);
  };

  startEdit = () => {
    this.toggleDraft(true);
    this.toggleMode(true, () => {
      this.editor.setContents(this.setEditorContent());
      this.lastSavedContent = this.getEditorContent();
    });
    this.editor.core.focus();
  };

  cancelEdit = () => {
    const { article, dispatch } = this.props;
    this.toggleMode(false, () => {
      if (!article.isChanged && article.status === ArticleStatus.published) {
        this.toggleDraft(false);
      }
    });

    this.editor.setContents(this.lastSavedContent);
    dispatch(
      update({
        ...article,
        subTitles: getSubTitlesFromContent(
          this.getEditorNode(),
          this.scrollRef
        ),
      })
    );
  };

  toggleMode = (isEditing: boolean, callback?: () => void) => {
    const { dispatch } = this.props;

    this.setState({ isEditing }, callback);

    dispatch(confirmLeavePage({ isConfirm: isEditing }));

    if (isEditing) {
      this.editor.enabled();
    } else {
      this.editor.disabled();
    }
  };

  toggleDraft = (isOn, callback?) => {
    this.setState({ isDraft: isOn }, () => {
      if (!this.state.isDraft) {
        this.setState({ isEditing: false });
      }
      this.editor.setContents(this.setEditorContent());
      callback && callback();
    });
  };

  fetchArticleContent = async () => {
    const { article } = this.props;

    if (!article) {
      return this.setState({ hasNotData: true });
    }

    this.setState({ isLoading: true });

    try {
      const { slug, language, articleId } = article;
      await getArticle({
        slug,
        articleId,
        language,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  saveArticleContent = async (contentDraft) => {
    const { article } = this.props;
    if (!article) return false;

    this.setState({ isLoading: true });

    try {
      await updateArticle({
        ...article,
        contentDraft,
      });
    } catch (error) {
      console.error(error);
    }
  };

  publishArticle = async (articleId) => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'modals.common.title',
          text: 'modals.help.confirmPublish.text',
        },
        callback: (onAccept) => {
          if (onAccept) {
            publishArticle(articleId);
          }
        },
      })
    );
  };

  handleArticleScroll = ({ scrollTop }) => {
    const { article, location, history } = this.props;
    const currentId = location.hash.slice(1);

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (!article.subTitles || this.ignoreScrollHandler) return;

    for (const title of article.subTitles) {
      if (
        currentId !== title.id &&
        scrollTop >= title.top &&
        scrollTop < title.bottom
      ) {
        this.ignoreHashChanges = true;
        history.push(`#${title.id}`);

        this.timeoutId = setTimeout(() => {
          this.ignoreHashChanges = false;
        }, 200);
        break;
      }
    }
  };

  scrollArticleToCurrentHash = () => {
    const { article, location } = this.props;
    const id = location.hash.slice(1);
    const subTitle = article.subTitles?.find((title) => title.id === id);

    if (!subTitle?.top || !this.scrollRef) return;

    this.scrollRef.scrollerElement.scrollTo({
      behavior: 'smooth',
      top: subTitle.top,
    });

    // waiting for animation to end
    this.ignoreScrollHandler = true;
    setTimeout(() => {
      this.ignoreScrollHandler = false;
    }, 500);
  };

  getUrlParams = (props = this.props) => {
    const { userLang, match, location } = props;
    const { lang } = queryString.parse(location.search);

    return {
      slug: match.params.slug,
      lang: lang ? lang.toString() : userLang,
    };
  };

  confirmDelete = (articleId) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'Confirm',
        callback: (answer) => {
          answer && deleteArticle(articleId);
        },
        content: { text: 'Are you sure you want to delete this article' },
      })
    );
  };

  confirmLeaveArticle = (location) => {
    const { history, dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'Confirm',
        callback: (answer) => {
          if (answer) {
            this.toggleMode(false, () => {
              history.push(location.pathname);
            });
          }
        },
        content: {
          text: 'Are you sure you want to leave article without save changes?',
        },
      })
    );
  };

  checkLeavingPage = (location) => {
    const { article } = this.props;
    if (location.pathname === '/login') return true;
    if (location.pathname.includes(article.slug)) return true; //для навигации по статье в режиме редактирования
    this.confirmLeaveArticle(location);
    return false;
  };

  openModal = (content?) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'AddHelpArticle',
        content,
      })
    );
  };

  removeSubtitles = (article) => {
    this.props.dispatch(
      update({
        ...article,
        subTitles: [],
      })
    );
  };

  onEvent = ({ name, data }) => {
    const { dispatch } = this.props;
    const { status } = data.rpc;
    const { payload } = data;
    if (status !== 'success') return;

    if (name === Messages.Article_Publish) {
      this.setState({ isDraft: false });
    } else if (name === Messages.Article_Update) {
      dispatch(update({ ...payload.article }));
      this.setState({ isLoading: false });
    } else if (name === Messages.Article_View) {
      this.setState({
        isDraft: payload.article.status === ArticleStatus.draft,
      });
    }
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: RouteProps
): ConnectedProps => ({
  userLang: state.user.interfaceLang || 'en',
  article: getArticleBySlug(state.help.articles, ownProps.match.params.slug),
  isConfirmLogout: state.app.isConfirm,
});

export default withRouter(connect(mapStateToProps)(HelpArticleContainer));
