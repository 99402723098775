import { useTranslation } from 'hooks';
import { ButchUserEditMode, UserEditingResult } from '../../types';
import { SimpleList } from '../SimpleList';
import './successModalContent.scss';

export const SuccessModalContent: React.FC<{
  userEditingResult: UserEditingResult;
  editMode: ButchUserEditMode;
}> = ({ userEditingResult, editMode }) => {
  const getTranslate = useTranslation();
  const projectsListTitle =
    editMode === 'add'
      ? 'myteam.butchEdit.usersSuccessfullyAdded.projects.title'
      : 'myteam.butchEdit.usersSuccessfullyRemoved.projects.title';
  const projects =
    editMode === 'add'
      ? userEditingResult.projectsToAdd
      : userEditingResult.projectsToRemove;

  return (
    <div className='butch-user-edit-success-modal'>
      <div className='butch-user-edit-success-modal__column butch-user-edit-success-modal__column_email'>
        <div className='butch-user-edit-success-modal__title'>
          {getTranslate('myteam.butchEdit.usersSuccessfullyEdited.list.title')}
        </div>
        <SimpleList items={userEditingResult.users.map((user) => user.email)} />
      </div>
      <div className='butch-user-edit-success-modal__column'>
        <div className='butch-user-edit-success-modal__title'>
          <div>{getTranslate(projectsListTitle)}</div>
        </div>
        <SimpleList items={projects.map((project) => project.name)} />
      </div>
    </div>
  );
};
