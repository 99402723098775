import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './polifyll';

import StartupContainer from './StartupContainer';
import { store } from 'store';
import Repository from 'helpers/Repository';
import Env from 'helpers/Env';
import { setupSentry } from 'setupSentry';
import 'styles/index.scss';

Repository.set('store', store);
Env.isProduction() && setupSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <Router>
      <StartupContainer />
    </Router>
  </Provider>
);
