import Grid from 'components/grid';
import Icon from 'components/ui/icon';
import './accounting-request-notes.scss';

export const AccountingRequestNotes: React.FC<{ notes: string }> = ({
  notes,
}) => (
  <Grid
    className='accounting-request-notes'
    spacing='s'
    direction='row'
    align='center'>
    <Icon className='accounting-request-notes__icon' name='im-Chat' size={14} />
    <div className='accounting-request-notes__text'>{notes}</div>
  </Grid>
);
