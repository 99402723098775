import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import Mapper from 'helpers/Mapper';
import dataMapper from './operationItemDataMapper';
import { AnyObject } from 'types/Common';
import Checkbox from '../../ui/checkbox';
import ConditionalWrapper from '../../ui/conditionalWrapper';
import CopyToClipboard from '../../ui/copyToClipboard';
import LabelStatus from 'components/ui/labelStatus';
import './operationItem.scss';

interface OwnProps {
  data: AnyObject;
  isCurrent: boolean;
  onSelect: (operationId: string) => void;
  onCheckboxChange?: (id: string, isSelected: boolean) => void;
  onStatusClick?: (parentOperationId: string) => void;
  customClass?: string;
  isDisabled?: boolean;
  isCheckboxDisabled?: boolean;
  type?: 'chargeback' | 'operation';
}

type Props = OwnProps & IntlProps & WithRouterProps;

class OperationItem extends PureComponent<Props> {
  static defaultProps = {
    type: 'operation' as const,
  };

  render() {
    const {
      data,
      isCurrent,
      onSelect,
      type,
      isDisabled,
      isCheckboxDisabled,
      customClass,
      onCheckboxChange,
      onStatusClick,
    } = this.props;
    const {
      id,
      label,
      value,
      isSelected,
      operationType,
      updatedDate,
      amount,
      chargeback,
      currency,
      fraud,
      status,
      recurring,
    } = dataMapper(data, type);
    const isChargeback = type === 'chargeback';

    return (
      <div
        className={classNames(
          'operation-item',
          {
            'operation-item_active': isCurrent,
            'operation-item_disabled': isDisabled,
            'operation-item_chargeback': isChargeback,
          },
          customClass
        )}
        id={`operation-item-${id}`}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(id);
        }}>
        {isChargeback && (
          <div className='operation-item__col'>
            <Checkbox
              id={value}
              customClass='operation-item__checkbox'
              checked={isSelected}
              disabled={isCheckboxDisabled}
              onChange={(e) => {
                e.persist();
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                onCheckboxChange?.(id, e.target.checked);
              }}
            />
          </div>
        )}
        <ConditionalWrapper
          condition={isChargeback}
          wrap={(children) => (
            <div className='operation-item__col'>{children}</div>
          )}>
          {!isChargeback && (
            <div className='operation-item__block operation-item__block_header'>
              <span className='operation-item__type'>{operationType}</span>
              <span className='operation-item__date'>{updatedDate}</span>
            </div>
          )}
          <div className='operation-item__block'>
            <span className='operation-item__title'>{amount} </span>
            <span className='operation-item__currency'>{currency}</span>
          </div>
          <div className='operation-item__block operation-item__block_status-box'>
            <LabelStatus text={status} status={Mapper.getStatus(status)} />

            {recurring ? (
              <LabelStatus
                text={recurring}
                status={Mapper.getStatus(recurring)}
                onClick={(e) => {
                  e.stopPropagation();

                  if (recurring === 'retry operation') {
                    return;
                  }

                  if (onStatusClick) {
                    onStatusClick(id);
                  }
                }}
              />
            ) : null}

            {Boolean(fraud) && (
              <LabelStatus
                text='fraud'
                theme='bordered'
                status={Mapper.getStatus('fraud')}
              />
            )}
            {Boolean(chargeback) && (
              <LabelStatus
                text='chargeback'
                theme='bordered'
                status={Mapper.getStatus('chargeback')}
              />
            )}
          </div>
          <div className='operation-item__block operation-item__block_footer'>
            <span className='operation-item__label'>{label}:</span>
            <span className='operation-item__value'>
              {isChargeback ? value : id}
            </span>
            <CopyToClipboard text={value} customClass='operation-item__copy' />
          </div>
        </ConditionalWrapper>
      </div>
    );
  }
}

export default withRouter(addTranslation(OperationItem));
