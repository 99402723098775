import React, { CSSProperties, PureComponent } from 'react';
import { throttle } from 'lodash-es';
import classNames from 'classnames';
import './resizeBar.scss';

const initialState = {
  verticalBar: {},
  horizontalBar: {},
};

type ResizeBarStyles = {
  verticalBar: Pick<CSSProperties, 'right' | 'top' | 'height'>;
  horizontalBar: Pick<CSSProperties, 'right'>;
};

interface Props {
  height: ResizeBarStyles['verticalBar']['height'];
  headerHeight: number;
  width?: number;
  minWidth: number;
  id: string;
  updateWidth: ((id: string, width: number) => void) | undefined;
  setIsResizing?: (isResizing: boolean) => void;
}

interface State {
  styles: ResizeBarStyles;
  dragging: boolean;
  startPageX: number;
}

export default class ResizeBar extends PureComponent<Props, State> {
  private horizontalBarRef;
  state = {
    styles: initialState,
    dragging: false,
    startPageX: 0,
  };

  componentDidMount() {
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onDragEnd);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onDragEnd);
  }

  render() {
    const { styles, dragging } = this.state;
    return (
      <div className='ui-resize-bars-container'>
        <div
          onMouseDown={this.onDragStart}
          className={classNames('ui-resize-bar__vertical', {
            'ui-resize-bar__vertical_dragging': dragging,
          })}
          style={styles.verticalBar}
        />
        <div
          ref={(ref) => {
            this.horizontalBarRef = ref;
          }}
          className={classNames('ui-resize-bar__horizontal', {
            'ui-resize-bar__horizontal_dragging': dragging,
          })}
          style={styles.horizontalBar}
        />
      </div>
    );
  }

  onDrag = throttle((e) => {
    const { width, minWidth, height, headerHeight } = this.props;
    const { dragging } = this.state;
    if (!dragging) return;
    const initialWidth = width || this.horizontalBarRef.offsetWidth;
    this.setState((prevState) => {
      let right: ResizeBarStyles['verticalBar']['right'] =
        prevState.startPageX - e.clientX;
      if (initialWidth - right < minWidth) {
        // keep resize bar out of min width range
        right = prevState.styles.verticalBar.right || 0;
      }

      return {
        styles: {
          verticalBar: {
            right,
            height,
            top: headerHeight,
          },
          horizontalBar: {
            right,
          },
        },
      };
    });
  }, 25);

  onDragStart = (e) => {
    const { setIsResizing } = this.props;
    this.setState({ dragging: true, startPageX: e.pageX });
    setIsResizing && setIsResizing(true);
  };

  onDragEnd = (e) => {
    const { startPageX, dragging } = this.state;
    if (!dragging) return;
    const { updateWidth, id, width, setIsResizing } = this.props;
    const initialWidth = width || this.horizontalBarRef.offsetWidth;
    const newWidth = initialWidth + e.pageX - startPageX;
    this.setState({ styles: initialState, dragging: false });
    updateWidth && updateWidth(id, newWidth);
    setIsResizing && setIsResizing(false);
  };
}
