import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = null;

const configuration = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.INIT_ALL_CONFIGURATION:
      return {
        ...state,
        ...payload.list,
      };

    case ACTIONS.UPDATE_CONFIGURATION:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.APPLY_CONFIGURATION:
      return {
        ...state,
        // ...state[payload.name] - для сохранения initialConfig
        // ...payload.body - изменения в табах конфигуратора
        [payload.name]: [...payload.body],
      };

    case ACTIONS.CHANGE_FIXED_CONFIGURATION: {
      const columns = [...state[payload.name]];
      const newColumns = columns.map(({ fixed, ...rest }) => {
        if (fixed !== undefined) {
          return {
            ...rest,
            fixed: !fixed,
          };
        }

        return rest;
      });

      return { ...state, [payload.name]: newColumns };
    }

    default:
      return state;
  }
};

export default configuration;
