import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { AccountingRequestItem } from 'pages/accountingStatementRequests/types';

export const setAccountingRequests =
  (accountingRequests: AccountingRequestItem[]) => (dispatch) =>
    dispatch(
      createAction({
        type: ACTIONS.SET_ACCOUNTING_REQUESTS,
        payload: {
          list: accountingRequests,
        },
      })
    );

export const resetAccountingRequests = () => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.RESET_ACCOUNTING_REQUESTS,
    })
  );
};
