import React from 'react';
import { MafAppendix, MafSection } from 'components/maf/types';

interface Props {
  selectedEntity: MafSection | MafAppendix | any;
}

const MafHeader: React.FC<Props> = ({ selectedEntity }) => {
  return (
    <div className='maf-content__header'>
      <span className='maf-content__header-caption'>
        {selectedEntity?.caption}
      </span>
    </div>
  );
};

export { MafHeader };
