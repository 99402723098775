import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { pickBy } from 'lodash-es';
import { RootState, StoreProps } from 'store';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { paymentCancel, paymentCapture, paymentRefund } from 'api/payment';
import showNotification from 'components/ui/notification/showNotification';
import Action from './Action';
import { AnyObject } from 'types/Common';
import OPERATION_CODES from 'constants/operationCodes';
import statuses from 'components/ui/button/statuses';

export type actionTypesT = 'capture' | 'cancel' | 'refund' | 'payout';

interface OwnProps {
  type: string;
  projectId: string;
  transactionId: string;
  operationId?: string;
  customClass: string;
  tooltip?: string;
  buttonTheme?: keyof typeof statuses;
  buttonType?: 'round';
  onBeforeOpen?: () => boolean;
  isEnabled?: boolean;
  isOpenedPanel?: boolean;
  selectorPortal?: string;
}

interface ConnectedProps {
  data: AnyObject;
  isMenuExpanded?: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isOpenedPanel: boolean;
  isSending: boolean;
}

class ActionContainer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPanel: Boolean(props.isOpenedPanel && props.data?.isEnabled),
      isSending: false,
    };
  }

  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { data, isOpenedPanel } = this.props;
    const { data: prevData } = prevProps;

    if (
      prevData.isEnabled !== data.isEnabled ||
      prevData.reasonCode !== data.reasonCode
    ) {
      if (isOpenedPanel && data.isEnabled) {
        this.setState({ isOpenedPanel: true });
      }

      Tooltip.hide();
    }

    if (prevData && !prevData.isEnabled && data.isEnabled) {
      this.setState({ isOpenedPanel: false });
    }

    Tooltip.rebuild();
  }

  onSubmit = async (amount: string) => {
    const { type, projectId, transactionId, operationId, getTranslate } =
      this.props;

    this.setState({ isSending: true });

    const request = () => {
      switch (type) {
        case 'cancel':
          return paymentCancel;
        case 'capture':
          return paymentCapture;
        case 'refund':
          return paymentRefund;
        default:
          return () => {
            console.error(
              `Error in getting request for ${type} payment action`
            );
          };
      }
    };

    try {
      await request()(
        pickBy({
          projectId,
          transactionId,
          operationId,
          amount,
        })
      );
    } catch (err: any) {
      const { payload } = err;
      const { validationErrors } = payload || {};

      if (validationErrors && Object.values(validationErrors).length) {
        showNotification({
          status: 'error',
          content: getTranslate(Object.values(validationErrors)[0] as string),
        });
      }
    } finally {
      this.setState({
        isSending: false,
        isOpenedPanel: false,
      });
    }
  };

  togglePanel = (isOpened: boolean) => {
    this.setState({
      isOpenedPanel: isOpened,
    });
  };

  render() {
    const {
      data,
      type,
      projectId,
      transactionId,
      tooltip,
      customClass,
      buttonTheme,
      buttonType,
      selectorPortal,
    } = this.props;
    const { isOpenedPanel, isSending } = this.state;

    if (
      !data ||
      (!data.isEnabled &&
        data.reasonCode !== OPERATION_CODES.REASON_PAYMENT_PROCESSING &&
        type !== 'refund')
    ) {
      return null;
    }

    return (
      <Action
        type={type}
        data={data}
        projectId={projectId}
        transactionId={transactionId}
        customClass={customClass}
        isSending={isSending}
        isOpenedPanel={isOpenedPanel}
        onSubmit={this.onSubmit}
        togglePanel={this.togglePanel}
        tooltip={tooltip}
        buttonTheme={buttonTheme}
        buttonType={buttonType}
        isMenuExpanded={this.props.isMenuExpanded}
        selectorPortal={selectorPortal}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): ConnectedProps => ({
  isMenuExpanded: state.configuration?.isMenuExpanded || false,
  data: state.paymentActions[ownProps.transactionId]?.[ownProps.type],
});

export default connect(mapStateToProps)(addTranslation(ActionContainer));
