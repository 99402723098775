import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { getPaymentByProject } from 'api/payment';
import { WithRouterProps } from 'decorators/withRouter';
import { getPayment } from 'actions/payment';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import getPaymentById from 'selectors/getPaymentById';
import UiStateFactory from 'factories/UiStateFactory';
import SavedPaymentsItem from 'types/savedEntity/SavedPayment';
import savedEntities from 'constants/savedEntities';
import SavedEntity from 'types/savedEntity';
import PaymentCard from 'components/paymentCard/payment';
import { StoreProps } from 'store';

export interface Config {
  isCustomerInfo: boolean;
  isAddToList: boolean;
  isManagePayment: boolean;
  isRemittanceRecipientAccount: boolean;
}

interface OwnProps {
  id: string;
  savedEntity: keyof typeof savedEntities;
  apiRequest?: any;
  config?: Config;
}

interface ConnectedProps {
  user: any;
  payment: SavedPaymentsItem;
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  isLoading: boolean;
}

class PaymentCardContainer extends Component<Props, State> {
  static defaultProps: Readonly<Partial<OwnProps>> = {
    config: {
      isCustomerInfo: true,
      isAddToList: true,
      isManagePayment: true,
      isRemittanceRecipientAccount: false,
    },
  };

  constructor(props: Props) {
    super(props);
    const isFetched = props.payment ? props.payment.isFetch : false;
    this.state = {
      isLoading: !isFetched && !props.payment?.data,
    };
  }

  async componentDidMount() {
    await this.init();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { user, storedSavedEntities } = this.props;

    if (user && prevProps.user.timezone !== user.timezone) {
      this.fetchPayment();
    }

    if (!prevProps.storedSavedEntities && storedSavedEntities) {
      await this.init();
    }

    if (prevProps.id !== this.props.id) {
      const isFetched = this.props.payment ? this.props.payment.isFetch : false;
      this.setState({
        isLoading: !isFetched && !this.props.payment?.data,
      });
      await this.init();
    }
  }

  render() {
    const { payment, config } = this.props;

    return (
      <PaymentCard
        payment={payment}
        config={config}
        isLoading={this.state.isLoading}
        syncPaymentUI={this.syncPaymentUIWithStore}
        fetchData={this.fetchPayment}
      />
    );
  }

  /**
   * Добавление карточки в сохраненные страницы, инициализация IU,
   * получения информации о платеже с сервера
   */
  async init() {
    const {
      id,
      payment,
      savedEntity,
      location,
      storedSavedEntities,
      dispatch,
    } = this.props;

    if (!storedSavedEntities) return;

    const urlParams = queryString.parse(location.search);
    const actionParams: any = {};
    if (urlParams.name) {
      actionParams.name = urlParams.name;
    }
    if (urlParams.type) {
      actionParams.type = urlParams.type;
    }
    if (urlParams.projectId) {
      actionParams.projectId = urlParams.projectId;
    }
    if (!payment) {
      const entityKey = savedEntities[savedEntity];
      dispatch(addEntityToSaved({ entityKey, id, urlParams: actionParams }));
    }

    if (!payment || (payment && !payment.data)) {
      const ui = UiStateFactory.getInitialPageUI();
      this.syncPaymentUIWithStore(id, ui);
      this.fetchPayment();
    }
  }

  fetchPayment = () => {
    const { id, savedEntity, apiRequest, dispatch, location } = this.props;
    this.setState({ isLoading: true });
    const { projectId, name } = queryString.parse(location.search);

    if (
      projectId &&
      typeof projectId === 'string' &&
      name &&
      typeof name === 'string'
    ) {
      dispatch(
        getPayment(id, savedEntity, this.toggleOffLoading, () =>
          getPaymentByProject({ projectId, paymentId: name })
        )
      );
    } else {
      dispatch(
        getPayment(
          id,
          savedEntity,
          () => {
            this.setState({ isLoading: false });
          },
          apiRequest
        )
      );
    }
  };

  toggleOffLoading = () => {
    this.setState({ isLoading: false });
  };

  /**
   * Сохранить состояние скроллов, табов и т.д в сторе
   * @param id
   * @param ui
   */
  syncPaymentUIWithStore = (id, ui) => {
    const { savedEntity, dispatch } = this.props;
    const entityKey = savedEntities[savedEntity];

    dispatch(updateEntity({ entityKey, id, fields: { ui } }));
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  user: state.user,
  payment: getPaymentById(
    state,
    savedEntities[ownProps.savedEntity],
    ownProps.id
  ),
  storedSavedEntities: state.savedEntities[ownProps.savedEntity],
});

export default withRouter(connect(mapStateToProps)(PaymentCardContainer));
