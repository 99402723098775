import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';

import { applyConfiguration } from 'actions/configuration';
import api from 'api/dictionaries';
import Button from 'components/ui/button';
import CustomScrollbar from 'components/ui/customScrollbar';
import Configuration from './Configuration';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import ItemConfiguration from 'types/ItemConfiguration';
import I18nHelpers from 'i18n/I18nHelpers';

interface OwnProps {
  onClose: any;
  name: string;
}

interface ConnectedProps {
  columns: ItemConfiguration[];
}

type Props = OwnProps & ConnectedProps & IntlProps & StoreProps;

interface State {
  columns: ItemConfiguration[];
}

class ConfigurationContainer extends Component<Props, State> {
  private readonly sortedRequired;

  constructor(props: Props) {
    super(props);
    this.sortedRequired = this.sortByActive(props.columns);

    this.state = {
      columns: this.sortedRequired,
    };
  }

  render() {
    const { columns } = this.state;
    const { getTranslate } = this.props;

    return (
      <>
        <div className='configuration'>
          <CustomScrollbar>
            <div className='configuration__container'>
              <div className='configuration__note'>
                {I18nHelpers.getJsx(getTranslate('configuration.tipText'))}
              </div>
              <Button
                status='outline'
                customClass='ui-button_full-width configuration__button'
                onClick={this.resetConfiguration}
                text={getTranslate('configuration.resetButton')}
              />
            </div>
            <hr className='utils-separator configuration__separator' />
            <Configuration
              configuration={columns}
              getConfiguration={this.changeConfiguration}
            />
          </CustomScrollbar>
        </div>

        <div className='configuration__footer'>
          <Button
            customClass='ui-button_full-width'
            status='primary'
            text={getTranslate('configuration.button')}
            disabled={!this.hasChanges()}
            onClick={this.sendConfigurations}
          />
        </div>
      </>
    );
  }

  sortByActive = (list) => {
    const activeGroup: ItemConfiguration[] = [];
    const disActiveGroup: ItemConfiguration[] = [];

    list.forEach((item) => {
      if (item.active) {
        activeGroup.push(item);
      } else {
        disActiveGroup.push(item);
      }
    });

    return [...activeGroup, ...disActiveGroup];
  };

  changeConfiguration = (config: ItemConfiguration[]) => {
    this.setState((state) => {
      return {
        ...state,
        columns: config,
      };
    });
  };

  sendConfigurations = () => {
    const { dispatch, onClose, name } = this.props;
    if (isNotAvailableForSupport(Messages.UserConfig_Set)) return false;

    onClose();
    // waiting close panel
    setTimeout(() => {
      dispatch(applyConfiguration(this.state.columns, name));
    }, 500);
  };

  resetConfiguration = () => {
    const { name } = this.props;
    api.getTableColumns(true).then((list) => {
      this.setState({ columns: list[name] });
    });
  };

  hasChanges = () => {
    const { columns } = this.state;
    return !isEqual(this.sortedRequired, columns);
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  return {
    columns: state.configuration[ownProps.name],
  };
};

export default connect(mapStateToProps)(addTranslation(ConfigurationContainer));
