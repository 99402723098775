import React, { PureComponent } from 'react';
import classNames from 'classnames';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Header, Row } from 'components/ui/table';
import Checkbox from 'components/ui/checkbox';
import Utils from 'helpers/Utils';
import { AnyObject } from 'types/Common';
import { currency } from '../../constants';
import tableNames from 'constants/tableNames';
import './reconciliationSection.scss';

interface Props {
  tableName: string;
  data: any;
  isLoading: boolean;
  isUserCanEdit: boolean;
  configuration: AnyObject;
  changeData: (section, rowId, colName, value, isOver?) => void;
}

class DataSection extends PureComponent<Props> {
  render() {
    const { data, isLoading } = this.props;
    return (
      <div className='reconciliation-section'>
        <DynamicTable
          isListView
          resizableColumns={false}
          tableName={tableNames.reconciliation}
          items={data}
          rowRender={this.rowRender}
          headerRender={this.renderTableHeader()}
          isFetch={isLoading}
          customClass='reconciliation-section__item'
        />
      </div>
    );
  }

  rowRender = (data) => {
    const { configuration, changeData, isUserCanEdit, tableName } = this.props;
    return (
      <Row
        key={data.id}
        customClass={classNames({
          'reconciliation-section__row-lighted': data.safeguarded,
        })}>
        {configuration.map((col, i) => {
          const { id } = col;
          if (['date', 'bank_name'].includes(id) || col.params?.isEditable) {
            return (
              <Column
                key={id}
                content={
                  isNaN(parseFloat(data[id]))
                    ? data[id]
                    : Utils.getNumberWithSpace(data[id])
                }
                id={id}
                modifier={id}
                params={
                  !data.totals && {
                    isEditable: isUserCanEdit,
                    edit: {
                      canEdit: isUserCanEdit,
                      type: currency.includes(id) ? 'amount' : '',
                      isForbiddenEdit: !isUserCanEdit,
                      onChange: (value) => {
                        changeData(
                          tableName,
                          data.bank_name || data.id,
                          id,
                          value
                        );
                      },
                      onTableInputBlur: (value) => {
                        changeData(
                          tableName,
                          data.bank_name || data.id,
                          id,
                          value,
                          true
                        );
                      },
                    },
                  }
                }
              />
            );
          }
          let content = data[id];
          if (['bank', 'service_provider', 'safeguarded'].includes(id)) {
            content = (
              <Checkbox
                id={`checkbox-${id}-${i}`}
                disabled={true}
                checked={data[id] || false}
              />
            );
          }
          return <Column key={id} content={content} id={id} modifier={id} />;
        })}
      </Row>
    );
  };

  renderTableHeader = () => {
    const { configuration } = this.props;
    return (
      <Header>
        {configuration.map((col) => {
          return (
            <Column
              key={col.id}
              id={col.id}
              modifier={col.id}
              content={col.localId}
            />
          );
        })}
      </Header>
    );
  };
}

export default DataSection;
