import { useEffect } from 'react';
import Messages from 'constants/rpcTypes';
import EventManager from 'helpers/EventManager';

export const useEventListener = (
  payload: {
    events: Messages[];
    onEvent: (name: string, data: any) => void;
  },
  deps: React.DependencyList
) => {
  useEffect(() => {
    const { events, onEvent } = payload;
    const subscribeIds: string[] = [];

    events.forEach((name) => {
      const id = EventManager.subscribe(name, onEvent);
      subscribeIds.push(id);
    });

    return () => {
      subscribeIds.forEach((id) => {
        EventManager.unsubscribe(id);
      });
    };
    // eslint-disable-next-line
  }, deps);
};
