import React, { Component } from 'react';
import { connect } from 'react-redux';

import HelpPage from './HelpPage';
import { resetHelp } from 'actions/help';
import { StoreProps } from 'store';

interface ConnectedProps {
  isFetched: boolean;
}

type Props = ConnectedProps & StoreProps;

class HelpContainer extends Component<Props> {
  render() {
    const { isFetched } = this.props;
    return <HelpPage isFetched={isFetched} />;
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetHelp());
  }
}

const mapStateToProps = (state) => ({
  isFetched: state.help.isFetched,
});

export default connect(mapStateToProps)(HelpContainer);
