import { openModal } from 'actions/modal';
import { store } from 'store';

export const askUserToProvideAccountingData = () =>
  new Promise((resolve) => {
    store.dispatch(
      openModal({
        modalId: 'MafConfirm',
        needCloseButton: false,
        content: {
          title: 'Are you ready to provide financial data?',
          text: 'By clicking: “Yes, provide” I agree that my data will be processed by Ecommpay.',
          buttonYes: 'Yes, Provide',
          buttonNo: 'Cancel',
        },
        callback: resolve,
      })
    );
  });
