import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import {
  IDictionary,
  IPaymentSetting,
} from 'pages/payments/createFiscalReceipt/types';
import { RootState } from 'store';
import { useTranslation } from 'hooks';
import Utils from 'helpers/Utils';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import Form from 'components/ui/form/Form';

interface Props {
  content: IPaymentSetting & { availableAmount: number };
  callback: (data: IPaymentSetting) => void;
}

const FiscalReceiptPaymentSettings: React.FC<Props> = ({
  content,
  callback,
}) => {
  const getTranslate = useTranslation();
  const [availableAmount, setAvailableAmount] = useState(0);
  const [values, setValues] = useState<IPaymentSetting>({
    id: uuid(),
    paymentType: null,
    amount: '',
  });

  const paymentType = useSelector((state: RootState) => {
    const data = state.filtersValues?.CashVoucherPaymentType as
      | IDictionary
      | undefined;

    return {
      list:
        data?.list?.map(({ id, text }) => ({ value: id, label: text })) || [],
      isLoading: data?.isLoading || false,
    };
  });

  const isAmountLimitValid =
    availableAmount >= Number(Utils.getNumberWithoutSpace(values.amount));
  const isFormValid =
    Boolean(values.amount && values.paymentType) && isAmountLimitValid;

  useEffect(() => {
    const formattedValues = omit(content, 'availableAmount');
    const isEdit = !isEmpty(formattedValues);
    const { availableAmount } = content;

    if (isEdit) {
      setValues(formattedValues);
      setAvailableAmount(availableAmount + Number(formattedValues.amount));
      return;
    }

    setAvailableAmount(availableAmount);
  }, [content]);

  const handleChange = (values: Partial<IPaymentSetting>) => {
    setValues((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const handleAdd = () => {
    callback(values);
  };

  return (
    <Form customClass='ui-form-fields__form' onSubmit={handleAdd}>
      <ModalTitle>
        {getTranslate('fiscalReceipt.form.PaymentSettings.label')}
      </ModalTitle>
      <ModalContent>
        <div className='modal__content-item'>
          <CustomSelect
            id='paymentType'
            modern
            isRequired
            placeholder={getTranslate('fiscalReceipt.detCard.PayType.label')}
            label={getTranslate('fiscalReceipt.detCard.PayType.label')}
            isLoading={paymentType.isLoading}
            options={paymentType.list}
            value={paymentType.list.find(
              ({ value }) => value === values.paymentType
            )}
            onChange={({ value }) => {
              handleChange({
                paymentType: value,
              });
            }}
          />
        </div>
        <div className='modal__content-item'>
          <InputAmount
            id='amount'
            modern
            isRequired
            label={getTranslate('fiscalReceipt.detCard.Amount.label')}
            placeholder='0.00'
            tooltip={getTranslate('fiscalReceipt.detCard.Amount.tooltip')}
            error={
              !isAmountLimitValid
                ? getTranslate('payments.single.refundAmountBigger')
                : ''
            }
            theme=''
            value={values.amount}
            onChange={(e) => {
              handleChange({
                amount: e.target.value,
              });
            }}
          />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={!isFormValid}
          text={getTranslate('common.add.button')}
          status='primary'
          type='submit'
        />
      </ModalFooter>
    </Form>
  );
};

export { FiscalReceiptPaymentSettings };
