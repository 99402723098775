import React from 'react';
import classNames from 'classnames';
import { IntlProps } from 'decorators/addTranslation';

import Icon from 'components/ui/icon';
import PageTemplate from 'components/pageTemplate';
import CustomScrollbar from 'components/ui/customScrollbar';
import Grid from 'components/grid';
import Link from 'components/ui/link';
import Card from './components/card';
import CredentialsCard from './components/credentialsCard';
import { NonSddWidgets } from './components/nonSddWidgets';
import Loader from 'components/ui/loader';

import { DocLinksList } from 'types/Api';
import { BoardingStage } from 'types/BoardingStage';
import Utils from 'helpers/Utils';
import { OnboardingInformer } from './components/OnboardingInformer';
import { pageImages } from './helpers/pageImages';

interface Props extends IntlProps {
  boardingStage: BoardingStage;
  docLinks?: DocLinksList;
  toTechDocs: (url: string) => void;
  limits: { text: string; icon: string; current: number; max: number }[];
  isLoadingLimits: boolean;
  displayOnboardingInformer: boolean | null;
}

const firstSteps = {
  id: 'firstStep',
  label: 'getStarted.firstSteps.label',
  items: [
    {
      id: 'testCards',
      image: 'cards',
      label: 'getStarted.firstSteps.testCards.label',
      text: 'getStarted.firstSteps.testCards.text',
      link: 'test_cards',
    },
    {
      id: 'manualPayments',
      image: 'wallet',
      label: 'getStarted.firstSteps.manualPayments.label',
      text: 'getStarted.firstSteps.manualPayments.text',
      link: '/manual',
    },
    {
      id: 'paymentTypes',
      image: 'docs',
      label: 'getStarted.firstSteps.paymentTypes.label',
      text: 'getStarted.firstSteps.paymentTypes.text',
      link: '/payments',
    },
  ],
};

const technicalDocumentation = {
  id: 'technicalDocumentation',
  label: 'getStarted.documetation.label',
  items: [
    {
      id: 'generalDocumentation',
      image: 'im-Edit',
      label: 'getStarted.documetation.generalDocumentation.label',
      text: 'getStarted.documetation.generalDocumentation.text',
      link: 'general',
    },
    {
      id: 'api',
      image: 'im-List',
      label: 'getStarted.documetation.API.label',
      text: 'getStarted.documetation.API.text',
      link: 'api_reference',
    },
    {
      id: 'pp',
      image: 'im-Browser',
      label: 'getStarted.documetation.PP.label',
      text: 'getStarted.documetation.PP.text',
      link: 'payment_page_integration',
    },
    {
      id: 'payoutsAPI',
      image: 'im-Code',
      label: 'getStarted.documetation.payoutsAPI.label',
      text: 'getStarted.documetation.payoutsAPI.text',
      link: 'payouts',
    },
  ],
};

class GetStartedPage extends React.PureComponent<Props> {
  render() {
    return (
      <CustomScrollbar>
        {this.props.displayOnboardingInformer && <OnboardingInformer />}
        <div className='get-started'>
          <PageTemplate.Container>
            {this.renderHeadCards()}
            {this.renderCardsBlock({
              id: 'testMode',
              items: this.props.limits,
              label: 'getStarted.testMode.label',
            })}
            {this.renderCardsBlock(firstSteps)}
            {this.renderCardsBlock(technicalDocumentation)}
          </PageTemplate.Container>
        </div>
      </CustomScrollbar>
    );
  }

  renderHeadCards = () => {
    let credentialsCardWidth: 4 | 5;
    let leftBlock: React.ReactNode;

    switch (this.props.boardingStage) {
      case 'onboarding_draft':
      case 'onboarding_fill':
      case 'onboarding_review':
        credentialsCardWidth = 4;
        leftBlock = <NonSddWidgets boardingStage={this.props.boardingStage} />;
        break;

      default:
        // if there is no boarding stage, then we are in the process of creating a new account
        leftBlock = <NonSddWidgets boardingStage={'onboarding_draft'} />;
        credentialsCardWidth = 4;
    }

    return (
      <Grid align='flex-start'>
        {leftBlock}
        <CredentialsCard width={credentialsCardWidth} />
      </Grid>
    );
  };

  renderCardsBlock = (cardBlockData) => {
    const { getTranslate } = this.props;
    const cardSize = cardBlockData.id === 'testMode' ? 's' : 'md';

    return (
      <Grid
        spacing='s'
        direction='column'
        className={classNames(
          'cards-block',
          `cards-block__${cardBlockData.id}`
        )}>
        <h1>{getTranslate(cardBlockData.label)}</h1>
        <Grid spacing='s'>
          {cardBlockData.items.map((item) => {
            return (
              <Card
                key={item.id || Utils.getHash()}
                size={cardSize}
                className={classNames(
                  'cards-block__item',
                  item.id,
                  'utils-flex'
                )}>
                {this.renderCardBlockItem(cardBlockData.id, item)}
              </Card>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  renderCardBlockItem = (id, data) => {
    const { getTranslate, docLinks, toTechDocs, isLoadingLimits } = this.props;
    switch (id) {
      case 'testMode': {
        if (isLoadingLimits) {
          return <Loader type='bung' />;
        }

        return (
          <>
            <div className='cards-block__icon-box'>
              <Icon name={data.icon} size={18} />
            </div>
            <div className='cards-block__content'>
              <h1>
                <b>
                  {data.current}/{data.max}
                </b>
              </h1>
              <p>{getTranslate(data.text)}</p>
            </div>
          </>
        );
      }
      case 'firstStep':
        return (
          <Grid direction='column' spacing='s'>
            {data.id === 'testCards' ? (
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={docLinks?.[data.link]}
                className='cards-block__doc-icon-link'>
                <Icon name='im-External-Link' size={14} />
              </a>
            ) : (
              <Link className='cards-block__doc-icon-link' url={data.link}>
                <Icon name='im-Arrow-right-Option-2' size={14} />
              </Link>
            )}
            <div className='cards-block__image-box'>
              <img alt='#' src={pageImages[data.image]} />
            </div>
            <h2>{getTranslate(data.label)}</h2>
            <p>{getTranslate(data.text)}</p>
          </Grid>
        );
      case 'technicalDocumentation':
        return (
          <Grid direction='column' spacing='m' flex={1}>
            <Grid direction='column' spacing='s'>
              <div className='cards-block__image-box'>
                <Icon name={data.image} size={21} />
              </div>
              <h2>{getTranslate(data.label)}</h2>
              <p>{getTranslate(data.text)}</p>
            </Grid>

            <Grid
              spacing='xxs'
              align='center'
              flex={1}
              className='cards-block__footer-doc-link'
              onClick={() => toTechDocs(docLinks?.[data.link])}>
              <span className='utils-link'>
                <b>
                  {getTranslate(
                    'getStarted.documetation.showDocumentation.button'
                  )}
                </b>
              </span>
              <Icon name='im-Arrow-right-Option-2' size={10} />
            </Grid>
          </Grid>
        );
    }
  };
}

export default GetStartedPage;
