export const availableRoles = {
  isChargebackMailingEnabled: ['merchant_admin', 'risk'],
  isFinancialReportMailingEnabled: [
    'internal_admin',
    'internal_support',
    'merchant_admin',
    'finance',
  ],
  isFraudMailingEnabled: [
    'internal_admin',
    'internal_support',
    'merchant_admin',
    'risk',
    'internal_general_admin',
  ],
};

export const requiredPermissions = {
  isChargebackMailingEnabled: 'chargeback_manage',
  isFinancialReportMailingEnabled: 'financial_report_view',
  isFraudMailingEnabled: 'rcs_view',
};

export const MAX_AVAILABLE_MERCHANTS = 500;
