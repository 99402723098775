import React from 'react';
import { useTranslation } from 'hooks';
import Button from 'components/ui/button';
import { MafContainerProps } from 'components/maf/types/props';
import './mafActions.scss';
import { useMafPagesToolkit } from 'components/maf/components/PagesTabManager/hooks';

interface Props
  extends Pick<
    MafContainerProps,
    | 'onCloseModalTab'
    | 'onCommit'
    | 'onUpdate'
    | 'canCommit'
    | 'canUpdate'
    | 'onBackAndCancelStep'
  > {
  isOverviewStep: boolean;
  isFirstStep: boolean;
  isModalMode: boolean;
  isPageTabMode: boolean;
  isCurrentPageFirst?: boolean;
  isCurrentPageLast?: boolean;
  goToNextPage?: () => void;
  goToPreviousPage?: () => void;
}

const MafActions: React.FC<Props> = ({
  canCommit,
  isOverviewStep,
  isFirstStep,
  canUpdate,
  isModalMode,
  isCurrentPageFirst,
  isCurrentPageLast,
  isPageTabMode,
  onBackAndCancelStep,
  onCloseModalTab,
  onCommit,
  onUpdate,
}) => {
  const getTranslate = useTranslation();
  const { goToNextPage, goToPreviousPage } = useMafPagesToolkit();

  const getOnClick = () => {
    if (isPageTabMode && !isCurrentPageFirst) {
      return goToPreviousPage;
    }

    if (isModalMode) {
      return onCloseModalTab;
    }

    return onBackAndCancelStep;
  };

  const getButtonText = () => {
    if (isPageTabMode && !isCurrentPageFirst) {
      return getTranslate('common.back.button');
    }

    if (isModalMode) {
      return getTranslate('common.cancel.button');
    }

    return getTranslate('common.back.button');
  };

  return (
    <div className='form-control-buttons'>
      <div>
        {!isFirstStep && (
          <Button
            id='goBack'
            status='outline'
            disabled={!canUpdate}
            tooltip={
              canUpdate
                ? undefined
                : getTranslate('businessdocs.DAF.button.back.tooltip')
            }
            text={getButtonText()}
            onClick={getOnClick()}
          />
        )}
      </div>
      <div className='form-control-buttons__save-buttons'>
        {isOverviewStep ? (
          <Button
            id='submitMaf'
            status='primary'
            text={getTranslate('businessdocs.DAF.button.send')}
            disabled={!canCommit}
            onClick={onCommit}
            tooltip={
              canUpdate
                ? undefined
                : getTranslate('businessdocs.DAF.button.back.tooltip')
            }
          />
        ) : (
          <Button
            id='saveMaf'
            status='primary'
            disabled={!canUpdate}
            text={
              isPageTabMode && !isCurrentPageLast
                ? getTranslate('businessdocs.DAF.button.next')
                : getTranslate('businessdocs.DAF.button.save')
            }
            onClick={
              isPageTabMode && !isCurrentPageLast ? goToNextPage : onUpdate
            }
            tooltip={
              canUpdate
                ? undefined
                : getTranslate('businessdocs.DAF.button.back.tooltip')
            }
          />
        )}
      </div>
    </div>
  );
};

export { MafActions };
