import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { createReference } from 'api/references';
import Button from 'components/ui/button';
import { addListeners, IListeners } from 'decorators/addListeners';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import './buttonReference.scss';

interface OwnProps {
  transactionId: string;
  operationId: string;
  customClass?: string;
}

interface ConnectedProps {
  modal: AnyObject;
}

type Props = OwnProps & ConnectedProps & IntlProps;

interface State {
  isLoading: boolean;
}

@addListeners([Messages.PaymentOperationReference_Create])
class ButtonReference
  extends PureComponent<Props, State>
  implements IListeners
{
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { modal } = this.props;
    if (
      prevProps.modal.isOpened &&
      !modal.isOpened &&
      modal.modalId === 'Confirm'
    ) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { customClass, getTranslate } = this.props;
    const { isLoading } = this.state;

    return (
      <Button
        id='button-reference'
        size='normal'
        status='outline'
        onClick={() => this.create()}
        loading={isLoading}
        text={getTranslate('payments.reference.button')}
        customClass={classNames('button-payment-reference', customClass)}
      />
    );
  }

  async create() {
    const { transactionId, operationId } = this.props;

    if (isNotAvailableForSupport(Messages.PaymentOperationReference_Create))
      return false;

    this.setState({ isLoading: true });
    try {
      await createReference({ transactionId, operationId });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onEvent({ name }) {
    if (name === Messages.PaymentOperationReference_Create) {
      this.setState({
        isLoading: false,
      });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  modal: state.modal,
});

export default connect(mapStateToProps)(addTranslation(ButtonReference));
