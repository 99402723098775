import classNames from 'classnames';
import React, { PureComponent } from 'react';
import Loader from 'components/ui/loader';
import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';
import './teaser.scss';

interface Props {
  title: string;
  text: string | JSX.Element;
  status?: string;
  isLoading?: boolean;
}

class Teaser extends PureComponent<Props> {
  render() {
    const { status, title, text, children, isLoading } = this.props;

    return (
      <div
        className={classNames('ui-teaser', {
          'ui-teaser_loading': isLoading,
        })}>
        <div className='ui-teaser__content'>
          {status && (
            <div className='ui-teaser__status'>
              {isLoading ? (
                <Loader type='bung' />
              ) : (
                <LabelStatus
                  status={Mapper.getStatus(status?.toLowerCase())}
                  text={status}
                />
              )}
            </div>
          )}
          <div className='ui-teaser__title'>
            {isLoading ? <Loader type='bung' /> : title}
          </div>
          <div className='ui-teaser__text'>
            {isLoading ? <Loader type='bung' /> : text}
          </div>
        </div>
        <div className='ui-teaser__actions'>
          {isLoading ? <Loader type='bung' /> : children}
        </div>
      </div>
    );
  }
}

export default Teaser;
