import React from 'react';
import { IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import PhoneInput from 'components/ui/phoneInput';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import Switch from 'components/ui/switch';
import PairedFilter from 'pages/admin/components/pairedFilter';
import { FieldsType } from './FieldsConfig';
import { AdminUserFields, UserFields } from './UserFieldsInterface';
import { AnyObject } from 'types/Common';
import {
  availableRoles,
  MAX_AVAILABLE_MERCHANTS,
  requiredPermissions,
} from './constants';

interface Props extends IntlProps {
  updateFiltersValues: (key: string, value: AnyObject) => void;
  fieldsConfig: AnyObject[];
  filtersValues: AnyObject;
  isEditing: boolean;
  disableForm?: boolean;
  fields: UserFields | AdminUserFields;
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any, param?: string) => void;
  isChargebackMailingAvailable: boolean;
  isFinancialReportMailingAvailable: boolean;
  isFraudMailingAvailable: boolean;
  isAdmin: boolean;
}

export default class UserFormSection extends React.PureComponent<Props> {
  render() {
    const {
      fields,
      fieldsConfig,
      getTranslate,
      isEditing,
      validationErrors,
      onChangeField,
      disableForm,
      isAdmin,
    } = this.props;

    return (
      <>
        {fieldsConfig.map((configItem) => {
          const {
            type,
            labelId,
            name,
            placeholderId,
            disabled,
            valueProp,
            filterProp,
            connectedTo,
            ...restProps
          } = configItem;
          const id = name;
          const fieldDisabled = disableForm || disabled;
          switch (type) {
            case FieldsType.text: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <Input
                    {...restProps}
                    label={getTranslate(labelId)}
                    disabled={(isEditing && name === 'email') || fieldDisabled}
                    customClass='ui-form-fields__field'
                    placeholder={getTranslate(placeholderId)}
                    id={id}
                    value={fields[name]}
                    error={getTranslate(validationErrors[name])}
                    onChange={({ target }) => onChangeField(name, target.value)}
                    modern
                  />
                </div>
              );
            }
            case FieldsType.phone: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <PhoneInput
                    {...restProps}
                    selectedCountryCode={fields.contactPhoneCountry}
                    label={getTranslate(labelId)}
                    disabled={(isEditing && name === 'email') || fieldDisabled}
                    customClass='ui-form-fields__field'
                    placeholder={getTranslate(placeholderId)}
                    id={id}
                    value={fields[name]}
                    error={getTranslate(validationErrors[name])}
                    onChange={(_, { dialCountry, value }) =>
                      onChangeField(
                        name,
                        `${dialCountry.dial_code}${value}`,
                        dialCountry.code
                      )
                    }
                  />
                </div>
              );
            }
            case FieldsType.select: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <SelectionList
                    id={id}
                    customClass='ui-form-fields__field'
                    label={getTranslate(labelId)}
                    items={fields[name]}
                    isDisabled={fieldDisabled}
                    onChange={(items) => onChangeField(name, items)}
                    containerSelector='.ui-form-fields__content'
                    modern
                  />
                </div>
              );
            }
            case FieldsType.connectedFilters: {
              const { filtersValues, updateFiltersValues } = this.props;
              const isNotMerchant = filterProp !== 'merchantId';
              return (
                <div
                  className='ui-form-fields__item'
                  key={`user-builder-${labelId}`}>
                  <PairedFilter
                    label={getTranslate(labelId)}
                    isDisabled={fieldDisabled}
                    valueProp={valueProp!}
                    updateFiltersValues={updateFiltersValues}
                    onChangeFilter={onChangeField}
                    filtersValues={filtersValues}
                    filters={fields}
                    filterProp={filterProp}
                    connectedTo={connectedTo}
                    autonomous={!isNotMerchant}
                    cantAffect={isNotMerchant ? 'merchantId' : undefined}
                    containerSelector='.ui-form-fields__content'
                    isValidBeforeApply={(items) =>
                      this.isValidBeforeApply(isNotMerchant, items)
                    }
                    modern
                  />
                </div>
              );
            }
            case FieldsType.singleSelect: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <CustomSelect
                    id={id}
                    label={getTranslate(labelId)}
                    value={fields[name].value}
                    options={fields[name].list}
                    onChange={(item) =>
                      onChangeField(name, {
                        value: { value: item.value, label: item.label },
                        list: fields[name].list,
                      })
                    }
                    customClass='ui-form-fields__field ui-select_no-min-width'
                    modern
                  />
                </div>
              );
            }
            case FieldsType.switch: {
              const {
                isChargebackMailingAvailable,
                isFinancialReportMailingAvailable,
                isFraudMailingAvailable,
              } = this.props;

              if (
                (name === 'isChargebackMailingEnabled' &&
                  !isChargebackMailingAvailable) ||
                (name === 'isFinancialReportMailingEnabled' &&
                  !isFinancialReportMailingAvailable) ||
                (name === 'isFraudMailingEnabled' && !isFraudMailingAvailable)
              ) {
                return null;
              }

              const isEnabled = fields.roles.find((item) => {
                return (
                  availableRoles[name].some(
                    (role) => item.isSelected && role === item.id
                  ) &&
                  !fields.permissionsDisabled.includes(
                    requiredPermissions[name]
                  )
                );
              });

              if (isAdmin && !isEnabled) {
                return null;
              }

              return (
                <div
                  className='ui-form-fields__item ui-form-fields__item_switch'
                  key={id}>
                  <Switch
                    id={id}
                    checked={fields[name]}
                    disabled={
                      fieldDisabled ||
                      (name === 'isChargebackMailingEnabled' ||
                      name === 'isFinancialReportMailingEnabled' ||
                      name === 'isFraudMailingEnabled'
                        ? !isEnabled
                        : false)
                    }
                    text={getTranslate(labelId)}
                    customClass='ui-form-fields__field'
                    textPosition='right'
                    onChange={(e) => onChangeField(name, e.target.checked)}
                  />
                </div>
              );
            }
            default:
              return null;
          }
        })}
      </>
    );
  }

  isValidBeforeApply = (isNotMerchant, items) => {
    if (isNotMerchant) return false;
    return (
      items.filter((item) => item.isSelected).length > MAX_AVAILABLE_MERCHANTS
    );
  };
}
