import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { GetUserPayload } from 'types/Api';

export const getUsers = (payload: GetUserPayload) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.User_List,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({ ...data, items: data.rows || [], totalRows: Infinity }));
};

export const getUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Get,
        payload: { userId },
      },
    ],
  });
};

export const createUser = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Create,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const updateUser = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Update,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const updateUserBulk = <Payload>(payload) => {
  return new RpcService().request<Payload>({
    messages: [
      {
        type: RpcTypes.User_BulkUpdate,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const deleteUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Delete,
        payload: { userId },
      },
    ],
  });
};

export const disableUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Disable,
        payload: { userId },
      },
    ],
  });
};

export const enableUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_Enable,
        payload: { userId },
      },
    ],
  });
};
