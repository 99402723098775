import React, { PureComponent } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import I18nHelpers from 'i18n/I18nHelpers';
import Icon from 'components/ui/icon';
import { confirmationTypes } from '../types';
import './confirmSlider.scss';
import 'swiper/swiper-bundle.css';
import googleplayImage from 'images/social/googleplay.svg';
import appstoreImage from 'images/social/appstore.svg';
import googleAuthImage from 'images/google-auth.png';

import step4Image from './images/step-4.svg';
import step5Image from './images/step-5.svg';
import step6Image from './images/step-6.svg';

import step3 from './images/step-3.svg';
import step3qr from './images/step-3-qr.svg';

interface Props extends IntlProps {
  qrImageBase64: string;
  type: confirmationTypes;
}

class ConfirmSlider extends PureComponent<Props> {
  render() {
    const { type } = this.props;
    SwiperCore.use([Navigation, Pagination]);
    return (
      <div className='confirm-slider'>
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          loopedSlides={2}
          centeredSlides
          loop
          navigation={{
            prevEl: '.confirm-slider__arrow_prev',
            nextEl: '.confirm-slider__arrow_next',
          }}
          pagination={{
            clickable: true,
            el: '.confirm-slider__pagination',
            bulletClass: 'confirm-slider__bullet',
            bulletActiveClass: 'confirm-slider__bullet_active',
          }}>
          {/*1,2,3*/}
          {this.commonSteps}
          {type === confirmationTypes.SMSCode ? (
            <>
              {this.step4}
              {this.step5}
              {this.finalStep}
            </>
          ) : (
            <>
              {this.step4Qr}
              {this.finalStep}
            </>
          )}
        </Swiper>
        <div className='confirm-slider__navigation'>
          <button
            className='confirm-slider__arrow confirm-slider__arrow_prev'
            type='button'>
            <Icon name='im-Arrow-left-Option-2' size={12} />
          </button>
          <button
            className='confirm-slider__arrow confirm-slider__arrow_next'
            type='button'>
            <Icon name='im-Arrow-right-Option-2' size={12} />
          </button>
        </div>
        <div className='confirm-slider__pagination' />
      </div>
    );
  }

  getTextBlock(step) {
    return (
      <div className='confirm-slider__content'>
        <div className='confirm-slider__title'>
          {this.translate(step, 'header')}
        </div>
        <div className='confirm-slider__text'>
          {this.translate(step, 'text')}
        </div>
      </div>
    );
  }

  translate(step, part: 'text' | 'header') {
    const { getTranslate, type } = this.props;
    return I18nHelpers.getJsx(
      getTranslate(
        `auth.login.2faActivation.step${step}.${
          type === confirmationTypes.QRCode ? 'qr.' : ''
        }${part}`
      )
    );
  }

  get commonSteps() {
    const { type } = this.props;
    const isSmsType = type === confirmationTypes.SMSCode;
    return (
      <>
        <SwiperSlide className='confirm-slider__item'>
          <div className='confirm-slider__img'>
            <img
              src={googleplayImage}
              alt='googleplay'
              className='confirm-slider__pic confirm-slider__pic_store'
            />
            <img
              src={appstoreImage}
              alt='appstore'
              className='confirm-slider__pic confirm-slider__pic_store'
            />
          </div>
          {this.getTextBlock(1)}
        </SwiperSlide>
        <SwiperSlide className='confirm-slider__item'>
          <div className='confirm-slider__img'>
            <img
              src={googleAuthImage}
              alt='ga'
              className='confirm-slider__pic confirm-slider__pic_ga'
            />
          </div>
          {this.getTextBlock(2)}
        </SwiperSlide>
        <SwiperSlide className='confirm-slider__item'>
          <div className='confirm-slider__img'>
            <img
              src={isSmsType ? step3 : step3qr}
              alt=''
              className='confirm-slider__pic'
            />
          </div>
          {this.getTextBlock(3)}
        </SwiperSlide>
      </>
    );
  }

  get step4() {
    return (
      <SwiperSlide className='confirm-slider__item'>
        <div className='confirm-slider__img'>
          <img src={step4Image} alt='' className='confirm-slider__pic' />
        </div>
        {this.getTextBlock(4)}
      </SwiperSlide>
    );
  }

  get step5() {
    return (
      <SwiperSlide className='confirm-slider__item'>
        <div className='confirm-slider__img'>
          <img src={step5Image} alt='' className='confirm-slider__pic' />
        </div>
        {this.getTextBlock(5)}
      </SwiperSlide>
    );
  }

  get finalStep() {
    const isSms = this.props.type === confirmationTypes.SMSCode;
    return (
      <SwiperSlide className='confirm-slider__item'>
        <div className='confirm-slider__img'>
          <img src={step6Image} alt='' className='confirm-slider__pic' />
        </div>
        {this.getTextBlock(isSms ? 6 : 5)}
      </SwiperSlide>
    );
  }

  get step4Qr() {
    const { qrImageBase64 } = this.props;
    return (
      <SwiperSlide className='confirm-slider__item'>
        {this.getTextBlock(4)}
        <div className='confirm-slider__img confirm-slider__img_qr'>
          <img
            className='confirm-slider__pic'
            src={`data:image/gif;base64,${qrImageBase64}`}
            alt='auth-qr-code'
          />
        </div>
      </SwiperSlide>
    );
  }
}

export default addTranslation(ConfirmSlider);
