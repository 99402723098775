import { ErrorCallbackArgs } from '@codat/sdk-link-types';
import { CodatLink } from 'pages/accountingStatementRequests/components/CodatLink';

interface Props {
  callback: (data: any) => void;
  content: {
    companyId: string;
    onConnection: () => void;
    onError: (error: ErrorCallbackArgs) => void;
    onClose: () => void;
    onFinish: () => void;
  };
  invertButtons?: boolean;
}

export const CodatModal: React.FC<Props> = ({
  content: { companyId, onConnection, onError, onClose, onFinish },
}) => {
  return (
    <CodatLink
      companyId={companyId}
      onConnection={onConnection}
      onError={onError}
      onClose={onClose}
      onFinish={onFinish}
    />
  );
};
