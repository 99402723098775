import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import InfoIcon from '../infoIcon';
import './checkbox.scss';

interface Props extends IntlProps {
  id: string;
  checked: boolean;
  onChange?: (event: any) => void;
  containerRef?: { current: any };
  text?: string;
  disabled?: boolean;
  customClass?: string;
  tooltip?: string;
  isRequired?: boolean;
}

class Checkbox extends PureComponent<Props> {
  render() {
    const {
      id,
      text,
      checked,
      disabled,
      containerRef,
      onChange,
      customClass,
      tooltip,
      isRequired,
      getTranslate,
    } = this.props;

    return (
      <label
        ref={containerRef}
        className={classNames('ui-checkbox', customClass, {
          'ui-checkbox_checked': checked,
          'ui-checkbox_disabled': disabled,
        })}
        id={id}>
        <span className='ui-checkbox__wrapper'>
          <input
            className='ui-checkbox__input'
            type='checkbox'
            id={id}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <span className='ui-checkbox__inner'>
            <span className='ui-checkbox__icon'>
              <Icon name='im-Tick' size={8} />
            </span>
          </span>
        </span>
        {text && (
          <span title={text} className='ui-checkbox__text'>
            {text}
            {isRequired && (
              <span
                data-tip={getTranslate('common.requiredField.tooltip')}
                className='ui-checkbox__required'>
                *
              </span>
            )}
          </span>
        )}
        {tooltip && (
          <InfoIcon
            size={15}
            icon='im-Info'
            tooltip={tooltip}
            customClass='ui-checkbox__info'
          />
        )}
      </label>
    );
  }
}

export default addTranslation(Checkbox);
