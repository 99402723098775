import adFlag from 'images/flags/ad.svg';
import boFlag from 'images/flags/bo.svg';
import dmFlag from 'images/flags/dm.svg';
import gmFlag from 'images/flags/gm.svg';
import keFlag from 'images/flags/ke.svg';
import mkFlag from 'images/flags/mk.svg';
import nzFlag from 'images/flags/nz.svg';
import shFlag from 'images/flags/sh.svg';
import twFlag from 'images/flags/tw.svg';
import brFlag from 'images/flags/br.svg';
import aeFlag from 'images/flags/ae.svg';
import dzFlag from 'images/flags/dz.svg';
import gnFlag from 'images/flags/gn.svg';
import kgFlag from 'images/flags/kg.svg';
import mlFlag from 'images/flags/ml.svg';
import omFlag from 'images/flags/om.svg';
import siFlag from 'images/flags/si.svg';
import tzFlag from 'images/flags/tz.svg';
import afFlag from 'images/flags/af.svg';
import bsFlag from 'images/flags/bs.svg';
import ecFlag from 'images/flags/ec.svg';
import gpFlag from 'images/flags/gp.svg';
import khFlag from 'images/flags/kh.svg';
import mmFlag from 'images/flags/mm.svg';
import paFlag from 'images/flags/pa.svg';
import skFlag from 'images/flags/sk.svg';
import uaFlag from 'images/flags/ua.svg';
import btFlag from 'images/flags/bt.svg';
import eeFlag from 'images/flags/ee.svg';
import agFlag from 'images/flags/ag.svg';
import gqFlag from 'images/flags/gq.svg';
import kiFlag from 'images/flags/ki.svg';
import mnFlag from 'images/flags/mn.svg';
import peFlag from 'images/flags/pe.svg';
import slFlag from 'images/flags/sl.svg';
import ugFlag from 'images/flags/ug.svg';
import aiFlag from 'images/flags/ai.svg';
import bwFlag from 'images/flags/bw.svg';
import egFlag from 'images/flags/eg.svg';
import grFlag from 'images/flags/gr.svg';
import kmFlag from 'images/flags/km.svg';
import moFlag from 'images/flags/mo.svg';
import pfFlag from 'images/flags/pf.svg';
import smFlag from 'images/flags/sm.svg';
import ukFlag from 'images/flags/uk.svg';
import alFlag from 'images/flags/al.svg';
import byFlag from 'images/flags/by.svg';
import enFlag from 'images/flags/en.svg';
import gtFlag from 'images/flags/gt.svg';
import knFlag from 'images/flags/kn.svg';
import mpFlag from 'images/flags/mp.svg';
import pgFlag from 'images/flags/pg.svg';
import snFlag from 'images/flags/sn.svg';
import umFlag from 'images/flags/um.svg';
import amFlag from 'images/flags/am.svg';
import bzFlag from 'images/flags/bz.svg';
import erFlag from 'images/flags/er.svg';
import guFlag from 'images/flags/gu.svg';
import kpFlag from 'images/flags/kp.svg';
import mqFlag from 'images/flags/mq.svg';
import phFlag from 'images/flags/ph.svg';
import soFlag from 'images/flags/so.svg';
import undefinedFlag from 'images/flags/undefined.svg';
import anFlag from 'images/flags/an.svg';
import caFlag from 'images/flags/ca.svg';
import esFlag from 'images/flags/es.svg';
import gwFlag from 'images/flags/gw.svg';
import krFlag from 'images/flags/kr.svg';
import mrFlag from 'images/flags/mr.svg';
import pkFlag from 'images/flags/pk.svg';
import srFlag from 'images/flags/sr.svg';
import usFlag from 'images/flags/us.svg';
import aoFlag from 'images/flags/ao.svg';
import ccFlag from 'images/flags/cc.svg';
import etFlag from 'images/flags/et.svg';
import hkFlag from 'images/flags/hk.svg';
import kwFlag from 'images/flags/kw.svg';
import msFlag from 'images/flags/ms.svg';
import plFlag from 'images/flags/pl.svg';
import ssFlag from 'images/flags/ss.svg';
import uyFlag from 'images/flags/uy.svg';
import arFlag from 'images/flags/ar.svg';
import cdFlag from 'images/flags/cd.svg';
import euFlag from 'images/flags/eu.svg';
import hnFlag from 'images/flags/hn.svg';
import kzFlag from 'images/flags/kz.svg';
import mtFlag from 'images/flags/mt.svg';
import pnFlag from 'images/flags/pn.svg';
import stFlag from 'images/flags/st.svg';
import uzFlag from 'images/flags/uz.svg';
import asFlag from 'images/flags/as.svg';
import cfFlag from 'images/flags/cf.svg';
import fiFlag from 'images/flags/fi.svg';
import hrFlag from 'images/flags/hr.svg';
import laFlag from 'images/flags/la.svg';
import muFlag from 'images/flags/mu.svg';
import prFlag from 'images/flags/pr.svg';
import svFlag from 'images/flags/sv.svg';
import vcFlag from 'images/flags/vc.svg';
import atFlag from 'images/flags/at.svg';
import cgFlag from 'images/flags/cg.svg';
import fjFlag from 'images/flags/fj.svg';
import htFlag from 'images/flags/ht.svg';
import lbFlag from 'images/flags/lb.svg';
import mvFlag from 'images/flags/mv.svg';
import psFlag from 'images/flags/ps.svg';
import syFlag from 'images/flags/sy.svg';
import veFlag from 'images/flags/ve.svg';
import auFlag from 'images/flags/au.svg';
import chFlag from 'images/flags/ch.svg';
import fkFlag from 'images/flags/fk.svg';
import huFlag from 'images/flags/hu.svg';
import lcFlag from 'images/flags/lc.svg';
import mwFlag from 'images/flags/mw.svg';
import ptFlag from 'images/flags/pt.svg';
import szFlag from 'images/flags/sz.svg';
import vgFlag from 'images/flags/vg.svg';
import awFlag from 'images/flags/aw.svg';
import ckFlag from 'images/flags/ck.svg';
import idFlag from 'images/flags/id.svg';
import liFlag from 'images/flags/li.svg';
import mxFlag from 'images/flags/mx.svg';
import pwFlag from 'images/flags/pw.svg';
import tcFlag from 'images/flags/tc.svg';
import viFlag from 'images/flags/vi.svg';
import axFlag from 'images/flags/ax.svg';
import fmFlag from 'images/flags/fm.svg';
import clFlag from 'images/flags/cl.svg';
import ieFlag from 'images/flags/ie.svg';
import lkFlag from 'images/flags/lk.svg';
import myFlag from 'images/flags/my.svg';
import pyFlag from 'images/flags/py.svg';
import tdFlag from 'images/flags/td.svg';
import vnFlag from 'images/flags/vn.svg';
import azFlag from 'images/flags/az.svg';
import cmFlag from 'images/flags/cm.svg';
import foFlag from 'images/flags/fo.svg';
import ilFlag from 'images/flags/il.svg';
import lrFlag from 'images/flags/lr.svg';
import mzFlag from 'images/flags/mz.svg';
import qaFlag from 'images/flags/qa.svg';
import tfFlag from 'images/flags/tf.svg';
import vuFlag from 'images/flags/vu.svg';
import baFlag from 'images/flags/ba.svg';
import cnFlag from 'images/flags/cn.svg';
import frFlag from 'images/flags/fr.svg';
import imFlag from 'images/flags/im.svg';
import lsFlag from 'images/flags/ls.svg';
import naFlag from 'images/flags/na.svg';
import reFlag from 'images/flags/re.svg';
import tgFlag from 'images/flags/tg.svg';
import wsFlag from 'images/flags/ws.svg';
import bbFlag from 'images/flags/bb.svg';
import coFlag from 'images/flags/co.svg';
import fxFlag from 'images/flags/fx.svg';
import inFlag from 'images/flags/in.svg';
import ltFlag from 'images/flags/lt.svg';
import ncFlag from 'images/flags/nc.svg';
import roFlag from 'images/flags/ro.svg';
import thFlag from 'images/flags/th.svg';
import yeFlag from 'images/flags/ye.svg';
import bdFlag from 'images/flags/bd.svg';
import crFlag from 'images/flags/cr.svg';
import gaFlag from 'images/flags/ga.svg';
import ioFlag from 'images/flags/io.svg';
import luFlag from 'images/flags/lu.svg';
import neFlag from 'images/flags/ne.svg';
import rsFlag from 'images/flags/rs.svg';
import tjFlag from 'images/flags/tj.svg';
import ytFlag from 'images/flags/yt.svg';
import beFlag from 'images/flags/be.svg';
import cuFlag from 'images/flags/cu.svg';
import gbFlag from 'images/flags/gb.svg';
import iqFlag from 'images/flags/iq.svg';
import lvFlag from 'images/flags/lv.svg';
import nfFlag from 'images/flags/nf.svg';
import ruFlag from 'images/flags/ru.svg';
import tkFlag from 'images/flags/tk.svg';
import zaFlag from 'images/flags/za.svg';
import bfFlag from 'images/flags/bf.svg';
import cvFlag from 'images/flags/cv.svg';
import gdFlag from 'images/flags/gd.svg';
import irFlag from 'images/flags/ir.svg';
import lyFlag from 'images/flags/ly.svg';
import ngFlag from 'images/flags/ng.svg';
import rwFlag from 'images/flags/rw.svg';
import tlFlag from 'images/flags/tl.svg';
import zmFlag from 'images/flags/zm.svg';
import bgFlag from 'images/flags/bg.svg';
import cyFlag from 'images/flags/cy.svg';
import geFlag from 'images/flags/ge.svg';
import isFlag from 'images/flags/is.svg';
import maFlag from 'images/flags/ma.svg';
import niFlag from 'images/flags/ni.svg';
import saFlag from 'images/flags/sa.svg';
import tmFlag from 'images/flags/tm.svg';
import zwFlag from 'images/flags/zw.svg';
import bhFlag from 'images/flags/bh.svg';
import czFlag from 'images/flags/cz.svg';
import gfFlag from 'images/flags/gf.svg';
import itFlag from 'images/flags/it.svg';
import mcFlag from 'images/flags/mc.svg';
import nlFlag from 'images/flags/nl.svg';
import sbFlag from 'images/flags/sb.svg';
import tnFlag from 'images/flags/tn.svg';
import biFlag from 'images/flags/bi.svg';
import deFlag from 'images/flags/de.svg';
import ggFlag from 'images/flags/gg.svg';
import jeFlag from 'images/flags/je.svg';
import mdFlag from 'images/flags/md.svg';
import noFlag from 'images/flags/no.svg';
import scFlag from 'images/flags/sc.svg';
import toFlag from 'images/flags/to.svg';
import bjFlag from 'images/flags/bj.svg';
import defaultFlag from 'images/flags/defaultFlag.svg';
import ghFlag from 'images/flags/gh.svg';
import jmFlag from 'images/flags/jm.svg';
import meFlag from 'images/flags/me.svg';
import npFlag from 'images/flags/np.svg';
import sdFlag from 'images/flags/sd.svg';
import trFlag from 'images/flags/tr.svg';
import bmFlag from 'images/flags/bm.svg';
import djFlag from 'images/flags/dj.svg';
import giFlag from 'images/flags/gi.svg';
import joFlag from 'images/flags/jo.svg';
import mgFlag from 'images/flags/mg.svg';
import nrFlag from 'images/flags/nr.svg';
import seFlag from 'images/flags/se.svg';
import ttFlag from 'images/flags/tt.svg';
import bnFlag from 'images/flags/bn.svg';
import dkFlag from 'images/flags/dk.svg';
import glFlag from 'images/flags/gl.svg';
import jpFlag from 'images/flags/jp.svg';
import mhFlag from 'images/flags/mh.svg';
import nuFlag from 'images/flags/nu.svg';
import sgFlag from 'images/flags/sg.svg';
import tvFlag from 'images/flags/tv.svg';


export const flagIcons = {
  ad: adFlag,
  bo: boFlag,
  dm: dmFlag,
  gm: gmFlag,
  ke: keFlag,
  mk: mkFlag,
  nz: nzFlag,
  sh: shFlag,
  tw: twFlag,
  br: brFlag,
  ae: aeFlag,
  dz: dzFlag,
  gn: gnFlag,
  kg: kgFlag,
  ml: mlFlag,
  om: omFlag,
  si: siFlag,
  tz: tzFlag,
  af: afFlag,
  bs: bsFlag,
  ec: ecFlag,
  gp: gpFlag,
  kh: khFlag,
  mm: mmFlag,
  pa: paFlag,
  sk: skFlag,
  ua: uaFlag,
  bt: btFlag,
  ee: eeFlag,
  ag: agFlag,
  gq: gqFlag,
  ki: kiFlag,
  mn: mnFlag,
  pe: peFlag,
  sl: slFlag,
  ug: ugFlag,
  ai: aiFlag,
  bw: bwFlag,
  eg: egFlag,
  gr: grFlag,
  km: kmFlag,
  mo: moFlag,
  pf: pfFlag,
  sm: smFlag,
  uk: ukFlag,
  al: alFlag,
  by: byFlag,
  en: enFlag,
  gt: gtFlag,
  kn: knFlag,
  mp: mpFlag,
  pg: pgFlag,
  sn: snFlag,
  um: umFlag,
  am: amFlag,
  bz: bzFlag,
  er: erFlag,
  gu: guFlag,
  kp: kpFlag,
  mq: mqFlag,
  ph: phFlag,
  so: soFlag,
undefined: undefinedFlag,
  an: anFlag,
  ca: caFlag,
  es: esFlag,
  gw: gwFlag,
  kr: krFlag,
  mr: mrFlag,
  pk: pkFlag,
  sr: srFlag,
  us: usFlag,
  ao: aoFlag,
  cc: ccFlag,
  et: etFlag,
  hk: hkFlag,
  kw: kwFlag,
  ms: msFlag,
  pl: plFlag,
  ss: ssFlag,
  uy: uyFlag,
  ar: arFlag,
  cd: cdFlag,
  eu: euFlag,
  hn: hnFlag,
  kz: kzFlag,
  mt: mtFlag,
  pn: pnFlag,
  st: stFlag,
  uz: uzFlag,
  as: asFlag,
  cf: cfFlag,
  fi: fiFlag,
  hr: hrFlag,
  la: laFlag,
  mu: muFlag,
  pr: prFlag,
  sv: svFlag,
  vc: vcFlag,
  at: atFlag,
  cg: cgFlag,
  fj: fjFlag,
  ht: htFlag,
  lb: lbFlag,
  mv: mvFlag,
  ps: psFlag,
  sy: syFlag,
  ve: veFlag,
  au: auFlag,
  ch: chFlag,
  fk: fkFlag,
  hu: huFlag,
  lc: lcFlag,
  mw: mwFlag,
  pt: ptFlag,
  sz: szFlag,
  vg: vgFlag,
  aw: awFlag,
  ck: ckFlag,
  id: idFlag,
  li: liFlag,
  mx: mxFlag,
  pw: pwFlag,
  tc: tcFlag,
  vi: viFlag,
  ax: axFlag,
  fm: fmFlag,
  cl: clFlag,
  ie: ieFlag,
  lk: lkFlag,
  my: myFlag,
  py: pyFlag,
  td: tdFlag,
  vn: vnFlag,
  az: azFlag,
  cm: cmFlag,
  fo: foFlag,
  il: ilFlag,
  lr: lrFlag,
  mz: mzFlag,
  qa: qaFlag,
  tf: tfFlag,
  vu: vuFlag,
  ba: baFlag,
  cn: cnFlag,
  fr: frFlag,
  im: imFlag,
  ls: lsFlag,
  na: naFlag,
  re: reFlag,
  tg: tgFlag,
  ws: wsFlag,
  bb: bbFlag,
  co: coFlag,
  fx: fxFlag,
  in: inFlag,
  lt: ltFlag,
  nc: ncFlag,
  ro: roFlag,
  th: thFlag,
  ye: yeFlag,
  bd: bdFlag,
  cr: crFlag,
  ga: gaFlag,
  io: ioFlag,
  lu: luFlag,
  ne: neFlag,
  rs: rsFlag,
  tj: tjFlag,
  yt: ytFlag,
  be: beFlag,
  cu: cuFlag,
  gb: gbFlag,
  iq: iqFlag,
  lv: lvFlag,
  nf: nfFlag,
  ru: ruFlag,
  tk: tkFlag,
  za: zaFlag,
  bf: bfFlag,
  cv: cvFlag,
  gd: gdFlag,
  ir: irFlag,
  ly: lyFlag,
  ng: ngFlag,
  rw: rwFlag,
  tl: tlFlag,
  zm: zmFlag,
  bg: bgFlag,
  cy: cyFlag,
  ge: geFlag,
  is: isFlag,
  ma: maFlag,
  ni: niFlag,
  sa: saFlag,
  tm: tmFlag,
  zw: zwFlag,
  bh: bhFlag,
  cz: czFlag,
  gf: gfFlag,
  it: itFlag,
  mc: mcFlag,
  nl: nlFlag,
  sb: sbFlag,
  tn: tnFlag,
  bi: biFlag,
  de: deFlag,
  gg: ggFlag,
  je: jeFlag,
  md: mdFlag,
  no: noFlag,
  sc: scFlag,
  to: toFlag,
  bj: bjFlag,
  default: defaultFlag,
  gh: ghFlag,
  jm: jmFlag,
  me: meFlag,
  np: npFlag,
  sd: sdFlag,
  tr: trFlag,
  bm: bmFlag,
  dj: djFlag,
  gi: giFlag,
  jo: joFlag,
  mg: mgFlag,
  nr: nrFlag,
  se: seFlag,
  tt: ttFlag,
  bn: bnFlag,
  dk: dkFlag,
  gl: glFlag,
  jp: jpFlag,
  mh: mhFlag,
  nu: nuFlag,
  sg: sgFlag,
  tv: tvFlag,
};

export default flagIcons;