import React, { ReactNode } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';
import CustomScrollbar from 'components/ui/customScrollbar';
import I18nHelpers from 'i18n/I18nHelpers';
import { images } from './images';

type Tag = {
  tagName: string;
  wrapper: (content: string) => ReactNode;
};

interface Config {
  [key: string]: {
    title: string;
    image?: string;
    mainText?: string;
    mainTextWithHtml?: {
      text: string;
      tags?: Tag[];
    };
    secondaryTextWithHtml?: {
      text: string;
      tags?: Tag[];
    };
    secondaryText?: string;
    grayTextBlock?: string;
  };
}

interface Props extends IntlProps {
  downLoadTemplates: (currPage: string) => void;
  uploadTemplate: () => void;
  contentId: string;
  docLinks?: { [key: string]: string };
}

class Tips extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return (
      this.props.contentId !== nextProps.contentId ||
      (!this.props.docLinks && !!nextProps.docLinks)
    );
  }

  render() {
    return <CustomScrollbar>{this.renderContent()}</CustomScrollbar>;
  }

  renderContent = () => {
    const { contentId, getTranslate } = this.props;

    if (!contentId) {
      return null;
    }

    return (
      <div
        className={classNames('tips-tab', {
          'tips-tab__empty': contentId === 'no_tip',
        })}>
        {contentId === 'payments_registry' && (
          <div style={{ marginBottom: 20 }}>
            {getTranslate('payments.tips.introduction')}
          </div>
        )}
        {this.blueInfoBlock()}
        {this.grayInfoBlock()}
      </div>
    );
  };

  get config(): Config {
    const { downLoadTemplates, uploadTemplate, docLinks } = this.props;
    return {
      no_tip: {
        title: 'Tips.noTips.label',
        mainText: 'Tips.noTips.text',
        image: images.helpdesk,
      },
      payments_registry: {
        title: 'payments.tips.label',
        mainText: 'payments.tips.text1',
        secondaryText: 'payments.tips.text2',
      },
      manual_payments_registry: {
        image: images.manualPayments,
        title: 'manualPayments.tips.label',
        mainText: 'manualPayments.tips.text1',
        secondaryText: 'manualPayments.tips.text2',
      },
      manual_payments_request: {
        image: images.manualPaymentsSingle,
        title: 'manualPayments.singlePayout.tips.label',
        mainText: 'manualPayments.singlePayout.tips.text',
      },
      manual_payments_bulk: {
        image: images.massPayout,
        title: 'manualPayments.massPayments.tips.label',
        mainTextWithHtml: {
          text: 'manualPayments.massPayments.tips.text1',
          tags: [
            {
              tagName: 'a',
              wrapper: (content) => (
                <span
                  key='manualPayments.massPayments.tips.text1.link1'
                  onClick={(e) => {
                    e.preventDefault();
                    downLoadTemplates('payouts');
                  }}
                  className='utils-link'>
                  {content}
                </span>
              ),
            },
            {
              tagName: 'a',
              wrapper: (content) => (
                <span
                  key='manualPayments.massPayments.tips.text1.link2'
                  onClick={uploadTemplate}
                  className='utils-link'>
                  {content}
                </span>
              ),
            },
          ],
        },
        secondaryText: !docLinks
          ? 'manualPayments.massPayments.tips.text2'
          : '',
        secondaryTextWithHtml: docLinks && {
          text: 'manualPayments.massPayments.tips.text2',
          tags: [
            {
              tagName: 'a',
              wrapper: (content) => (
                <a
                  className='tips-link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={docLinks['manualPayments.massPayments']}>
                  <span className='utils-ui-block__text-bold'>{content}</span>
                </a>
              ),
            },
          ],
        },
      },
      manual_payments_moto: {
        image: images.motoPayments,
        title: 'manualPayments.moto.tips.label',
        mainText: 'manualPayments.moto.tips.text',
      },
      payment_links_registry: {
        image: images.paymentLink,
        title: 'paymentLink.tips.label',
        mainTextWithHtml: {
          text: 'paymentLink.tips.text1',
        },
        grayTextBlock: 'paymentLink.tips.text2',
      },
      payment_link: {
        title: 'paymentLink.tips.label',
        image: images.newPaymentLink,
        mainText: 'paymentLink.newPaymentLink.tips',
      },
      reports_registry: {
        image: images.reports,
        title: 'reports.tips.label',
        mainText: 'reports.tips.text1',
        secondaryText: 'reports.tips.text2',
      },
      reports_form: {
        image: images.newReport,
        title: 'reports.newReport.tips.label',
        mainText: 'reports.newReport.tips.text1',
        grayTextBlock: 'reports.newReport.tips.text2',
      },
      balances: {
        image: images.finance,
        title: 'finance.tips.label',
        mainText: 'finance.tips.text',
      },
      risks_fraud_registry: {
        image: images.risks,
        title: 'risks.fraud.tips.label',
        mainText: 'risks.fraud.tips.text',
      },
      risks_bw_registry: {
        image: images.blackWhite,
        title: 'risks.bwLists.tips.label',
        mainText: 'risks.bwLists.tips.text',
      },
      risks_bw_single: {
        image: images.singleAddOn,
        title: 'risks.bwLists.manage.singleadding.tips.label',
        mainText: 'risks.bwLists.manage.singleadding.tips.text',
      },
      risks_bw_bulk: {
        image: images.bulkAddOn,
        title: 'risks.bwLists.manage.massadding.tips.label',
        mainTextWithHtml: {
          text: 'risks.bwLists.manage.massadding.tips.text1',
          tags: [
            {
              tagName: 'a',
              wrapper: (content) => (
                <span
                  key='risks.bwLists.manage.massadding.tips.link1'
                  onClick={(e) => {
                    e.preventDefault();
                    downLoadTemplates('risks');
                  }}
                  className='utils-link'>
                  {content}
                </span>
              ),
            },
            {
              tagName: 'a',
              wrapper: (content) => (
                <span
                  key='risks.bwLists.manage.massadding.tips.link2'
                  onClick={uploadTemplate}
                  className='utils-link'>
                  {content}
                </span>
              ),
            },
          ],
        },
        secondaryText: !docLinks
          ? 'risks.bwLists.manage.massadding.tips.text2'
          : '',
        secondaryTextWithHtml: docLinks && {
          text: 'risks.bwLists.manage.massadding.tips.text2',
          tags: [
            {
              tagName: 'a',
              wrapper: (content) => (
                <a
                  className='tips-link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={docLinks['risks.bwLists.manage']}>
                  <span className='utils-ui-block__text-bold'>{content}</span>
                </a>
              ),
            },
          ],
        },
      },
      chargebacks_registry: {
        image: images.chargebacks,
        title: 'chargebacks.tips.label',
        mainText: 'chargebacks.tips.text',
      },
      projects: {
        image: images.projects,
        title: 'projects.tips.label',
        mainText: 'projects.tips.text',
      },
      payment_confirmations: {
        image: images.paymentConfirmations,
        title: 'confirmations.tips.label',
        mainText: 'confirmations.tips.text',
      },
      business_docs: {
        image: images.businessDocs,
        title: 'businessDocs.tips.label',
        mainText: 'businessDocs.tips.text',
      },
      my_team: {
        image: images.team,
        title: 'myTeam.tips.label',
        mainText: 'myTeam.tips.text',
      },
    };
  }

  grayInfoBlock = () => {
    const { getTranslate, contentId } = this.props;
    if (!this.config[contentId].grayTextBlock) {
      return null;
    }

    return (
      <div className='info info__gray'>
        {getTranslate(this.config[contentId].grayTextBlock)}
      </div>
    );
  };

  blueInfoBlock = () => {
    const { getTranslate, contentId } = this.props;
    const tipConfig = this.config[contentId];

    if (!tipConfig) {
      return null;
    }

    return (
      <div className='info info__blue'>
        {tipConfig.image && <img src={tipConfig.image} alt='' />}

        <div className='text-block'>
          <span className='text-block__title'>
            {getTranslate(tipConfig.title)}
          </span>

          {tipConfig.mainTextWithHtml && (
            <span>
              {I18nHelpers.getJsx(
                getTranslate(tipConfig.mainTextWithHtml.text),
                tipConfig.mainTextWithHtml.tags
              )}
            </span>
          )}
          {tipConfig.mainText && (
            <span>{getTranslate(tipConfig.mainText)}</span>
          )}

          {tipConfig.secondaryTextWithHtml && (
            <span className='text-block__secondary'>
              {I18nHelpers.getJsx(
                getTranslate(tipConfig.secondaryTextWithHtml.text),
                tipConfig.secondaryTextWithHtml.tags
              )}
            </span>
          )}
          {tipConfig.secondaryText && (
            <span className='text-block__secondary'>
              {getTranslate(tipConfig.secondaryText)}
            </span>
          )}
        </div>
      </div>
    );
  };
}

export default addTranslation(Tips);
