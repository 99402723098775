import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

export default [
  {
    filterId: filtersKeys.referenceOperationId,
    type: FilterTypes.numbers,
    title: 'filters.types.operationId.placeholder',
  },
  {
    filterId: filtersKeys.referencePaymentId,
    type: FilterTypes.text,
    title: 'filters.types.paymentId.placeholder',
  },
  {
    filterId: filtersKeys.referenceAmount,
    type: FilterTypes.amount,
    title: 'filters.fields.amount.placeholder',
  },
  {
    filterId: filtersKeys.referenceOperationDate,
    type: FilterTypes.dateSelect,
    title: 'filters.types.operationCreated',
  },
  {
    filterId: filtersKeys.referenceConfirmDate,
    type: FilterTypes.dateSelect,
    title: 'filters.types.refrenceCreated',
  },
];
