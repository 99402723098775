import React, { PureComponent } from 'react';
import ArrayMove from 'array-move';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ItemConfiguration from 'types/ItemConfiguration';
import {
  SortableContainer,
  SortableItem,
  SortableHandle,
} from 'components/ui/sortable';
import Checkbox from 'components/ui/checkbox';
import Icon from 'components/ui/icon';
import Utils from 'helpers/Utils';

import './configuration.scss';

interface Props extends IntlProps {
  getConfiguration: (config: ItemConfiguration[]) => void;
  configuration: ItemConfiguration[];
}

class Configuration extends PureComponent<Props> {
  render() {
    const { getTranslate, configuration } = this.props;

    return (
      <div className='configuration__content'>
        <div className='configuration__list'>
          <SortableContainer
            onSortEnd={this.handleSort}
            useDragHandle={true}
            lockAxis='y'
            lockOffset={['0%', '-10%']}
            lockToContainerEdges={true}
            helperClass='configuration__item-helper'>
            {configuration.map((item, index) => {
              const isFixed = Utils.hasProp(item, 'fixed');
              const option = isFixed ? 'fixed' : 'active';

              if (isFixed) {
                return (
                  <div className='configuration__item' key={item.id}>
                    <span className='configuration__item-position'>
                      {index + 1}
                    </span>
                    <label
                      className='ui-checkbox ui-checkbox_full-width ui-checkbox_locker'
                      onClick={() => this.handleCheckbox(item, index, option)}>
                      <span className='ui-checkbox__icon'>
                        <Icon
                          name={item[option] ? 'im-Lock-close' : 'im-Lock-open'}
                          size={18}
                        />
                      </span>
                      <span className='ui-checkbox__text'>
                        {getTranslate(item.localId)}
                      </span>
                    </label>
                  </div>
                );
              }

              return (
                <SortableItem
                  key={`item-${item.id}`}
                  index={index}
                  disabled={!item.active}>
                  <div className='configuration__item' key={item.id}>
                    <span className='configuration__item-position'>
                      {index + 1}
                    </span>
                    <SortableHandle>
                      <span
                        className={classNames('configuration__drag', {
                          configuration__drag_disabled: !item.active,
                        })}>
                        <Icon
                          className='common_disable'
                          name='im-Draganddrop'
                          size={12}
                        />
                      </span>
                    </SortableHandle>
                    <Checkbox
                      id={item.id}
                      text={getTranslate(item.localId)}
                      checked={item.active}
                      onChange={() => this.handleCheckbox(item, index, option)}
                    />
                  </div>
                </SortableItem>
              );
            })}
          </SortableContainer>
        </div>
      </div>
    );
  }

  handleSort = ({ oldIndex, newIndex }) => {
    const { getConfiguration, configuration } = this.props;
    const configList = JSON.parse(JSON.stringify(configuration));
    getConfiguration(ArrayMove(configList, oldIndex, newIndex));
  };

  handleCheckbox = (
    item: ItemConfiguration,
    index: number,
    option: 'fixed' | 'active'
  ) => {
    const { getConfiguration, configuration } = this.props;
    let newConfig: ItemConfiguration[];

    // For multiple fixed field activation if one of the lock clicked
    if (option === 'fixed') {
      newConfig = configuration.map(({ fixed, ...rest }) => {
        if (fixed !== undefined) {
          return {
            ...rest,
            fixed: !fixed,
          };
        }

        return rest;
      });
    } else {
      // For checkbox that clicked and field changed to active
      const { active } = item;
      const newItem = {
        ...item,
        active: !active,
      };

      const copy = [...configuration];
      copy.splice(index, 1, newItem);
      newConfig = copy;
    }

    getConfiguration(newConfig);
  };
}

export default addTranslation(Configuration);
