import React, { Component } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import DataListContainer from 'components/dataListContainer';
import SavedEntitiesContainer from 'components/savedEntities';
import Row from 'components/ui/table/row';
import Column from 'components/ui/table/column/Column';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import TopPanelContainer from 'components/topPanel';
import PaymentCardContainer from 'components/paymentCard';
import SwitcherTabs from 'components/ui/switcherTabs/SwitcherTabs';
import SlideDown from 'components/ui/slideDown';
import AddToList from './components/addtoList';

import { AnyObject } from 'types/Common';
import savedEntities from 'constants/savedEntities';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import { minRisksTableColumnsWidth } from './constants';
import quickFiltersConfig from './quickFiltersConfig';
import HelpDeskHelpers from 'components/helpDesk/HelpDeskHelpers';
import { filtersKeysType } from 'types/Filters';
import { WithRouterProps } from 'decorators/withRouter';
import { tableRoutes } from './routesMapper';
import permissionReasons from 'constants/permissionReasons';
import './risks.scss';

interface OwnProps {
  id?: string;
  tableName: tableNames;
  hasSelectedFiltersCustom?: boolean;
  configuration: AnyObject;
  filtersValuesCustom: AnyObject;
  isLoading: boolean;
  canRenderTable: boolean;
  apiRequest: (data?: any) => Promise<any>;
  onChangeCurrentTable: (tableName: string) => void;
  onDeleteItem: (e, data: AnyObject) => void;
  onRowClick: (data: AnyObject) => void;
  onReset?: () => void;
  onChange: (filterName: filtersKeysType, value: string | number) => void;
  onBeforeChange: {
    [key: string]: (key: filtersKeysType, value: any) => boolean;
  };
}

type Props = OwnProps & IntlProps & WithRouterProps & WithPermissions;

interface State {
  showWbForm: boolean;
}

enum AddItemTypes {
  single,
  multiple,
}

class RisksPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showWbForm: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.tableName !== this.props.tableName) {
      this.setState({ showWbForm: false });
    }
  }

  toggleVisibleWbForm = (forceValue?: boolean) => {
    const { showWbForm } = this.state;
    const newShowWbForm = forceValue !== undefined ? forceValue : !showWbForm;

    this.setState({
      showWbForm: newShowWbForm,
    });

    if (newShowWbForm) {
      this.onChangeAddType(0);
    } else {
      HelpDeskHelpers.setCurrentTab.call(this, {
        riskManage: null,
      });
    }
  };

  onChangeAddType = (tabIndex) => {
    HelpDeskHelpers.setCurrentTab.call(this, {
      riskManage: AddItemTypes[tabIndex],
    });
  };

  prepareDataToDelete = (data) => {
    return {
      contentType: data.contentType,
      listType: data.listType,
      merchantId: data.merchantId,
      projectId: data.projectId,
      id: data.id,
    };
  };

  renderContent = () => {
    const {
      tableName,
      apiRequest,
      onReset,
      onChange,
      onBeforeChange,
      isLoading,
      canRenderTable,
      id,
      hasSelectedFiltersCustom,
      filtersValuesCustom,
      getTranslate,
      location,
      isSomeEnabled,
      isDisabledByReason,
    } = this.props;

    if (id) {
      return <PaymentCardContainer id={id} savedEntity={savedEntities.risks} />;
    }

    const isEnabled =
      isSomeEnabled([
        Messages.Rcs_BulkCreateElement,
        Messages.Rcs_CreateElement,
      ]) ||
      isDisabledByReason(
        Messages.Rcs_CreateElement,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      ) ||
      isDisabledByReason(
        Messages.Rcs_BulkCreateElement,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return (
      <PageTemplate.Container>
        <TopPanelContainer
          name={tableName as tableNames.rcs | tableNames.fraud}
          customClass='page-risks__top-panel'
          filtersConfig={quickFiltersConfig[tableName]}
          isFilterButton={false}
          isConfigurationButton={tableName !== tableNames.rcs}
          filtersValuesCustom={filtersValuesCustom}
          hasSelectedFiltersCustom={hasSelectedFiltersCustom}
          onReset={onReset}
          onChange={onChange}
          onBeforeChange={onBeforeChange}
          button={
            tableName === tableNames.rcs &&
            isEnabled && (
              <Button
                text={getTranslate('risks.manage.button')}
                status='primary'
                onClick={() => this.toggleVisibleWbForm(true)}
              />
            )
          }
        />

        <SlideDown isOpen={this.state.showWbForm}>
          <AddToList
            defaultOpenedWbForm={
              location.state?.currentTab?.riskManage ?? undefined
            }
            onCloseForm={() => this.toggleVisibleWbForm(false)}
            onChangeAddType={this.onChangeAddType}
          />
        </SlideDown>

        {canRenderTable && (
          <DataListContainer
            minColumnsWidth={minRisksTableColumnsWidth}
            apiRequest={apiRequest}
            tableName={tableName}
            rowRender={this.rowRender}
            quickFilter={tableName}
            customClass={`page-risks__table table-${tableName}`}
          />
        )}

        {isLoading && <Loader />}
      </PageTemplate.Container>
    );
  };

  rowRender = ({ handleDoubleClick, ...data }) => {
    const { configuration, tableName, onDeleteItem, onRowClick } = this.props;

    return (
      <Row
        key={data.id}
        customClass={classNames({
          'ui-table__row_clickable': tableName === tableNames.fraud,
          'table-rcsBlackWhite__row_black':
            tableName === tableNames.rcs && data.listType === 'blacklist',
        })}
        onClick={() => {
          handleDoubleClick(onRowClick);
        }}>
        {configuration.map(({ id, fixed, valueType }) => {
          let content = data[id];

          if (id === 'delete') {
            content = (
              <Button
                iconSize={16}
                icon='im-Basket'
                status='transparent'
                size='inline'
                onClick={(e) => onDeleteItem(e, this.prepareDataToDelete(data))}
              />
            );
          }

          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              data={data}
              content={content || '—'}
              customClass={classNames(`ui-table__column_${id}`, {
                'ui-table__column_fixed': fixed,
              })}
              params={{
                valueType,
                valueId: id,
              }}
            />
          );
        })}
      </Row>
    );
  };

  render() {
    const { tableName, onChangeCurrentTable, getTranslate } = this.props;

    return (
      <PageTemplate.Main customClass='page-risks'>
        <PageTemplate.Entities>
          <SwitcherTabs
            tabs={[
              {
                id: tableNames.fraud,
                text: getTranslate('risks.tabs.fraud'),
                path: 'risks',
              },
              {
                id: tableNames.rcs,
                text: getTranslate('risks.tabs.bwlist'),
                path: `risks/${tableRoutes[tableNames.rcs]}`,
              },
            ]}
            activeTab={tableName}
            icon='im-Risk'
            onClick={onChangeCurrentTable}
          />
          <SavedEntitiesContainer
            entityKey={savedEntities.risks}
            isFirstTab={false}
          />
        </PageTemplate.Entities>

        {this.renderContent()}
      </PageTemplate.Main>
    );
  }
}

export default addPermissions(addTranslation(RisksPage));
