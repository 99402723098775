import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import breakpoints from 'constants/breakpoints';
import LocalStorage from 'helpers/LocalStorage';
import macbook from 'images/macbook.png';
import './constructor.scss';

interface ConnectedProps {
  status: boolean;
  paymentPageEditorUrl: string;
  isProjectLoading: boolean;
}

type Props = ConnectedProps & IntlProps;

interface State {
  isLoading: boolean;
  isDisabled: boolean;
  authToken: string;
  iframeOrigin: string;
  isMobile: boolean;
}

@addListeners([Messages.Auth_Ping])
class Constructor extends PureComponent<Props, State> {
  private iframeRef;
  state = {
    isLoading: true,
    isDisabled: false,
    isMobile: window.innerWidth < breakpoints.commonDesktop,
    authToken: '',
    iframeOrigin: '',
  };

  componentDidMount() {
    this.setState({
      isDisabled: isNotAvailableForSupport(
        Messages.Project_SaveRedirectSettings
      ),
    });
    if (this.props.paymentPageEditorUrl) {
      this.setAuthToken();
      this.setOrigin();
    } else {
      this.toggleLoading();
    }
    window.addEventListener('resize', this.checkWidth);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.authToken && prevState.authToken !== this.state.authToken) {
      this.postMessage();
    }

    if (
      this.props.paymentPageEditorUrl &&
      prevProps.paymentPageEditorUrl !== this.props.paymentPageEditorUrl
    ) {
      this.setState({ isLoading: true });
      this.setAuthToken();
    }
  }

  render() {
    const { paymentPageEditorUrl, status, isProjectLoading, getTranslate } =
      this.props;
    const { isLoading, isDisabled, isMobile } = this.state;

    if (isMobile) {
      return (
        <div className='card project-constructor project-constructor_empty'>
          <img
            src={macbook}
            alt='Constructor not available on mobile'
            className='project-constructor__img'
          />
          <div className='project-constructor__title'>
            {getTranslate('projects.constructor.notAvailable.title')}
          </div>
          <div className='project-constructor__text'>
            {getTranslate('projects.constructor.notAvailable.text')}
          </div>
        </div>
      );
    }
    return (
      <div className='card project-constructor'>
        {isLoading && !isProjectLoading && <Loader />}

        {paymentPageEditorUrl ? (
          <>
            <iframe
              ref={(el) => {
                this.iframeRef = el;
              }}
              src={paymentPageEditorUrl}
              name='constructor'
              onLoad={() => {
                this.toggleLoading();
                this.postMessage();
              }}
              title='constructor-pp'
              className='project-constructor__frame'
            />

            {isDisabled && <div className='project-constructor__veil' />}
            {(isDisabled || !status) && (
              <div className='project-constructor__veil' />
            )}
          </>
        ) : (
          <div className='page-projects__no-content'>
            {!isProjectLoading && <NoContent />}
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  toggleLoading = () => {
    this.setState({ isLoading: false });
  };

  setOrigin = () => {
    const url = new URL(this.props.paymentPageEditorUrl);
    this.setState({ iframeOrigin: url.origin });
  };

  setAuthToken = () => {
    this.setState({ authToken: LocalStorage.get('authToken') });
  };

  postMessage = () => {
    const { iframeOrigin, authToken } = this.state;
    if (!iframeOrigin || !this.iframeRef?.contentWindow) return;
    this.iframeRef.contentWindow.postMessage(
      JSON.stringify({
        type: 'DashboardAuthToken',
        payload: { authToken },
      }),
      iframeOrigin
    );
  };

  checkWidth = () => {
    this.setState({ isMobile: window.innerWidth < breakpoints.commonDesktop });
  };

  onEvent = ({ data }) => {
    if (this.state.isLoading) return;
    this.setState({ authToken: data.payload.authToken });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  paymentPageEditorUrl: state.projects.paymentPageEditorUrl,
  status: state.projects.project.status,
  isProjectLoading: state.projects.isLoading,
});
export default connect(mapStateToProps)(addTranslation(Constructor));
