import React from 'react';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import ReferenceList from './list';

const References: React.VFC = () => (
  <PageTemplate.Main>
    <PageTemplate.Container>
      <ReferenceList />
    </PageTemplate.Container>
  </PageTemplate.Main>
);

export default References;
