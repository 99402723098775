import manualPayments from 'images/helpdesk/manual-payments.png';
import manualPaymentsSingle from 'images/helpdesk/manual-payments-single.png';
import massPayout from 'images/helpdesk/mass-payout.png';
import motoPayments from 'images/helpdesk/moto-payments.png';
import paymentLink from 'images/helpdesk/payment-link.png';
import newPaymentLink from 'images/helpdesk/new-payment-link.png';
import reports from 'images/helpdesk/reports.png';
import newReport from 'images/helpdesk/new-report.png';
import finance from 'images/helpdesk/finance.png';
import risks from 'images/helpdesk/risks.png';
import blackWhite from 'images/helpdesk/black-white.png';
import singleAddOn from 'images/helpdesk/single-add-on.png';
import bulkAddOn from 'images/helpdesk/bulk-add-on.png';
import chargebacks from 'images/helpdesk/chargebacks.png';
import projects from 'images/helpdesk/projects.png';
import paymentConfirmations from 'images/helpdesk/payment-confirmations.png';
import businessDocs from 'images/helpdesk/business-docs.png';
import team from 'images/helpdesk/team.png';
import helpdesk from 'images/helpdesk/helpdesk.svg';

export const images = {
  manualPayments,
  manualPaymentsSingle,
  massPayout,
  motoPayments,
  paymentLink,
  newPaymentLink,
  reports,
  newReport,
  finance,
  risks,
  blackWhite,
  singleAddOn,
  bulkAddOn,
  chargebacks,
  projects,
  paymentConfirmations,
  businessDocs,
  team,
  helpdesk,
};
