import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { isEqual } from 'lodash-es';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import api from 'api/projects';
import { updateProject, updateProjectField } from 'actions/projects';
import Redirects from './Redirects';
import showNotification from 'components/ui/notification/showNotification';
import { prepareData } from '../../utils';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import {
  DictionaryCustomSelect,
  DictionaryMultiSelect,
} from 'types/FilterValue';
import { Redirect, ValidationErrors } from '../../types';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { openModal } from 'actions/modal';
import { scrollToError } from 'components/formFields/helpers';

interface ConnectedProps {
  projectId: string;
  status: boolean;
  redirectSuccess: Redirect;
  redirectFail: Redirect;
  redirectTokenize: Redirect;
  merchantBackSuccess: Redirect;
  merchantBackFail: Redirect;

  paymentPageMerchantReturnState: DictionaryCustomSelect;
  redirectUrlMode: DictionaryMultiSelect;
  canSaveRedirectSettings: boolean;
  oldData: AnyObject;

  isEditable: boolean;
  redirectHistory: AnyObject;
  settingHistoryAction: AnyObject;
  validationErrors: ValidationErrors;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithPermissions;

@addListeners([
  Messages.Project_SaveRedirectSettings,
  Messages.Confirm_Reject,
  Messages.Confirm_Accept,
])
class RedirectsContainer extends PureComponent<Props> {
  render() {
    const {
      projectId,
      status,
      redirectUrlMode,
      canSaveRedirectSettings,
      redirectHistory,
      redirectSuccess,
      redirectFail,
      redirectTokenize,
      merchantBackSuccess,
      merchantBackFail,
      validationErrors,
      paymentPageMerchantReturnState,
    } = this.props;

    return (
      <Redirects
        currentProjectId={projectId}
        redirectSuccess={redirectSuccess}
        redirectFail={redirectFail}
        redirectTokenize={redirectTokenize}
        merchantBackSuccess={merchantBackSuccess}
        merchantBackFail={merchantBackFail}
        status={status}
        saveRedirectSettings={() => this.saveRedirectSettings()}
        canSaveRedirectSettings={canSaveRedirectSettings}
        editPermission={this.canEdit()}
        redirectUrlMode={redirectUrlMode}
        onChangeField={this.onChangeField}
        redirectHistory={redirectHistory}
        getHistory={(id, action) => this.getHistory(id, action)}
        validationErrors={validationErrors}
        paymentPageMerchantReturnState={paymentPageMerchantReturnState}
      />
    );
  }

  canEdit = () => {
    const { isEnabled, isDisabledByReason, isEditable } = this.props;
    return (
      isEditable &&
      (isEnabled(Messages.Project_SaveRedirectSettings) ||
        isDisabledByReason(
          Messages.Project_SaveRedirectSettings,
          permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
        ))
    );
  };

  onChangeField = (type, name: string, value) => {
    const section: Redirect = this.props[type];
    const errors: Redirect = this.props.validationErrors[type];
    const { dispatch } = this.props;

    dispatch(
      updateProject({
        [type]: { ...section, [name]: value },
        validationErrors: { ...errors, [name]: '' },
      })
    ).then(() => {
      this.checkChanges(type, name);
    });
  };

  async saveRedirectSettings() {
    const { dispatch, getTranslate } = this.props;

    const dataToSave = prepareData({
      ...this.props,
    });

    dispatch(updateProjectField('isLoading', true));
    try {
      await api.saveProjectRedirectSettings(dataToSave);
    } catch ({ payload }) {
      const { validationErrors } = payload;
      if (validationErrors?.projectId) {
        showNotification({
          status: 'error',
          content: getTranslate(validationErrors.projectId),
        });
        dispatch(updateProjectField('isLoading', false));
      }

      dispatch(
        updateProject({
          isLoading: false,
          validationErrors: {
            ...validationErrors,
            ...payload.validationErrors,
          },
        })
      );
    }
  }

  checkChanges = (type?: string, name?) => {
    const { oldData } = this.props;
    if (
      type &&
      name &&
      this.props[type][name] !== oldData.redirectSettings[type][name]
    ) {
      if (!this.props.canSaveRedirectSettings) {
        const { dispatch } = this.props;
        dispatch(updateProjectField('canSaveRedirectSettings', true));
      }
    } else {
      const { projectId, dispatch } = this.props;

      const canSave = !isEqual(
        prepareData({
          ...this.props,
        }),
        prepareData({
          projectId,
          ...oldData.redirectSettings,
        })
      );
      dispatch(updateProjectField('canSaveRedirectSettings', canSave));
    }
  };

  async getHistory(id, action) {
    const { settingHistoryAction, dispatch } = this.props;
    const params = {
      sort: 'desc',
      filter: {
        projectId: id,
        action,
      },
    };
    dispatch(updateProjectField('isLoading', true));
    try {
      const redirectHistory = await api.getProjectHistory(params);
      dispatch(
        updateProject({
          redirectHistory: {
            ...redirectHistory,
            title: settingHistoryAction?.list.find(
              (item) => +item.id === +action
            ).text,
          },
          isShowHistory: true,
        })
      );
      this.props.dispatch(
        openModal({
          modalId: 'RedirectHistory',
          theme: 'modern',
          onClose: () => {
            Tooltip.rebuild();
          },
          content: {
            redirectHistory,
          },
        })
      );
    } finally {
      dispatch(updateProjectField('isLoading', false));
    }
  }

  onEvent = ({ name, data }) => {
    const { dispatch } = this.props;
    const { payload } = data;

    if (name === Messages.Project_SaveRedirectSettings) {
      if (data.payload.validationErrors) {
        scrollToError(payload.validationErrors, 'top', true);
        return;
      }

      dispatch(
        updateProject({
          ...payload,
          project: { ...payload.project },
          oldData: {
            ...this.props.oldData,
            redirectSettings: data.payload.redirectSettings,
          },
          isLoading: false,
        })
      ).then(() => {
        this.checkChanges();
      });
    } else if (
      name === Messages.Confirm_Reject &&
      payload.unblockedTypes.includes(Messages.Project_SaveRedirectSettings)
    ) {
      dispatch(updateProjectField('isLoading', false));
    }
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { projects, filtersValues } = state;
  return {
    isEditable: projects.isEditable,
    redirectUrlMode: state.filtersValues.redirectUrlMode,
    settingHistoryAction: state.filtersValues.settingHistoryAction,
    canSaveRedirectSettings: projects.canSaveRedirectSettings,
    redirectSuccess: projects.redirectSuccess,
    redirectFail: projects.redirectFail,
    redirectTokenize: projects.redirectTokenize,
    merchantBackSuccess: projects.merchantBackSuccess,
    merchantBackFail: projects.merchantBackFail,
    oldData: projects.oldData,
    projectId: projects.project.projectId,
    status: projects.project.status,
    redirectHistory: projects.redirectHistory,
    validationErrors: projects.validationErrors,
    paymentPageMerchantReturnState: {
      list: getCustomSelectItems({
        list: filtersValues.paymentPageMerchantReturnState?.list || [],
      }),
      hasMoreRows: filtersValues.paymentPageMerchantReturnState?.hasMoreRows,
      isFetched: filtersValues.paymentPageMerchantReturnState?.isFetched,
      isLoading: filtersValues.paymentPageMerchantReturnState?.isLoading,
    },
  };
};

export default connect(mapStateToProps)(
  addTranslation(addPermissions(RedirectsContainer))
);
