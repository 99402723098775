import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import { AccountingRequestItem } from 'pages/accountingStatementRequests/types';

interface State {
  list: AccountingRequestItem[];
}

const initialState: State = {
  list: [],
};

export const accountingRequests = (
  state = initialState,
  action: ActionReducer<State>
) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_ACCOUNTING_REQUESTS:
      return {
        list: payload.list,
      };
    case ACTIONS.RESET_ACCOUNTING_REQUESTS:
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};
