import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { RootState } from 'store';
import { useTranslation } from 'hooks';

import { uploadUserFile } from 'api/maf';
import { setBeforeUpdateActions } from 'actions/maf';
import { MafField } from '../../types';
import { getDateFromResponse, getFileSize, getIcon } from './helper';
import Icon from 'components/ui/icon';
import Loader from 'components/ui/loader';
import InfoIcon from 'components/ui/infoIcon';
import showNotification from 'components/ui/notification/showNotification';
import './fileUpload.scss';

interface Props {
  field: MafField;
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  className?: string;
  fileTypes?: string;
  mafId?: string;
  legalEntityId?: string;
  formName: string;
  onChange: ({
    objectName,
    date,
    fileName,
  }: {
    objectName: string;
    date: string;
    fileName: string;
  }) => void;
}

const MAX_FILE_SIZE_IN_MB = 20;
const MB_IN_BYTES = 1048576;

const FileUpload: React.FC<Props> = ({
  field,
  disabled,
  required,
  error,
  className,
  fileTypes,
  formName,
  legalEntityId,
  onChange,
  mafId = '',
  tooltip = '',
}) => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { actionsBeforeUpdate } = useSelector((state: RootState) => state.maf);
  const fileName = (file && file.name) || field.lookup_display_value || '';

  const uploadFile = useCallback(() => {
    return new Promise((resolve) => {
      setLoading(true);

      uploadUserFile({
        file,
        mafId,
        legalEntityId,
        attachmentType: field.attach_type_id || '',
        documentType: field.doc_type_id || '',
      })
        .then((result) => {
          const date = getDateFromResponse(result);
          onChange({ ...result, date });
          removeFile();
          resolve(result);
        })
        .catch((e) => {
          const content = e.payload?.validationErrors?.file;

          if (e.payload?.validationErrors?.file) {
            showNotification({
              status: 'error',
              content,
            });

            resolve({
              validationErrors: {
                [field.name]: content,
              },
              formName,
              fieldName: field.name,
            });
          }
          console.error(e);
          resolve(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [file, mafId, legalEntityId, field, formName, onChange]);

  useEffect(() => {
    if (file) {
      actionsBeforeUpdate[field.name] = uploadFile;
      dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
    }
  }, [file]);

  useEffect(() => {
    return () => {
      delete actionsBeforeUpdate[field.name];
      dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    multiple: false,
    maxSize: MB_IN_BYTES * MAX_FILE_SIZE_IN_MB,
    noKeyboard: true,
    onDropAccepted: (files) => setFile(files[0]),
    onDropRejected: (files) => {
      const errorCode = files[0].errors[0].code;

      if (errorCode === 'file-invalid-type') {
        showNotification({
          status: 'error',
          content: `${getTranslate(
            'fileupload.error.invalid.type'
          )} ${fileTypes}`,
        });

        return;
      }

      if (errorCode === 'file-too-large') {
        showNotification({
          status: 'error',
          content: `${getTranslate(
            'fileupload.error.too.large'
          )} ${MAX_FILE_SIZE_IN_MB} MB`,
        });

        return;
      }
    },
  });

  const removeFile = () => {
    setFile(null);
  };

  return (
    <>
      <div className='file-upload__title'>
        <span className='file-upload__label'>
          {getTranslate(field.caption)}
        </span>

        {required && (
          <span
            data-tip={getTranslate('common.requiredField.tooltip')}
            className='file-upload__required'>
            *
          </span>
        )}

        {tooltip && (
          <InfoIcon
            tooltip={getTranslate(tooltip)}
            customClass='file-upload__info'
          />
        )}
      </div>

      <div
        {...getRootProps()}
        className={classNames(className, 'file-upload', {
          'file-upload_disabled': disabled,
          'file-upload_error': error,
        })}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!disabled ? (
              <div className='file-upload__info-block'>
                <Icon
                  name='im-Upload'
                  className='file-upload__icon'
                  size={16}
                />
                <div className='file-upload__info-text'>
                  <span>{getTranslate('fileupload.info.text')}</span>
                  <span className='file-upload__link-text'>
                    {getTranslate('fileupload.info.text.link')}
                  </span>
                </div>
              </div>
            ) : null}

            <input {...getInputProps()} accept={fileTypes} />

            {file || field.lookup_display_value ? (
              <div className='selected-file'>
                <div className='selected-file__icon'>
                  <img src={getIcon(fileName)} alt='file-icon' />
                </div>
                <div className='selected-file__name'>{fileName}</div>

                {file && file.size && (
                  <p className='selected-file__size'>
                    {getFileSize(file.size)}
                  </p>
                )}

                {!file && field.lookup_display_value ? (
                  <InfoIcon
                    tooltip={getTranslate(
                      'businessDocs.documentsUpload.tooltip'
                    )}
                    customClass='selected-file__info'
                    size={20}
                  />
                ) : null}

                {file && !disabled ? (
                  <div
                    className='selected-file__remove'
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFile();
                    }}>
                    <Icon name='im-Basket' size={11} />
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>

      {error && <div className='file-upload__error-text'>{error}</div>}
    </>
  );
};

export default FileUpload;
