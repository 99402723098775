import PaymentPageUI from 'types/PaymentPageUI';
import RecurringCardPageUI from 'types/RecurringCardPageUI';

class UiStateFactory {
  static getInitialPageUI(): PaymentPageUI {
    return {
      pageScrollTop: 0,
      operationsScrollTop: 0,
      selectedOperationId: '',
      operationInfoScrollTop: 0,
      paymentInfoScrollTop: 0,
      paymentSelectedTab: 0,
      parentSelectedOperationId: '',
      isRetriesOpen: false,
      operationOrder: 'desc',
    };
  }

  static getInitialRecurringCardUI(): RecurringCardPageUI {
    return {
      pageScrollTop: 0,
      selectedOperationId: '',
      operationInfoScrollTop: 0,
      subsOperationsScrollTop: 0,
      initOperationsScrollTop: 0,
      paymentInfoScrollTop: 0,
      paymentSelectedTab: 0,
      initOperationsIsOpened: false,
      isMaxHeight: false,
      parentSelectedOperationId: '',
      isRetriesOpen: false,
      currentPaymentType: 'subs',
      operationOrder: 'desc',
    };
  }
}

export default UiStateFactory;
