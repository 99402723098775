import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { addEntityToSaved } from 'actions/savedEntities';
import { WithRouterProps } from 'decorators/withRouter';
import PaymentsPage from './PaymentsPage';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import fetchUpdateDictionary from 'helpers/dictionaries';
import { AnyObject } from 'types/Common';
import {
  QuickFiltersType,
  QuickPayoutsFiltersType,
  QuickReportsFiltersType,
} from 'types/QuickFilters';
import landingState from 'constants/landingState';
import { StoreProps } from 'store';
import ItemConfiguration from 'types/ItemConfiguration';
import Messages from 'constants/rpcTypes';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import path from 'helpers/path';
import { prepareDataForState } from 'helpers/paymentsPayoutsRequest';
import getConfigurationByName from 'selectors/getConfigurationByName';
import getFiltersValues from 'selectors/getFiltersValues';
import { getData } from 'actions/getData';
import { getPayments } from 'api/payments';
import tableNames from 'constants/tableNames';
import permissionReasons from 'constants/permissionReasons';
import savedEntities from 'constants/savedEntities';
import CONFIG from 'config';

interface ConnectedProps {
  configuration: ItemConfiguration[];
  sort: {
    field: string;
    order: 'desc' | 'asc';
  };
  tableData: {
    items: AnyObject[];
    isFetch: boolean;
  };
  isDemoUser: boolean;
  quickFilters:
    | QuickFiltersType
    | QuickPayoutsFiltersType
    | QuickReportsFiltersType;
  searchFilters: any;
  filters: any;
}

type Props = ConnectedProps &
  StoreProps &
  WithPermissions &
  WithRouterProps<{ id?: string }>;

class PaymentsContainer extends Component<Props> {
  loadPaymentMethodGroup;
  tableRef;
  constructor(props: Props) {
    super(props);
    this.loadPaymentMethodGroup = fetchUpdateDictionary.bind(
      this,
      'paymentMethodGroup'
    );
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    //in some cases when new payments are being added, we may not have their paymentMethodType (DAS-6665)
    const {
      tableData: { isFetch, items },
    } = this.props;
    const {
      tableData: { items: prevItems },
    } = prevProps;

    if (!isFetch && items.length !== prevItems.length) {
      this.loadPaymentMethodGroup();
    }
  }

  goToPayment = (data) => {
    if (this.props.isEnabled(Messages.Payments_Payment)) {
      const pathUrl = getPathUrl(tableNames.payments, data);
      if (pathUrl) {
        this.props.history.push(path(pathUrl));
      }
    }
  };

  createTabWithPayment = (data) => {
    const { dispatch } = this.props;
    const id = data.transactionId;
    const regex: RegExp = /\?.+/;
    const str: string = getPathUrl(tableNames.payments, data);
    const m: string = regex.exec(str)![0];
    const { name, type, projectId } = queryString.parse(m);

    dispatch(
      addEntityToSaved({
        entityKey: savedEntities.payments,
        id,
        urlParams: { name, type, projectId },
      })
    );
  };

  refreshTableData = () => {
    const { dispatch, sort, quickFilters, filters, searchFilters } = this.props;

    const baseRequest = {
      pagination: {
        // Limit for payments table - 40
        limit: CONFIG.REQUEST_ITEMS_LIMIT * 2,
        offset: 0,
      },
      sort,
      filter: {},
      saveFilter: true,
    };

    const request = prepareDataForState(
      { quickFilters, filters, searchFilters },
      baseRequest,
      { tableName: tableNames.payments }
    );

    this.tableRef?.scrollTo(0, 0);

    dispatch(
      getData({
        apiRequest: getPayments,
        request,
        onResolve: () => {},
        isUpload: false,
        name: tableNames.payments,
      })
    );
  };

  getTableRef = (ref) => {
    this.tableRef = ref;
  };

  render() {
    const {
      match: { params },
      configuration,
      isDemoUser,
      sort,
      isEnabled,
      isDisabledByReason,
    } = this.props;

    if (!configuration) return null;

    return (
      <PaymentsPage
        id={params.id}
        isDemoUser={isDemoUser}
        configuration={configuration}
        sort={sort}
        goToPayment={this.goToPayment}
        openTabWithPayment={this.createTabWithPayment}
        getTableRef={this.getTableRef}
        isRefundEnabled={
          isEnabled(Messages.PaymentOperation_Refund) ||
          isDisabledByReason(
            Messages.PaymentOperation_Refund,
            permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
          )
        }
        onRefresh={this.refreshTableData}
      />
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.payments),
  sort: state.data[tableNames.payments].sort,
  tableData: state.data.payments,
  isDemoUser: state.user.landingState === landingState.demoStage,
  quickFilters: state.quickFilters[tableNames.payments],
  searchFilters: state.searchFilters,
  filters: getFiltersValues(state, tableNames.payments),
});

export default withRouter(
  connect(mapStateToProps)(addPermissions(PaymentsContainer))
);
