import React from 'react';
import { isEqual } from 'lodash-es';
import { loadDictionary } from 'api/dictionaries';
import SelectionList from 'components/ui/selectionList';
import { typeToIdProp } from 'pages/admin/constants';
import { Item } from 'components/ui/selectionList/SelectionList';
import { AnyObject } from 'types/Common';
import { FilterNames } from 'pages/admin/types';
import { connect } from 'react-redux';
import { RootState, StoreProps } from 'store';
import { setFormValues } from 'actions/formValues';

interface ConnectedProps {
  pairdFilterValues: {
    merchantId: [];
    projectId: [];
  };
}

interface Props extends StoreProps, ConnectedProps {
  updateFiltersValues: (key: string, value: AnyObject) => void;
  onChangeFilter: (key: string, value: any) => void;
  valueProp: string;
  filterProp: string;
  connectedTo: string[];
  autonomous?: boolean;
  cantAffect?: string;
  filters: AnyObject;
  filtersValues: AnyObject;
  isFullMode?: boolean;
  isDisabled?: boolean;
  modern?: boolean;
  label?: string;
  containerSelector?: string;
  isQuickFilters?: boolean;
  isValidBeforeApply?: (items: Item[]) => boolean | undefined;
  isPairFilterLoading?: boolean;
  setPairFilterLoading?: (isLoading: boolean) => void;
}

interface State {
  isLoading: boolean;
  queue: string;
  items: Item[];
  forceUpdate: boolean;
}

const TIMEOUT_BEFORE_LOAD = 1000;
const ITEMS_PER_PAGE = 20;
class PairedFilter extends React.PureComponent<Props, State> {
  state = { isLoading: false, queue: '', items: [], forceUpdate: false };
  private handleFiltersChangeTimer;
  private handleSearchTimer;

  componentDidMount() {
    this.updateItems();
  }

  render() {
    const {
      filterProp,
      valueProp,
      isFullMode,
      isDisabled,
      modern,
      label,
      containerSelector,
      isPairFilterLoading,
      isQuickFilters,
    } = this.props;
    const { items, isLoading, queue, forceUpdate } = this.state;

    return (
      <SelectionList
        id={valueProp}
        label={label}
        isDisabled={isDisabled}
        onClose={this.handleClose}
        isFullMode={isFullMode}
        handleSearch={this.onSearch}
        withLoadMore
        isLoading={isLoading || isPairFilterLoading}
        loadMore={this.handleLoadMore}
        items={isLoading && queue && items.length < ITEMS_PER_PAGE ? [] : items}
        forceUpdate={forceUpdate}
        onChange={(value) => this.handleChange(filterProp, value)}
        modern={modern}
        containerSelector={containerSelector}
        isValidBeforeApply={this.props.isValidBeforeApply}
        tooltip={
          !isQuickFilters && filterProp === 'projectId'
            ? 'admin.userEdit.project.select.tooltip'
            : undefined
        }
      />
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { filtersValues, valueProp, filters, filterProp, connectedTo } =
      this.props;
    const { filtersValues: prevValues, filters: prevFilters } = prevProps;

    const items = filtersValues[valueProp]?.list || [];
    const prevItems = prevValues[valueProp]?.list || [];
    const valuesLengthChanged = items.length !== prevItems.length;
    const itemsChanged = () => !isEqual(items, prevItems);

    const valuesChanged = valuesLengthChanged || itemsChanged();

    const filtersChanged = !isEqual(
      filters[filterProp],
      prevFilters[filterProp]
    );

    connectedTo &&
      connectedTo.forEach((item) => {
        const connectedFiltersChanged =
          connectedTo && filters[item]?.length !== prevFilters[item]?.length;

        if (
          valuesChanged ||
          filtersChanged ||
          connectedFiltersChanged ||
          (!prevValues && this.props.filtersValues.prevValues)
        ) {
          this.updateItems();
        }
      });
  }

  componentWillUnmount() {
    const { updateFiltersValues, filtersValues, valueProp } = this.props;
    const list = this.state.items;
    clearTimeout(this.handleSearchTimer);
    clearTimeout(this.handleFiltersChangeTimer);
    updateFiltersValues(valueProp, { ...filtersValues[valueProp], list });
  }

  updateItems = (newValues?) => {
    const { filtersValues, valueProp, filters, filterProp, pairdFilterValues } =
      this.props;
    const { items } = this.state;

    const newItems = newValues || filtersValues[valueProp]?.list;
    const selectedItems = items.filter((item: Item) => item.isSelected);
    const newItemsMap =
      /* eliminate duplicates... */
      [
        ...pairdFilterValues[filterProp],
        ...selectedItems,
        ...(newItems || []),
      ].reduce((acc, item) => {
        const id = item[filterProp] || item.id;
        acc[id] = {
          ...item,
          id,
          isSelected: filters[filterProp].includes(id),
        };
        return acc;
      }, {});

    this.setState({ items: Object.values(newItemsMap), forceUpdate: true });
    setTimeout(() => {
      this.setState({ forceUpdate: false });
    }, 100);
  };

  handleChange = (filterProp, value) => {
    const {
      dispatch,
      onChangeFilter,
      setPairFilterLoading,
      pairdFilterValues,
    } = this.props;

    this.setState({ items: value });

    const selectedValues = value.filter((i) => i.isSelected);
    const selectedValueIds = selectedValues.map((i) => i.id);

    dispatch(
      setFormValues('users', {
        pairdFilterValues: {
          ...pairdFilterValues,
          [filterProp]: selectedValues,
        },
      })
    );

    onChangeFilter(filterProp, selectedValueIds);
    clearTimeout(this.handleFiltersChangeTimer);

    if (setPairFilterLoading && filterProp === 'merchantId') {
      setPairFilterLoading(true);
    }

    this.refetchConnectedFilters(filterProp, {
      [filterProp]: selectedValueIds,
    });
  };

  fetchAndUpdate = async (fetchParams, stateProp) => {
    const { updateFiltersValues, setPairFilterLoading } = this.props;
    try {
      this.setState({ isLoading: true });
      const result = await loadDictionary(fetchParams, false);
      updateFiltersValues(stateProp, {
        ...result,
        list: result.elements,
        elements: null,
      });
    } finally {
      this.setState({ isLoading: false });
      if (setPairFilterLoading) {
        setPairFilterLoading(false);
      }
    }
  };

  refetchConnectedFilters = (filterProp, fetchParams) => {
    const { connectedTo, cantAffect, isQuickFilters } = this.props;
    if (cantAffect && connectedTo.includes(cantAffect)) {
      return;
    }

    connectedTo.forEach((item) => {
      if (isQuickFilters && item === 'legalEntities') return;

      const filterName = typeToIdProp[item];
      this.fetchAndUpdate(
        {
          name: filterName,
          params: fetchParams,
        },
        filterName
      );
    });
  };

  onSearch = (q) => {
    clearTimeout(this.handleSearchTimer);
    this.setState({ queue: q });
    this.handleSearchTimer = setTimeout(() => {
      this.handleSearch(q);
    }, TIMEOUT_BEFORE_LOAD);
  };

  handleSearch = async (q) => {
    const { filters, updateFiltersValues, connectedTo, autonomous, valueProp } =
      this.props;

    this.setState({ isLoading: true });

    for (const item of connectedTo) {
      const params = { q };
      if (!autonomous) {
        params[item] = filters[item];
      }
      const result = await loadDictionary(
        {
          name: valueProp,
          params,
        },
        false
      );
      updateFiltersValues(valueProp, {
        ...result,
        list: result.elements,
        elements: null,
      });
      this.updateItems(result.elements);
    }

    this.setState({ isLoading: false });
  };

  handleLoadMore = async () => {
    const { isLoading, queue } = this.state;
    if (isLoading) {
      return;
    }
    const {
      filters,
      filtersValues,
      valueProp,
      connectedTo,
      autonomous,
      isQuickFilters,
    } = this.props;
    if (!isQuickFilters && valueProp !== FilterNames.merchant) return;

    if (filtersValues[valueProp].hasMoreRows) {
      this.setState({ isLoading: true });
      if (isQuickFilters) {
        for (const item of connectedTo) {
          const params: { q: string; merchantId?: string } = { q: queue };
          if (!autonomous) {
            params[item] = filters[item];
            params.merchantId = filters.merchantId;
          }
          await this.uploadDictionaries(params);
        }
      } else {
        await this.uploadDictionaries({ q: queue });
      }

      this.setState({ isLoading: false });
    }
  };

  uploadDictionaries = async (params) => {
    const { filtersValues, valueProp, updateFiltersValues } = this.props;
    const currentFilterValues = filtersValues[valueProp];
    const prevElements = currentFilterValues.list;
    const result = await loadDictionary(
      {
        name: valueProp,
        params,
        offset: currentFilterValues.offset,
      },
      false
    );

    result.offset = currentFilterValues.offset + result.elements.length;
    result.list = prevElements.concat(this.deleteDoubles(result.elements));

    updateFiltersValues(valueProp, result);
  };

  deleteDoubles = (newList) => {
    const { filtersValues, valueProp } = this.props;
    const currentFilterValues = filtersValues[valueProp];
    return newList.filter((item) => {
      return !currentFilterValues.list.find(
        (oldItem) => oldItem.merchantId === item.merchantId
      );
    });
  };

  handleClose = () => {
    const { queue } = this.state;
    if (!queue) return;
    this.setState({ queue: '' });
    this.onSearch('');
  };
}

const mapStateToProps = (state: RootState) => ({
  pairdFilterValues: state.formValues.users.pairdFilterValues,
});

export default connect(mapStateToProps)(PairedFilter);
