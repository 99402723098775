import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import {
  CreateProjectSettingParams,
  DeleteProjectSettingParams,
  ProjectSetting,
  ProjectSettingsList,
  UpdateProjectSettingParams,
} from 'pages/projects/components/callbacks/types';

const getProjects = (projectId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_Get,
        payload: { projectId },
      },
    ],
  });
};

export const updateSalt = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_UpdateSalt,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const deleteSalt = (projectId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_DeleteSalt,
        payload: { projectId },
      },
    ],
  });
};

const saveProjectRedirectSettings = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_SaveRedirectSettings,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

const saveProjectEditorStyles = (params: {
  projectId: string;
  styles: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_SaveEditorStyles,
        payload: { ...params },
      },
    ],
    showValidateNotification: true,
  });
};

const getProjectHistory = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_UrlHistory,
        payload: { ...params },
      },
    ],
  });
};

const changePaymentMethodStatus = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_ChangePaymentMethodStatus,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

const getProjectCallbackSettingList = (params: {
  projectId: string;
}): Promise<ProjectSettingsList> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ProjectCallbackSettings_List,
        payload: params,
      },
    ],
    isCancelable: true,
  });
};

const updateProjectCallbackSettings = (
  payload: UpdateProjectSettingParams
): Promise<ProjectSetting> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ProjectCallbackSettings_Update,
        payload,
      },
    ],
  });
};

const createProjectCallbackSettings = (
  payload: CreateProjectSettingParams
): Promise<ProjectSetting> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ProjectCallbackSettings_Create,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

const deleteProjectCallbackSettings = (
  payload: DeleteProjectSettingParams
): Promise<ProjectSetting> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ProjectCallbackSettings_Delete,
        payload,
      },
    ],
  });
};

export const getProjectFiscalReceiptInfo = (payload: { projectId: string }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_CashVoucherSettingsGet,
        payload,
      },
    ],
  });
};

export const updateProjectFiscalReceiptInfo = (payload: {
  projectId: string | null;
  inn: string | null;
  group: string | null;
  taxationSystem: number | null;
  paymentAddress: string | null;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Project_CashVoucherSettingsUpdate,
        payload,
      },
    ],
  });
};

export default {
  getProjects,
  saveProjectRedirectSettings,
  saveProjectEditorStyles,
  getProjectHistory,
  changePaymentMethodStatus,
  updateSalt,
  deleteSalt,
  getProjectCallbackSettingList,
  updateProjectCallbackSettings,
  createProjectCallbackSettings,
  deleteProjectCallbackSettings,
};
