import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { isEqual } from 'lodash-es';

import SettingsForm from 'components/modal/modalList/recurringSettingsForm/RecurringSettingsForm';
import {
  cancelRecurringSettings,
  updateRecurringSettings,
} from 'api/recurring';
import { closeModal } from 'actions/modal';
import { addListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import {
  RecurringSettingsFields,
  RecurringSettingsForm,
} from 'pages/recurring/card/settings/RecurringSettingsTypes';
import checkFilters from 'helpers/checkFilters';
import { scrollToError } from 'components/formFields/helpers';
import { AnyObject } from 'types/Common';
import { DictionaryCustomSelect } from 'types/FilterValue';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';

interface OwnProps {
  content: {
    transactionId: string;
    form: RecurringSettingsFields;
  };
}

interface ConnectedProps {
  user: AnyObject;
  filtersValues: AnyObject;
  subscriptionPaymentPeriod: DictionaryCustomSelect;
}

type Props = OwnProps & ConnectedProps & StoreProps & WithPermissions;

interface State {
  form: RecurringSettingsFields;
  isEditMode: boolean;
  isSaving: boolean;
  isConfirm: boolean;
  validationErrors: AnyObject;
}

@addListeners([
  Messages.Confirm_Reject,
  Messages.Confirm_Accept,
  Messages.SubscriptionPayment_Update,
  Messages.SubscriptionPayment_Cancel,
])
class RecurringSettingsFormContainer extends Component<Props, State> {
  private initialForm: RecurringSettingsForm = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      form: props.content.form,
      validationErrors: {},
      isEditMode: false,
      isSaving: false,
      isConfirm: false,
    };
  }

  componentDidMount() {
    checkFilters('recurringSettings');
    this.initialForm = this.props.content.form;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (!isEqual(prevProps.content.form, this.props.content.form)) {
      this.initialForm = this.props.content.form;
      this.setState({ form: this.props.content.form });
    }

    if (prevState.isEditMode !== this.state.isEditMode) {
      Tooltip.rebuild();
    }
  }

  render() {
    const { user, subscriptionPaymentPeriod } = this.props;
    const { isEditMode, isSaving } = this.state;

    const canEditPaymentId = !this.initialForm?.recurringScheduledPaymentId;
    if (this.props.children) {
      return this.props.children;
    }
    return (
      <SettingsForm
        form={this.state.form}
        user={user}
        validationErrors={this.state.validationErrors}
        canEditPaymentId={canEditPaymentId}
        isEditMode={isEditMode}
        subscriptionPeriod={subscriptionPaymentPeriod}
        isChangeable={this.isChangeable()}
        isEditable={this.isChangeable() && this.props.content.form.isEditable}
        onChangeField={this.changeField}
        onEnterEdit={this.enterEditMode}
        onExitEdit={this.onExitEditMode}
        onCancel={() => this.cancelRecurring()}
        onSubmitForm={() => this.updateSettings()}
        isSaving={isSaving}
        isConfirm={this.state.isConfirm}
        confirmExit={this.exitEditMode}
      />
    );
  }

  changeField = (field: keyof RecurringSettingsFields, value) => {
    this.setState(
      (state) =>
        ({
          form: {
            ...state.form,
            [field]: value,
          },
          validationErrors: {
            ...state.validationErrors,
            [field]: '',
          },
        } as State)
    );
  };

  enterEditMode = () => {
    const { isEditMode } = this.state;
    if (
      (!this.isChangeable() && this.props.content.form.isEditable) ||
      isEditMode
    )
      return false;
    this.setState({ isEditMode: true });
  };

  onExitEditMode = () => {
    if (!isEqual(this.props.content.form, this.state.form)) {
      this.setState({ isConfirm: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  exitEditMode = (isConfirm) => {
    if (isConfirm) {
      this.setState({
        isConfirm: false,
        isEditMode: false,
        form: this.props.content.form,
      });
    } else {
      this.setState({ isConfirm: false });
    }
  };

  isChangeable = () => {
    return !!(
      this.props.content.form?.isChangeable &&
      this.props.isEnabled(Messages.SubscriptionPayment_Update)
    );
  };

  async cancelRecurring() {
    const { content } = this.props;

    if (!this.isChangeable()) return false;

    try {
      this.setState({ isSaving: true });
      await cancelRecurringSettings(content.transactionId);
    } catch (error) {
      console.error('Cancel recurring error', error);
    }
  }

  async updateSettings() {
    const {
      content: { transactionId },
    } = this.props;

    this.setState({ isSaving: true });
    const recurringAmount = this.state.form?.recurringAmount || '';
    const recurringInterval = this.state.form?.recurringInterval || '1';

    try {
      await updateRecurringSettings(transactionId, {
        ...this.state.form,
        recurringInterval,
        recurringAmount: recurringAmount.replace(/\s/g, ''),
      });
    } catch (error) {
      console.error('Update settings error: ', error);
    } finally {
      scrollToError(this.state.validationErrors);
    }
  }

  onEvent = ({ name, data }) => {
    if (name === Messages.SubscriptionPayment_Update) {
      if (data.payload.validationErrors) {
        this.setState({
          validationErrors: data.payload.validationErrors,
          isSaving: false,
        });
      } else {
        this.setState({
          isEditMode: false,
          isSaving: false,
        });
      }
    } else if (name === Messages.Confirm_Accept) {
      if (
        data.payload.unblockedTypes.includes(
          Messages.SubscriptionPayment_Update
        ) ||
        data.payload.unblockedTypes.includes(
          Messages.SubscriptionPayment_Cancel
        )
      ) {
        this.setState({ isSaving: true });
      }
    } else if (name === Messages.SubscriptionPayment_Cancel) {
      this.props.dispatch(closeModal());
    } else {
      this.setState({ isSaving: false });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    user: state.user,
    filtersValues: state.filtersValues,
    subscriptionPaymentPeriod: state.filtersValues.subscriptionPaymentPeriod,
  };
};

export default connect(mapStateToProps)(
  addPermissions(RecurringSettingsFormContainer)
);
