import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useGetCurrentPageIndex } from './useGetCurrentPageIndex';
import { useGetMafPages } from './useGetMafPages';
import { setMafPageCurrentIndex } from 'actions/maf';

export const useMafPagesToolkit = () => {
  const dispatch = useDispatch();
  const slideDirection = useRef<'left' | 'right' | null>(null);

  const currentPageIndex = useGetCurrentPageIndex();
  const pages = useGetMafPages();

  const goToNextPage = () => {
    if (currentPageIndex < pages.length - 1) {
      slideDirection.current = 'right';
      dispatch(setMafPageCurrentIndex(currentPageIndex + 1));
    }
  };

  const goToPreviousPage = () => {
    if (currentPageIndex > 0) {
      slideDirection.current = 'left';
      dispatch(setMafPageCurrentIndex(currentPageIndex - 1));
    }
  };

  const goToPage = (index: number) => {
    if (index < 0 || index > pages.length - 1) {
      console.warn('Invalid page index');
    }
    dispatch(setMafPageCurrentIndex(index));
  };

  return {
    goToNextPage,
    goToPreviousPage,
    goToPage,
  };
};
