import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'lodash-es';
import classNames from 'classnames';
import { openModal } from 'actions/modal';
import { RootState, StoreProps } from 'store';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { AnyObject } from 'types/Common';
import { ProjectGeneral } from './types';
import breakpoints from 'constants/breakpoints';
import { projectTabs } from './utils';
import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import Redirects from './components/redirects';
import PaymentMethods from './components/paymentMethods';
import CallBacks from './components/callbacks';
import SaltSettings from './components/saltSettings';
import Constructor from './components/constructor';
import ConstructorVersionFive from './components/constructorVersionFive';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import CustomSelect from 'components/ui/customSelect';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import { Value } from 'components/ui/customSelect/CustomSelect';
import { FiscalReceipt } from './components/fiscalReceipt';
import './projects.scss';

interface ConnectedProps {
  projectId: string;
  paymentPageDesignerVersionFiveUrl: string;
}

interface Props extends IntlProps, WithPermissions, ConnectedProps, StoreProps {
  projectList: Value[];
  setActiveProject: (id: string) => void;
  onChangeTab: (tabIndex: number, tabId?: string) => void;
  isLoading: boolean;
  editPaymentMethods: boolean;
  projectGeneral: ProjectGeneral;
  paymentMethods: AnyObject;
  validationErrors: AnyObject;
  isPaymentPageEditorEnabled: boolean;
  isPaymentPageDesignerVersionFiveEnabled: boolean;
  isCashVoucherEnabled: boolean;
  isLoginAsSupport: boolean;
  currentTabIndex: number;
  setActiveTab: (pathId: any) => void;
}

interface State {
  isMobile: boolean;
}

class Projects extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= breakpoints.projects,
    };
    this.handleResize = throttle(this.handleResize, 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getCurrentProject = () => {
    const { projectGeneral, projectList } = this.props;
    return (
      projectList.find((item) => item.value === projectGeneral.projectId) ||
      projectList[0]
    );
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= breakpoints.projects });
  };

  showStyleEditorModal = () => {
    const {
      projectId,
      paymentPageDesignerVersionFiveUrl,
      dispatch,
      setActiveTab,
    } = this.props;

    dispatch(
      openModal({
        modalId: 'PaymentPageDesigner',
        content: {
          projectId,
          paymentPageDesignerVersionFiveUrl,
          onModalClose: () => {
            const generalTabPath = projectTabs.find(
              (t) => t.id === 'general'
            )?.path;
            setActiveTab(generalTabPath);
          },
        },
      })
    );
  };

  renderGeneral = () => {
    const {
      projectGeneral,
      isEnabled,
      isDisabledByReason,
      isLoginAsSupport,
      getTranslate,
    } = this.props;
    if (!projectGeneral)
      return (
        <div className='page-projects__no-content'>
          <NoContent />
        </div>
      );
    return (
      <div className='card card_l'>
        <div className='page-projects__block-title card__header'>
          {getTranslate('projects.general.title')}
        </div>
        <div className='card__content card__container'>
          <div className='page-projects__block-text'>
            {getTranslate('projects.general.text')}
          </div>
          <div className='page-projects__row page-projects__row_general'>
            <div className='card card_bordered page-projects__card'>
              <div className='card__header card__title-l'>
                {getTranslate('projects.general.information')}
              </div>
              <div className='card__content card__container'>
                <div className='page-projects__block-row'>
                  <span className='page-projects__block-key'>
                    {getTranslate('projects.status')}
                  </span>
                  <span className='page-projects__text-value'>
                    {getTranslate(
                      projectGeneral.status
                        ? 'projects.status.active'
                        : 'projects.status.notActive'
                    )}
                  </span>
                </div>
                <div className='page-projects__block-row'>
                  <span className='page-projects__block-key'>
                    {getTranslate('projects.id.label')}
                  </span>
                  <span className='page-projects__text-value'>
                    {projectGeneral.projectId}
                  </span>
                </div>
                <div className='page-projects__block-row'>
                  <span className='page-projects__block-key'>
                    {getTranslate('projects.url.label')}
                  </span>
                  <span className='page-projects__text-value'>
                    {projectGeneral.url}
                  </span>
                </div>
              </div>
            </div>
            {(isEnabled(Messages.Project_UpdateSalt) ||
              (isDisabledByReason(
                Messages.Project_UpdateSalt,
                permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
              ) &&
                isLoginAsSupport)) && (
              <div className='card card_bordered page-projects__card'>
                <div className='card__header card__title-l'>
                  {getTranslate('projects.categories.saltSettings.label')}
                </div>
                <div className='card__content card__container'>
                  <div className='page-projects__block-text'>
                    {getTranslate('projects.salt.text')}
                  </div>
                  <SaltSettings />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderHeadBlock = () => {
    const { projectList, setActiveProject, getTranslate } = this.props;

    return (
      <>
        <div className='page-projects__title'>
          {getTranslate('projects.title')}
        </div>
        <CustomSelect
          id='projects-list'
          label={getTranslate('projects.list.label')}
          value={this.getCurrentProject()}
          options={projectList}
          onChange={({ value }) => setActiveProject(value)}
          customClass='page-projects__list'
          modern
          isSearchable={true}
          backspaceRemovesValue={false}
        />
      </>
    );
  };

  render() {
    const {
      projectGeneral,
      isPaymentPageEditorEnabled,
      isPaymentPageDesignerVersionFiveEnabled,
      isCashVoucherEnabled,
      isLoading,
      getTranslate,
      isEnabled,
      onChangeTab,
    } = this.props;
    const { isMobile } = this.state;
    return (
      <PageTemplate.Main customClass='page-projects'>
        <PageTemplate.Container
          customClass={classNames('page-projects__contaner', {
            'page-projects__expanded': isMobile,
          })}>
          {isLoading && <Loader />}
          {projectGeneral.projectId ? (
            <>
              {isMobile && (
                <div className='page-projects__header'>
                  {this.renderHeadBlock()}
                </div>
              )}
              <Tabs
                mode={isMobile ? undefined : 'vertical'}
                theme='brand'
                defaultActiveTabIndex={this.props.currentTabIndex}
                needUpdateDefaultTab
                onBeforeChange={onChangeTab}
                separate
                customClass='ui-tabs_page-projects page-projects__tabs'>
                {!isMobile && (
                  <Tab
                    title={this.renderHeadBlock()}
                    className='ui-tab_page-projects-header'
                    isDisabled
                  />
                )}

                <Tab
                  title={getTranslate('projects.categories.general')}
                  icon='im-Configurator'>
                  <div className='page-projects__block'>
                    {this.renderGeneral()}
                  </div>
                </Tab>
                <Tab
                  title={getTranslate('projects.categories.paymentMethods')}
                  icon='im-Card-pay'>
                  <div className='page-projects__methods'>
                    <PaymentMethods />
                  </div>
                </Tab>
                {isEnabled(Messages.ProjectCallbackSettings_List) && (
                  <Tab
                    title={getTranslate('projects.categories.callbacks')}
                    icon='im-Code'>
                    <CallBacks />
                  </Tab>
                )}
                <Tab
                  title={getTranslate('projects.categories.paymentUrls')}
                  icon='im-External-Link'>
                  <Redirects />
                </Tab>

                {isPaymentPageEditorEnabled && (
                  <Tab
                    title={getTranslate(
                      'projects.categories.constructor.label'
                    )}
                    icon='im-Design'
                    id='constructor'>
                    <Constructor />
                  </Tab>
                )}
                {isPaymentPageDesignerVersionFiveEnabled && (
                  <Tab
                    title={getTranslate(
                      'projects.categories.designerVersionFive.label'
                    )}
                    icon='im-Design'
                    id='constructor-spa'>
                    <ConstructorVersionFive
                      showModal={this.showStyleEditorModal}
                    />
                  </Tab>
                )}

                {isCashVoucherEnabled ? (
                  <Tab
                    id='fiscal-receipt'
                    title={getTranslate('fiscalReceipt.Info.label')}
                    icon='im-Chargebacks'>
                    <FiscalReceipt />
                  </Tab>
                ) : null}
              </Tabs>
            </>
          ) : (
            !isLoading && (
              <div className='page-projects__no-content'>
                <NoContent />
              </div>
            )
          )}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  projectId: state.projects.project.projectId,
  paymentPageDesignerVersionFiveUrl:
    state.projects.paymentPageDesignerVersionFiveUrl,
});

export default connect(mapStateToProps)(
  addTranslation(addPermissions(Projects))
);
