import Grid from 'components/grid';
import quickBookIcon from 'images/codatAccounting/quickbook.svg';
import sageIcon from 'images/codatAccounting/sage.svg';
import xeroIcon from 'images/codatAccounting/xero.svg';
import './accounting-statement-banner.scss';
import Icon from 'components/ui/icon';

export const Banner: React.FC = () => (
  <Grid
    direction='row'
    justify='space-between'
    align='center'
    className='accounting-statement-banner'>
    <div className='accounting-statement-banner__text-container'>
      <div className='accounting-statement-banner__title'>
        Accounting Statement Requests
      </div>
      <div className='accounting-statement-banner__description'>
        We kindly request access to your company's accounting information. This
        will help us better determine the level of financial health of your
        organization, enabling us to offer you the most advantageous and optimal
        conditions.
        <br />
        <br />
        For these purposes, we utilize the Codat.io service - the most reliable
        and technologically advanced platform for exchanging financial
        information.
        <br />
        <br />
        Please select your company below and follow the prompts on the screen.
      </div>
    </div>
    <div className='accounting-statement-banner__image'>
      <Grid direction='row' align='center'>
        <img
          className='accounting-statement-banner__provider-icon'
          src={quickBookIcon}
          alt='quickbook-icon'
        />
        <img
          className='accounting-statement-banner__provider-icon'
          src={sageIcon}
          alt='sage-icon'
        />
        <img
          className='accounting-statement-banner__provider-icon'
          src={xeroIcon}
          alt='xero-icon'
        />
        <div>or</div>
        <Grid
          className='accounting-statement-banner__upload-documents-sign'
          direction='column'
          align='center'
          justify='center'>
          <Icon name='im-Upload' className='file-upload__icon' size={16} />
          <div>Upload</div>
        </Grid>
      </Grid>
    </div>
  </Grid>
);
