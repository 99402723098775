import DateHelpers from 'helpers/Date';
import csvIcon from 'images/fileFormats/csv.svg';
import excelIcon from 'images/fileFormats/excel.svg';
import wordIcon from 'images/fileFormats/word.svg';
import imagesIcon from 'images/fileFormats/images.svg';
import pdfIcon from 'images/fileFormats/pdf.svg';
import powerPointIcon from 'images/fileFormats/power-point.svg';
import unknownIcon from 'images/fileFormats/unknown.svg';

export const getIcon = (fileName: string) => {
  const type = fileName.split('.').pop();

  switch (type) {
    case 'csv':
      return csvIcon;
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
      return excelIcon;
    case 'docx':
    case 'doc':
      return wordIcon;
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return imagesIcon;
    case 'pdf':
      return pdfIcon;
    case 'pptx':
    case 'pptm':
    case 'ppt':
      return powerPointIcon;
    default:
      return unknownIcon;
  }
};

export const getFileSize = (size: number) => {
  const _1MB = 1024;
  const sizeKB = size / _1MB;

  if (sizeKB < _1MB) {
    return `${sizeKB.toFixed(1)} KB`;
  }

  return `${(sizeKB / _1MB).toFixed(1)} MB`;
};

export const getDateFromResponse = ({ objectName }) => {
  const lastPiece = objectName.split('-').pop();
  const dateString = lastPiece.split('.').shift();
  return DateHelpers.getFormat(
    DateHelpers.createDate(dateString, 'YYYYMMDDHHmmss'),
    'crmDate'
  );
};
