import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Animation from 'components/ui/animation';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';
import modes from 'components/ui/buttonsGroup/modes';
import Input from 'components/ui/input';
import DateFilter from 'components/dateFilter';
import FiltersButton from 'components/filters/FiltersButton';
import filtersKeys from 'constants/filters';
import { QuickMyTeamFiltersType } from 'types/QuickFilters';
import { filtersKeysType } from 'types/Filters';
import { AnyObject } from 'types/Common';
import tableNames from 'constants/tableNames';
import './quickFilters.scss';

interface Props extends IntlProps {
  filters: QuickMyTeamFiltersType;
  onChangeFilter: (key: filtersKeysType, value: any) => void;
  onChangeCurrentTable: (tableName) => void;
  tableName: string;
  filtersValues: AnyObject;
  onResetFilters: () => void;
  hasSelectedQuickFilters: boolean;
  hasSelectedFilters: boolean;
  isAdmin: boolean;
}

class QuickFilters extends PureComponent<Props> {
  static defaultProps = {
    onChangeFilter: () => null,
  };

  render() {
    const {
      tableName,
      onResetFilters,
      hasSelectedQuickFilters,
      hasSelectedFilters,
      isAdmin,
    } = this.props;

    return (
      <div className='quick-filters quick-filters_team'>
        <Animation>
          <div className='quick-filters__inner'>
            {!hasSelectedFilters && (
              <>
                {tableName === tableNames.teamActivities && (
                  <div className='quick-filters__item'>
                    {this.renderUserIP()}
                  </div>
                )}
                <div className='quick-filters__item'>
                  {this.renderUserName()}
                </div>
                <div className='quick-filters__item'>{this.renderRole()}</div>
                <div className='quick-filters__item'>{this.renderStatus()}</div>
                {tableName === tableNames.teamActivities && (
                  <div className='quick-filters__item'>
                    {this.renderPeriods()}
                  </div>
                )}
              </>
            )}
            <div className='quick-filters__item quick-filters__item_settings'>
              <div className='quick-filters__filter-settings ui-buttons-group_single-choice'>
                <div className='ui-buttons-group__inner'>
                  <Button
                    status='danger-light'
                    id='reload'
                    icon='im-Reset-filter'
                    onClick={onResetFilters}
                    customClass='ui-button_no-border'
                    disabled={!hasSelectedQuickFilters && !hasSelectedFilters}
                  />
                  {!isAdmin && <FiltersButton name={tableName} />}
                </div>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    );
  }

  renderTableType = () => {
    const { onChangeCurrentTable, tableName, getTranslate } = this.props;

    return (
      <ButtonsGroup
        activeButtons={[tableName]}
        mode='singleRequired'
        onClick={(values) => {
          const [value] = values;
          onChangeCurrentTable(value);
        }}
        className='ui-buttons-group_single-choice'>
        <Button
          status='light'
          id={filtersKeys.teamUsers}
          text={getTranslate('myteam.quickFilters.reporttype.users')}
        />
        <Button
          status='light'
          id={filtersKeys.teamActivities}
          text={getTranslate('myteam.quickFilters.reporttype.users.activities')}
        />
      </ButtonsGroup>
    );
  };

  renderUserName = () => {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return (
      <Input
        placeholder={getTranslate('myteam.quickFilters.username.hint')}
        id='team-enter-name'
        value={filters[filtersKeys.userName]}
        onChange={(e) => {
          onChangeFilter(filtersKeys.userName, e.target.value);
        }}
        customClass='ui-input_quick-filter'
      />
    );
  };

  renderStatus = () => {
    const { filters, onChangeFilter, getTranslate, filtersValues } = this.props;
    if (!filtersValues.userStatus) return null;
    return (
      <ButtonsGroup
        activeButtons={filters[filtersKeys.status]}
        mode='multi'
        onClick={(values) => {
          onChangeFilter(filtersKeys.status, values);
        }}>
        {(filtersValues.userStatus || []).map((item) => {
          return (
            <Button
              status='light'
              id={item.id}
              text={getTranslate(
                `myteam.quickfilters.status.${item.text.toLowerCase()}`
              )}
              key={item.text}
            />
          );
        })}
      </ButtonsGroup>
    );
  };

  renderRole = () => {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return (
      <ButtonsGroup
        activeButtons={filters[filtersKeys.role]}
        mode={modes.multi}
        onClick={(values) => {
          onChangeFilter(filtersKeys.role, values);
        }}>
        <Button
          status='light'
          id='operations'
          text={getTranslate('myteam.quickfilters.role.operation')}
        />
        <Button
          status='light'
          id='finance'
          text={getTranslate('myteam.quickfilters.role.finance')}
        />
        <Button
          status='light'
          id='support'
          text={getTranslate('myteam.quickfilters.role.support')}
        />
        <Button
          status='light'
          id='risk'
          text={getTranslate('myteam.quickfilters.role.risk')}
        />
      </ButtonsGroup>
    );
  };

  renderUserIP = () => {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return (
      <Input
        placeholder={getTranslate('myteam.quickfilters.IPplaceholder.hint')}
        id='team-enter-ip'
        value={filters[filtersKeys.ip]}
        onChange={(e) => onChangeFilter(filtersKeys.ip, e.target.value)}
        customClass='ui-input_quick-filter'
      />
    );
  };

  renderPeriods = () => {
    const { filters, onChangeFilter } = this.props;

    return (
      <DateFilter
        filterKey={filtersKeys.teamDate}
        onApplyRange={this.applyRange}
        onChangeFilter={onChangeFilter}
        date={filters[filtersKeys.teamDate]}
        customClass='date-filter_fixed-mobile'
      />
    );
  };

  applyRange = ({ from, to }) => {
    const { onChangeFilter } = this.props;

    onChangeFilter(filtersKeys.teamDate, {
      type: 'custom',
      values: [from, to],
    });
  };
}

export default addTranslation(QuickFilters);
