import React, { Fragment, PureComponent } from 'react';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import tableNames from 'constants/tableNames';
import { Column, Header, Row } from 'components/ui/table';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import DynamicTable from 'components/ui/table/DynamicTable';
import EditableColumn from './components/column';
import './lcUpload.scss';

const tableConfig = [
  { id: 'token', content: 'Token' },
  { id: 'en', content: 'EN' },
  { id: 'ru', content: 'RU' },
  { id: 'status', content: 'Status' },
  { id: 'delete', content: 'Delete' },
];

interface Props {
  data: any;
  ticket: string;
  isUploading: boolean;
  onReset: () => void;
  onRoute: () => void;
  onStartUpload: () => void;
  onDeleteRow: (row: number) => void;
  onChangeData: (row: number, key: string, value: string) => void;
  onInputForm: (node: any) => void;
  onChangeTicket: (ticket: string) => void;
}

@wrapAppWithCssClass('layout-app_tablet-width')
class LcUpload extends PureComponent<Props> {
  private form;

  render() {
    const { data, ticket, onRoute, onStartUpload, onChangeTicket } = this.props;

    return (
      <div className='page-lc-upload'>
        <div className='page-lc-upload__top'>
          <Button
            status='primary'
            text='Back to manage'
            customClass='page-lc-upload__button'
            onClick={onRoute}
          />
          <div className='page-lc-upload__top-right'>
            <Input
              id='lc-upload-ticket'
              value={ticket}
              customClass='page-lc-upload__input-ticket'
              placeholder='Enter ticket'
              onChange={({ target: { value } }) => onChangeTicket(value)}
            />
            <Button
              status='success'
              text='Start upload'
              disabled={!data || !ticket}
              customClass='page-lc-upload__button'
              onClick={onStartUpload}
            />
          </div>
        </div>
        {data ? this.renderTable() : this.renderForm()}
        <div className='page-lc-upload__bottom'>
          <Button
            status='danger'
            text='Reset'
            customClass='page-lc-upload__button'
            onClick={this.reset}
          />
        </div>
      </div>
    );
  }

  renderForm = () => {
    return (
      <Fragment>
        <div
          ref={(el) => {
            this.form = el;
          }}
          className='page-lc-upload__form'
          contentEditable={true}
          suppressContentEditableWarning={true}
          onInput={this.onInput}
        />
      </Fragment>
    );
  };

  renderTable() {
    const { data } = this.props;

    return (
      <DynamicTable
        items={data}
        tableName={tableNames.lcUpload}
        rowRender={this.renderRow}
        customConfiguration={tableConfig}
        headerRender={this.renderTableHeader()}
        customClass='lc-upload-table'
      />
    );
  }

  renderRow = (data: any, index) => {
    const { isUploading, onChangeData, onDeleteRow } = this.props;

    return (
      <Row customClass='lc-manage-table__row'>
        {tableConfig.map((config) => {
          const content = data[config.id];

          return (
            <EditableColumn
              columnWidths={data.columnWidths}
              key={config.id}
              rowIndex={index}
              configId={config.id}
              data={content}
              uploadStatus={data.uploadStatus}
              isUploading={isUploading}
              onChange={onChangeData}
              onDeleteRow={onDeleteRow}
            />
          );
        })}
      </Row>
    );
  };

  renderTableHeader() {
    return (
      <Header>
        {tableConfig.map((column) => {
          return (
            <Column
              key={column.id}
              id={column.id}
              content={column.content}
              modifier={column.id}
            />
          );
        })}
      </Header>
    );
  }

  reset = () => {
    const { onReset } = this.props;
    if (this.form) {
      this.form.innerHTML = '';
    }
    onReset();
  };

  onInput = () => {
    const { onInputForm } = this.props;
    onInputForm(this.form);
  };
}

export default LcUpload;
