import React, { PureComponent } from 'react';
import { Buffer } from 'buffer';
import { getApsIcons } from 'api/icons';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import PageTemplate from 'components/pageTemplate';
import DataListContainer from 'components/dataListContainer';
import TopPanelContainer from './components/topPanel';
import { Column, Row } from 'components/ui/table';
import Button from 'components/ui/button';
import tableNames from 'constants/tableNames';
import ItemConfiguration from 'types/ItemConfiguration';
import { AnyObject } from 'types/Common';
import Env from 'helpers/Env';
import { minIconsTableColumnsWidth } from './constants';
import './icons.scss';

interface Props {
  configuration: ItemConfiguration[];
  canRender: boolean;
  onEdit: (data: AnyObject) => void;
  addItem: (data: AnyObject) => void;
}

@wrapAppWithCssClass('layout-app_tablet-width')
class Icons extends PureComponent<Props> {
  render() {
    const { canRender, addItem } = this.props;

    return (
      <PageTemplate.Main customClass='page-icons'>
        <PageTemplate.Container>
          <TopPanelContainer onCreateItem={addItem} />
          {canRender && (
            <DataListContainer
              minColumnsWidth={minIconsTableColumnsWidth}
              tableName={tableNames.apsIcon}
              customClass='table-icons'
              apiRequest={getApsIcons}
              rowRender={this.renderRow}
            />
          )}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderRow = (data) => {
    const { configuration, onEdit } = this.props;

    return (
      <Row customClass='page-icons__table-row'>
        {configuration.map((column) => {
          const { id } = column;
          let content = data[id];

          if (id === 'activate') {
            content = (
              <Button
                onClick={() => onEdit(data)}
                icon='im-Edit1'
                size='small'
                status='light'
              />
            );
          } else if (id === 'iconLink' && data[id]) {
            const image = data['fileData'];
            const buff = Buffer.from(image);
            const base64data = buff.toString('base64');
            content = (
              <>
                <span className='page-icons__icon-wrapper'>
                  <img
                    className='page-icons__icon'
                    src={`data:image/svg+xml;base64,${base64data}`}
                    alt={data['paymentMethodName']}
                  />
                </span>
                <a
                  href={`${Env.getIconHost()}${content}`}
                  download
                  className='ui-link'>
                  Download
                </a>
              </>
            );
          }

          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              content={content || '–'}
              customClass={`ui-table__column_${id}`}
            />
          );
        })}
      </Row>
    );
  };
}

export default Icons;
