import Loader from '../loader';
import NoContent from '../noContent';
import './table.scss';

type SimpleTableProps<ItemType> = {
  customClass?: string;
  isFetch: boolean;
  items: ItemType[];
  noContentText?: string;
  headerRender: React.ReactNode;
  rowRender: (item: ItemType) => React.ReactNode;
};

export const SimpleTable = <ItemType,>({
  isFetch,
  headerRender,
  noContentText,
  items,
  rowRender,
}: SimpleTableProps<ItemType>) => {
  return (
    <div className='ui-table ui-table_fixed'>
      <div className='ui-table__header ui-table__header_simple'>
        {headerRender}
      </div>
      <div className='ui-table-content ui-table-content_simple'>
        {items.length === 0 && <NoContent text={noContentText} />}
        {isFetch && <Loader />}
        {items.map((item, index) => {
          return (
            <div className='ui-table__row ui-table__row_simple' key={index}>
              {rowRender(item)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
