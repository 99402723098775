import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Tooltip from 'react-tooltip';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';

import tokenApi from 'api/tokens';
import api from 'api/user';
import apiLocalization from 'api/localization';
import { initApp } from 'actions/app';
import getWl from 'api/wl';
import { enableApiLogs } from 'api/logs';
import { StoreProps } from 'store';

import { ErrorBoundary } from 'components/errorBoundary';
import NotificationContainer from 'components/ui/notification/NotificationContainer';
import ModalContainer from 'components/modal/ModalContainer';
import TooltipInfo from 'components/ui/tooltipInfo';
import AppContainer from 'pages/app/AppContainer';
import PolicyWidget from 'pages/app/components/policyWidget';

import publicRoutes from 'config/publicRoutes';
import LocalStorage from 'helpers/LocalStorage';
import { addListeners } from 'decorators/addListeners';
import axiosMiddleware from 'helpers/axiosMiddleware';
import Token from 'helpers/Token';
import Utils from 'helpers/Utils';
import Env from 'helpers/Env';
import Repository from 'helpers/Repository';
import path from 'helpers/path';
import DateHelpers from 'helpers/Date';
import TagManager from 'react-gtm-module';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import TooltipHtml from 'components/ui/tooltipInfo/TooltipHtml';
import { WithRouterProps } from 'decorators/withRouter';
import { InjectIntlContext } from 'hooks/useTranslation';

interface ConnectedProps {
  user: AnyObject;
  app: AnyObject;
  settings: AnyObject;
}

type Props = ConnectedProps & StoreProps & WithRouterProps;

interface State {
  instanceId: string;
  canRenderApp: boolean;
  isFetchIntl: boolean;
  lang: string;
  messages: AnyObject;
}

@addListeners([Messages.Redirect, Messages.Auth_LoginToken])
class StartupContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const instanceId = Utils.getHash();
    this.state = {
      instanceId,
      canRenderApp: false,
      isFetchIntl: false,
      lang: 'en',
      messages: {},
    };

    this.attachEvents();

    axiosMiddleware({ instanceId });
  }

  async componentDidMount() {
    const { history } = this.props;
    const { redirect_token: key } = queryString.parse(history.location.search);

    if (key) {
      history.replace({});
      await tokenApi.updateToken(key);
    } else {
      await this.startApp();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { user, location, settings } = this.props;

    if (!prevProps.user && user) {
      this.setLocale();
    } else if (
      prevProps.user &&
      user &&
      prevProps.user.interfaceLang !== user.interfaceLang
    ) {
      this.setLocale();
    }

    if (prevProps.location.pathname !== location.pathname) {
      Tooltip.hide();
    }

    if (!isEqual(prevProps.settings, settings)) {
      this.setFavicon();
      this.setTitle();
      this.setDescription();
      this.setGoogleTagManager();
    }
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  startApp = async () => {
    const { dispatch } = this.props;
    const { instanceId } = this.state;

    dispatch(initApp({ instanceId }));
    this.setRepository();
    this.setActiveTab();
    this.setLocale();
    this.setAppVersion();
    this.setCurrentBuilder();
    this.setFavicon();
    this.setTitle();
    this.setDescription();
    this.setGlobalMethods();
    await this.fetchLocalization();
    await this.fetchUser();
    await getWl();
    this.setGoogleTagManager();
    this.setState({ canRenderApp: true });
  };

  setActiveTab = () => {
    const { instanceId } = this.state;
    if (instanceId) {
      LocalStorage.set('instanceId', instanceId);
    }
  };

  attachEvents = () => {
    window.addEventListener('focus', this.setActiveTab);
  };

  detachEvents = () => {
    window.removeEventListener('focus', this.setActiveTab);
  };

  fetchUser = async () => {
    const { history } = this.props;
    const { authToken } = Token.getTokens();
    const pathname = history.location.pathname;
    const isPublicRoute = !!publicRoutes.find(
      (route) => route.path === path(pathname)
    );
    const isLoginPage = pathname === path('/login');

    if (!isPublicRoute) {
      if (authToken) {
        try {
          await api.getUser();
        } catch (error) {
          console.error(error);
        }
      } else {
        history.push(path('/login'), { from: pathname });
      }
    } else if (isLoginPage) {
      if (authToken) {
        try {
          await api.getUser();
          history.push(path('/'));
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  setGoogleTagManager = () => {
    const {
      settings: { gtmId },
    } = this.props;

    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  };

  setLocale() {
    const { user } = this.props;
    let lang: string;

    if (user && user.interfaceLang) {
      lang = user.interfaceLang;
      DateHelpers.setLocale(lang);
    } else {
      if (!LocalStorage.get('noAuthLang')) {
        LocalStorage.set('noAuthLang', 'en');
      }
      lang = LocalStorage.get('noAuthLang');
      DateHelpers.setLocale(lang);
    }

    TagManager.dataLayer({
      dataLayer: {
        interfaceLang: lang,
      },
    });

    this.setState({ lang });
    document.documentElement.setAttribute('lang', lang);
  }

  setAppVersion() {
    if (Env.isProduction()) {
      window['appVersion'] = Env.getAppVersion();
    }
  }

  // currentBuilder - ITSEC naming convention, this is for security reasons
  setCurrentBuilder() {
    if (Env.isProduction()) {
      window['currentBuilder'] = Env.getCurrentBuilder();
    }
  }

  setGlobalMethods() {
    window['enableApiLogs'] = enableApiLogs;
  }

  setRepository() {
    const { history } = this.props;
    Repository.set('history', history);
  }

  fetchLocalization = async () => {
    try {
      const { dictionaries } = await apiLocalization.getLocalization();
      Repository.set('messages', dictionaries);
      this.setState({ messages: dictionaries, isFetchIntl: true });
    } catch (error) {
      console.error(error);
    }
  };

  setFavicon = () => {
    const {
      settings: { themeConfig },
    } = this.props;
    const link = document.querySelector('link[rel*=icon]');

    if (link && themeConfig?.faviconUrl) {
      link.setAttribute('href', themeConfig.faviconUrl);
    }
  };

  setTitle = () => {
    const {
      settings: { htmlDocumentTitle },
    } = this.props;

    if (htmlDocumentTitle) {
      document.title = htmlDocumentTitle;
    }
  };

  setDescription = () => {
    const {
      settings: { htmlDocumentDescription },
    } = this.props;
    const meta = document.querySelector('meta[name=description]');

    if (meta && htmlDocumentDescription) {
      meta.setAttribute('content', htmlDocumentDescription);
    }
  };

  onEvent = async ({ data, name }) => {
    if (name === Messages.Redirect) {
      const { location } = window;
      let redirectUrl = data.payload.redirectUrl;

      if (Env.isDevelopment()) {
        const { pathname, search } = new URL(redirectUrl);
        redirectUrl = location.origin + pathname + search;
      }

      location.replace(redirectUrl);
    } else if (name === Messages.Auth_LoginToken) {
      await this.startApp();
    }
  };

  render() {
    const { user }: any = this.props;
    const { canRenderApp, messages, isFetchIntl, lang } = this.state;

    return (
      <Fragment>
        {canRenderApp && isFetchIntl && (
          <ErrorBoundary>
            <IntlProvider
              locale={lang}
              messages={messages[lang]}
              key={lang}
              onError={() => null}>
              <InjectIntlContext>
                <div className='layout-app'>
                  <Switch>
                    {publicRoutes.map((route, index) => {
                      const PublicComponent = route.component;

                      return (
                        <Route
                          key={`${route.path}${index}`}
                          exact={route.exact}
                          path={route.path}
                          render={(props) => {
                            return <PublicComponent {...props} user={user} />;
                          }}
                        />
                      );
                    })}
                    <Route path={'/'} render={() => <AppContainer />} />
                  </Switch>

                  <PolicyWidget />
                  <ModalContainer />
                  <TooltipInfo />
                  <TooltipHtml />
                </div>
              </InjectIntlContext>
            </IntlProvider>
          </ErrorBoundary>
        )}
        <NotificationContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  app: state.app,
  settings: state.settings,
});

export default withRouter(connect(mapStateToProps)(StartupContainer));
