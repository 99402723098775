import { each } from 'lodash-es';
import BaseMessageHandler from '../BaseMessageHandler';

export default class Qa_GetApiLogs extends BaseMessageHandler {
  success = () => {
    const {
      message: { payload },
    } = this;
    const { logs } = payload;

    try {
      each(logs, (ids, service) => {
        console.log(`Service:%c ${service}`, 'font-weight: bold');
        each(ids, ({ request, response, rpc }, key) => {
          if (request?.data) {
            console.log(
              '%cRequest:',
              'color: #3498db',
              parse({ ...request.data, id: key })
            );
          }
          if (response?.data) {
            console.log('%cResponse:', 'color: #2ecc71', parse(response.data));
          }
          if (rpc) {
            console.log('%cRpc:', 'color: #9b59b6', parse(rpc));
          }
        });
        console.log('');
      });
    } catch (error) {
      console.error(error);
    }
  };
}

function parse(data) {
  const parsedData = {};
  each(data, (value, key) => {
    parsedData[key] = value;
    try {
      if (typeof value === 'string') {
        parsedData[key] = JSON.parse(value);
      }
    } catch (e) {}
  });
  return parsedData;
}
