import React from 'react';
import SubmittedForm from 'components/formFields/components/submittedForm';
import './mafReviewInformer.scss';

interface Props {
  onButtonClick: () => void;
}

const MafReviewInformer: React.FC<Props> = ({ onButtonClick }) => (
  <div className='maf-review-informer__conteiner'>
    <div className='maf-review-informer__content card'>
      <SubmittedForm
        id='dafWaiting'
        status='success'
        title='businessDocs.waitingForDocsVerification.label'
        text='businessDocs.waitingForDocsVerification.text'
        primaryButtonText='businessDocs.backToList.button'
        onPrimaryButtonClick={onButtonClick}
      />
    </div>
  </div>
);

export { MafReviewInformer };
