import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import api from 'api/projects';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import CreateCallbackForm from './CreateCallbackForm';
import showNotification from 'components/ui/notification/showNotification';
import { ProjectSettingParams } from 'pages/projects/components/callbacks/types';
import { AnyObject } from 'types/Common';
import SelectItem from 'types/SelectItem';
import Messages from 'constants/rpcTypes';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { getMethods } from 'creators/paymentMethods';
import { loadDictionary } from 'api/dictionaries';
import './createCallbackForm.scss';

interface OwnProps {
  content: {
    isNotAvailable: (message: string) => boolean;
  };
}

interface ConnectedProps {
  projectId: string;
  permissions: AnyObject[];
  resultTypeValues: SelectItem[];
  transactionTypeValues: SelectItem[];
}

type Props = OwnProps & ConnectedProps & IntlProps;

interface State {
  isLoading: boolean;
  formChanged: boolean;
  formValues: ProjectSettingParams;
  availableResultTypes: SelectItem[];
  validationErrors: AnyObject;
  paymentMethods: SelectItem[];
}

const initialState = {
  isLoading: false,
  formChanged: false,
  formValues: {
    url: '',
    resultType: '',
    transactionType: '',
    paymentMethodId: '',
  },
  availableResultTypes: [],
  validationErrors: {},
  paymentMethods: [],
};

class CreateCallbackFormContainer extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.setPaymentMethods();
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.props.projectId !== prevProps.projectId) {
      this.setState(
        {
          ...initialState,
        },
        this.setPaymentMethods
      );
    }
  }

  render() {
    const { transactionTypeValues } = this.props;
    const {
      formValues,
      isLoading,
      validationErrors,
      formChanged,
      availableResultTypes,
      paymentMethods,
    } = this.state;

    return (
      <CreateCallbackForm
        formChanged={formChanged}
        validationErrors={validationErrors}
        isLoading={isLoading}
        onCreateProjectSetting={this.createProjectSetting}
        transactionTypeValues={transactionTypeValues}
        availableResultTypes={availableResultTypes}
        paymentMethods={paymentMethods}
        formValues={formValues}
        onChange={this.onChange}
      />
    );
  }

  createProjectSetting = async () => {
    const { content } = this.props;
    if (content.isNotAvailable(Messages.ProjectCallbackSettings_Create)) return;

    try {
      this.setState({ isLoading: true });
      await api.createProjectCallbackSettings({
        projectId: this.props.projectId,
        ...this.state.formValues,
      });
    } catch ({ payload, error }) {
      this.setState({ validationErrors: payload?.validationErrors || {} });
      if (error && payload?.validationErrors?.projectId) {
        showNotification({
          status: 'error',
          content: payload?.validationErrors?.projectId,
        });
      }
    } finally {
      this.setState({ isLoading: false, formChanged: false });
    }
  };

  onChange = (key: string, value: any) => {
    const { formValues, validationErrors } = this.state;
    const errors = { ...validationErrors, [key]: '' };
    const values = { ...formValues, [key]: value };

    if (key === 'url' && value.length > 255) {
      errors.url = 'common.tooLongString.sysmsg';
    }
    errors.resultType = this.validateResultType(values);

    let availableResultTypes = this.state.availableResultTypes;

    if (key === 'transactionType') {
      availableResultTypes = this.getResultTypes(value);
      values.resultType = '';
    }

    this.setState(() => ({
      formValues: values,
      validationErrors: errors,
      formChanged: true,
      availableResultTypes,
    }));
  };

  getResultTypes = (value) => {
    const { resultTypeValues, transactionTypeValues } = this.props;

    const resultTypeSet = transactionTypeValues.find((item) => {
      return item.value === value;
    });

    return (
      (resultTypeSet &&
        resultTypeValues.filter((resultType) => {
          return resultTypeSet.resultTypes.find(
            (item) => item === resultType.value
          );
        })) ||
      []
    );
  };

  validateResultType = (values) => {
    const { transactionType, resultType } = values;
    if (
      resultType &&
      transactionType === 'account_verification' &&
      resultType !== 'success' &&
      resultType !== 'decline'
    ) {
      return 'projects.callbacks.transactionTypeValidation.error';
    } else if (resultType === 'error' && transactionType !== 'all') {
      return 'projects.callbacks.resultTypeValidation.error';
    }
    return '';
  };

  setPaymentMethods = async () => {
    const paymentMethods = await loadDictionary(
      {
        name: 'paymentMethod',
        params: { projectId: this.props.projectId },
      },
      false
    );
    this.setState({ paymentMethods: getMethods(paymentMethods.elements) });
  };
}

const mapStateToProps = (state) => {
  const { projectCallbackSettings: values } = state.filtersValues || {};

  if (!values.list) {
    return {};
  }

  return {
    projectId: state.projects.project.projectId,
    permissions: state.permissions,
    resultTypeValues: getCustomSelectItems({
      list: values.list.resultType,
    }),
    transactionTypeValues: getCustomSelectItems({
      list: values.list.transactionType,
      additionalParam: 'resultTypes',
    }),
  };
};

export default addTranslation(
  connect(mapStateToProps)(CreateCallbackFormContainer)
);
