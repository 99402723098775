export default {
  USER_FIRST_LOGIN: 'user_first_login',
  USER_BLOCKED_BY_PASSWORD_OR_TFA_BY_ATTEMPTS:
    'user_blocked_by_password_or_tfa_attempts',
  USER_PASSWORD_EXPIRES_SOON: 'user_password_expires_soon',
  USER_ROLES_OR_PERMISSIONS_CHANGED: 'user_roles_or_permissions_changed',
  PAYMENT_OPERATION_WAS_SUCCESSFUL: 'payment_operation_was_successful',
  PAYMENT_OPERATION_TYPE_CAPTURE_WAS_UNSUCCESSFUL:
    'payment_operation_type_capture_was_unsuccessful',
  PAYMENT_OPERATION_TYPE_CANCEL_WAS_UNSUCCESSFUL:
    'payment_operation_type_cancel_was_unsuccessful',
  PAYMENT_OPERATION_TYPE_REFUND_WAS_UNSUCCESSFUL:
    'payment_operation_type_refund_was_unsuccessful',
  PAYMENT_OPERATION_TYPE_PAYOUT_WAS_UNSUCCESSFUL:
    'payment_operation_type_payout_was_unsuccessful',
  PAYMENT_OPERATION_BATCH_FINALIZED: 'payment_operation_batch_finalized',
  PAYMENT_OPERATION_BATCH_AWAITING_CLARIFICATION:
    'payment_operation_batch_awaiting_clarification',
  PAYMENT_OPERATION_ONE_ACCOUNT_REPEAT: 'payment_operation_one_account_repeat ',
  PAYMENT_OPERATION_SUM_EXCEEDED: 'payment_operation_sum_exceeded',
  PAYMENT_OPERATION_TO_MANY_PER_PERIOD: 'payment_operation_to_many_per_period',
  REPORT_CREATED: 'report_created',
  REPORT_FAILED: 'report_failed',
  INVOICE_SALE_OPERATION_WAS_SUCCESSFUL:
    'invoice_sale_operation_was_successful',
  CHARGEBACK_ACCEPT_WAS_UNSUCCESSFUL: 'chargeback_accept_was_unsuccessful',
  CHARGEBACK_ACCEPT_WAS_SUCCESSFUL: 'chargeback_accept_was_successful',
  USER_HAS_NEW_CHARGEBACKS: 'user_has_new_chargebacks',
  USER_HAS_NEW_CHARGEBACKS_PRE_ARBITRATION:
    'user_has_new_chargebacks_pre_arbitration',
  USER_HAS_NEW_CHARGEBACKS_ARBITRATION: 'user_has_new_chargebacks_arbitration',
  USER_HAS_EXPIRING_CHARGEBACKS: 'user_has_expiring_chargebacks',
  REMITTANCE_PAYMENT_WAS_UNSUCCESSFUL: 'remittance_payment_was_unsuccessful',
  REMITTANCE_PAYMENT_WAS_SUCCESSFUL: 'remittance_payment_was_successful',
  SHOW_PAF: 'show_paf',
  TYPE_PAF_READY_TO_FILL: 'paf_ready_to_fill',
  BANK_ACCOUNT_DETAILS_APPROVED: 'user_has_approved_bank_account_details',
  BANK_ACCOUNT_DETAILS_NEED_TO_BE_APPROVED:
    'user_has_bank_account_details_need_to_be_corrected',
  TRANSFER_BALANCE_REJECTED: 'user_has_rejected_balance_transfer',
  TRANSFER_BALANCE_NEED_CORRECTION: 'user_has_correction_in_balance_transfer',
  TRANSFER_BALANCE_SUCCESS: 'user_has_succeeded_balance_transfer',
  SETTLEMENT_REQUEST_SUCCESS: 'user_has_succeeded_settlement_request',
  SETTLEMENT_REQUEST_REJECT: 'user_has_rejected_settlement_request',
  SETTLEMENT_REQUEST_CORRECTION: 'user_has_correction_in_settlement_request',
  SETTLEMENT_REQUEST_REFUSE: 'user_has_refused_settlement_request',
  SETTLEMENT_REQUEST_SENT: 'user_has_sent_settlement_request',
} as const;
