import React, { PureComponent, RefObject } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import Button from 'components/ui/button';
import DateHelpers from 'helpers/Date';
import Utils from 'helpers/Utils';
import { getMaxDate, getMinDate } from 'components/ui/datePicker/helpers';
import { AnyObject } from 'types/Common';
import './manualInputCalendar.scss';
import './manualInputCalendarModern.scss';
import Icon from 'components/ui/icon';

interface Props extends IntlProps {
  value: { dateFrom: string | Date; dateTo?: string | Date };
  onChange: (date) => void;
  id?: string;
  mode?: string;
  isRange?: boolean;
  isDisable?: boolean;
  isOpenedCalendar?: boolean;
  isInnerBtn?: boolean;
  withTime?: boolean;
  isDisabled?: boolean;
  error?: string;
  label?: string;
  minDate?: string;
  maxDate?: string;
  placeholder?: string;
  customClass?: string;
  modern?: boolean;
  disableButton?: boolean;
  needIcon?: boolean;
  onOpenCalendar?: (e) => void;
  onClick?: (e) => void;
  onFocus?: (e) => void;
}

interface State {
  dateFrom: string;
  dateTo: string;
  timeFrom: string;
  timeTo: string;
  isFocus: boolean;
  cleaveOptions: AnyObject;
}

const DATE_LENGTH = 10;
const TIME_LENGTH = 8;
const className = 'ui-manual-input-calendar';

class ManualInputCalendar extends PureComponent<Props, State> {
  dateFromRef: RefObject<any>;
  timeFromRef: RefObject<any>;
  dateToRef: RefObject<any>;
  timeToRef: RefObject<any>;

  static defaultProps = {
    withTime: true,
    id: Utils.getHash(),
  };

  constructor(props) {
    super(props);
    this.state = {
      dateFrom: '',
      dateTo: '',
      timeFrom: '',
      timeTo: '',
      isFocus: false,
      cleaveOptions: {},
    };

    this.dateFromRef = React.createRef();
    this.timeFromRef = React.createRef();
    this.dateToRef = React.createRef();
    this.timeToRef = React.createRef();
  }

  componentDidMount() {
    this.setFields();
    this.attachEvents();
    this.setCleaveOptions();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setFields();
    }

    if (
      prevProps.minDate !== this.props.minDate ||
      prevProps.maxDate !== this.props.maxDate
    ) {
      this.setCleaveOptions(() => {
        this.setState({ dateFrom: '', dateTo: '' }, this.setFields);
      });
    }
  }

  render() {
    const {
      id,
      onOpenCalendar,
      isRange,
      isInnerBtn,
      withTime,
      placeholder,
      label,
      isDisabled,
      mode,
      modern,
      error,
      onClick,
      getTranslate,
      customClass,
      disableButton,
      needIcon,
      onFocus,
    } = this.props;
    const { dateFrom, timeFrom, dateTo, timeTo, isFocus, cleaveOptions } =
      this.state;

    const placeholderText = () => {
      if (placeholder) return placeholder;
      if (!withTime) {
        return 'common.enterDate.placeholder';
      }
      return isRange
        ? 'common.enterDate.period.placeholder'
        : 'common.enterDate.oneDate.placeholder';
    };
    return (
      <div
        className={classNames('ui-manual-input-calendar', customClass, {
          'ui-manual-input-calendar_range': isRange,
          'ui-manual-input-calendar_modern': modern,
          'ui-manual-input-calendar_inner-btn': isInnerBtn,
          'ui-manual-input-calendar_error': error,
          'ui-manual-input-calendar_disabled': isDisabled,
        })}>
        <div
          className='ui-manual-input-calendar__wrapper'
          id={id}
          onClick={onClick}>
          {label && (
            <div className='ui-manual-input-calendar__label'>{label}</div>
          )}
          <div className='ui-manual-input-calendar__row'>
            {modern && (
              <Button
                icon='im-Calendar'
                iconSize={16}
                onClick={(e) => {
                  onOpenCalendar && onOpenCalendar(e);
                }}
                customClass='ui-manual-input-calendar__btn'
              />
            )}
            <div
              className={classNames('ui-manual-input-calendar__field', {
                'ui-manual-input-calendar__field_prefix': needIcon,
              })}
              onClick={(e) => {
                this.setFocus(e);
              }}>
              {!dateFrom && !dateTo && !isFocus ? (
                <span className='ui-manual-input-calendar__placeholder'>
                  {getTranslate(placeholderText())}
                </span>
              ) : (
                <div className='calendar-date-n-range-wrapper'>
                  <Input
                    id={`ui-manual-input-calendar-date-${id}-${mode || 'from'}`}
                    placeholder=''
                    value={dateFrom}
                    onChange={(e) => this.onChange(e.target, 'dateFrom')}
                    cleaveOptions={cleaveOptions.date}
                    disabled={isDisabled}
                    inputContainerRef={this.dateFromRef}
                    customClass='ui-manual-input-calendar__date'
                    prefix={needIcon && <Icon size={16} name='im-Calendar' />}
                    onFocus={onFocus}
                  />
                  {withTime && (
                    <Input
                      id={`ui-manual-input-calendar-time-${id}-${
                        mode || 'from'
                      }`}
                      placeholder=''
                      inputContainerRef={this.timeFromRef}
                      value={timeFrom}
                      onChange={(e) => this.onChange(e.target, 'timeFrom')}
                      cleaveOptions={cleaveOptions.time}
                      disabled={isDisabled}
                      onFocus={() => this.moveFocusBack('timeFrom')}
                      customClass='ui-manual-input-calendar__time'
                    />
                  )}

                  {isRange && (
                    <span className='ui-manual-input-calendar__span is-range-date-time-separator'>
                      —
                    </span>
                  )}

                  {isRange && (
                    <div>
                      <div className='calendar-range-wrapper'>
                        <Input
                          id={`ui-manual-input-calendar-date-${id}-${
                            mode || 'to'
                          }`}
                          placeholder=''
                          inputContainerRef={this.dateToRef}
                          value={dateTo}
                          onChange={(e) => this.onChange(e.target, 'dateTo')}
                          cleaveOptions={cleaveOptions.date}
                          disabled={isDisabled}
                          customClass='ui-manual-input-calendar__date'
                        />
                        {withTime && (
                          <Input
                            id={`ui-manual-input-calendar-time-${id}-${
                              mode || 'to'
                            }`}
                            placeholder=''
                            inputContainerRef={this.timeToRef}
                            value={timeTo}
                            onChange={(e) => this.onChange(e.target, 'timeTo')}
                            cleaveOptions={cleaveOptions.time}
                            disabled={isDisabled}
                            onFocus={() => this.moveFocusBack('timeTo')}
                            customClass='ui-manual-input-calendar__time'
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!modern && !disableButton && (
              <Button
                icon='im-Calendar'
                status='outline'
                iconSize={16}
                onClick={(e) => {
                  onOpenCalendar && onOpenCalendar(e);
                }}
                customClass='ui-manual-input-calendar__btn'
              />
            )}
          </div>
        </div>
        {error && (
          <div className='ui-manual-input-calendar__error'>
            {getTranslate(error)}
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.detachEvents();
  }

  attachEvents = () => {
    document.body.addEventListener('click', this.blur);
  };

  detachEvents = () => {
    document.body.removeEventListener('click', this.blur);
  };

  setFocus = (e) => {
    if (
      !this.props.isDisabled &&
      e.target.classList.value.includes(className) &&
      !e.target.classList.value.includes('ui-button')
    ) {
      e.stopPropagation();
      this.setState({ isFocus: true }, () => {
        this.moveFocusBack('dateFrom');
      });
      this.props.onClick && this.props.onClick(e);
    }
  };

  blur = (e) => {
    if (this.props.isDisabled) return;
    const parent = `.${className}`;
    if (
      !e.target
        .closest(parent)
        ?.getAttribute('id')
        ?.includes(`${this.props.id}`) ||
      e.target.classList.value.includes('ui-button')
    ) {
      this.setState({ isFocus: false });
    }
  };

  setDate = () => {
    const { value, isRange, withTime } = this.props;

    this.setState({
      dateFrom: value.dateFrom ? this.formatDate(value.dateFrom, 'date') : '',
      timeFrom:
        withTime && value.dateFrom
          ? this.formatDate(value.dateFrom, 'time')
          : '',
    } as Pick<State, keyof State>);

    if (isRange) {
      this.setState({
        dateTo: value.dateTo ? this.formatDate(value.dateTo, 'date') : '',
        timeTo:
          withTime && value.dateTo ? this.formatDate(value.dateTo, 'time') : '',
      } as Pick<State, keyof State>);
    }
  };

  formatDate = (date, format) => {
    if (date) {
      return DateHelpers.getFormat(
        DateHelpers.createDate(date, 'datetime'),
        format
      );
    }
    return '';
  };

  setValue = () => {
    const { dateFrom, timeFrom, dateTo, timeTo } = this.state;
    let timeStart = '',
      timeEnd = '';
    const isToMode = this.props.mode === 'to';
    if (dateFrom.length === DATE_LENGTH && this.props.withTime) {
      timeStart = timeFrom || (isToMode ? `23:59:59` : `00:00:00`);
      timeEnd = timeTo || `23:59:59`;
    }

    this.props.onChange({
      dateFrom: this.startDate(dateFrom, timeStart),
      dateTo: this.startDate(dateTo, timeEnd),
    });
  };

  startDate = (date, time) => {
    if (this.props.withTime) {
      return date && time ? `${date} ${time}` : '';
    }
    return date;
  };

  setFields = () => {
    this.setDate();
  };

  onChange = (target, name) => {
    let timeStart = this.state.timeFrom || '',
      timeEnd = this.state.timeTo || '';
    const { value } = target;
    const { withTime } = this.props;
    const isDate = value.length === DATE_LENGTH;
    const isTime = value.length === TIME_LENGTH;
    const isToMode = this.props.mode === 'to';

    if (withTime) {
      if (name === 'dateFrom' && isDate) {
        timeStart = this.state.timeFrom || (isToMode ? `23:59:59` : `00:00:00`);
      } else if (name === 'dateFrom' && value.length === 0) {
        timeStart = '';
      }
      if (name === 'dateTo' && isDate) {
        timeEnd = this.state.timeTo || `23:59:59`;
      } else if (name === 'dateTo' && value.length === 0) {
        timeEnd = '';
      }
    }

    this.setState(
      (prevState) =>
        ({
          [name]: value,
          timeFrom:
            name === 'timeFrom' ? value : prevState.timeFrom || timeStart,
          timeTo: name === 'timeTo' ? value : prevState.timeTo || timeEnd,
        } as Pick<State, keyof State>),
      () => {
        if (
          (name.includes('date') && (isDate || value.length === 0)) ||
          (name.includes('time') && (isTime || value.length === 0))
        ) {
          this.setValue();
        }

        this.moveFocus(name, target.selectionStart);
      }
    );
  };

  moveFocusBack = (name: 'timeFrom' | 'timeTo' | 'dateFrom') => {
    const inputClass = '.ui-input__field';
    if ((name === 'timeFrom' || name === 'dateFrom') && !this.state.dateFrom) {
      this.dateFromRef.current.querySelector(inputClass).focus();
    } else if (name === 'timeTo' && !this.state.dateTo) {
      this.dateToRef.current.querySelector(inputClass).focus();
    }
  };

  moveFocus = (name, caret) => {
    if (
      (name.includes('date') && caret !== DATE_LENGTH) ||
      (name.includes('time') && caret !== TIME_LENGTH)
    )
      return;

    switch (name) {
      case 'dateFrom': {
        if (!this.props.isRange) {
          if (!this.props.withTime) return;
          this.resetCaret(this.timeFromRef);
        } else {
          this.resetCaret(this.dateToRef);
        }
        return;
      }
      case 'dateTo': {
        if (!this.props.withTime) return;
        this.setState({ timeTo: '23:59:59' });
        this.resetCaret(this.timeToRef);
        return;
      }
      case 'timeFrom': {
        if (!this.props.isRange) return;
        this.resetCaret(this.dateToRef);
      }
    }
  };

  resetCaret = (ref) => {
    const element = ref.current.querySelector('.ui-input__field');
    element.focus();

    element.selectionStart = 0;
    element.selectionEnd = 0;
  };

  getMinDate = () => {
    const date = getMinDate(this.props.minDate);
    if (date) {
      return DateHelpers.createDate(date).format('YYYY-MM-DD');
    }
  };

  getMaxDate = () => {
    const date = getMaxDate(this.props.maxDate);
    if (date) {
      return DateHelpers.createDate(date).format('YYYY-MM-DD');
    }
  };

  setCleaveOptions = (callback?) => {
    this.setState(
      {
        cleaveOptions: {
          time: {
            time: true,
            timePattern: ['h', 'm', 's'],
            delimiters: [':'],
          },
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y'],
            delimiters: ['.'],
            dateMax: this.getMaxDate(),
            dateMin: this.getMinDate(),
          },
        },
      },
      callback
    );
  };
}

export default addTranslation(ManualInputCalendar);
