import React from 'react';
import { useTranslation } from 'hooks';
import { MafDigest, MafPreview } from 'components/maf/types';
import CustomSelect from 'components/ui/customSelect/CustomSelect';
import { LegalEntity, MafListOption } from './types';
import tableNames from 'constants/tableNames';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Header, Row } from 'components/ui/table';
import { LinkText } from './components/LinkText';
import { StatusTag } from './components/StatusTag';
import { DateDisplay } from './components/DateDisplay';
import './businessDocumentsList.scss';

interface Props {
  digest?: MafDigest;
  legalEntities?: LegalEntity[];
  selectedLegalEntity?: MafListOption;
  goToForm: (id: string, caption: string, wasMafOpen: boolean) => void;
  changeSelectedEntity: (selectedEtity: MafListOption) => void;
}

const BusinessDocumentsListView: React.FC<Props> = ({
  digest,
  selectedLegalEntity,
  legalEntities = [],
  changeSelectedEntity,
  goToForm,
}) => {
  const getTranslate = useTranslation();

  const renderHeader = () => {
    return (
      <Header>
        <Column
          key='action'
          id='action'
          content={getTranslate('businessdocuments.doc.action')}
        />
        <Column
          key='name'
          id='name'
          content={getTranslate('businessdocuments.doc.name')}
        />
        <Column
          key='status'
          id='status'
          content={getTranslate('businessdocuments.doc.status')}
        />
        <Column
          key='created'
          id='created'
          content={getTranslate('businessdocuments.doc.created')}
        />
        <Column
          key='updated'
          id='updated'
          content={getTranslate('businessdocuments.doc.updated')}
        />
      </Header>
    );
  };

  const rowRender = (maf: MafPreview) => {
    const {
      crm_id,
      caption,
      boarding_status,
      maf_was_open,
      created_on,
      updated_on,
    } = maf;

    return (
      <Row
        key={crm_id}
        customClass='ui-table__row_clickable'
        onClick={() => goToForm(crm_id, caption, maf_was_open)}>
        <Column
          id={crm_id + '_action'}
          customClass='merchant-legal-entity-table__cell'
          content={<LinkText status={boarding_status} />}
        />
        <Column
          id={crm_id + '_name'}
          customClass='merchant-legal-entity-table__cell'
          content={caption}
        />
        <Column
          id={crm_id + '_status'}
          customClass='merchant-legal-entity-table__cell'
          content={
            boarding_status && (
              <StatusTag
                status={boarding_status}
                wasMafUpdated={maf_was_open}
              />
            )
          }
        />
        <Column
          customClass='merchant-legal-entity-table__cell'
          id={crm_id + '_created'}
          content={<DateDisplay dateString={created_on} />}
        />
        <Column
          customClass='merchant-legal-entity-table__cell'
          id={crm_id + '_updated'}
          content={<DateDisplay dateString={updated_on} />}
        />
      </Row>
    );
  };

  return (
    <>
      <div className='merchant-legal-entity-custom-select-wrapper'>
        <CustomSelect
          customClass='merchant-legal-entity-custom-select'
          theme='light'
          modern
          placeholder={' '}
          value={{
            value: selectedLegalEntity?.value,
            label: selectedLegalEntity?.label,
          }}
          options={legalEntities?.map((le) => ({
            value: le.id,
            label: le.title,
          }))}
          onChange={changeSelectedEntity}
        />
      </div>
      <div className='merchant-legal-entity-table'>
        <DynamicTable
          isFetch={!digest?.mafs}
          resizableColumns={false}
          noContentText='add.entities.info.text'
          items={digest?.mafs || [{}]}
          headerRender={renderHeader()}
          rowRender={rowRender}
          tableName={tableNames.merchantLegalEntities}
        />
      </div>
    </>
  );
};

export { BusinessDocumentsListView };
