import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArrayMove from 'array-move';
import { withRouter } from 'react-router-dom';

import { WithRouterProps } from 'decorators/withRouter';
import { changeArticlePosition, getMenu } from 'api/help';
import { setArticles } from 'actions/help';
import { openModal } from 'actions/modal';
import { StoreProps } from 'store';
import path from 'helpers/path';
import HelpArticle from '../../types';
import HelpMenuTab from './HelpMenuTab';

interface OwnProps {
  showSubmenu: boolean;
}

interface ConnectedProps {
  isFetched: boolean;
  articles: HelpArticle[];
  language: string;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  WithRouterProps<{ slug?: string }>;

class HelpMenuTabContainer extends Component<Props> {
  async componentDidMount() {
    const { isFetched } = this.props;
    if (isFetched) return;
    await this.setArticles();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      articles,
      match: { params },
    } = this.props;

    if (
      (!prevProps.articles.length && articles.length) ||
      (prevProps.match.params.slug && !params.slug && articles.length)
    ) {
      if (params.slug /* || isSearchResults */) return;
      this.goToArticle(articles[0].slug);
    }
  }

  render() {
    const { articles, isFetched, showSubmenu, match, location } = this.props;

    return (
      <HelpMenuTab
        currentSlug={match.params.slug}
        hash={location.hash}
        isFetched={isFetched}
        showSubmenu={showSubmenu}
        articles={articles}
        onOpenModal={() => this.openModal()}
        onChangePosition={this.handleChangePosition}
        onClick={this.goToArticle}
      />
    );
  }

  async setArticles() {
    const { language } = this.props;
    await getMenu(language);
  }

  handleChangePosition = ({ oldIndex, newIndex }) => {
    const { articles, dispatch } = this.props;

    const copyArticles: HelpArticle[] = JSON.parse(JSON.stringify(articles));
    const newArticles: HelpArticle[] = ArrayMove(
      copyArticles,
      oldIndex,
      newIndex
    ).map((article: HelpArticle, index) => {
      return {
        ...article,
        sortMenuIndex: index,
      };
    });

    dispatch(setArticles(newArticles));

    const articlesToSend = newArticles
      .filter(({ sortMenuIndex }, index) => {
        return newIndex > oldIndex ? index >= oldIndex : index >= newIndex;
      })
      .map(({ articleId, sortMenuIndex }) => {
        return {
          articleId,
          sortMenuIndex,
        };
      });
    return changeArticlePosition(articlesToSend);
  };

  goToArticle = (slug: string) => {
    const { history } = this.props;
    history.push(path(`/help/${slug}`));
  };

  openModal = (content?) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'AddHelpArticle',
        content,
      })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  isFetched: state.help.isFetched,
  articles: state.help.articles,
  language: state.user.interfaceLang || 'en',
});

export default withRouter(connect(mapStateToProps)(HelpMenuTabContainer));
