import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'hooks';
import Icon from 'components/ui/icon';
import { MafHelpers } from 'components/maf/helpers';
import { MafStepsType, MafTab } from 'components/maf/types';
import Utils from 'helpers/Utils';
import { AnyObject } from 'types/Common';
import './mafSteps.scss';

interface Props {
  form: MafTab | undefined;
  validationErrors: AnyObject;
  currentStep: number;
  tabs?: MafTab[];
  isFormLocked: boolean;
  onChangeStep: (index: number) => void;
}

const iconsMap = {
  //foremaf
  [MafStepsType.GENERAL]: 'im-Forms',
  [MafStepsType.UBOS]: 'im-Avatar',
  //simplemaf
  [MafStepsType.BASIC_INFO]: 'im-Forms',
  [MafStepsType.UBO]: 'im-Avatar',
  [MafStepsType.WEBSITE_AND_APPS]: 'im-Desktop',
  [MafStepsType.SIGNATORIES]: 'im-My-team',
  [MafStepsType.DETAILS]: 'im-Reports',
  [MafStepsType.OVERVIEW]: 'im-List',
  //daf
  [MafStepsType.DAF_INFO]: 'im-Avatar',
  [MafStepsType.DAF_MERCH]: 'im-House',
  [MafStepsType.DAF_UBO]: 'im-My-team',
  [MafStepsType.DAF_MANAGERS]: 'im-My-team',
  [MafStepsType.DAF_CONTACTS]: 'im-Avatar',
  [MafStepsType.DAF_ADD_CONTACTS]: 'im-Phone',
  [MafStepsType.DAF_SIGNERS]: 'im-Edit',
  [MafStepsType.DAF_DIRECTORS]: 'im-My-team',
  [MafStepsType.DAF_SHAREHOLDERS]: 'im-My-team',
  [MafStepsType.DAF_WEBSITE_INFO]: 'im-Desktop',
  [MafStepsType.DAF_LICENSING]: 'im-Document',
  [MafStepsType.DAF_AML]: 'im-Catalog',
  [MafStepsType.DAF_PREVENTION]: 'im-Risk',
  [MafStepsType.DAF_PAYIN]: 'im-Card-pay',
  [MafStepsType.DAF_PAYOUT]: 'im-Card-pay',
  [MafStepsType.DAF_ANTIFRAUD]: 'im-Configurator',
  [MafStepsType.DAF_ACCOUNT]: 'im-Marker',
  [MafStepsType.DAF_ADD_INFO]: 'im-References',
  [MafStepsType.DAF_DOCS_UPLOAD]: 'im-Forms',
  //daf additional
  [MafStepsType.ADDITIONAL_DAF_COMPANY_DOCS]: 'im-Forms',
  [MafStepsType.ADDITIONAL_DAF_UBO_DOCS]: 'im-Avatar',
  [MafStepsType.ADDITIONAL_DAF_DIRECTOR_DOCS]: 'im-My-team',
  [MafStepsType.ADDITIONAL_DAF_SHAREHOLDERS_DOCS]: 'im-Single-Payout',
};

const MafSteps: React.FC<Props> = ({
  tabs,
  currentStep,
  isFormLocked,
  validationErrors,
  onChangeStep,
}) => {
  const getTranslate = useTranslation();
  const { validatedTabs } = useSelector((state: RootState) => state.maf);

  const getStepLayout = (step: MafTab, index: number) => {
    const isValid = MafHelpers.validateSection(step, validationErrors);
    const groupByTag = step?.tabs ? step.tabs[0]?.group_by_tag : null;
    const text = getTranslate(groupByTag || step.caption);

    const validatedAndInvalid = validatedTabs[step.name] && !isValid;

    const isPending = step.tabs?.some((tab) => {
      return (
        !MafHelpers.validateSection(tab, validationErrors) &&
        MafHelpers.isTabAwaitingClarification(tab)
      );
    });

    const defaultStepLayout = (
      <>
        <Icon
          className={classNames('maf-steps__icon', {
            'maf-steps__icon_current': currentStep === index,
          })}
          size={16}
          name={
            iconsMap[groupByTag?.toLowerCase() || step.caption?.toLowerCase()]
          }
        />
        <span>{text}</span>
      </>
    );

    if (isFormLocked) {
      return defaultStepLayout;
    }

    if (!isValid && isPending) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_pending'
            size={16}
            name='im-Refill'
          />
          <span className='maf-steps__text_pending'>{text}</span>
        </>
      );
    }

    if (validatedAndInvalid) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_error'
            size={16}
            name='im-Warning-2'
          />
          <span className='maf-steps__text_error'>{text}</span>
        </>
      );
    }

    if (validatedTabs[step.name] && isValid) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_success'
            size={16}
            name='im-Tick'
          />
          <span className='maf-steps__text_success'>{text}</span>
        </>
      );
    }

    return defaultStepLayout;
  };

  const updatedTabs = useMemo(() => {
    const defaultTab = {
      name: 'overview.maf.tab',
      caption: 'overview.maf.tab',
    } as MafTab;

    if (!tabs) {
      return [defaultTab];
    }

    const modified = tabs.map((tab) => {
      if (tab.caption === '') {
        return {
          ...tab,
          caption: tab.tabs[0].caption,
          name: tab.tabs[0].name,
        };
      }
      return tab;
    });

    return [...modified, defaultTab];
  }, [tabs]);

  const renderStep = (step: MafTab, index: number) => {
    return (
      <div
        key={`${step.name}${Utils.getHash()}`}
        className={classNames('maf-steps__item', {
          'maf-steps__item_current': currentStep === index,
        })}
        onClick={() => onChangeStep(index)}>
        <span className='maf-steps__item-inner'>
          {getStepLayout(step, index)}
        </span>
      </div>
    );
  };

  return <div className='maf-steps'>{updatedTabs.map(renderStep)}</div>;
};

export { MafSteps };
