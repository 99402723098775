import React, { PureComponent, PropsWithChildren } from 'react';
import ReactDropZone from 'react-dropzone';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import FileTemplate from 'components/ui/fileTemplate';
import './dropzone.scss';
import { getFileIcon } from './helpers/getFileIcon';

interface Props extends IntlProps {
  onDrop: (files: File[]) => void;
  file: File | null;
  onDeleteFile?: () => void;
  isFileUploading?: boolean;
  getRef?: any;
  acceptFiles?: string;
  noClick?: boolean;
  noKeyboard?: boolean;
  customClass?: any;
  errorMessage?: string;
  theme?: 'small';
  showText?: boolean;
  fileIcon?: string;
  label?: string;
}

export const DROPZONE_CLASSNAME = 'ui-drop-zone__default';

class Dropzone extends PureComponent<PropsWithChildren<Props>> {
  dropZoneRef;

  static defaultProps = {
    noClick: true,
    noKeyboard: true,
    showText: true,
    isFileUploading: false,
    customClass: '',
    onDeleteFile: () => null,
  };

  render() {
    const {
      onDrop,
      noClick,
      noKeyboard,
      getRef,
      acceptFiles,
      customClass,
      theme,
      isFileUploading,
    } = this.props;
    const isEmptyError = this.props.errorMessage && !this.props.file;
    return (
      <ReactDropZone
        onDrop={(files) => onDrop(files)}
        ref={(el) => {
          this.dropZoneRef = el;
          getRef && getRef(el);
        }}
        noClick={noClick}
        noKeyboard={noKeyboard}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section
            className={classNames('ui-drop-zone', {
              'ui-drop-zone_drag': isDragActive,
              [customClass]: !!customClass,
              'ui-drop-zone_small': !!theme,
              'ui-drop-zone_error': isEmptyError,
            })}>
            <div className='ui-drop-zone__wrapper'>
              <div className='ui-drop-zone__inner' {...getRootProps()}>
                <input {...getInputProps()} accept={acceptFiles} />
                {isFileUploading && <Loader />}
                {this.renderContent()}
              </div>
            </div>
            {isEmptyError && (
              <div className='ui-drop-zone__error'>
                {this.props.errorMessage}
              </div>
            )}
          </section>
        )}
      </ReactDropZone>
    );
  }

  renderContent() {
    const { isFileUploading, children } = this.props;
    if (children) {
      return children;
    }

    return (
      <div className='ui-drop-zone__content'>
        {!isFileUploading && this.renderFileBlock()}
      </div>
    );
  }

  renderFileBlock() {
    const { file, showText, onDeleteFile, errorMessage, getTranslate } =
      this.props;

    if (file) {
      return (
        <>
          <div className='ui-drop-zone__text ui-drop-zone__text_loaded'>
            <span>{getTranslate('common.dragDrop.loadedTitle')}</span>
            {this.renderLoadButton()}
          </div>
          <FileTemplate
            id={file.name}
            title={file.name}
            size={file.size}
            loadedIcon={getFileIcon(file)}
            onDelete={onDeleteFile}
            loadable={false}
            error={errorMessage}
          />
        </>
      );
    }

    return (
      <div className={classNames(DROPZONE_CLASSNAME, {})}>
        <div className={`${DROPZONE_CLASSNAME}-text`}>
          {showText && (
            <>
              <Icon name='im-Upload' className='ui-drop-zone__icon' />
              {getTranslate('common.dragDrop.part1')}{' '}
            </>
          )}
          {this.renderLoadButton()}
        </div>
      </div>
    );
  }

  renderLoadButton = () => {
    const { file, isFileUploading, label, getTranslate } = this.props;
    const text =
      file || isFileUploading
        ? getTranslate('common.dragDrop.replaceFile')
        : `${getTranslate('common.dragDrop.browse')} ${getTranslate(
            'common.dragDrop.part2'
          )}`;

    return (
      <Button
        onClick={this.openFileSystem}
        text={getTranslate(label) || text}
        icon={file || isFileUploading ? 'im-Upload' : ''}
        iconPosition='left'
        size='inline'
        status='link'
        customClass='file-picker-button'
      />
    );
  };

  openFileSystem = () => {
    const { dropZoneRef } = this;
    if (dropZoneRef.open) {
      dropZoneRef.open();
    }
  };
}

export default addTranslation(Dropzone);
