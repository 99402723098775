import { forEach } from 'lodash-es';
import { createSelector } from 'reselect';

const getFilters = (state, name, onlyUserData = false) => {
  return {
    onlyUserData,
    quickFilters: state.quickFilters[name],
  };
};

export default createSelector(getFilters, ({ quickFilters, onlyUserData }) => {
  const values = {};
  forEach(quickFilters, (filter, key) => {
    if (
      typeof filter === 'number' ||
      filter?.length ||
      filter?.values?.length
    ) {
      values[key] = filter;
    }
  });

  return values;
});
