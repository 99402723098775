import React, { PureComponent, Fragment } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import tableNames from 'constants/tableNames';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import { Column, Header, Row } from 'components/ui/table';
import DynamicTable from 'components/ui/table/DynamicTable';
import Input from 'components/ui/input';
import Button from 'components/ui/button';
import Checkbox from 'components/ui/checkbox';
import Loader from 'components/ui/loader';
import SelectionList from 'components/ui/selectionList';
import Create from './components/create';

import { RowItem, Filters, TranslationVariant } from './LcManageTypes';
import './lcManage.scss';

const tableConfig = [
  { id: 'build_number', content: 'Build' },
  { id: 'ticket_number', content: 'Ticket' },
  { id: 'token', content: 'Token' },
  { id: 'en', content: 'EN' },
  { id: 'ru', content: 'RU' },
  { id: 'manage', content: 'Manage' },
];

interface Props extends IntlProps {
  list: RowItem[];
  filters: Filters;
  ticketsPreview: any[];
  isLoading: boolean;
  isFetched: boolean;
  onChangeFilter: (key: string, value: any) => void;
  onResetFilters: () => void;
  onCreateSuccess: (data: RowItem) => void;
  onSync: () => void;
  onEdit: (data: RowItem) => void;
  onRemove: (data: RowItem) => void;
  onChangeTicketsPreview: (items: any[]) => void;
}

@wrapAppWithCssClass('layout-app_desktop-width')
class LcManage extends PureComponent<Props> {
  render() {
    const {
      list,
      filters,
      ticketsPreview,
      isLoading,
      isFetched,
      onChangeFilter,
      onCreateSuccess,
      onResetFilters,
      onChangeTicketsPreview,
      onSync,
    } = this.props;

    return (
      <PageTemplate.Main customClass='page-lc-manage'>
        <PageTemplate.Container>
          <div className='page-lc-manage__controls'>
            <div className='page-lc-manage__controls-row'>
              <div className='page-lc-manage__controls-row-inner'>
                <SelectionList
                  items={filters.byStatus}
                  placeholder='Select status'
                  placeholderSelected='Selected status'
                  customClass='page-lc-manage__controls-status'
                  onChange={(items) => onChangeFilter('byStatus', items)}
                />
                <Input
                  id='byTask'
                  value={filters.byTask}
                  placeholder='Filter by ticket'
                  customClass='page-lc-manage__controls-input'
                  onChange={({ target }) =>
                    onChangeFilter('byTask', target.value)
                  }
                />
                <Input
                  id='byToken'
                  value={filters.byToken}
                  placeholder='Filter by token'
                  customClass='page-lc-manage__controls-input'
                  onChange={({ target }) =>
                    onChangeFilter('byToken', target.value)
                  }
                />
                <Input
                  id='byText'
                  value={filters.byText}
                  placeholder='Filter by text'
                  customClass='page-lc-manage__controls-input'
                  onChange={({ target }) =>
                    onChangeFilter('byText', target.value)
                  }
                />
                <Checkbox
                  text='Without text'
                  id='withoutText'
                  customClass='page-lc-manage__controls-checkbox'
                  checked={filters.withoutText}
                  onChange={({ target }) =>
                    onChangeFilter('withoutText', target.checked)
                  }
                />
                {this.canRenderResetButton() && (
                  <Button
                    status='primary'
                    id='filtersReset'
                    icon='close'
                    onClick={onResetFilters}
                    customClass='page-lc-manage__controls-reset'
                  />
                )}
              </div>
              <div className='page-lc-manage__controls-row-inner'>
                <SelectionList
                  items={ticketsPreview}
                  placeholder='Select tickets'
                  placeholderSelected='Selected tickets'
                  customClass='page-lc-manage__controls-tickets'
                  onChange={(items) => onChangeTicketsPreview(items)}
                />
                <Button
                  status='primary'
                  id='sync'
                  icon='autorenew'
                  onClick={onSync}
                  customClass='page-lc-manage__controls-sync'
                />
              </div>
            </div>
          </div>
          <Create onCreateSuccess={onCreateSuccess} />
          {isFetched && (
            <DynamicTable
              tableName={tableNames.lcManage}
              customConfiguration={tableConfig}
              items={list}
              rowRender={this.renderRow}
              headerRender={this.renderTableHeader()}
              customClass='lc-manage-table'
            />
          )}
          {isLoading && <Loader />}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderTableHeader() {
    return (
      <Header>
        {tableConfig.map(({ id, content }) => {
          return (
            <Column
              key={id}
              id={id}
              modifier={id}
              header={true}
              content={content}
            />
          );
        })}
      </Header>
    );
  }

  renderRow = (data: RowItem) => {
    const { onEdit, onRemove } = this.props;

    return (
      <Row customClass='lc-manage-table__row'>
        {tableConfig.map(({ id }) => {
          let content = data[id];
          let isTooltip = true;
          if (id === 'en' || id === 'ru') {
            isTooltip = false;
            if (data.variants) {
              content = this.renderTranslate(id, data.variants);
            }
          } else if (id === 'manage') {
            content = (
              <Fragment>
                <Button
                  iconSize={16}
                  icon='im-Edit1'
                  size='inline'
                  status='transparent'
                  disabled={!data.editable}
                  onClick={() => data.editable && onEdit(data)}
                />
                <Button
                  iconSize={16}
                  icon='im-Basket'
                  size='inline'
                  status='transparent'
                  disabled={!data.delitable}
                  onClick={() => data.delitable && onRemove(data)}
                />
              </Fragment>
            );
          }
          return (
            <Column
              key={id}
              id={id}
              columnWidths={data.columnWidths}
              isTooltip={isTooltip}
              content={content || '–'}
              customClass={`ui-table__column_${id}`}
            />
          );
        })}
      </Row>
    );
  };

  renderTranslate(alias: string, variants: TranslationVariant[]) {
    let isEmpty = true;
    const result = variants.map((variant: TranslationVariant, index) => {
      if (
        variant.texts &&
        variant.texts.find((item) => item.text && item.lang === alias)
      ) {
        isEmpty = false;
        const item = variant.texts.find((text) => text.lang === alias);
        if (!item || !item.text) {
          return null;
        }

        return (
          <div key={index} className='lc-manage-table__translate'>
            <span
              className='lc-manage-table__translate-inner'
              data-tip={item.text}>
              <span
                className={`lc-manage-table__translate-status lc-manage-table__translate-status_${variant.status}`}>
                {variant.status}
              </span>
              <span key={item.lang} className='lc-manage-table__translate-text'>
                {item.text}
              </span>
            </span>
          </div>
        );
      }
      return null;
    });

    return isEmpty ? null : result;
  }

  canRenderResetButton() {
    const { filters } = this.props;
    const { byTask, byToken, byText, byStatus, withoutText } = filters;
    return (
      byTask ||
      byToken ||
      byText ||
      byStatus.filter((val) => val.isSelected).length ||
      withoutText
    );
  }
}

export default addTranslation(LcManage);
