import DateHelpers from 'helpers/Date';

interface Props {
  dateString?: string;
}

const DateDisplay: React.FC<Props> = ({ dateString }) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const formattedDate = DateHelpers.formatDateForTableCell(date);

  return <div className='date-display'>{formattedDate}</div>;
};

export { DateDisplay };
