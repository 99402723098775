import ACTIONS from 'constants/actionTypes';
import tableNames from 'constants/tableNames';
import { Balance } from 'pages/finance/modules/statement/StatementFinanceTypes';
import ActionReducer from 'types/ActionReducer';

interface FormValues {
  [tableNames.balanceStatement]: Balance;
  [tableNames.users]: {
    pairdFilterValues: {
      merchantId: [];
      projectId: [];
      legalEntities: [];
    };
  };
}

const initialState: FormValues = {
  [tableNames.balanceStatement]: {
    isFetched: false,
    data: {
      pendingDates: {},
      totals: {},
      balances: [],
      columns: {
        operational: [],
        nonOperational: [],
      },
      reportParams: {
        reportDate: '',
        merchant: '',
        project: [],
        reportPeriod: [],
        balance: [],
        contract: [],
        contractWith: [],
      },
    },
    updateAt: null,
  },
  [tableNames.users]: {
    pairdFilterValues: {
      merchantId: [],
      projectId: [],
      legalEntities: [],
    },
  },
};

const formValues = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_FORM_VALUES:
      return {
        ...state,
        [payload.name]: payload.data,
      };
    case ACTIONS.RESET_CONCRETE_FORM_VALUES:
      return {
        ...state,
        [payload.name]: initialState[payload.name],
      };
    case ACTIONS.RESET_FORM_VALUES:
      return initialState;
    default:
      return state;
  }
};

export { formValues };
