import RpcTypes from 'constants/rpcTypes';

import BaseMessageHandler from '../BaseMessageHandler';
import Article_Create from './Article_Create';
import Article_Delete from './Article_Delete';
import Article_Menu from './Article_Menu';
import Article_Publish from './Article_Publish';
import Article_View from './Article_View';
import Auth_ConfirmSecondFactor from './Auth_ConfirmSecondFactor';
import Auth_ConfirmSecondFactorRequest from './Auth_ConfirmSecondFactorRequest';
import Auth_ConfirmSecondFactorStateRequest from './Auth_ConfirmSecondFactorStateRequest';
import Auth_Login from './Auth_Login';
import Auth_LoginAsSupport from './Auth_LoginAsSupport';
import Auth_LoginToken from './Auth_LoginToken';
import Confirm from './Confirm';
import Confirm_Psd2 from './Confirm_Psd2';
import Confirm_Telegram from './Confirm_Telegram';
import DemoStage_LandingData from './DemoStage_LandingData';
import DictionariesUpdated from './DictionariesUpdated';
import Dictionary_Load from './Dictionary_Load';
import GET_WL from './GET_WL';
import HelpTips_List from './HelpTips_List';
import HelpTips_MarkAsRead from './HelpTips_MarkAsRead';
import Invoice_Cancel from './Invoice_Cancel';
import InvoicesUpdated from './InvoicesUpdated';
import MessagesPermissions from './MessagesPermissions';
import Notification from './Notification';
import PaymentOperation_AvailableActions from './PaymentOperation_AvailableActions';
import PaymentOperation_PreFilledPayout from './PaymentOperation_PreFilledPayout';
import PaymentOperation_StatusUpdate from './PaymentOperation_StatusUpdate';
import PaymentOperationBatch_Updated from './PaymentOperationBatch_Updated';
import PaymentOperationReference_Create from './PaymentOperationReference_Create';
import Profile_Get from './Profile_Get';
import Profile_Update from './Profile_Update';
import Profile_UpdateLanguage from './Profile_UpdateLanguage';
import Profile_UpdateTimezone from './Profile_UpdateTimezone';
import Qa_EnableApiLogs from './Qa_EnableApiLogs';
import Qa_GetApiLogs from './Qa_GetApiLogs';
import Rcs_DeleteElement from './Rcs_DeleteElement';
import RedirectPayoutCard from './RedirectPayoutCard';
import Remittance_PaymentBatchUpdated from './Remittance_PaymentBatchUpdated';
import RemittanceQueue_StatusUpdated from './RemittanceQueue_StatusUpdated';
import ReportTemplate_Activate from './ReportTemplate_Activate';
import ReportTemplate_Deactivate from './ReportTemplate_Deactivate';
import ReportTemplateTaskHistory_Delete from './ReportTemplateTaskHistory_Delete';
import Team_DeleteUser from './Team_DeleteUser';
import Team_DisableUser from './Team_DisableUser';
import Team_EnableUser from './Team_EnableUser';
import Team_UpdateUser from './Team_UpdateUser';
import User_AccessUpdated from './User_AccessUpdated';
import User_Create from './User_Create';
import User_Delete from './User_Delete';
import User_Disable from './User_Disable';
import User_Enable from './User_Enable';
import User_Update from './User_Update';
import UserNotification from './UserNotification';
import FinancialData_FileUpdated from './FinancialData_FileUpdated';

const MessageHandlers: { [K in RpcTypes]: typeof BaseMessageHandler } = {
  Article_Create,
  Article_Delete,
  Article_Menu,
  Article_Publish,
  Article_View,
  Auth_ConfirmSecondFactor,
  Auth_ConfirmSecondFactorRequest,
  Auth_ConfirmSecondFactorStateRequest,
  Auth_Login,
  Auth_LoginAsSupport,
  Auth_LoginToken,
  Confirm,
  Confirm_Psd2,
  Confirm_Telegram,
  DemoStage_LandingData,
  DictionariesUpdated,
  Dictionary_Load,
  GET_WL,
  HelpTips_List,
  HelpTips_MarkAsRead,
  Invoice_Cancel,
  InvoicesUpdated,
  MessagesPermissions,
  Notification,
  PaymentOperation_AvailableActions,
  PaymentOperation_PreFilledPayout,
  PaymentOperation_StatusUpdate,
  PaymentOperationBatch_Updated,
  PaymentOperationReference_Create,
  Profile_Get,
  Profile_Update,
  Profile_UpdateLanguage,
  Profile_UpdateTimezone,
  Qa_EnableApiLogs,
  Qa_GetApiLogs,
  Rcs_DeleteElement,
  // @ts-ignore TODO t.melashchenko выручай. Кажется такого сообщения не бывает.
  RedirectPayoutCard,
  Remittance_PaymentBatchUpdated,
  RemittanceQueue_StatusUpdated,
  ReportTemplate_Activate,
  ReportTemplate_Deactivate,
  ReportTemplateTaskHistory_Delete,
  Team_DeleteUser,
  Team_DisableUser,
  Team_EnableUser,
  Team_UpdateUser,
  User_AccessUpdated,
  User_Create,
  User_Delete,
  User_Disable,
  User_Enable,
  User_Update,
  UserNotification,
  FinancialData_FileUpdated,
};

export default MessageHandlers;
