import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { openModal } from 'actions/modal';

interface Props {
  blockWhen: boolean;
  modalContent?: {
    title: string;
    text: string;
  };
  onLeaveConfirm?: () => void;
}

const defaultModalContent = {
  title: 'projects.urls.modal.urlChangeDiscard.header',
  text: 'projects.urls.modal.urlChangeDiscard.label',
};

const RouteLeavingGuard: React.FC<Props> = ({
  blockWhen,
  modalContent = defaultModalContent,
  onLeaveConfirm,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false);
  const [lastPath, setLastPath] = useState<string | null>(null);

  useEffect(() => {
    if (confirmed && lastPath) {
      if (onLeaveConfirm) {
        onLeaveConfirm();
      }

      history.push(lastPath);
      resetState();
    }
    // eslint-disable-next-line
  }, [confirmed, lastPath]);

  const resetState = () => {
    setConfirmed(false);
    setLastPath(null);
  };

  const handleRouteBlocking = (nextLocation) => {
    const { pathname } = nextLocation as Location;

    if (pathname === '/login') {
      return true;
    }

    if (!confirmed) {
      setLastPath(pathname);

      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            ...modalContent,
          },
          callback: (canLeave) => {
            if (canLeave) {
              setConfirmed(canLeave);
            }
          },
        })
      );
      return false;
    }

    return true;
  };

  return <Prompt when={blockWhen} message={handleRouteBlocking} />;
};

export { RouteLeavingGuard };
