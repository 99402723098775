import React, { Component } from 'react';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import Payout from './components/payout';
import { AnyObject } from 'types/Common';
import Action from './components/action';
import statuses from 'components/ui/button/statuses';
import classNames from 'classnames';
import { actionTypesT } from './components/action/ActionContainer';
import RoundButton from 'components/ui/roundButton';
import './paymentActions.scss';

interface Props extends IntlProps {
  transactionId: string;
  operationId?: string;
  projectId: string;
  availableActions: AnyObject;
  refundTooltip?: string;
  buttonTheme?: keyof typeof statuses;
  buttonType?: 'round';
  actionTypes: actionTypesT[];
  customClass?: string;
  startPing?: () => void;
  isEnabled?: boolean;
  startPingOnClick?: boolean;
  isLoading: boolean;
  disabled?: boolean;
  isOpenedPanel?: boolean;
  selectorPortal?: string;
}

class PaymentActions extends Component<Props> {
  componentDidMount() {
    Tooltip.rebuild();
  }

  getContent = () => {
    const {
      transactionId,
      operationId,
      projectId,
      availableActions,
      buttonTheme,
      buttonType,
      selectorPortal,
      actionTypes,
      isOpenedPanel,
      isEnabled,
    } = this.props;

    if (!availableActions) {
      return this.renderButtons();
    }

    return actionTypes.map((actionType) => {
      if (actionType === 'payout') {
        return (
          <Payout
            key='payment-action-payout'
            projectId={projectId}
            transactionId={transactionId}
            customClass='payment-actions__item'
            buttonTheme={buttonTheme}
          />
        );
      }

      const isRefund = actionType === 'refund';

      return (
        <Action
          key={`payment-action-${actionType}`}
          type={actionType}
          customClass='payment-actions__item'
          transactionId={transactionId}
          projectId={projectId}
          buttonTheme={buttonTheme}
          buttonType={buttonType}
          operationId={isRefund ? operationId : undefined}
          isOpenedPanel={isOpenedPanel}
          isEnabled={isEnabled}
          selectorPortal={selectorPortal}
        />
      );
    });
  };

  renderButtons = () => {
    const {
      isLoading,
      startPingOnClick,
      isEnabled,
      disabled,
      refundTooltip,
      getTranslate,
      startPing,
    } = this.props;

    if (isLoading) {
      return (
        <Icon
          size={18}
          name='im-Ellipse'
          className='rotating payment-actions__loader'
        />
      );
    }

    if (startPingOnClick && !isEnabled) {
      return (
        <RoundButton
          icon='im-Refund'
          iconSize={11}
          disabled={disabled}
          tooltipDelay={!disabled ? 800 : 0}
          tooltip={getTranslate(refundTooltip)}
          onClick={(e) => {
            e.stopPropagation();

            if (startPing) {
              startPing();
            }
          }}
        />
      );
    }
  };

  render() {
    const { customClass } = this.props;

    return (
      <div className={classNames('payment-actions', customClass)}>
        {this.getContent()}
      </div>
    );
  }
}

export default addTranslation(PaymentActions);
