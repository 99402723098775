import { MafHelpers } from '../helpers';
import { MafTab } from '../types';

export class ModalTabManager {
  private openedTabsStates: {
    isTemporal: boolean;
    currentValues: MafTab;
    initialValues: MafTab;
    isModified?: boolean;
  }[] = [];
  private updateVisibleTab: (tab: MafTab | null) => void;
  private mafTabNamesMap: Record<string, MafTab>;

  constructor(
    updateVisibleTab: (tab: MafTab | null) => void,
    mafTabNamesMap: Record<string, MafTab>
  ) {
    this.updateVisibleTab = updateVisibleTab;
    this.mafTabNamesMap = mafTabNamesMap;
  }

  openTab(tab: MafTab) {
    this.openedTabsStates.push({
      isTemporal: Boolean(tab.__temporal),
      currentValues: tab,
      initialValues: JSON.parse(JSON.stringify(tab)),
    });

    if (this.currentTab) {
      this.currentTab.__collapsed = true;
    }

    this.updateVisibleTab(this.currentTab);
  }

  changeCurrentTab(tab: MafTab) {
    if (!this.currentTab) {
      return;
    }
    delete this.currentTab.__collapsed;

    this.openedTabsStates.pop();
    this.openTab(tab);
  }

  closeCurrentTab() {
    if (!this.currentTab) {
      return;
    }

    const parent = this.mafTabNamesMap[this.currentTab.__parentFormId || ''];

    if (this.currentTabState.isTemporal) {
      MafHelpers.removeTabfromParent(this.currentTab.name, parent);
    } else {
      MafHelpers.restoreTabValues(
        this.currentTab,
        this.currentTabState.initialValues
      );
    }

    delete this.currentTab.__collapsed;

    this.openedTabsStates.pop();
    this.updateVisibleTab(this.currentTab);
  }

  setCurrentTabModified(isModified: boolean) {
    if (!this.currentTab) {
      return;
    }

    this.currentTabState.isModified = isModified;
  }

  private get currentTabState() {
    return this.openedTabsStates[this.openedTabsStates.length - 1];
  }

  get currentTab(): MafTab | null {
    return this.isModalTabOpened ? this.currentTabState.currentValues : null;
  }

  get tabs() {
    return this.openedTabsStates.map((tabState) => tabState.currentValues);
  }

  get isCurrentTabModified() {
    return Boolean(this.isModalTabOpened && this.currentTabState.isModified);
  }

  get openedTabsNumber() {
    return this.openedTabsStates.length;
  }

  get isModalTabOpened() {
    return this.openedTabsStates.length > 0;
  }
}
