import { useDispatch } from 'react-redux';
import { MainMenuItem } from 'pages/app/components/mainMenu/components/MainMenuItem';
import { MenuItem } from 'pages/app/components/mainMenu/types';
import './accounting-request-menu-item.scss';
import Messages from 'constants/rpcTypes';
import { ACTIVE_CODAT_STATUS } from 'pages/accountingStatementRequests/constant';

import { useEffect, useState } from 'react';

import { useEventListener } from 'hooks';
import { getAccountingRequests } from 'api/accouning';

import { AccountingRequestItem } from 'pages/accountingStatementRequests/types';
import { setAccountingRequests } from 'actions/accountingRequests';
import { Badge } from 'components/ui/badge';

type Props = {
  item: MenuItem;
  title: string;
};

const getActiveAccounitngRequestsCount = (requests: AccountingRequestItem[]) =>
  requests.filter((request) => request.codatStatus === ACTIVE_CODAT_STATUS)
    .length;

export const AccountingRequestMainMenuItem = ({ item, title }: Props) => {
  const [requestsCount, setRequestsCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountingRequests();
  }, []);

  useEventListener(
    {
      events: [Messages.AccountingDataRequest_List],
      onEvent: (
        _,
        data: {
          payload: { [key: string]: AccountingRequestItem };
        }
      ) => {
        if (!data?.payload) {
          return;
        }

        let accountingRequests;

        if (Array.isArray(data.payload)) {
          accountingRequests = data.payload;
        } else {
          const accountingRequestsObject = data.payload;
          accountingRequests = Object.values(accountingRequestsObject);
        }

        dispatch(setAccountingRequests(accountingRequests));
        setRequestsCount(getActiveAccounitngRequestsCount(accountingRequests));
      },
    },
    []
  );

  return (
    <div className='accounting-request-menu-item'>
      <MainMenuItem item={item} title={title} />
      {requestsCount > 0 && (
        <Badge
          position={{
            left: 21,
            top: -7,
          }}
          count={requestsCount}
          className='accounting-request-menu-item__count'
        />
      )}
    </div>
  );
};
