import { useTranslation } from 'hooks';
import {
  useGetMafPages,
  useMafPagesToolkit,
  useGetCurrentPageIndex,
} from '../PagesTabManager/hooks';
import { MafTab } from 'components/maf/types';

export const BreadCrumbsForPagesMode = ({ form }: { form: MafTab }) => {
  const getTranslate = useTranslation();
  const pages = useGetMafPages();
  const { goToPage } = useMafPagesToolkit();
  const currentPageIndex = useGetCurrentPageIndex();

  // we need breadcrumbs only for the first tab of the page
  if (
    pages[currentPageIndex]?.tabs &&
    pages[currentPageIndex]?.tabs?.findIndex(
      (tab) => tab.name === form.name
    ) !== 0
  ) {
    return null;
  }

  const crumbs = pages
    .slice(0, currentPageIndex + 1)
    .map(
      (page) => page.group_by_tag || page.tabs?.[0]?.group_by_tag || 'No Title'
    );

  return (
    <div className='maf-breadcrumbs-pages'>
      {crumbs.map((crumb, index) => {
        return (
          <Crumb
            key={index}
            crumbName={getTranslate(crumb)}
            onClick={() => goToPage(index)}
            isFirst={index === 0}
          />
        );
      })}
    </div>
  );
};

const Crumb = ({
  crumbName,
  onClick,
  isFirst,
}: {
  crumbName: string;
  onClick: () => void;
  isFirst: boolean;
}) => (
  <>
    {!isFirst && <span>{`>`}</span>}
    <div className='maf-breadcrumbs-pages__item' onClick={onClick}>
      {crumbName.toLowerCase()}
    </div>
  </>
);
