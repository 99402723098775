import Icon from 'components/ui/icon';
import I18nHelpers from 'i18n/I18nHelpers';
import { MenuItem } from '../types';

export const MainMenuItem: React.FC<{ item: MenuItem; title: string }> = ({
  item,
  title,
}) => (
  <>
    <Icon size={18} className='main-menu__item-icon' name={item.icon} />
    <div className='main-menu__item-text'>{I18nHelpers.getJsx(title)}</div>
  </>
);
