import { IFormDescriptorItem, IOperationsFormValues } from 'types/Analytics';
import { isOperationDmsEnabled } from './utils';

const operationTypeOptions = [
  {
    id: 'payout',
    text: 'Payout',
  },
  {
    id: 'refund',
    text: 'Refund',
  },
  {
    id: 'recurring',
    text: 'Recurring',
  },

  {
    id: 'capture',
    text: 'Capture',
  },
  {
    id: 'sale',
    text: 'Sale',
  },
  {
    id: 'auth',
    text: 'Auth',
  },
  {
    id: 'cancel',
    text: 'Cancel',
  },
  {
    id: 'account verification',
    text: 'Account verification',
  },
];

const chartParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.chartTitle.label',
    placeholder: 'analytics.editForm.params.chartTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartData.label',
    options: [
      {
        value: 'count',
        label: 'analytics.editForm.params.chartData.number.item',
      },
      {
        value: 'amount',
        label: 'analytics.editForm.params.chartData.channelAmount.item',
      },
      {
        value: 'sum_channel_amount_in_eur',
        label: 'analytics.editForm.params.chartData.channelAmountEUR.item',
      },
      {
        value: 'sum_channel_amount_in_usd',
        label: 'analytics.editForm.params.chartData.channelAmountUSD.item',
      },
      {
        value: 'avg_channel_amount',
        label: 'analytics.editForm.params.chartData.avgChannelAmount.item',
      },
      {
        value: 'avg_channel_amount_in_eur',
        label: 'analytics.editForm.params.chartData.avgChannelAmountEUR.item',
      },
      {
        value: 'avg_channel_amount_in_usd',
        label: 'analytics.editForm.params.chartData.avgChannelAmountUSD.item',
      },
      {
        value: 'unique_customers',
        label: 'analytics.editForm.params.chartData.uniqueCustomers.item',
      },
    ],
  },
  {
    id: 'groupBy',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupBy.label',
    options: [
      {
        value: 'projectId',
        label: 'analytics.editForm.params.groupBy.project.item',
      },
      {
        value: 'operationType',
        label: 'analytics.editForm.params.groupBy.operationType.item',
      },
      {
        value: 'operationStatus',
        label: 'analytics.editForm.params.groupBy.operationStatus.item',
      },
      {
        value: 'paymentMethod',
        label: 'analytics.editForm.params.groupBy.paymentMethodType.item',
      },
      {
        value: 'issuerCountry',
        label: 'analytics.editForm.filters.countryByBIN.label',
      },
      {
        value: 'country',
        label: 'analytics.editForm.params.groupBy.countryByIP.item',
      },
      {
        value: 'operationTypeDms',
        label: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
        permissionCallback: isOperationDmsEnabled,
      },
    ],
    onChangeCallback: (values) => {
      if (
        'operationTypeDms' in values &&
        'groupBy' in values &&
        values.groupBy !== 'operationTypeDms'
      ) {
        const { operationTypeDms, ...rest } = values as IOperationsFormValues;

        return { ...rest };
      }

      if (
        'operationType' in values &&
        'groupBy' in values &&
        values.groupBy === 'operationTypeDms'
      ) {
        const { operationType, ...rest } = values as IOperationsFormValues;

        return { ...rest };
      }

      return values;
    },
    conditionalOptions: [
      {
        data: [
          {
            value: 'currency',
            label: 'analytics.editForm.params.groupBy.channelCurrency.item',
          },
        ],
        condition: (values) =>
          ![
            'sum_channel_amount_in_eur',
            'sum_channel_amount_in_usd',
            'avg_channel_amount_in_eur',
            'avg_channel_amount_in_usd',
          ].includes(values.chartData),
      },
    ],
    renderOptions: {
      disabled: {
        condition: (values) =>
          values.chartData === 'amount' ||
          values.chartData === 'avg_channel_amount',
        fallBackValue: {
          value: 'currency',
          label: 'analytics.editForm.params.groupBy.channelCurrency.item',
        },
      },
      tooltipText: 'analytics.editForm.params.groupByAmount.toolTip',
    },
  },
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
        order: 2,
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
        order: 3,
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
        order: 4,
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
        order: 5,
      },
    ],
    conditionalOptions: [
      {
        data: [
          {
            value: 'all',
            label: 'analytics.editForm.params.groupPeriodBy.noGroup.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'chartType' in values && values.chartType !== 'line',
      },
    ],
    renderOptions: {
      disabled: {
        fallBackValue: {
          value: 'all',
          label: 'analytics.editForm.params.groupPeriodBy.noGroup.item',
        },
        condition: (values) =>
          'chartType' in values && values.chartType === 'pie chart',
      },
      tooltipText: 'analytics.editForm.params.groupByPie.toolTip',
    },
  },
  {
    id: 'chartType',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartType.label',
    options: [
      { value: 'bar', label: 'analytics.editForm.params.chartType.bar.item' },
      {
        value: 'pie chart',
        label: 'analytics.editForm.params.chartType.pie.item',
      },
      { value: 'line', label: 'analytics.editForm.params.chartType.line.item' },
    ],
  },
];

const filters: Array<IFormDescriptorItem> = [
  {
    id: 'projects',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'operationType',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.operationType.label',
    options: operationTypeOptions,
    renderOptions: {
      hidden: {
        condition: (values) =>
          'groupBy' in values && values.groupBy === 'operationTypeDms',
      },
    },
  },
  {
    id: 'operationTypeDms',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupBy.operationTypeDMS.item',
    renderOptions: {
      hidden: {
        condition: (values) =>
          'groupBy' in values && values.groupBy !== 'operationTypeDms',
      },
    },
    options: operationTypeOptions,
  },
  {
    type: 'selectGroup',
    id: 'operationStatus',
    label: 'analytics.editForm.filters.operationStatus.label',
    items: [
      {
        value: 'success',
        label: 'Success',
      },
      {
        value: 'decline',
        label: 'Decline',
      },
    ],
  },
  {
    id: 'issuerCountry',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
  {
    id: 'country',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByIP.label',
    dictionaryKey: 'country',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'currency',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.currency.label',
    dictionaryKey: 'currency',
    tooltip: 'select.currency.favorite.tooltip',
  },
];

// const PaymentsFieldValidations = {
//   chartName: [notEmpty],
// };

export { filters, chartParams };
