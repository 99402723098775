import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import PaymentCardContainer from 'components/paymentCard/PaymentCardContainer';
import SavedEntitiesContainer from 'components/savedEntities';
import TopPanelContainer from 'components/topPanel/TopPanelContainer';
import DataListContainer from 'components/dataListContainer';
import GenerateTestPaymentsButton from './components/GenerateTestPaymentsButton';
import { getPayments } from 'api/payments';
import {
  minPaymentTableColumnsWidth,
  refundablePaymentStatuses,
  refundablePaymentTypes,
} from './constants';
import CONFIG from 'config';
import tableNames from 'constants/tableNames';
import savedEntities from 'constants/savedEntities';
import quickFiltersConfig from 'pages/payments/quickFiltersConfig';
import { Column, Header, Row } from 'components/ui/table';
import ItemConfiguration from 'types/ItemConfiguration';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Lock from 'components/tableHeaders/components/lock/Lock';
import PaymentActions from 'components/paymentCard/paymentActions';
import setNoChangeWordOrder from 'helpers/setNoChangeWordOrder';
import SortingCell from 'components/tableHeaders/components/sortingCell/SortingCell';
import RoundButton from 'components/ui/roundButton';
import Button from 'components/ui/button';
import { CreateFiscalReceipt } from './createFiscalReceipt';
import './payments.scss';

interface OwnProps {
  id?: string;
  isDemoUser: boolean;
  configuration: ItemConfiguration[];
  sort: {
    field: string;
    order: 'desc' | 'asc';
  };
  getTableRef: (ref) => void;
  goToPayment: (data) => void;
  openTabWithPayment: (data) => void;
  isRefundEnabled: boolean;
  onRefresh: () => void;
}

type Props = OwnProps & IntlProps;

class PaymentsPage extends PureComponent<Props> {
  getTooltip = (data) => {
    const { paymentType, paymentStatus } = data;

    if (this.isRefundEnabled(data)) {
      if (paymentType === 'recurring') {
        return 'payments.registry.refund.recurring.popup';
      }
      return 'payments.registry.refund.popup';
    }

    if (!refundablePaymentTypes.includes(paymentType)) {
      return 'payments.single.reason.invalidType.popup';
    }

    if (!refundablePaymentStatuses.includes(paymentStatus)) {
      return 'payments.single.reason.invalidStatus.popup';
    }
  };

  isRefundEnabled = (data) => {
    const { paymentType, paymentStatus } = data;

    return (
      refundablePaymentTypes.includes(paymentType) &&
      refundablePaymentStatuses.includes(paymentStatus)
    );
  };

  headerRender = () => {
    const { configuration, sort, getTranslate } = this.props;

    return (
      <Header>
        {configuration.map(({ id, localId, fixed }) => {
          let content: string | React.ReactNode = getTranslate(localId);
          const isFixed = Boolean(fixed);

          if (id === 'paymentId') {
            content = (
              <Lock
                content={getTranslate(localId)}
                tableName={tableNames.payments}
                isFixed={isFixed}
              />
            );
          }

          if (id === 'creationDate' || id === 'updatedDate') {
            content = (
              <SortingCell tableName={tableNames.payments} id={id} sort={sort}>
                {content}
              </SortingCell>
            );
          }

          return (
            <Column
              id={id}
              key={id}
              customClass={isFixed ? 'ui-table__column_fixed' : ''}
              modifier={id}
              content={content}
              minColumnsWidth={minPaymentTableColumnsWidth}
            />
          );
        })}
      </Header>
    );
  };

  rowRender = (data) => {
    const {
      configuration,
      goToPayment,
      getTranslate,
      isRefundEnabled,
      openTabWithPayment,
    } = this.props;
    const { transactionId, projectId, columnWidths } = data;

    return (
      <Row
        onClick={() => data.handleDoubleClick(goToPayment)}
        key={transactionId}
        customClass='ui-table__row_clickable'>
        {configuration.map((config) => {
          const { id, fixed } = config;

          if (id === 'paymentId') {
            const tooltip = this.getTooltip(data);

            return (
              <Column
                id={id}
                key={`data-${projectId}-${transactionId}-${id}`}
                customClass={classNames('page-payments__id', {
                  'ui-table__column_fixed': fixed,
                  'page-payments__id_no-action': !isRefundEnabled,
                })}
                modifier={id}
                content={
                  <>
                    <span className='ui-table__payment-id-and-button-open-in-new-tab'>
                      <RoundButton
                        customClass='page-payments__button-open-in-new-tab'
                        icon='im-Tab-Open'
                        iconSize={11}
                        tooltip={getTranslate(
                          `payments.registry.openTab.button`
                        )}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          openTabWithPayment(data);
                        }}
                      />
                      {setNoChangeWordOrder(data[id])}
                    </span>

                    {isRefundEnabled ? (
                      <PaymentActions
                        transactionId={transactionId}
                        projectId={projectId}
                        buttonTheme='primary'
                        actionTypes={['refund']}
                        customClass='page-payments__action payment-actions_registry'
                        isNeedPing={false}
                        disabled={!this.isRefundEnabled(data)}
                        refundTooltip={tooltip}
                        startPingOnClick
                        buttonType='round'
                        selectorPortal='.page-payments__action-panel'
                      />
                    ) : null}
                  </>
                }
                minColumnsWidth={minPaymentTableColumnsWidth}
                columnWidths={columnWidths}
                data={data}
              />
            );
          }

          return (
            <Column
              id={id}
              key={`data-${projectId}-${transactionId}-${id}`}
              modifier={id}
              content={data[id] || '—'}
              minColumnsWidth={minPaymentTableColumnsWidth}
              columnWidths={columnWidths}
              data={data}
              params={config}
            />
          );
        })}
      </Row>
    );
  };

  renderContainer = () => {
    const { id, isDemoUser, getTranslate, getTableRef, onRefresh } = this.props;

    if (!id) {
      return (
        <PageTemplate.Container>
          <TopPanelContainer
            name={tableNames.payments}
            showButton
            filtersConfig={quickFiltersConfig}
            button={
              <>
                <GenerateTestPaymentsButton isDemoUser={isDemoUser} />

                <Button
                  status='light'
                  icon='im-Refill'
                  iconSize={14}
                  iconPosition='left'
                  text={getTranslate('payments.table.refresh')}
                  onClick={onRefresh}
                />
              </>
            }
          />
          <DataListContainer
            getTableRef={getTableRef}
            tableName={tableNames.payments}
            apiRequest={getPayments}
            // Limit for payments table - 40
            apiRequstLimit={CONFIG.REQUEST_ITEMS_LIMIT * 2}
            minColumnsWidth={minPaymentTableColumnsWidth}
            rowRender={this.rowRender}
            headerRender={this.headerRender()}
          />
          <div className='page-payments__action-panel'></div>
        </PageTemplate.Container>
      );
    }

    if (id === 'create-fiscal-receipt') {
      return (
        <PageTemplate.Container>
          <CreateFiscalReceipt />
        </PageTemplate.Container>
      );
    }

    return (
      <PaymentCardContainer id={id} savedEntity={savedEntities.payments} />
    );
  };

  render() {
    return (
      <PageTemplate.Main>
        <PageTemplate.Entities>
          <SavedEntitiesContainer entityKey={savedEntities.payments} />
        </PageTemplate.Entities>

        {this.renderContainer()}
      </PageTemplate.Main>
    );
  }
}

export default addTranslation(PaymentsPage);
