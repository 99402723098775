import React from 'react';
import classNames from 'classnames';
import InfoIcon from 'components/ui/infoIcon';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import Loader from 'components/ui/loader';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { AnyObject } from 'types/Common';
import { MafField, MafTab } from '../../types';

type RadioValues = { values: Item[]; titleKey: string };
type Item = {
  value?: string;
  object_id?: string;
};
interface Props extends IntlProps {
  dictionaries: AnyObject | null;
  field: MafField;
  isRequired: boolean;
  isLoading: boolean;
  error: string;
  isDisabled: boolean;
  onChangeField: (formId: string, key: string, value: any) => void;
  form: MafTab;
  isRow?: boolean;
}

class MafRadioButtons extends React.PureComponent<Props> {
  render() {
    const {
      field,
      isRequired,
      getTranslate,
      error,
      isDisabled,
      isLoading,
      isRow,
    } = this.props;

    const { values, titleKey } = this.getValues();

    if (!values) return null;

    const checkedId = field.value || '';
    return (
      <div
        className={classNames('maf-field__radio-group', {
          'maf-field__radio-group__with-error': !!error,
        })}>
        {field.caption && (
          <div className='maf-field__radio-group__title'>
            {getTranslate(field.caption)}
            {isRequired && (
              <span
                data-tip={getTranslate('common.requiredField.tooltip')}
                className='maf-field__radio-group__required'>
                *
              </span>
            )}
            {field.notes && (
              <InfoIcon
                tooltip={getTranslate(field.notes)}
                customClass='ui-radio__info'
              />
            )}
          </div>
        )}
        {error && <div className='maf-field__radio-group__error'>{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <RadioGroup
            checkedId={checkedId}
            onChange={this.handleChange}
            customClass={classNames({ 'ui-radio-group_row': isRow })}>
            {values.map((item) => {
              const itemId = item.value || item.object_id || '';
              return (
                <Radio
                  isDisabled={isDisabled}
                  key={itemId}
                  id={itemId}
                  text={getTranslate(item[titleKey])}
                />
              );
            })}
          </RadioGroup>
        )}
      </div>
    );
  }

  getValues = () => {
    const { dictionaries, field } = this.props;
    const result: RadioValues = {
      values: [],
      titleKey: '',
    };
    if (Array.isArray(field.enum_values)) {
      result.values = field.enum_values;
      result.titleKey = 'title';
    } else if (dictionaries && field.lookup && dictionaries[field.lookup]) {
      result.values = dictionaries[field.lookup];
      result.titleKey = field.lookup_display_field_name || 'name';
    }
    return result;
  };

  handleChange = (newCheckedId) => {
    const { onChangeField, form, field } = this.props;
    onChangeField(form.name, field.name, {
      value: newCheckedId,
    });
  };
}

export default addTranslation(MafRadioButtons);
