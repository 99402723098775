import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useEventListener } from 'hooks';
import { RootState } from 'store';
import { removeAllEntities } from 'actions/savedEntities';
import Messages from 'constants/rpcTypes';
import savedEntities from 'constants/savedEntities';
import { MafListOption } from './types';
import { getMafDigest, setMafWasOpen } from 'api/maf';
import { MafDigest } from 'components/maf/types';
import { BusinessDocumentsListView } from './BusinessDocumentsListView';
import './businessDocumentsList.scss';

interface Props {
  digest?: MafDigest;
  selectedLegalEntity: MafListOption;
}

const BusinessDocumentsList: React.FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [digest, setDigest] = useState<MafDigest>();
  const [selectedLegalEntity, setSelectedLegalEntity] =
    useState<MafListOption>();

  const legalEntities = useSelector(
    (state: RootState) => state.user.activeLegalEntities
  );

  useEffect(() => {
    if (legalEntities) {
      const initialLegalEntity = {
        value: legalEntities[0]?.id,
        label: legalEntities[0]?.title,
      };

      setSelectedLegalEntity(initialLegalEntity);
      updateDigest(initialLegalEntity?.value);
    }
  }, [legalEntities]);

  useEventListener(
    {
      events: [Messages.Maf_GetDigest],
      onEvent: (
        _,
        data: {
          payload: { result: string };
        }
      ) => {
        if (data.payload.result === 'ok') {
          updateDigest(selectedLegalEntity?.value);
        }
      },
    },
    [selectedLegalEntity]
  );

  const updateDigest = async (id?: number) => {
    if (!id) {
      return;
    }

    try {
      const digest = await getMafDigest(id);
      setDigest(digest);
    } catch (e) {
      console.error('Cannot get maf digest', e);
    }
  };

  const handleLegalEntityChange = (currentLegalEntity: MafListOption) => {
    if (
      !currentLegalEntity ||
      currentLegalEntity.value === selectedLegalEntity?.value
    ) {
      return;
    }

    setSelectedLegalEntity(currentLegalEntity);
    setDigest(undefined);
    dispatch(
      removeAllEntities({
        entityKey: savedEntities.businessDocs,
      })
    );
    updateDigest(currentLegalEntity.value);
  };

  const handleFormOpen = (
    formId: string,
    caption: string,
    wasMafOpen: boolean
  ) => {
    if (!wasMafOpen) {
      setMafWasOpen(formId, selectedLegalEntity?.value);
    }

    history.push({
      pathname: `${location.pathname}/${formId}`,
      search: `?legalEntityId=${selectedLegalEntity?.value}`,
      state: { legalEntityCaption: caption },
    });
  };

  return (
    <BusinessDocumentsListView
      digest={digest}
      legalEntities={legalEntities}
      selectedLegalEntity={selectedLegalEntity}
      changeSelectedEntity={handleLegalEntityChange}
      goToForm={handleFormOpen}
    />
  );
};

export { BusinessDocumentsList };
