import React from 'react';
import classNames from 'classnames';

import CustomSelect from 'components/ui/customSelect';
import Input from 'components/ui/input';
import Icon from 'components/ui/icon';
import SelectionList from 'components/ui/selectionList';
import Checkbox from 'components/ui/checkbox';
import { ServiceSettingsFormHelpers } from './ServiceSettingsFormHelpers';

import '../graphDetails.scss';

import {
  IFormValues,
  IFormDescriptorItem,
  IValidateHandler,
  IOnChange,
} from 'types/Analytics';
import InputDateRange from 'components/ui/inputDateRange';

type Dictionary = Array<{ id: string; text: string }>;

interface IGetRenderableField {
  formDescriptorItem: IFormDescriptorItem;
  onChange: IOnChange;
  // TODO add typings
  values: any;
  id: string;
  getTranslate: (id: string, values?: any) => string;
  label: string;
  tooltip?: string;
  validationErrors: { [key: string]: string };
  toggleDatePicker: () => void;
  panelPosition: {
    top: number;
  };
  dictionaries: { [key: string]: Dictionary };
  grid?: number;
  validateHandler: IValidateHandler;
  isEnabled: (string) => boolean;
}

const getSelectOptions = (
  formItem: IFormDescriptorItem,
  values: IFormValues
): { value: string; label: string }[] =>
  ServiceSettingsFormHelpers.getOptions(formItem, values);

const getMultiSelectItems = (
  formItem: IFormDescriptorItem,
  dictionaries: { [key: string]: Dictionary },
  values: IFormValues,
  getTranslate: (id: string, values?: any) => string
) => {
  const { dictionaryKey, id, optionsTranslate } = formItem;

  if (dictionaryKey === undefined) {
    const data = ServiceSettingsFormHelpers.getOptions(formItem, values);

    return data.map((item) => ({
      ...item,
      isSelected: 'id' in item ? values[id]?.includes(item.id) : false,
      text: optionsTranslate === true ? getTranslate(item.text) : item.text,
    }));
  }

  const data = dictionaries[dictionaryKey];

  return data === undefined
    ? []
    : data.map((item) => ({
        ...item,
        isSelected: values[id]?.includes(item.id),
      }));
};
const GetRenderableField = ({
  formDescriptorItem,
  onChange,
  values,
  id,
  getTranslate,
  label,
  tooltip,
  validationErrors,
  toggleDatePicker,
  panelPosition,
  dictionaries,
  grid,
}: IGetRenderableField) => {
  const formItemClassName: string = `settings-form__row ${
    grid ? `grid-column-${grid}` : ''
  }`;

  const isDisabled =
    formDescriptorItem.renderOptions?.readOnly === true ||
    formDescriptorItem.renderOptions?.disabled?.condition(values);
  // const validatorList = formDescriptorItem.validate;

  if (formDescriptorItem.renderOptions?.hidden?.condition(values) === true) {
    return null;
  }

  const tooltipText = formDescriptorItem.renderOptions?.tooltipText;
  const isRequired = formDescriptorItem.isRequired;
  const onChangeCallback = formDescriptorItem.onChangeCallback;

  switch (formDescriptorItem.type) {
    case 'singleSelect': {
      const options = getSelectOptions(formDescriptorItem, values).map(
        ({ value, label: labelItem }) => ({
          value,
          label: getTranslate(labelItem),
        })
      );

      const selectedItem = options.find((i) => i.value === values[id]);

      return (
        <div
          key={id}
          className={classNames(formItemClassName, 'settings-form__grid-item')}>
          <CustomSelect
            id={id}
            options={options}
            value={selectedItem}
            isDisabled={isDisabled}
            onChange={({ value }) => {
              onChange(id, value, onChangeCallback);
            }}
            label={getTranslate(label)}
            customClass={classNames(
              'settings-form__grid-element',
              'settings-form__flex-grow',
              'ui-select_large'
            )}
          />
          {isDisabled === true && tooltipText !== undefined && (
            <Icon
              size={18}
              name='help_outline'
              className={classNames(
                'payment-action__icon-info',
                'settings-form__grid-element',
                'settings-form__tooltip-icon'
              )}
              data-place={'right'}
              tooltip={getTranslate(tooltipText)}
              data-delay-hide='200'
            />
          )}
        </div>
      );
    }
    case 'date': {
      const data = values[id];

      return (
        <div key={id} className={formItemClassName}>
          <InputDateRange
            isDisabled={isDisabled}
            id='graph-details-period-settings'
            label={getTranslate(label)}
            value={{ dateFrom: data?.from || '', dateTo: data?.to || '' }} //`${data.from} - ${data.to}`
            onChange={(value) => {
              console.log('value', value);
              onChange(id, { from: value.dateFrom, to: value.dateTo });
            }}
            onDatePickerToggle={toggleDatePicker}
            error={validationErrors['period']}
            disableButton
            customClass='settings-form__date-picker'
            panelPosition={panelPosition}
          />
        </div>
      );
    }
    case 'multiSelect': {
      return (
        <div key={id} className={formItemClassName}>
          <SelectionList
            isDisabled={isDisabled}
            label={getTranslate(label)}
            // @ts-ignore
            items={getMultiSelectItems(
              formDescriptorItem,
              dictionaries,
              values,
              getTranslate
            )}
            isSingleMode={formDescriptorItem.isSingleMode}
            onChange={(value) => {
              const currentItem = value
                .filter((i) => i.isSelected)
                .map((i) => i.id);

              onChange(id, currentItem, onChangeCallback);
            }}
            tooltip={getTranslate(tooltip || '')}
          />
        </div>
      );
    }
    case 'input': {
      const { placeholder } = formDescriptorItem;

      return (
        <div key={id} className={formItemClassName}>
          <Input
            disabled={isDisabled}
            isRequired={isRequired}
            id={id}
            placeholder={
              placeholder === undefined ? '' : getTranslate(placeholder)
            }
            label={getTranslate(label)}
            onChange={({ target: { value } }) => onChange(id, value)}
            value={values[id]}
          />
        </div>
      );
    }

    case 'selectGroup': {
      return (
        <div key={id} className={formItemClassName}>
          <div className='settings-form__label'>{getTranslate(label)}</div>
          <div className='settings-form__row-container'>
            {formDescriptorItem.items?.map(
              ({ value, label: selectItemLabel }) => {
                return (
                  <div
                    key={`select-item-${value}`}
                    className='settings-form__row'>
                    <Checkbox
                      disabled={isDisabled}
                      id={id}
                      text={
                        selectItemLabel !== undefined
                          ? getTranslate(selectItemLabel)
                          : ''
                      }
                      checked={values[id]?.includes(value)}
                      onChange={(e) => {
                        const newValues = values[id];
                        if (e.target.checked) {
                          newValues.push(value);
                        } else {
                          newValues.splice(newValues.indexOf(value), 1);
                        }
                        onChange(id, newValues);
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    }

    default:
      return null;
  }
};

export { GetRenderableField };
