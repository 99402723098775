import React, { PureComponent } from 'react';
import classNames from 'classnames';

import DataBlock from 'components/ui/dataBlock';
import PaymentDataMapper from '../PaymentDataMapper';

import { AnyObject } from 'types/Common';
import PaymentPageUI from 'types/PaymentPageUI';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import './operationInfo.scss';

interface Props {
  operation: AnyObject;
  transactionId: string;
  pageUI: PaymentPageUI | RecurringCardPageUI | undefined;
  onChangePageUI: (objectState: AnyObject) => void;
  modern?: boolean;
}

class OperationInfo extends PureComponent<Props> {
  render() {
    const { operation } = this.props;

    if (!operation) return null;

    const {
      operationInfo,
      cryptoInfo,
      paymentMethodInfo,
      paymentProviderInfo,
      secure,
      address,
      callback,
      deviceInfo,
      fraud,
      vatBillingInfo,
    } = PaymentDataMapper.getOperationInfo(operation);
    const isModern = this.props.modern ? 'modern' : undefined;

    return (
      <div
        className={classNames('grid-row', {
          'payment-operation-info__content-inner': !this.props.modern,
        })}>
        <div className='grid-column-6'>
          <DataBlock
            title='payments.single.operationInfo.header'
            data={operationInfo}
            theme={isModern}
          />

          <DataBlock
            title='payments.single.providerInfo.header'
            data={paymentProviderInfo}
            theme={isModern}
          />

          <DataBlock
            title='payments.single.deviceInfo.header'
            data={deviceInfo}
            theme={isModern}
          />
        </div>

        <div className='grid-column-6'>
          <DataBlock
            title='payments.data.paymentMethod.header'
            data={paymentMethodInfo}
            theme={isModern}
          />

          <DataBlock
            title='payments.single.callback.header'
            data={callback}
            theme={isModern}
          />

          <DataBlock
            title='payments.single.3DSecure.header'
            data={secure}
            theme={isModern}
          />

          {address.isVisible && (
            <DataBlock
              title='payments.single.address.header'
              data={address.content}
              theme={isModern}
            />
          )}

          {fraud.isFraud && (
            <DataBlock
              title={'payments.single.fraud.header'}
              data={fraud.content}
              theme={isModern}
            />
          )}

          {cryptoInfo.isVisible && (
            <DataBlock
              title='payments.single.cryptoInfo.header'
              data={cryptoInfo.content}
              theme={isModern}
            />
          )}

          {vatBillingInfo.isVisible && (
            <DataBlock
              title='payments.single.BillingInfo.header'
              data={vatBillingInfo.content}
              theme={isModern}
            />
          )}
        </div>
      </div>
    );
  }
}

export default OperationInfo;
