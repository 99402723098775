import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import { useTranslation } from 'hooks';
import { SCENES } from './constants';
import { BaseInterface, ContentInterface } from './types';
import './codeVerification.scss';
import I18nHelpers from 'i18n/I18nHelpers';

interface Props extends BaseInterface {
  content: ContentInterface;
  canSendCode: boolean;
  onChangeCode: (event) => void;
  onCompleteTimer: () => void;
  onSendCode: () => void;
  onResendCode: () => void;
  onChangeScene: (scene: keyof typeof SCENES) => void;
  callback: () => void;
}

export const CodeVerification: React.FC<Props> = ({
  code,
  content,
  scene,
  errorMessage,
  canSendCode,
  canResendCode,
  timeResendCode,
  timeConfirmCode,
  isLoading,
  onChangeCode,
  onSendCode,
  onResendCode,
  onCompleteTimer,
  onChangeScene,
  callback,
}) => {
  const getTranslate = useTranslation();
  const { subjectDetails, confirmationDetails } = content;
  const {
    title,
    description,
    sendingInfoText,
    confirmButtonText,
    showConfirmButtonTimer,
    params,
  } = subjectDetails;
  const showResendButton = confirmationDetails.codeResendSinceTimeLeft !== 0;
  const startResendTime = !canResendCode && timeResendCode !== undefined;
  const startConfirmTime = !canSendCode && timeConfirmCode !== undefined;

  const getErrorContent = () => {
    switch (scene) {
      case SCENES.ATTEMPTS_ERROR:
        return (
          <>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.attempts.text')}
            </div>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.tryAgain.text')}
            </div>
          </>
        );

      case SCENES.TIMEOUT_ERROR:
        return (
          <>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.timeOut.text')}
            </div>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.tryAgain.text')}
            </div>
          </>
        );

      default:
        return (
          <div className='code-verification__error-text'>
            {getTranslate('payment.psd2.Settings.text')}
          </div>
        );
    }
  };

  const renderContent = () => {
    switch (scene) {
      case SCENES.MAIN:
        return renderMain();
      case SCENES.ATTEMPTS_ERROR:
      case SCENES.TIMEOUT_ERROR:
      case SCENES.TRANSACTION_LOCKED_ERROR:
        return (
          <div className='code-verification__error'>{getErrorContent()}</div>
        );

      default:
        console.error(
          `${scene} not supported! You can use ${Object.values(
            SCENES
          ).toString()}`
        );
        return null;
    }
  };

  const renderMain = () => {
    return (
      <div className='code-verification__main'>
        {params.length ? (
          <div className='code-verification__header'>
            <div className='payment-verification'>
              {params.map(({ key, value }, idx) => {
                return (
                  <div className='payment-verification__row' key={idx}>
                    {getTranslate(key)}
                    <span className='payment-verification__row-value'>
                      {getTranslate(value)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <Form customClass='code-verification__form' onSubmit={onSendCode}>
          <div className='code-verification__info'>
            <div className='code-verification__info-text'>
              {I18nHelpers.getJsx(getTranslate(sendingInfoText))}
            </div>
          </div>
          <Input
            autoFocus
            id='verification-code'
            value={code}
            label={getTranslate('payment.psd2.verificationCode')}
            placeholder='123 456'
            cleaveOptions={{
              numericOnly: true,
              blocks: [3, 3],
              delimiters: [' '],
            }}
            onChange={onChangeCode}
            error={errorMessage}
            customClass='code-verification__input-field'
            modern
          />
        </Form>
      </div>
    );
  };

  const renderFooter = () => {
    if (scene === SCENES.MAIN) {
      return (
        <div className='code-verification__buttons'>
          {showResendButton ? (
            <Button
              status='outline'
              disabled={!canResendCode}
              onClick={onResendCode}
              customClass='ui-button_full-width code-verification__resend-button'
              text={
                <>
                  {getTranslate('payment.psd2.resendCode.button')}{' '}
                  {startResendTime ? (
                    <Countdown
                      renderer={({ minutes, seconds, completed }) =>
                        !completed ? (
                          <span>
                            ({zeroPad(minutes)}:{zeroPad(seconds)})
                          </span>
                        ) : null
                      }
                      onComplete={onCompleteTimer}
                      date={timeResendCode}
                    />
                  ) : null}
                </>
              }
            />
          ) : null}

          <Button
            status='primary'
            onClick={onSendCode}
            disabled={!canSendCode}
            customClass='ui-button_full-width'
            text={
              <>
                {getTranslate(confirmButtonText)}{' '}
                {showConfirmButtonTimer && startConfirmTime ? (
                  <Countdown
                    renderer={({ minutes, seconds, completed }) =>
                      !completed ? (
                        <span>
                          ({zeroPad(minutes)}:{zeroPad(seconds)})
                        </span>
                      ) : null
                    }
                    onComplete={() => onChangeScene(SCENES.TIMEOUT_ERROR)}
                    date={timeConfirmCode}
                  />
                ) : null}
              </>
            }
          />
        </div>
      );
    }

    return (
      <Button
        text={getTranslate('common.ok.button')}
        status='primary'
        customClass='ui-button_full-width'
        onClick={() => callback()}
      />
    );
  };

  return (
    <div className='code-verification'>
      <ModalTitle>{getTranslate(title)}</ModalTitle>
      <ModalContent>
        {renderContent()}
        {renderFooter()}
        {scene === SCENES.MAIN && description ? (
          <p className='code-verification__description'>
            {I18nHelpers.getJsx(getTranslate(description))}
          </p>
        ) : null}
      </ModalContent>
      {isLoading && <Loader />}
    </div>
  );
};
