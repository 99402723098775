import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import Button from 'components/ui/button';
import ModalTitle from '../../components/ModalTitle';
import ModalContent from '../../components/ModalContent';
import { AnyObject } from 'types/Common';
import SelectItem from 'types/SelectItem';

interface Props extends IntlProps {
  formValues: {
    url: string;
    resultType: string;
    transactionType: string;
    paymentMethodId?: string;
  };
  paymentMethods: SelectItem[];
  availableResultTypes: SelectItem[];
  transactionTypeValues: SelectItem[];
  onChange: (key: string, value: any) => void;
  onCreateProjectSetting: () => void;
  isLoading: boolean;
  formChanged: boolean;
  validationErrors: AnyObject;
}

class CreateCallbackForm extends React.PureComponent<Props> {
  render() {
    const {
      onChange,
      isLoading,
      formValues: { url, resultType, transactionType, paymentMethodId },
      getTranslate,
      paymentMethods,
      availableResultTypes,
      validationErrors,
      transactionTypeValues,
      onCreateProjectSetting,
    } = this.props;
    const transactionTypeValue = this.getOption(
      transactionType,
      transactionTypeValues
    );
    return (
      <>
        <ModalTitle>
          {getTranslate('projects.callback.create.title')}
        </ModalTitle>
        <ModalContent customClass='create-callback-form'>
          <div className='create-callback-form__text'>
            {getTranslate('projects.callback.create.text')}
          </div>
          <Input
            disabled={isLoading}
            id='url'
            label={getTranslate('projects.callbacks.newUrl.label')}
            placeholder={getTranslate('projects.callbacks.url.placeHolder')}
            value={url}
            error={getTranslate(validationErrors?.url)}
            onChange={(event) => onChange('url', event.target.value)}
            customClass='create-callback-form__item'
            modern
          />
          <CustomSelect
            isDisabled={isLoading}
            options={transactionTypeValues}
            value={transactionTypeValue}
            error={getTranslate(validationErrors?.transactionType)}
            customClass='create-callback-form__item'
            label={getTranslate('projects.callbacks.transactionType.label')}
            onChange={({ value }) => onChange('transactionType', value)}
            modern
          />
          <CustomSelect
            isDisabled={isLoading || !transactionTypeValue}
            options={availableResultTypes}
            value={this.getOption(resultType, availableResultTypes)}
            error={getTranslate(validationErrors?.resultType)}
            customClass='create-callback-form__item'
            label={getTranslate('projects.callbacks.resultType.label')}
            onChange={({ value }) => onChange('resultType', value)}
            modern
          />
          <CustomSelect
            isDisabled={isLoading || !paymentMethods}
            options={paymentMethods}
            isLoading={isLoading}
            value={paymentMethods.find(
              ({ value }) => value === paymentMethodId
            )}
            error={getTranslate(validationErrors?.paymentMethodId)}
            customClass='ui-select_payment-method create-callback-form__item'
            label={getTranslate('projects.callbacks.selectPaymentMethod.label')}
            tooltip={getTranslate(
              'projects.callbacks.paymentMethod.info.tooltip'
            )}
            tooltipId='modal'
            tooltipPosition='left'
            onChange={({ value }) => onChange('paymentMethodId', value)}
            modern
          />
          <Button
            loading={isLoading}
            text={getTranslate('common.save.button')}
            disabled={!this.canCreate()}
            status='primary'
            type='submit'
            onClick={() => {
              if (this.canCreate()) {
                onCreateProjectSetting();
              }
            }}
            customClass='create-callback-form__item create-callback-form__item_button'
          />
        </ModalContent>
      </>
    );
  }

  getOption = (value, list) => {
    return list.find((item) => item.value === value) || null;
  };

  canCreate = () => {
    const { formValues, validationErrors, formChanged } = this.props;
    return (
      formChanged &&
      !Object.values(validationErrors).some((err) => !!err) &&
      formValues.url &&
      formValues.resultType &&
      formValues.transactionType
    );
  };
}

export default addTranslation(CreateCallbackForm);
