export const CODE_LENGTH = 6;

export const RESEND_CODE_INTERVAL = 120000;

export const VERIFICATION_TYPES = {
  SMS: 'sms.va',
  GOOGLE_AUTH: 'sms.ga',
};

export const SCENES = {
  MAIN: 'MAIN',
  ATTEMPTS_ERROR: 'ATTEMPTS_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
  TRANSACTION_LOCKED_ERROR: 'TRANSACTION_LOCKED_ERROR',
} as const;

export const BACKEND_ERRORS = {
  ERROR_CODE_OPERATION_EXPIRED: 'operation_expired',
  ERROR_CODE_ATTEMPTS_EXCEEDED: 'attempts_exceeded',
  ERROR_CODE_TRANSACTION_LOCKED: 'transaction_locked',
  ERROR_CODE_VERIFICATION_ID_INVALID: 'verification_id_invalid',
  ERROR_CODE_VERIFICATION_CODE_INVALID: 'verification_code_invalid',
  ERROR_CODE_USER_ID_INVALID: 'user_id_invalid',
  ERROR_CODE_TO_EARLY: 'resend_to_early',
};

export const SUBJECTS = {
  TRANSACTION: 'transaction',
};
