import React from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
} from 'react-18-sortable-hoc';
import './sortable.scss';
import classNames from 'classnames';

export const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

export const SortableItem = sortableElement((props) => {
  return (
    <li className={classNames('ui-sortable-item', props.customClass)}>
      {props.children}
    </li>
  );
});

export const SortableHandle = sortableHandle(({ children }) => children);
