import LocalStorage from 'helpers/LocalStorage';

const defaultTheme = {
  colors: {
    brand: {
      main: '#4B007C',
      highlight: '#300050',
      dark: '#3E0066',
      'layout-background': '#f0f1f3',
    },
    accent: {
      main: '#00cc24',
      highlight: '#00a61d',
      dark: '#008016',
    },
  },
  font: {
    default: {
      body: "'ProximaNova', sans-serif",
      heading: "'ProximaNova', sans-serif",
    },
  },
  componentStyle: {
    'drop-zone': {
      'border-radius': '16',
    },
    'file-template': {
      'border-radius': '12',
    },
    card: {
      'border-radius': '16',
    },
    button: {
      'border-radius': '8',
    },
    'buttons-group': {
      'border-radius': '8',
    },
    'input-default': {
      'border-radius': ' 8',
    },
    'input-modern': {
      'border-radius': '12',
    },
    form: {
      'border-radius': '24',
    },
    teaser: {
      'border-radius': '16',
    },
    chip: {
      'border-radius': '6',
    },
    modal: {
      'border-radius': '21',
    },
    toast: {
      'border-radius': '12',
    },
    table: {
      'border-radius': '12',
    },
  },
};

const setCssVariables = (name: string, type: string, value: string) => {
  const root = document.documentElement;
  root.style.setProperty(`--${name}-${type}`, value);
};

export const setAppTheme = (theme = defaultTheme) => {
  // setting dynamic app colors
  Object.keys(theme.colors).forEach((name) => {
    Object.entries(theme.colors[name]).forEach(([type, value]) => {
      setCssVariables(name, type, String(value));
    });
  });

  // setting dynamic app fonts
  const lang = LocalStorage.get('noAuthLang');
  const fontStyle = theme.font[lang] ?? theme.font.default;

  Object.entries(fontStyle).forEach(([type, value]) => {
    setCssVariables('font', type, String(value));
  });

  // setting dynamic border radius
  Object.entries(theme.componentStyle).forEach(([name]) => {
    Object.entries(theme.componentStyle[name]).forEach(([type, value]) => {
      // HINT: Change value already in css format
      setCssVariables(name, type, `${value}px`);
    });
  });
};
