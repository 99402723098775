import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { WithRouterProps } from 'decorators/withRouter';
import SwitcherTabsItem from './types';
import Icon from 'components/ui/icon';

interface OwnProps {
  icon?: string;
  tabs: SwitcherTabsItem[];
  activeTab: string;
  onClick: (id: string) => void;
}

type Props = OwnProps & WithRouterProps;

class SwitcherTabs extends PureComponent<Props> {
  render() {
    const { tabs, activeTab, location, icon = '', onClick } = this.props;

    const isAcitve = (id: string, path: string, rootMatch: boolean) => {
      const { pathname } = location;
      let pathMatch = pathname === `/${path}`;

      if (rootMatch) {
        pathMatch = pathname.includes(path);
      }

      return id === activeTab && pathMatch;
    };

    return (
      <div className='saved-entities saved-entities_switcher'>
        <ul className='saved-entities__list'>
          {tabs.map(({ id, text, path, rootMatch = false }) => (
            <li
              key={id}
              className={classNames(
                'saved-entities__item saved-entities__item_fixed',
                { 'saved-entities__item_active': isAcitve(id, path, rootMatch) }
              )}
              onClick={() => onClick(id)}>
              <span className='saved-entities__item-outer'>
                <span className='saved-entities__item-inner utils-overflow-dots'>
                  <Icon
                    name={icon}
                    size={13}
                    className='saved-entities__icon'
                  />
                  {text}
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(SwitcherTabs);
