import csvIcon from './csv.svg';
import excelIcon from './excel.svg';
import wordIcon from './word.svg';
import imagesIcon from './images.svg';
import pdfIcon from './pdf.svg';
import powerPointIcon from './power-point.svg';
import unknownIcon from './unknown.svg';

export const getFileFormatIcon = (fileName: string) => {
  const type = fileName.split('.').pop();

  switch (type) {
    case 'csv':
      return csvIcon;
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
      return excelIcon;
    case 'docx':
    case 'doc':
      return wordIcon;
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return imagesIcon;
    case 'pdf':
      return pdfIcon;
    case 'pptx':
    case 'pptm':
    case 'ppt':
      return powerPointIcon;
    default:
      return unknownIcon;
  }
};