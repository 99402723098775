import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { updateEntity } from './savedEntities';
import savedEntities from 'constants/savedEntities';
import api from 'api/payment';

export const getPayment = (
  id: string,
  tableName: string,
  onSuccess?: () => void,
  apiRequest?: () => Promise<any>
) => {
  const request = apiRequest || api.getPaymentById;
  return (dispatch) => {
    request(id)
      .then(({ payment }) => {
        dispatch(
          createAction({
            type: ACTIONS.FETCH_PAYMENT_SUCCESS,
            payload: {
              payment,
            },
          })
        );
        dispatch(
          updateEntity({
            entityKey: savedEntities[tableName],
            id: id.toString(),
            fields: {
              data: payment,
            },
          })
        );
        onSuccess && onSuccess();
      })
      .catch(({ error }) => {
        dispatch(
          createAction({
            type: ACTIONS.FETCH_PAYMENT_FAIL,
            payload: {
              error,
            },
          })
        );
        dispatch(
          updateEntity({
            entityKey: savedEntities[tableName],
            id: id.toString(),
            fields: {
              data: {
                error,
              },
            },
          })
        );
      });
  };
};
