import React, { useContext } from 'react';

export const WebSocketContext = React.createContext(false);

export const useWebSocketConnection = () => {
  const isConnected = useContext(WebSocketContext);

  return {
    isConnected,
  };
};
