import React from 'react';

import SelectionList from 'components/ui/selectionList';
import { Item } from 'components/ui/selectionList/SelectionList';
import ButtonsGroup from 'components/ui/buttonsGroup';
import { AnyObject } from 'types/Common';

import { MafFormProps } from '../../types/props';
import { MafDictionaries, MafTab } from '../../types';
import { MafOp } from '../../constants';
import { MafTabWithTemplate } from './types';
import { ToggleButton } from 'components/ui/toggleButton';

interface Props
  extends Pick<
    MafFormProps,
    'fieldState' | 'onDeleteForm' | 'onCreateFormByTemplate' | 'onDeleteAllTabs'
  > {
  form: MafTabWithTemplate;
  dictionaries: MafDictionaries | null;
  loading: boolean;
  isLocked: boolean;
  modern?: boolean;
}

type State = {
  fields: Item[];
};

class SingleFieldSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.getFields(props.form),
    };
  }

  render() {
    const { form, loading, isLocked, fieldState, modern } = this.props;
    const { fields } = this.state;
    const currentField = form.tab_template.tabs[0].fields[0];
    const isDisabled =
      isLocked ||
      fieldState.isDisabled(form, currentField) ||
      fieldState.isFieldsDisabledByParentForm(form);

    return (
      <div className='maf-single-field'>
        {form.tab_template.item_type === 'single_field_tiles' ? (
          <ButtonsGroup
            mode='multiRequired'
            theme='dark'
            separate
            isLoading={loading}
            disabled={form.behaviour_type === 'read-only' || isDisabled}
            activeButtons={fields
              .filter((item) => item.isSelected)
              .map((item) => item.id)}
            onChange={(event: AnyObject) =>
              event.state && this.onChange(form, event.state)
            }>
            {fields.map((item) => (
              <ToggleButton key={item.id} id={item.id} text={item.text} />
            ))}
          </ButtonsGroup>
        ) : (
          <SelectionList
            isSelectAll={form.behaviour_type === 'select-all-available'}
            isDisabled={loading || isDisabled}
            items={fields}
            onChange={(_, event: AnyObject) =>
              event.state && this.onChange(form, event.state)
            }
            isFullMode={true}
            id={`maf-single-field-${form.name}`}
            lazyApplyAndSort={false}
            modern={modern}
            label={form.tab_template.tabs[0].fields[0].caption}
            isRequired={form.tab_template.tabs[0].fields[0].required}
            isLoading={loading}
          />
        )}
      </div>
    );
  }

  getFields = (form: MafTabWithTemplate): Item[] => {
    const field = form.tab_template.tabs[0].fields[0];
    const selectedValues = this.getMafFieldValues(form);

    const enum_values = Array.isArray(field?.enum_values)
      ? field?.enum_values
      : null;
    if (enum_values && enum_values.length) {
      return enum_values.map((item) => ({
        id: String(item.value),
        text: item.title || item.value,
        isSelected: selectedValues.some((s) => s.id === item.value),
      }));
    }

    const { dictionaries } = this.props;
    const dictionary = dictionaries?.[field?.lookup ?? ''];
    const lookup_display_field_name = field?.lookup_display_field_name;
    if (dictionary && dictionary.length) {
      return dictionary.map((item) => ({
        ...item,
        id: String(item.object_id),
        text: item[lookup_display_field_name || 'name'],
        isSelected: selectedValues.some((s) => s.id === item.object_id),
      }));
    }

    return [];
  };

  getMafFieldValues = (form: MafTab): { id: string }[] => {
    if (form.tabs) {
      return form.tabs
        .filter((tab) => tab.op !== MafOp.delete)
        .flatMap(this.getMafFieldValues);
    }
    if (form.op !== MafOp.delete) {
      return (form.fields || []).map((item) => ({
        id: item.value,
      }));
    }
    return [];
  };

  onChange = (form: MafTabWithTemplate, { id, isSelected }) => {
    const { onCreateFormByTemplate, onDeleteAllTabs } = this.props;
    const isSelectAll = !id;

    if (isSelectAll) {
      //select all flow
      if (!isSelected) {
        onDeleteAllTabs(form.name);
        this.setState({
          fields: this.getFields(this.props.form),
        });
        return;
      }
      const { dictionaries } = this.props;
      const field = form.tab_template.tabs[0].fields[0];
      const lookup = field?.lookup;
      if (!lookup) {
        return;
      }
      const dictionary = dictionaries?.[lookup];

      dictionary?.forEach((item) => {
        onCreateFormByTemplate(form.name, form.tab_template, [
          String(item.object_id),
        ]);
      });
      this.setState({
        fields: this.getFields(this.props.form),
      });
      return;
    }

    if (isSelected) {
      const tabs = this.filterTabsByFieldValue(form.tabs || [], id);
      if (tabs.length) {
        tabs.forEach((tab) => {
          if (tab.op === MafOp.delete) {
            tab.op = MafOp.view;
          }
        });
      } else {
        onCreateFormByTemplate(form.name, form.tab_template, [id]);
      }
    } else {
      this.deleteTabsByValue(form, id);
    }

    this.setState({
      fields: this.getFields(this.props.form),
    });
  };

  deleteTabsByValue(form: MafTab, fieldValue: string) {
    const { onDeleteForm } = this.props;

    const tabs = this.filterTabsByFieldValue(form.tabs || [], fieldValue);

    for (const tab of tabs) {
      if (tab.name) {
        onDeleteForm(tab.name);
      }
    }
  }

  filterTabsByFieldValue = (tabs: MafTab[], fieldValue: string): MafTab[] => {
    return tabs.filter((tab) => {
      if (tab.tabs) {
        return this.filterTabsByFieldValue(tab.tabs, fieldValue).length > 0;
      }
      if (tab.fields) {
        return tab.fields.some((field) => field.value === fieldValue);
      }
      return false;
    });
  };
}

export default SingleFieldSelect;
