import { StatusTag } from '../BusinessDocumentsList/components/StatusTag';
import I18nHelpers from 'i18n/I18nHelpers';
import { useTranslation } from 'hooks';
import './topPageInformer.scss';

interface Props {
  status: 'accepted' | 'decline' | 'daf_review';
}

const statusMap = {
  daf_review: {
    label: 'businessdocs.DAF.informer.inReview.label',
    text: 'businessdocs.DAF.informer.inReview.text',
  },
  accepted: {
    label: 'businessdocs.DAF.informer.accepted.label',
    text: 'businessdocs.DAF.informer.accepted.text',
  },
  decline: {
    label: 'businessdocs.DAF.informer.declined.label',
    text: 'businessdocs.DAF.informer.declined.text',
  },
};

const TopPageInfromer: React.FC<Props> = ({ status }) => {
  const getTranslate = useTranslation();

  return (
    <div className='maf-status-informer'>
      <div className='maf-status-informer__status-tag'>
        <StatusTag status={status} wasMafUpdated />
      </div>
      <div className='maf-status-informer__description'>
        <div className='maf-status-informer__description-label'>
          {getTranslate(statusMap[status].label)}
        </div>
        <div className='maf-status-informer__description-content'>
          {I18nHelpers.getJsx(getTranslate(statusMap[status].text))}
        </div>
      </div>
    </div>
  );
};

export { TopPageInfromer };
