import React, { PureComponent } from 'react';
import Input from 'components/ui/input';
import InputProps from 'components/ui/input/InputProps';

class InputAmount extends PureComponent<InputProps> {
  render() {
    const { cleaveOptions } = this.props;

    return (
      <Input
        {...this.props}
        // if more than 20 characters, value could reach out than NUMBER.MAX_SAFE_INTEGER
        maxLength={this.props.maxLength || 20}
        cleaveOptions={{
          ...cleaveOptions,
          numeral: true,
          delimiter: ' ',
        }}
      />
    );
  }
}

export default InputAmount;
