import React from 'react';
import RoundButton from 'components/ui/roundButton';
import DataBlock from 'components/ui/dataBlock';
import './settingCard.scss';

interface Props {
  data: {
    id: string;
    title?: string;
    content: { key: string; value: string | number | null }[];
  };
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const SettingCard: React.FC<Props> = ({ data, onEdit, onDelete }) => {
  const { id, title, content } = data;

  return (
    <div className='setting-card'>
      <div className='setting-card__header'>
        {title ? (
          <div className='setting-card__title utils-overflow-dots'>{title}</div>
        ) : null}

        <div className='setting-card__actions'>
          <RoundButton
            size={26}
            iconSize={10}
            onClick={() => onEdit(id)}
            icon='im-Edit1'
          />
          <RoundButton
            size={26}
            iconSize={10}
            onClick={() => onDelete(id)}
            icon='im-Basket'
            status='danger'
          />
        </div>
      </div>
      <div className='setting-card__content'>
        <DataBlock data={content} theme='modern' />
      </div>
    </div>
  );
};

export { SettingCard };
