import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

export default [
  {
    filterId: filtersKeys.invoicePaymentId,
    type: FilterTypes.text,
    title: 'invoicing.quickFilters.paymentID',
  },
  {
    filterId: filtersKeys.invoiceProjectId,
    type: FilterTypes.multiSelect,
    title: 'invoicing.quickFilters.projectId',
    dictionary: 'enabledProject',
  },
  {
    filterId: filtersKeys.invoiceStatus,
    type: FilterTypes.multiSelect,
    dictionary: 'invoiceStatus',
    title: 'invoicing.quickFilters.status',
  },
  {
    filterId: filtersKeys.invoiceCurrency,
    type: FilterTypes.multiSelect,
    dictionary: 'currency',
    title: 'invoicing.quickFilters.currency',
  },
  {
    filterId: filtersKeys.invoiceCreatedAt,
    type: FilterTypes.dateButtons,
  },
];
