import { MafOp } from '../constants';
import { AnyObject } from 'types/Common';
import { BoardingStage } from 'types/BoardingStage';

export type ItemType =
  | 'inline_edit'
  | 'multi_fields_tile'
  | 'single_field'
  | 'single_field_tiles'
  | 'tab'
  | 'display_tab_by_pages';

export interface Maf {
  crm_id: string;
  caption: string;
  locked: boolean;
  to_lock_in_crm: boolean;
  sections?: MafSection[];
  boarding_status?: BoardingStage;
}

export type MafPreview = {
  // actualize with MafDigest
  caption: string;
  crm_id: string;
  maf_was_open: boolean;
  data_hash: string;
  boarding_status: string;
  locked: string;
  sections: string;
  to_lock_in_crm: string;
  '@type': string;
  created_on: string;
  updated_on: string;
};

export type MafDigest = {
  mafs: MafPreview[] | Maf[];
};

export interface MafSection {
  section_type_name: string;
  section_type: string;
  caption: string;
  crm_id: string;
  highlight: boolean;
  order: number;
  appendixes: MafAppendix[];
  tabs?: MafTab[];

  __grouped?: boolean;
}

export interface MafTab {
  index?: number;
  name: string;
  caption: string;
  crm_id: string;
  notes?: string;
  item_type?: ItemType;
  is_modal_mode?: boolean;
  requires_not_empty_tabs?: boolean;
  entity_schema_name?: string;
  fields: MafField[];
  tabs: MafTab[];
  tab_template?: MafTab;
  enabled?: boolean;
  op: MafOp;
  behaviour_type?:
    | 'read-only'
    | 'select-all-available'
    | 'filebin'
    | 'readonly_and_links';
  visible_rule_set?: RuleSet;
  blocking_rule_set?: RuleSet;
  group_by_tag?: string;
  validators?: MafTabValidatorConfig[];

  // client fields
  __temporal?: boolean;
  __canRemove?: boolean;
  __parentFormId?: string;
  __parent?: MafTab;
  __tabGroup?: boolean;
  __collapsed?: true; // render just a tab preview or a full collapsed version
  __grouped?: boolean;
}

export interface MafAppendix {
  appendix_type: string;
  appendix_type_name: string;
  crm_id: string;
  caption: string;
  order: number;
  highlight: boolean;
  tabs?: MafTab[];

  __grouped?: boolean;
}

export type Condition =
  | 'equal'
  | 'not_equal'
  | 'greater_equal'
  | 'greater'
  | 'less'
  | 'less_equal'
  | 'filled_in'
  | 'not_filled_in';

export interface Rule {
  field: {
    tab: string;
    name: string;
  };
  enabled?: boolean;
  condition: Condition;
  value: string;
  index: string;
}

type RuleSet = {
  rules: Rule[];
  expression_mask: string;
};

export type MafFieldType =
  | 'index'
  | 'string'
  | 'phone'
  | 'number'
  | 'int'
  | 'checkbox'
  | 'tile_checkbox'
  | 'radiobutton'
  | 'file'
  | 'lookup'
  | 'date'
  | 'heading_label'
  | 'warning'
  | 'combo_lookup'
  | 'details'
  | 'row_delete_enable'
  | 'type_of_window'
  | 'tab_indicator'
  | 'filesize';

export interface MafField {
  default_value?: string;
  type: MafFieldType;
  value: string;
  name: string;
  caption: string;
  order: number;
  required: boolean;
  enabled: boolean;
  op: MafOp;
  notes?: string;
  config?: {
    min_date: string;
    max_date: string;
  };
  regexp?: string;
  mismatch_message?: string;
  obligatory_if_invisible?: boolean;
  lookup_order_field?: string;
  lookup_display_value?: string;
  lookup_display_field_name?: string;
  lookup?: string;
  attach_type_id?: string;
  doc_type_id?: string;
  enum_values?: {
    title: string;
    value: string;
  }[];
  visible_rule?: Rule;
  obligatory_rule_set?: RuleSet;
  visible_rule_set?: RuleSet;
  blocking_rule_set?: RuleSet;
  validation_rule_set?: RuleSet;
  filter_rule?: {
    dict_field_name: string;
    field: {
      name: string;
      tab: string;
    };
  };
  behaviour?: string;
}

export type RuleSetName =
  | 'obligatory_rule_set'
  | 'visible_rule_set'
  | 'blocking_rule_set'
  | 'validation_rule_set';

interface MafDictionaryItem {
  name: string;
  object_id: number | string;
  position?: number;
}

type MafDictionaryList = MafDictionaryItem[];

export interface MafDictionaries {
  [key: string]: MafDictionaryList;
}

export interface MafInputData {
  mafData: Maf;
  crmMaf: AnyObject;
  crmMafQueue: AnyObject;
}

export enum MafStepsType {
  //foremaf
  GENERAL = 'general',
  UBOS = 'ultimate beneficial owners',
  //simplemaf
  BASIC_INFO = 'basic information on the company',
  UBO = 'ultimate beneficial owner',
  WEBSITE_AND_APPS = 'website, applications and transactions',
  SIGNATORIES = 'authorised signatory',
  DETAILS = 'settlement account details',
  OVERVIEW = 'overview.maf.tab',
  //daf
  DAF_INFO = 'businessdocs.daf.info.label',
  DAF_MERCH = 'businessdocs.daf.merchantbasicdata.label',
  DAF_UBO = 'businessdocs.daf.ubo.label',
  DAF_MANAGERS = 'businessdocs.daf.seniormanagers.label',
  DAF_CONTACTS = 'businessdocs.daf.primarycontact.label',
  DAF_ADD_CONTACTS = 'businessdocs.daf.additionalcontacts.label',
  DAF_SIGNERS = 'businessdocs.daf.signers.label',
  DAF_DIRECTORS = 'businessdocs.daf.directors.label',
  DAF_SHAREHOLDERS = 'businessdocs.daf.shareholders.label',
  DAF_WEBSITE_INFO = 'businessdocs.daf.websiteinfo.label',
  DAF_LICENSING = 'businessdocs.daf.licensingandregulation.label',
  DAF_AML = 'businessdocs.daf.amlpolicy.label',
  DAF_PREVENTION = 'businessdocs.daf.preventionpractices.label',
  DAF_PAYIN = 'businessdocs.daf.cardspayin.label',
  DAF_PAYOUT = 'businessdocs.daf.cardspayout.label',
  DAF_ANTIFRAUD = 'businessdocs.daf.antifraudfilters.label',
  DAF_ACCOUNT = 'businessdocs.daf.settlaccountdetails.label',
  DAF_ADD_INFO = 'businessdocs.daf.additionalinfo.label',
  DAF_DOCS_UPLOAD = 'businessdocs.daf.documentsupload.label',
  //additional daf
  ADDITIONAL_DAF_COMPANY_DOCS = 'businessdocs.daf.companydocs.label',
  ADDITIONAL_DAF_UBO_DOCS = 'businessdocs.daf.ubodocs.label',
  ADDITIONAL_DAF_DIRECTOR_DOCS = 'businessdocs.daf.directordocs.label',
  ADDITIONAL_DAF_SHAREHOLDERS_DOCS = 'businessdocs.daf.shareholderdocs.label',
}
export type MafPageTabManagerState = {
  currentPageIndex: number;
  pages: MafTab[];
  isPageTabMode: boolean;
};

export type MafState = {
  dictionaries: MafDictionaries;
  actionsBeforeUpdate: Record<string, Function>;
  validatedTabs: Record<string, boolean>;
  isDictionaryFetching: boolean;
  status?: BoardingStage; // TODO separate maf and boarding statuses
};

export type MafValidationErrors = Record<
  MafTab['name'],
  Record<MafField['name'] | 'tab_error', string>
>;

export interface MafTabValidatorConfig {
  type: string;
  field_names: string;
  condition?: Condition;
  value?: string;
}

export interface DictionaryOption {
  value: string;
  label: string;
}
