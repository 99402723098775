import ActionReducer from 'types/ActionReducer';
import ACTIONS from 'constants/actionTypes';

interface State {
  wl: string;
  salesEmail: string;
  themeConfig: {
    colors: {
      brand: {
        main: string;
      };
    };
  };
  isSignUpAvailable: boolean;
}

const initialState: State = {
  wl: '',
  salesEmail: '',
  themeConfig: {
    colors: {
      brand: {
        main: '',
      },
    },
  },
  isSignUpAvailable: false,
};

export const settings = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_WL:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default settings;
