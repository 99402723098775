import { useTranslation } from 'hooks';

interface Props {
  status: string;
}

const statusToTextMap = {
  daf_fill: 'businessdocuments.doc.action.edit',
  daf_review: 'businessdocuments.doc.action.view',
  accepted: 'businessdocuments.doc.action.view',
  decline: 'businessdocuments.doc.action.view',
};

const LinkText: React.FC<Props> = ({ status }) => {
  const getTranslate = useTranslation();

  return (
    <div className='merchant-legal-entity-table__link'>
      {getTranslate(statusToTextMap[status])}
    </div>
  );
};

export { LinkText };
