import React from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';

import { closeModal } from 'actions/modal';

import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import SquareImg from 'images/common/Square.svg';
import RectangleImg from 'images/common/Rectangle.svg';

import './editLayout.scss';

interface OwnProps {
  callback: (layoutType: string) => void;
}

type Props = OwnProps & IntlProps & StoreProps;

export enum LayoutTypes {
  grid,
  vertical,
}

const Square = () => (
  <img src={SquareImg} alt='' className='edit-layout__square' />
);

const Rectangle = () => (
  <img src={RectangleImg} alt='' className='edit-layout__square' />
);

class EditLayout extends React.PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    return (
      <div className='edit-layout'>
        <ModalTitle>
          {getTranslate('analytics.mainView.layout.editLayout.label')}
        </ModalTitle>
        <ModalContent>
          <div
            className='layout-type layout-type_grid-modules'
            onClick={() => this.chooseLayout(LayoutTypes.grid)}>
            <Square />
            <Square />
            <Square />
            <Square />
          </div>
          <div
            className='layout-type layout-type_grid-rows'
            onClick={() => this.chooseLayout(LayoutTypes.vertical)}>
            <Rectangle />
            <Rectangle />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            status='outline'
            onClick={this.closeModal}
            text={getTranslate('common.cancel.button')}
          />
        </ModalFooter>
      </div>
    );
  }

  chooseLayout = (type) => {
    const { callback } = this.props;
    this.closeModal();
    return callback(type);
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(closeModal());
  };
}

export default connect()(addTranslation(EditLayout));
