import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'hooks';
import { useDispatch } from 'react-redux';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { changeOrder } from 'actions/analytics';
import AddGraphButton from './components/AddGraphButton';
import Button from 'components/ui/button';
import GraphCard from './components/GraphItem';
import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';
import Loader from 'components/ui/loader';
import './analytics.scss';

interface Props {
  layoutType: LayoutTypes;
  chartsFetched: boolean;
  byOrder: {
    [order: number]: {
      biChartId: string;
      fetched: boolean;
      error?: string;
    };
  };
  isAddChartDisabled: boolean;
  openChartEditModal: () => void;
  openLayoutModal: () => void;
}

const Analytics: React.FC<Props> = ({
  openChartEditModal,
  openLayoutModal,
  chartsFetched,
  byOrder,
  layoutType,
  isAddChartDisabled,
}) => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
        delay: 100,
      },
    })
  );

  const items = Object.entries(byOrder).map(([order, chart]) => ({
    id: chart.biChartId,
    fetched: chart.fetched,
    error: chart.error,
    order: Number(order),
  }));

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return;
    }

    const oldOrder = active.data.current!.order;
    const newOrder = over.data.current!.order;

    dispatch(
      changeOrder({
        oldOrder,
        newOrder,
      })
    );
  };

  const renderContent = () => {
    if (!items.length) {
      return <AddGraphButton addNewChart={openChartEditModal} />;
    }

    return (
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={items}>
          <div
            className={classNames({
              'sortable-container-grid': layoutType === LayoutTypes.grid,
              'sortable-container-vertical':
                layoutType === LayoutTypes.vertical,
            })}>
            {items.map((item) => (
              <SortableItem key={item.id} layoutType={layoutType} {...item} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <div className='analytics'>
      <div className='analytics__top-panel'>
        <Button
          status='primary'
          onClick={openChartEditModal}
          text={getTranslate('analytics.mainView.addChart.button')}
          disabled={isAddChartDisabled}
          tooltip={
            isAddChartDisabled
              ? getTranslate('analytics.mainView.addChart.toolTip')
              : ''
          }
        />
        <Button
          customClass='analytics__edit-layout'
          status='outline'
          onClick={openLayoutModal}
          text={getTranslate('analytics.mainView.editLayout.button')}
        />
      </div>
      {chartsFetched ? renderContent() : <Loader />}
    </div>
  );
};

const SortableItem: React.FC<{
  id: string;
  fetched: boolean;
  layoutType: LayoutTypes;
  order: number;
  error?: string;
}> = (props) => {
  const { id, order } = props;
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, data: { order } });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <GraphCard
        {...props}
        isDragging={isDragging}
        dragHandler={{ ...attributes, ...listeners }}
      />
    </div>
  );
};

export default Analytics;
