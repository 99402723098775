import React, { Component } from 'react';
import { connect } from 'react-redux';

import { StoreProps } from 'store';
import { closeModal } from 'actions/modal';
import Modal from './Modal';

import modalsList from './modalList';
import Confirm from 'components/modal/modalList/Confirm';

interface ConnectedProps {
  modal: {
    content: object;
    theme: string;
    isOpened: boolean;
    modalId: string;
    onClickAwayClose: boolean;
    customClassName?: string;
    needCloseButton?: boolean;
    tooltipId?: string;
    invertButtons?: boolean;
    confirm?: {
      title: string;
      text: string;
    };
    callback?: (data: any) => void;
    onClose?: () => void;
  };
}

type Props = ConnectedProps & StoreProps;

class ModalContainer extends Component<Props> {
  onClose = (result?) => {
    const {
      modal: { callback, onClose },
      dispatch,
    } = this.props;

    dispatch(closeModal());

    if (onClose) {
      onClose();
    }

    if (callback) {
      return callback(result ?? false);
    }
  };

  render() {
    const {
      modal: {
        isOpened,
        modalId,
        content,
        theme,
        customClassName,
        tooltipId,
        invertButtons,
        confirm,
        callback,
        needCloseButton = true,
        onClickAwayClose = true,
      },
    } = this.props;

    const ModalContent = modalsList[modalId];

    if (!ModalContent) {
      return null;
    }

    return (
      <Modal
        isOpened={isOpened}
        modalId={modalId}
        theme={theme}
        customClassName={customClassName}
        onClickAwayClose={onClickAwayClose}
        needCloseButton={needCloseButton}
        tooltipId={tooltipId}
        onClose={this.onClose}>
        {
          <ModalContent
            callback={this.onClose}
            content={content}
            invertButtons={invertButtons}>
            {confirm ? (
              <Confirm content={confirm} callback={callback || (() => null)} />
            ) : null}
          </ModalContent>
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  modal: state.modal,
});

export default connect(mapStateToProps)(ModalContainer);
