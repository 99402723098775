import React, { isValidElement, PureComponent } from 'react';
import classNames from 'classnames';
import './radioGroup.scss';

interface Props<Id = string> {
  checkedId: string;
  onChange: (id: Id) => void;
  label?: string;
  customClass?: string;
  error?: string;
  id?: string;
}

class RadioGroup<Id> extends PureComponent<Props<Id>> {
  static defaultProps = {
    customClass: '',
  };

  render() {
    const { children, customClass, checkedId, onChange, label, error } =
      this.props;

    return (
      <div
        className={classNames('ui-radio-group', customClass, {
          'ui-radio-group_error': !!error,
        })}
        id={this.props.id}>
        {label && <div className='ui-radio-group__label'>{label}</div>}
        <div className='ui-radio-group__list'>
          {React.Children.map(children, (child, key) => {
            if (!child) return null;
            if (isValidElement(child)) {
              const { id, isChecked } = child.props;
              return (
                <div className='ui-radio-group__item'>
                  {React.cloneElement(child, {
                    key,
                    id,
                    customClass: child.props.customClass || '',
                    isChecked: Boolean(
                      id === checkedId || (!checkedId && isChecked)
                    ),
                    onChange: () => onChange(id),
                  })}
                </div>
              );
            }
          })}
        </div>

        {error && <span className='ui-radio-group__error'>{error}</span>}
      </div>
    );
  }
}

export default RadioGroup;
