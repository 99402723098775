import DateHelpers from 'helpers/Date';
import { FilterTypes } from 'constants/FilterTypes';
import FormFieldsConfig from 'components/formFields/types';

export const fieldsConfig: FormFieldsConfig = {
  general: [
    {
      id: 'paymentId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      generateValue: true,
      label: 'invoicing.newinvoice.paymentID',
      placeholder: 'invoicing.newinvoice.paymentID.placeholder',
      subText: 'invoicing.newinvoice.paymentId.hint',
      tooltip: 'paymentIdGenerator.common.info.hint',
      clearButton: true,
    },
    [
      {
        id: 'amount',
        fieldType: FilterTypes.amount,
        valueType: 'string',
        required: true,
        label: 'invoicing.newinvoice.amount',
        placeholder: 'invoicing.newinvoice.amount.placeholder',
      },
      {
        id: 'currency',
        fieldType: FilterTypes.select,
        valueType: 'string',
        required: true,
        dictionaryId: 'currency',
        label: 'invoicing.newinvoice.currency',
        placeholder: 'invoicing.newinvoice.currency.placeholder',
        tooltip: 'select.currency.favorite.tooltip',
      },
    ],
    {
      id: 'bestBefore',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      initialValue: DateHelpers.getFormat(
        DateHelpers.getEndDay(DateHelpers.getDate())
      ),
      required: true,
      maxDate: DateHelpers.getFormat(
        DateHelpers.add(1, 'months').endOf('month'),
        'datetime'
      ),
      minDate: DateHelpers.getFormat(DateHelpers.getDate(), 'datetime'),
      label: 'invoicing.newinvoice.expiryDate',
    },
    {
      id: 'projectId',
      fieldType: FilterTypes.select,
      valueType: 'string',
      required: true,
      dictionaryId: 'invoiceProject',
      dictionaryValueKey: 'projectId',
      label: 'invoicing.newinvoice.project',
      placeholder: 'invoicing.newinvoice.project.placeholder',
    },
    {
      id: 'paymentMethodCode',
      fieldType: FilterTypes.select,
      valueType: 'number',
      required: true,
      dictionaryId: 'paymentMethods',
      customOptions: true,
      label: 'invoicing.newinvoice.paymentMethod',
      placeholder: 'invoicing.newinvoice.paymentMethod.placeholder',
      tooltip: 'invoicing.newinvoice.paymentMethod.tooltip',
    },
  ],
  additional: [
    {
      id: 'customerId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'invoicing.newinvoice.customerID',
      placeholder: 'invoicing.newinvoice.customerID.placeholder',
      subText: 'invoicing.newinvoice.customerId.hint',
    },
    {
      id: 'description',
      fieldType: 'textarea',
      valueType: 'string',
      required: false,
      label: 'invoicing.newinvoice.description',
      placeholder: 'invoicing.newinvoice.description.placeholder',
    },
    {
      id: 'cardOperationType',
      fieldType: 'radioList',
      valueType: 'string',
      initialValue: 'auth',
      required: true,
      label: 'invoicing.newinvoice.operationType',
      list: [
        {
          id: 'auth',
          text: 'invoicing.newinvoice.auth.label',
          tooltip: 'invoicing.newinvoice.auth.hint',
        },
        {
          id: 'sale',
          text: 'invoicing.newinvoice.sale.label',
          tooltip: 'invoicing.newinvoice.sale.hint',
        },
      ],
    },
    {
      id: 'customerEmail',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: false,
      label: 'invoicing.newinvoice.email',
      placeholder: 'invoicing.newinvoice.email.placeholder',
    },
    {
      id: 'motoType',
      fieldType: FilterTypes.select,
      valueType: 'string',
      required: true,
      dictionaryId: 'motoType',
      label: 'invoicing.newinvoice.moto',
      placeholder: 'invoicing.newinvoice.moto',
    },
    {
      id: 'cardToken',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'invoicing.newinvoice.cardToken',
      placeholder: 'invoicing.newinvoice.cardToken.placeholder',
    },
    {
      id: 'isEmailSend',
      fieldType: 'switch',
      valueType: 'boolean',
      required: false,
      label: 'invoicing.newinvoice.sendInvoice.button',
    },
  ],
};

export const fieldsConfigSubscription: FormFieldsConfig = {
  general: fieldsConfig.general
    .map((item) => {
      if (Array.isArray(item)) {
        return item.map((innerItem) => {
          if (innerItem.id === 'amount') {
            return {
              ...innerItem,
              label: 'invoicing.newSubscriptionInvoice.amount',
            };
          }
          return innerItem;
        });
      } else if (item.id === 'paymentMethodCode') {
        return {
          ...item,
          tooltip: '',
        };
      }

      return item;
    })
    .concat([
      {
        id: 'text',
        fieldType: 'infoText',
        label: 'invoicing.newinvoice.subscription.paymentMethodEmpty',
      },
    ]),
  additional: [
    fieldsConfig.additional[0],
    {
      id: 'customerFirstName',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'invoice.create.subscription.customerFirstName.label',
      placeholder: 'invoice.create.subscription.customerFirstName.label',
    },
    {
      id: 'customerLastName',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'invoice.create.subscription.customerLastName.label',
      placeholder: 'invoice.create.subscription.customerLastName.label',
    },
    ...fieldsConfig.additional
      .slice(1)
      .filter(
        (field) => !Array.isArray(field) && field.id !== 'cardOperationType'
      ),
    {
      id: 'cardVerify',
      fieldType: 'switch',
      valueType: 'boolean',
      required: false,
      label: 'invoice.create.subscription.cardVerify.label',
    },
  ],
  subscription: [
    {
      id: 'title',
      fieldType: 'title',
      label: 'invoicing.newinvoice.subscriptionInfo.title',
    },
    {
      id: 'recurringScheduledPaymentId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'subscriptions.settings.recurringPaymentId.label',
      placeholder: 'subscriptions.settings.recurringPaymentId.label',
    },
    [
      {
        id: 'recurringInterval',
        fieldType: FilterTypes.numbers,
        valueType: 'string',
        initialValue: '1',
        required: true,
        label: 'subscriptions.settings.interval.label',
        placeholder: 'subscriptions.settings.interval.placeholder',
      },
      {
        id: 'recurringPeriod',
        fieldType: FilterTypes.select,
        valueType: 'string',
        required: true,
        label: 'subscriptions.settings.recurringCycle.label',
        placeholder: '',
        dictionaryId: 'subscriptionPaymentPeriod',
        customOptions: true,
      },
    ],
    {
      id: 'recurringStartDate',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      required: true,
      label: 'invoicing.newSubscriptionInvoice.firstRecurring',
      minDate: DateHelpers.getFormat(DateHelpers.getDate(), 'datetime'),
      maxDate: 'none',
      placeholder: '',
    },
    {
      id: 'recurringEndDate',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      label: 'invoicing.newSubscriptionInvoice.lastRecurring',
      minDate: DateHelpers.getFormat(DateHelpers.getDate(), 'datetime'),
      maxDate: 'none',
      placeholder: '',
    },
  ],
};

export const fieldsConfigStc: Pick<
  FormFieldsConfig,
  'senderInfo' | 'serviceDetails'
> = {
  senderInfo: [
    {
      id: 'title',
      fieldType: 'title',
      label: 'invoicing.newinvoice.senderInfo.label',
    },
    [
      {
        id: 'customerFirstName',
        fieldType: FilterTypes.text,
        valueType: 'string',
        label: 'invoicing.newinvoice.firstName',
        placeholder: 'invoicing.newinvoice.firstName.placeholder',
      },
      {
        id: 'customerLastName',
        fieldType: FilterTypes.text,
        valueType: 'string',
        label: 'invoicing.newinvoice.lastName',
        placeholder: 'invoicing.newinvoice.lastName.placeholder',
      },
    ],
    {
      id: 'customerPhone',
      fieldType: FilterTypes.phoneInput,
      valueType: 'string',
      label: 'invoicing.newinvoice.phone',
      placeholder: 'invoicing.newinvoice.phone.placeholder',
    },
    {
      id: 'additionalDataSenderCountryCode',
      fieldType: FilterTypes.text,
      valueType: 'string',
      label: 'invoicing.newinvoice.countryCode',
      placeholder: 'invoicing.newinvoice.countryCode.placeholder',
    },
    {
      id: 'additionalDataSenderCreateDate',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      label: 'invoicing.newinvoice.createDate',
    },
  ],
  serviceDetails: [
    {
      id: 'title',
      fieldType: 'title',
      label: 'invoicing.newinvoice.serviceDetails.label',
    },
    {
      id: 'additionalDataOtaType',
      fieldType: FilterTypes.text,
      valueType: 'string',
      label: 'invoicing.newinvoice.otaType',
      placeholder: 'invoicing.newinvoice.otaType.placeholder',
    },
    {
      id: 'additionalDataOtaServiceStartDate',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      label: 'invoicing.newinvoice.otaType.startDate',
      maxDate: 'none',
    },
    {
      id: 'additionalDataOtaServiceEndDate',
      fieldType: FilterTypes.dateTime,
      valueType: 'string',
      label: 'invoicing.newinvoice.otaType.endDate',
      maxDate: 'none',
    },
    {
      id: 'additionalDataOtaChangeGuestTF',
      fieldType: FilterTypes.radioList,
      valueType: 'boolean',
      label: 'invoicing.newinvoice.otaChange',
      list: [
        {
          id: '1',
          text: 'common.yes.button',
        },
        {
          id: '0',
          text: 'common.no.button',
        },
      ],
    },
    {
      id: 'additionalDataOtaStartCountry',
      fieldType: FilterTypes.text,
      valueType: 'string',
      label: 'invoicing.newinvoice.otaTypeCountry',
      placeholder: 'invoicing.newinvoice.otaTypeCountry.placeholder',
    },
  ],
};
