import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';

interface Props {
  status: string;
  wasMafUpdated: boolean;
}

const unupdatedMafStatusMap = {
  daf_fill: 'New',
};

const updatedMafStatusMap = {
  daf_fill: 'In Progress',
  daf_review: 'In review',
  accepted: 'Accepted',
  decline: 'Declined',
};

const StatusTag: React.FC<Props> = ({ status, wasMafUpdated }) => {
  const statusToDispalay = wasMafUpdated
    ? updatedMafStatusMap[status]
    : unupdatedMafStatusMap[status] || status;

  return (
    <div className='status-tag'>
      <LabelStatus
        status={Mapper.getStatus(statusToDispalay)}
        text={statusToDispalay.replace(' ', '\u00A0')} // non-braking space
      />
    </div>
  );
};

export { StatusTag };
