import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import ModalTitle from 'components/modal/components/ModalTitle';
import Button from 'components/ui/button';
import { MultipleSelectionList } from 'components/ui/MultipleSelectList';
import Radio from 'components/ui/radio';
import RadioGroup from 'components/ui/radioGroup';
import { AdminUserFields } from 'components/userBuilder/UserFieldsInterface';
import { useTranslation } from 'hooks';
import { ButchUserEditMode, Project } from './types';
import { SimpleList } from './components/SimpleList';
import './butchUserEditModal.scss';

type Props = {
  projects: Project[];
  isSubmitting: boolean;
  editMode: ButchUserEditMode;
  selectedProjects: string[];
  selectedUsers: AdminUserFields[];
  onCancel: () => void;
  onSubmit: () => void;
  onModeChange: (value: ButchUserEditMode) => void;
  onSelectedProjectsChange: (selected: string[]) => void;
};

export const ButchUserEditView: React.FC<Props> = ({
  editMode,
  projects,
  onCancel,
  onSubmit,
  isSubmitting,
  onModeChange,
  selectedUsers,
  selectedProjects,
  onSelectedProjectsChange,
}) => {
  const getTranslate = useTranslation();

  return (
    <>
      <ModalTitle>{getTranslate('myteam.bunchEdit.modal.title')}</ModalTitle>
      <ModalContent>
        <div className='butch-user-edit-modal'>
          <div className='butch-user-edit-modal__title'>
            {getTranslate('myteam.bunchEdit.modal.chooseMode.title')}
          </div>
          <RadioGroup
            checkedId={editMode}
            onChange={onModeChange}
            customClass='butch-user-edit-modal__radio-buttons'>
            <Radio
              customClass='butch-user-edit-modal__radio-button'
              isDisabled={isSubmitting}
              key='add'
              id='add'
              text={getTranslate('myteam.bunchEdit.mode.add.label')}
            />
            <Radio
              customClass='butch-user-edit-modal__radio-button butch-user-edit-modal__radio-button_red'
              isDisabled={isSubmitting}
              key='remove'
              id='remove'
              text={getTranslate('myteam.bunchEdit.mode.remove.label')}
            />
          </RadioGroup>
          <div className='butch-user-edit-modal__title'>
            {getTranslate('myteam.bunchEdit.modal.selectProject.title')}
          </div>
          <div>
            <div className='butch-user-edit-modal__selectors'>
              <MultipleSelectionList
                disabled={isSubmitting}
                options={projects.map((project) => ({
                  label: project.text,
                  value: project.projectId,
                }))}
                onChange={(selected) => {
                  onSelectedProjectsChange(
                    selected.map((project) => project.value)
                  );
                }}
                addSelectorButtonLabel={getTranslate(
                  'myteam.bunchEdit.addProject.button'
                )}
              />
            </div>
          </div>
          <div className='butch-user-edit-modal__title'>
            {getTranslate('myteam.bunchEdit.modal.selectedUsers.title')}{' '}
            {`(${selectedUsers.length})`}
          </div>
          <div>
            <SimpleList items={selectedUsers.map((user) => user.email)} />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className='butch-user-edit-modal__buttons'>
          <Button
            disabled={isSubmitting}
            text={getTranslate('common.cancel.button')}
            status='outline'
            size='normal'
            onClick={onCancel}
          />
          <Button
            text={getTranslate('common.Continue.button')}
            loading={isSubmitting}
            status='primary'
            size='normal'
            disabled={!selectedProjects.length}
            tooltip={getTranslate('myteam.bunchEdit.continueDisabled.tooltip')}
            onClick={onSubmit}
          />
        </div>
      </ModalFooter>
    </>
  );
};
