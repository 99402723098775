import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip';
import { useTranslation } from 'hooks';
import { checkSelectedRcsProjects } from 'pages/risks/helpers';
import WbListFields from './WbListFields';
import ListTypes from './ListTypes';
import { AnyObject } from 'types/Common';
import SelectItemType from 'types/SelectItem';
import { getCreateListAccessByProjects } from 'api/risks';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import './wbSingle.scss';

interface Props {
  fields: WbListFields;
  dictionaries: {
    merchants: SelectItemType[];
  };
  isCreating: boolean;
  isLoading: boolean;
  validationErrors: AnyObject;
  canCreate: boolean;
  onChangeField: (key: keyof WbListFields, value: any) => void;
  onCreate: () => void;
  onCancel: () => void;
}

type Access = {
  customer: boolean;
  operation_pan: boolean;
  ip: boolean;
  pan: boolean;
  email: boolean;
  bin: boolean;
};

const TOOLTIP_LOCALE = 'risks.addToList.forProject.tooltip';

const WbSingle: React.FC<Props> = ({
  fields,
  dictionaries,
  validationErrors,
  canCreate,
  isCreating,
  isLoading,
  onChangeField,
  onCreate,
  onCancel,
}) => {
  const getTranslate = useTranslation();
  const [isCheckingAccess, setCheckingAccess] = useState(false);
  const [access, setAccess] = useState<{
    whitelist: Access;
    blacklist: Access;
  }>();
  const hasWhitelistAccess = Boolean(
    Object.values(access?.whitelist || {}).some((item) => item)
  );
  const whiteListTooltip = !hasWhitelistAccess
    ? getTranslate('risks.addToList.whitelist.access.tooltip')
    : undefined;

  useEffect(() => {
    Tooltip.rebuild();
  }, [fields]);

  useEffect(() => {
    fetchPermissionByProject();
  }, [fields.projectList]);

  const fetchPermissionByProject = async () => {
    setCheckingAccess(true);

    const projectIds = fields.projectList
      .filter(({ isSelected }) => isSelected)
      .map(({ id }) => id);

    try {
      const result = await getCreateListAccessByProjects({
        projectIds,
      });

      const access = {
        blacklist: mapAccessList(result.blacklist),
        whitelist: mapAccessList(result.whitelist),
      };

      setAccess(access);
    } catch (e) {
      console.error(e);
    } finally {
      setCheckingAccess(false);
    }
  };

  const mapAccessList = (list: Array<keyof Access>) => {
    const map: Access = {
      customer: false,
      email: false,
      ip: false,
      operation_pan: false,
      pan: false,
      bin: false,
    };

    list.forEach((item) => {
      map[item] = true;
    });

    return map;
  };

  return (
    <div className='wb-single-form'>
      {isCreating || isCheckingAccess ? <Loader /> : null}

      <Form onSubmit={onCreate}>
        <div className='grid-row'>
          <div className='grid-column-1 wb-single-form__column wb-single-form__column_type'>
            <div className='wb-single-form__field-title'>
              {getTranslate('risks.addToList.list')}
            </div>
            <RadioGroup
              onChange={(value) => onChangeField('listType', value)}
              checkedId={fields.listType}>
              <Radio
                id={ListTypes.blackList}
                text={getTranslate('risks.addToList.list.black')}
              />
              <Radio
                id={ListTypes.whiteList}
                isDisabled={!hasWhitelistAccess}
                infoIcon={!isCheckingAccess ? whiteListTooltip : undefined}
                text={getTranslate('risks.addToList.list.white')}
              />
            </RadioGroup>
          </div>
          <div className='grid-column-11'>
            <div className='grid-row'>
              <div className='grid-column-4 wb-single-form__column'>
                <CustomSelect
                  options={dictionaries.merchants}
                  modern
                  value={fields.selectedMerchant}
                  label={getTranslate('risks.addToList.merchant')}
                  error={validationErrors.merchantId}
                  onChange={(value) => onChangeField('selectedMerchant', value)}
                  isLoading={isLoading}
                  customClass='ui-select_large wb-single-form__field'
                />
                <Input
                  id='wb-form-email'
                  type='email'
                  modern
                  disabled={!access?.[fields.listType].email}
                  label={getTranslate('risks.addToList.email')}
                  placeholder={getTranslate(
                    'risks.addToList.email.placeholder'
                  )}
                  tooltip={getTranslate(TOOLTIP_LOCALE)}
                  value={fields.email}
                  error={validationErrors.email}
                  onChange={(event) =>
                    onChangeField('email', event.target.value)
                  }
                  maxLength={255}
                  customClass='wb-single-form__field'
                />
                <Input
                  id='wb-form-bin'
                  modern
                  disabled={!access?.[fields.listType].bin}
                  label={getTranslate('risks.addToList.bin')}
                  placeholder={getTranslate('risks.addToList.bin.placeholder')}
                  value={fields.bin}
                  error={validationErrors.bin}
                  onChange={(event) => onChangeField('bin', event.target.value)}
                  cleaveOptions={{
                    numericOnly: true,
                  }}
                  tooltip={getTranslate('risks.addToList.forMerchant.tooltip')}
                  maxLength={6}
                  customClass='wb-single-form__field'
                />
              </div>
              <div className='grid-column-4 wb-single-form__column'>
                <SelectionList
                  modern
                  label={getTranslate('risks.addToList.project')}
                  items={fields.projectList}
                  error={validationErrors.projectId}
                  onChange={(projects) =>
                    onChangeField('projectList', projects)
                  }
                  isValidBeforeApply={checkSelectedRcsProjects}
                  isLoading={isLoading}
                  customClass='wb-single-form__field'
                />
                <Input
                  id='wb-form-card'
                  modern
                  disabled={!access?.[fields.listType].pan}
                  label={getTranslate('risks.addToList.card')}
                  placeholder={getTranslate('risks.addToList.card.placeholder')}
                  tooltip={getTranslate(TOOLTIP_LOCALE)}
                  value={fields.pan}
                  error={validationErrors.pan}
                  maxLength={19}
                  cleaveOptions={{
                    numericOnly: true,
                  }}
                  onChange={(event) => onChangeField('pan', event.target.value)}
                />
              </div>
              <div className='grid-column-4 wb-single-form__column'>
                <Input
                  id='wb-form-customer'
                  modern
                  disabled={!access?.[fields.listType].customer}
                  label={getTranslate('risks.addToList.customerID')}
                  placeholder={getTranslate(
                    'risks.addToList.customerID.placeholder'
                  )}
                  tooltip={getTranslate(TOOLTIP_LOCALE)}
                  value={fields.customer}
                  error={validationErrors.customer}
                  onChange={(event) =>
                    onChangeField('customer', event.target.value)
                  }
                  maxLength={255}
                  customClass='wb-single-form__field'
                />
                <Input
                  id='wb-form-ip'
                  modern
                  disabled={!access?.[fields.listType].ip}
                  label={getTranslate('risks.addToList.IP')}
                  placeholder={getTranslate('risks.addToList.IP.placeholder')}
                  tooltip={getTranslate('risks.addToList.forMerchant.tooltip')}
                  value={fields.ip}
                  error={validationErrors.ip}
                  maxLength={255}
                  onChange={(event) => onChangeField('ip', event.target.value)}
                />
              </div>
              <div className='grid-column-4 wb-single-form__column'>
                <Input
                  id='wb-form-description'
                  modern
                  type='textarea'
                  resize={false}
                  label={getTranslate('risks.addToList.reason')}
                  placeholder={getTranslate(
                    'risks.addToList.reason.placeholder'
                  )}
                  value={fields.comment}
                  error={validationErrors.description}
                  onChange={(event) =>
                    onChangeField('comment', event.target.value)
                  }
                  customClass='wb-single-form__reason wb-single-form__field'
                />
                <div className='wb-single-form__buttons'>
                  <Button
                    text={getTranslate('common.cancel.button')}
                    onClick={onCancel}
                    status='outline'
                  />
                  <Button
                    text={getTranslate('common.apply.button')}
                    disabled={!canCreate}
                    status='success'
                    type='submit'
                    customClass='wb-single-form__apply'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export { WbSingle };
