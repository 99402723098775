import React, { VFC } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import { WithRouterProps } from 'decorators/withRouter';

import { DafStatusInformerLayout } from './DafStatusInformerLayout';
import I18nHelpers from 'i18n/I18nHelpers';
import { getCommercialOffer } from 'api/maf';
import { MafListDemonstration } from './components/MafListDemonstration/MafListDemonstration';

interface ConnectedProps {
  email: string;
}

export const DafIsReadyComponent: VFC<
  ConnectedProps &
    IntlProps &
    WithRouterProps & { needDownloadButton?: boolean }
> = ({ email, getTranslate, history, needDownloadButton }) => (
  <DafStatusInformerLayout
    title={getTranslate('getStarted.DAFIsReady.label')}
    mainText={I18nHelpers.getJsxFromHtml(
      getTranslate('getStarted.DAFIsReady.text', {
        email: `<a href="mailto:${email}">${email}</a>`,
      })
    )}
    buttons={
      <>
        <Button
          id='goToDaf'
          status='primary'
          onClick={() => history.push('/maf')}
          text={getTranslate('getStarted.fillOut.button')}
        />
        {needDownloadButton && (
          <Button
            id='comOfferDownload'
            status='outline'
            onClick={() => getCommercialOffer()}
            text={getTranslate('getStarted.downloadOffer.button')}
          />
        )}{' '}
      </>
    }
    image={<MafListDemonstration />}
  />
);

const mapStateToProps = (state): ConnectedProps => ({
  email: state.user?.boarding?.email || state.settings.salesEmail,
});

export const DafIsReady = withRouter(
  connect(mapStateToProps)(addTranslation(DafIsReadyComponent))
);
