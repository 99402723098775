import React, { PureComponent } from 'react';
import { invert } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import statusList from 'constants/statusList';
import './labelStatus.scss';
const statusKeys = invert(statusList);

type theme = 'colored' | 'bordered';

export type Status = keyof typeof statusKeys;

interface Props {
  text: string;
  status: Status;
  theme?: theme;
  customClass?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

class LabelStatus extends PureComponent<Props & IntlProps> {
  render() {
    const {
      text,
      status,
      theme = 'colored',
      customClass = '',
      getTranslate,
      onClick,
    } = this.props;

    return (
      <div
        className={`ui-label-status ui-label-status_${theme} ui-label-status_${theme}_${status} ${customClass}`}
        onClick={onClick}>
        <span>{getTranslate(text)}</span>
      </div>
    );
  }
}

export default addTranslation(LabelStatus);
