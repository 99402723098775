import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Input from 'components/ui/input';
import PairedFilter from '../pairedFilter';
import filtersKeys from 'constants/filters';
import { QuickAdminFiltersType } from 'types/QuickFilters';
import { filtersKeysType } from 'types/Filters';
import SelectionList from 'components/ui/selectionList';
import { AnyObject } from 'types/Common';
import {
  connectedFilters,
  inputFields,
  userPropertiesFilterItems,
} from './config';
import './quickFilters.scss';
import Button from 'components/ui/button';

interface Props extends IntlProps {
  showAllFilters: boolean;
  filters: QuickAdminFiltersType;
  updateFiltersValues: (key: string, value: AnyObject) => void;
  onChangeFilter: (key: filtersKeysType, value: any) => void;
  tableName: string;
  filtersValues: AnyObject;
  availableWlItems: any[];
  handleSearch: (filterProp: string, q: string) => void;
  handleChange: (key: string, value: any) => void;
  handleLoadMore: (filterProp: string) => void;
  hasSelectedQuickFilters: boolean;
  hasSelectedFilters: boolean;
  onResetFilters: () => void;
}

interface State {
  isPairFilterLoading: boolean;
}

class QuickFilters extends PureComponent<Props, State> {
  state = {
    isPairFilterLoading: false,
  };

  static defaultProps = {
    onChangeFilter: () => null,
  };

  render() {
    const {
      filtersValues,
      hasSelectedQuickFilters,
      hasSelectedFilters,
      onResetFilters,
    } = this.props;
    return (
      <div className='quick-filters quick-filters-admin'>
        <div className='quick-filters__inner'>
          <div className='quick-filters__col'>
            <div className='quick-filters__row'>
              {this.renderUserFilter()}
              {filtersValues && this.renderConnectedFilters()}
            </div>
            <div className='quick-filters__row'>
              {filtersValues.userStatusAdmin &&
                this.renderUserPropertiesFilter()}
              {this.renderWlId()}
            </div>
          </div>
          <div className='quick-filters__col quick-filters-admin__reset'>
            <Button
              status='danger-light'
              id='reload'
              icon='im-Reset-filter'
              onClick={onResetFilters}
              customClass='ui-button_no-border'
              disabled={!hasSelectedQuickFilters && !hasSelectedFilters}
            />
          </div>
        </div>
      </div>
    );
  }

  renderUserFilter = () => {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return inputFields.map(({ id, label, placeholder, type }) => (
      <div className='quick-filters__block' key={id}>
        <div className='quick-filters__block-title'>{getTranslate(label)}</div>
        <div className='quick-filters__block-content'>
          {type === 'number' ? (
            <Input
              id={`admin-enter-${id}`}
              value={filters[filtersKeys[id]]}
              onChange={(e) => {
                onChangeFilter(filtersKeys[id], e.target.value);
              }}
              placeholder={getTranslate(placeholder)}
              cleaveOptions={{ numericOnly: true }}
            />
          ) : (
            <Input
              placeholder={getTranslate(placeholder)}
              id={`admin-enter-${id}`}
              value={filters[filtersKeys[id]]}
              onChange={(e) => onChangeFilter(filtersKeys[id], e.target.value)}
            />
          )}
        </div>
      </div>
    ));
  };

  renderUserPropertiesFilter = () => {
    const { filters, getTranslate, filtersValues } = this.props;
    const { onChangeFilter } = this.props;
    return userPropertiesFilterItems.map(
      ({ valueProp, filterProp, labelId }) => {
        const items = filtersValues[valueProp].map((item) => ({
          ...item,
          isSelected: filters[filterProp].includes(item.id),
        }));
        const label = getTranslate(labelId);
        return (
          <div
            className={`quick-filters__block ${filterProp}`}
            key={filterProp}>
            <div className='quick-filters__block-title'>{label}</div>
            <div className='quick-filters__block-content'>
              <SelectionList
                id={`${filterProp}-filter`}
                items={items}
                onChange={(value) => {
                  onChangeFilter(
                    filterProp,
                    value
                      .filter((item) => item.isSelected)
                      .map((item) => item.id)
                  );
                }}
              />
            </div>
          </div>
        );
      }
    );
  };

  renderConnectedFilters = () => {
    const {
      getTranslate,
      filtersValues,
      filters,
      onChangeFilter,
      updateFiltersValues,
    } = this.props;
    return connectedFilters.map(
      ({
        valueProp,
        filterProp,
        labelId,
        connectedTo,
        hideForQuickFilters,
      }) => {
        if (hideForQuickFilters) return null;

        const label = getTranslate(labelId);
        const { isPairFilterLoading } = this.state;

        return (
          <div
            className={`quick-filters__block ${filterProp}`}
            key={filterProp}>
            <div className='quick-filters__block-title'>{label}</div>
            <div className='quick-filters__block-content'>
              <div className='filters-multi-select'>
                <PairedFilter
                  valueProp={valueProp}
                  updateFiltersValues={updateFiltersValues}
                  onChangeFilter={onChangeFilter}
                  filtersValues={filtersValues}
                  filters={filters}
                  filterProp={filterProp}
                  connectedTo={connectedTo}
                  isQuickFilters
                  isPairFilterLoading={isPairFilterLoading}
                  setPairFilterLoading={(isPairFilterLoading) =>
                    this.setState({ isPairFilterLoading })
                  }
                />
              </div>
            </div>
          </div>
        );
      }
    );
  };

  renderWlId = () => {
    const {
      onChangeFilter,
      availableWlItems,
      filters: { wlId },
      getTranslate,
    } = this.props;
    const itemsList = availableWlItems.map((item) => {
      if (wlId.includes(item.id)) {
        return { ...item, isSelected: true };
      }
      return item;
    });
    return (
      <div className='quick-filters__block quick-filters__block_wlId'>
        <div className='quick-filters__block-title'>
          {getTranslate('admin.quickfilters.wl.label')}
        </div>
        <div className='quick-filters__block-content'>
          <SelectionList
            items={itemsList}
            onChange={(items) => {
              const selected = items.reduce<string[]>((result, item) => {
                if (item.isSelected) {
                  return [...result, item.id];
                }
                return result;
              }, []);

              return onChangeFilter('wlId', selected);
            }}
          />
        </div>
      </div>
    );
  };
}

export default addTranslation(QuickFilters);
