/* eslint-disable max-lines */
import React, { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import I18nHelpers from 'i18n/I18nHelpers';
import InfoText from 'components/ui/InfoText';
import Button from 'components/ui/button';
import ButtonsGroup from 'components/ui/buttonsGroup';
import RoundButton from 'components/ui/roundButton';
import classNames from 'classnames';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import Input from 'components/ui/input';
import PhoneInput from 'components/ui/phoneInput';
import Checkbox from 'components/ui/checkbox';
import Icon from 'components/ui/icon';
import Grid from 'components/grid';
import CustomSelect from 'components/ui/customSelect';
import SelectionListItem from 'types/SelectionListItem';
import { DictionaryOption, MafField, MafTab } from '../../types';
import { MafHelpers } from '../../helpers';
import SingleFieldSelect from '../singleFieldSelect';
import { mafTabHasTemplate } from '../singleFieldSelect/types';
import MafRadioButtons from '../mafRadioButtons';
import FileUpload from '../fileUpload';
import MafDateInput from '../mafDateInput';
import BreadCrumbs from '../breadCrumbs/BreadCrumbs';
import ComboLookup from '../comboLookup';
import { getDictionary } from 'api/maf';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import {
  INT_MAX,
  INT_MIN,
  NUMBER_MAX_FRACTIONAL_LENGTH,
  NUMBER_MAX_INTEGER_LENGTH,
} from '../../constants';
import { ToggleButton } from 'components/ui/toggleButton';
import { LegalEntity } from 'pages/businessDocuments/components/BusinessDocumentsList/types';
import { MultipleFileUpload } from '../multiFileUpload/MultipleFileUploadContainer';
import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';
import { ConnectedProps, LegacyProps, Props as OwnProps } from './types';
import { BreadCrumbsForPagesMode } from '../breadCrumbs/BreadCrumbsForPagesMode';

type Props = PropsWithChildren<
  OwnProps & ConnectedProps & IntlProps & LegacyProps
>;

type CardStatus = 'success' | 'pending' | 'error';

interface State {
  loadOptions: DictionaryOption[];
  searchSymbols: string;
  dictionaryName: string;
}

const statusTextMap = {
  'pre-filled': 'pre-filled',
  success: 'completed',
  pending: 'awaiting clarification',
  error: 'missing information',
};

const iconsMap = {
  'pre-filled': 'im-Edit1',
  success: 'im-Tick',
  error: 'im-Arrow-right-Option-2',
};

@addListeners([Messages.Dictionary_RequestCrmEnum])
class MafFormLegacy extends Component<PropsWithChildren<Props>, State> {
  salesEmail?: string;

  constructor(props: PropsWithChildren<Props>) {
    super(props);

    this.salesEmail = this.props.legalEntities.find(
      ({ id }: LegalEntity) => id === Number(this.props.legalEntityId)
    )?.sales_email;
  }

  static defaultProps: Readonly<Partial<OwnProps>> = {
    allowMultipleInputsPerRows: true,
    useModernInputs: true,
    ignoreDuplicateHeaders: false,
    onCloseModalTab: () => null,
  };

  state: State = {
    loadOptions: [],
    searchSymbols: '',
    dictionaryName: '',
  };

  private symbolCount = 3;
  private requestDelay = 1000;
  private timeOutId;

  componentDidMount() {
    document.addEventListener(
      'wheel',
      this.disableInputTypeNumberChangingValuesByScrolling
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'wheel',
      this.disableInputTypeNumberChangingValuesByScrolling
    );
  }

  componentDidUpdate(_, prevState: Readonly<State>) {
    if (
      prevState.searchSymbols !== this.state.searchSymbols &&
      this.state.searchSymbols.length >= this.symbolCount
    ) {
      this.timeOutId = setTimeout(async () => {
        await getDictionary(
          this.state.dictionaryName,
          this.state.searchSymbols
        );
      }, this.requestDelay);
    }
  }

  onEvent({ data }) {
    const { payload } = data;

    if (payload?.enum_values) {
      this.setState({
        loadOptions: getCustomSelectItems({
          list: payload.enum_values,
          getValue: (item) => item.value,
          getLabel: (item) => item.title,
        }),
      });
    }
  }

  render() {
    const { data, formData, children } = this.props;

    if (!data) {
      return null;
    }

    return (
      <div className='maf-form-container'>
        <div className='maf-form-container__main'>
          {this.renderForm(formData || this.currentForm)}
        </div>
        {children ? (
          <div className='maf-form-container__buttons'>{children}</div>
        ) : null}
      </div>
    );
  }

  getCardStatus = (card: MafTab, hasError: boolean): CardStatus => {
    const isPending = hasError && MafHelpers.isTabAwaitingClarification(card);

    if (isPending) {
      return 'pending';
    }

    if (hasError) {
      return 'error';
    }

    return 'success';
  };

  renderForm = (form: MafTab | null) => {
    const {
      validationErrors,
      getTranslate,
      onOpenModalTab,
      goToPage,
      fieldState,
    } = this.props;

    if (!form) {
      return null;
    }

    if (
      ['multi_fields_tile', 'display_tab_by_pages'].includes(
        String(form?.item_type)
      ) &&
      !form.__collapsed
    ) {
      if (this.currentForm.behaviour_type === 'readonly_and_links') {
        const activeTabs = form.tabs?.filter((tab) =>
          fieldState.isVisible(form, tab)
        );
        const isOverallError = activeTabs.some(
          ({ name, tabs }) =>
            validationErrors[name] ||
            tabs?.some((tab) => validationErrors[tab.name])
        );
        const isOverallSuccess = activeTabs.every((tab) =>
          MafHelpers.validateSection(tab, validationErrors)
        );

        let overallStatus = 'pre-filled';

        if (isOverallSuccess) {
          overallStatus = 'success';
        }

        if (isOverallError) {
          overallStatus = 'error';
        }

        return (
          <div key={form.name} className='maf-form maf-form__card-view'>
            {this.renderTabControls(form)}

            <div className='maf-form__card-view-header'>
              <div>
                <p className='maf-form__card-view-header_inner'>
                  {getTranslate(form.caption)}
                </p>
                <LabelStatus
                  status={Mapper.getStatus(overallStatus)}
                  text={statusTextMap[overallStatus]}
                />
              </div>
            </div>

            <div className='maf-form__card-view-content'>
              {form.tabs
                ?.filter((tab) => fieldState.isVisible(form, tab))
                .map((tab, index) => {
                  const isSuccess = MafHelpers.validateSection(
                    tab,
                    validationErrors
                  );

                  const isError =
                    validationErrors[tab.name] ||
                    tab.tabs?.some((tab) => validationErrors[tab.name]);

                  let detailStatus = 'draft';

                  if (isSuccess) {
                    detailStatus = 'success';
                  }

                  if (isError) {
                    detailStatus = 'error';
                  }

                  const formLabel = getTranslate(
                    tab.caption || tab.tabs[0].caption
                  );

                  return (
                    <div
                      onClick={() => {
                        onOpenModalTab(form);
                        goToPage(index);
                      }}
                      key={formLabel}
                      className={classNames(
                        'maf-form__card-view-content-detail',
                        `maf-form__card-view-content-detail_${detailStatus}`
                      )}>
                      <span className='maf-form__card-view-content-text'>
                        {getTranslate(formLabel)}
                      </span>
                      <Icon name={iconsMap[detailStatus]} size={12} />
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }

      const hasError = !MafHelpers.validateSection(form, validationErrors);
      const status = this.getCardStatus(form, hasError);
      const cardPreview = this.getCardPreview(form);

      return (
        <div key={form.name} className='maf-form maf-form__card-view'>
          {this.renderTabControls(form)}

          <div className='maf-form__card-view-header'>
            <div>
              <p className='maf-form__card-view-header_inner'>
                {getTranslate(form.caption)}
              </p>

              {status ? (
                <LabelStatus
                  status={Mapper.getStatus(status)}
                  text={statusTextMap[status]}
                />
              ) : null}
            </div>
          </div>

          {cardPreview.length ? (
            <div className='maf-form__card-view-content'>{cardPreview}</div>
          ) : null}
        </div>
      );
    }

    return this.renderFormContent(form);
  };

  //the form is presumed to be invalid at this point
  userMayFill = (form: MafTab) => {
    const { validationErrors, fieldState } = this.props;

    const tabErrors = validationErrors?.[form?.tabs?.[0].name];

    if (!tabErrors) return;

    return Object.keys(tabErrors).find((fieldName) =>
      form?.tabs?.[0]?.fields.some(
        (field) =>
          field.name === fieldName &&
          !fieldState.isDisabled(form.tabs[0], field)
      )
    );
  };

  isFormDeletable = (form: MafTab) =>
    form.tabs?.find((tab) => {
      // hidden delete field for some render conditions
      const deleteField = tab.fields?.find(
        (field) => field.type === 'row_delete_enable'
      );

      return deleteField?.value === 'True';
    });

  renderTabControls = (form: MafTab) => {
    const { goToPage, onOpenModalTab, onDeleteTab } = this.props;
    const isDeletable = this.isFormDeletable(form);

    return !this.isLocked() ? (
      <div className='maf-form__edit-buttons utils-flex'>
        <RoundButton
          size={26}
          iconSize={10}
          onClick={() => {
            onOpenModalTab(form);
            goToPage(0);
          }}
          icon='im-Edit1'
        />
        {isDeletable && (
          <RoundButton
            size={26}
            iconSize={10}
            onClick={() => onDeleteTab(form)}
            icon='im-Basket'
            status='danger'
          />
        )}
      </div>
    ) : null;
  };

  getCardPreview = (form: MafTab) => {
    const { getTranslate } = this.props;

    return form.tabs
      ?.flatMap((tab) =>
        tab.fields?.filter(
          (field) =>
            field.type !== 'checkbox' &&
            field.value &&
            this.props.fieldState.isVisible(form, field)
        )
      )
      .filter((i) => i)
      .slice(0, 4)
      .map((field) => {
        return (
          <div key={field.name} className='maf-form__card-view-item-preview'>
            <div className='maf-form__card-view-item-preview_label'>
              {getTranslate(field.caption)}
            </div>
            <div className='maf-form__card-view-item-preview_value'>
              {MafHelpers.formatCardPreviewValue(field)}
            </div>
          </div>
        );
      });
  };

  renderFields = (form: MafTab) => {
    const fields = this.prepareFields(form);

    return fields.map((field, index) => {
      if (field.groupedFields) {
        const isOnlyTileCheckboxes = !field.groupedFields.some(
          (f) => f.type !== 'tile_checkbox'
        );
        if (isOnlyTileCheckboxes) {
          return (
            <div
              key={field.key + '_' + index}
              className='maf-form__row maf-form__row_tile_checkbox'>
              <ButtonsGroup
                mode='multi'
                theme='dark'
                activeButtons={field.groupedFields
                  .filter((innerField) => innerField.value === 'True')
                  .map((innerField) => innerField.name)}
                onChange={(event) =>
                  event.state?.id &&
                  this.props.onChangeField(form.name, event.state.id, {
                    value: event.state.isSelected ? 'True' : 'False',
                  })
                }>
                {field.groupedFields.map((innerField) => (
                  <ToggleButton
                    key={innerField.name}
                    id={innerField.name}
                    text={this.props.getTranslate(innerField.caption)}
                  />
                ))}
              </ButtonsGroup>
            </div>
          );
        }

        const isOnlyCheckBoxes = !field.groupedFields.some(
          (f) => f.type !== 'checkbox'
        );
        return (
          <div
            key={field.key + '_' + index}
            className='maf-form__row-container'>
            {field.groupedFields.map((innerField, idx) => {
              const element = this.getFormElement(form, innerField);
              return (
                element && (
                  <div
                    key={innerField.name + '_' + idx}
                    className={classNames(
                      'maf-form__row',
                      `grid-column-${innerField.layout.columns || 12}`,
                      {
                        'maf-field__checkbox':
                          !isOnlyCheckBoxes && innerField.type === 'checkbox',
                      }
                    )}>
                    {element}
                  </div>
                )
              );
            })}
          </div>
        );
      }

      const element = this.getFormElement(form, field);
      return (
        element && (
          <div
            key={field.name + '_' + index}
            className={`maf-form__row maf-form__row_${field.type}`}>
            {element}
          </div>
        )
      );
    });
  };

  isFirstTabOnPage = (form: MafTab) =>
    Number(
      this.props
        .getTabByName(form.__parentFormId)
        ?.tabs?.findIndex((tab) => tab.name === form.name)
    ) === 0;

  renderFormContent = (form: MafTab) => {
    const {
      validationErrors,
      dictionaries,
      onDeleteAllTabs,
      onCreateFormByTemplate,
      currentFormName,
      onDeleteForm,
      withBreadCrumbs,
      onCloseModalTab,
      getTranslate,
      fieldState,
      isDictionaryFetching,
    } = this.props;

    const needBreadCrumbs = withBreadCrumbs && this.isFirstTabOnPage(form);

    const tabError =
      this.isCurrentFormValidated && validationErrors[form.name]?.tab_error;
    const withTabError = !!tabError;
    const isSingleField =
      mafTabHasTemplate(form) &&
      (form.tab_template?.item_type === 'single_field' ||
        form.tab_template?.item_type === 'single_field_tiles');
    const isInlineEditContainer =
      !isSingleField &&
      !(form.is_modal_mode || form.tab_template?.is_modal_mode) &&
      form.tab_template?.item_type === 'inline_edit';
    const isInlineEditElement =
      !isSingleField &&
      !(form.is_modal_mode || form.tab_template?.is_modal_mode) &&
      form.item_type === 'inline_edit';

    const isPending = form.tabs?.some((tab) => {
      return (
        !MafHelpers.validateSection(tab, validationErrors) &&
        MafHelpers.isTabAwaitingClarification(tab)
      );
    });

    const bvdTabs = this.getBvdTabs(form);
    const commonTabs = this.getCommonTabs(form, {
      needsSort: Boolean(form.tab_template),
    });

    const formTitle = this.renderFormTitle(
      form,
      withBreadCrumbs || withTabError
    );

    const siblingsTabsCount = Number(
      this.props.getTabByName(form.__parentFormId)?.tabs?.length
    );

    const email = this.salesEmail || this.props.managerEmail;
    const notes = getTranslate(form.notes, {
      email: `<a href="mailto:${email}">${email}</a>`,
      user_name: this.props.userName,
    });

    if (form.behaviour_type === 'filebin') {
      return (
        <MultipleFileUpload
          key={form.name}
          error={tabError}
          form={form}
          mafId={this.props.data?.crm_id}
          legalEntityId={this.props.legalEntityId}
          disabled={this.isLocked()}
          onAddFileField={({ name, tab_template }) =>
            tab_template ? onCreateFormByTemplate(name, tab_template) : null
          }
          onRemoveFileField={this.props.onDeleteTab}
          setFormAsFailed={this.props.forseMarkStepAsFailed}
          fileTypes={this.props.fileTypes}
          onChangeFileName={({
            objectName,
            date,
            fileName,
            formName,
            field,
          }) => {
            this.props.onChangeField(
              formName,
              this.fileKey(field),
              {
                value: objectName,
                file_date: date,
                lookup_display_value: fileName,
              },
              'file'
            );
          }}
          onChangeFileSize={({ fileSize, formName, field }) => {
            this.props.onChangeField(
              formName,
              field.name,
              {
                value: fileSize,
              },
              'filesize'
            );
          }}
        />
      );
    }

    return (
      <div
        key={form.name}
        className={classNames('maf-form', 'maf-form_level-0', {
          'maf-form__inline-edit': isInlineEditContainer,
        })}>
        {form.__collapsed || (
          <div className='maf-form__header'>
            {formTitle}
            {isPending && this.renderAwaitingClarificationInfo()}
            {needBreadCrumbs && !this.props.isPageTabMode && (
              <BreadCrumbs
                onCloseModalTab={onCloseModalTab}
                getTabByName={this.props.getTabByName}
                currentFormName={getTranslate(currentFormName)!}
                form={form}
              />
            )}
            {withBreadCrumbs && this.props.isPageTabMode && (
              <BreadCrumbsForPagesMode form={form} />
            )}
            {form.tab_template?.notes && (
              <InfoText
                text={getTranslate(form.tab_template?.notes)}
                type='shallow'
              />
            )}
            {withTabError && (
              <InfoText text={getTranslate(tabError)} type='error' />
            )}
            {form.notes && (
              <InfoText
                text={
                  notes.includes('|')
                    ? I18nHelpers.getJsxFromHtml(notes)
                    : notes
                }
                type='shallow'
              />
            )}
            {!(isSingleField || isInlineEditContainer) &&
              form.behaviour_type !== 'readonly_and_links' &&
              this.renderButtonCreateNewForm(form)}
          </div>
        )}
        {!(form.is_modal_mode || isInlineEditElement) &&
          this.renderFormRemoveControl(form)}
        {this.renderFields(form)}
        {!isSingleField && (
          <>
            {!!bvdTabs?.length && (
              <div className='maf-form__bvd-tabs-wrapper'>
                {bvdTabs.map(this.renderBvdCard)}
              </div>
            )}
            {!!commonTabs?.length && (
              <ConditionalWrapper
                condition={isInlineEditElement}
                wrap={(children) => (
                  <div className='maf-form__inline-edit-wrapper'>
                    {children}
                    {siblingsTabsCount > 1 &&
                      !this.isLocked() &&
                      this.renderInlineDelete(form)}
                  </div>
                )}>
                <div className='maf-form__bvd-tabs-wrapper'>
                  {commonTabs.map((tab) => this.renderForm(tab))}
                </div>

                {form.behaviour_type === 'readonly_and_links' &&
                  this.renderButtonCreateNewForm(form)}
              </ConditionalWrapper>
            )}
            {isInlineEditContainer && this.renderInlineEditCreateNewTab(form)}
          </>
        )}
        {isSingleField && mafTabHasTemplate(form) && (
          <SingleFieldSelect
            form={form}
            fieldState={fieldState}
            isLocked={this.isLocked()}
            loading={isDictionaryFetching}
            dictionaries={dictionaries}
            onDeleteAllTabs={onDeleteAllTabs}
            onCreateFormByTemplate={onCreateFormByTemplate}
            onDeleteForm={onDeleteForm}
          />
        )}
      </div>
    );
  };

  renderBvdCard = (tab: MafTab) => {
    const caption = tab.caption.trim();
    return (
      <div
        key={tab.name}
        className={classNames(
          'maf-form maf-form__card-view',
          'maf-form__card-view_bvd'
        )}>
        <div className={classNames('maf-form__card-view-header')}>
          <span className='maf-form__card-view-header_inner'>
            <Icon size={17} name='im-Avatar' />
            {caption}
          </span>
          <Button
            onClick={() => this.props.onOpenModalTab(tab)}
            customClass='maf-form__bvd-card-select-button'
            text='SELECT'
          />
        </div>
      </div>
    );
  };

  renderAwaitingClarificationInfo = () => {
    return (
      <InfoText
        text={
          <div className='maf-form__awaiting-clarification'>
            <div className='maf-form__awaiting-clarification-icon'>
              <Icon size={24} name='im-Refill' />
            </div>
            <div className='maf-form__awaiting-clarification-title'>
              Awaiting
            </div>
            <div>
              Authorised Signatory Verification in the process. This might take
              a while.
            </div>
          </div>
        }
      />
    );
  };

  getBvdTabs = (form: MafTab) => {
    return form.tabs?.filter((tab) => MafHelpers.isBVDTab(tab));
  };

  getCommonTabs = (form: MafTab, options?: { needsSort?: boolean }) => {
    const commonTabs =
      form.tabs?.filter((tab) => !MafHelpers.isBVDTab(tab)) || [];

    const visibleTabs = commonTabs?.filter((tab) =>
      this.props.fieldState.isVisible(form, tab)
    );

    return options?.needsSort ? this.sortCommonTabs(visibleTabs) : visibleTabs;
  };

  sortCommonTabs = (commonTabs: MafTab[]) => {
    const { validationErrors } = this.props;
    return commonTabs
      .sort((tabA, tabB) => {
        //awaiting are next
        const isAAwaiting = MafHelpers.isTabAwaitingClarification(tabA);
        const isBAwaiting = MafHelpers.isTabAwaitingClarification(tabB);
        return +isAAwaiting - +isBAwaiting;
      })
      .sort((tabA, tabB) => {
        //invalid are second
        const isAInvalid = MafHelpers.validateSection(tabA, validationErrors);
        const isBInvalid = MafHelpers.validateSection(tabB, validationErrors);
        return +isAInvalid - +isBInvalid;
      });
  };

  renderFormRemoveControl = (form: MafTab) => {
    const { onDeleteForm } = this.props;

    if (!this.isFormDeletable(form)) {
      return null;
    }

    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
          onDeleteForm(form.name);
        }}
        className='maf-form__delete'>
        <Button
          icon='im-Basket'
          iconSize={20}
          customClass='ui-button_no-border'
        />
      </div>
    );
  };

  renderInlineDelete = (form: MafTab) => {
    return (
      <div className='maf-form__inline-edit-delete utils-flex'>
        {this.isFormDeletable(form) && (
          <RoundButton
            size={26}
            status='danger'
            icon='im-Basket'
            iconSize={11}
            onClick={(e) => {
              e.stopPropagation();
              this.props.onDeleteTab?.(form);
            }}
          />
        )}
      </div>
    );
  };

  renderFormTitle = (form: MafTab, lowMargin: boolean) => {
    const { getTranslate } = this.props;

    if (
      !form.caption ||
      (this.props.ignoreDuplicateHeaders &&
        form.fields?.[0].type === 'heading_label')
    )
      return null;
    return (
      <div
        className={classNames('maf-form__title', {
          'maf-form__title_low-margin': lowMargin,
        })}>
        {getTranslate(form.caption)}
      </div>
    );
  };

  getTemplateCaption = (tab_template: MafTab) => {
    const { getTranslate } = this.props;

    if (tab_template?.caption) {
      return getTranslate(tab_template.caption);
    }

    if (tab_template?.tabs?.[0]) {
      if (tab_template.tabs[0].caption) {
        return getTranslate(tab_template.tabs[0].caption);
      }

      const fields = this.prepareFields(tab_template.tabs[0]);
      if (fields[0]?.caption) {
        return getTranslate(fields[0].caption);
      }

      if (fields[0]?.groupedFields?.[0]?.caption) {
        return getTranslate(fields[0].groupedFields[0].caption);
      }
    }

    return '';
  };

  renderButtonCreateNewForm = (form: MafTab) => {
    const {
      onCreateFormByTemplate,
      onOpenModalTab,
      getTranslate,
      goToPage,
      fieldState,
    } = this.props;
    const { name, tab_template, behaviour_type } = form;

    if (
      !tab_template?.tabs?.length ||
      this.isLocked() ||
      behaviour_type === 'read-only'
    )
      return null;

    return (
      <Button
        status='outline'
        id='createNewForm'
        text={`${getTranslate(
          'onboarding.add.button'
        )} ${this.getTemplateCaption(tab_template).toLowerCase()}`}
        customClass='maf-form__create-new-form-button'
        disabled={
          this.isLocked() || fieldState.isFieldsDisabledByParentForm(form)
        }
        onClick={() => {
          if (tab_template.is_modal_mode) {
            const newForm = onCreateFormByTemplate(name, tab_template);

            if (newForm) {
              newForm.item_type === 'display_tab_by_pages' &&
                MafHelpers.groupTabs(newForm);
              onOpenModalTab(newForm);
              goToPage(0);
            }
          } else {
            onCreateFormByTemplate(name, tab_template);
          }
        }}
      />
    );
  };

  renderInlineEditCreateNewTab = (form: MafTab) => {
    const { onCreateFormByTemplate } = this.props;
    const { name, tab_template, behaviour_type } = form;

    if (!tab_template?.tabs?.length || this.isLocked()) return null;

    return (
      <RoundButton
        id='createNewForm'
        size={26}
        icon='im-Plus'
        iconSize={11}
        iconPosition='left'
        text={`${this.props.getTranslate(
          'getStarted.PAF.addmore.label'
        )} ${this.getTemplateCaption(tab_template).toLowerCase()}`}
        customClass='maf-form__inline-edit-add-button'
        onClick={() => onCreateFormByTemplate(name, tab_template)}
        disabled={behaviour_type === 'read-only'}
      />
    );
  };

  isLocked = () => {
    const { data } = this.props;
    return Boolean(data?.locked);
  };

  prepareFields = (form: MafTab) => {
    const { fieldState } = this.props;
    const { fields } = form;

    const sortedFields = (fields || [])
      .filter((field) => fieldState.isVisible(form, field))
      .sort((a, b) => a.order - b.order);

    if (this.props.allowMultipleInputsPerRows) {
      const groupedFieldKeys: { key: string; order: number }[] = [];

      return sortedFields
        .map(this.groupFieldsByLayout(groupedFieldKeys, fields))
        .filter((field) => field);
    }

    return sortedFields;
  };

  groupFieldsByLayout = (groupedFieldKeys, fields) => (field) => {
    if (field.layout) {
      const rowKey = field.layout.row;
      const alreadyInGroup = groupedFieldKeys.find(({ key }) => key === rowKey);

      if (alreadyInGroup) return undefined;

      const { order } = field;
      groupedFieldKeys.push({ key: rowKey, order });

      return {
        groupedFields: fields
          .filter((f) => f.layout?.row === rowKey)
          .sort((a, b) =>
            a.layout.order && b.layout.order
              ? a.layout.order - b.layout.order
              : 1
          ),
        key: rowKey,
        order,
      };
    }
    return field;
  };

  getLoadedValue = (field: MafField): DictionaryOption => {
    return {
      value: field.value,
      label: field.lookup_display_value!,
    };
  };

  getFormElement = (form: MafTab, field: MafField) => {
    const {
      onChangeField,
      validationErrors,
      getTranslate,
      dictionaries,
      isDictionaryFetching,
      fieldState,
      useModernInputs,
      onBlur,
    } = this.props;

    const id = field.name;

    if (!fieldState.isVisible(form, field)) {
      return null;
    }

    if (field.enabled === false) {
      return null;
    }

    const isLoading = Boolean(
      field.lookup && !field.enum_values && isDictionaryFetching
    );
    const isRequired = fieldState.isRequired(form, field);
    const isDisabled =
      this.isLocked() ||
      fieldState.isDisabled(form, field) ||
      fieldState.isFieldsDisabledByParentForm(form);
    const formError = !isDisabled
      ? this.isCurrentFormValidated && validationErrors[form.name]
      : false;

    const error = formError ? getTranslate(formError[field.name]) : '';

    const isOptionsLoadable = Boolean(
      field?.lookup?.startsWith('crm_dictionary_service.')
    );

    const getDictionaryName = (formField: MafField): string => {
      if (isOptionsLoadable) {
        const [, dictionaryName] = formField.lookup!.split('.');
        return dictionaryName;
      }

      return '';
    };

    const dictionaryName = getDictionaryName(field);

    const onInputChange = (newValue: string) => {
      clearTimeout(this.timeOutId);

      this.setState({
        searchSymbols: newValue,
        dictionaryName,
      });
    };

    switch (field.type) {
      case 'string':
        return (
          <Input
            autoComplete='off'
            id={id}
            error={error}
            label={field.caption}
            value={getTranslate(field.value)}
            modern={useModernInputs}
            tooltip={getTranslate(field.notes)}
            placeholder={getTranslate('getStarted.PAF.value.placeholder')}
            customClass='maf-field'
            disabled={isDisabled}
            isRequired={isRequired}
            onChange={({ target }) =>
              this.changeInput(form, field, target.value)
            }
          />
        );
      case 'phone':
        return (
          <PhoneInput
            autoComplete='off'
            id={id}
            error={error}
            label={field.caption}
            value={field.value}
            tooltip={field.notes}
            placeholder={getTranslate(
              'filters.fields.customerPhone.placeholder'
            )}
            customClass='maf-field__phone-input'
            disabled={isDisabled}
            modern={useModernInputs}
            isRequired={isRequired}
            selectedCountryCode={this.getCountryCode(field.value)}
            isSingleField={true}
            onChange={(_, { dialCountry, value }) => {
              this.changeInput(
                form,
                field,
                (dialCountry.code ? `(${dialCountry.code}) ` : '') +
                  value.replace(/ /gi, '')
              );
            }}
          />
        );
      case 'int':
        return (
          <Input
            autoComplete='off'
            id={id}
            type='text'
            label={field.caption}
            value={field.value}
            error={error}
            modern={useModernInputs}
            tooltip={field.notes}
            placeholder={getTranslate('getStarted.PAF.value.placeholder')}
            customClass='maf-field'
            disabled={isDisabled}
            isRequired={isRequired}
            onChange={({ target }) => {
              // accept only numbers
              target.value = target.value.replace(/[^0-9]+/g, '');

              // CRM "int" in range from -2^31 to 2^31
              if (
                Number(target.value) > INT_MAX ||
                Number(target.value) < INT_MIN
              ) {
                // if value is out of range, block input to add characters
                target.value = target.value.slice(0, -1);
              }

              this.changeInput(form, field, target.value);
            }}
          />
        );
      case 'number':
        return (
          <Input
            autoComplete='off'
            id={id}
            type='number'
            label={field.caption}
            value={field.value && Number(field.value)}
            error={error}
            modern={useModernInputs}
            tooltip={field.notes}
            placeholder={getTranslate('getStarted.PAF.value.placeholder')}
            customClass='maf-field'
            disabled={isDisabled}
            isRequired={isRequired}
            onChange={({ target }) => {
              const [integerPart, fractionalPart] = target.value.split('.');

              // accept only few digits before dot
              if (integerPart.length > NUMBER_MAX_INTEGER_LENGTH) {
                target.value =
                  integerPart.slice(0, -1) +
                  (fractionalPart ? `.${fractionalPart}` : '');
              }

              // accept only two digits after dot
              if (
                fractionalPart &&
                fractionalPart.length > NUMBER_MAX_FRACTIONAL_LENGTH
              ) {
                target.value = target.value.slice(0, -1);
              }

              this.changeInput(form, field, target.value);
            }}
          />
        );
      case 'checkbox': {
        const isChecked = field.value === 'True';
        return (
          <div>
            <Grid align='center' spacing='xxs' noGap>
              <Checkbox
                id={id}
                isRequired={isRequired}
                text={getTranslate(field.caption)}
                disabled={isDisabled}
                checked={isChecked}
                onChange={({ target }) =>
                  onChangeField(form.name, field.name, {
                    value: target.checked ? 'True' : 'False',
                  })
                }
              />
              {field.notes && (
                <div className='maf-field__checkbox-info'>
                  <Icon
                    tooltip={getTranslate(field.notes)}
                    name='im-Info-Hint'
                    size={20}
                  />
                </div>
              )}
            </Grid>
            {error && <div className='maf-field__checkbox_error'>{error}</div>}
          </div>
        );
      }
      case 'tile_checkbox': {
        const isChecked = field.value === 'True';
        return (
          <div>
            <Grid align='center' spacing='xxs' noGap>
              <ButtonsGroup
                mode={isRequired ? 'multiRequired' : 'multi'}
                theme='dark'
                separate
                disabled={isDisabled}
                activeButtons={isChecked ? [field.name] : []}
                onChange={(event) =>
                  onChangeField(form.name, field.name, {
                    value: event.state?.isSelected ? 'True' : 'False',
                  })
                }>
                <Button id={field.name} text={field.caption} />
              </ButtonsGroup>
              {field.notes && (
                <div className='maf-field__checkbox-info'>
                  <Icon tooltip={field.notes} name='im-Info-Hint' size={20} />
                </div>
              )}
            </Grid>
            {error && <div className='maf-field__checkbox_error'>{error}</div>}
          </div>
        );
      }
      case 'radiobutton': {
        return (
          <MafRadioButtons
            dictionaries={dictionaries}
            field={field}
            isLoading={isLoading}
            isRequired={isRequired}
            error={error}
            isDisabled={isDisabled}
            onChangeField={onChangeField}
            form={form}
          />
        );
      }
      case 'file': {
        const fileKey = this.fileKey(field);
        return (
          <FileUpload
            fileTypes={this.props.fileTypes}
            field={field}
            formName={form.name}
            mafId={this.props.data?.crm_id}
            legalEntityId={this.props.legalEntityId}
            required={isRequired}
            tooltip={field.notes}
            disabled={isDisabled}
            error={error}
            onChange={({ objectName, date, fileName }) => {
              onChangeField(
                form.name,
                fileKey,
                {
                  value: objectName,
                  file_date: date,
                  lookup_display_value: fileName,
                },
                'file'
              );
            }}
          />
        );
      }
      case 'lookup': {
        const options = isOptionsLoadable
          ? this.state.loadOptions
          : this.getItems(form, field);
        const value = options.find((option) => {
          if (field.lookup_display_value) {
            return (
              option.value === field.value ||
              option.label.toLowerCase() ===
                field.lookup_display_value.toLowerCase() // todo: remove when crm fixes uppercase issue
            );
          }
          return option.value === field.value;
        });

        const loadedValue = isOptionsLoadable
          ? this.getLoadedValue(field)
          : null;

        const noOptionsMessage = isOptionsLoadable
          ? 'businessDocs.DAF.InterBankBICList.placeholder'
          : 'common.selectSingle.noOptions';

        return (
          <CustomSelect
            isLoading={isLoading}
            isRequired={isRequired}
            error={error}
            modern={useModernInputs}
            isSearchable
            isDisabled={isDisabled}
            tooltip={field.notes}
            options={options}
            label={field.caption}
            autoScrollToSelectedOption
            value={loadedValue || value || null}
            customClass='maf-field'
            onChange={(val) => val && this.handleSelectChange(form, field, val)}
            onInputChange={isOptionsLoadable ? onInputChange : undefined}
            onBlur={() => onBlur(field)}
            noOptionsMessage={noOptionsMessage}
          />
        );
      }
      case 'date': {
        return (
          <MafDateInput
            field={field}
            error={error}
            form={form}
            modern={useModernInputs}
            onChangeField={onChangeField}
            isRequired={isRequired}
            isDisabled={isDisabled}
          />
        );
      }
      case 'heading_label': {
        return (
          <Grid
            spacing='xxs'
            align='center'
            className='maf-field__heading-label'>
            <span>{getTranslate(field.caption)}</span>
            {field.notes && (
              <div className='maf-field__heading-label-info'>
                <Icon
                  tooltip={getTranslate(field.notes)}
                  name='im-Info-Hint'
                  size={20}
                />
              </div>
            )}
          </Grid>
        );
      }
      case 'warning': {
        return <div className='maf-field__warning'>{field.caption}</div>;
      }
      case 'combo_lookup': {
        const dictionary = dictionaries?.[field.lookup || ''];

        let options: { label: string; value: string }[] = [];

        if (dictionary) {
          options = this.applyFilterRule(form, field, dictionary)
            .map((item) => ({
              value: item.object_id,
              label: item[field.lookup_display_field_name || 'name'],
            }))
            .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label));
        }

        let value =
          field.value &&
          options.find((option) => option.value === field.value)?.label;

        if (!value && field.lookup_display_value) {
          value = field.lookup_display_value || '';
        }

        return (
          <ComboLookup
            id={id}
            onInputChange={(e) => {
              onChangeField(form.name, field.name, {
                value: '',
                lookup_display_value: e.target.value,
              });
            }}
            modern={useModernInputs}
            isRequired={isRequired}
            error={error}
            isDisabled={isDisabled}
            tooltip={field.notes}
            options={options}
            label={field.caption}
            value={value}
            onSelect={({ value: newValue }) => {
              onChangeField(form.name, field.name, {
                value: newValue,
                lookup_display_value: '',
              });
            }}
            onBlur={() => onBlur(field)}
          />
        );
      }
      case 'details': {
        const email = this.salesEmail || this.props.managerEmail;
        const value = getTranslate(field.value || field.default_value, {
          email: `<a href="mailto:${email}">${email}</a>`,
          user_name: this.props.userName,
        });
        return (
          <InfoText
            isDisabledIcon={true}
            text={
              <>
                {field.caption && (
                  <div className='maf-field__details-title'>
                    {getTranslate(field.caption)}
                  </div>
                )}
                <div>
                  {value.includes('|')
                    ? I18nHelpers.getJsxFromHtml(value)
                    : value}
                </div>
              </>
            }
          />
        );
      }
      default:
        return null;
    }
  };

  fileKey = (field: MafField) => {
    return `${field.doc_type_id}${field.attach_type_id}`;
  };

  changeInput = (form: MafTab, field: MafField, newValue: string) => {
    const { onChangeField } = this.props;
    onChangeField(form.name, field.name, { value: newValue });
  };

  applyFilterRule = (form: MafTab, field: MafField, list) => {
    const { getTabByName } = this.props;
    if (field.filter_rule) {
      const { dict_field_name, field: compareField } = field.filter_rule;
      const targetTab = getTabByName(form.name) || form;
      const targetField = targetTab?.fields.find(
        (f) => f.name === compareField.name
      );
      if (targetField) {
        return list.filter((item) => {
          return targetField.value === item[dict_field_name];
        });
      }
    }
    return list;
  };

  getItems = (
    form: MafTab,
    field: MafField
  ): { value: string; label: string }[] => {
    const { dictionaries } = this.props;
    if (field.enum_values) {
      return this.sortBy(field.enum_values, field.lookup_order_field).map(
        (item) => ({
          value: item.value,
          label: item.title,
        })
      );
    }
    if (dictionaries && field.lookup) {
      const dictionary = dictionaries[field.lookup];
      if (!dictionary) return [];

      const list = this.applyFilterRule(form, field, dictionary);
      const sortedItems = this.sortSelectItems(list, field.lookup_order_field);
      return sortedItems.map((item) => ({
        value: item.object_id,
        label: item[field.lookup_display_field_name || 'name'],
      }));
    }
    return [];
  };

  sortBy = (items: { value: string; title: string }[], field?: string) => {
    if (field) {
      return items.sort((a, b) => a[field] - b[field]);
    }
    return items;
  };

  sortSelectItems = (items, sortField?: string) => {
    return items.sort((a, b) => {
      if (sortField) {
        return a[sortField] - b[sortField];
      }

      if (a.position && b.position) {
        return a.position - b.position;
      }

      if (a.object_id && b.object_id) {
        return a.object_id - b.object_id; // otherwise order get messed up after choosing an option - DAS-6593 (FF bug)
      }

      return 1;
    });
  };

  handleSelectChange = (form: MafTab, field: MafField, { value, label }) => {
    this.changeSelectionItem(form, field, [
      { id: value, text: label, isSelected: true },
    ]);
  };

  changeSelectionItem = (
    form: MafTab,
    field: MafField,
    newItems: SelectionListItem[]
  ) => {
    const { onChangeField } = this.props;
    const selectedItem = newItems.find(({ isSelected }) => isSelected);
    if (selectedItem) {
      onChangeField(form.name, field.name, {
        value: selectedItem.id,
        lookup_display_value: selectedItem.text,
      });
    }
  };

  getCountryCode = (value) => {
    if (!value) return '';
    const start = value.indexOf('(');
    const end = value.indexOf(')');
    if (start > -1 && end > -1) {
      return value.slice(start + 1, end);
    }
    return '';
  };

  get currentForm() {
    const { currentStep, currentSteps } = this.props;
    return currentSteps[currentStep];
  }

  get isCurrentFormValidated() {
    const currentForm = this.currentForm;

    return currentForm?.__tabGroup && currentForm.tabs?.length > 0
      ? currentForm.tabs.every((tab) => this.props.validatedTabs[tab.name])
      : this.props.validatedTabs[currentForm?.name];
  }

  disableInputTypeNumberChangingValuesByScrolling = () => {
    if ((document.activeElement as HTMLInputElement)?.type === 'number') {
      (document.activeElement as HTMLInputElement)?.blur();
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  isDictionaryFetching: state.maf.isDictionaryFetching,
  dictionaries: state.maf.dictionaries,
  validatedTabs: state.maf.validatedTabs,
  legalEntities: state.user?.activeLegalEntities,
  managerEmail: state.user?.boarding?.email,
  userName: state.user?.name,
});

export default connect(mapStateToProps)(addTranslation(MafFormLegacy));
