import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { initAllConfiguration } from 'actions/configuration';
import { resetConcreteQuickFilters } from 'actions/quickFilters';
import {
  deleteUser,
  disableUser,
  enableUser,
  getActivities,
  getUsers,
} from 'api/team';
import api from 'api/dictionaries';
import path from 'helpers/path';
import TeamPage from './TeamPage';
import getConfigurationByName from 'selectors/getConfigurationByName';
import tableNames from 'constants/tableNames';
import { RootState, StoreProps } from 'store';

interface ConnectedProps {
  state: RootState;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  tableName: tableNames;
  isLoading: boolean;
  canRenderTable: boolean;
}

class TeamContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableName: tableNames.teamUsers,
      canRenderTable: true,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const list = await api.getTableColumns();
    dispatch(initAllConfiguration(list));
  }

  render() {
    const { tableName, canRenderTable, isLoading } = this.state;
    const { state } = this.props;
    const apiRequest =
      tableName === tableNames.teamUsers ? getUsers : getActivities;

    if (!state.configuration) return null;

    return (
      <TeamPage
        tableName={tableName}
        canRenderTable={canRenderTable}
        isLoading={isLoading}
        apiRequest={apiRequest}
        configuration={getConfigurationByName(state, tableName)}
        onToggleActivate={(data) => this.toggleActivate(data)}
        onEditUser={(id) => this.editUser(id)}
        onDeleteUser={(id) => this.deleteUser(id)}
        onChangeCurrentTable={this.changeCurrentTable}
      />
    );
  }

  changeCurrentTable = (tableName) => {
    const { state, dispatch, history, location } = this.props;
    this.setState({ tableName });
    dispatch(
      resetConcreteQuickFilters({
        name: 'myTeam',
      })
    );
    if (location.pathname !== '/team') {
      history.push(path('/team'));
    }
    const data = state.data[tableName];
    if (data && !data.isFetch) {
      this.setState(
        {
          canRenderTable: false,
        },
        () => {
          this.setState({
            canRenderTable: true,
          });
        }
      );
    }
  };

  async toggleActivate(data) {
    try {
      data.userStatus === 'Active'
        ? await disableUser(data.id)
        : await enableUser(data.id);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteUser(id: number) {
    this.setState({ isLoading: true });
    try {
      await deleteUser(id);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async editUser(id: number) {
    const { history } = this.props;
    history.push(path(`/team/edit/${id}`));
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  state,
});

export default connect(mapStateToProps)(addTranslation(TeamContainer));
