import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { pickBy } from 'lodash-es';

import { RpcRequestMessage, RpcResponseMessage } from 'types/Rpc';
import showNotification from 'components/ui/notification/showNotification';
import path from './path';
import Utils from './Utils';
import Repository from './Repository';
import LocalStorage from './LocalStorage';
import getMessageError from './getMessageError';
import { httpStatuses } from 'constants/httpCodes';
import getMessageHandler from 'messages';
import RpcTypes from 'constants/rpcTypes';
import getLocalizedText from './getLocalizedText';
import { AnyObject } from 'types/Common';

interface RequestParams {
  messages: RpcRequestMessage[];
  headers?: {};
  showValidateNotification?: boolean;
  params?: {};
  query?: {};
  isCancelable?: boolean;
  mapTo?: string;
}

type RpcResponseMessages = RpcResponseMessage[];

class RpcService {
  private messages: RpcResponseMessages;
  private mappedData: AnyObject;

  constructor() {
    this.messages = [];
    this.mappedData = {};
  }

  public request = <Payload = any>({
    messages,
    headers,
    showValidateNotification,
    params,
    query,
    isCancelable,
    mapTo,
  }: RequestParams): Promise<Payload> => {
    return new Promise((resolve, reject) => {
      let url = `rpc?type=${messages
        .map((message) => message.type)
        .toString()}`;
      if (query) {
        url += `&${queryString.stringify(query)}`;
      }

      const requestMessages = messages.map((message) => {
        let fullMessage = message;
        if (!message.id) {
          fullMessage = {
            ...fullMessage,
            id: Utils.getHash(),
          };
        }

        if (mapTo) {
          this.mappedData = {
            ...this.mappedData,
            [`${fullMessage.id}`]: message.payload?.[mapTo],
          };
        }

        return fullMessage;
      });

      const data = pickBy({
        messages: requestMessages,
        ...this.prepareParams(messages[0], params),
        isCancelable,
      });

      axios({
        url,
        headers,
        method: 'POST',
        data,
      })
        .then((response: AxiosResponse) => {
          const responseMessages = response.data.messages;

          this.setMessages(responseMessages);

          const requestList: RpcResponseMessages = [];
          this.messages.forEach((message) => {
            if (messages.find(({ type }) => message.type === type)) {
              requestList.push(message);

              if (mapTo && message.id) {
                this.mappedData[message.id] = {
                  name: this.mappedData[message.id],
                  payload: message.payload,
                };
              }
            }
          });

          this.executeList(
            mapTo ? Object.values(this.mappedData) : responseMessages,
            response
          );

          const messageErrors = this.getErrors(requestList);
          messageErrors.forEach((messageError) => {
            if (mapTo && messageError.id) {
              messageError[mapTo] = this.mappedData[messageError.id].name;
            }
            this.executeError(messageError, showValidateNotification);
          });

          const formattedRequestList =
            requestList.length === 1
              ? requestList[0].payload
              : requestList.map((item) => {
                  return {
                    ...item.payload,
                    id: item.id,
                  };
                });

          if (requestList.some((message) => message.status === 'success')) {
            resolve(formattedRequestList);
          } else if (messageErrors.length) {
            reject(
              messageErrors.length === 1 ? messageErrors[0] : messageErrors
            );
          } else {
            resolve(formattedRequestList);
          }
        })
        .catch((error) => {
          const { response } = error;
          if (response) {
            reject({
              status: response.status,
              error: response.data.error,
              payload: response.data.payload,
            });
          } else {
            console.error('Error:', error);
            reject(error);
          }
        });
    });
  };

  public getById = (id) => {
    return this.messages.find((message) => message.id === id);
  };

  public filterByType = (type): RpcResponseMessages => {
    return this.messages.filter((message) => message.type === type);
  };

  public getByType = (type): RpcResponseMessage | undefined => {
    return this.messages.find((message) => message.type === type);
  };

  public setMessages = (messages) => {
    this.messages = messages;
  };

  public getMessages(): RpcResponseMessages {
    return this.messages;
  }

  private prepareParams(message: RpcRequestMessage, params = {}) {
    const paramsResult: any = { ...params };
    const type: any = message.type;
    if (
      [
        RpcTypes.Auth_Login,
        RpcTypes.Auth_ConfirmSecondFactor,
        RpcTypes.Auth_ConfirmSecondFactorState,
        RpcTypes.Auth_PasswordChange,
        RpcTypes.Auth_PasswordReset,
        RpcTypes.Auth_PasswordResetComplete,
        RpcTypes.GET_WL,
        RpcTypes.Localize_Dictionaries,
      ].includes(type)
    ) {
      paramsResult.language = LocalStorage.get('noAuthLang');
    }
    return paramsResult;
  }

  private getErrors = (messages: RpcResponseMessages): RpcResponseMessages => {
    return messages.filter(
      (message) => message.status && message.status !== 'success'
    );
  };

  private executeList = (messages, response) => {
    try {
      for (const message of messages) {
        if (message.status === 'error') {
          continue;
        }

        const handler = getMessageHandler({
          dispatch: Repository.get('store').dispatch,
          history: Repository.get('history'),
          message,
          response,
        });

        handler && handler();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  private executeError(message, showValidateNotification = true) {
    if (!message.error) {
      console.error('object error is not exist!');
    }

    if (message.isExecute) {
      return;
    }

    const history = Repository.get('history');
    const { status } = message.error;
    switch (status) {
      case httpStatuses.validateError:
        if (showValidateNotification) {
          showNotification({
            status: 'error',
            content: getMessageError(message),
          });
        }
        message.isExecute = true;
        break;
      case httpStatuses.unauthorized:
        if (history.location.pathname === '/login') {
          history.push(path('/login'), {
            ...history.location.state,
            reset: true,
          });
        } else {
          const pathname = history.location.search
            ? history.location.pathname + history.location.search
            : history.location.pathname;
          history.push(path('/login'), {
            from: pathname,
            reset: true,
          });
        }
        message.isExecute = true;
        break;
      case httpStatuses.accessDenied:
        if (message.error.code === 1) {
          showNotification({
            status: 'error',
            content: getLocalizedText('common.notAvaliableAction.sysmsg'),
          });
        }
        message.isExecute = true;
        break;
      case httpStatuses.accessDenied2:
        history.push(path('/login'), { reset: true });
        message.isExecute = true;
        break;
      case httpStatuses.serverError: {
        const backendNotification = this.getByType('Notification');
        if (backendNotification?.payload.level === 'error') break;
        // Если получили 500 и бек не прислал об этом Notification
        showNotification({
          status: 'error',
          content: 'Server error, please try again later',
        });
        message.isExecute = true;
        break;
      }
    }
  }
}

export default RpcService;
