import React, { PureComponent, Fragment } from 'react';
import { map } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import isDateFilter from 'helpers/isDateFilter';
import './selectedFilters.scss';

interface Props extends IntlProps {
  filters: any;
  onResetFilter: (filterId: string) => void;
}

class SelectedFilters extends PureComponent<Props> {
  render() {
    const { filters } = this.props;

    return (
      <div className='selected-filters'>
        <div className='selected-filters__list'>
          {map(filters, (value, id) => this.renderGroup(id, value))}
        </div>
      </div>
    );
  }

  renderGroup(filterId, value) {
    const { onResetFilter, getTranslate } = this.props;

    if (!SelectedFilters.canRender(filterId, value)) {
      return null;
    }

    return (
      <div
        key={filterId}
        className={`selected-filters__group selected-filters__group_${filterId}`}>
        <span className='selected-filters__group-title'>
          {getTranslate(`filters.types.${filterId}`)}
        </span>
        {': '}
        {this.renderItems(value, filterId)}
        <div
          onClick={() => onResetFilter(filterId)}
          className='selected-filters__reset'>
          <Icon name='im-Close' size={8} />
        </div>
      </div>
    );
  }

  renderItems(value: string | string[], filterId?: string) {
    const isDateValue = filterId && isDateFilter(filterId);
    if (typeof value === 'string') {
      return <div className='selected-filters__items'>{value}</div>;
    } else if (Array.isArray(value)) {
      const separator = isDateValue ? ' - ' : ' / ';
      return (
        <div className='selected-filters__items'>
          {value.map((val, i) => {
            const valueToRender = isDateValue ? val.replace(' ', ', ') : val;
            return (
              <Fragment key={i}>
                <span>{this.props.getTranslate(valueToRender)}</span>
                {i + 1 !== value.length ? separator : ''}
              </Fragment>
            );
          })}
        </div>
      );
    }

    return null;
  }

  static canRender(filterId, value) {
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length > 0;
    }
    return false;
  }
}

export default addTranslation(SelectedFilters);
