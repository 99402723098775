import React, { Component } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import I18nHelpers from 'i18n/I18nHelpers';
import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';
import buttonStatuses from 'components/ui/button/statuses';
import LabelStatus from 'components/ui/labelStatus';
import { Status as LabelStatusType } from 'components/ui/labelStatus/LabelStatus';
import Mapper from 'helpers/Mapper';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    badge?: {
      text: string;
      status: LabelStatusType;
    };
    title?: string;
    text?: string;
    icon?: React.ReactNode;
    okButton?: {
      text: string;
      status?: keyof typeof buttonStatuses;
    };
    cancelButton?: {
      text: string;
      status?: keyof typeof buttonStatuses;
    };
  };
}

class Confirm extends Component<Props> {
  getText = (text: string) => {
    const { getTranslate } = this.props;
    return /\w\.\w/gi.test(text) ? getTranslate(text) : text;
  };

  render() {
    const {
      getTranslate,
      callback,
      content: {
        badge,
        title,
        text,
        icon,
        okButton = {
          text: 'common.yes.button',
          status: 'primary',
        },
        cancelButton = {
          text: 'common.no.button',
          status: 'outline',
        },
      },
    } = this.props;

    return (
      <>
        {icon ? <div className='modal__icon'>{icon}</div> : null}
        <ModalTitle>
          {title ? this.getText(title) : getTranslate('modals.common.title')}
        </ModalTitle>
        {badge && (
          <div className='modal__badge'>
            <LabelStatus
              text={this.getText(badge.text)}
              status={Mapper.getStatus(String(badge.status))}
            />
          </div>
        )}
        {text ? (
          <ModalContent>{I18nHelpers.getJsx(this.getText(text))}</ModalContent>
        ) : null}
        <ModalFooter>
          <Button
            text={this.getText(cancelButton.text)}
            status={cancelButton.status}
            size='normal'
            onClick={() => callback(false)}
          />
          <Button
            text={this.getText(okButton.text)}
            status={okButton.status}
            size='normal'
            onClick={() => callback(true)}
          />
        </ModalFooter>
      </>
    );
  }
}

export default addTranslation(Confirm);
